import getByPath from 'lodash/get';
import { combineEpics, ofType } from 'redux-observable';
import { addTag, removeTag } from 'tg-core-redux/lib/modules/player/action';
import { switchMap, map, filter } from 'rxjs/operators';

import {
  getCurrentExperimentTag,
  formatExperimentTag,
} from '../utils/experiment';

const updateExperimentTagOnceAuthenticated = (action$, state$) =>
  action$.pipe(
    ofType('EXPERIMENT_WIN'),
    filter(() => !state$.value.player.isAuthenticated),
    switchMap(action =>
      action$.pipe(
        ofType(
          'SIGNINV2_COMPLETE',
          'SIGNINWITHCODE_COMPLETE',
          'SIGNIN_PNP_COMPLETE'
        ),
        map(() => {
          const { experiment, variant, status } = action.payload;

          return updateExperimentTag(experiment, variant, status, state$.value);
        })
      )
    )
  );

const updateExperimentTagAfterExperimentWin = (action$, state$) =>
  action$.pipe(
    ofType('EXPERIMENT_WIN'),
    filter(() => state$.value.player.isAuthenticated),
    map(action => {
      const { experiment, variant, status } = action.payload;

      return updateExperimentTag(experiment, variant, status, state$.value);
    })
  );

const updateExperimentTag = (experiment, variant, status, state) => {
  const { player, app } = state;

  const newExperimentTag = formatExperimentTag(experiment, variant, status);

  const currentExperimentTag = getCurrentExperimentTag(
    getByPath(player, 'user.Tags', []),
    experiment,
    status,
    app.jurisdiction,
    app.ipCountry
  );

  if (!currentExperimentTag) {
    return addTag(newExperimentTag, player.sessionId);
  }

  if (currentExperimentTag !== newExperimentTag) {
    return [
      removeTag(currentExperimentTag, player.sessionId),
      addTag(newExperimentTag, player.sessionId),
    ];
  }

  return [];
};

export default combineEpics(
  updateExperimentTagAfterExperimentWin,
  updateExperimentTagOnceAuthenticated
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';

import playerActions from 'tg-core-redux/lib/modules/player/action';
import walletActions from 'tg-core-redux/lib/modules/wallet/action';
import quickDepositActions from '@actions/quickDeposit';
import { addAlert } from '@actions/alert';
import { withConfig } from '@config';
import BasePage from '@pages/BasePage';
import { getProperties } from 'tg-core-redux/lib/modules/player_properties/action';
import './style.css';
import withRouteData from '@utils/withRouteData';
import Sbtech from './Sbtech';
import Metric from './Metric';
import QuickDepositMobile, {
  selectQuickDepositMobileProps,
} from '@components/QuickDepositMobile';
import Betconstruct from './Betconstruct';
import SpringBuilder from './SpringBuilder';
import Betsson from './Betsson';
import ProductBlock from '@components/ProductBlock';
import { Translate } from 'tg-core-components';
import CurrencySelector from '@components/CurrencySelector';

export const IMPLEMENTATIONS = {
  METRIC: 'MetricGaming',
  SBTECH: 'SBTech',
  BETCONSTRUCT: 'BetConstruct',
  SPRINGBUILDER: 'SpringBuilder',
  BETSSON: 'Betsson',
};

@withRouteData
@withConfig
class SportsContainer extends Component {
  isFetchingToken = false;

  state = {
    token: undefined,
  };

  componentDidMount() {
    const { sessionId } = this.props;
    __CLIENT__ && sessionId && this.props.getProperties(sessionId);
  }

  renderSportsbook(implementation) {
    const {
      config,
      locale,
      user,
      playerProperties,
      ipCountryCode,
      contentful_config,
      getWallet,
      getWallets,
      sessionId,
      jurisdiction,
      device,
      addAlert,
      isAuthenticated,
      match,
      history,
      location,
      wallet,
    } = this.props;
    const { token } = this.state;

    const oddsFormat = find(playerProperties, p => p.Name === 'oddsFormat');
    const layout = find(playerProperties, p => p.Name === 'sportsbook-layout');
    const countries = contentful_config.find(c => c.key === 'countries');
    const ipCountry =
      countries.value.find(c => c.value === ipCountryCode) || {};

    const props = {
      isAuthenticated,
      config,
      locale,
      token,
      getWallet,
      getWallets,
      sessionId,
      jurisdiction,
      user,
      currency: user
        ? user.Currency
        : ipCountry
        ? ipCountry.currency
        : config.defaultCurrency,
      oddsFormat: oddsFormat ? oddsFormat.Value : 'Decimal',
      layout: layout ? layout.Value : 'classic',
      device,
      addAlert,
      match,
      history,
      location,
      wallet,
      ipCountryCode,
    };

    switch (implementation) {
      case IMPLEMENTATIONS.METRIC:
        return <Metric {...props} config={config.metric} key={token} />;
      case IMPLEMENTATIONS.BETCONSTRUCT:
        return <Betconstruct {...props} config={config} key={token} />;
      case IMPLEMENTATIONS.SBTECH:
        return <Sbtech {...props} config={config} key={token} />;
      case IMPLEMENTATIONS.SPRINGBUILDER:
        return <SpringBuilder {...props} config={config} />;
      case IMPLEMENTATIONS.BETSSON:
        return <Betsson {...props} config={config} key={token} />;
      default:
        return null;
    }
  }

  async getSession(implementation) {
    const { sessionId, createSSOToken } = this.props;

    if (implementation === IMPLEMENTATIONS.SPRINGBUILDER) {
      implementation = IMPLEMENTATIONS.BETCONSTRUCT;
    }

    this.isFetchingToken = true;
    const token = await createSSOToken(sessionId, implementation);
    this.isFetchingToken = false;
    return token;
  }

  async componentDidUpdate(prevProps) {
    if (this.isFetchingToken) return;

    if (this.getImplementation() === IMPLEMENTATIONS.BETSSON) {
      return;
    }

    // If token is missing but sessionId is present or if the user currency changes
    // Request new token
    if (
      (!this.state.token && this.props.sessionId) ||
      prevProps?.user?.Currency !== this.props?.user?.Currency ||
      prevProps?.activeDisplayCurrency !== this.props?.activeDisplayCurrency
    ) {
      const token = await this.getSession(this.getImplementation());
      this.setState({ token: token.data });
    }
  }

  getImplementation() {
    const { config } = this.props;

    if (config.metric) return IMPLEMENTATIONS.METRIC;
    if (config.betconstruct) return IMPLEMENTATIONS.BETCONSTRUCT;
    if (config.sbtech) return IMPLEMENTATIONS.SBTECH;
    if (config.springBuilder) return IMPLEMENTATIONS.SPRINGBUILDER;
    if (config.betsson) return IMPLEMENTATIONS.BETSSON;

    return null;
  }

  render() {
    const {
      pages,
      match,
      quickDepositMobileProps,
      quickDepositInit,
      config,
      sections,
      productBlocks,
      displayCurrencies,
      activeDisplayCurrency,
      providerCurrencies,
      setActiveCurrency,
      sessionId,
    } = this.props;

    const implementation = this.getImplementation();

    if (!implementation)
      return <div>No sportsbook configuration available</div>;

    let page = pages.find(p => '/' + p.url === match.url);
    if (!page) page = pages.find(p => p.url === 'sports');

    const productBlockSection = sections.find(
      s => s.identifier === 'block-feature-sportsbook'
    );

    if (
      productBlocks?.find(block => block.Product === 'Sportsbook') ||
      config?.block?.sportsbook
    )
      return <ProductBlock productBlockSection={productBlockSection} />;

    /**
     * If it's a multiCurrency casino and the selected currency is not available for this game, select another one.
     */
    const subproviderKey = implementation.toLowerCase();
    // Get supported currencis for this gsp or fallback to default
    let currencies = providerCurrencies?.value
      ? providerCurrencies?.value[subproviderKey] ||
        providerCurrencies?.value['default']
      : [];

    // Filter out gsp supported currencis with aleacc supported.
    currencies = currencies.filter(pc =>
      displayCurrencies.find(dc => pc.toUpperCase() === dc.toUpperCase())
    );

    const mainCurrenciesCount = providerCurrencies?.value?.mainCurrencies || 0;

    if (
      config.multiCurrency &&
      activeDisplayCurrency &&
      currencies.length > 0 &&
      !currencies.includes(activeDisplayCurrency)
    ) {
      return (
        <BasePage className="SportsContainer" page={page} betslipTab={true}>
          <div className="SportsContainer__require-change-currency-message-wrapper">
            <div className="SportsContainer__require-change-currency-message">
              <Translate
                id="label.gameplay.change-currency"
                defaultMessage="This game does not support { activeDisplayCurrency }. Please select another currency to start the game."
                values={{
                  activeDisplayCurrency,
                }}
              />

              <CurrencySelector
                currency={null}
                expanded={true}
                expandable={false}
                currencies={currencies.map(c => ({ code: c, name: c }))}
                mainCurrencies={mainCurrenciesCount > 0}
                mainCurrenciesCount={mainCurrenciesCount}
                onSelect={c => {
                  setActiveCurrency(c, sessionId);
                }}
              />
            </div>
          </div>
        </BasePage>
      );
    }

    return (
      <BasePage className="SportsContainer" page={page} betslipTab={true}>
        {this.renderSportsbook(implementation)}

        {config.quickDeposit?.enabled && (
          <QuickDepositMobile
            quickDepositInit={quickDepositInit}
            {...quickDepositMobileProps}
          />
        )}
      </BasePage>
    );
  }
}

export default connect(
  state => ({
    playerProperties: state.playerProperties.data,
    ipCountryCode: state.app.ipCountry,
    sessionId: state.player.sessionId,
    user: state.player.user,
    wallet: state.wallet.data,
    pages: state.content.pages.data,
    sections: state.content.sections.data,
    locale: state.app.locale,
    jurisdiction: state.app.jurisdiction,
    contentful_config: state.content.config.data,
    device: state.app.device,
    isAuthenticated: state.player.isAuthenticated,
    quickDepositMobileProps: selectQuickDepositMobileProps(state),
    productBlocks: state.responsible_gaming.productBlocks,
    displayCurrencies: state.currencies.displayCurrencies,
    activeDisplayCurrency: state.player.activeCurrency,
    providerCurrencies:
      state.content.config.data?.find(c => c.key === 'provider-currencies') ||
      [],
  }),
  {
    setActiveCurrency: playerActions.setActiveCurrency,
    createSSOToken: playerActions.createSSOToken,
    getWallet: walletActions.getWallet,
    getWallets: walletActions.getWallets,
    addAlert: addAlert,
    getProperties,
    quickDepositInit: quickDepositActions.init,
  }
)(SportsContainer);

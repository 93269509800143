import React, { Component } from 'react';
import compose from 'recompose/compose';
import { injectIntl, FormattedNumber } from 'react-intl';
import WithValidation from 'tg-core-components/lib/lib/WithValidation';
import { Button, Icon, Translate } from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';

import './ConfirmContactInfoStyle.css';

class ConfirmContactInfoStep extends Component {
  render() {
    const {
      email,
      phone,
      fullname,
      birthdate,
      address,
      zip,
      city,
      optInMarketing,
      onSubmit,
      onGoBack,
      currency,
      intl,
      gender,
      limitsData,
      isLoading,
    } = this.props;

    return (
      <form className="Step3 form" onSubmit={onSubmit}>
        <div className="account-information">
          <div className="account-header">
            <Icon icon={`user-circle`} />
            <p className="fullname">{fullname}</p>
          </div>

          {address && zip && city && (
            <div className="user-info">
              <p>
                {address}
                <br />
                {zip} {city.toUpperCase()}
              </p>
            </div>
          )}

          {gender && birthdate && (
            <div className="birth-info">
              <Translate
                tagName="p"
                id={`noun.${gender.toLowerCase()}`}
                defaultMessage={gender}
              />
              <p>{birthdate}</p>
            </div>
          )}

          <div className="contact-info">
            <p>
              <Icon icon="phone" /> {phone}
            </p>
            <p>
              <Icon icon={`envelope`} /> {email}
            </p>
          </div>

          {optInMarketing && (
            <div className="optin-information-holder">
              <div className="optin-information-wrapper">
                <p>
                  <strong>
                    {translate(
                      {
                        id: 'label.optin-message-step3-before',
                        defaultMessage:
                          'You have opted in to receive direct marketing',
                      },
                      intl
                    )}
                  </strong>
                </p>

                <p>
                  {translate(
                    {
                      id: 'label.optin-message-step3-after',
                      defaultMessage:
                        'To stay updated with our promotions and offers, it is recommended that you stay subscribed.',
                    },
                    intl
                  )}
                </p>
              </div>

              <p className="final-message">
                {translate(
                  {
                    id: 'label.optin-message-step3-last',
                    defaultMessage:
                      'Click previous below, if you would like to change your selection.',
                  },
                  intl
                )}
              </p>
            </div>
          )}
        </div>

        {limitsData &&
          limitsData.map(l => {
            let period = '';

            switch (parseInt(l.Timespan)) {
              case 0:
                period = translate({ id: 'label.each_transaction' }, intl);
                break;
              case 1:
                period = translate({ id: 'label.each_day' }, intl);
                break;
              case 7:
                period = translate({ id: 'label.each_week' }, intl);
                break;
              case 30:
                period = translate({ id: 'label.each_month' }, intl);
                break;
              default:
                break;
            }

            return (
              l.Amount > 0 && (
                <div className="inner-limit-info" key={Math.random()}>
                  <p>
                    <Icon icon={`dollar-sign`} />
                    <Translate id={`label.type.${l.Type}`} />, {period}:{' '}
                    <FormattedNumber
                      value={l.Amount}
                      style={'currency'}
                      currency={currency}
                    />
                  </p>
                </div>
              )
            );
          })}
        <div className="layout-item">
          <Button className="button tertiary wide" onClick={onGoBack}>
            {translate({ id: 'action.button.previous' }, intl)}
          </Button>

          <Button
            className="button primary wide loading"
            type="submit"
            isLoading={isLoading}>
            {translate({ id: 'action.button.activate_account' }, intl)}
          </Button>
        </div>
      </form>
    );
  }
}

const rules = {};

export default compose(
  WithValidation(rules),
  injectIntl
)(ConfirmContactInfoStep);

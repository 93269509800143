import React, { Component } from 'react';
import ChangeUsernameWidget from 'tg-core-components/lib/widgets/ChangeUsername';
import { connect } from 'react-redux';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import marked from 'marked';
import OffCanvasLayout from '@components/OffCanvasLayout';
import withRouteData, { Loader } from '@utils/withRouteData';
import { loadContents } from '@actions/content';

@withRouteData
@connect(state => ({
  sessionId: state.player.sessionId,
  pages: state.content.pages.data,
}))
class ChangeUsername extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#change-username'],
    },
  ]);

  handleSubmit = usernameInfo => {
    this.props.dispatch(
      playerActions.changeUsername(
        {
          Username: usernameInfo.Username,
        },
        this.props.sessionId
      )
    );
  };

  render() {
    const page = this.props.pages?.find(p => p.url === '#change-username');

    return (
      <OffCanvasLayout className="ChangeUsernameContainer" backUrl="#dashboard">
        <Loader isLoading={!page}>
          {page?.content && (
            <header
              dangerouslySetInnerHTML={{ __html: marked(page.content) }}
            />
          )}
          <ChangeUsernameWidget onSubmitForm={this.handleSubmit} />
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default ChangeUsername;

import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Translate } from 'tg-core-components';
import { timeToPeriod } from '@utils/limitTranslationMap';

const DepositLimits = ({ limitsToShow, currency, jurisdiction }) =>
  limitsToShow.length > 0 ? (
    <div className="deposit-limit">
      <Translate
        id="label.deposit-limit"
        defaultMessage="Deposit limit"
        tagName="h3"
      />
      <div className="limits">
        {limitsToShow
          .filter(l => Object.hasOwnProperty.call(l, 'Id'))
          .map(l => (
            <div className="limit" key={l.Id}>
              <div>
                {timeToPeriod({
                  time: parseInt(l.Timespan),
                  type: l.Type,
                })}
              </div>
              <div>
                <FormattedNumber
                  value={Math.max(
                    0,
                    jurisdiction === 'es'
                      ? typeof l.RemainingAmount !== 'undefined'
                        ? l.RemainingAmount
                        : l.Amount
                      : l.Amount
                  )}
                  style="currency"
                  currency={currency}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  ) : null;

export default DepositLimits;

const initialState = {
  isLoading: false,
  status: null,
  error: null,
  data: { leaderboards: {}, relativeLeaderboards: {} },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LEADERBOARD_START':
      return {
        ...state,
        status: null,
        isLoading: true,
      };
    case 'GET_LEADERBOARD_COMPLETE':
      return {
        ...state,
        isLoading: false,
        status: 'success',
        data: {
          ...state.data,
          leaderboards: {
            ...state.data.leaderboards,
            [action.payload.Identifier]: action.payload,
          },
        },
      };
    case 'GET_LEADERBOARD_FAILURE':
      return {
        ...state,
        isLoading: false,
        status: null,
        error: action.error,
      };

    case 'GET_RELATIVE_LEADERBOARD_START':
      return {
        ...state,
        status: null,
        isLoading: true,
      };
    case 'GET_RELATIVE_LEADERBOARD_COMPLETE':
      return {
        ...state,
        isLoading: false,
        status: 'success',
        data: {
          ...state.data,
          relativeLeaderboards: {
            ...state.data.relativeLeaderboards,
            [action.payload.Identifier]: action.payload,
          },
        },
      };
    case 'GET_RELATIVE_LEADERBOARD_FAILURE':
      return {
        ...state,
        isLoading: false,
        status: null,
        error: action.error,
      };
    default:
      return state;
  }
};

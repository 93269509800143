export const validate = ssn => {
  const regexList = [
    /^[0-9]{8}-[0-9]{4}$/,
    /^[0-9]{12}$/,
    /^[0-9]{6}-[0-9]{4}$/,
    /^[0-9]{10}$/,
  ];

  const modifiedNumber = ssn
    .replace(/\D/g, '')
    .split('')
    .reverse()
    .slice(0, 10);

  if (modifiedNumber.length != 10) {
    return false;
  }

  const sum = modifiedNumber
    .map(n => Number(n))
    .reduce((previous, current, index) => {
      if (index % 2) current *= 2;
      if (current > 9) current -= 9;

      return previous + current;
    });

  return 0 === sum % 10 && regexList.some(regex => regex.test(ssn));
};

export const transform = ssn => {
  ssn = ssn.trim();

  if (ssn.indexOf('-') > -1) ssn = ssn.replace('-', '');

  if (ssn.length === 10) {
    const currentYear = new Date().getFullYear();
    const oldestAge = currentYear - parseInt('19' + ssn.slice(0, 2)) - 1;

    return oldestAge > 80 ? (ssn = '20' + ssn) : (ssn = '19' + ssn);
  }

  return ssn;
};

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as serviceWorker from './registerServiceWorker';

import React from 'react';
import ReactDOM from 'react-dom';
import 'lazysizes';
import Bootstrap from './bootstrap';
import Cookies from 'universal-cookie';
import { gtmInit } from '@utils/google-tag-manager';
import { getConfig } from '@config';
import { configureStore } from './redux';
import { createBrowserHistory } from 'history';
import querystring from 'query-string';

const cookies = new Cookies();

const store = configureStore(window.__INITIAL_STATE__);

const { localeUrl } = store.getState().app;

const isPnp = getConfig(
  store.getState().app.jurisdiction,
  store.getState().app.ipCountry
);

const history = createBrowserHistory({ basename: `/${localeUrl}` });
window.routerHistory = history;

if (cookies.get('sessionId')) {
  store.dispatch({
    type: 'UPDATE_AUTHENTICATE',
    payload: cookies.get('sessionId'),
  });
}

// dispose as global variable to allow access from anywhere
window.reduxStore = store;

window.handlePaymentFailure = search => {
  if (isPnp) {
    window.postMessage({
      name: 'pnp-deposit-failure',
      payload: querystring.parse(search),
    });
  }
  store.dispatch({
    type: 'PAYMENT_FAILURE',
    payload: querystring.parse(search),
  });
};

window.handlePaymentSuccess = search => {
  if (isPnp) {
    window.postMessage({
      name: 'pnp-deposit-success',
      payload: querystring.parse(search),
    });
  }

  store.dispatch({
    type: 'PAYMENT_SUCCESS',
    payload: querystring.parse(search),
  });
};

window.handleDepositComplete = (search, bonusCode, didClaimBonus) => {
  store.dispatch({
    type: 'DEPOSIT_COMPLETE',
    payload: {
      ref_url: querystring.parse(search).ref_url,
      bonusCode: bonusCode,
      didClaimBonus: didClaimBonus,
    },
  });
};

window.handlePaymentPending = search => {
  if (isPnp) {
    window.postMessage({
      name: 'pnp-deposit-pending',
      payload: querystring.parse(search),
    });
  }

  store.dispatch({
    type: 'PAYMENT_PENDING',
    payload: querystring.parse(search),
  });
};

window.handlePaymentCancellation = () => {
  store.dispatch({
    type: 'PAYMENT_CANCELLATION',
  });
};

const rootElement = document.getElementById('root');

const currentLocale = cookies.get('locale') || 'en-US';
const localeCode = currentLocale.split('-')[0].toLowerCase();

import(`@formatjs/intl-pluralrules/locale-data/${localeCode}`);
import(`@formatjs/intl-relativetimeformat/locale-data/${localeCode}`);

ReactDOM.hydrate(<Bootstrap store={store} history={history} />, rootElement);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./bootstrap', () => {
    const NextApp = require('./bootstrap').default;
    ReactDOM.render(<NextApp store={store} history={history} />, rootElement);
  });
}

if (getConfig().CURRENT_ENV === 'prod') gtmInit(getConfig().GTM_ID);

serviceWorker.register();

import React from 'react';
import { getConfig } from '@utils/config';
import marked from 'marked';

import './style.css';

const BlockFeature = ({
  /* Props from selector */
  jurisdiction,
  ipCountry,
  sections,
}) => {
  const config = getConfig(jurisdiction, ipCountry);

  const section = config?.block?.signIn
    ? 'block-feature-sign-in'
    : 'block-feature-deposit';
  const content = sections?.find(s => s.identifier === section)?.content;

  return (
    <div className="BlockFeature">
      <div
        className="BlockFeature__content"
        dangerouslySetInnerHTML={{
          __html: marked(content || ''),
        }}
      />
    </div>
  );
};

const selector = state => ({
  jurisdiction: state.app.jurisdiction,
  ipCountry: state.app.ipCountry,
  sections: state.content.sections.data,
});

export default {
  name: 'BlockFeature',
  selector,
  Component: BlockFeature,
};

import { addAlert, removeAlerts } from '@actions/alert';
import { combineEpics, ofType } from 'redux-observable';
import { map, filter } from 'rxjs/operators';

const getAction = type => type.match(/^(.*)_[A-Z]+$/)[1];

const collectAlerts = action$ =>
  action$.pipe(
    filter(action => action.type.match(/_FAILURE$|_COMPLETE$|_CANCELLATION$/)),
    map(action => {
      const alert = {
        ...action,
        type: getAction(action.type),
        level:
          (action.type.match(/_FAILURE$/) && 'danger') ||
          (action.type.match(/_COMPLETE$/) && 'success') ||
          (action.type.match(/_CANCELLATION$/) && 'warning'),
      };
      return [addAlert(alert)];
    })
  );

const cleanUpAlerts = action$ =>
  action$.pipe(
    ofType(
      'PLAYER_UPDATE_RESET',
      'CHANGE_PASSWORD_RESET',
      'REQUEST_ACTIVATION_CODE_RESET',
      'ACTIVATE_RESET',
      'PLAYER_UPDATE_START',
      'CHANGE_PASSWORD_START',
      'REQUEST_ACTIVATION_CODE_START',
      'ACTIVATE_START',
      'SIGNINV2_START',
      'SET_PLAYER_PROPERTIES_START'
    ),
    map(action => [removeAlerts(getAction(action.type))])
  );

export default combineEpics(collectAlerts, cleanUpAlerts);

import depositApis from 'tg-core-devcode/lib/deposit';
import { processPayment } from 'tg-core-devcode/lib/utils';

export const matchProviderType = (first, second) => {
  let { providerType: fType } = first;
  let { providerType: sType } = second;

  if (first.service) fType = fType + first.service;
  if (second.service) sType = sType + second.service;

  return sType.toLowerCase() === fType.toLowerCase();
};

export const getDepositMethod = provider => {
  switch (provider) {
    case 'trustly':
      return depositApis.trustly;
    case 'zimpler':
      return depositApis.zimpler;
    case 'brite':
      return depositApis.bank;
    case 'coinspaid':
      return depositApis.bank;
    default:
      return depositApis.trustly;
  }
};

export const getService = provider => {
  switch (provider) {
    case 'trustly':
      return null;
    case 'zimpler':
      return null;
    case 'brite':
      return 'BRITE';
    default:
      return null;
  }
};

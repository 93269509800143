import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Translate } from 'tg-core-components';
import marked from 'marked';
import { openHash } from '@utils/hash';

import './style.css';

const BonusSelector = ({
  bonuses,
  onBonusSelect,
  selectedBonus,
  isLoading,
}) => {
  return (
    <div className="BonusSelector">
      <div className="BonusSelector__bonuses">
        {bonuses.map(b => (
          <Bonus
            key={b.Id}
            onClick={id => onBonusSelect(id)}
            name={b.name}
            content={b.content}
            image={b.image}
            identifier={b.Identifier}
            id={b.Id}
            tac={b.tac}
            isLoading={isLoading && selectedBonus === b.Id}
          />
        ))}
      </div>
    </div>
  );
};

const Bonus = ({
  onClick,
  name,
  content,
  image,
  identifier,
  id,
  tac,
  isLoading,
}) => (
  <div className="BonusSelector__bonus">
    {image?.file?.url && (
      <div className="BonusSelector__bonus__image">
        <img src={image.file.url} />
      </div>
    )}

    <div className="BonusSelector__bonus__content">
      <h3>{name}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: marked(content),
        }}
      />
    </div>

    {tac && (
      <Link
        onClick={e => {
          e.stopPropagation();
        }}
        to={{
          hash: openHash(
            location.hash,
            `#popup/terms-and-conditions/bonus/${identifier}`
          ),
        }}>
        <Translate id="action.read-full-tac" defaultMessage="Read full T&Cs" />
      </Link>
    )}

    <Button
      className="button primary"
      onClick={() => onClick(id)}
      isLoading={isLoading}>
      <Translate id="action.claim-bonus" defaultMessage="Claim" />
    </Button>
  </div>
);

export default BonusSelector;

export const selectBonuses = state => {
  const {
    bonus: { data: bonuses },
    content: {
      bonuses: { data: bonusesContent },
    },
  } = state;

  // Distinct bonus by Identifier
  return [...new Map(bonuses?.map(item => [item['Identifier'], item])).values()]
    .map(bonus => {
      const bonusContent = bonusesContent?.find(
        b => bonus.Identifier === b.identifier
      );

      if (bonusContent && bonus.State === 'Initiated')
        return {
          ...bonus,
          content: bonusContent.content,
          image: bonusContent.image,
          name: bonusContent.name,
          tac: bonusContent.termsAndConditions,
        };

      return false;
    })
    ?.filter(Boolean);
};

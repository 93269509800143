import React, { Component } from 'react';

const withHideOnServer = WrappedComponent =>
  class WithHideOnServer extends Component {
    state = {
      visibility: 'hidden',
    };

    componentDidMount() {
      if (__CLIENT__) this.setState({ visibility: 'visible' });
    }

    render() {
      const { visibility } = this.state;

      if (visibility === 'visible') return <WrappedComponent {...this.props} />;

      return (
        <div style={{ visibility }}>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };

export default withHideOnServer;

import React, { Component } from 'react';
import compose from 'recompose/compose';
import { injectIntl } from 'react-intl';
import { Input, Button, Translate } from 'tg-core-components';
import WithValidation from 'tg-core-components/lib/lib/WithValidation';
import BonusItem from '@components/BonusItem';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { withCookies } from 'react-cookie';

@withCookies
@injectIntl
class BonusStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPromoField: false,
    };
  }

  render() {
    const {
      onSubmit,
      intl,
      bonuses,
      cookies,
      onSkip,
      onSubmitBonus,
    } = this.props;

    const { showPromoField } = this.state;

    const handleTogglePromo = e => {
      e.preventDefault();
      this.setState(prevState => ({
        showPromoField: !prevState.showPromoField,
      }));
    };

    const submitButton = (
      <Button className="button primary wide" type="submit">
        {translate({ id: 'action.button.continue' }, intl)}
      </Button>
    );

    return (
      <div>
        <form className="form BonusStep signup-form" onSubmit={onSubmit}>
          <div>
            <Translate
              id="label.choose_bonus"
              defaultMessage="Start your journey with a bonus or {link}"
              values={{
                link: (
                  <a href="#" onClick={onSkip}>
                    {translate(
                      {
                        id: 'action.button.no_bonus',
                        defaultMessage: 'click here to continue without.',
                      },
                      intl
                    )}
                  </a>
                ),
              }}
            />
          </div>
          {showPromoField && (
            <div className="layout-item promo">
              <div className="layout-item-8">
                <Input
                  label=""
                  placeholder={translate({ id: 'label.bonus_code' }, intl)}
                  name="PromoCode"
                  autoFocus
                />
              </div>
              <div className="promo-button layout-item-4">{submitButton}</div>
            </div>
          )}

          {!showPromoField && (
            <a href="#" onClick={handleTogglePromo}>
              {translate(
                {
                  id: 'action.button.promo_code',
                  defaultMessage: 'Do you have a bonus code?',
                },
                intl
              )}
            </a>
          )}
        </form>
        <div>
          {bonuses &&
            bonuses.map((b, k) => (
              <BonusItem
                {...b}
                key={k}
                onClick={() => {
                  if (b.bonusType === 'signup') {
                    cookies.set('bonusCodeSignUp', b.bonusCode, {
                      expires: new Date(Date.now() + 31556952000),
                    });
                    cookies.remove('bonusCodeDeposit');
                  }
                  if (b.bonusType === 'deposit') {
                    cookies.set('bonusCodeDeposit', b.bonusCode, {
                      expires: new Date(Date.now() + 31556952000),
                    });
                    cookies.remove('bonusCodeSignUp');
                  }
                  onSubmitBonus({ PromoCode: b.bonusCode });
                }}
              />
            ))}
        </div>
      </div>
    );
  }
}

const rules = {
  PromoCode: [],
};

export default compose(WithValidation(rules))(BonusStep);

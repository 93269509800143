import React, { useState } from 'react';
import marked from 'marked';
import { useDispatch } from 'react-redux';
import { Button, Translate } from 'tg-core-components';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import { useConfig } from '@utils/config';

import './style.css';

const Consent = ({
  /* Props from parent */
  nextStep,
  sessionId,

  /* Props from selector */
  sections,
}) => {
  const [yesIsLoading, setYesIsLoading] = useState(false);
  const [noIsLoading, setNoIsLoading] = useState(false);
  const dispatch = useDispatch();
  const config = useConfig();

  const consentHeader = sections?.find(
    s => s.identifier === 'header.paynplay.consent'
  );

  const communicationConsentDescription = sections?.find(
    s => s.identifier === 'description.paynplay.communication-consent'
  );

  return (
    <div className="Consent">
      {consentHeader?.content && (
        <header
          dangerouslySetInnerHTML={{
            __html: marked(consentHeader.content),
          }}
        />
      )}

      <div className="Consent__buttons">
        <Button
          className="button primary"
          isLoading={yesIsLoading}
          onClick={() => {
            setYesIsLoading(true);

            config?.communicationConsent?.values.forEach(value =>
              dispatch(playerActions.optIn(sessionId, value))
            );

            nextStep();
          }}>
          <Translate id="action.yes" defaultMessage="Yes" />
        </Button>

        <Button
          className="button tertiary"
          isLoading={noIsLoading}
          onClick={() => {
            setNoIsLoading(true);
            nextStep();
          }}>
          <Translate id="action.no" defaultMessage="No" />
        </Button>
      </div>

      {communicationConsentDescription?.content && (
        <div
          className="communication-consent-description"
          dangerouslySetInnerHTML={{
            __html: marked(communicationConsentDescription.content),
          }}
        />
      )}
    </div>
  );
};

const selector = state => ({
  sections: state.content.sections.data,
});

export default {
  name: 'consent',
  Component: Consent,
  selector,
};

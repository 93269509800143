import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import BetslipTab from '@components/BetslipTab/index';
import { getWallet, getWallets } from 'tg-core-redux/lib/modules/wallet/action';

@connect(
  state => ({
    sessionId: state.player.sessionId,
  }),
  { getWallet, getWallets }
)
class Metric extends Component {
  iframe = null;
  state = {
    token: null,
  };

  _receiveMessage = e => {
    const { sessionId, getWallet, getWallets } = this.props;

    if (!this.iframe || !e.data) return;

    const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;

    switch (data.name) {
      case 'iframeResize':
        this.iframe.style.height = data.value + 'px';
        break;
      case 'refreshBalance':
        return Promise.all([getWallet(sessionId), getWallets(sessionId)]);
    }
  };

  _sendMessage = (name, value) => {
    if (!this.iframe) return;
    this.iframe.contentWindow.postMessage(JSON.stringify({ name, value }), '*');
  };

  _metricScroll = () => {
    this._sendMessage('scroll', {
      height: window.document.documentElement.clientHeight,
      top: this.iframe.getBoundingClientRect().top,
    });
  };

  componentDidMount() {
    __CLIENT__ && window.addEventListener('message', this._receiveMessage);

    __CLIENT__ && window.addEventListener('scroll', this._metricScroll);
  }

  componentWillUnmount() {
    __CLIENT__ && window.removeEventListener('message', this._receiveMessage);

    __CLIENT__ && window.removeEventListener('scroll', this._metricScroll);
  }

  render() {
    const { config, oddsFormat, currency, user, token, locale } = this.props;

    let url = config.host;
    let lang = locale.split('-')[0];
    if (locale === 'es-MX') lang = locale;

    const query = {
      oddsFormat,
      currency,
      userName: user?.Username,
      operatorName: config.operator,
      lang,
      userSessionToken: token,
    };

    url += '?' + queryString.stringify(query);

    return (
      <div>
        <BetslipTab provider="metric" />
        <iframe
          id="metric"
          className="container"
          ref={r => (this.iframe = r)}
          src={url}
          data-hj-allow-iframe
        />
      </div>
    );
  }
}

export default Metric;

import { combineEpics, ofType } from 'redux-observable';
import kycActions from 'tg-core-redux/lib/modules/kyc/action';
import { map } from 'rxjs/operators';

const uploadSuccess = (action$, state$) =>
  action$.pipe(
    ofType('UPLOAD_KYC_COMPLETE'),
    map(() => kycActions.getKyc(state$.value.player.sessionId))
  );

export default combineEpics(uploadSuccess);

import React from 'react';
import { Provider, connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import preload from './preload';
import ErrorBoundary from '@components/ErrorBoundary';
import OverlayRouter from '@components/OverlayRouter';
import OverlayRoutes from '@components/OverlayRoutes';
import App from '@pages/App';
import ScrollToTop from '@components/ScrollToTop';
import { translationError } from '@utils/translations';
import { getConfig } from '@config';
import { experimentOptions } from '@utils/experiment';
import { ExperimentProvider } from 'react-experiment-hoc';

const IntlReduxProvider = connect(
  state => ({ ...state.translation, locale: state.app.uiLocale }),
  null,
  null,
  {
    pure: false,
  }
)(IntlProvider);

const ExperimentReduxProvider = connect(state => ({
  isBootstrapping: state.app.isBootstrapping,
  jurisdiction: state.app.jurisdiction,
  config: getConfig(state.app.jurisdiction, state.app.ipCountry),
}))(({ config, jurisdiction, isBootstrapping, children }) => (
  <ExperimentProvider
    options={experimentOptions(config, isBootstrapping)}
    key={jurisdiction + isBootstrapping}>
    {children}
  </ExperimentProvider>
));

const Bootstrap = ({ store, history }) => (
  <ErrorBoundary>
    <Provider store={store} onEnter={preload(store)}>
      <ExperimentReduxProvider>
        <IntlReduxProvider onError={translationError} textComponent="span">
          <Router history={history}>
            <OverlayRouter location={window.location}>
              <ScrollToTop>
                <App />
                <OverlayRoutes />
              </ScrollToTop>
            </OverlayRouter>
          </Router>
        </IntlReduxProvider>
      </ExperimentReduxProvider>
    </Provider>
  </ErrorBoundary>
);

export default Bootstrap;

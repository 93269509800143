import Cookies from 'universal-cookie';
import { getJurisdiction } from '@utils/jurisdiction';
import { getContentPreviewSettings } from '@utils/contentPreview';
import getByPath from 'lodash/get';

const cookies = new Cookies();

export const initialState = {
  isLoading: false,
  ipCountry: '',
  ip: '',
  locale: cookies.get('locale') || 'en-US',
  uiLocale: cookies.get('uiLocale') || 'en-US',
  localeUrl: cookies.get('locale') || 'en-US',
  isBootstrapping: true,
  device: null,
  jurisdiction: null,
  initialJurisdiction: null,
  terms: {},
  showGlobalLoader: false,
  hideInstallAppBanner: cookies.get('install-app') === 'false',
  visibilityState: 'visible',
};

export default (state = initialState, action) => {
  const contentPreviewSettings = getContentPreviewSettings();
  switch (action.type) {
    case 'USER_INFO_COMPLETE': {
      const jurisdiction = getJurisdiction({
        contentPreviewJurisdiction: contentPreviewSettings.jurisdiction,
        userJurisdiction: getByPath(action, 'payload.Jurisdiction'),
      });

      return {
        ...state,
        jurisdiction,
      };
    }

    case 'API_BOOTSTRAP_COMPLETE': {
      var ipCountry = '';
      if (action.payload && action.payload.ipcountry)
        ipCountry = action.payload.ipcountry;

      if (getContentPreviewSettings('country')) {
        ipCountry = getContentPreviewSettings('country');
      }

      var ip = '';
      if (action.payload && action.payload.ip) ip = action.payload.ip;

      const jurisdiction = getJurisdiction({
        contentPreviewJurisdiction: contentPreviewSettings.jurisdiction,
        userJurisdiction: __CLIENT__ && cookies.get('userJurisdiction'),
        ipCountry,
      });

      return {
        ...state,
        ipCountry,
        ip: ip,
        jurisdiction,
        initialJurisdiction: state.initialJurisdiction || jurisdiction,
      };
    }

    case 'SET_USERAGENT_DATA':
      return { ...state, ...action.payload };

    case 'BOOTSTRAP_INIT':
      return { ...state, isBootstrapping: true };
    case 'BOOTSTRAP_DONE':
      return { ...state, isBootstrapping: false };

    case 'FETCH_PAGES_START':
    case 'FETCH_GAMES_START':
    case 'FETCH_MENUS_START':
    case 'SIGNINV2_START':
      return { ...state, isLoading: true };

    case 'FETCH_PAGES_COMPLETE':
    case 'FETCH_GAMES_COMPLETE':
    case 'FETCH_MENUS_COMPLETE':
    case 'SIGNINV2_COMPLETE':
      return { ...state, isLoading: false };

    case 'SHOW_GLOBAL_LOADER':
      return { ...state, showGlobalLoader: true };
    case 'HIDE_GLOBAL_LOADER':
      return { ...state, showGlobalLoader: false };

    case 'REQUEST_APPROVE_TAC':
      return {
        ...state,
        terms: action.payload,
      };

    case 'SET_VISIBILITY_STATE':
      return { ...state, visibilityState: action.payload };

    case 'HIDE_INSTALL_APP_BANNER':
      return { ...state, hideInstallAppBanner: action.payload };

    default:
      return state;
  }
};

import React, { useState, useRef, useEffect } from 'react';
import { Button, Input, Money, Icon } from 'tg-core-components';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import translate from 'tg-core-components/lib/lib/utils/translate';

import './style.css';

const QuickAmounts = ({
  amounts,
  currency,
  intl,
  amount,
  handleChange,
  showCustomAmount,
  toggleCustomAmount,
  isLoading,
  deposit,
  disabled,
  isDeposit,
  limit,
  isWithinLimits,
}) => {
  const [activeButton, setActiveButton] = useState(null);
  const amountInput = useRef(null);

  useEffect(() => {
    if (amountInput && amountInput.current) {
      showCustomAmount
        ? amountInput.current.focus()
        : amountInput.current.blur();
    }
  }, [showCustomAmount]);

  if (!amounts) return null;

  return (
    <div className={cn('QuickAmounts', { show: showCustomAmount })}>
      <div className="amount-buttons">
        {amounts.map((amount, i) => (
          <Button
            onClick={() => {
              handleChange(amount);
              setActiveButton(amount);
            }}
            className={cn('button primary', {
              'show-loader': isLoading && activeButton === amount,
            })}
            type="submit"
            key={i}
            disabled={showCustomAmount || disabled}
            isLoading={isLoading && activeButton === amount}>
            <Money value={amount} currency={currency} />
          </Button>
        ))}
      </div>

      <Input
        pattern="\d*"
        className="custom-amount"
        name="custom-amount"
        type="number"
        min="0"
        placeholder={
          limit.min && limit.max
            ? `${intl.formatNumber(limit.min, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })} - ${intl.formatNumber(limit.max, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`
            : translate(
                {
                  id: 'label.amount',
                  defaultMessage: 'Amount',
                },
                intl
              )
        }
        value={amount || ''}
        onChange={(e, value) => handleChange(value)}
        onKeyPress={deposit}
        setRef={ref => (amountInput.current = ref)}
      />

      <Button
        className={cn('button action', {
          primary: !isDeposit,
          secondary: isDeposit,
          'show-loader': isLoading && isDeposit,
        })}
        type="button"
        isLoading={isLoading}
        onClick={() => (isDeposit ? deposit() : toggleCustomAmount())}
        disabled={disabled || (isDeposit && !isWithinLimits)}>
        <span className={cn({ show: showCustomAmount && amount < 1 })}>
          <Icon icon="times" />
        </span>
        <span className={cn({ show: !showCustomAmount })}>
          <Icon icon="pencil" />
        </span>
        <span className={cn({ show: isDeposit }, { disabled: disabled })}>
          <Icon icon="chevron-right" />
        </span>
      </Button>
    </div>
  );
};

export default injectIntl(QuickAmounts);

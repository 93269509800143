import React from 'react';
import marked from 'marked';
import { Button } from 'tg-core-components';

const WheelNotice = ({ section }) => {
  if (!section) return null;

  return (
    <div className="WheelNotice">
      <div
        className="WheelNotice__content"
        dangerouslySetInnerHTML={{ __html: marked(section.content || '') }}
      />
      {section.cta && section.action && (
        <div className="WheelNotice__cta">
          <Button className="button primary" to={section.action}>
            {section.cta}
          </Button>
        </div>
      )}
    </div>
  );
};

export default WheelNotice;

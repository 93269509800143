import React from 'react';
import { Input, Button } from 'tg-core-components';
import cn from 'classnames';
import {
  validateRules,
  required,
  email,
  externalEmailValidation,
} from '../../validation';
import { getContinueLabel } from '../../helpers';

import translate from 'tg-core-components/lib/lib/utils/translate';

const Email = ({
  /* Props from parent */
  errors,
  onChange,
  intl,
  fields,
  isLoading,
  nextStep,
  statuses,
  onExists,
  setIsLoading,

  /* Props from selector */
  device,
  jurisdiction,
}) => {
  const checkIfEmailExists = async () => {
    setIsLoading(true);
    const validEmail = await externalEmailValidation(jurisdiction)(
      fields.Email
    );
    setIsLoading(false);

    if (validEmail) {
      return false;
    }

    return true;
  };

  const isValid = statuses.Email === 'success';

  return (
    <form
      onSubmit={e => {
        e.preventDefault();

        if (!isValid) return;

        checkIfEmailExists().then(emailExists => {
          if (emailExists) {
            return onExists();
          }

          nextStep();
        });
      }}>
      <Input
        autoFocus
        type="email"
        label={translate({ id: 'label.email', defaultMessage: 'Email' }, intl)}
        value={fields.Email}
        name="Email"
        onChange={(_, Email) => onChange({ Email })}
        status={(isLoading && 'loading') || statuses.Email}
        statusText={errors.Email && translate({ id: errors.Email }, intl)}
      />

      <Button
        isLoading={isLoading}
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ Email }) => {
  const rules = {
    Email: [
      [required, 'error.empty.email'],
      [email, 'error.condition.email'],
    ],
  };
  const errors = await validateRules({ Email }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  device: state.app.device,
  jurisdiction: state.app.jurisdiction,
});

export default {
  name: 'email',
  icon: 'envelope',
  Component: Email,
  validate,
  selector,
  fields: ['Email'],
};

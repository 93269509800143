import React, { Component } from 'react';
import PanicWidget from '@components/PanicWidget';
import Popup from '@components/Popup';
import { Translate } from 'tg-core-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import responsibleGamingActions from 'tg-core-redux/lib/modules/responsible_gaming/action';
import withRouteData from '@utils/withRouteData';
import { loadContents } from '@actions/content';

import './style.css';

@withRouteData
@connect(
  state => ({
    sections: state.content.sections.data,
    sessionId: state.player.sessionId,
  }),
  dispatch => ({
    panic: bindActionCreators(responsibleGamingActions.panic, dispatch),
    signOut: () =>
      dispatch({ type: 'SIGN_OUT_START', payload: { panic: true } }),
  })
)
class PanicContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'section',
      identifiers: ['panic-top', 'panic-bottom'],
    },
  ]);

  state = {
    continueByButtonPress: false,
    disableButton: false,
  };

  PANIC_OPTIONS = [
    {
      panicChoice: 'Open',
    },
    {
      panicChoice: 'Pause',
    },
    {
      panicChoice: 'None',
    },
  ];

  componentDidMount() {
    // Opens popup and sends in "Open"
    this.props.panic(this.props.sessionId, this.PANIC_OPTIONS[0]);
  }

  componentWillUnmount() {
    const { sessionId } = this.props;

    // Close and send "None"
    if (sessionId && !this.state.continueByButtonPress) {
      this.props.panic(sessionId, this.PANIC_OPTIONS[2]);
    }
  }

  _handleOnPanic = () => {
    this.setState({ disableButton: true });

    // Press panic button and send in "Pause". When done we sign player out
    this.props
      .panic(this.props.sessionId, this.PANIC_OPTIONS[1])
      .then(() => this.props.signOut(this.props.sessionId));
  };

  _handleOnContinue = () => {
    // Variable used to prevent duplicate request calls when we unmount from component
    this.setState({ continueByButtonPress: true, disableButton: true });

    this.props
      .panic(this.props.sessionId, this.PANIC_OPTIONS[2])
      .then(() => (window.location.hash = ''));
  };

  render() {
    const { sections, isRouteDataLoading, location, match } = this.props;

    const topContent = sections?.find(i => i.identifier === 'panic-top');
    const bottomContent = sections?.find(i => i.identifier === 'panic-bottom');

    return (
      <Popup
        className="PanicContainer"
        title={
          <Translate id="label.spelpaus.header" defaultMessage="Spelpaus" />
        }
        showLoading={isRouteDataLoading}
        location={location}
        match={match}>
        <PanicWidget
          disable={this.state.disableButton}
          topContent={topContent}
          bottomContent={bottomContent}
          onPanic={this._handleOnPanic}
          onContinue={this._handleOnContinue}
        />
      </Popup>
    );
  }
}

export default PanicContainer;

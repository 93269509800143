import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';

export const setSocialSecurityNumber = ssn => {
  if (!window.localStorage) return false;

  localStorage.setItem('ssn', ssn);
};

export const getSocialSecurityNumber = () => {
  if (!window.localStorage) return '';

  return localStorage.getItem('ssn') ? localStorage.getItem('ssn') : '';
};

export const setRememberSocialSecurityNumber = shouldBeRemembered => {
  if (!window.localStorage) return false;

  localStorage.setItem(
    'remember-ssn',
    JSON.stringify({ value: shouldBeRemembered })
  );
};

export const getRememberSocialSecurityNumber = () => {
  if (!window.localStorage) return false;

  try {
    return localStorage.getItem('remember-ssn')
      ? JSON.parse(localStorage.getItem('remember-ssn')).value
      : false;
  } catch (err) {
    return false;
  }
};

export const getStorageParsedData = item => {
  if (!__CLIENT__) return;
  let data = localStorage.getItem(item);
  return (data = data ? JSON.parse(data) : {});
};

export const updateSignUpData = obj => {
  if (!__CLIENT__) return;

  const clone = cloneDeep(obj);

  for (const [key, value] of Object.entries(clone)) {
    if (value === '') {
      delete clone[key];
    }
  }

  localStorage.setItem(
    'signUpData',
    JSON.stringify(
      omit(clone, ['Password', 'confirm_password', 'AcceptTerms', 'PersonalId'])
    )
  );
};

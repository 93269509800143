import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const withVisibilityState = WrappedComponent => props => {
  const dispatch = useDispatch();
  useEffect(() => {
    const onVisibilityChange = () =>
      dispatch({
        type: 'SET_VISIBILITY_STATE',
        payload: document.visibilityState,
      });
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange);
  });
  return <WrappedComponent {...props} />;
};

export default withVisibilityState;

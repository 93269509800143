import React, { Component } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { closeHash } from '@utils/hash';
import { withOverlayRouter } from '@components/OverlayRouter';
import { withRouter } from 'react-router';
import getByPath from 'lodash/get';
import backArrow from '@images/back-arrow.svg';
import exitIcon from '@images/cancel.svg';

import './style.css';

const Header = ({ backUrl, overlayLocation, overlayMatch, preventClose }) => {
  const sections = useSelector(state => state.content.sections.data);
  const canvasHeader = sections?.find(s => s.identifier === 'canvas-header');

  return (
    <div
      className="canvas-header"
      style={{
        ...(canvasHeader?.backgroundImage && {
          backgroundImage: `url('${canvasHeader.backgroundImage.file.url}')`,
        }),
      }}>
      <div className="canvas-back">
        {backUrl && (
          <Link
            to={{
              ...(backUrl.startsWith('#')
                ? { hash: backUrl }
                : { pathname: backUrl }),
              state: { preventTransition: true },
            }}>
            <img src={backArrow} alt="back" />
          </Link>
        )}
      </div>
      {canvasHeader && (
        <img
          className="canvas-logo"
          src={canvasHeader.image?.file.url}
          alt={canvasHeader.image?.description || canvasHeader.image?.title}
        />
      )}
      <div className="canvas-close">
        {!preventClose && (
          <Link
            replace
            to={{
              hash:
                overlayLocation && overlayMatch
                  ? closeHash(overlayLocation.hash, overlayMatch.url)
                  : '',
            }}>
            <img src={exitIcon} alt="exit" />
          </Link>
        )}
      </div>
    </div>
  );
};

@withRouter
@withOverlayRouter
class OffCanvasLayout extends Component {
  state = {
    closed: true,
  };

  preventTransition =
    typeof getByPath(this.props.location, 'state.preventTransition') ===
      'boolean' && getByPath(this.props.location, 'state.preventTransition');

  componentDidMount() {
    const { onTransitionStart, onTransitionEnd } = this.props;

    // Open sidebar on next tick
    setTimeout(() => this.setState({ closed: false }), 1);

    if (this.preventTransition || (__CLIENT__ && window.isApp)) {
      const mockEvent = { target: { className: 'canvas-content' } };
      typeof onTransitionStart === 'function' && onTransitionStart(mockEvent);
      typeof onTransitionEnd === 'function' && onTransitionEnd(mockEvent);
    }
  }

  scrollToTop = () => {
    if (this.canvasContentRef && this.canvasContentRef.current) {
      this.canvasContentRef.current.scrollTo(0, 0);
    }
  };

  render() {
    const {
      className = '',
      children,
      direction = 'right',
      forwardRef,
      overlayLocation,
      overlayMatch,
      preventClose,
      onTransitionStart,
      onTransitionEnd,
    } = this.props;
    let { backUrl } = this.props;
    const { closed } = this.state;

    backUrl = this.props.location.state?.backUrl || backUrl;

    return (
      <div
        ref={ref => {
          this.wrapper = ref;
          forwardRef && forwardRef(ref);
        }}
        className={cn(className, 'OffCanvas', {
          'OffCanvas--direction-left': direction === 'left',
          'OffCanvas--direction-right': direction === 'right',
          'OffCanvas--closed': closed && !this.preventTransition,
          'OffCanvas-enter-done':
            this.preventTransition || (__CLIENT__ && window.isApp),
        })}>
        <div
          className="canvas-content"
          onTransitionStart={onTransitionStart}
          onTransitionEnd={onTransitionEnd}>
          <Header
            backUrl={backUrl}
            overlayLocation={overlayLocation}
            overlayMatch={overlayMatch}
            preventClose={preventClose}
          />
          {typeof children === 'function'
            ? children({ scrollToTop: this.scrollToTop })
            : children}
        </div>
        {preventClose ? (
          <div className="canvas-overlay" />
        ) : (
          <Link
            replace
            className="canvas-overlay"
            to={{
              hash:
                overlayLocation && overlayMatch
                  ? closeHash(overlayLocation.hash, overlayMatch.url)
                  : '',
            }}
          />
        )}
      </div>
    );
  }
}

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => (
  <OffCanvasLayout {...props} forwardRef={ref} />
));

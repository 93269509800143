import { combineEpics, ofType } from 'redux-observable';
import { showToast } from '@actions/toast';
import { getIntl } from '@utils/translations';
import { filter, map } from 'rxjs/operators';
import { getOffers } from 'tg-core-redux/lib/modules/bonus/action';
import { getWallets } from 'tg-core-redux/lib/modules/wallet/action';
import { getConfig } from '@config';

const preClaimBonusFailure = action$ =>
  action$.pipe(
    ofType('PRECLAIM_BONUS_COMPLETE'),
    map(() => ({
      type: 'PRECLAIM_BONUS_FAILURE',
      payload: {
        data: {
          Code: 404,
        },
      },
    }))
  );

const reloadBalanceWhenBonusIsTriggered = (action$, state$) =>
  action$.pipe(
    ofType('TRIGGER_BONUS_BY_PROMO_CODE_COMPLETE'),
    filter(action => action.payload === 1),
    map(() => {
      return [
        getWallets(state$.value.player.sessionId),
      ]
    })
  );

const showToastWhenBonusIsTriggered = (action$, state$) =>
  action$.pipe(
    ofType('TRIGGER_BONUS_BY_PROMO_CODE_COMPLETE'),
    filter(action => action.payload === 0),
    map(() => {
      return showToast({
        text: getIntl(state$.value).formatMessage({
          id: 'trigger.bonus.failure',
        }),
        toastType: 'ERROR',
      });
    })
  );

const refetchBonusOffersAfterPreclaim = (action$, state$) =>
  action$.pipe(
    ofType('CLAIM_BONUS_COMPLETE'),
    map(() => {
      const {
        player: { sessionId },
        app: { jurisdiction, ipCountry },
      } = state$.value;

      return getOffers(
        sessionId,
        getConfig(jurisdiction, ipCountry).bonusProvider
      );
    })
  );

export default combineEpics(
  preClaimBonusFailure,
  showToastWhenBonusIsTriggered,
  refetchBonusOffersAfterPreclaim,
  reloadBalanceWhenBonusIsTriggered
);

import React from 'react';
import cn from 'classnames';
import { Input, Button } from 'tg-core-components';
import { validateRules, minLength } from '../../validation';
import { getContinueLabel } from '../../helpers';
import translate from 'tg-core-components/lib/lib/utils/translate';

const BirthDate = ({
  /* Props from parent */
  errors,
  onChange,
  fields,
  nextStep,
  statuses,
  intl,

  /* Props from selector */
  device,
}) => {
  const isValid = statuses.BirthDate === 'success';

  return (
    <form onSubmit={nextStep}>
      <Input
        autoFocus
        type="birthdate"
        value={fields.BirthDate || ''}
        name="BirthDate"
        onChange={(_, BirthDate) => onChange({ BirthDate })}
        status={statuses.BirthDate}
        statusText={
          errors.BirthDate && translate({ id: errors.BirthDate }, intl)
        }
      />

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ BirthDate }) => {
  const rules = {
    BirthDate: [[minLength(9), 'error.empty.birthdate']],
  };
  const errors = await validateRules({ BirthDate }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  device: state.app.device,
});

export default {
  name: 'birth-date',
  icon: 'user',
  Component: BirthDate,
  validate,
  selector,
  fields: ['BirthDate'],
};

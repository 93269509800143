import React from 'react';
import cn from 'classnames';
import TopHeaderNavList from './TopHeaderNavList';
import LanguageSelector from '@pages/LanguageSelector';
import './style.css';

const TopHeader = ({
  topHeaderNav,
  topHeaderSecondNav,
  className,
  ...rest
}) => (
  <div {...rest} className={cn('TopHeader', className)}>
    <div className="TopHeader__nav">
      <TopHeaderNavList items={topHeaderNav?.items || []} />
    </div>
    <div className="TopHeader__second-nav">
      <TopHeaderNavList items={topHeaderSecondNav?.items || []} />
    </div>
    <div className="TopHeader__language">
      <LanguageSelector />
    </div>
  </div>
);

export default TopHeader;

import React from 'react';

export const initialState = {
  isBottomBarOpen: true,
  bottomBarCategory: null,
  isGameLaunched: false,
  isDarkMode: false,
  searchTerm: '',
  isSearchOpen: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'set-is-bottom-bar-open': {
      return { ...state, isBottomBarOpen: action.payload };
    }
    case 'set-bottom-bar-category': {
      return { ...state, bottomBarCategory: action.payload };
    }
    case 'set-is-game-launched': {
      return { ...state, isGameLaunched: action.payload };
    }
    case 'toggle-dark-mode': {
      return { ...state, isDarkMode: !state.isDarkMode };
    }
    case 'set-search-term': {
      return { ...state, searchTerm: action.payload };
    }
    case 'set-is-search-open': {
      return { ...state, isSearchOpen: action.payload };
    }
    default:
      return state;
  }
};

export const GamePlayContext = React.createContext();

/**
 * Actions
 */
export const setIsBottomBarOpen = payload => ({
  type: 'set-is-bottom-bar-open',
  payload,
});

export const setBottomBarCategory = payload => ({
  type: 'set-bottom-bar-category',
  payload,
});

export const setIsGameLaunched = payload => ({
  type: 'set-is-game-launched',
  payload,
});

export const setSearchTerm = payload => ({ type: 'set-search-term', payload });

export const setIsSearchOpen = payload => ({
  type: 'set-is-search-open',
  payload,
});

export const toggleDarkMode = () => ({ type: 'toggle-dark-mode' });

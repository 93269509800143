import React, { Component } from 'react';
import { connect } from 'react-redux';
import marked from 'marked';

import BasePage from '@pages/BasePage';
import withRouteData from '@utils/withRouteData';

import '@pages/ContentContainer/style.css';
import NavList from '@components/NavList';
import { selectTac } from '@utils/tac';

@withRouteData
class TermsContainer extends Component {
  constructor(props) {
    super(props);

    this.headers = {};
  }

  componentDidMount() {
    this.scrollIntoView();
  }

  componentDidUpdate() {
    this.scrollIntoView();
  }

  renderTermsAndConditions = () => {
    const { tac } = this.props;

    const set = tac.content;

    if (!set || !set.items) return null;

    return set.items.map((i, k) => (
      <div key={k}>
        <div className="ref" ref={ref => (this.headers[i.action] = ref)} />
        <div
          dangerouslySetInnerHTML={{
            __html: marked(i.content),
          }}
        />
      </div>
    ));
  };

  scrollIntoView() {
    if (this.props.location.hash) {
      let node = this.headers[this.props.location.hash];
      if (node) {
        node.scrollIntoView({
          block: 'start',
        });
      }
    }
  }

  render() {
    const { pages, match, menus } = this.props;
    let page = pages.find(p => '/' + p.url === match.url);
    if (!page) page = pages.find(p => p.identifier === 'terms-and-conditions');
    const menu = menus.find(m => m.identifier === 'side-content-menu');
    const hideSidebar =
      page && page.layout && page.layout.includes('No sidebar');

    return (
      <BasePage className="ContentContainer TermsContainer">
        <main className="container">
          <div className="content-container">
            <div
              className="content"
              style={{
                backgroundImage: `url('${
                  page.background ? page.background.file.url : ''
                }')`,
              }}>
              {!hideSidebar && menu && <NavList menu={menu} />}
              <div>
                {page.content && (
                  <div className="actual-content">
                    <React.Fragment
                      dangerouslySetInnerHTML={{ __html: marked(page.content) }}
                    />
                    {this.renderTermsAndConditions()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </BasePage>
    );
  }
}

export default connect(state => ({
  pages: state.content.pages.data,
  sets: state.content.sets.data,
  menus: state.content.menus.data,
  tac: selectTac(state),
}))(TermsContainer);

import React from 'react';
import RouterContext from './RouterContext';
import { matchPath } from 'react-router';

const Switch = ({ children }) => {
  const context = React.useContext(RouterContext);
  const [matches, setMatches] = React.useState([]);

  React.useEffect(() => {
    const _matches = [];
    context.location.hash
      .replace(/^#/, '')
      .split(',')
      .forEach(h => {
        children.forEach(route => {
          const overlayMatch = matchPath(h, {
            path: route.props.path.replace(/^\//, ''),
            exact: route.props.exact,
            strict: route.props.strict,
          });

          if (
            overlayMatch &&
            // When we introduced content popups it will match on the specific route
            // ex. "/popup/terms-and-conditions" but also "/popup/*" showing it twice.
            // This fix filters out any overlay matches already included in the array.
            // Since we only want to show TAC for example using the specific component
            // and not the dynamic content popup.
            !_matches.some(m => m.props.overlayMatch.url === overlayMatch.url)
          ) {
            _matches.push({
              route,
              props: {
                overlayLocation: {
                  ...context.location,
                  state: route.props.state || null,
                },
                overlayMatch,
              },
            });
          }
        });
      });

    setMatches(_matches);
    context.setMatches(_matches.map(m => m.props.overlayMatch));

    // I don't know what else to do! :(
    // eslint-disable-next-line
  }, [context.location.hash]);

  return matches.map(m => renderMatch(m));
};

const renderMatch = ({ route, props }) => {
  return route.props.component
    ? React.createElement(route.props.component, props)
    : route.props.render
    ? route.props.render(props)
    : route.props.children;
};

export default Switch;

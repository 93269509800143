import ExternalAuthPnp from '@components/ExternalAuthPnp';
import selectCurrency from '@selectors/selectCurrency';
import { useConfig } from '@utils/config';
import { selectTacVersion } from '@utils/tac';
import marked from 'marked';
import queryString from 'query-string';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

const Auth = ({
  /* Props from parent */
  fields,
  onError,
  onSuccess,
  refUrl,
  pendingOptin,

  /* Props from selector */
  currency,
  operatingSystem,
  browser,
  jurisdiction,
  ip,
  device,
  locale,
  ipCountry,
  sections,
}) => {
  const config = useConfig();
  const [cookies] = useCookies([config.btagCookie]);
  const tacVersion = useSelector(state => selectTacVersion(state));

  const stepContent = sections?.find(
    s => s.identifier === 'header.paynplay.auth'
  )?.content;

  const attributes = {
    skinId: config.skinId,
    currency: currency,
    language: locale ? locale.split('-')[0] : 'en',
    jurisdiction,
    affiliateTag: cookies[config.btagCookie],
    clientIp: ip,
    platform: device,
    operatingSystem,
    browser,
    aleaccBonusCode: fields.BonusCode,
    tacVersionAccepted: tacVersion,
    limitsString: JSON.stringify(fields.Limits),
  };

  const transformedAttributes = Object.keys(attributes).reduce((acc, item) => {
    acc[`attributes[${item}]`] = attributes[item];

    return acc;
  }, {});

  const authConfig = {
    locale: locale.replace('-', '_'),
    amount: Number(fields.Amount) || null,
    currency: currency,
    country: ipCountry,
    currentTac: tacVersion,
    ...transformedAttributes,
  };

  return (
    <div className="Auth">
      {stepContent && (
        <div
          dangerouslySetInnerHTML={{ __html: marked(stepContent) }}
          className="Auth__content"
        />
      )}

      <ExternalAuthPnp
        config={authConfig}
        operatingSystem={operatingSystem}
        device={device}
        onError={onError}
        onSuccess={onSuccess}
        params={queryString.stringify({ refUrl, pendingOptin })}
      />
    </div>
  );
};

const selector = state => ({
  sections: state.content.sections.data,
  locale: state.app.locale,
  jurisdiction: state.app.jurisdiction,
  ip: state.app.ip,
  ipCountry: state.app.ipCountry,
  device: state.app.device,
  operatingSystem: state.app.operatingSystem,
  browser: state.app.browser,
  currency: selectCurrency(state),
});

export default {
  name: 'auth',
  Component: Auth,
  selector,
};

import React, { PureComponent, Fragment } from 'react';
import marked from 'marked';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router';
import BonusStep from './BonusStep';
import UserInfoStep from './UserInfoStep';
import ContactInfoStep from './ContactInfoStep';
import SetLimitsStep from './SetLimitsStep';
import ConfirmContactInfoStep from './ConfirmContactInfoStep';
import ActivationStep from './ActivationStep';
import ExternalAuthStep from './ExternalAuthStep';
import { GTM_EVENTS } from '@utils/google-tag-manager';
import BonusBar from '@components/BonusBar';
import isEqual from 'lodash/isEqual';

import './SignUp.css';

const STEPS = {
  SET_BONUS: 1,
  USER_INFORMATION: 2,
  CONTACT_INFORMATION: 3,
  SET_LIMITS: 4,
  CONFIRM_INFORMATION: 5,
  ACTIVATION: 6,
  EXTERNAL_AUTH: 7,
};

@withRouter
@withCookies
class SignUp extends PureComponent {
  constructor(props) {
    super(props);
    const { bonuses = [], cookies } = props;
    this.state = {
      currentStep:
        bonuses?.length > 1 &&
        !cookies.get('bonusCodeSignUp') &&
        !cookies.get('bonusCodeDeposit')
          ? STEPS.SET_BONUS
          : STEPS.USER_INFORMATION,
      submitData: {
        MobilePhoneNumber:
          this.props.ipCountry.callingCode &&
          `00${this.props.ipCountry.callingCode}`,
        Country: this.props.ipCountry.value,
        Currency: this.props.ipCountry.currency,
        Language: this.props.locale.includes('-')
          ? this.props.locale.split('-')[0]
          : this.props.locale,
        Gender: 'Male',
        Limits: [],
      },
      currentStatus: 'resend',
    };
  }

  componentDidMount() {
    this.props.pushGtmEvent({ event: GTM_EVENTS.SIGNUP_INIT });
    this.pushGtmEvents();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bonuses = [], cookies, scrollToTop } = this.props;

    if (
      !isEqual(prevProps.bonuses, bonuses) &&
      this.state.currentStep === STEPS.USER_INFORMATION
    ) {
      if (bonuses.length === 1) {
        const bonusType =
          bonuses[0].bonusType === 'signup'
            ? 'bonusCodeSignUp'
            : 'bonusCodeDeposit';
        cookies.set(bonusType, bonuses[0].bonusCode, {
          expires: new Date(Date.now() + 31556952000),
        });
      }

      if (
        bonuses.length > 1 &&
        !cookies.get('bonusCodeSignUp') &&
        !cookies.get('bonusCodeDeposit')
      )
        this.setState({ currentStep: STEPS.SET_BONUS });
    }

    if (prevState.currentStep !== this.state.currentStep) {
      scrollToTop();
    }

    if (this.state.currentStep !== prevState.currentStep) {
      this.pushGtmEvents();
    }
  }

  pushGtmEvents = () => {
    const { pushGtmEvent } = this.props;
    const { currentStep } = this.state;
    switch (currentStep) {
      case STEPS.SET_BONUS:
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_STEP_BONUS });
        break;
      case STEPS.USER_INFORMATION:
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_STEP_USER_INFORMATION });
        break;
      case STEPS.CONTACT_INFORMATION:
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_STEP_CONTACT_INFORMATION });
        break;
      case STEPS.SET_LIMITS:
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_STEP_LIMITS });
        break;
      case STEPS.CONFIRM_INFORMATION:
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_STEP_CONFIRM_INFORMATION });
        break;
      case STEPS.ACTIVATION:
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_STEP_ACTIVATION });
        break;
      case STEPS.EXTERNAL_AUTH:
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_STEP_EXTERNAL_AUTH });
        break;
    }
  };

  render() {
    const { currentStep } = this.state;
    const header = this.props[`header${currentStep}`];

    return (
      <div>
        <div className="signup-step-wrapper">
          {header && header.content && (
            <header
              dangerouslySetInnerHTML={{ __html: marked(header.content) }}
            />
          )}
        </div>
        {this._generateContent()}
      </div>
    );
  }

  _generateContent = () => {
    const {
      cookies,
      externalAuthClientId,
      externalAuthRedirectUrl,
      externalAuthProvider,
      externalAuthUiFriendly,
      device,
      locale,
      isLoading,
      bonuses,
      selectedBonus,
      promotions,
      signUpIssues,
      responsibleGamingContent,
      jurisdiction,
    } = this.props;
    const { currentStep, submitData, previousStep } = this.state;

    switch (currentStep) {
      case STEPS.SET_BONUS:
        return (
          <BonusStep
            bonuses={bonuses}
            selected={selectedBonus}
            {...submitData}
            onSubmitBonus={this.onSetBonusSubmit}
            onSubmitForm={this.onSetBonusSubmit}
            onSkip={e => {
              e.preventDefault();
              cookies.remove('bonusCodeSignUp');
              cookies.remove('bonusCodeDeposit');
              this._handlePreviousStep(previousStep || STEPS.USER_INFORMATION);
            }}
          />
        );
      case STEPS.USER_INFORMATION:
        return (
          <Fragment>
            <UserInfoStep
              {...submitData}
              {...this.props}
              onSubmitForm={this.onUserInfoSubmit}
              updateSubmitData={this.updateSubmitData}
            />
            <BonusBar
              promotions={promotions}
              onClick={() =>
                this.setState(() => ({
                  currentStep: STEPS.SET_BONUS,
                  previousStep: STEPS.USER_INFORMATION,
                }))
              }
            />
          </Fragment>
        );

      case STEPS.CONTACT_INFORMATION:
        return (
          <Fragment>
            <ContactInfoStep
              {...submitData}
              {...this.props}
              onSubmitForm={this.onContactInfoSubmit}
              onGoBack={() => this._handlePreviousStep(STEPS.USER_INFORMATION)}
              updateSubmitData={this.updateSubmitData}
            />
            <BonusBar
              promotions={promotions}
              onClick={() =>
                this.setState(() => ({
                  currentStep: STEPS.SET_BONUS,
                  previousStep: STEPS.CONTACT_INFORMATION,
                }))
              }
            />
          </Fragment>
        );

      case STEPS.SET_LIMITS: {
        const onUpdate = ({ limits }) => {
          this.setState({
            submitData: {
              ...this.state.submitData,
              Limits: limits,
            },
          });
        };

        return (
          <Fragment>
            <SetLimitsStep
              limits={submitData.Limits || []}
              content={responsibleGamingContent}
              onUpdate={onUpdate}
              onSubmit={() => this._handleNextStep(STEPS.CONFIRM_INFORMATION)}
              onGoBack={() =>
                this._handlePreviousStep(STEPS.CONTACT_INFORMATION)
              }
              currency={submitData.Currency || 'EUR'}
              jurisdiction={jurisdiction}
            />
            <BonusBar
              promotions={promotions}
              onClick={() =>
                this.setState(() => ({
                  currentStep: STEPS.SET_BONUS,
                  previousStep: STEPS.SET_LIMITS,
                }))
              }
            />
          </Fragment>
        );
      }

      case STEPS.CONFIRM_INFORMATION:
        return (
          <ConfirmContactInfoStep
            {...this.props}
            email={submitData.Email}
            phone={submitData.MobilePhoneNumber}
            fullname={`${submitData.FirstName} ${submitData.LastName}`}
            birthdate={submitData.BirthDate}
            address={submitData.Address1}
            zip={submitData.Zip}
            city={submitData.City}
            country={submitData.Country}
            optInMarketing={submitData.OptInMarketing}
            currency={submitData.Currency}
            nationality={submitData.Nationality}
            SupportNumber={submitData.SupportNumber}
            gender={submitData.Gender}
            limits={submitData.Limits}
            onSubmitForm={this.onConfirmContactInfoSubmit}
            onGoBack={() =>
              ['uk', 'mga', 'sga'].includes(this.props.jurisdiction)
                ? this._handlePreviousStep(STEPS.SET_LIMITS)
                : this.props.jurisdiction === 'es'
                ? this._handlePreviousStep(STEPS.SPAIN)
                : this._handlePreviousStep(STEPS.CONTACT_INFORMATION)
            }
            limitsData={submitData.Limits}
            isLoading={isLoading}
          />
        );

      case STEPS.ACTIVATION:
        return (
          <ActivationStep
            phone={submitData.MobilePhoneNumber}
            onSubmitForm={this.onActivationSubmit}
            resendActivateCode={this._handleResendActivateCode}
            isLoading={isLoading}
            currentStatus={this.state.currentStatus}
            signUpIssues={signUpIssues}
          />
        );

      case STEPS.EXTERNAL_AUTH:
        return (
          <ExternalAuthStep
            locale={locale}
            device={device}
            ssn={submitData.PersonId}
            clientId={externalAuthClientId}
            redirectUrl={externalAuthRedirectUrl}
            provider={externalAuthProvider}
            uiFriendly={externalAuthUiFriendly}
            onSubmit={this.onExternalAuthSubmit}
          />
        );
    }
  };

  // Bonus form
  onSetBonusSubmit = data => {
    let { PromoCode } = data;

    const bonusData = {
      PromoCode: PromoCode,
    };

    this.props.handleSubmitBonus(bonusData).then(() => {
      this.setState({
        submitData: { ...this.state.submitData, ...bonusData },
      });
      if (this.state.previousStep) {
        this._handleNextStep(this.state.previousStep);
      } else {
        this._handleNextStep(STEPS.USER_INFORMATION);
      }
    });
  };

  // User information form
  onUserInfoSubmit = () => {
    this.props.handleSubmitUserInfo(this.state.submitData).then(() => {
      this._handleNextStep(STEPS.CONTACT_INFORMATION);
    });
  };

  // Contact information form
  onContactInfoSubmit = data => {
    let { BirthDate, ...others } = data;
    let { jurisdiction } = this.props;

    const step2Data = {
      ...others,
      BirthDate: BirthDate,
    };

    this.props
      .handleSubmitContactInfo({
        ...step2Data,
      })
      .then(() => {
        this.setState({
          submitData: { ...step2Data, ...this.state.submitData },
        });

        // Skip limits step for Curacao
        this._handleNextStep(
          jurisdiction === 'curacao'
            ? STEPS.CONFIRM_INFORMATION
            : STEPS.SET_LIMITS
        );
      });
  };

  _handleSetLimits = data => {
    let limits = this.state.submitData.Limits;

    if (!limits) limits = [];

    let limit = limits.find(
      l => l.Type === data.Type && l.Timespan === data.Timespan
    );

    // Remove limit that already exists
    if (limit) limits.splice(limits.indexOf(limit), 1);

    // Only allow numbers
    const validAmount = new RegExp('^[0-9]+$');
    if (!validAmount.test(data.Amount)) return null;

    // Add new limit to the array
    limits = [...limits, data].map((l, k) => ({
      ...l,
      Id: k,
      Status: 'Active',
    }));

    // Update state with new limit
    this.setState({
      submitData: {
        ...this.state.submitData,
        Limits: limits,
      },
    });
  };

  _handleCancelLimit = id => {
    const { submitData } = this.state;
    const d = submitData.Limits.filter(limit => limit.Id !== id);

    this.setState({
      submitData: {
        ...this.state.submitData,
        Limits: d,
      },
    });
  };

  // Game limit setting form
  onSetLimitsSubmit = e => {
    e.preventDefault();
    let { jurisdiction } = this.props;

    this._handleNextStep(
      jurisdiction === 'sga' ? STEPS.EXTERNAL_AUTH : STEPS.CONFIRM_INFORMATION
    );
  };

  // Confirm information form
  onConfirmContactInfoSubmit = step3Data => {
    let { OptInMarketing, ...other } = this.state.submitData;

    // Transform provided phone number on confirmation
    step3Data = {
      ...other,
      OptIns: OptInMarketing
        ? ['NewsEmails', 'BonusEmails', 'NewsSMSs', 'BonusSMSs', 'Phone']
        : [],
    };

    this.props.handleConfirmContactInfo(step3Data).then(() => {
      // If there is an 409 error, start again from step 1
      if (this.props.signUpError.hasError) {
        this.setState(() => ({
          currentStep: STEPS.USER_INFORMATION,
          submitData: { ...other },
        }));
      } else if (this.props.disableActivation) {
        window.location.hash = '';
      } else {
        this.setState({ submitData: { ...step3Data } });
        this._handleNextStep(STEPS.ACTIVATION);
      }
    });
  };

  // Activate account form
  onActivationSubmit = data => {
    this.props.handleSubmitActivationCode(data);
  };

  onExternalAuthSubmit = data => {
    const { submitData } = this.state;
    const { jurisdiction } = this.props;

    this.setState({
      submitData: {
        ...submitData,
        ...data,
      },
    });

    if (['sga', 'dga'].includes(jurisdiction.toLowerCase())) {
      return this.props
        .handleConfirmContactInfo({
          ...submitData,
          ...data,
          MobilePhoneNumber: `00${submitData.MobilePhoneNumber}`,
          OptIns: submitData.OptInMarketing
            ? ['NewsEmails', 'BonusEmails', 'NewsSMSs', 'BonusSMSs', 'Phone']
            : [],
        })
        .then(() => {
          // If there is an 409 error, start again from step 1
          if (this.props.signUpError.hasError) {
            this.setState(() => ({
              currentStep: STEPS.USER_INFORMATION,
              submitData: {
                Limits: [],
              },
            }));
          } else {
            window.location.hash = '';
          }
        });
    }

    this._handleNextStep(STEPS.CONFIRM_INFORMATION);
  };

  _handleNextStep = step => {
    const currentStep = step;
    this.setState({
      currentStep,
    });
    if (step === STEPS.CONFIRM_INFORMATION && this.props.clearErrors) {
      this.props.clearErrors();
    }
  };

  _handlePreviousStep = currentStep => {
    this.setState({
      currentStep,
    });
  };

  updateSubmitData = (e, name, value) => {
    this.setState(prevState => ({
      submitData: {
        ...prevState.submitData,
        [name]: value,
      },
    }));
  };

  _handleResendActivateCode = () => {
    this.setState({
      currentStatus: 'sending',
    });

    this.props.resendActivationCode(this.state.submitData.Email).then(() => {
      this.setState({
        currentStatus: 'sent',
      });
    });
  };
}

export default SignUp;

const initialState = {
  status: null,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PAYMENT_SUCCESS':
      return {
        status: 'success',
        data: action.payload,
      };

    case 'PAYMENT_FAILURE':
      return {
        status: 'failed',
        data: action.payload,
      };

    case 'PAYMENT_PENDING':
      return {
        status: 'pending',
        data: action.payload,
      };

    case 'RESET_PAYMENT':
      return {
        status: null,
        data: null,
      };

    default:
      return state;
  }
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Route, Switch, withRouter } from 'react-router';
import routes from '../routes';
import merge from 'lodash/merge';
import Toast from '@components/Toast';
import { withConfig } from '@config';
import { loadScript } from '@utils/script';
import { initChat } from '@utils/chat';
import hashActions from '@utils/hashActions';
import realitycheck from '@utils/realitycheck';
import getHeadScript from '@utils/getHeadScript';
import { hideToast } from '@actions/toast';
import { shouldClientRedirect } from '@utils/shouldRedirect';
import { getNonAcceptedAgreements } from '@utils/agreements';
import playerPropertiesAction from 'tg-core-redux/lib/modules/player_properties/action';
import {
  hideNotification,
  removeNotification,
} from 'tg-core-redux/lib/modules/notification/action';
import { Notification, Loader } from 'tg-core-components';
import withVisibilityState from '@components/hoc/withVisibilityState';

import '@styles/utilities.css';
import '@theme/skin.css';

@withVisibilityState
@withRouter
@withConfig
@injectIntl
@connect(
  state => ({
    isServerSideRender: state.app.isServerSideRender,
    isBootstrapping: state.app.isBootstrapping,
    isShowingToast: state.toast.isShowingToast,
    isShowingLoading: state.app.showGlobalLoader,
    realityCheckSession: state.responsible_gaming.session,
    notifications: state.notification,
    locale: state.app.locale,
    user: state.player.user,
    playerProperties: state.playerProperties.properties,
    sessionId: state.player.sessionId,
    isAuthenticated: state.player.isAuthenticated,
    jurisdiction: state.app.jurisdiction,
    toast: state.toast,
    ipCountry: state.app.ipCountry,
    agreements: state.content.sets.data?.find(
      s => s.identifier === 'agreements'
    ),
  }),
  {
    removeNotification,
    hideNotification,
    hideToast,
    setPlayerProperties: playerPropertiesAction.setProperties,
  }
)
class App extends Component {
  componentDidMount = () => {
    const {
      isAuthenticated,
      jurisdiction,
      intl,
      config,
      locale,
      user,
      playerProperties,
      setPlayerProperties,
      sessionId,
      ipCountry,
      agreements,
    } = this.props;

    hashActions.init();

    if (jurisdiction && ipCountry && !isAuthenticated) {
      const headScript = getHeadScript(jurisdiction, ipCountry);
      loadScript(headScript.src, headScript.id || 'chat').then(() => {
        initChat({
          intl,
          config: config.freshChat,
          locale,
          user,
          playerProperties,
          setPlayerProperties,
          sessionId,
          jurisdiction,
          ipCountry,
        });
      });
    }
    this.checkForAgreements(agreements);
  };

  componentDidUpdate = prevProps => {
    const {
      realityCheckSession,
      isAuthenticated,
      jurisdiction,
      intl,
      config,
      locale,
      user,
      playerProperties,
      setPlayerProperties,
      sessionId,
      location,
      history,
      agreements,
      ipCountry,
    } = this.props;

    const req = {
      path: location.pathname,
      query: location.search,
    };

    if (location !== prevProps.location) {
      config.redirects.forEach(route => {
        if (shouldClientRedirect(route, location)) {
          history.replace(route.target(req));
        }
      });
    }

    if (
      (user !== prevProps.user ||
        ipCountry !== prevProps.ipCountry ||
        jurisdiction !== prevProps.jurisdiction) &&
      ipCountry &&
      jurisdiction
    ) {
      const headScript = getHeadScript(jurisdiction, ipCountry);
      loadScript(headScript.src, headScript.id || 'chat').then(() => {
        initChat({
          intl,
          config: config.freshChat,
          locale,
          user,
          playerProperties,
          setPlayerProperties,
          sessionId,
          jurisdiction,
          ipCountry,
        });
      });
    }

    if (
      realityCheckSession &&
      (realityCheckSession !== prevProps.realityCheckSession ||
        isAuthenticated !== prevProps.isAuthenticated ||
        jurisdiction !== prevProps.jurisdiction)
    ) {
      realitycheck.init(
        realityCheckSession || {},
        isAuthenticated,
        jurisdiction
      );
    }

    if (
      agreements &&
      // if agreements were updated on contentful (or we just fetched them for the first time)
      agreements !== prevProps.agreements
    )
      this.checkForAgreements(agreements);
  };

  componentWillUnmount() {
    hashActions.terminate();
  }

  checkForAgreements = agreements => {
    const acceptedAgreements = JSON.parse(
      (__CLIENT__ && window.localStorage.getItem('agreements')) || '[]'
    );
    const agreementsToAccept = getNonAcceptedAgreements({
      agreements: agreements?.items,
      acceptedAgreements,
    });
    if (agreementsToAccept.length)
      __CLIENT__ &&
        window.routerHistory.push(
          { hash: '#popup/agreements' },
          { agreementsToAccept }
        );
  };

  render() {
    const {
      jurisdiction,
      ipCountry,
      isBootstrapping,
      isServerSideRender,
      isShowingToast,
      isShowingLoading,
      notifications,
      removeNotification,
      hideNotification,
      toast,
      hideToast,
    } = this.props;

    if (!isServerSideRender && isBootstrapping) return null;

    return (
      <Fragment>
        <Switch>
          {routes(jurisdiction, ipCountry).map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={({ match, location, ...rest }) => {
                  if (match) {
                    location.state = merge(location.state, {
                      data: route.data,
                    });

                    return React.createElement(route.component, {
                      ...rest,
                      match,
                      location,
                      loadData: () => {
                        if (!route.loadData) {
                          return console.warn(
                            `Route ${route.path}: please add loadData function to routes.js`
                          );
                        }

                        return route.loadData(
                          match,
                          location
                        )(window.reduxStore);
                      },
                    });
                  }
                }}
              />
            );
          })}
        </Switch>
        {isShowingToast && <Toast toast={toast} hideToast={hideToast} />}
        {isShowingLoading && <Loader />}
        {notifications && (
          <Notification
            removeNotification={removeNotification}
            hideNotification={hideNotification}
            {...notifications[0]}
          />
        )}
      </Fragment>
    );
  }
}

export default App;

import React from 'react';
import cn from 'classnames';
import { Icon, Translate } from 'tg-core-components';

import './style.css';

const PasswordFeedback = ({ password = '' }) => {
  const hasCapitalLetter = password.match(/[A-Z]+/);
  const hasLowercaseLetter = password.match(/[a-z]+/);
  const hasDigit = password.match(/[0-9]+/);
  const hasSpecialCharacter = password.match(
    /[\s!"#\$%&'\(\)\*\+,\-\.\/:;<>\?@\[\\\]\^_`{|}~]/
  );
  const hasMinimumLength = password.match(/.{8,}/);

  return (
    <div className="PasswordFeedback">
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': hasCapitalLetter,
        })}>
        <Icon icon="check-square" />{' '}
        <Translate
          id="label.password-feedback.capital"
          defaultMessage="Capital letter"
        />
      </div>
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': hasLowercaseLetter,
        })}>
        <Icon icon="check-square" />{' '}
        <Translate
          id="label.password-feedback.lowercase"
          defaultMessage="Lowercase letter"
        />
      </div>
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': hasDigit,
        })}>
        <Icon icon="check-square" />{' '}
        <Translate id="label.password-feedback.digit" defaultMessage="Digit" />
      </div>
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': hasSpecialCharacter,
        })}>
        <Icon icon="check-square" />{' '}
        <Translate
          id="label.password-feedback.special"
          defaultMessage="Special character"
        />
      </div>
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': hasMinimumLength,
        })}>
        <Icon icon="check-square" />{' '}
        <Translate
          id="label.password-feedback.minimum-length"
          defaultMessage="Minimum length"
        />
      </div>
    </div>
  );
};

export default PasswordFeedback;

import selectRequiredDisplayPages from '@selectors/selectRequiredDisplayPages';
import { selectTac } from '@utils/tac';
import { REQUIRED_ACTIONS } from '@utils/player';

const selectRequiredActions = state => {
  const { player } = state;

  const requiredActions = selectRequiredDisplayPages(state);

  const acceptedTac = player.acceptedTacVersion?.toLowerCase();
  const currentTac = selectTac(state);

  if (player.hasLoginBlock) {
    requiredActions.push(REQUIRED_ACTIONS.LOGIN_BLOCK.name);
  }

  // Check for session ID since this trigger on sign-in failure and we don't want to prompt for TAC.
  if (
    player?.sessionId &&
    currentTac.version &&
    acceptedTac != currentTac.version
  ) {
    requiredActions.push(REQUIRED_ACTIONS.ACCEPT_TAC.name);
  }

  return requiredActions;
};

export default selectRequiredActions;

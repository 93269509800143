const initialState = {
  livecasino: null,
  jackpots: null,
  recentWins: [],
  topGames: {
    popular: [],
    wager: [],
    wagerUWC: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_JACKPOTS_COMPLETE':
      return {
        ...state,
        jackpots: action.payload,
      };

    case 'GET_TOP_GAMES_COMPLETE':
      return {
        ...state,
        topGames: {
          ...state.topGames,
          popular: action.payload,
        },
      };

    case 'GET_TOP_GAMES_WAGER_COMPLETE':
      return {
        ...state,
        topGames: {
          ...state.topGames,
          wager: action.payload,
        },
      };

    case 'GET_TOP_GAMES_UWC_COMPLETE':
      return {
        ...state,
        topGames: {
          ...state.topGames,
          wagerUWC: action.payload,
        },
      };

    case 'GET_LIVE_CASINO_COMPLETE':
    case 'GET_LIVE_CASINO_V2_COMPLETE':
      return {
        ...state,
        livecasino: action.payload,
      };

    case 'GET_RECENT_WINS_COMPLETE':
    case 'GET_RECENT_WIN_BY_ID_COMPLETE':
      return {
        ...state,
        recentWins: action.payload,
      };

    default:
      return state;
  }
};

import React from 'react';
import { FormattedRelativeTime } from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';
import { Translate } from 'tg-core-components';

const Countdown = ({ startDate, endDate, isLoading = false }) => {
  if (!startDate || !endDate || isLoading) return <div>...</div>;

  const startDateWithTimezone = new Date(`${startDate}+00:00`);
  const endDateWithTimezone = new Date(`${endDate}+00:00`);
  const currentDate = new Date().getTime();

  return (
    <div className="Countdown">
      {currentDate < startDateWithTimezone.getTime() ? (
        <Translate
          id="message.tournament-time-start"
          defaultMessage="Starts {time}"
          values={{
            time: (
              <FormattedRelativeTime {...selectUnit(startDateWithTimezone)} />
            ),
          }}
        />
      ) : currentDate > endDateWithTimezone.getTime() ? (
        <Translate
          id="message.tournament-time-ended"
          defaultMessage="Ended {time}"
          values={{
            time: (
              <FormattedRelativeTime {...selectUnit(endDateWithTimezone)} />
            ),
          }}
        />
      ) : (
        <Translate
          id="message.tournament-time-end"
          defaultMessage="Ends {time}"
          values={{
            time: (
              <FormattedRelativeTime {...selectUnit(endDateWithTimezone)} />
            ),
          }}
        />
      )}
    </div>
  );
};

export default Countdown;

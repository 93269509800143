import React from 'react';
import { Translate, Button } from 'tg-core-components';
import { startChat } from '@utils/chat';
import marked from 'marked';

import './style.css';

const Blocked = ({
  /* Props from parent */
  errors,

  /* Props from selector */
  jurisdiction,
}) => {
  return (
    <div className="Blocked">
      <div
        className="Blocked__content"
        dangerouslySetInnerHTML={{
          __html: marked(errors.Blocked || ''),
        }}
      />

      <Button
        className="button primary"
        onClick={e => {
          e.preventDefault();
          startChat(jurisdiction);
        }}>
        <Translate id="start.live-chat" defaultMessage="Start chat" />
      </Button>
    </div>
  );
};

const selector = state => ({
  jurisdiction: state.app.jurisdiction,
});

export default {
  name: 'blocked',
  selector,
  Component: Blocked,
};

import React, { Component, useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import BasePage from '@pages/BasePage';
import withRouteData from '@utils/withRouteData';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import { showToast } from '@actions/toast';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { intersectionBy, toLower } from 'lodash';
import { Input, Button, Translate } from 'tg-core-components';
import Loader from '@components/Loader';
import translate from 'tg-core-components/lib/lib/utils/translate';

import './style.css';

/**
 * @component ManageSubscriptions
 * @description Manages the subscriptions. You can choose which subscriptions you want to remove/keep.
 */
@injectIntl
@connect(
  state => ({
    optInOut: state.optInOut,
    sets: state.content.sets.data,
  }),
  dispatch => ({
    showToast: bindActionCreators(showToast, dispatch),
    optOutChoices: bindActionCreators(playerActions.optOutChoices, dispatch),
    getOptOutInsByToken: bindActionCreators(
      playerActions.getOptOutInsByToken,
      dispatch
    ),
  })
)
class ManageSubscriptions extends Component {
  componentDidMount() {
    this.props.getOptOutInsByToken(this.props.token);
  }

  onSubmit(handledSubscriptions) {
    const { intl } = this.props;
    const { token } = queryString.parse(this.props.location.search);
    const optInOut = handledSubscriptions
      .map(sub => sub.types)
      .reduce((acc, sub) => {
        return [...acc, ...sub];
      });

    return this.props
      .optOutChoices(
        token,
        optInOut.filter(o => o.OptIn).map(o => o.Type)
      )
      .then(() => {
        this.props.showToast({
          text: translate({ id: 'label.subscription-successful' }, intl),
          toastType: 'SUCCESS',
        });
      });
  }

  render() {
    const { optInOut, sets } = this.props;
    let subscriptions = sets.find(s => s.identifier === 'subscriptions');

    return (
      <Subscriptions
        optInOut={optInOut}
        subscriptions={subscriptions}
        onSubmit={this.onSubmit.bind(this)}
        sets={sets}
      />
    );
  }
}

const Subscriptions = ({ optInOut, subscriptions, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mappedSubscriptions, setMappedSubscriptions] = useState([]);

  const SubscribeItem = ({ subscribe, onChange }) => (
    <div className="subscriptions__checkboxes__item">
      <Input
        type="checkbox"
        label={subscribe.content}
        onChange={() => onChange(subscribe)}
        value={subscribe.types.some(optInOut => optInOut.OptIn)}
      />
    </div>
  );

  useEffect(() => {
    const checkTypes = types => {
      const intersection = intersectionBy(
        types,
        optInOut.data.map(d => d.Type),
        toLower
      ).map(toLower);

      return optInOut.data.filter(o =>
        intersection.includes(o.Type.toLowerCase())
      );
    };

    if (subscriptions && optInOut.data) {
      setMappedSubscriptions(
        subscriptions.items.map(sub => ({
          content: sub.content,
          types: checkTypes(sub.action.split(',').map(action => action.trim())),
        }))
      );
      setIsLoading(false);
    }
  }, [subscriptions, optInOut, setMappedSubscriptions, setIsLoading]);

  return (
    <div className="subscriptions">
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="subscriptions__checkboxes">
            {(mappedSubscriptions || []).map((subscribe, i) => (
              <SubscribeItem
                subscribe={subscribe}
                onChange={() => {
                  subscribe.types.map(type => (type.OptIn = !type.OptIn));
                }}
                key={i}
              />
            ))}
          </div>
          <Button
            className="button primary"
            onClick={() => onSubmit(mappedSubscriptions)}>
            <Translate
              id="action.apply-subscriptions"
              defaultMessage="Submit"
            />
          </Button>
        </Fragment>
      )}
    </div>
  );
};

/**
 * @component UnsubscribeAll
 * @description Unsubscribes the user from everything
 */
@injectIntl
@connect()
class UnsubscribeAll extends Component {
  state = { isDone: false };

  async UNSAFE_componentWillMount() {
    const { token, intl } = this.props;

    try {
      await this.props.dispatch(playerActions.optOutChoices(token, []));
      this.setState({ isDone: true });

      this.props.dispatch(
        showToast({
          text: translate({ id: 'label.subscription-successful' }, intl),
          toastType: 'SUCCESS',
        })
      );
    } catch (error) {
      console.log('Error', error);
    }
  }

  render() {
    const { isDone } = this.state;
    const { intl } = this.props;

    return (
      <p>
        {translate(
          {
            id: isDone ? 'optin.unsubscribe-all' : 'optin.unsubscribing-all',
          },
          intl
        )}
      </p>
    );
  }
}

/**
 * @component SubscriptionContainer
 * @description Manages which component the container should render
 */
@withRouter
@withRouteData
class SubscriptionContainer extends Component {
  render() {
    const { token } = queryString.parse(this.props.location.search);

    return (
      <BasePage className="SubscriptionContainer">
        <div className="subscription-wrapper">
          {token ? (
            <Switch>
              <Route
                path="/subscriptions/unsubscribe-all"
                render={props => <UnsubscribeAll {...props} token={token} />}
              />
              <Route
                path="/subscriptions"
                render={props => (
                  <ManageSubscriptions {...props} token={token} />
                )}
              />
            </Switch>
          ) : (
            <Translate
              id="TEXT.NO-TOKEN-PROVIDED"
              defaultMessage="No token provided"
            />
          )}
        </div>
      </BasePage>
    );
  }
}

export default SubscriptionContainer;

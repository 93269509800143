import React from 'react';
import BasePage from '@pages/BasePage';
import withRouteData from '@utils/withRouteData';
import compose from 'recompose/compose';
import { useConfig } from '@config';
import { withRouter } from 'react-router';
import { getProvider } from './helpers';
import { useSelector } from 'react-redux';

import './style.css';

const BingoContainer = ({ history, match }) => {
  const config = useConfig();
  const pages = useSelector(state => state.content.pages.data);
  const sessionId = useSelector(state => state.player.sessionId);

  const page = pages?.find(p => p.url === 'bingo' || p.url === 'bingo/play');
  const params = match.params;

  const BingoProvider = getProvider(config);

  const props = {
    sessionId,
    history,
    roomId: params.room,
    config,
  };

  return (
    <BasePage className="BingoContainer" page={page}>
      <BingoProvider {...props} />
    </BasePage>
  );
};

export default compose(withRouter, withRouteData)(BingoContainer);

import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { Icon } from 'tg-core-components';
import debounce from 'lodash/debounce';

import './style.css';

const FavoriteHeart = ({ onClick, isFavorite = false }) => {
  const [isActive, setIsActive] = useState(isFavorite);

  const onClickDebounced = useCallback(debounce(onClick, 400), []);

  useEffect(() => {
    setIsActive(isFavorite);
  }, [isFavorite]);

  return (
    <div
      className={cn('FavoriteHeart', {
        'FavoriteHeart--is-favorite': isActive,
      })}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();

        setIsActive(!isActive);

        onClickDebounced();
      }}>
      <Icon icon={`heart${isActive ? '' : '-o'}`} />
    </div>
  );
};

export default FavoriteHeart;

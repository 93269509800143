import React, { Component, Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import marked from 'marked';

import withRouteData from '@utils/withRouteData';
import { createImageUrl, FORMAT } from '@utils/picture';
import { loadContents } from '@actions/content';
import selectAvailableBonuses from '@selectors/selectAvailableBonuses';
import { withConfig } from '@config';

import { Switch, Route } from '@components/OverlayRouter';
import Popup from '@components/Popup';
import '@pages/ContentContainer/style.css';

@withRouteData
@withConfig
@connect(state => ({
  pages: state.content.pages.data,
  sets: state.content.sets.data,
  promotionBonuses: selectAvailableBonuses(state),
  bonuses: state.content.bonuses.data,
}))
class TermsContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      identifiers: ['terms-and-conditions'],
    },
    {
      name: 'set',
      identifiers: ['terms-conditions'],
    },
    {
      name: 'bonus',
    },
  ]);

  render() {
    const { pages, sets, overlayMatch } = this.props;
    const page = pages?.find(
      p =>
        '/' + p.url === overlayMatch.url ||
        p.identifier === 'terms-and-conditions'
    );
    const set = sets?.find(m => m.identifier === 'terms-conditions');

    // TMP hotfix. Real issue is in Switch useEffect. route is not re-rendered when props update.
    if (!page || !set) return null;

    return (
      <div
        className="TermsContainer"
        style={{
          backgroundImage: `url('${
            page?.background
              ? createImageUrl({
                  src: page.background.file.url,
                  format: FORMAT.JPG,
                  progressive: true,
                  width: __CLIENT__ && Math.min(1920, window.innerWidth),
                })
              : ''
          }')`,
        }}>
        <Switch>
          <Route
            path="/popup/terms-and-conditions/bonus/:id"
            exact={true}
            render={props => (
              <BonusTerms page={page} set={set} {...this.props} {...props} />
            )}
          />
          <Route
            path="/popup/terms-and-conditions/:section?"
            exact={true}
            render={props => (
              <Terms page={page} set={set} {...this.props} {...props} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

const Terms = ({ page, set, location, overlayMatch, isRouteDataLoading }) => {
  const headerRefs = useRef({});
  useEffect(
    () =>
      headerRefs.current[overlayMatch.params.section] &&
      headerRefs.current[overlayMatch.params.section].scrollIntoView({
        block: 'center',
      }),
    [overlayMatch.params.section]
  );

  return (
    <Popup
      showLoading={isRouteDataLoading}
      title={page?.title}
      location={location}
      match={overlayMatch}
      preventScroll>
      {page?.content && (
        <div
          className="actual-content"
          dangerouslySetInnerHTML={{
            __html: marked(page.content),
          }}
        />
      )}

      <div className="terms-and-conditions-content">
        {set?.items?.map((i, k) => (
          <Fragment key={k}>
            <div
              className="ref"
              ref={ref => (headerRefs.current[i.identifier] = ref)}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: marked(i.content),
              }}
            />
          </Fragment>
        ))}
      </div>
    </Popup>
  );
};

const BonusTerms = ({
  page,
  location,
  overlayMatch,
  promotionBonuses,
  bonuses,
  isRouteDataLoading,
  config,
}) => {
  const bonus = config.isPnp
    ? bonuses?.find(b => b.identifier === overlayMatch.params.id)
    : promotionBonuses?.find(b => b.identifier === overlayMatch.params.id);

  return (
    <Popup
      showLoading={isRouteDataLoading}
      title={page?.title}
      location={location}
      match={overlayMatch}
      preventScroll>
      {bonus?.termsAndConditions && (
        <div
          dangerouslySetInnerHTML={{
            __html: marked(bonus.termsAndConditions),
          }}
        />
      )}
    </Popup>
  );
};

export default TermsContainer;

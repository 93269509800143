export const selectTacVersion = state => {
  const { content } = state;

  const tacContent = content.sets.data?.find(
    s => s.identifier === 'terms-conditions'
  );

  return tacContent?.config?.version;
};

export const selectTacContent = state => {
  const { content } = state;

  const tacContent = content.sets.data?.find(
    s => s.identifier === 'terms-conditions'
  );

  return tacContent;
};

export const selectTac = state => {
  const tacVersion = selectTacVersion(state);
  const tacContent = selectTacContent(state);

  return {
    version: tacVersion,
    content: tacContent,
  };
};

import React, { Component } from 'react';
import compose from 'recompose/compose';
import { injectIntl } from 'react-intl';
import { Input, Icon, Button } from 'tg-core-components';
import WithValidation from 'tg-core-components/lib/lib/WithValidation';
import require from 'tg-core-components/lib/lib/WithValidation/rules/required';
import email from 'tg-core-components/lib/lib/WithValidation/rules/email';
import phone from 'tg-core-components/lib/lib/WithValidation/rules/phone';
import { Translate } from 'tg-core-components';
import api from 'tg-core-api';
import getByPath from 'lodash/get';
import translate from 'tg-core-components/lib/lib/utils/translate';
import getCallingCodes from '@utils/callingCodeOptions';

import './SignUpSga.css';

class SignUpSga extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: props.Email,
      Password: props.Password,
      MobilePhoneNumber: '0046',
      showPromoField: false,
    };
  }

  componentDidMount = () => {
    const { onChange } = this.props;

    onChange && onChange('AcceptTerms', '');
    onChange && onChange('OptInMarketing', '');

    const { Email, MobilePhoneNumber } = this.state;

    this._validateEmail(Email || '');
    this._validatePhone(MobilePhoneNumber || '');
  };

  _handleBlur = (field, value) => {
    const { onBlur } = this.props;

    onBlur(field, value);
    this.setState({
      [field]: value,
    });
  };

  _validateEmail = async email => {
    const { jurisdiction } = this.props;

    if (email.trim()) {
      this.setState({ emailValidation: 'loading' });
      try {
        await api.player.validateEmail(encodeURIComponent(email), jurisdiction);
        this.setState({ emailValidation: 'success' });
      } catch (e) {
        this.setState({ emailValidation: 'failure' });
      }
    }
  };

  _validatePhone = async phonenumber => {
    if (phonenumber?.length > 5) {
      this.setState({ phoneValidation: 'loading' });
      try {
        await api.player.validatePhone(phonenumber, this.props.jurisdiction);
        this.setState({ phoneValidation: 'success' });
      } catch (e) {
        const code = getByPath(e, 'value.response.data.Code', '');
        if (code === 'RateLimited') {
          this.setState({ phoneValidation: 'success' });
        } else {
          this.setState({ phoneValidation: 'failure' });
        }
      }
    }
  };

  render() {
    const {
      errors,
      onSubmit,
      intl,
      onBlur,
      countries,
      onChange,
      isLoading,
      callingCodes,
    } = this.props;

    const {
      Email,
      MobilePhoneNumber,
      emailValidation,
      phoneValidation,
      showPromoField,
    } = this.state;

    const callingCodeOptions = getCallingCodes(countries, callingCodes);

    const handleTogglePromo = () => {
      this.setState(prevState => ({
        showPromoField: !prevState.showPromoField,
      }));
    };

    const handleClosePromo = () => {
      this.setState(() => ({ showPromoField: false }));
    };

    return (
      <form className="SignUpSga form signup-form" onSubmit={onSubmit}>
        <Input
          label={translate(
            {
              id: 'label.email',
              defaultMessage: 'Email',
            },
            intl
          )}
          type="email"
          name="Email"
          value={Email}
          onBlur={e => {
            this._handleBlur('Email', e.target.value);
            this._validateEmail(e.target.value);
          }}
          status={errors.Email ? 'failure' : emailValidation}
          statusText={
            (errors.Email && translate({ id: errors.Email }, intl)) ||
            (emailValidation === 'failure' &&
              translate(
                {
                  id: 'errors.taken.email',
                  defaultMessage: 'Email is already taken.',
                },
                intl
              ))
          }
          autoFocus
        />

        <Input
          name="MobilePhoneNumber"
          type="tel"
          value={MobilePhoneNumber || ''}
          callingCodes={callingCodeOptions}
          onBlur={(e, mobilePhoneNumber) => {
            onBlur('MobilePhoneNumber', mobilePhoneNumber);
            this._validatePhone(mobilePhoneNumber);
          }}
          maxLength="17"
          status={errors.MobilePhoneNumber ? 'failure' : phoneValidation}
          statusText={
            (errors.MobilePhoneNumber &&
              translate({ id: errors.MobilePhoneNumber }, intl)) ||
            (phoneValidation === 'failure' &&
              translate(
                {
                  id: 'errors.taken.phone',
                  defaultMessage: 'Phone number is already taken.',
                },
                intl
              ))
          }
        />

        <Input
          type="checkbox"
          name="OptInMarketing"
          label={translate(
            {
              id: 'label.optin.marketing',
              defaultMessage:
                'I would like to receive marketing materials by email, phone and SMS. To opt out from receiving marketing from us via the above contacts details, you may manage your subscription preferences in your Account.',
            },
            intl
          )}
          onChange={(e, optInMarketing) =>
            onChange('OptInMarketing', optInMarketing)
          }
        />

        <Input
          type="checkbox"
          label={translate({ id: 'label.terms-of-conditions' }, intl)}
          name="AcceptTerms"
          onChange={(e, acceptTerms) => onChange('AcceptTerms', acceptTerms)}
          status={(errors.AcceptTerms && 'failure') || 'idle'}
          statusText={
            errors.AcceptTerms && translate({ id: errors.AcceptTerms }, intl)
          }
        />

        <div className="divider" />

        {showPromoField && (
          <div className="promo">
            <Input
              label={translate({ id: 'label.bonus_code' }, intl)}
              name="PromoCode"
              autoFocus
            />
            <a className="promo-close">
              <Icon onClick={handleClosePromo} icon="times" />
            </a>
          </div>
        )}

        {!showPromoField && (
          <Button className="button tertiary wide" onClick={handleTogglePromo}>
            {translate({ id: 'action.button.promo_code' }, intl)}
          </Button>
        )}

        <Button
          className={`button primary wide ${isLoading && 'loading'}`}
          isLoading={isLoading}
          disabled={
            !!isLoading ||
            emailValidation !== 'success' ||
            phoneValidation !== 'success'
          }>
          <Translate
            id="action.button.create-account"
            defaultMessage="Create account"
          />
        </Button>
      </form>
    );
  }
}

const rules = {
  Email: [
    [require, 'error.empty.email'],
    [email, 'error.condition.email'],
  ],
  MobilePhoneNumber: [
    [require, 'error.empty.phone'],
    [phone, 'error.condition.phone'],
  ],
  AcceptTerms: [[require, 'error.empty.terms-of-conditions']],
  OptInMarketing: [],
  PromoCode: [],
};

export default compose(WithValidation(rules), injectIntl)(SignUpSga);

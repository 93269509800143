import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Translate } from 'tg-core-components';

import logotype from '@images/logo.png';
import hamburgerIcon from '@images/hamburger-icon-index.svg';

import './style.css';

export const SignInButton = ({ isPnp, clicked }) => (
  <div className="sign-in">
    <Link
      className="button secondary"
      to={{ hash: 'sign-in' }}
      onClick={clicked}>
      {isPnp ? (
        <Translate id="action.fetch_balance" defaultMessage="Fetch balance" />
      ) : (
        <Translate id="action.sign-in" defaultMessage="Sign in" />
      )}
    </Link>
  </div>
);

const IndexHeader = ({
  logoLinkUrl = '/casino',
  onLogoClick,
  showSignInButton,
  signInButtonClick,
  showDashboardButton,
  isPnp,
  homeButtonLink = '/casino',
  in_banner,
}) => (
  <div className="index__header">
    <Link
      className="index__header__logo"
      to={logoLinkUrl}
      onClick={onLogoClick}>
      <img
        src={logotype}
        alt="index-header-logo"
        className="index__header__logo__image"
      />
    </Link>

    {!in_banner && (
      <div className="index__header__actions">
        {showSignInButton && (
          <SignInButton isPnp={isPnp} clicked={signInButtonClick} />
        )}

        {showDashboardButton && (
          <Link
            to={{ hash: 'dashboard' }}
            className="index__header__actions__menu">
            <img src={hamburgerIcon} alt="menu" />
          </Link>
        )}

        <Link to={homeButtonLink} className="index__header__actions__home">
          <Icon icon="home" />
          <div className="home-btn-label">
            <Translate id="action.home" defaultMessage="Home" />
          </div>
        </Link>
      </div>
    )}
  </div>
);

export default IndexHeader;

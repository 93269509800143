const replaceToken = (content, values) => {
  for (let value in values) {
    const replaceRegExp = new RegExp(`{${value}}`, 'g');
    content = content.replace(replaceRegExp, values[value]);
  }

  return content;
};

export default replaceToken;

/**
 * Using redux state to determine what features should be enabled depending on e.g. device or browser
 */
const selectFeatureDetect = state => ({
  renderVideos:
    state.app.device === 'Desktop' &&
    !['IE', 'Edge'].includes(state.app.browser),
});

export default selectFeatureDetect;

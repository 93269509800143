import { useUrls } from '@utils/urls';
import React from 'react';
import { Button, Translate } from 'tg-core-components';

import './style.css';

const DepositStep = () => {
  const { deposit } = useUrls();
  return (
    <div className="Deposit">
      <Button className="button primary" to={deposit()}>
        <Translate
          id="action.first-deposit"
          defaultMessage="Make a first deposit"
        />
      </Button>

      <Button className="button link" to="/casino">
        <Translate id="action.explore" defaultMessage="Explore" />
      </Button>
    </div>
  );
};

export default {
  name: 'deposit',
  icon: 'sign-in',
  Component: DepositStep,
};

import React, { useEffect } from 'react';
import { Input, Translate, Button } from 'tg-core-components';
import { validateRules, required } from '../../validation';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { openHash } from '@utils/hash';
import { useConfig } from '@utils/config';

import './style.css';

import translate from 'tg-core-components/lib/lib/utils/translate';

const Confirm = ({
  errors,
  onChange,
  intl,
  fields,
  signUp,
  isLoading,
  statuses,
  location,
}) => {
  const isValid = statuses.AcceptTerms === 'success';
  const config = useConfig();

  useEffect(() => {
    if (config.marketingOptedIn) {
      onChange({ OptInMarketing: true });
    }
  }, []);

  return (
    <div className="Confirm">
      <Input
        type="checkbox"
        name="AcceptTerms"
        label={
          <Translate
            id="message.confirm_terms_conditions"
            defaultMessage="I confirm I am over 18 years old and agree to the {pplink} and to the {tclink}"
            values={{
              tclink: (
                <Link
                  to={{
                    hash: openHash(
                      location.hash,
                      '#popup/terms-and-conditions'
                    ),
                  }}>
                  <Translate
                    id="action.confirm_terms_conditions_tclink"
                    defaultMessage="Terms and Conditions"
                  />
                </Link>
              ),
              pplink: (
                <Link
                  to={{
                    hash: openHash(location.hash, '#popup/privacy-policy'),
                  }}>
                  <Translate
                    id="action.confirm_terms_conditions_pplink"
                    defaultMessage="Privacy Policy"
                  />
                </Link>
              ),
            }}
          />
        }
        value={fields.AcceptTerms}
        onChange={(_, AcceptTerms) => {
          onChange({ AcceptTerms });
        }}
        status={statuses.AcceptTerms}
        statusText={
          errors.AcceptTerms &&
          translate(
            {
              id: errors.AcceptTerms,
            },
            intl
          )
        }
      />

      <Input
        type="checkbox"
        name="OptInMarketing"
        label={translate(
          {
            id: 'label.optin.marketing',
            defaultMessage: 'I want to receive marketing material',
          },
          intl
        )}
        value={fields.OptInMarketing}
        onChange={(_, OptInMarketing) => {
          onChange({ OptInMarketing });
        }}
      />

      {!isValid && (
        <Button
          onClick={() => onChange({ OptInMarketing: true, AcceptTerms: true })}
          className="select primary">
          <Translate
            id="action.button.accept-all"
            defaultMessage="Accept all"
          />
        </Button>
      )}

      <Button
        onClick={signUp}
        isLoading={isLoading}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        <Translate id="action.button.continue" defaultMessage="Continue" />
      </Button>
    </div>
  );
};

const validate = async ({ AcceptTerms }) => {
  const rules = {
    AcceptTerms: [[required, 'error.empty.terms-of-conditions']],
  };
  const errors = await validateRules({ AcceptTerms }, rules);

  return Promise.resolve({ errors });
};

export default {
  name: 'confirm',
  icon: 'check-circle',
  Component: Confirm,
  validate,
  fields: ['AcceptTerms'],
};

import Cookies from 'universal-cookie';
import { getConfig } from '@config';
import { getContentPreviewSettings } from '@utils/contentPreview';

const initialState = {
  currencies: [],
  displayCurrencies: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CURRENCIES_COMPLETE': {
      return {
        ...state,
        currencies: action.payload,
      };
    }
    case 'GET_DISPLAY_CURRENCIES_COMPLETE': {
      return {
        ...state,
        displayCurrencies: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

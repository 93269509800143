import React from 'react';

import Header from './Header';
import Summary from './Summary';
import DepositLimits from './DepositLimits';
import ResponsibleGaming from './ResponsibleGaming';

import './style.css';

const SessionSummary = ({
  session,
  limits,
  user,
  sections,
  paymentStats,
  jurisdiction,
}) => {
  let limitsToShow = [];

  const responsibleGamingText =
    sections && sections.find(s => s.identifier === 'session-summary-info');
  const headerContent =
    sections && sections.find(s => s.identifier === 'session-summary-header');

  if (limits) {
    limitsToShow = getLimitsToShow(limits);
    limitsToShow = Object.keys(limitsToShow).map(key => limitsToShow[key]);
  }

  const depositWithdrawalSummary =
    paymentStats &&
    parseInt(paymentStats.TotalWithdraw) - parseInt(paymentStats.TotalDeposit);

  const timestamp = session && new Date(session.LastUnixStarted * 1000);

  if (!session || !user) return null;

  return (
    <div className="SessionSummary">
      <Header content={headerContent} />
      <Summary
        session={session}
        currency={user.currency}
        depositWithdrawalSummary={depositWithdrawalSummary}
        jurisdiction={jurisdiction}
        timestamp={timestamp}
      />
      <DepositLimits
        limitsToShow={limitsToShow}
        jurisdiction={jurisdiction}
        currency={user.currency}
      />
      <ResponsibleGaming content={responsibleGamingText} />
    </div>
  );
};

const getLimitsToShow = limits =>
  limits
    .filter(l => l.Type === 'Deposit')
    .sort((a, b) => {
      return a.Timespan - b.Timespan || a.Status > b.Status
        ? -1
        : a.Status < b.Status
        ? 1
        : 0;
    })
    .reduce((acc, l) => {
      if (acc[l.Timespan]) return acc;
      acc[l.Timespan] = l;
      return acc;
    }, {});

export default SessionSummary;

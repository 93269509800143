import React from 'react';
import ExternalAuthAccount from '@components/ExternalAuthAccount';

const ExternalAuthStep = ({
  onSubmit,
  provider,
  clientId,
  redirectUrl,
  uiFriendly,
  ssn,
  device,
  locale,
}) => (
  <div className="ExternalAuthStep">
    <ExternalAuthAccount
      onSubmit={onSubmit}
      ssn={ssn}
      clientId={clientId}
      redirectUrl={redirectUrl}
      provider={provider}
      uiFriendly={uiFriendly}
      locale={locale}
      bankIdQR={'false'}
      bankIdDevice={device === 'Desktop' ? 'other' : 'same'}
    />
  </div>
);

export default ExternalAuthStep;

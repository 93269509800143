import React from 'react';
import { Translate, Button } from 'tg-core-components';
import marked from 'marked';

import './style.css';

const Error = ({ errors, onClose }) => {
  return (
    <div className="Error">
      <div
        className="Error__content"
        dangerouslySetInnerHTML={{
          __html: marked(errors.Error || ''),
        }}
      />

      <Button onClick={onClose} className="primary">
        <Translate id="action.button.close" defaultMessage="Close" />
      </Button>
    </div>
  );
};

export default {
  name: 'error',
  Component: Error,
};

import React, { useContext, useEffect } from 'react';
import PopUnder from '../PopUnder';
import { withRouter } from 'react-router-dom';
import GamePlayBackground from '../GamePlayBackground';
import GamePlayHeader, { selectGamePlayHeaderProps } from '../GamePlayHeader';
import GameDetails, { selectGameDetailsProps } from '../GameDetails';
import GameFrame, { selectGameFrameProps } from '../GameFrame';
import { useSetLastPlayedGame, usePlaySessionLimit } from '../helpers';
import {
  GamePlayContext,
  setIsBottomBarOpen,
  setSearchTerm,
  setIsSearchOpen,
} from '../context';
import Helmet from 'react-helmet';

import './style.css';

export const selectGamePlayProps = state => ({
  sessionId: state.player.sessionId,
  gamePlayHeaderProps: selectGamePlayHeaderProps(state),
  gameDetailsProps: selectGameDetailsProps(state),
  gameFrameProps: selectGameFrameProps(state),
});

const GamePlay = ({
  /* Props from parent */
  game,
  page,
  onExitGame,
  toggleFavoriteGameById,
  quickDepositInit,
  quickDepositOpen,
  pushGtmEvent,

  /* withRouter */
  history,
  location,

  /* Props from selector */
  gamePlayHeaderProps,
  gameDetailsProps,
  gameFrameProps,
  sessionId,
  setLastPlayedGame,
}) => {
  const { state, dispatch } = useContext(GamePlayContext);

  useSetLastPlayedGame({
    sessionId,
    game,
    setLastPlayedGame,
    isGameLaunched: state.isGameLaunched,
  });
  usePlaySessionLimit({ game, history });

  // Close bottom bar and search when changing game
  useEffect(() => {
    if (state.isGameLaunched) {
      dispatch(setIsBottomBarOpen(false));
    }
    dispatch(setSearchTerm(''));
    dispatch(setIsSearchOpen(false));
  }, [dispatch, state.isGameLaunched, game.identifier]);

  return (
    <div className="GamePlay">
      <Helmet
        bodyAttributes={{
          'data-gameplay-bottom-bar-open': state.isBottomBarOpen,
        }}
      />
      <GamePlayBackground game={game} />
      <GamePlayHeader
        {...gamePlayHeaderProps}
        game={game}
        quickDepositInit={quickDepositInit}
        quickDepositOpen={quickDepositOpen}
        pushGtmEvent={pushGtmEvent}
      />
      <GameFrame
        {...gameFrameProps}
        game={game}
        onExitGame={onExitGame}
        location={location}
      />
      <PopUnder
        isClosed={!state.isBottomBarOpen}
        onClose={() => {
          dispatch(setIsBottomBarOpen(false));
          dispatch(setSearchTerm(''));
          dispatch(setIsSearchOpen(false));
        }}>
        <GameDetails
          {...gameDetailsProps}
          game={game}
          page={page}
          toggleFavoriteGameById={toggleFavoriteGameById}
        />
      </PopUnder>
    </div>
  );
};

export default React.memo(withRouter(GamePlay));

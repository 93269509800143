import React from 'react';
import ExternalAuthAccount from '@components/ExternalAuthAccount';
import { STEPS } from './index';

class BankIdSignInStep extends React.Component {
  state = {
    isLoading: true,
  };

  render() {
    const {
      setStep,
      onSubmitSignIn,
      device,
      clientId,
      uiFriendly,
      redirectUrl,
      provider,
      locale,
      browser,
      getStep,
      ssn,
      history,
    } = this.props;

    const { isLoading } = this.state;

    return (
      <div className="BankIdSignInStep">
        {isLoading && getStep(STEPS.SET_METHOD_STEP, { isLoading: true })}
        <ExternalAuthAccount
          onSubmit={data => {
            onSubmitSignIn(data).then(response => {
              if (response.data && response.data.JwtTokenSignup) {
                history.push({ hash: '#sign-up' });
              }
            });
          }}
          style={{ visibility: isLoading ? 'hidden' : 'visible' }}
          onLoad={() => {
            this.setState({ isLoading: false });
            setStep(STEPS.BANKID_SIGNIN);
          }}
          clientId={clientId}
          uiFriendly={uiFriendly}
          redirectUrl={redirectUrl}
          provider={provider}
          locale={locale}
          browser={browser}
          bankIdQR="false"
          bankIdDevice={device === 'Desktop' ? 'other' : 'same'}
          ssn={ssn}
        />
      </div>
    );
  }
}

export default BankIdSignInStep;

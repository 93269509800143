import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import SignUpSga from './SignUpSga';
import { getConfig } from '@config';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import { withCookies } from 'react-cookie';
import SetLimitsStep from '../Account/SignUp/SetLimitsStep';
import responsibleGamingActions from 'tg-core-redux/lib/modules/responsible_gaming/action';
import Alert, { createAlertId, createAlertValues } from '@components/Alert';
import { Redirect } from 'react-router';
import SetMethodStep from './SetMethodStep';
import EmailSignInStep from './EmailSignInStep';
import BankIdSignInStep from './BankIdSignInStep';
import { getQueryParams } from '@utils/refRedirect';
import { selectTac } from '@utils/tac';
import { pushGtmEvent } from '@actions/app';
import { GTM_EVENTS } from '@utils/google-tag-manager';

import {
  setSocialSecurityNumber,
  getSocialSecurityNumber,
  setRememberSocialSecurityNumber,
  getRememberSocialSecurityNumber,
} from '@utils/localstorage';
import { validate, transform } from '@utils/ssn';

import './style.css';

export const STEPS = {
  SET_METHOD_STEP: 1,
  EMAIL_SIGNIN: 2,
  BANKID_SIGNIN: 3,
  SIGNUP: 5,
  SET_LIMITS: 8,
  WELCOME: 9,
};

@withCookies
@withRouter
class SgaPlayNow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: STEPS.SET_METHOD_STEP,
      error: false,
      isLoading: false,
      ssnInputValue: '',
      ssn: '',
      ssnIsValid: true,
      ssnShouldBeRemembered: false,
      isMobile: false,
    };
  }

  componentDidMount = () => {
    const { location, pushGtmEvent } = this.props;
    const { step } = location.state;

    pushGtmEvent({ event: GTM_EVENTS.SIGNIN_INIT });
    if (step) this.setState({ step });
    else this.pushGtmEvents();

    const ssnTrimmed = getSocialSecurityNumber().slice(2);
    this.setState({
      ssnInputValue: ssnTrimmed,
      ssn: ssnTrimmed,
      ssnShouldBeRemembered: getRememberSocialSecurityNumber(),
      isMobile: this.props.device === 'Mobile',
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.step !== prevState.step) {
      this.pushGtmEvents();
    }
  }

  pushGtmEvents = () => {
    const { pushGtmEvent } = this.props;
    const { step } = this.state;
    switch (step) {
      case STEPS.SET_METHOD_STEP:
        pushGtmEvent({ event: GTM_EVENTS.SIGNIN_STEP_SET_METHOD });
        break;
      case STEPS.EMAIL_SIGNIN:
        pushGtmEvent({ event: GTM_EVENTS.SIGNIN_STEP_EMAIL });
        break;
      case STEPS.BANKID_SIGNIN:
        pushGtmEvent({ event: GTM_EVENTS.SIGNIN_STEP_BANKID });
        break;
      case STEPS.SIGNUP:
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_INIT });
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_STEP_CONTACT_INFORMATION });
        break;
      case STEPS.SET_LIMITS:
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_STEP_LIMITS });
        break;
      case STEPS.WELCOME:
        pushGtmEvent({ event: GTM_EVENTS.SIGNUP_STEP_WELCOME });
        break;
    }
  };

  onChangeStep(step, prevStep) {
    this.setState({
      step: step,
      error: false,
    });

    this.props.onChangeStep && this.props.onChangeStep(step, prevStep);
  }

  onSubmitSignIn = data => {
    const {
      signIn,
      signInWithCode,
      operatingSystem: OperatingSystem,
      browser: Browser,
      device: Platform,
    } = this.props;

    const refUrl = getQueryParams({
      location: window.routerHistory.location,
      queryName: 'ref_url',
    });

    if (data.query?.code) {
      data.Code = data.query.code;
      data.State = data.query.state;
      delete data.query;
    }

    const request = {
      ...data,
      Platform,
      OperatingSystem,
      Browser,
      refUrl,
    };

    this.setState({ isLoading: true });
    const promise = data.Code ? signInWithCode(request) : signIn(request);
    return promise.then(this.handleResponse);
  };

  onSubmitSignUp = data => {
    const {
      cookies,
      signUpWithToken,
      jurisdiction,
      config,
      player,
      device: Platform,
      operatingSystem: OperatingSystem,
      browser: Browser,
      tac,
    } = this.props;

    const {
      MobilePhoneNumber,
      OptInMarketing,
      Email,
      UserName,
      PromoCode,
    } = data;

    const Jurisdiction = jurisdiction;
    const AffiliateTag = cookies.get(config.btagCookie);

    const formData = {
      Username: UserName,
      Email,
      AffiliateTag,
      Platform,
      Jurisdiction,
      OperatingSystem,
      Browser,
      TacVersionAccepted: tac.version,
      JwtTokenString: player && player.jwtToken,
      Country: 'SE',
      Currency: 'SEK',
      Language: 'sv',
      MobilePhoneNumber,
      OptIns: OptInMarketing
        ? ['NewsEmails', 'BonusEmails', 'NewsSMSs', 'BonusSMSs', 'Phone']
        : [],
      PromoCode,
    };

    this.setState({ isLoading: true });
    return signUpWithToken(formData).then(this.handleResponse);
  };

  onSubmitSetLimits = data => {
    const { player, setLimit } = this.props;

    const limitsPromise = [];
    for (let value in data.values) {
      if (data.values[value] !== 0) {
        const limit = {
          Type: 'Deposit',
          Amount: data.values[value],
          Timespan: value,
        };
        limitsPromise.push(setLimit(player.sessionId, limit));
      }
    }

    this.setState({ isLoading: true });
    return Promise.all(limitsPromise);
  };

  handleResponse = action => {
    if (action.type && action.type.includes('_FAILURE')) {
      this.setState({
        error: action,
        isLoading: false,
      });
    } else if (
      action.payload &&
      action.payload.Issues &&
      action.payload.Issues.length > 0
    ) {
      this.setState({
        error: action,
        isLoading: false,
      });
    } else {
      this.setState({
        error: false,
        isLoading: false,
      });
    }

    return action;
  };

  onSubmitSetLimitsStep = ({ limits }) => {
    const { player, setMultipleLimits } = this.props;
    const limitsGroupedByType = limits.reduce(
      (limits, l) => ({
        ...limits,
        [l.Type]: [...(limits[l.Type] || []), l],
      }),
      {}
    );
    this.setState({ isLoading: true });
    const promises = Object.keys(limitsGroupedByType).map(type =>
      setMultipleLimits(player.sessionId, {
        Type: type,
        Limits: limitsGroupedByType[type].map(l => ({
          Timespan: l.Timespan,
          Amount: l.Amount,
        })),
      })
    );
    return Promise.all(promises)
      .then(this.handleResponse)
      .then(response => {
        this.setState({ isLoading: false });
        const responseok = Array.isArray(response)
          ? response.every(r => r.status < 300)
          : response.status < 300;
        if (responseok) this.onChangeStep(STEPS.WELCOME, this.state.step);
      });
  };

  getStep = (step, props) => {
    const {
      sections,
      sets,
      device,
      config,
      contentful_config,
      ipCountryCode,
      locale,
      browser,
      jurisdiction,
      history,
    } = this.props;

    const {
      oauth_clientid,
      oauth_ui_friendly,
      oauth_redirecturl,
      oauth_provider,
    } = config.devcode;

    const contentSetMethod = sections?.find(
      s => s.identifier === 'play-now.header.set-method'
    );

    const countries = contentful_config?.find(c => c.key === 'countries');
    const callingCodes = contentful_config?.find(c => c.key === 'callingCodes');
    const ipCountry =
      countries?.value.find(c => c.value === ipCountryCode) || {};

    switch (step || this.state.step) {
      case STEPS.SET_METHOD_STEP:
        return (
          <SetMethodStep
            {...props}
            header={contentSetMethod}
            setStep={step => this.onChangeStep(step, this.state.step)}
            ssnIsValid={this.state.ssnIsValid}
            ssn={this.state.ssnInputValue}
            ssnShouldBeRemembered={this.state.ssnShouldBeRemembered}
            handleChange={newValue =>
              this.setState({ ssnInputValue: newValue, ssn: newValue })
            }
            isMobile={this.state.isMobile}
            handleSubmit={e => {
              e.preventDefault();

              this.setState(
                {
                  ssnIsValid:
                    validate(this.state.ssnInputValue.trim()) ||
                    this.state.isMobile,
                },
                () => {
                  if (this.state.ssnIsValid) {
                    setRememberSocialSecurityNumber(
                      this.state.ssnShouldBeRemembered
                    );

                    if (this.state.ssnShouldBeRemembered) {
                      setSocialSecurityNumber(transform(this.state.ssn));
                    } else {
                      setSocialSecurityNumber('');
                    }

                    this.setState({
                      step: STEPS.BANKID_SIGNIN,
                      ssn: transform(this.state.ssn),
                    });
                  }
                }
              );
            }}
            toggleCheckbox={() =>
              this.setState({
                ssnShouldBeRemembered: !this.state.ssnShouldBeRemembered,
              })
            }
          />
        );
      case STEPS.EMAIL_SIGNIN:
        return (
          <EmailSignInStep
            {...props}
            isLoading={this.state.isLoading}
            setStep={step => this.onChangeStep(step, this.state.step)}
            onSubmitSignIn={this.onSubmitSignIn}
          />
        );
      case STEPS.BANKID_SIGNIN:
        return (
          <BankIdSignInStep
            {...props}
            getStep={(step, props) => this.getStep(step, props)}
            setStep={step => this.onChangeStep(step, this.state.step)}
            onSubmitSignIn={this.onSubmitSignIn}
            locale={locale}
            device={device}
            browser={browser}
            clientId={oauth_clientid}
            uiFriendly={oauth_ui_friendly}
            redirectUrl={oauth_redirecturl}
            provider={oauth_provider}
            ssn={this.state.ssn}
            ipCountryCode={ipCountryCode}
            history={history}
          />
        );
      case STEPS.SIGNUP:
        return (
          <SignUpSga
            {...props}
            jurisdiction={jurisdiction}
            isLoading={this.state.isLoading}
            setStep={step => this.onChangeStep(step, this.state.step)}
            countries={countries}
            callingCodes={callingCodes}
            ipCountry={ipCountry}
            onSubmitForm={data => {
              this.onSubmitSignUp(data)
                .then(this.handleResponse)
                .then(response => {
                  if (response && response.status === 200)
                    this.onChangeStep(STEPS.SET_LIMITS, this.state.step);
                });
            }}
          />
        );
      case STEPS.SET_LIMITS: {
        const onUpdate = ({ limits }) => this.setState({ limits });
        const responsibleGamingContent =
          sets &&
          sets.find(s => s.identifier === 'responsible-gaming-signup-new');
        const setLimitHeaderContent =
          sections &&
          sections.find(s => s.identifier === 'play-now.header.set-limits');
        return (
          <SetLimitsStep
            limits={
              Array.isArray(this.state.limits)
                ? this.state.limits.map(l => ({ ...l, Status: 'Active' }))
                : []
            }
            content={responsibleGamingContent}
            header={setLimitHeaderContent}
            onUpdate={onUpdate}
            onSubmit={this.onSubmitSetLimitsStep}
            isLoading={this.state.isLoading}
            currency="SEK"
            jurisdiction="sga"
          />
        );
      }
      case STEPS.WELCOME:
        return (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        );
    }
  };

  render() {
    const { error } = this.state;

    return (
      <div className="SgaPlayNow">
        {error && (
          <Alert
            icon="exclamation-triangle"
            id={createAlertId(error, 'danger')}
            values={createAlertValues(error)}
            level="danger"
          />
        )}

        {this.getStep()}
      </div>
    );
  }
}

export default connect(
  state => ({
    sections: state.content.sections.data,
    sets: state.content.sets.data,
    config: getConfig(state.app.jurisdiction),
    player: state.player,
    contentful_config: state.content.config.data,
    ipCountryCode: state.app.ipCountry,
    jurisdiction: state.app.jurisdiction,
    operatingSystem: state.app.operatingSystem,
    browser: state.app.browser,
    device: state.app.device,
    limits: state.responsible_gaming.data,
    locale: state.app.locale,
    tac: selectTac(state),
  }),
  dispatch => ({
    signIn: bindActionCreators(playerActions.signInV2, dispatch),
    signInWithCode: bindActionCreators(playerActions.signInWithCode, dispatch),
    signUpWithCode: bindActionCreators(playerActions.signUpWithCode, dispatch),
    signUpWithToken: bindActionCreators(
      playerActions.signUpWithToken,
      dispatch
    ),
    verifyExistingSePlayer: (playerSessionId, verifySePlayerModel) =>
      dispatch(
        playerActions.verifyExistingSePlayer(
          playerSessionId,
          verifySePlayerModel
        )
      ),
    verifyExistingSePlayerWithToken: (playerSessionId, verifySePlayerModel) =>
      dispatch(
        playerActions.verifyExistingSePlayerWithToken(
          playerSessionId,
          verifySePlayerModel
        )
      ),
    setLimit: bindActionCreators(responsibleGamingActions.setLimit, dispatch),
    pushGtmEvent: bindActionCreators(pushGtmEvent, dispatch),
  })
)(SgaPlayNow);

import React, { Component } from 'react';
import Popup from '@components/Popup';
import marked from 'marked';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { closeHash } from '@utils/hash';
import { Button, Translate } from 'tg-core-components';

@withRouter
class AgreementsContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  constructor(props) {
    super(props);

    this.state = {
      agreementsToAccept: this.props.location?.state?.agreementsToAccept || [],
    };
  }

  handleAcceptAgreement = () => {
    const { agreementsToAccept } = this.state;
    const acceptedAgreementName = agreementsToAccept[0].identifier;

    let acceptedAgreements = JSON.parse(
      window?.localStorage.getItem('agreements') || '[]'
    );
    acceptedAgreements.push(acceptedAgreementName);
    window?.localStorage.setItem(
      'agreements',
      JSON.stringify(acceptedAgreements)
    );
    this.setState({ agreementsToAccept: agreementsToAccept.slice(1) });
  };

  render() {
    const { location } = this.props;

    const { agreementsToAccept } = this.state;

    return agreementsToAccept && agreementsToAccept.length ? (
      <Popup className="AgreementsContainer" preventClose={true}>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: marked(agreementsToAccept[0].content),
          }}
        />
        <Button
          type="button"
          className="button primary"
          onClick={this.handleAcceptAgreement}>
          <Translate id="label.agree" defaultMessage="I Agree" />
        </Button>
      </Popup>
    ) : (
      <Redirect replace to={closeHash(location.hash, 'popup/agreements')} />
    );
  }
}

export default AgreementsContainer;

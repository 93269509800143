import Router from './Router';
import RouterContext from './RouterContext';
import Route from './Route';
import Switch from './Switch';
import withOverlayRouter from './withOverlayRouter';

/**
 * Our own implementation of react-routers HashRouter. We cant use the
 * original since we want to be able to nest BrowserRouter and HashRouter
 * yet still control what router is used in <Link />.
 */
export default Router;
export { Route, Switch, RouterContext, withOverlayRouter };

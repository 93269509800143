import React, { useEffect, useState, useRef } from 'react';
import marked from 'marked';
import getByPath from 'lodash/get';
import cn from 'classnames';
import { Money, Translate } from 'tg-core-components';

import './style.css';

const JackpotWidget = ({
  value,
  currency,
  section,
  nextDropTime,
  nextJackpotValue,
  previousWinAmount,
  hasBeenWon,
}) => {
  const type = getByPath(section, 'config.type');
  const image = getByPath(section, 'image.file.url');
  const backgroundImage = getByPath(section, 'backgroundImage.file.url');

  const [timeLeft, setTimeLeft] = useState(
    nextDropTime && calculateTimeLeft(nextDropTime)
  );
  const [jackpotValue, setJackpotValue] = useState(value);
  const [jackpotStep, setJackpotStep] = useState(0.1);
  const dropTimeoutRef = useRef(null);
  const tickingTimeoutRef = useRef(null);

  useEffect(() => {
    setJackpotValue(value);
  }, [value]);

  // New jackpot amount from the feed
  useEffect(() => {
    if (value > jackpotValue) setJackpotStep((value - jackpotValue) / 100);
  }, [value, jackpotValue]);

  // Jackpot live tick
  useEffect(() => {
    clearTimeout(tickingTimeoutRef.current);
    tickingTimeoutRef.current = setTimeout(
      () => setJackpotValue(jackpotValue + Math.random() * jackpotStep),
      100
    );
  }, [jackpotStep, jackpotValue]);

  // "Must drop in..." timer
  useEffect(() => {
    if (['Hourly', 'Daily'].includes(section.config.type))
      dropTimeoutRef.current = setTimeout(
        () => setTimeLeft(nextDropTime && calculateTimeLeft(nextDropTime)),
        1000
      );
  }, [nextDropTime, section.config.type, timeLeft]);

  useEffect(() => {
    return () => {
      clearTimeout(tickingTimeoutRef.current);
      clearTimeout(dropTimeoutRef.current);
    };
  }, []);

  const { seconds, minutes, hours } = timeLeft;

  return (
    <div
      className="JackpotWidget"
      style={{
        ...(backgroundImage && { backgroundImage: `url(${backgroundImage})` }),
      }}>
      <div className="JackpotWidget__image">
        <img src={image} loading="lazy" alt="jackpot" />
      </div>

      {hasBeenWon && (
        <div className="JackpotWidget__badge">
          <Translate
            id="label.jackpot-drop.has-won"
            defaultMessage="Has been won!"
          />
        </div>
      )}

      {section?.content && (
        <div
          className="JackpotWidget__content"
          dangerouslySetInnerHTML={{ __html: marked(section.content || '') }}
        />
      )}

      <h1 className="JackpotWidget__value">
        <Money
          value={hasBeenWon ? previousWinAmount : jackpotValue}
          currency={currency}
        />
      </h1>

      {type !== 'Progressive' && (
        <div
          className={cn('JackpotWidget__info', {
            'JackpotWidget__info--jackpot': hasBeenWon,
            'JackpotWidget__info--clock': !hasBeenWon,
          })}>
          {!hasBeenWon ? (
            <div>
              <Translate
                id="label.jackpot-drop.must-drop-in"
                defaultMessage="Must drop in"
                tagName="span"
              />
              <span>
                {twoDigits(hours)}:{twoDigits(minutes)}:{twoDigits(seconds)}
              </span>
            </div>
          ) : (
            nextJackpotValue && (
              <div>
                <Translate
                  id="label.jackpot-drop.next-jackpot"
                  defaultMessage="Next jackpot"
                  tagName="span"
                />
                <Money
                  value={hasBeenWon ? jackpotValue : nextJackpotValue}
                  currency={currency}
                />
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

const twoDigits = n => (n < 10 ? '0' : '') + n;

const calculateTimeLeft = date => {
  const difference = Number(date) - Number(new Date());
  if (!difference) return;

  return {
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  };
};

export default JackpotWidget;

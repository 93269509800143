import React, { Component } from 'react';
import { connect } from 'react-redux';
import OffCanvasLayout from '@components/OffCanvasLayout';
import withRouteData, { Loader } from '@utils/withRouteData';
import { loadContents } from '@actions/content';
import marked from 'marked';
import { ResponsibleGamingWidget } from 'tg-core-components';
import Alert from '@components/Alert';
import {
  selfBlock,
  selfExclude,
  setMultipleLimits,
  deleteLimit,
  confirm,
  decline,
} from 'tg-core-redux/lib/modules/responsible_gaming/action';
import { withRouter } from 'react-router';
import { openHash } from '@utils/hash';
import getByPath from 'lodash/get';
import { getLimit } from 'tg-core-redux/lib/modules/responsible_gaming/action';
import { withConfig } from '@config';

@withRouter
@withRouteData
@withConfig
@connect(
  state => ({
    pages: state.content.pages.data,
    sets: state.content.sets.data,
    limits: state.responsible_gaming.data,
    limitsConfirm: state.responsible_gaming.confirms,
    isLoading: state.responsible_gaming.isLoading,
    user: state.player.user,
    sessionId: state.player.sessionId,
    jurisdiction: state.app.jurisdiction,
    activeWalletCurrency: state.player.user?.Currency,
  }),
  {
    selfBlock,
    selfExclude,
    setMultipleLimits,
    deleteLimit,
    confirm,
    decline,
    getLimit,
  }
)
class ResponsibleGamingContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard/responsible-gaming'],
    },
    {
      name: 'set',
      identifiers: ['responsible-gaming-new'],
    },
    {
      name: 'section',
      identifiers: [
        'responsible-gaming-confirm-block-new',
        'responsible-gaming-confirm-selfexclude-new',
      ],
    },
  ]);
  state = {
    hasCompleted: false,
    hasConfirmed: false,
  };

  componentDidMount() {
    const { getLimit, sessionId } = this.props;

    getLimit(sessionId);
  }

  componentDidUpdate() {
    const { history } = this.props;
    const { hasConfirmed, hasCompleted } = this.state;

    /**
     * Perform actions when returning to this page from e.g. confirm popup or selftest
     */
    const { action } = getByPath(history, 'location.state', {});
    const { type, payload } = action || {};
    switch (type) {
      case 'confirm': {
        const { didConfirm } = payload;
        const { selfBlock, selfExclude, sessionId } = this.props;
        const type = getByPath(this.multipleLimits, 'Type');
        const amount = getByPath(this.multipleLimits, 'Limits[0].Amount');
        if (didConfirm && !hasConfirmed && type === 'Block') {
          selfBlock(sessionId, amount);
          this.setState({
            hasConfirmed: true,
          });
        }

        if (didConfirm && !hasConfirmed && type === 'SelfExclude') {
          selfExclude(sessionId, amount);
          this.setState({
            hasConfirmed: true,
          });
        }
        break;
      }
      case 'selftest': {
        const { didComplete, PgsiScore } = payload;
        if (didComplete && !hasCompleted) {
          this.submitLimits(
            {
              ...this.multipleLimits,
              PgsiScore,
            },
            this.deleteLimits
          );
          this.setState({
            hasCompleted: true,
          });
        }
        break;
      }
    }
  }

  onSubmit = ({ type, product, limits, validationState }) => {
    const { history } = this.props;

    const newLimits = limits
      .filter(l => l.Amount > 0)
      .map(l => ({ Amount: l.Amount, Timespan: l.Timespan }));

    this.multipleLimits = { Type: type, Product: product, Limits: newLimits };

    this.deleteLimits = limits
      .filter(l => l.Amount === 0 && l.Id)
      .map(l => l.Id);

    if (validationState['show-confirm-on-submit']) {
      history.push({
        hash: openHash(history.location.hash, '#popup/confirm'),
        state: {
          contentId: `responsible-gaming-confirm-${type}-new`.toLowerCase(),
        },
      });
      return Promise.resolve();
    }

    return this.submitLimits(this.multipleLimits, this.deleteLimits);
  };

  submitLimits = (multipleLimits, deleteLimits) => {
    const { setMultipleLimits, deleteLimit, sessionId } = this.props;
    if (!multipleLimits || !deleteLimits) return Promise.resolve();
    return Promise.all([
      setMultipleLimits(sessionId, multipleLimits),
      ...deleteLimits.map(id => deleteLimit(sessionId, id)),
    ]);
  };

  onConfirmLimit = id => this.props.confirm(this.props.sessionId, id);

  onDeclineLimit = id => this.props.decline(this.props.sessionId, id);

  render() {
    const {
      isRouteDataLoading,
      pages,
      sets,
      limits,
      limitsConfirm,
      isLoading,
      user,
      jurisdiction,
      activeWalletCurrency,
      config,
    } = this.props;

    const limitsPerCurrency = limits?.filter(
      l => l?.Currency === activeWalletCurrency
    );

    const page =
      pages && pages.find(p => p.url === '#dashboard/responsible-gaming');
    const content =
      sets && sets.find(s => s.identifier === 'responsible-gaming-new');

    return (
      <OffCanvasLayout
        className="ResponsibleGamingContainer"
        backUrl="#dashboard">
        <Loader
          isLoading={
            isRouteDataLoading || !limits || !limitsConfirm || !content
          }>
          {page && page.content && (
            <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
          )}
          {config.multiCurrency && (
            <Alert
              id="alert_message.warning.limit-currency-info"
              defaultMessage="These are the limits for your chosen wallet {activeWalletCurrency}."
              level="warning"
              values={{ activeWalletCurrency: activeWalletCurrency }}
            />
          )}
          <ResponsibleGamingWidget
            content={content}
            limits={[...(limitsPerCurrency || []), ...(limitsConfirm || [])]}
            onSubmit={this.onSubmit}
            isLoading={isLoading}
            onConfirmLimit={this.onConfirmLimit}
            onDeclineLimit={this.onDeclineLimit}
            currency={user && user.Currency}
            jurisdiction={jurisdiction}
          />
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default ResponsibleGamingContainer;

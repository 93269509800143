import React, { Fragment } from 'react';
import marked from 'marked';
import { Button, Translate } from 'tg-core-components';

const ActivateSuccess = ({ content, history }) => (
  <Fragment>
    {content && content.content && (
      <div
        className="ActivateSuccess"
        dangerouslySetInnerHTML={{
          __html: marked(content.content),
        }}
      />
    )}

    <Button
      className="button primary"
      onClick={() => history.replace('/casino')}>
      <Translate id="label.activate-continue" defaultMessage="Continue" />
    </Button>
  </Fragment>
);

export default ActivateSuccess;

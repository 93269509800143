import React from 'react';
import { Select, Button } from 'tg-core-components';
import { validateRules, required } from '../../validation';
import cn from 'classnames';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { getContinueLabel } from '../../helpers';

const Nationality = ({
  /* Props from parent */
  errors,
  onChange,
  intl,
  fields,
  nextStep,
  statuses,

  /* Props from selector */
  nationalities,
  device,
}) => {
  const isValid = statuses.Nationality === 'success';

  return (
    <form onSubmit={nextStep}>
      {nationalities?.value?.length > 1 && (
        <Select
          autoFocus
          label={translate(
            { id: 'label.nationality', defaultMessage: 'Nationality' },
            intl
          )}
          value={fields.Nationality}
          name="Nationality"
          onChange={(_, Nationality) => onChange({ Nationality })}
          status={statuses.Nationality}
          statusText={
            errors.Nationality &&
            translate(
              {
                id: errors.Nationality,
                defaultMessage: 'Please select nationality.',
              },
              intl
            )
          }>
          {!fields.Nationality && (
            <Select.Option value="" selected disabled>
              {translate(
                {
                  id: 'label.nationality',
                  defaultMessage: 'Nationality',
                },
                intl
              )}
            </Select.Option>
          )}
          {nationalities?.value?.map(c => (
            <Select.Option key={c.value} value={c.value}>
              {c.label}
            </Select.Option>
          ))}
        </Select>
      )}

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ Nationality }) => {
  const rules = {
    Nationality: [[required, 'error.empty.nationality']],
  };
  const errors = await validateRules({ Nationality }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  device: state.app.device,
  nationalities: state.content.config.data?.find(
    c => c.key === 'nationalities'
  ),
});

export default {
  name: 'nationality',
  icon: 'user',
  Component: Nationality,
  validate,
  selector,
  fields: ['Nationality'],
};

import React, { useState } from 'react';
import { Button, Icon } from 'tg-core-components';
import { useCookies } from 'react-cookie';
import { useConfig } from '@utils/config';
import { withRouter } from 'react-router';
import { openHash } from '@utils/hash';
import marked from 'marked';
import { Helmet } from 'react-helmet';
import { useAddToHomescreenPrompt } from '@utils/hooks';

import './style.css';

export const isPWA = () => {
  const isStandalone =
    __CLIENT__ && window.matchMedia('(display-mode: standalone)').matches;

  return (
    (__CLIENT__ && document.referrer.startsWith('android-app://')) ||
    (__CLIENT__ && navigator.standalone) ||
    isStandalone
  );
};

export const selectInstallAppProps = state => ({
  device: state.app.device,
  operatingSystem: state.app.operatingSystem,
  sections: state.content.sections.data,
});

const InstallApp = ({
  /* Props from selector */
  device,
  operatingSystem,
  sections,

  /* Props from withRouter */
  history,
  location,
}) => {
  const config = useConfig();
  const [cookies, setCookie] = useCookies(['install-app']);
  const [hideNotice, setHideNotice] = useState(
    cookies['install-app'] === 'false'
  );

  const [prompt, promptInstall] = useAddToHomescreenPrompt();

  if (hideNotice || isPWA() || (!prompt && operatingSystem === 'Android'))
    return null;

  const onClose = () => {
    setHideNotice(true);

    setCookie('install-app', false, {
      path: '/',
      expires: new Date(Date.now() + 31556952000 * 2),
      secure: config.CURRENT_ENV === 'prod',
    });
  };

  const onInstall = () => {
    if (device === 'Desktop' || operatingSystem === 'iOS') {
      return history.push({ hash: openHash(location.hash, '#popup/app') });
    }

    if (operatingSystem === 'Android') {
      promptInstall();
    }
  };

  const content = sections?.find(s => s.identifier === 'install-app-prompt')
    ?.content;

  if (!content) return null;

  return (
    <>
      <Helmet
        bodyAttributes={{
          'data-install-app':
            __CLIENT__ && !window.navigator.standalone ? true : false,
        }}
      />
      <div className={'InstallApp' + (!__CLIENT__ ? ' serverside' : '')}>
        {content && (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: marked(content) }}
          />
        )}

        <div className="buttons">
          <Button className="button icon" onClick={onInstall}>
            <Icon icon="download" />
          </Button>
          <Button className="button icon" onClick={onClose}>
            <Icon icon="close" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default withRouter(InstallApp);

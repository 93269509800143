import React, { Component } from 'react';
import ChangePasswordWidget from 'tg-core-components/lib/widgets/ChangePassword';
import { connect } from 'react-redux';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import marked from 'marked';
import OffCanvasLayout from '@components/OffCanvasLayout';
import withRouteData, { Loader } from '@utils/withRouteData';
import { loadContents } from '@actions/content';

@withRouteData
@connect(state => ({
  sessionId: state.player.sessionId,
  pages: state.content.pages.data,
  sections: state.content.sections.data,
  status: state.player.changePasswordStatus,
}))
class ChangePassword extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard/change-password'],
    },
    {
      name: 'section',
      identifiers: ['change-password-success', 'change-password-failure'],
    },
  ]);

  handleSubmit = passwordInfo => {
    this.props.dispatch(
      playerActions.changePassword(
        {
          CurrentPassword: passwordInfo.CurrentPassword,
          NewPassword: passwordInfo.NewPassword,
        },
        this.props.sessionId
      )
    );
  };

  render() {
    const { pages, sections, status } = this.props;

    const page = pages?.find(p => p.url === '#dashboard/change-password');
    const successSection = sections.find(
      s => s.identifier === 'change-password-success'
    );
    const failureSection = sections.find(
      s => s.identifier === 'change-password-failure'
    );

    return (
      <OffCanvasLayout backUrl="#dashboard">
        <Loader isLoading={!page}>
          <div>
            {page?.content && status !== 'COMPLETE' && (
              <header
                dangerouslySetInnerHTML={{ __html: marked(page.content) }}
              />
            )}
            {status === 'FAILURE' && (
              <div
                className="failure-section"
                dangerouslySetInnerHTML={{
                  __html: marked(
                    failureSection?.content || 'Password could not be updated'
                  ),
                }}
              />
            )}
            {status === 'COMPLETE' ? (
              <div
                className="success-section"
                dangerouslySetInnerHTML={{
                  __html: marked(
                    successSection?.content || 'Password successfully updated'
                  ),
                }}
              />
            ) : (
              <ChangePasswordWidget onSubmitForm={this.handleSubmit} />
            )}
          </div>
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default ChangePassword;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'tg-core-components';
import marked from 'marked';
import { PAGES } from '../index';

import './style.css';

class Correct extends Component {
  state = {
    showText: false,
    imageSrc: '',
  };

  componentDidMount = () => {
    this.loadGif();
  };

  componentDidUnmount = () => {
    clearTimeout(this.showText);
  };

  loadGif = () => {
    const { day } = this.props;
    const imageSrc = day.correctAnswerImage?.file.url;

    this.setState({ imageSrc: '' });
    setTimeout(() => {
      this.setState({
        imageSrc,
      });
    }, 0);

    if (!imageSrc) this.showText();
  };

  showText = () => {
    this.setState({ showText: true });
  };

  onClickCta = () => {
    window.location.hash = '';
  };

  onClickBack = e => {
    e.preventDefault();
    this.props.setPage(PAGES.CALENDAR);
  };

  render() {
    const { day } = this.props;
    const { imageSrc, showText } = this.state;

    return (
      <div className="Correct">
        <img
          src={imageSrc}
          alt="correct"
          className="correct-image"
          onLoad={() => {
            setTimeout(this.showText, 1300);
          }}
        />

        <div className={`correct-content${showText ? ' transform-scale' : ''}`}>
          <a className="back" href="#" onClick={this.onClickBack}>
            <Translate
              id="action.back-to-calendar"
              defaultMessage="Back to calendar"
            />
          </a>

          {day?.description && (
            <div
              dangerouslySetInnerHTML={{ __html: marked(day.description) }}
              className="correct-description"
            />
          )}

          {day?.action && day?.cta && (
            <Link
              className="button primary large correct-cta"
              onClick={this.onClickCta}
              to={day.action}>
              {day.cta}
            </Link>
          )}
        </div>

        {day?.extraInfo && (
          <div
            dangerouslySetInnerHTML={{ __html: marked(day.extraInfo) }}
            className={`correct-extra-info${
              showText ? ' transform-opacity' : ''
            }`}
          />
        )}
      </div>
    );
  }
}

export default Correct;

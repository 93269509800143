export const getNonAcceptedAgreements = ({
  agreements,
  acceptedAgreements,
}) => {
  return (
    agreements?.reduce((nonAccepted, agreement) => {
      if (!acceptedAgreements?.find(a => a === agreement.identifier))
        nonAccepted.push(agreement);
      return nonAccepted;
    }, []) || []
  );
};

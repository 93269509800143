import React, { useState } from 'react';
import Icon from 'tg-core-components/lib/components/Icon';
import cn from 'classnames';

import './style.css';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  return (
    <div className="Accordion">
      {items?.map((item, k) => {
        return (
          <div
            key={k}
            className={cn('Accordion__item', {
              'Accordion__item--expanded': k === activeIndex,
            })}>
            <div
              className="Accordion__item__header"
              onClick={() => setActiveIndex(k === activeIndex ? null : k)}>
              {item.header}
              <Icon icon={'caret-down'} />
            </div>
            <div className="Accordion__item__content">{item.content}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;

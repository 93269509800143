import React, { Component, Fragment } from 'react';
import { loadScript, removeScript } from '@utils/script';
import { withConfig } from '@config';
import { withRouter } from 'react-router';
import exitIcon from '@images/cancel.svg';
import Loader from '@components/Loader';

@withRouter
@withConfig
class BonusWheel extends Component {
  state = {
    isLoading: true,
    bonus: null,
  };

  async componentDidMount() {
    const {
      config: { bonusWheel, bonusProvider },
      bonuses,
      history,
      claimBonus,
      player,
      logo,
      wheel,
      center,
      ticker,
      frame,
      overlay,
      background,
      tickerPosition,
      disabled,
    } = this.props;

    if (!bonusWheel) return null;

    await loadScript(bonusWheel.host, 'bonus-wheel-script');

    window.BonusWheel &&
      window.BonusWheel.init({
        element: '#bonus-wheel',
        bonuses,
        tickerPosition,
        disabled,
        assets: {
          logo,
          wheel,
          center,
          ticker,
          frame,
          overlay,
          background,
          closeIcon: exitIcon,
        },
        onBonus: bonus => {
          this.setState({ bonus });

          if (player.isAuthenticated && player.sessionId && bonus.code) {
            claimBonus(player.sessionId, bonus.id, bonusProvider);
          }
        },
        onAction: e => {
          e.preventDefault();
          history.replace(this.state.bonus.url || '/casino');
        },
        onBack: e => {
          e.preventDefault();
          history.replace('/casino');
        },
        onLoad: () => {
          this.setState({ isLoading: false });
        },
      });
  }

  componentWillUnmount() {
    removeScript('bonus-wheel-script');
  }

  render() {
    return (
      <Fragment>
        {this.state.isLoading && <Loader />}

        <div id="bonus-wheel" />
      </Fragment>
    );
  }
}

export default BonusWheel;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  optIn,
  optOut,
  getOptInOuts,
} from 'tg-core-redux/lib/modules/player/action';
import Loader from '@components/Loader';
import marked from 'marked';
import OffCanvasLayout from '@components/OffCanvasLayout';
import { intersectionBy, toLower } from 'lodash';
import { Input } from 'tg-core-components';
import { loadContents } from '@actions/content';
import withRouteData from '@utils/withRouteData';

import './style.css';

const SubscribeItem = ({ subscribe, onChange }) => (
  <div className="subscribe-item">
    <Input
      type="checkbox"
      label={subscribe.content}
      onChange={() => onChange(subscribe)}
      value={subscribe.types.some(optInOut => optInOut.OptIn) ? 'true' : ''}
    />
  </div>
);

@withRouteData
@connect(
  state => ({
    sessionId: state.player.sessionId,
    pages: state.content.pages.data,
    sets: state.content.sets.data,
    optInOut: state.optInOut,
  }),
  { optIn, optOut, getOptInOuts }
)
class Subscriptions extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard/subscriptions'],
    },
    {
      name: 'set',
      identifiers: ['subscriptions'],
    },
  ]);

  state = { isLoading: true };

  async componentDidMount() {
    const { sessionId, getOptInOuts } = this.props;

    await getOptInOuts(sessionId);
    this.setState({ isLoading: false });
  }

  handleToggle = subscribe => {
    const { optIn, optOut, sessionId } = this.props;

    if (subscribe.types.some(optInOuts => optInOuts.OptIn))
      subscribe.types.forEach(optInOut => optOut(sessionId, optInOut.Type));
    else subscribe.types.forEach(optInOut => optIn(sessionId, optInOut.Type));
  };

  checkTypes = types => {
    const { optInOut } = this.props;

    const intersection = intersectionBy(
      types,
      optInOut.data.map(d => d.Type),
      toLower
    ).map(toLower);

    return optInOut.data.filter(o =>
      intersection.includes(o.Type.toLowerCase())
    );
  };

  render() {
    const { pages, sets } = this.props;
    const { isLoading } = this.state;

    const page = pages?.find(p => p.url === '#dashboard/subscriptions');
    let subscriptions = sets?.find(s => s.identifier === 'subscriptions');

    if (!isLoading) {
      subscriptions = subscriptions?.items.map(sub => ({
        content: sub.content,
        types: this.checkTypes(
          sub.action.split(',').map(action => action.trim())
        ),
      }));
    }

    return (
      <OffCanvasLayout className="SubscriptionsContainer" backUrl="#dashboard">
        {isLoading ? (
          <Loader inline />
        ) : (
          <div className="newsletter-container">
            {page?.content && (
              <header
                dangerouslySetInnerHTML={{ __html: marked(page.content) }}
              />
            )}

            <div className="newsletter-buttons">
              {(subscriptions || []).map((subscribe, i) => (
                <SubscribeItem
                  subscribe={subscribe}
                  onChange={this.handleToggle}
                  key={i}
                />
              ))}
            </div>
          </div>
        )}
      </OffCanvasLayout>
    );
  }
}

export default Subscriptions;

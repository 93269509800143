import React from 'react';
import marked from 'marked';

const ResponsibleGaming = ({ content }) =>
  content && content.content ? (
    <div
      className="ResponsibleGaming"
      dangerouslySetInnerHTML={{
        __html: marked(content.content),
      }}
    />
  ) : null;

export default ResponsibleGaming;

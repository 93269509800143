import React from 'react';
import { FormattedDate } from 'react-intl';

const Timestamp = ({ timestamp }) => (
  <div className="date">
    <FormattedDate
      value={timestamp}
      year="numeric"
      month="2-digit"
      day="2-digit"
      hour="2-digit"
      minute="2-digit"
    />
  </div>
);

export default Timestamp;

import React from 'react';
import Game, { selectGameProps } from '@components/Game';
import { CategoryRow, SectionRow } from '@components/CasinoCategoryRow';
import TopListNumber from '@components/TopListNumber';
import { CATEGORY_TYPES } from '@utils/casino';
import cn from 'classnames';

import './style.css';

export const selectCasinoGridProps = state => ({
  gameProps: selectGameProps(state),
});

const CasinoGrid = ({
  /* props from parent */
  items,
  className,
  toggleFavoriteGameById,
  title,
  disableLargeThumbnail,
  gridGamesToShow,
  gameProps,
  isCategory,
  isSection,
  type,
}) => {
  if (!items?.length) return null;

  const gridGames = disableLargeThumbnail
    ? items.slice(0, gridGamesToShow || 10)
    : items.slice(0, gridGamesToShow || 7);

  return (
    <div
      className={cn('CasinoGrid', className, {
        'section-grid': isSection,
        'category-grid': isCategory,
      })}>
      <div className="CasinoGrid__header">{title}</div>
      <div className="CasinoGrid__grid">
        {gridGames.map((g, k) => {
          if (isCategory) return <CategoryRow {...g} key={g.identifier} />;

          if (isSection) return <SectionRow {...g} key={g.identifier} />;

          return (
            <div
              className={cn('CasinoGrid__item ', {
                'CasinoGrid__item--large': !disableLargeThumbnail && k === 0,
              })}
              key={k}>
              <Game
                game={g}
                className={cn({
                  'large-thumbnail': !disableLargeThumbnail && k === 0,
                })}
                toggleFavoriteGameById={toggleFavoriteGameById}
                canShowVideoPreview
                type={type}
                {...gameProps}
              />
              {type === CATEGORY_TYPES.LIST && <TopListNumber number={++k} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CasinoGrid;

import { max, min, validateRules } from '@components/PayNPlay/validation';
import selectCurrency from '@selectors/selectCurrency';
import { useConfig } from '@utils/config';
import cn from 'classnames';
import marked from 'marked';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Money, Translate } from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { withRouter } from 'react-router';
import { transformAmount } from '@components/PayNPlay/helpers';

import './style.css';

const Amount = ({
  /* Props from parent */
  fields,
  statuses,
  onChange,
  errors,
  intl,
  nextStep,
  minDepositAmount,
  maxDepositAmount,
  bonusCode,
  isInline,

  /* Props from selector */
  sections,
  isAuthenticated,
  contentfulConfig,
  currency,

  /* Props from withRouter */
  history,
}) => {
  const config = useConfig();

  const [showBonusCodeInput, setShowBonusCodeInput] = useState(false);

  const goTostartPlaying = amount => {
    const params = queryString.stringify({
      depositAmount: amount || fields.Amount,
      bonusCode: fields.BonusCode,
    });

    history.push(`/start-playing${params ? `?${params}` : ''}`);
  };

  const inlinePnpSection = sections?.find(s => s.identifier === 'inline-pnp');
  const backgroundUrl = inlinePnpSection?.backgroundImage?.file.url;

  const quickAmounts = contentfulConfig?.find(c => c.key === 'quick-amounts');
  const amounts = config.showQuickAmounts && quickAmounts?.value?.[currency];

  const stepContent = sections?.find(
    s => s.identifier === 'header.paynplay.amount'
  )?.content;

  const onSubmit = (e, amount) => {
    e.preventDefault();

    if (isInline) {
      goTostartPlaying(amount);
    } else {
      nextStep();
    }
  };

  const isValid = statuses.Amount === 'success';

  return (
    <div
      className="Amount"
      style={
        isInline && backgroundUrl
          ? {
              backgroundImage: `url(${backgroundUrl})`,
            }
          : {}
      }>
      {stepContent && !isInline && (
        <div
          className="Amount__content"
          dangerouslySetInnerHTML={{ __html: marked(stepContent) }}
        />
      )}

      {inlinePnpSection?.content && isInline && (
        <div
          className="Amount__content"
          dangerouslySetInnerHTML={{ __html: marked(inlinePnpSection.content) }}
        />
      )}

      <form onSubmit={onSubmit}>
        {amounts?.length > 0 && (
          <div className="Amount__quick-amounts">
            {amounts?.map((quickAmount, i) => (
              <Button
                key={i}
                className={cn('button primary wide', {
                  active: quickAmount === fields.Amount,
                })}
                onClick={e => {
                  onChange({ Amount: quickAmount });
                  onSubmit(e, quickAmount);
                }}
                type="button">
                <Money
                  value={quickAmount}
                  currency={currency}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Button>
            ))}
          </div>
        )}

        <div className="Amount__custom-amount">
          <Input
            autoFocus
            type="text"
            inputMode="numeric"
            name="Amount"
            value={fields.Amount}
            placeholder={translate(
              {
                id: 'label.paynplay.amount_placeholder',
                defaultMessage: 'Enter amount ({currency})',
                values: { currency },
              },
              intl
            )}
            onChange={(_, Amount) => onChange({ Amount })}
            transform={transformAmount}
            status={statuses.Amount}
            statusText={
              errors.Amount &&
              translate(
                {
                  id: errors.Amount,
                  values: {
                    minAmount: minDepositAmount,
                    maxAmount: maxDepositAmount,
                    currency,
                  },
                },
                intl
              )
            }
          />
        </div>

        {showBonusCodeInput && (
          <Input
            type="text"
            className="Amount__bonus-code-input"
            name="BonusCode"
            autoFocus
            onChange={(_, BonusCode) => onChange({ BonusCode })}
            placeholder={translate(
              {
                id: 'label.paynplay.bonus-code-placeholder',
                defaultMessage: 'Bonus code',
              },
              intl
            )}
          />
        )}

        <Button className="button primary" type="submit" disabled={!isValid}>
          <Translate id="paynplay.amount.deposit" defaultMessage="Deposit" />
        </Button>

        {config.showPnpBonusCodeInput && !bonusCode && (
          <div className="Amount__bonus-code-button">
            {!showBonusCodeInput && (
              <a
                href=""
                onClick={e => {
                  e.preventDefault();
                  setShowBonusCodeInput(true);
                }}>
                <Translate
                  id="paynplay.amount.show-bonus-code"
                  defaultMessage="I have a bonus code"
                />
              </a>
            )}
          </div>
        )}
      </form>

      <div className="Amount__tac">
        <Translate
          id="message.paynplay.accept_tac"
          defaultMessage="By continuing you accept our {terms} and {privacy}"
          values={{
            terms: (
              <Link to="#popup/terms-and-conditions">
                {translate(
                  {
                    id: 'label.terms-and-conditions',
                    defaultMessage: 'Terms &amp; Conditions',
                  },
                  intl
                )}
              </Link>
            ),
            privacy: (
              <Link to="#popup/privacy-policy">
                {translate(
                  {
                    id: 'label.privacy-policy',
                    defaultMessage: 'Privacy policy',
                  },
                  intl
                )}
              </Link>
            ),
          }}
        />
      </div>

      {!isAuthenticated && !isInline && (
        <div className="Amount__question">
          <Translate
            id="message.paynplay.have_account"
            defaultMessage="Have you played here before? {link}"
            values={{
              link: (
                <Button
                  onClick={() => {
                    nextStep();
                    onChange({ Amount: null });
                  }}
                  className="button secondary small">
                  {translate(
                    {
                      id: 'action.fetch_balance',
                      defaultMessage: 'Fetch balance',
                    },
                    intl
                  )}
                </Button>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};

const validate = async ({ Amount }, _, minDepositAmount, maxDepositAmount) => {
  const rules = {
    Amount: [
      [min(minDepositAmount), 'label.paynplay.below_minimum'],
      [max(maxDepositAmount), 'label.paynplay.above_maximum'],
    ],
  };
  const errors = await validateRules({ Amount }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  sections: state.content.sections.data,
  isAuthenticated: state.player.isAuthenticated,
  contentfulConfig: state.content.config.data,
  currency: selectCurrency(state),
});

export default {
  name: 'amount',
  Component: withRouter(Amount),
  validate,
  selector,
  fields: ['Amount'],
};

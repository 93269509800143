import { combineEpics, ofType } from 'redux-observable';
import { showToast } from '@actions/toast';
import { createAlertId } from '@components/Alert';
import { getIntl } from '@utils/translations';
import getByPath from 'lodash/get';
import { tap, ignoreElements, filter, map } from 'rxjs/operators';

const handleShowError = (action$, state$) =>
  action$.pipe(
    filter(action => action.payload && action.payload.isNetworkException),
    filter(
      action =>
        ![
          'SIGNIN_PNP_FAILURE',
          'SIGNINV2_FAILURE',
          'SIGNINWITHCODE_FAILURE',
          'SIGNUPV2_FAILURE',
          'SIGNUPWITHCODE_FAILURE',
          'SIGNUPWITHTOKEN_FAILURE',
          'GET_UNPUSHED_MESSAGES_FAILURE',
          'SET_LIMIT_FAILURE',
          'PLAYER_UPDATE_FAILURE',
          'REQUEST_PASSWORD_RESET_FAILURE',
          'CHANGE_PASSWORD_FAILURE',
        ].includes(action.type)
    ),
    tap(action => {
      const result = getByPath(action, 'payload.value.response.data');
      if (typeof result === 'object') {
        // ignore invalid session id error
        if (result.Code === 2) return;

        let text = getIntl(state$.value).formatMessage({
          id: `error_message.aleacc.${action.type}.${result.Code}`,
          defaultMessage: result.Message,
        });

        if (result.Code === 'PlayerSelfBlocked') {
          text = getIntl(state$.value).formatMessage({
            id: 'error_message.playerselfblocked',
          });
        }

        return window.reduxStore.dispatch(
          showToast({
            text,
            toastType: 'ERROR',
          })
        );
      }

      if (typeof result === 'string' && result.length > 0) {
        return window.reduxStore.dispatch(
          showToast({
            text: result,
            toastType: 'ERROR',
          })
        );
      }
    }),
    ignoreElements()
  );

const showErrorToast = (action$, state$) =>
  action$.pipe(
    ofType('PLAYER_UPDATE_FAILURE'),
    map(action =>
      showToast({
        text: getIntl(state$.value).formatMessage({
          id: createAlertId(action, 'danger'),
        }),
        toastType: 'ERROR',
      })
    )
  );

export default combineEpics(handleShowError, showErrorToast);

import Cookies from 'universal-cookie';
import { combineEpics, ofType } from 'redux-observable';
import { getConfig } from '@config';
import { tap, ignoreElements } from 'rxjs/operators';

const contentPreview = (action$, state$) =>
  action$.pipe(
    ofType('CONTENT_FETCH_CONFIG_COMPLETE'),
    tap(() => {
      const { app, content } = state$.value;
      const cookies = new Cookies();
      const authorizedIPs = content.config.data.find(
        c => c.key === 'content-preview-ips'
      );
      const isAuthorizedForPreview =
        authorizedIPs && authorizedIPs.value.some(IP => IP === app.ip);

      if (isAuthorizedForPreview && !cookies.get('block_content_preview')) {
        cookies.set('content_preview', 'activated', {
          secure: getConfig().CURRENT_ENV === 'prod',
          expires: new Date(Date.now() + 31556952000 * 2),
        });
      }
    }),
    ignoreElements()
  );

export default combineEpics(contentPreview);

import React from 'react';
import { Input, Select, countryEmojiFlags, Button } from 'tg-core-components';
import {
  validateRules,
  required,
  stringWithoutNumbers,
  noSpecialCharacters,
} from '../../validation';
import cn from 'classnames';
import { getContinueLabel } from '../../helpers';
import { useConfig, getConfig } from '@utils/config';

import translate from 'tg-core-components/lib/lib/utils/translate';

const Country = ({
  /* Props from parent */
  errors,
  onChange,
  intl,
  fields,
  nextStep,
  statuses,

  /* Props from selector */
  countries,
  device,
  ipCountry,
}) => {
  const config = useConfig();
  const overrideCountry = config?.overrideSignUpCountryMap?.[ipCountry];
  const isValid = !overrideCountry
    ? statuses.Zip === 'success' && statuses.Country === 'success'
    : statuses.Zip === 'success';
  const accountCountries = countries?.value.filter(c => c.type === 'account');

  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <Input
        autoFocus={!fields.Zip}
        label={translate({ id: 'label.zip', defaultMessage: 'Postcode' }, intl)}
        value={fields.Zip}
        name="Zip"
        onChange={(_, Zip) => onChange({ Zip })}
        status={statuses.Zip}
        statusText={
          errors.Zip &&
          translate(
            { id: errors.Zip, defaultMessage: 'Please enter your post code' },
            intl
          )
        }
      />

      {!overrideCountry && (
        <Select
          autoFocus={fields.Zip}
          name="Country"
          value={fields.Country}
          onChange={(_, Country) => onChange({ Country })}
          status={statuses.Country}
          statusText={
            errors.Country && translate({ id: errors.Country }, intl)
          }>
          {!fields.Country && (
            <Select.Option value="" selected disabled>
              {translate(
                {
                  id: 'label.country',
                  defaultMessage: 'Country',
                },
                intl
              )}
            </Select.Option>
          )}
          {(accountCountries?.length ? accountCountries : countries?.value)
            ?.sort((a, b) => a.label.localeCompare(b.label))
            ?.map(c => (
              <Select.Option key={c.value} value={c.value}>
                {`${countryEmojiFlags[c.value] || ''} ${c.label}`}
              </Select.Option>
            ))}
        </Select>
      )}

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ Zip, Country }, jurisdiction) => {
  const { overrideCountry } = getConfig(jurisdiction);
  const rules = {
    Zip: [
      [required, 'error.empty.zip'],
      [noSpecialCharacters, 'error.format.zip'],
    ],
  };
  if (!overrideCountry)
    rules.Country = [
      [required, 'error.empty.country'],
      [stringWithoutNumbers, 'error.format.country'],
    ];
  const errors = await validateRules({ Country, Zip }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  countries: state.content.config.data?.find(c => c.key === 'countries'),
  ipCountry: state.app.ipCountry,
  device: state.app.device,
});

export default {
  name: 'country',
  icon: 'user',
  Component: Country,
  validate,
  selector,
  fields: ['Zip', 'Country'],
};

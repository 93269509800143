import React from 'react';
import compose from 'recompose/compose';
import WithValidation from 'tg-core-components/lib/lib/WithValidation';
import { Button, Input, Translate } from 'tg-core-components';
import require from 'tg-core-components/lib/lib/WithValidation/rules/required';
import { injectIntl } from 'react-intl';
import translate from 'tg-core-components/lib/lib/utils/translate';

const ActivateForm = ({
  errors,
  intl,
  onSubmit,
  changeEmail,
  changeCode,
  email,
  code,
  emailInputType,
  codeInputType,
  onBlur,
}) => (
  <div className="ActivateForm">
    <form className="form" onSubmit={onSubmit}>
      <Input
        label={translate(
          {
            id: 'label.email',
            defaultMessage: 'Email',
          },
          intl
        )}
        type={emailInputType}
        name="email"
        onBlur={e => onBlur('email', e.target.value)}
        status={(errors.email && 'failure') || 'idle'}
        statusText={errors.email && translate({ id: errors.email }, intl)}
        onChange={changeEmail}
        value={email}
        autocomplete="email"
      />

      <Input
        label={translate(
          {
            id: 'label.activation_code',
            defaultMessage: 'Activation code',
          },
          intl
        )}
        type={codeInputType}
        name="activation_code"
        status={(errors.activation_code && 'failure') || 'idle'}
        statusText={
          errors.activation_code &&
          translate({ id: errors.activation_code }, intl)
        }
        onChange={changeCode}
        value={code}
        autoComplete="one-time-code"
      />

      <div className="layout-item">
        <Button className="button primary wide" type="submit">
          <Translate id="label.activate" defaultMessage="Activate" />
        </Button>
      </div>
    </form>
  </div>
);

const rules = {
  email: [[require, 'error.empty.email']],
  activation_code: [[require, 'error.empty.activation_code']],
};

export default compose(WithValidation(rules), injectIntl)(ActivateForm);

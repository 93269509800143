const openHash = (currentHash, hash) => {
  currentHash = currentHash.replace(/^#\/|^#|^\//g, '').split(',');
  hash = hash.replace(/^#\/|^#|^\//g, '').split(',');
  const newHash = currentHash.includes(hash)
    ? currentHash
    : [currentHash, hash];
  return `#${newHash.join(',').replace(/^,/, '')}`;
};

const closeHash = (currentHash, hash) => {
  currentHash = currentHash.replace(/^#\/|^#|^\//g, '').split(',');
  hash = hash.replace(/^#\/|^#|^\//g, '').split(',');
  const newHash = currentHash.filter(h => !hash.includes(h));
  return `#${newHash.join(',').replace(/^,/, '')}`;
};

export { openHash, closeHash };

import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'tg-core-components';
import marked from 'marked';
import Icon from 'tg-core-components/lib/components/Icon';
import { openHash } from '@utils/hash';

import './style.css';

const BonusItem = ({
  selected,
  onClick,
  image,
  summary,
  termsAndConditions,
  disclaimer,
  identifier,
}) => (
  <div className={`bonus-item ${selected ? ' selected' : ''}`}>
    {selected && <Icon icon="check" />}
    {image && (
      <img src={image.file?.url} alt={image.description || image.title} />
    )}
    <div className="bonus-content-wrapper">
      <div className="bonus-content">
        {summary && (
          <div
            className="bonus-summary"
            dangerouslySetInnerHTML={{ __html: marked(summary) }}
          />
        )}
        {termsAndConditions && (
          <div className="bonus-tac">
            <Link
              to={{
                hash: openHash(
                  location.hash,
                  `#popup/terms-and-conditions/bonus/${identifier}`
                ),
              }}>
              <Translate
                id="action.read-full-tac"
                defaultMessage="READ FULL T&Cs"
              />
            </Link>
          </div>
        )}
        {disclaimer && (
          <div
            className="bonus-disclaimer"
            dangerouslySetInnerHTML={{
              __html: marked(disclaimer),
            }}
          />
        )}
      </div>
      <div className="button primary" onClick={onClick}>
        {selected ? (
          <Translate id="label.deselect-bonus" defaultMessage="Unpick" />
        ) : (
          <Translate id="label.select-bonus" defaultMessage="Pick" />
        )}
      </div>
    </div>
  </div>
);

export default BonusItem;

import React, { Component, Fragment } from 'react';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import marked from 'marked';
import { GTM_EVENTS } from '@utils/google-tag-manager';
import bonusActions from 'tg-core-redux/lib/modules/bonus/action';
import { getKyc } from 'tg-core-redux/lib/modules/kyc/action';
import { getExchangeRates } from 'tg-core-redux/lib/modules/exchange-rates/action';
import { pushGtmEvent } from '@actions/app';
import { onSuccess, onFailure } from '@actions/payment';
import OffCanvasLayout from '@components/OffCanvasLayout';
import BonusBar from '@components/BonusBar';
import { loadContents } from '@actions/content';
import withRouteData, { Loader } from '@utils/withRouteData';
import { openHash } from '@utils/hash';
import { initCashier } from '@utils/cashier';
import { withConfig } from '@config';
import Alerts from '@components/Alerts';
import BlockComponent from '@components/BlockComponent';
import { removeAlerts } from '@actions/alert';

import Deposit from '@pages/Deposit';

@withConfig
@withRouteData
@withRouter
@withCookies
@connect(
  state => ({
    isVerified: state.player.user && state.player.user.IsVerified,
    sessionId: state.player.sessionId,
    user: state.player.user,
    pages: state.content.pages.data,
    jurisdiction: state.app.jurisdiction,
    isAuthenticated: state.player.isAuthenticated,
    promotions: state.content.promotions.data,
    sections: state.content.sections.data,
    bonusOffer: state.bonusOffer,
    locale: state.app.locale,
    alerts: state.alerts.data?.filter(
      a => ['PAYMENT'].includes(a.type) && a.type === 'warning'
    ),
    paymentStatus: state.paymentStatus,
  }),
  {
    getOffers: bonusActions.getOffers,
    getExchangeRates,
    getKyc,
    pushGtmEvent,
    removeAlerts,
    onSuccess,
    onFailure,
  }
)
class DepositContainer extends Component {
  constructor(props) {
    super(props);
    this.cashierRef = React.createRef();
  }
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#deposit'],
    },
    {
      name: 'payment',
    },
    {
      name: 'promotion',
    },
  ]);

  state = {
    isLoading: !this.props.config.piqCashier,
    loading: true,
    redirectToActivate: false,
    hasInitializedPiqCashier: false,
  };

  cashierApi = null;

  componentDidMount = async () => {
    const {
      getOffers,
      sessionId,
      getKyc,
      config,
      pushGtmEvent,
      user,
      locale,
      onSuccess,
      onFailure,
    } = this.props;

    if (!this.state.redirectToActivate) {
      pushGtmEvent({ event: GTM_EVENTS.DEPOSIT_INIT });
    }

    getOffers(sessionId, config?.bonusProvider);
    await getKyc(sessionId);

    if (
      config.piqCashier &&
      user &&
      document.getElementById('cashier')?.childNodes?.length === 0
    ) {
      const { cashierRef } = this;
      this.cashierApi = initCashier({
        cashierRef,
        method: 'deposit',
        config,
        user,
        sessionId,
        locale: this.getLocale(locale),
        onSuccess: transaction => {
          const { txAmount, txAmountCy, txRefId } = transaction.data;

          onSuccess({
            amount: txAmount,
            currency: txAmountCy,
            tx: txRefId,
          });
        },
        onFailure: transaction => {
          const { txAmount, txAmountCy } = transaction.data;

          onFailure({
            amount: txAmount,
            currency: txAmountCy,
          });
        },
        onReady: () => {
          this.cashierApi?.set({
            attributes: {
              bonusCode: this.getBonusCode(),
            },
          });
          this.setState({ loading: false });
        },
      });
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  getLocale = locale => {
    const { config } = this.props;
    if (config.devcode?.localeMap?.[locale])
      return config.devcode.localeMap[locale];

    return locale;
  };

  componentDidUpdate = prevProps => {
    if (prevProps.isVerified !== this.props.isVerified) {
      this.setState({
        redirectToActivate:
          !this.props.config?.disableActivation &&
          !this.props.isVerified &&
          !['sga', 'dga', 'curacao'].includes(
            (this.props.jurisdiction || '').toLowerCase()
          ),
      });
    }
  };

  getBonusCode = () =>
    this.props.overlayMatch?.params?.bonusCode ||
    this.props.location?.state?.bonusCode ||
    this.props.cookies.get('bonusCodeSignUp') ||
    this.props.cookies.get('bonusCodeDeposit');

  onSelectMethodorAccount = () => {
    const offCanvasContent = this.offCanvasLayout?.querySelector(
      '.canvas-content'
    );
    if (offCanvasContent) {
      offCanvasContent.scrollTop = offCanvasContent.scrollHeight;
    }
  };

  scrollTop = () => {
    const offCanvasContent = this.offCanvasLayout?.querySelector(
      '.canvas-content'
    );
    if (offCanvasContent) offCanvasContent.scrollTop = 0;
  };

  render() {
    const {
      pages,
      location,
      className,
      cookies,
      user,
      promotions,
      bonusOffer,
      getExchangeRates,
      history,
      sections,
      alerts,
      removeAlerts,
      paymentStatus,
      config,
    } = this.props;

    const page = pages?.find(p => p.url === '#deposit');

    if (!user) return null;

    if (this.state.redirectToActivate) {
      return (
        <Redirect
          to={{ hash: 'dashboard/activate' }}
          state={{ referrer: location ? location.pathname : '/' }}
        />
      );
    }

    const bonusCode = this.getBonusCode();

    const bonuses =
      bonusOffer &&
      bonusOffer.data
        .reduce((acc, b) => {
          if (b.Provider === 'fasttrack') {
            return b.promotion ? [...acc, b] : acc;
          }

          if (!b.RewardGroups) return;

          const items = b.RewardGroups.map(r => ({
            ...r,
            promotion:
              promotions && promotions.find(c => c.bonusCode === r.PromoCode),
          }));

          const result = items.filter(i => i.promotion);

          return [...acc, ...result];
        }, [])
        .filter(bonus => bonus.promotion)
        .map(bonus => bonus.promotion);

    const pickedBonus = bonuses?.find(b => b.PromoCode === bonusCode);

    const aleaccBonusCode = (pickedBonus && pickedBonus.PromoCode) || bonusCode;

    if (config.piqCashier) {
      this.cashierApi?.set({
        attributes: { bonusCode: aleaccBonusCode },
      });
    }

    if (config?.block?.deposit) {
      const content = sections?.find(
        s => s.identifier === 'block-feature-deposit'
      )?.content;

      return <BlockComponent content={content} className={className} />;
    }

    return (
      <OffCanvasLayout
        className={`DepositContainer ${className ? className : ''}`}
        ref={ref => (this.offCanvasLayout = ref)}>
        <div className="payment-wrapper">
          <Loader isLoading={!page}>
            {page?.content && (
              <header>
                <div
                  dangerouslySetInnerHTML={{ __html: marked(page.content) }}
                />
              </header>
            )}
            <Alerts alerts={alerts} removeAlerts={removeAlerts} />
          </Loader>

          <Fragment>
            {bonuses.length > 0 && !paymentStatus.status && (
              <BonusBar
                promoCode={bonusCode}
                promotions={bonuses}
                onClick={() =>
                  history.push({
                    hash: openHash(location.hash, 'pick-bonus'),
                    state: { preventTransition: true, backUrl: '#deposit' },
                  })
                }
              />
            )}

            {!config.piqCashier && (
              <Deposit
                onSelectMethodOrAccount={this.onSelectMethodorAccount}
                scrollTop={this.scrollTop}
                bonusCode={aleaccBonusCode}
                fromWelcome={Boolean(cookies.get('bonusCodeSignUp'))}
                skipBonusStep={this.props.skipBonusStep}
                getExchangeRates={getExchangeRates}
              />
            )}
          </Fragment>

          {config.piqCashier && <div ref={this.cashierRef} id="cashier" />}
        </div>
      </OffCanvasLayout>
    );
  }
}

export default DepositContainer;

const initialState = {
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ALERT': {
      const alert = action.payload;
      const alerts = state.data.filter(a => a.type !== alert.type);
      return { ...state, data: [...alerts, alert] };
    }

    case 'REMOVE_ALERTS': {
      if (Array.isArray(action.payload)) {
        const alerts = state.data.filter(a => !action.payload.includes(a.type));
        return { ...state, data: alerts };
      }
      const type = action.payload;
      const alerts = state.data.filter(a => a.type !== type);
      return { ...state, data: alerts };
    }

    default: {
      return state;
    }
  }
};

const selectLastPlayedGames = state => {
  const lastPlayedGames = state.casino.lastPlayedGames;
  const allGames = state.content.games.data;
  return lastPlayedGames
    .map(g => {
      if (g.Tables && g.Tables.length > 0) {
        return allGames.find(
          game => game.Tables?.includes(g.tableId) && game.backendId === g.Id
        );
      }
      return allGames.find(game => game.backendId === g.Id);
    })
    .filter(g => g);
};
export default selectLastPlayedGames;

import { makeApiCallAction } from 'tg-core-redux/lib/lib/action';
import http from '@utils/http';
import queryString from 'query-string';
import { getConfig } from '@config';

export const bootstrap = (parameters = {}) => (dispatch, getState) => {
  const { jurisdiction } = getState().app;
  const query = queryString.stringify(parameters);
  const url = `${getConfig(jurisdiction).aleacc.host}/bootstrap?${query}`;

  return dispatch(makeApiCallAction('API_BOOTSTRAP', http.get(url)));
};

export function showGlobalLoader() {
  return {
    type: 'SHOW_GLOBAL_LOADER',
  };
}

export function hideGlobalLoader() {
  return {
    type: 'HIDE_GLOBAL_LOADER',
  };
}

export function pushGtmEvent(payload) {
  return {
    type: 'PUSH_GTM_EVENT',
    payload,
  };
}

export function hideInstallAppBanner(payload) {
  return {
    type: 'HIDE_INSTALL_APP_BANNER',
    payload,
  };
}

import React from 'react';
import { Translate } from 'tg-core-components';
import { Link } from 'react-router-dom';
import Game, { selectGameProps } from '@components/Game';
import { CategoryRow, SectionRow } from '@components/CasinoCategoryRow';
import TopListNumber from '@components/TopListNumber';
import cn from 'classnames';
import Slider from '../Slider';
import { useWindowWidth } from '@utils/hooks';
import { CATEGORY_TYPES } from '@utils/casino';
import { withConfig } from '@config';

import './style.css';

export const selectGameGridProps = state => ({
  device: state.app.device,
  gameProps: selectGameProps(state),
});

const GameGrid = ({
  /* props from parent */
  items = [],
  title,
  className = '',
  toggleFavoriteGameById,
  overview,
  isCategory,
  isSection,
  baseUrl,
  categoryId,
  type,
  isJackpotDrop,
  isSearchOpen,
  category,

  /* props from selector */
  device,

  gameProps,
  config: { casino },
}) => {
  const windowWidth = useWindowWidth();
  if (!items.length) {
    return null;
  }
  const types = { isCategory, isSection, isJackpotDrop };
  const sliderType = Object.keys(types).find(key => types[key] === true);
  const configSettings = casino?.sliderSettings?.[sliderType];

  const createSetting = (breakpoint, nrOfItems) => ({
    breakpoint: breakpoint,
    slidesToShow: nrOfItems,
    expand:
      items.length < nrOfItems
        ? 'expand-right row'
        : items.length === nrOfItems
        ? 'expand-both row'
        : 'expand-both slide',
  });
  const casinoSliderSettings = {
    responsive: [
      createSetting(1200, configSettings ? configSettings[1200] : 4),
      createSetting(1400, configSettings ? configSettings[1400] : 5),
      createSetting(2400, configSettings ? configSettings[2400] : 6),
      createSetting('default', configSettings ? configSettings['default'] : 8),
    ],
  };
  const settings = casinoSliderSettings.responsive.find(
    s => s.breakpoint > windowWidth || s.breakpoint === 'default'
  );
  const slicedItems = items.slice(0, settings.slidesToShow * 4);
  const to = {
    pathname: `${baseUrl}/${categoryId}`,
    state: { data: { scrollToTop: false } },
  };
  const noLastSlide = isCategory || isSection || isJackpotDrop || isSearchOpen;
  return (
    <div
      className={cn('GameGrid', className, {
        'section-row': isSection,
        'category-row': isCategory,
      })}>
      {title && <div className="grid-header">{title}</div>}
      {overview ? (
        <Slider
          classNames={cn(`slider--${settings.expand} slider--${type}`, {
            'no-last-slide': noLastSlide,
          })}
          showScrollNav={
            device === 'Desktop' && !settings.expand.includes('row')
          }>
          {slicedItems.map((g, i) => {
            if (isCategory) return <CategoryRow {...g} key={g.identifier} />;

            if (isSection) return <SectionRow {...g} key={g.identifier} />;

            return (
              <>
                <Game
                  key={g.identifier}
                  game={g}
                  toggleFavoriteGameById={toggleFavoriteGameById}
                  canShowVideoPreview
                  type={type}
                  {...gameProps}
                />
                {type === CATEGORY_TYPES.LIST && <TopListNumber number={++i} />}
              </>
            );
          })}
          {!settings.expand.includes('row') && !noLastSlide && (
            <div className="last-slide">
              <Link to={to} className="show-all">
                <Translate
                  id="label.show-all-games-count"
                  defaultMessage="Show all {value} games"
                  values={{ value: items.length }}
                />
              </Link>
            </div>
          )}
        </Slider>
      ) : (
        <div className="games grid">
          {items?.map((g, i) => (
            <div className="game-wrapper" key={g.identifier}>
              <Game
                game={g}
                toggleFavoriteGameById={toggleFavoriteGameById}
                canShowVideoPreview
                type={type}
                {...gameProps}
              />
              {category?.config?.type === CATEGORY_TYPES.LIST && (
                <TopListNumber number={++i} />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default withConfig(GameGrid);

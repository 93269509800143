import React from 'react';
import { Input, Button } from 'tg-core-components';
import {
  validateRules,
  required,
  externalPersonIdValidation,
  personIdES,
} from '../../validation';
import translate from 'tg-core-components/lib/lib/utils/translate';
import cn from 'classnames';
import { getContinueLabel } from '../../helpers';

const PersonalId = ({
  /* Props from parent */
  fields,
  onChange,
  isLoading,
  errors,
  intl,
  nextStep,
  statuses,

  /* Props from selector */
  device,
}) => {
  const isValid = statuses.PersonId === 'success';

  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <Input
        autoFocus
        name="PersonId"
        label={translate(
          {
            id: 'label.person_id',
            defaultMessage: 'Person ID (NIF/NIE)',
          },
          intl
        )}
        value={fields.PersonId}
        onChange={(_, PersonId) => onChange({ PersonId })}
        status={(isLoading && 'loading') || statuses.PersonId}
        statusText={errors.PersonId && translate({ id: errors.PersonId }, intl)}
      />

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ PersonId }, jurisdiction) => {
  const rules = {
    PersonId: [
      [required, 'error.empty.personId'],
      [personIdES, 'error.format.personId'],
      [externalPersonIdValidation(jurisdiction), 'error.invalid.personId'],
    ],
  };
  const errors = await validateRules({ PersonId }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  device: state.app.device,
});

export default {
  name: 'personal-id',
  icon: 'user',
  Component: PersonalId,
  validate,
  selector,
  fields: ['PersonId'],
};

import { REQUIRED_ACTIONS } from '@utils/player';

const selectRequiredDisplayPages = state =>
  (state.player.displayPages || [])
    .filter(page => page.ForceAction)
    .map(page => page.PageType || page.Page)
    // Ignore required actions that we don't have support for yet.
    .filter(page =>
      Object.values(REQUIRED_ACTIONS)
        .map(item => item.name)
        .includes(page)
    );
export default selectRequiredDisplayPages;

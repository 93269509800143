const initialState = {
  isLoading: false,
  status: null,
  error: null,
  data: null,
  uploadStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_KYC':
      return {
        ...state,
        status: null,
        isLoading: true,
      };
    case 'GET_KYC_COMPLETE':
      return {
        ...state,
        isLoading: false,
        status: 'success',
        data: action.payload,
      };
    case 'GET_KYC_FAILURE':
      return {
        ...state,
        isLoading: false,
        status: null,
        error: action.error,
      };
    case 'UPLOAD_KYC_START':
      return {
        ...state,
        isLoading: true,
      };
    case 'UPLOAD_KYC_COMPLETE':
      return {
        ...state,
        isLoading: false,
        uploadStatus: 'success',
      };
    case 'UPLOAD_KYC_FAILURE':
      // console log action
      return {
        ...state,
        isLoading: false,
        uploadStatus: 'failed', // get error?
      };

    default:
      return state;
  }
};

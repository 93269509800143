import React, { useEffect, memo } from 'react';
import { Helmet } from 'react-helmet';

const TGSeal = ({ config, content }) => {
  useEffect(() => {
    if (__CLIENT__ && typeof window.tgseal === 'function' && config?.script) {
      window.tgseal({
        root: config.root,
        theme: config.theme,
        size: config.size,
      });
    }
  });

  return (
    config?.script &&
    content && (
      <>
        <Helmet defer={true}>
          <script src={config.script} type="text/javascript" defer />
        </Helmet>
        <div
          className="TGSeal"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </>
    )
  );
};

export default memo(TGSeal);

import React from 'react';
import { Input, Button } from 'tg-core-components';
import cn from 'classnames';
import {
  validateRules,
  required,
  stringWithoutNumbers,
  noSpecialCharacters,
} from '../../validation';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { getContinueLabel } from '../../helpers';

const Name = ({
  /* Props from parent */
  errors,
  onChange,
  intl,
  fields,
  nextStep,
  statuses,

  /* Props from selector */
  device,
}) => {
  const isValid =
    statuses.FirstName === 'success' && statuses.LastName === 'success';
  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <Input
        autoFocus
        type="text"
        label={translate({ id: 'label.name', defaultMessage: 'Name' }, intl)}
        name="Name"
        value={(
          (fields.FirstName || '') +
          ' ' +
          (fields.LastName || '')
        ).trim()}
        onChange={(_, Name) =>
          onChange({
            FirstName: Name.split(' ')[0] || '',
            LastName:
              Name.split(' ')
                .splice(1)
                .join(' ') || '',
          })
        }
        status={
          [statuses.FirstName, statuses.LastName].includes('failure')
            ? 'failure'
            : statuses.FirstName && statuses.LastName
        }
        statusText={
          (errors.FirstName || errors.LastName) &&
          translate({ id: errors.FirstName || errors.LastName }, intl)
        }
      />

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ FirstName, LastName }) => {
  const rules = {
    FirstName: [
      [required, 'error.empty.first-name'],
      [noSpecialCharacters, 'error.invalid.first-name'],
      [stringWithoutNumbers, 'error.invalid.first-name'],
    ],
    LastName: [
      [required, 'error.empty.last-name'],
      [noSpecialCharacters, 'error.invalid.last-name'],
      [stringWithoutNumbers, 'error.invalid.last-name'],
    ],
  };
  const errors = await validateRules({ FirstName, LastName }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  device: state.app.device,
});

export default {
  name: 'name',
  icon: 'user',
  Component: Name,
  validate,
  selector,
  fields: ['FirstName', 'LastName'],
};

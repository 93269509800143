import React from 'react';
import Carousel from '@components/Carousel';
import Features from '@components/Features';

import './style.css';

const TextSlider = ({ features, interval }) => {
  return (
    <div className="TextSlider">
      <Features
        features={{
          ...features,
          items:
            features.items &&
            features.items.map(f => {
              return {
                ...f,
                content: f.content.split('\n')[0],
              };
            }),
        }}
      />

      <Carousel
        features={{
          ...features,
          items:
            features.items &&
            features.items.map(f => {
              return {
                ...f,
                content: f.content.split('\n')[1],
                image: null,
              };
            }),
        }}
        interval={interval}
      />
    </div>
  );
};

export default TextSlider;

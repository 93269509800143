export function failure(payload) {
  return {
    type: 'SIGNIN_PNP_FAILURE',
    payload,
  };
}

export function success(payload) {
  return {
    type: 'SIGNIN_PNP_COMPLETE',
    payload,
  };
}

export default {
  success,
  failure,
};

import React from 'react';
import Popup from '@components/Popup';
import { loadContents } from '@actions/content';
import { useSelector } from 'react-redux';
import withRouteData from '@utils/withRouteData';
import marked from 'marked';
import { Translate } from 'tg-core-components';

const RequestEditProfileContainer = ({ isRouteDataLoading }) => {
  const sections = useSelector(state => state.content.sections.data);
  const section = sections?.find(s => s.identifier === 'request-edit-profile');

  return (
    <Popup
      title={
        <Translate
          id="request-edit-profile.header"
          defaultMessage="Edit profile"
          tagName="h1"
        />
      }
      preventClose
      className="RequestEditProfileContainer"
      showLoading={isRouteDataLoading}>
      {section && (
        <div
          dangerouslySetInnerHTML={{ __html: marked(section.content || '') }}
        />
      )}
    </Popup>
  );
};

RequestEditProfileContainer.hashRoute = true;
RequestEditProfileContainer.globalLoader = false;
RequestEditProfileContainer.dataLoader = loadContents([
  {
    name: 'section',
    identifiers: ['request-edit-profile'],
  },
]);

export default withRouteData(RequestEditProfileContainer);

import React, { Fragment } from 'react';
import GameGrid, { selectGameGridProps } from '@components/GameGrid';
import CasinoGrid, { selectCasinoGridProps } from '@components/CasinoGrid';
import CasinoPromotion, {
  selectCasinoPromotionProps,
} from '@components/CasinoPromotion';
import JackpotDrop from '@components/JackpotDrop';
import { withConfig } from '@config';
import selectGames from '@selectors/selectGames';
import selectLastPlayedGames from '@selectors/selectLastPlayedGames';
import selectFavoriteGames from '@selectors/selectFavoriteGames';
import selectTopGames from '@selectors/selectTopGames';
import { getGamesByCategory, getCategoryType } from '@utils/casino';
import RecentWins, { selectRecentWinProps } from '@components/RecentWins';

export const selectGameGridListProps = state => ({
  games: selectGames(state),
  casinoGridProps: selectCasinoGridProps(state),
  gameGridProps: selectGameGridProps(state),
  casinoPromotionProps: selectCasinoPromotionProps(state),
  favoriteGames: selectFavoriteGames(state),
  recentWinsProps: selectRecentWinProps(state),
  topGames: selectTopGames(state),
  lastPlayedGames: selectLastPlayedGames(state),
});

const GameGridList = ({
  /* props form parent */
  items,
  toggleFavoriteGameById,
  title,
  baseUrl,

  /* props from selector */
  games,
  lastPlayedGames,
  favoriteGames,
  topGames,

  /* withConfig */
  config,

  casinoGridProps,
  gameGridProps,
  casinoPromotionProps,
  recentWinsProps,
}) => {
  if (!items) return null;

  return (
    <div className="GameGridList">
      {items.map(item => {
        const gridItems =
          item.items ||
          getGamesByCategory(
            games,
            item.identifier,
            lastPlayedGames,
            favoriteGames,
            item.config,
            topGames
          );
        const isSection = item.config?.type === 'sections';
        const isCategory = item.items && !isSection;

        const layout = isSection
          ? config.casino.layout
          : item?.config?.type || config.casino.layout;

        return item.name || item.items ? (
          <Fragment>
            {layout === 'grid' || item?.config?.layout === 'grid' ? (
              <CasinoGrid
                key={item.identifier}
                className={item.identifier}
                items={[
                  gridItems.find(g =>
                    item.config?.bigThumbnail?.includes(g.backendId)
                  ),
                  ...gridItems.filter(
                    g => !item.config?.bigThumbnail?.includes(g.backendId)
                  ),
                ].filter(Boolean)}
                title={title(item)}
                toggleFavoriteGameById={toggleFavoriteGameById}
                disableLargeThumbnail={
                  item.config?.disableLargeThumbnail ||
                  config.casino.disableLargeThumbnail
                }
                gridGamesToShow={
                  item.config?.gridGamesToShow || config.casino.gridGamesToShow
                }
                {...casinoGridProps}
                isSection={isSection}
                isCategory={isCategory}
                type={getCategoryType(item.type)}
              />
            ) : (
              <GameGrid
                key={item.identifier}
                items={gridItems}
                toggleFavoriteGameById={toggleFavoriteGameById}
                title={title(item)}
                overview
                isCategory={isCategory}
                isSection={isSection}
                baseUrl={baseUrl}
                categoryId={item.identifier}
                type={getCategoryType(item.type)}
                {...gameGridProps}
              />
            )}
          </Fragment>
        ) : item.config?.type === 'recent-wins' ? (
          <RecentWins
            limit={item.config.limit || 4}
            threshold={item.config.threshold}
            gameId={item.config.gameId}
            {...recentWinsProps}
            {...item}
          />
        ) : item.content ? (
          <CasinoPromotion
            key={item.identifier}
            promotion={item}
            game={games.find(
              g => g.backendId && g.backendId === item.config?.backendId
            )}
            {...casinoPromotionProps}
          />
        ) : item.config.type === 'jackpotDrop' ? (
          <JackpotDrop />
        ) : null;
      })}
    </div>
  );
};

export default withConfig(GameGridList);

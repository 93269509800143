import React from 'react';
import { combineEpics, ofType } from 'redux-observable';
import {
  map,
  concatAll,
  take,
  tap,
  ignoreElements,
  filter,
  delay,
} from 'rxjs/operators';
import playerGroupActions from 'tg-core-redux/lib/modules/player-groups/action';
import currenciesActions from 'tg-core-redux/lib/modules/currencies/action';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import walletActions from 'tg-core-redux/lib/modules/wallet/action';
import bonusActions from 'tg-core-redux/lib/modules/bonus/action';
import eventActions from 'tg-core-redux/lib/modules/event/action';
import responsibleGamingActions from 'tg-core-redux/lib/modules/responsible_gaming/action';
import playerPropertiesAction from 'tg-core-redux/lib/modules/player_properties/action';
import getByPath from 'lodash/get';
import { showNotification } from 'tg-core-redux/lib/modules/notification/action';
import { showToast } from '@actions/toast';
import { filterOutContentBySegmentation } from '@actions/content';
import { redirectToRefUrlIfPresent } from '@utils/refRedirect';
import { getConfig } from '@config';
import { getUrls } from '@utils/urls';
import { createAlertId } from '@components/Alert';
import { getIntl } from '@utils/translations';
import { clearChatHistory } from '@utils/chat';
import SessionSummary from '@components/SessionSummary';
import { loadScript } from '@utils/script';
import getHeadScript from '@utils/getHeadScript';
import {
  initInternalMessagePolling,
  stopInternalMessagePolling,
} from '@utils/internalMessage';
import { selectTac } from '@utils/tac';
import { zip } from 'rxjs';
import Cookies from 'universal-cookie';
import { addAlert } from '@actions/alert';
import selectRequiredActions from '@selectors/selectRequiredActions';
import casinoActions from 'tg-core-redux/lib/modules/casino/action';
import { removePendingAction } from '@actions/player';
import { authenticateZendesk } from '@utils/chat';
import { APP_DASHBOARD_ACTIONS } from './appDashboardActions';

const isUnauthorized = action =>
  action.payload?.value?.response?.status === 401 &&
  !['PLAYER_UPDATE_FAILURE'].includes(action.type);
const logoutWhenGot401 = (action$, state$) =>
  action$.pipe(
    filter(() => state$.value.player.isAuthenticated),
    filter(isUnauthorized),
    map(() => {
      const {
        app: { jurisdiction, ipCountry },
      } = state$.value;
      const { signInString } = getUrls(jurisdiction, ipCountry);
      const alert = {
        type: 'session_expired',
        level: 'warning',
      };

      window.routerHistory.replace(signInString());

      return [{ type: 'SIGNOUT' }, addAlert(alert)];
    })
  );

const getDataAfterLogin = (action$, state$) =>
  action$.pipe(
    ofType(
      'SIGNIN_PNP_COMPLETE',
      'SIGNINV2_COMPLETE',
      'SIGNINWITHCODE_COMPLETE'
    ),
    map(action => {
      const { isAccount } = getConfig(
        state$.value.app.jurisdiction,
        state$.value.app.ipCountry
      );
      if (isAccount && action.payload.JwtTokenSignup)
        return [{ type: 'SIGNOUT_SOFT' }];

      return [
        playerActions.paymentStats(action.payload.SessionId),
        playerActions.lifetimeStats(action.payload.SessionId),
        playerActions.userInfo(action.payload.SessionId),
        walletActions.getWallet(action.payload.SessionId),
        walletActions.getWallets(action.payload.SessionId),
        currenciesActions.getCurrencies(),
        playerActions.getActiveCurrency(action.payload.SessionId),
        responsibleGamingActions.getLimit(action.payload.SessionId),
        responsibleGamingActions.getRealityCheckSession(
          action.payload.SessionId
        ),
        responsibleGamingActions.getProductBlocks(action.payload.SessionId),
        playerPropertiesAction.getProperties(action.payload.SessionId),
        playerActions.getDisplayPages(action.payload.SessionId),
        casinoActions.getFavorites(action.payload.SessionId),
        bonusActions.getOffers(action.payload.SessionId),
        casinoActions.getLastPlayedGames(
          action.payload.SessionId,
          state$.value.app.device
        ),
      ];
    }),
    concatAll()
  );

const closeSidebarAndShowToastOnActivateSuccess = (action$, state$) =>
  action$.pipe(
    ofType('ACTIVATE_COMPLETE'),
    filter(() => !state$.value.player?.isAutoLoggedIn),
    tap(() => (window.location.hash = '')),
    map(action => {
      const { app, player } = state$.value;
      // Only redirect if we are auto logged in (sign-up)
      if (player.isAutoLoggedIn) {
        const jurisdictionReload =
          !app.initialJurisdiction ||
          app.initialJurisdiction.toLowerCase() !==
            app.jurisdiction.toLowerCase();
        const { signUpRedirect } = getConfig(app.jurisdiction, app.ipCountry);

        redirectToRefUrlIfPresent(
          window.routerHistory,
          signUpRedirect,
          jurisdictionReload
        );
      }

      return [
        {
          type: 'SHOW_TOAST',
          text: getIntl(state$.value).formatMessage({
            id: createAlertId(action, 'success'),
          }),
          toastType: 'SUCCESS',
        },
      ];
    })
  );

// If we receive SessionId upon sign up we don't need
// to sign-in after sign-up. Trigger sign in action
// with the data received in sign up request instead.
const dispatchSignInIfSessionIdInSignUp = action$ =>
  action$.pipe(
    ofType('SIGNUPWITHCODE_COMPLETE', 'SIGNUPWITHTOKEN_COMPLETE'),
    filter(action => getByPath(action, ['payload', 'SessionId'])),
    map(action => ({
      type: 'SIGNINV2_COMPLETE',
      payload: action.payload,
    }))
  );

const dispatchSignInIfSessionIdInActivation = (action$, state$) =>
  action$.pipe(
    ofType('ACTIVATE_COMPLETE'),
    filter(() => !state$.value.player.isAuthenticated),
    filter(action => getByPath(action, ['payload', 'SessionId'])),
    map(action => ({
      type: 'SIGNINV2_COMPLETE',
      payload: action.payload,
    }))
  );

// We make sure the user is logged in prior to step 4/4
// Now we can access user data needed to pre-set limits
// We do NOT want to sign in of the signup request had
// a SessionId
const autoLoginAfterThirdStep = (action$, state$) =>
  action$.pipe(
    ofType('SIGNUPV2_COMPLETE', 'SIGNUPWITHCODE_COMPLETE'),
    filter(action => !getByPath(action, ['payload', 'SessionId'])),
    filter(action => {
      const issues = getByPath(action, ['payload', 'Issues']);
      return !issues || issues.length === 0;
    }),
    map(() => {
      const { player, app } = state$.value;
      return playerActions.signInV2({
        Jurisdiction: player.registrationInfo.Jurisdiction,
        Password: player.registrationInfo.Password,
        Platform: app.device,
        Browser: app.browser,
        OperatingSystem: app.operatingSystem,
        Username: player.registrationInfo.Email,
      });
    })
  );

const getUserInfoAfterActivate = (action$, state$) =>
  zip(
    action$.ofType('USER_INFO_COMPLETE'),
    action$.ofType('ACTIVATE_COMPLETE')
  ).pipe(
    map(() => {
      setTimeout(() => redirectToRefUrlIfPresent(window.routerHistory), 1000);
      return playerActions.userInfo(state$.value.player.sessionId);
    }),
    take(1)
  );

const getUserInfoAfterUpdate = (action$, state$) =>
  action$.pipe(
    ofType('PLAYER_UPDATE_COMPLETE'),
    map(action => [
      showToast({
        text: getIntl(state$.value).formatMessage({
          id: createAlertId(action, 'success'),
        }),
        toastType: 'SUCCESS',
      }),
      playerActions.userInfo(state$.value.player.sessionId),
    ]),
    concatAll()
  );

const saveCookieConsentToAleacc = (action$, state$) =>
  zip(action$.ofType('GET_PLAYER_PROPERTIES_COMPLETE')).pipe(
    filter(() => selectRequiredActions(state$.value).length === 0),
    map(([propertiesAction]) => {
      const cookies = new Cookies();
      const { player } = state$.value;
      const didAcceptCookieAleacc = propertiesAction.payload?.find(
        p => p.Name === 'accepted-cookies'
      );
      const didAcceptCookieLocal = cookies.get('accepted-cookies');

      if (!didAcceptCookieAleacc && didAcceptCookieLocal)
        return playerPropertiesAction.setProperties(player.sessionId, {
          Properties: [
            {
              Name: 'accepted-cookies',
              Type: 'Text',
              Value: 'true',
            },
          ],
        });

      return [];
    })
  );

const handleAccountLoginSuccess = (action$, state$) =>
  zip(
    action$.ofType('SIGNINV2_COMPLETE', 'SIGNINWITHCODE_COMPLETE'),
    action$.ofType('USER_INFO_COMPLETE')
  ).pipe(
    filter(() => selectRequiredActions(state$.value).length === 0),
    filter(
      () =>
        getConfig(state$.value.app.jurisdiction, state$.value.app.ipCountry)
          .isAccount
    ),
    tap(action => {
      const loginData = action.find(
        a =>
          a.type === 'SIGNINV2_COMPLETE' || a.type === 'SIGNINWITHCODE_COMPLETE'
      );
      const { app, player } = state$.value;
      const jurisdictionReload =
        !app.initialJurisdiction ||
        app.initialJurisdiction.toLowerCase() !==
          app.jurisdiction.toLowerCase();

      const { disableActivation } = getConfig(app.jurisdiction);

      // isAutoLoggedIn check makes sure that the code inside only runs if users are logging in manually
      // Because we don't want to run this code when signing up and being autologgedin on step 3/4
      if (!player.isAutoLoggedIn) {
        if (
          !disableActivation &&
          player.user &&
          !player.user.IsVerified &&
          app.jurisdiction.toLowerCase() !== 'sga'
        ) {
          redirectToRefUrlIfPresent(
            window.routerHistory,
            '#dashboard/activate',
            jurisdictionReload
          );
        } else {
          const sourceOfWealth = loginData?.payload?.DisplayPage?.some(
            page =>
              page.Page === 'SourceOfWealthFormRequired' && page.ForceAction
          );
          if (!sourceOfWealth) {
            window.location.hash = '';
          }
        }
      }

      if (window.routerHistory.location?.state?.referrer) {
        const { location } = window.routerHistory;

        if (location.state.referrer === location.pathname) {
          window.location.reload();
        } else {
          window.routerHistory.replace(location.state.referrer);
        }
      } else {
        if (
          !player.isAutoLoggedIn &&
          player.user &&
          (player.user.IsVerified ||
            ['sga', 'curacao'].includes(app.jurisdiction?.toLowerCase()))
        ) {
          const { signInRedirect } = getConfig(app.jurisdiction);
          const refUrl = getByPath(loginData, 'requestData[0].refUrl');

          if (!state$.value.player?.isAutoLoggedIn) {
            redirectToRefUrlIfPresent(
              window.routerHistory,
              refUrl ? refUrl : signInRedirect,
              jurisdictionReload
            );
          }
        }
      }

      jurisdictionReload && window.location.reload();
    }),
    ignoreElements()
  );
const fetchProductBlocksAfterPayment = (action$, state$) =>
  action$.pipe(
    ofType('PAYMENT_SUCCESS', 'PAYMENT_PENDING'),
    map(() =>
      responsibleGamingActions.getProductBlocks(state$.value.player.sessionId)
    )
  );

const handlePnpLoginSuccess = (action$, state$) =>
  zip(
    action$.ofType('SIGNIN_PNP_COMPLETE'),
    action$.ofType('USER_INFO_COMPLETE'),
    action$.ofType('GET_DISPLAY_PAGES_COMPLETE')
  ).pipe(
    filter(() => selectRequiredActions(state$.value).length === 0),
    tap(action => {
      const data = action.find(x => x.type === 'SIGNIN_PNP_COMPLETE');

      const isDeposit = data.payload.amount > 0;
      const refUrl = data.payload.refUrl;
      const config = getConfig(
        state$.value.app.jurisdiction,
        state$.value.app.ipCountry
      );

      if (!isDeposit) {
        let redirect = '/casino';

        if (refUrl) redirect = redirectToRefUrlIfPresent(window.routerHistory);
        if (config.signInRedirect) redirect = config.signInRedirect;

        window.routerHistory.replace(redirect);
      }
    }),
    ignoreElements()
  );

const showSessionSummaryAfterSignIn = (action$, state$) =>
  zip(
    action$.ofType(
      'SIGNIN_PNP_COMPLETE',
      'SIGNINV2_COMPLETE',
      'SIGNINWITHCODE_COMPLETE'
    ),
    action$.ofType('GET_DISPLAY_PAGES_COMPLETE'),
    action$.ofType('GET_REALITYCHECK_SESSION_COMPLETE'),
    action$.ofType('GET_LIMIT_COMPLETE'),
    action$.ofType('USER_INFO_COMPLETE'),
    action$.ofType('PAYMENT_STATS_COMPLETE')
  ).pipe(
    filter(() => selectRequiredActions(state$.value).length === 0),
    filter(action => {
      const { app, responsible_gaming } = state$.value;
      return (
        (getByPath(
          action.find(action => action.type === 'SIGNIN_PNP_COMPLETE'),
          ['payload', 'IsSignup']
        ) === 'false' ||
          (responsible_gaming.session &&
            responsible_gaming.session.LastUnixStarted !== 0)) &&
        ['sga', 'es'].includes(app.jurisdiction.toLowerCase())
      );
    }),
    take(1),
    map(actions =>
      showNotification({
        component: (
          <SessionSummary
            limits={actions.find(a => a.type === 'GET_LIMIT_COMPLETE').payload}
            session={
              actions.find(a => a.type === 'GET_REALITYCHECK_SESSION_COMPLETE')
                .payload
            }
            sections={state$.value.content.sections.data}
            user={actions.find(a => a.type === 'USER_INFO_COMPLETE').payload}
            paymentStats={
              actions.find(a => a.type === 'PAYMENT_STATS_COMPLETE').payload
            }
            jurisdiction={state$.value.app.jurisdiction}
          />
        ),
        timeout: 10,
        defaultAction: () =>
          window.routerHistory.push('#dashboard/responsible-gaming'),
        btnText: {
          id: 'action.change-limits',
          defaultMessage: 'Change limits',
        },
      })
    )
  );

const clearChatWhenSignedOut = action$ =>
  action$.pipe(
    ofType('SIGN_OUT_START'),
    tap(() => clearChatHistory()),
    ignoreElements()
  );

const redirectWhenSignout = action$ =>
  action$.pipe(
    ofType('SIGN_OUT_START'),
    tap(action => {
      if (action.payload?.panic)
        window.routerHistory.push('responsible-gaming');
      else if (!window.location.href.match('/sign-out'))
        window.location.href = '/sign-out';
    }),
    ignoreElements()
  );

const doSegmentationWhenUserLoggedIn = action$ =>
  zip(
    action$.ofType('PAYMENT_STATS_COMPLETE'),
    action$.ofType('USER_INFO_COMPLETE')
  ).pipe(
    map(() => filterOutContentBySegmentation()),
    take(1)
  );

const doSegmentationWhenUserGroupsFetched = action$ =>
  action$.pipe(
    ofType('PLAYER_GROUPS_COMPLETE'),
    map(() => filterOutContentBySegmentation()),
    ignoreElements()
  );

const requestJackpotsAfterLogin = (action$, state$) =>
  action$.pipe(
    ofType('USER_INFO_COMPLETE'),
    map(() => eventActions.getJackpots(state$.value.player.user.Currency))
  );

const getPlayerGroups = (action$, state$) =>
  action$.pipe(
    ofType('USER_INFO_START'), // will happen after deposit + log in
    filter(() => state$.value.player.isAuthenticated),
    map(() => playerGroupActions.getGroups(state$.value.player.sessionId))
  );

const requestLimitsAfterLogin = (action$, state$) =>
  action$.pipe(
    ofType('USER_INFO_COMPLETE'),
    map(() => responsibleGamingActions.toConfirm(state$.value.player.sessionId))
  );

const signInOnSgaValidate = (action$, state$) =>
  action$.pipe(
    ofType(
      'VERIFY_EXISTING_SE_PLAYER_COMPLETE',
      'VERIFY_EXISTING_SE_PLAYER_WITH_TOKEN_COMPLETE'
    ),
    map(() => {
      const tac = selectTac(state$.value);
      return [
        {
          type: 'SIGNINV2_COMPLETE',
          payload: {
            SessionId: state$.value.player.sessionId,
            TacVersionAccepted: tac.version,
          },
        },
      ];
    })
  );

const redirectOnAcceptTac = (action$, state$) =>
  action$.pipe(
    ofType('UPDATE_PLAYER_TAC_COMPLETE'),
    filter(
      () =>
        getConfig(state$.value.app.jurisdiction, state$.value.app.ipCountry)
          .isPnp
    ),
    tap(() => {
      window.routerHistory.replace(
        getConfig(state$.value.app.jurisdiction, state$.value.app.ipCountry)
          .signInRedirect || '/casino'
      );
    }),
    ignoreElements()
  );

const reloadChatAfterLogin = (action$, state$) =>
  action$.pipe(
    ofType('USER_INFO_COMPLETE'),
    delay(3000),
    tap(() => {
      const config = getConfig(state$.value.app.jurisdiction);

      if (config.chat.provider === 'freshchat') {
        const headScript = getHeadScript(
          state$.value.app.jurisdiction,
          state$.value.app.ipCountry
        );

        loadScript(headScript, 'chat').then(() => {
          const user = state$.value.player.user;
          window.fcWidget.setExternalId(`${user.Username}/${user.Id}`);
          window.fcWidget.user.setFirstName(user.FirstName);
          window.fcWidget.user.setLastName(user.LastName);
          window.fcWidget.user.setEmail(user.Email);
          window.fcWidget.user.setPhone(user.MobilePhoneNumber);
          window.fcWidget.user.setProperties({
            UserCountry: user.Country,
            Authenticated: `yes/${user.Username}`,
            Language: state$.value.app.locale,
            Jurisdiction: user.Jurisdiction,
            Site: config.freshChat.brand,
            Id: user.Id,
            BirthDate: user.BirthDate,
            Currency: user.Currency,
            IsComplete: user.IsComplete,
            IsVerified: user.IsVerified,
            LastDeposit: user.LastDeposit,
            DepositCount: user.DepositCount,
            WithdrawCount: user.WithdrawCount,
            Level: user.Level,
            Tags: user.Tags.join(', '),
          });
        });
      } else if (config.chat.provider === 'zendesk') {
        authenticateZendesk();
      }
    }),
    ignoreElements()
  );

const initializeInternalMessagePolling = (action$, state$) =>
  action$.pipe(
    ofType('SIGNIN_PNP_COMPLETE', 'SIGNINV2_COMPLETE', 'BOOTSTRAP_DONE'),
    filter(() => selectRequiredActions(state$.value).length === 0),
    filter(
      () =>
        state$.value.player.isAuthenticated &&
        getConfig(state$.value.app.jurisdiction).imProvider !== 'fasttrack'
    ),
    tap(() => {
      initInternalMessagePolling(state$.value.player.sessionId);
    }),
    ignoreElements()
  );

const abortInternalMessagePolling = action$ =>
  action$.pipe(
    ofType('SIGN_OUT_START', 'GET_UNPUSHED_MESSAGES_FAILURE'),
    tap(() => {
      stopInternalMessagePolling();
    }),
    ignoreElements()
  );

const sendActivationCodeAfterSetEmail = (action$, state$) =>
  action$.pipe(
    ofType('SET_DETAILS_WITHOUT_PASSWORD_COMPLETE'),
    filter(
      () => getConfig(state$.value.app.jurisdiction).sendActivationCodeOnSignUp
    ),
    map(action => {
      const usernameOrEmail =
        action.requestData[1] || action.requestData[2] || action.requestData[3];
      return playerActions.requestActivationCode(usernameOrEmail);
    })
  );

const updateDataAfterSetPlayerProperties = (action$, state$) =>
  action$.pipe(
    ofType('SET_PLAYER_PROPERTIES_COMPLETE'),
    map(() => {
      return playerPropertiesAction.getProperties(
        state$.value.player.sessionId
      );
    })
  );

const fastTrackSignout = (action$, state$) =>
  action$.pipe(
    ofType('SIGN_OUT_START'),
    filter(
      () => getConfig(state$.value.app.jurisdiction).imProvider === 'fasttrack'
    ),
    tap(() => window.FasttrackCrm && window.FasttrackCrm.logout()),
    ignoreElements()
  );

const resetPasswordSuccess = action$ =>
  action$.pipe(
    ofType('RESET_PASSWORD_COMPLETE'),
    map(() => {
      return () => window.routerHistory.replace('/#sign-in');
    })
  );

const redirectToBingoPlayAfterSignIn = (action$, state$) =>
  action$.pipe(
    ofType('SIGNINV2_COMPLETE'),
    filter(() => selectRequiredActions(state$.value).length === 0),
    filter(() => state$.value.player.isAuthenticated),
    tap(() => {
      if (window.routerHistory.location.pathname === '/bingo') {
        window.routerHistory.replace('/bingo/play');
      }
    }),
    ignoreElements()
  );

const handlePendingActionAfterSignIn = (action$, state$) =>
  zip(
    action$.ofType(
      'SIGNINV2_COMPLETE',
      'SIGNINWITHCODE_COMPLETE',
      'SIGNIN_PNP_COMPLETE'
    ),
    action$.ofType('USER_INFO_COMPLETE')
  ).pipe(
    filter(() => selectRequiredActions(state$.value).length === 0),
    filter(() => state$.value.player.pendingAction),
    map(() => {
      window.location.hash = `#action/${state$.value.player.pendingAction}`;

      return removePendingAction();
    })
  );

const fetchNewDataWhenChangeCurrency = (action$, state$) =>
  action$.ofType('SET_ACTIVE_CURRENCY_COMPLETE', 'SET_WALLET_COMPLETE').pipe(
    map(() => {
      return [
        playerActions.getActiveCurrency(state$.value.player.sessionId),
        walletActions.getWallet(state$.value.player.sessionId),
        walletActions.getWallets(state$.value.player.sessionId),
        playerActions.paymentStats(state$.value.player.sessionId),
        playerActions.userInfo(state$.value.player.sessionId),
        playerActions.lifetimeStats(state$.value.player.sessionId),
      ];
    })
  );

const notifyApp = action$ =>
  action$.pipe(
    ofType(...APP_DASHBOARD_ACTIONS),
    filter(() => window.isApp),
    tap(action => {
      // Try with SwiftUI App
      window?.webkit?.messageHandlers?.iosListener?.postMessage(
        JSON.stringify({ action: action.type, payload: action.payload })
      );

      // Implement more apps..
    }),
    ignoreElements()
  );

export default combineEpics(
  getDataAfterLogin,
  getUserInfoAfterUpdate,
  handleAccountLoginSuccess,
  handlePnpLoginSuccess,
  logoutWhenGot401,
  clearChatWhenSignedOut,
  redirectWhenSignout,
  closeSidebarAndShowToastOnActivateSuccess,
  autoLoginAfterThirdStep,
  doSegmentationWhenUserLoggedIn,
  getUserInfoAfterActivate,
  requestJackpotsAfterLogin,
  getPlayerGroups,
  requestLimitsAfterLogin,
  doSegmentationWhenUserGroupsFetched,
  dispatchSignInIfSessionIdInSignUp,
  signInOnSgaValidate,
  redirectOnAcceptTac,
  reloadChatAfterLogin,
  initializeInternalMessagePolling,
  showSessionSummaryAfterSignIn,
  abortInternalMessagePolling,
  sendActivationCodeAfterSetEmail,
  fetchProductBlocksAfterPayment,
  updateDataAfterSetPlayerProperties,
  fastTrackSignout,
  resetPasswordSuccess,
  saveCookieConsentToAleacc,
  redirectToBingoPlayAfterSignIn,
  handlePendingActionAfterSignIn,
  fetchNewDataWhenChangeCurrency,
  dispatchSignInIfSessionIdInActivation,
  notifyApp
);

import React, { Component } from 'react';
import quickDepositUtils from '@utils/quickDeposit';
import QuickAmounts from '@components/QuickAmounts';
import { injectIntl } from 'react-intl';
import concat from 'lodash/concat';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Input, Select, Translate } from 'tg-core-components';
import { parseCreditcard } from 'tg-core-components/lib/widgets/CashierAccordion/Payment/PaymentAccountParser';
import { GTM_EVENTS } from '@utils/google-tag-manager';
import getByPath from 'lodash/get';
import cn from 'classnames';
import { withUrls } from '@utils/urls';
import translate from 'tg-core-components/lib/lib/utils/translate';

import './style.css';

export const selectQuickDepositProps = state => ({
  currency: state.player.user?.Currency,
  jurisdiction: state.app.jurisdiction,
  paymentStats: state.player.paymentStats,
  productBlocks: state.responsible_gaming.productBlocks,
  quickDepositIsLoading: state.quickDeposit.isLoading,
  quickDepositIsInitialized: state.quickDeposit.isInitialized,
});

@withUrls
@withRouter
@injectIntl
class QuickDeposit extends Component {
  state = {
    selectedAccount: null,
    userInfo: {},
    amount: 0,
    cvv: '',
    showCustomAmount: false,
    isLoading: false,
    accounts: [],
    paymentMethods: [],
    didConfirm: false,
  };

  componentDidMount() {
    const { quickDepositInit, quickDepositIsInitialized } = this.props;
    if (quickDepositIsInitialized) {
      this.setInitialState();
    } else {
      quickDepositInit({ eventCallbacks: [this.handleActions] });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { history } = this.props;
    const { action } = getByPath(history, 'location.state', {});

    if (action) {
      history.replace({ state: { action: null } });
      const { type, payload } = action || {};

      switch (type) {
        case 'confirm': {
          const { didConfirm } = payload;
          if (didConfirm) this.handleDidConfirm();
          break;
        }
      }
    }

    if (
      this.state.didConfirm &&
      prevState.didConfirm !== this.state.didConfirm
    ) {
      this.makeDeposit();
    }
  }

  handleActions = ({ action, payload }) => {
    const { history, pushGtmEvent, quickDepositIsInitialized } = this.props;

    switch (action) {
      case 'loaddata-success':
        this.setInitialState();
        break;

      case 'deposit-success':
        this.setState({
          amount: 0,
          showCustomAmount: false,
        });
        break;

      case 'deposit-failure':
        if (!payload) {
          this.setState({
            amount: 0,
            showCustomAmount: false,
          });
        }
        break;

      case 'redirect-output':
        pushGtmEvent({ event: GTM_EVENTS.QUICK_DEPOSIT_INIT });
        history.push(
          `#popup/quick-deposit/${encodeURIComponent(JSON.stringify(payload))}`
        );
        break;

      case 'loaded':
        if (quickDepositIsInitialized) {
          this.setInitialState();
        }
    }
  };

  setInitialState = () => {
    const {
      paymentAccounts,
      paymentMethods,
      userInfo,
      paymentStats,
    } = window.TGQuickDeposit;

    const accounts = concat(...Object.values(paymentAccounts));

    this.setState({
      accounts,
      paymentMethods,
      userInfo,
      selectedAccount: accounts[0],
      paymentStats,
    });
  };

  createSelectLabel = (account, verbose = false) => {
    return account.type === 'creditcard'
      ? `${account.cardType.toUpperCase()} ${account.maskedAccount.slice(
          account.maskedAccount.indexOf('*')
        )} (${parseCreditcard(account).expiryDate})`
      : `${verbose ? account.type.toUpperCase() : ''} ${account.maskedAccount}`;
  };

  selectAccount = e => {
    const { accounts } = this.state;

    const selectedAccount =
      accounts.find(a => a.accountId === e.target.value) || accounts[0];

    this.setState({
      selectedAccount,
      cvv: '',
      amount: 0,
      showCustomAmount: false,
    });

    if (!e.target.value) {
      this.props.history.push('#deposit');

      // We have to "reset" the value sent to the select input in order for it to update.
      this.setState({ selectedAccount: null }, () => {
        this.setState({ selectedAccount });
      });
    }
  };

  generateSelectOptions = () => {
    const { intl } = this.props;
    const { paymentMethods, selectedAccount } = this.state;

    return [
      ...Object.keys(paymentMethods)
        .filter(m => paymentMethods[m].accounts?.length > 0)
        .map((m, i) => (
          <Select.OptGroup
            key={i}
            label={translate(
              {
                id: `label.quick-deposit.${paymentMethods[m].providerType}`,
              },
              intl
            )}>
            {paymentMethods[m].accounts
              .filter(a => a.accountId)
              .map(a => (
                <Select.Option key={a.accountId} value={a.accountId}>
                  {selectedAccount.accountId === a.accountId
                    ? this.createSelectLabel(a, true)
                    : this.createSelectLabel(a)}
                </Select.Option>
              ))}
          </Select.OptGroup>
        )),
      <Select.OptGroup key="" label="────────────">
        <Select.Option value="">
          {translate(
            {
              id: 'label.quick-deposit.more',
              defaultMessage: 'More alternatives',
            },
            intl
          )}
        </Select.Option>
      </Select.OptGroup>,
    ];
  };

  handleDidConfirm = () => {
    this.setState({
      didConfirm: true,
    });
  };

  isWithinLimits = (limit, amount) => {
    const parsedAmount = Number(amount);
    const parsedMin = Number(limit.min);
    const parsedMax = Number(limit.max);

    return parsedAmount >= parsedMin && parsedAmount <= parsedMax;
  };

  makeDeposit = async e => {
    const {
      selectedAccount,
      paymentMethods,
      amount,
      cvv,
      showCustomAmount,
    } = this.state;

    const paymentMethod = paymentMethods.find(
      p => p.providerType.toLowerCase() === selectedAccount.type.toLowerCase()
    );

    const disableDeposit =
      !this.isWithinLimits(paymentMethod.limit, amount) ||
      (amount < 1 && showCustomAmount) ||
      (selectedAccount.type === 'creditcard' && cvv === '');

    if (disableDeposit || (e && e.key !== 'Enter')) return;

    this.setState({ isLoading: true });

    const method = paymentMethod.providerType.toLowerCase();
    const accountId = selectedAccount.accountId;
    const service = paymentMethod.service;

    await window.TGQuickDeposit.deposit({
      method,
      accountId,
      amount,
      cvv,
      attributes: { psp: service },
    });

    this.setState({ isLoading: false });
  };

  render() {
    const {
      userInfo: { Currency },
      selectedAccount,
      amount,
      cvv,
      showCustomAmount,
      isLoading,
      accounts,
      paymentStats,
      paymentMethods,
    } = this.state;

    const {
      intl,
      quickDepositIsInitialized,
      quickDepositIsLoading,
      urls: { depositString },
    } = this.props;

    if (quickDepositIsLoading) {
      return <div className="QuickDeposit"></div>;
    }

    if (!quickDepositIsInitialized || !accounts.length > 0 || !selectedAccount)
      return (
        <div className="QuickDeposit">
          <form>
            <Button
              href={depositString()}
              className="button button--icon button--secondary button--right">
              <Translate defaultMessage="Deposit" id="action.deposit" />
              <Icon className="icon-coins" icon="coins" />
            </Button>
          </form>
        </div>
      );

    const paymentMethod = paymentMethods.find(
      p => p.providerType.toLowerCase() === selectedAccount.type.toLowerCase()
    );

    const quickAmounts = quickDepositUtils.generateQuickAmounts(
      Currency,
      paymentMethod.limit.min,
      paymentStats.LastDepositAmount,
      null,
      paymentMethod.limit
    );

    return (
      <div
        className={cn(
          'QuickDeposit',
          selectedAccount.type && `QuickDeposit--${selectedAccount.type}`
        )}>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.makeDeposit();
          }}>
          <Select
            className="QuickDeposit__account-select"
            onChange={this.selectAccount}
            value={selectedAccount.accountId}>
            {this.generateSelectOptions()}
          </Select>

          {selectedAccount.type === 'creditcard' && (
            <Input
              pattern="[0-9]*"
              min="0"
              type="number"
              className="cvv"
              placeholder={translate(
                {
                  id: 'cashier.details.cvv',
                  defaultMessage: 'CVV',
                },
                intl
              )}
              value={cvv}
              onChange={e => this.setState({ cvv: e.target.value })}
              autoFocus
            />
          )}

          <QuickAmounts
            deposit={this.makeDeposit}
            amounts={quickAmounts}
            amount={amount}
            currency={Currency}
            handleChange={amount => this.setState({ amount })}
            toggleCustomAmount={() =>
              this.setState({
                showCustomAmount: !showCustomAmount,
                amount: 0,
              })
            }
            showCustomAmount={showCustomAmount}
            isLoading={isLoading}
            disabled={selectedAccount.type === 'creditcard' && cvv === ''}
            isDeposit={showCustomAmount && amount >= 1}
            limit={paymentMethod.limit}
            isWithinLimits={this.isWithinLimits(paymentMethod.limit, amount)}
          />
        </form>
      </div>
    );
  }
}

export default QuickDeposit;

import { countryEmojiFlags } from 'tg-core-components';

export default (countries, callingCodes) =>
  callingCodes
    ? callingCodes?.value
        ?.sort((a, b) => (b.sortOrder || 0) - (a.sortOrder || 0))
        .map(i => ({
          value: '00' + i.callingCode,
          label:
            i.label || `+${i.callingCode} ${countryEmojiFlags[i.value] || ''}`,
        }))
    : countries
        ?.sort?.((a, b) => Number(a.callingCode) - Number(b.callingCode))
        .map(i => ({
          value: '00' + i.callingCode,
          label: `+${i.callingCode} ${countryEmojiFlags[i.value] || ''}`,
        }));

import React from 'react';
import Helmet from 'react-helmet';

const withNoIndex = predicate => BaseComponent => props => {
  const shouldNotIndex = predicate?.(props);

  return (
    <React.Fragment>
      {shouldNotIndex && (
        <Helmet>
          <meta id="wwa" name="robots" content="noindex" />
        </Helmet>
      )}
      <BaseComponent {...props} />
    </React.Fragment>
  );
};

export default withNoIndex;

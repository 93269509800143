import React, { Component } from 'react';
import { Button, Translate } from 'tg-core-components';
import marked from 'marked';
import { withCookies } from 'react-cookie';
import { startChat } from '@utils/chat';
import Popup from '@components/Popup';
import { connect } from 'react-redux';
import { closeHash } from '@utils/hash';

@withCookies
@connect(state => ({
  sections: state.content.sections.data,
}))
class CountryRestrictionContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;

  render() {
    const { sections, cookies, overlayLocation, overlayMatch } = this.props;

    const disableRestrictedAccess = cookies.get('disableRestrictedAccess');
    const restrictedAccess = sections?.find(
      i => i.identifier === 'popup-restricted-access'
    );

    if (!restrictedAccess || disableRestrictedAccess === 'true') {
      window.location.hash = closeHash(overlayLocation.hash, overlayMatch.url);
    }

    const [title, ...content] = (restrictedAccess &&
      restrictedAccess.content &&
      restrictedAccess.content.split('\n')) || ['', ''];

    return (
      <Popup
        title={
          title && (
            <div
              dangerouslySetInnerHTML={{
                __html: marked(title),
              }}
            />
          )
        }
        preventClose={true}>
        <div className="CountryRestrictionContainer">
          {content && (
            <div
              dangerouslySetInnerHTML={{
                __html: marked(content.join('\n')),
              }}
            />
          )}
          <Button className="button primary" onClick={() => startChat()}>
            <Translate id="start.live-chat" defaultMessage="Start chat" />
          </Button>
        </div>
      </Popup>
    );
  }
}

export default CountryRestrictionContainer;

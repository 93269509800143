import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import BasePage from '@pages/BasePage';
import withRouteData from '@utils/withRouteData';
import queryString from 'query-string';
import marked from 'marked';
import { ResetPassword, RequestResetPassword } from 'tg-core-components';
import Loader from '@components/Loader';
import { getConfig } from '@config';

import './style.css';

const RequestSection = ({ content, onSubmit, isLoading }) => {
  return (
    <div>
      {content && (
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: marked(content),
          }}
        />
      )}
      {isLoading && <Loader inline />}
      {!isLoading && <RequestResetPassword onSubmitForm={onSubmit} />}
    </div>
  );
};
const RequestedSection = ({ content }) => {
  return (
    <div>
      {content && (
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: marked(content),
          }}
        />
      )}
    </div>
  );
};
const ResetSection = ({ content, onSubmit, isLoading }) => {
  return (
    <div>
      {content && (
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: marked(content),
          }}
        />
      )}
      {isLoading && <Loader inline />}
      {!isLoading && <ResetPassword onSubmitForm={onSubmit} />}
    </div>
  );
};

@withRouteData
@connect(
  state => ({
    pages: state.content.pages.data,
    sections: state.content.sections.data,
    status: state.player.requestResetPasswordStatus,
    isAuthenticated: state.player.isAuthenticated,
    jurisdiction: state.app.jurisdiction,
    ipCountry: state.app.ipCountry,
    operatingSystem: state.app.operatingSystem,
    device: state.app.device,
    browser: state.app.browser,
  }),
  {
    signIn: playerActions.signInV2,
    resetPassword: playerActions.resetPassword,
    requestPasswordReset: playerActions.requestPasswordReset,
  }
)
class MigrationContainer extends Component {
  state = {
    isLoading: false,
    requestStatus: null,
  };

  onSubmitReset = data => {
    const {
      location,
      resetPassword,
      signIn,
      operatingSystem: OperatingSystem,
      browser: Browser,
      device: Platform,
    } = this.props;
    const token = queryString.parse(location.search).token;
    const username = queryString.parse(location.search).username;

    this.setState({
      isLoading: true,
    });

    resetPassword({
      UsernameOrEmail: username,
      Token: token,
      NewPassword: data.NewPassword,
    })
      .then(() => {
        signIn({
          Username: username,
          Password: data.NewPassword,
          Platform,
          OperatingSystem,
          Browser,
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  onSubmitRequestReset = async data => {
    const { requestPasswordReset } = this.props;
    this.setState({
      isLoading: true,
    });

    await requestPasswordReset(data.UsernameOrEmail);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const {
      pages,
      sections,
      match,
      status,
      location,
      jurisdiction,
      ipCountry,
      isAuthenticated,
    } = this.props;
    const { isLoading } = this.state;
    const page = pages && pages.find(p => '/' + p.url === match.url);

    const resetSection =
      sections &&
      sections.find(s => s.identifier === 'migration-reset-section');
    const requestedSection =
      sections &&
      sections.find(s => s.identifier === 'migration-requested-section');
    const requestSection =
      sections &&
      sections.find(s => s.identifier === 'migration-request-section');
    const promoSection =
      sections &&
      sections.find(s => s.identifier === 'migration-promo-section');

    const token = queryString.parse(location.search).token;
    const username = queryString.parse(location.search).username;

    const { signInRedirect } = getConfig(jurisdiction, ipCountry);
    if (isAuthenticated) return <Redirect to={signInRedirect} />;

    let state = 'request';
    if ((!token || !username) && status === 'COMPLETE') state = 'requested';
    else if (username && token) state = 'reset';

    return (
      <BasePage page={page} className="MigrationContainer">
        {page && page.content && (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: marked(page.content) }}
          />
        )}

        <div className="wrap">
          <div className="form-wrap">
            {state === 'request' && __CLIENT__ && (
              <RequestSection
                isLoading={isLoading}
                onSubmit={this.onSubmitRequestReset}
                content={requestSection && requestSection.content}
              />
            )}
            {state === 'requested' && (
              <RequestedSection
                content={requestedSection && requestedSection.content}
              />
            )}
            {state === 'reset' && (
              <ResetSection
                isLoading={isLoading}
                onSubmit={this.onSubmitReset}
                content={resetSection && resetSection.content}
              />
            )}
          </div>
          {promoSection && promoSection.content && (
            <div className="form-wrap">
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: marked(promoSection.content),
                }}
              />
            </div>
          )}
        </div>
      </BasePage>
    );
  }
}

export default MigrationContainer;

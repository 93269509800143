import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { useSelector, connect } from 'react-redux';
import { getConfig } from './config';

export const withUrls = BaseComponent => {
  @connect(state => ({
    jurisdiction: state.app.jurisdiction,
    ipCountry: state.app.ipCountry,
  }))
  class WithUrls extends React.Component {
    render() {
      const urls = getUrls(this.props.jurisdiction, this.props.ipCountry);

      return <BaseComponent {...this.props} urls={urls} />;
    }
  }

  return hoistNonReactStatic(WithUrls, BaseComponent);
};

export const useUrls = () => {
  const jurisdiction = useSelector(state => state.app.jurisdiction);
  const ipCountry = useSelector(state => state.app.ipCountry);
  const isAuthenticated = useSelector(state => state.player.isAuthenticated);

  return getUrls(jurisdiction, ipCountry, isAuthenticated);
};

export const getUrls = (jurisdiction, ipCountry, isAuthenticated) => {
  const config = getConfig(jurisdiction, ipCountry);

  return {
    withdraw: (options = {}) => withdraw(config, options),
    withdrawString: (options = {}) => objectToUrl(withdraw(config, options)),

    deposit: (options = {}) => deposit(config, options),
    depositString: (options = {}) => objectToUrl(deposit(config, options)),

    signIn: (options = {}) => signIn(config, options),
    signInString: (options = {}) => objectToUrl(signIn(config, options)),

    signUp: (options = {}) => signUp(config, options),
    signUpString: (options = {}) => objectToUrl(signUp(config, options)),

    play: (game, options = {}) => play(config, options, game, isAuthenticated),
    playString: (game, options = {}) =>
      objectToUrl(play(config, options, game, isAuthenticated)),
  };
};

const signIn = (config, { pathname, search, hash, state, key }) => {
  if (config.urls?.signIn)
    return config.urls.signIn({ pathname, search, hash, state, key });

  if (config.isAccount) hash = 'sign-in';

  if (config.isPnp) pathname = '/fetch-balance';

  return {
    pathname,
    search,
    hash,
    state,
    key,
  };
};

const signUp = (config, { pathname, search, hash, state, key }) => {
  if (config.urls?.signUp)
    return config.urls.signUp({ pathname, search, hash, state, key });

  if (config.isAccount) hash = 'sign-up';

  if (config.isPnp) pathname = '/start-playing';

  return {
    pathname,
    search,
    hash,
    state,
    key,
  };
};

const deposit = (config, { pathname, search, hash, state, key }) => {
  if (config.urls?.deposit)
    return config.urls.deposit({ pathname, search, hash, state, key });

  if (config.isAccount) hash = 'deposit';

  if (config.isPnp) pathname = '/start-playing';

  return {
    pathname,
    search,
    hash,
    state,
    key,
  };
};

const withdraw = (config, { pathname, search, hash, state, key }) => {
  if (config.urls?.withdraw)
    return config.urls.withdraw({ pathname, search, hash, state, key });

  hash = 'withdraw';

  return {
    pathname,
    search,
    hash,
    state,
    key,
  };
};

const play = (
  config,
  { pathname, search, hash, state, key },
  game,
  isAuthenticated
) => {
  if (!isAuthenticated && (game?.isLive || config.disableDemoPlay)) {
    if (config.isAccount) hash = 'sign-in';

    if (config.isPnp) pathname = '/start-playing';
  } else {
    pathname = `/play/${game?.url}`;
  }

  return {
    pathname,
    search,
    hash,
    state,
    key,
  };
};

const objectToUrl = ({ pathname, search, hash }) => {
  let url = '';

  if (pathname) url += pathname;

  if (search) url += search;

  if (hash) url += `#${hash}`;

  return url;
};

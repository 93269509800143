import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { getConfig } from '@config';
import Agent from 'agentkeepalive';

const { duration = 600, whitelist = [] } = getConfig().axiosCache;

const cache = setupCache({
  maxAge: duration * 1000,
  exclude: {
    query: false,
    filter: request => !whitelist.some(e => request.url.includes(e)),
  },
});

const httpAgent = new Agent({});
const httpsAgent = new Agent.HttpsAgent({});

const client = axios.create({
  adapter: (...rest) => cache.adapter(...rest),

  //keepAlive pools and reuses TCP connections, so it's faster
  httpAgent,
  httpsAgent,
});

// To override cache use
// import http from @utils/http
// http.get('https://httpbin.org/get', {
//   cache: {
//     // Options override
//   }
// })

export default client;

const initialState = {
  isLoading: false,
  isInitialized: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'QUICK_DEPOSIT_INIT_START': {
      return {
        ...state,
        isLoading: true,
        isInitialized: false,
      };
    }

    case 'QUICK_DEPOSIT_INIT_COMPLETE': {
      return {
        ...state,
        isLoading: false,
        isInitialized:
          __CLIENT__ &&
          window.TGQuickDeposit.isInitialized &&
          window.TGQuickDeposit.paymentMethods.length > 0,
      };
    }

    case 'QUICK_DEPOSIT_INIT_FAILURE': {
      return {
        ...state,
        isLoading: false,
        isInitialied: false,
      };
    }

    default: {
      return state;
    }
  }
};

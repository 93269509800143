import React from 'react';
import { useSelector } from 'react-redux';
import Popup from '@components/Popup';
import marked from 'marked';
import { loadContents } from '@actions/content';
import { Translate } from 'tg-core-components';
import withRouteData from '@utils/withRouteData';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getLobbyUrl } from '@utils/game';

import './style.css';

const PlaySessionExpiredContainer = ({ match, location }) => {
  const section = useSelector(state =>
    state.content.sections.data?.find(
      s => s.identifier === 'play-session-expired-popup'
    )
  );

  const games = useSelector(state => state.content.games.data);

  let [title, ...content] = section?.content?.split('\n') || ['', ''];
  title = <div dangerouslySetInnerHTML={{ __html: marked(title) }} />;
  content = (
    <div dangerouslySetInnerHTML={{ __html: marked(content.join('\n')) }} />
  );

  const game = games?.find(g => g.url === match.params.slug);

  return (
    <Popup
      className="PlaySessionExpiredContainer"
      title={title}
      preventClose={true}>
      {content}
      <Link
        className="button primary"
        to={{ pathname: getLobbyUrl(game, location) }}>
        <Translate id="action.back_to_lobby" defaultMessage="Back to lobby" />
      </Link>
    </Popup>
  );
};

PlaySessionExpiredContainer.hashRoute = true;
PlaySessionExpiredContainer.globalLoader = false;
PlaySessionExpiredContainer.dataLoader = loadContents([
  {
    name: 'section',
    identifiers: ['play-session-expired-popup'],
  },
]);

export default withRouteData(withRouter(PlaySessionExpiredContainer));

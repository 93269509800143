import internalMessageActions from 'tg-core-redux/lib/modules/internal_message/action';
import { getConfig } from '@config';
import Logo from '@images/message-icon.svg';

// Get text from first html-tag before first <br> within that tag
const decodeHtml = html => {
  let excerptContainer = document.createElement('template');
  excerptContainer.innerHTML = html;
  const excerpt =
    excerptContainer.content.firstChild &&
    excerptContainer.content.firstChild.innerHTML.split('<br>');
  return (
    excerpt &&
    excerpt[0] &&
    excerpt[0].toString().replace(/<[^>]+>|&nbsp;/g, '')
  );
};

const pipe = (...fns) => value => fns.reduce((acc, fn) => fn(acc), value);

export const getIconUrl = iconName => {
  return iconName || Logo;
};

const getAllMessages = sessionId =>
  window.reduxStore.dispatch(
    internalMessageActions.getMessages(sessionId, getConfig().imProvider)
  );

const getUnpushedMessages = sessionId =>
  window.reduxStore.dispatch(
    internalMessageActions.getMessagesWithStatus(
      sessionId,
      'Unpushed',
      getConfig().imProvider
    )
  );

export const mapMessageToNotification = message => ({
  title: message.Title,
  text: pipe(
    decodeHtml,
    string => `${string.substring(0, 120)}${string.length > 120 ? '...' : ''}`
  )(message.Content),
  defaultAction: () =>
    window.routerHistory.push('#dashboard/messages', { messageId: message.Id }),
  action: message.Attributes.ctaAction
    ? () => window.routerHistory.push(message.Attributes.ctaAction)
    : null,
  btnText: {
    id: message.Attributes.ctaTranslationKey || 'label.readmore-button',
    defaultMessage: 'Read more',
  },
  icon: getIconUrl(message.Attributes.icon),
  timeout: message.Attributes.noticeTime
    ? Number(message.Attributes.noticeTime)
    : 7,
});

let internalMessageInterval = null;
export const initInternalMessagePolling = sessionId => {
  const ONE_MINUTE_IN_MS = 1000 * 60;

  stopInternalMessagePolling();
  getAllMessages(sessionId);
  getUnpushedMessages(sessionId);
  internalMessageInterval = setInterval(() => {
    if (window.reduxStore.getState().app.visibilityState === 'visible') {
      getUnpushedMessages(sessionId);
    }
  }, ONE_MINUTE_IN_MS);
};

export const stopInternalMessagePolling = () => {
  clearInterval(internalMessageInterval);
  internalMessageInterval = null;
};

import React from 'react';
import { useSelector } from 'react-redux';
import getByPath from 'lodash/get';
import marked from 'marked';
import { injectIntl } from 'react-intl';
import compose from 'recompose/compose';
import { Translate } from 'tg-core-components';
import BasePage from '@pages/BasePage';
import withRouteData from '@utils/withRouteData';
import GameGroupTitle from '@components/GameGroupTitle';
import GameGrid, { selectGameGridProps } from '@components/GameGrid';
import JackpotDrop from '@components/JackpotDrop';
import selectGames from '@selectors/selectGames';
import { getGamesByCategory } from '@utils/casino';
import translate from 'tg-core-components/lib/lib/utils/translate';

import './style.css';

const JackpotDropContainer = ({ intl }) => {
  const pages = useSelector(state => state.content.pages.data);
  const sets = useSelector(state => state.content.sets.data);
  const games = useSelector(state => selectGames(state));
  const gameGridProps = useSelector(selectGameGridProps);

  const page = pages && pages.find(p => p.identifier === 'jackpot-drop');
  const backgroundImage = getByPath(page, 'background.file.url');

  const jackpotGames =
    sets && sets.find(s => s.identifier === 'jackpot-drop-games');

  const jackpotGamesCategory = getGamesByCategory(games, 'jackpot-drop-games');

  const baseUrl = '/casino';

  return (
    <BasePage className="JackpotDropContainer">
      <main className="container">
        <header>
          <Translate
            id="header.jackpot-drop"
            defaultMessage="Daily jackpot drops"
            tagName="h1"
          />
        </header>

        <div
          className="JackpotDropContainer__widgets"
          style={
            backgroundImage
              ? { backgroundImage: `url(${backgroundImage})` }
              : {}
          }>
          <JackpotDrop />
        </div>

        <div className="JackpotDropContainer__games">
          <div className="grid-header">
            <GameGroupTitle action={`${baseUrl}/jackpot-drop-games`} />
          </div>
          <GameGrid
            items={
              jackpotGamesCategory?.length
                ? jackpotGamesCategory
                : jackpotGames?.items
            }
            overview
            baseUrl="/casino"
            title={translate(
              {
                id: 'label.jackpot-drop-games',
                defaultMessage: 'Daily drop games',
              },
              intl
            )}
            isJackpotDrop
            {...gameGridProps}
          />
        </div>

        {page && (
          <div
            className="JackpotDropContainer__content"
            dangerouslySetInnerHTML={{
              __html: marked(page.content || ''),
            }}
          />
        )}
      </main>
    </BasePage>
  );
};

export default compose(injectIntl, withRouteData)(JackpotDropContainer);

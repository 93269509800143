import cloneDeep from 'lodash/cloneDeep';
import intersection from 'lodash/intersection';
import Cookies from 'universal-cookie';
import { getConfig } from './config';

const _country = (countries, accountCountry = '', ipCountry = '') =>
  countries.some(
    c =>
      (c.includes(':ip') && ipCountry === c.replace(':ip', '')) ||
      (c.includes(':account') &&
        accountCountry === c.replace(':account', '')) ||
      c === accountCountry ||
      c === ipCountry
  );
const _auth = (authenticated, isAuthenticated) =>
  !!(authenticated && isAuthenticated);
const _device = (devices, device) =>
  devices.map(d => d.toLowerCase()).indexOf(device?.toLowerCase()) >= 0;
const _count = (rule, count) => eval(rule.replace('n', count));
const _activated = (rule, active) => rule === active;
const _btag = (rule, btag, isAuthenticated, options) => {
  // If player is not logged in use cookie btag instead
  if (!isAuthenticated) {
    const cookies = new Cookies();
    btag = __CLIENT__ ? cookies.get('tg-btag') : options?.cookies?.['tg-btag'];
  }

  return btag?.match(rule);
};
const _language = (rule, language) =>
  rule.map(l => l.toLowerCase()).indexOf(language.toLowerCase()) >= 0;
const _jurisdiction = (rule, userJurisdiction, jurisdiction) =>
  rule
    .map(j => j.toLowerCase())
    .indexOf(
      (userJurisdiction && userJurisdiction.toLowerCase()) ||
        jurisdiction.toLowerCase()
    ) >= 0;
const _kycVerifiedAll = (rule, kycItems) => {
  const approved = kycItems.filter(i => i.Status === 'Approved');
  return rule.every(i => approved.find(k => k.Type === i));
};
const _kycVerifiedAny = (rule, kycItems) => {
  const approved = kycItems.filter(i => i.Status === 'Approved');
  return rule.some(i => approved.find(k => k.Type === i));
};

const _startdate = startdate => {
  let today = Date.now();
  let startTime = new Date(startdate).getTime();

  if (today > startTime) return true;
};
const _enddate = enddate => {
  let today = Date.now();
  let endTime = new Date(enddate).getTime();

  if (today < endTime) return true;
};

const checkGameRules = (game, rules, isAuthenticated) => {
  const { provider, subprovider = '', tags = [], jurisdiction } = game;

  if (rules.providers?.includes(provider.toLowerCase())) return false;
  if (rules.subproviders?.includes(subprovider.toLowerCase())) return false;
  if (rules.jurisdictions?.includes(jurisdiction.toLowerCase())) return false;
  if (rules.tags?.some(t => tags.indexOf(t) >= 0)) return false;
  if (!isAuthenticated && tags.indexOf('authenticated') >= 0) return false;

  return true;
};

export const _gamerules = (
  game,
  country,
  ipcountry = '',
  isAuthenticated,
  zip,
  rules
) => {
  const gameRules = rules.filter(
    r =>
      (r.value || '').toLowerCase() === (country || '').toLowerCase() ||
      (r.value || '').toLowerCase() === (ipcountry || '').toLowerCase()
  );

  return gameRules
    .map(r => {
      if (!r.disabled_games) return true;

      let includeGame = true;

      const zipRules = r.disabled_games?.zip_codes?.filter(zipObj =>
        zipObj?.zip?.some(regexp => new RegExp(regexp, 'i').test(zip))
      );

      if (zipRules?.length) {
        includeGame = zipRules.every(zipRule =>
          checkGameRules(game, zipRule, isAuthenticated)
        );
      }

      if (includeGame) {
        includeGame = checkGameRules(game, r.disabled_games, isAuthenticated);
      }

      return includeGame;
    })
    .reduce((p, c) => p && c, true);
};

const _groups = (groups, playerGroups) =>
  intersection(groups, playerGroups).length;

const _operatingSystem = (operatingSystems, operatingSystem) =>
  operatingSystems
    .map(o => o.toLowerCase())
    .includes(operatingSystem.toLowerCase());

export const _zip = (zipCodes, userZip) =>
  zipCodes?.some(regexp => new RegExp(regexp, 'i').test(userZip));

let evaluate = (segments, state, options) => {
  const {
    device,
    ipCountry,
    locale,
    jurisdiction,
    operatingSystem,
  } = state.app;
  const { user, isAuthenticated, paymentStats, groups } = state.player;
  const { data: kycData } = state.kyc;

  let userCountry = undefined;
  let userJurisdiction = undefined;
  let userZip = undefined;
  let userBtag = undefined;

  if (user) userCountry = user.Country;
  if (user) userJurisdiction = user.Jurisdiction;
  if (user) userZip = user.Zip;
  if (user) userBtag = user.AffiliateTag;

  if (!Array.isArray(segments)) segments = [segments];

  let result = true;
  segments.forEach(segment => {
    if (segment.rules && segment.rules.excludes && result) {
      if (
        segment.rules.excludes.countries &&
        (userCountry || ipCountry) &&
        _country(segment.rules.excludes.countries, userCountry, ipCountry)
      )
        result = false;

      if (
        segment.rules.excludes.btag &&
        _btag(segment.rules.excludes.btag, userBtag, isAuthenticated, options)
      )
        result = false;

      if (
        segment.rules.excludes.language &&
        locale &&
        _language(segment.rules.excludes.language, locale)
      )
        result = false;

      if (
        segment.rules.excludes.activated &&
        user &&
        _activated(segment.rules.excludes.activated, user.IsVerified)
      )
        result = false;

      if (
        segment.rules.excludes.authenticated &&
        _auth(segment.rules.excludes.authenticated, isAuthenticated)
      )
        result = false;

      if (
        segment.rules.excludes.devices &&
        _device(segment.rules.excludes.devices, device)
      )
        result = false;

      if (
        segment.rules.excludes.DepositCount &&
        paymentStats &&
        _count(segment.rules.excludes.DepositCount, paymentStats.DepositCount)
      )
        result = false;

      if (
        segment.rules.excludes.WithdrawCount &&
        paymentStats &&
        _count(segment.rules.excludes.WithdrawCount, paymentStats.WithdrawCount)
      )
        result = false;

      if (
        segment.rules.excludes.TotalDeposit &&
        paymentStats &&
        _count(segment.rules.excludes.TotalDeposit, paymentStats.TotalDeposit)
      )
        result = false;

      if (
        segment.rules.excludes.KycVerifiedAny &&
        kycData &&
        _kycVerifiedAny(segment.rules.excludes.KycVerifiedAny, kycData)
      )
        result = false;

      if (
        segment.rules.excludes.KycVerifiedAll &&
        kycData &&
        _kycVerifiedAll(segment.rules.excludes.KycVerifiedAll, kycData)
      )
        result = false;

      if (
        segment.rules.excludes.startdate &&
        _startdate(segment.rules.excludes.startdate)
      )
        result = false;

      if (
        segment.rules.excludes.enddate &&
        _enddate(segment.rules.excludes.enddate)
      )
        result = false;

      if (
        segment.rules.excludes.TotalWithdraw &&
        paymentStats &&
        _count(segment.rules.excludes.TotalWithdraw, paymentStats.TotalWithdraw)
      )
        result = false;

      if (
        segment.rules.excludes.groups &&
        groups &&
        _groups(segment.rules.excludes.groups, groups)
      )
        result = false;

      if (
        segment.rules.excludes.jurisdictions &&
        (userJurisdiction || jurisdiction) &&
        _jurisdiction(
          segment.rules.excludes.jurisdictions,
          userJurisdiction,
          jurisdiction
        )
      )
        result = false;

      if (
        segment.rules.excludes.operating_systems &&
        _operatingSystem(
          segment.rules.excludes.operating_systems,
          operatingSystem
        )
      )
        result = false;

      if (
        segment.rules.excludes.zip_codes &&
        userZip &&
        _zip(segment.rules.excludes.zip_codes, userZip)
      )
        result = false;
    }

    if (segment.rules && segment.rules.includes && result) {
      if (
        segment.rules.includes.countries &&
        (userCountry || ipCountry) &&
        !_country(segment.rules.includes.countries, userCountry, ipCountry)
      )
        result = false;

      if (
        segment.rules.includes.btag &&
        !_btag(segment.rules.includes.btag, userBtag, isAuthenticated, options)
      )
        result = false;

      if (
        segment.rules.includes.language &&
        locale &&
        !_language(segment.rules.includes.language, locale)
      )
        result = false;

      if (
        segment.rules.includes.activated &&
        user &&
        !_activated(segment.rules.includes.activated, user.IsVerified)
      )
        result = false;

      if (
        segment.rules.includes.authenticated &&
        !_auth(segment.rules.includes.authenticated, isAuthenticated)
      )
        result = false;

      if (
        segment.rules.includes.devices &&
        !_device(segment.rules.includes.devices, device)
      )
        result = false;

      if (
        segment.rules.includes.startdate &&
        !_startdate(segment.rules.includes.startdate)
      )
        result = false;

      if (
        segment.rules.includes.enddate &&
        !_enddate(segment.rules.includes.enddate)
      )
        result = false;

      if (
        segment.rules.includes.DepositCount &&
        paymentStats &&
        !_count(segment.rules.includes.DepositCount, paymentStats.DepositCount)
      )
        result = false;

      if (
        segment.rules.includes.WithdrawCount &&
        paymentStats &&
        !_count(
          segment.rules.includes.WithdrawCount,
          paymentStats.WithdrawCount
        )
      )
        result = false;

      if (
        segment.rules.includes.TotalDeposit &&
        paymentStats &&
        !_count(segment.rules.includes.TotalDeposit, paymentStats.TotalDeposit)
      )
        result = false;

      if (
        segment.rules.includes.TotalWithdraw &&
        paymentStats &&
        !_count(
          segment.rules.includes.TotalWithdraw,
          paymentStats.TotalWithdraw
        )
      )
        result = false;

      if (
        segment.rules.includes.KycVerifiedAny &&
        kycData &&
        !_kycVerifiedAny(segment.rules.includes.KycVerifiedAny, kycData)
      )
        result = false;

      if (
        segment.rules.includes.KycVerifiedAll &&
        kycData &&
        !_kycVerifiedAll(segment.rules.includes.KycVerifiedAll, kycData)
      )
        result = false;

      if (
        segment.rules.includes.groups &&
        groups &&
        !_groups(segment.rules.includes.groups, groups)
      )
        result = false;

      if (
        segment.rules.includes.jurisdictions &&
        (userJurisdiction || jurisdiction) &&
        !_jurisdiction(
          segment.rules.includes.jurisdictions,
          userJurisdiction,
          jurisdiction
        )
      )
        result = false;

      if (
        segment.rules.includes.operating_systems &&
        !_operatingSystem(
          segment.rules.includes.operating_systems,
          operatingSystem
        )
      )
        result = false;

      if (
        segment.rules.includes.zip_codes &&
        userZip &&
        !_zip(segment.rules.includes.zip_codes, userZip)
      )
        result = false;
    }
  });

  return result;
};

export function segmentation(_items, state, options) {
  if (!_items) return;

  const filter = item => {
    if (item.items) item.items = segmentation(item.items, state, options);
    if (item.takeover)
      item.takeover = segmentation(item.takeover, state, options);
    if (item.sections)
      item.sections = segmentation(item.sections, state, options);

    if (!item.segments && (!item.segment || !item.segment.rules)) return true;

    const config = getConfig(state.app.jurisdiction, state.app.ipCountry);

    // Filter out games for specific licence requirements
    const { ipCountry } = state.app;
    const isAuthenticated = state.player ? state.player.isAuthenticated : false;
    const rules = state.content.config?.data?.find(c => c.key === 'countries')
      .value;
    const userCountry =
      state.player && state.player.user ? state.player.user.Country : '';
    const userZip = state?.player?.user?.Zip;

    if (
      !config.showDisabledGames &&
      (userCountry || ipCountry) &&
      item.provider && // If game
      item.name && // If game
      item.categories && // If game
      !_gamerules(item, userCountry, ipCountry, isAuthenticated, userZip, rules)
    )
      return false;

    return evaluate(item.segments || item.segment, state, options);
  };

  if (!Array.isArray(_items)) return filter(_items) ? _items : null;
  return cloneDeep(_items).filter(filter);
}

export function bindReferences(payload) {
  const loop = (items, includes, payload) => {
    for (let k in items) {
      let field = items[k];

      if (Array.isArray(field)) {
        items[k] = loop(field, includes, payload);
      } else if (field.sys) {
        let ref =
          includes[field.sys.linkType] &&
          includes[field.sys.linkType].find(i => i.sys.id === field.sys.id);

        if (!ref) ref = payload.items.find(i => i.sys.id === field.sys.id);

        if (ref && ref.fields) items[k] = loop(ref.fields, includes, payload);
      }
    }

    return items;
  };

  payload.items.map(item => {
    item.fields._sysCreatedAt = item.sys.createdAt;
    return loop(item.fields, payload.includes, payload);
  });

  return payload;
}

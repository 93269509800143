import React from 'react';
import marked from 'marked';
import { Button, Translate } from 'tg-core-components';

import './style.css';

const About = ({ page, onClickAboutCta }) => {
  return (
    <div className="About">
      {page?.content && (
        <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
      )}

      {page && (
        <Button className="button primary large" onClick={onClickAboutCta}>
          <Translate id="action.letsgo" defaultMessage="Let's go!" />
        </Button>
      )}
    </div>
  );
};

export default About;

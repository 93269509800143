import React, { Component } from 'react';
import bg from '@images/error-bg.jpg';
import { getConfig } from '@config';
import { Button, Translate } from 'tg-core-components';

import './style.css';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError && getConfig().CURRENT_ENV === 'prod') {
      return (
        <div
          className="ErrorBoundary"
          style={{ backgroundImage: `url(${bg})` }}>
          <div className="error-message-container">
            <Translate
              id="header.site-error"
              defaultMessage="Well that isn't right!"
              tagName="h1"
            />
            <Translate
              id="label.site-error"
              defaultMessage="Something went wrong or this page clearly doesn't exist."
              tagName="p"
            />
            <Button
              className="button primary"
              onClick={() => window.location.replace('/')}>
              <Translate id="action.back" defaultMessage="Go back" />
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

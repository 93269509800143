import {
  validateEmail,
  validatePhone,
  validatePersonIdv2,
  validateSupportNumber,
} from 'tg-core-api/lib/player';

import { apiCache } from './helpers';

const validateRules = async (fields, rules) => {
  const fieldKeys = Object.keys(fields);
  let errors = {};

  for (let i = 0; i < fieldKeys.length; i++) {
    const field = fieldKeys[i];

    const fieldRules = rules[field];

    for (let j = 0; j < fieldRules.length; j++) {
      const rule = fieldRules[j];
      const [fn, msg] = rule;

      // Only validate one rule for a field at a time
      // since we want to check the format before sending
      // a network validation request.
      if (errors[field]) continue;

      const value = fields[field];
      const isValid = value === undefined ? true : await fn(value);

      if (!isValid) {
        errors = {
          ...errors,
          [field]: msg,
        };
      }
    }
  }

  return errors;
};

const required = value =>
  typeof value === 'string' ? Boolean(value?.trim()) : Boolean(value);

const email = value =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
const personIdES = value =>
  value.match(/^([XYZ])?\d+[TRWAGMYFPDXBNJZSQVHLCKE]$/i);
const supportNumber = value => value.match(/^([EC])\d{8}$/i);
const phone = value => value.match(/^\+?\d{9,17}$/);
const minLength = length => value => value.length >= length;
const stringWithoutNumbers = value => value.match(/^[\D]+$/);
const hasCapitalLetter = value => value.match(/[A-Z]+/);
const hasLowercaseLetter = value => value.match(/[a-z]+/);
const hasDigit = value => value.match(/[0-9]+/);
const hasSpecialCharacter = value =>
  value.match(/[\s!"#\$%&'\(\)\*\+,\-\.\/:;<>\?@\[\\\]\^_`{|}~]/);
const noSpecialCharacters = value =>
  !value.match(/[!"#\$%&'\(\)\*\+,\-\.\/:;<>\?@\[\\\]\^_`{|}~]/);
const doesNotIncludeStrings = (strings = []) => value =>
  strings.filter(Boolean).every(
    string =>
      !value
        .trim()
        .toLowerCase()
        .includes(
          string &&
            string
              .toString()
              .trim()
              .toLowerCase()
              .replace(/^0+/, '')
        )
  );
const blacklistedCharacters = value =>
  !value.match(/[\+\=?!@%&<>\[\]{}()<>|$€£#\/\\]/);

const externalEmailValidation = jurisdiction => email => {
  return apiCache(validateEmail)(encodeURIComponent(email), jurisdiction).then(
    res => {
      if (!res?.isNetworkException) {
        return true;
      }

      const { Code } = res.value.response.data;

      if (Code === 'RateLimited') {
        return true;
      }

      return false;
    }
  );
};

const externalPersonIdValidation = jurisdiction => personId => {
  return apiCache(validatePersonIdv2)(personId, jurisdiction).then(res => {
    if (res?.data?.IsValid) {
      return true;
    }

    const { Code } = res.value.response.data;

    if (Code === 'RateLimited') {
      return true;
    }

    return false;
  });
};

const externalSupportNumberValidation = jurisdiction => supportNumber => {
  return apiCache(validateSupportNumber)(supportNumber, jurisdiction).then(
    res => {
      if (!res?.isNetworkException) {
        return true;
      }

      const { Code } = res.value.response.data;

      if (Code === 'RateLimited') {
        return true;
      }

      return false;
    }
  );
};

const externalPhoneValidation = jurisdiction => phone => {
  return apiCache(validatePhone)(phone, jurisdiction).then(res => {
    if (!res?.isNetworkException) {
      return true;
    }

    const { Code } = res.value.response.data;

    if (Code === 'RateLimited') {
      return true;
    }

    return false;
  });
};

const getPasswordTestStrings = ({
  FirstName,
  LastName,
  LastName2,
  BirthDate,
}) => {
  const splittedFirstName = FirstName ? FirstName.split(' ') : undefined;
  const splittedLastName = LastName ? LastName.split(' ') : undefined;
  const splittedLastName2 = LastName2 ? LastName2.split(' ') : undefined;
  const [year, month, day] = (BirthDate || '').split('/');
  const birthDate1 = month && day && month + day;
  const birthDate2 = month && day && day + month;

  return [
    FirstName,
    LastName,
    LastName2 !== '' ? LastName2 : undefined,
    year,
    birthDate1,
    birthDate2,
    ...(splittedFirstName ? splittedFirstName : []),
    ...(splittedLastName ? splittedLastName : []),
    ...(splittedLastName2 ? splittedLastName2 : []),
  ];
};

export {
  required,
  email,
  phone,
  validateRules,
  minLength,
  stringWithoutNumbers,
  doesNotIncludeStrings,
  externalEmailValidation,
  externalPhoneValidation,
  externalPersonIdValidation,
  externalSupportNumberValidation,
  hasCapitalLetter,
  hasLowercaseLetter,
  hasDigit,
  hasSpecialCharacter,
  getPasswordTestStrings,
  noSpecialCharacters,
  personIdES,
  supportNumber,
  blacklistedCharacters,
};

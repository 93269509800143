import React, { Fragment } from 'react';
import { Icon, Button, Translate } from 'tg-core-components';

const Join = ({
  onJoinTournament,
  isAuthenticated = true,
  isLoading = false,
  isJoined,
  canJoin = false,
}) => {
  return (
    <div className="tournament-join">
      {canJoin && (
        <Button
          isLoading={isLoading}
          className="button primary icon"
          onClick={onJoinTournament}
          disabled={!canJoin || isJoined}>
          {!isLoading && (
            <Fragment>
              {isJoined && (
                <Fragment>
                  <Translate id="message.joined" defaultMessage="Joined" />
                  <Icon icon="check-circle-o" />
                </Fragment>
              )}

              {!isJoined && isAuthenticated && (
                <Translate id="action.join-tournament" defaultMessage="Join" />
              )}

              {!isJoined && !isAuthenticated && (
                <Translate
                  id="action.signin-and-join-tournament"
                  defaultMessage="Sign up & Join"
                />
              )}
            </Fragment>
          )}
        </Button>
      )}
    </div>
  );
};

export default Join;

import React from 'react';
import { Translate } from 'tg-core-components';
import { Link } from 'react-router-dom';
import translate from 'tg-core-components/lib/lib/utils/translate';
import compose from 'recompose/compose';
import { injectIntl } from 'react-intl';

import './style.css';

const NoGamesMessage = ({ linkLocation, onClickLink, intl }) => (
  <div className="NoGamesMessage">
    <Translate
      id="message.no-games"
      values={{
        link: (
          <Link to={linkLocation || {}} onClick={onClickLink}>
            {translate(
              {
                id: 'message.link-to-overview',
                defaultMessage: 'overview',
              },
              intl
            )}
          </Link>
        ),
      }}
      defaultMessage="Oh no, nothing found. You can search for games, providers, themes, tags and categories. You can also go back to the {link}."
    />
  </div>
);

export default compose(injectIntl)(NoGamesMessage);

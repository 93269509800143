import React from 'react';
import { Link } from 'react-router-dom';
import marked from 'marked';

const renderer = new marked.Renderer();
renderer.paragraph = e => e;

const TopHeaderNavList = props =>
  props.items.map(item =>
    /(http(s?)):\/\//gi.test(item.url) ? (
      <a
        className={'TopHeader__nav-item'}
        key={item.identifier}
        rel="noopener noreferrer"
        href={item.url}
        target="_blank">
        {item.menuIcon && (
          <img
            className={'TopHeader__nav-item__icon'}
            src={item.menuIcon.file.url}
            alt="menu"
          />
        )}
        <span
          className="TopHeader__nav-item__text"
          dangerouslySetInnerHTML={{
            __html: marked(item.menu || '', { renderer }),
          }}
        />
      </a>
    ) : (
      <Link
        className={'TopHeader__nav-item'}
        to={`/${item.url}`}
        key={item.identifier}>
        {item.menuIcon && (
          <img
            className={'TopHeader__nav-item__icon'}
            src={item.menuIcon.file.url}
            alt="menu"
          />
        )}
        <span
          className="TopHeader__nav-item__text"
          dangerouslySetInnerHTML={{
            __html: marked(item.menu || '', { renderer }),
          }}
        />
      </Link>
    )
  );

export default TopHeaderNavList;

import classNames from 'classnames';
import React, { useState } from 'react';
import { Translate, Icon, Button } from 'tg-core-components';

import './style.css';

const CurrencySelectorItem = ({ onChange, isActive, name, code }) => {
  return (
    <div
      className={classNames('CurrencySelectorItem', {
        'CurrencySelectorItem--active': isActive,
      })}
      onClick={() => onChange?.(code)}>
      <span className="CurrencySelectorItem__name"> {name} </span>
    </div>
  );
};

const CurrencySelectorList = ({
  onChange,
  activeCurrency,
  currencies,
  mainCurrencies,
  mainCurrenciesCount,
}) => {
  const [showSecondaryCurrencies, setShowSecondaryCurrencies] = useState(false);

  const mainDisplayCurrencies = currencies
    .slice(0, mainCurrenciesCount)
    ?.map(c => c.code);

  const main = currencies.filter(currency =>
    mainDisplayCurrencies.includes(currency.code)
  );

  const secondary = currencies.filter(
    currency => !mainDisplayCurrencies.includes(currency.code)
  );

  return (
    <div className="CurrencySelectorList">
      {!mainCurrencies &&
        currencies?.map(c => {
          return (
            <CurrencySelectorItem
              key={c.code}
              icon={null}
              name={c.name}
              code={c.code}
              onChange={onChange}
              isActive={activeCurrency === c.code}
            />
          );
        })}

      {mainCurrencies && (
        <div className="CurrencySelectorList__expandable">
          <div className="CurrencySelectorList__expandable__group">
            {main.map(c => {
              return (
                <CurrencySelectorItem
                  key={c.code}
                  icon={null}
                  name={c.name}
                  code={c.code}
                  onChange={onChange}
                  isActive={activeCurrency === c.code}
                />
              );
            })}
          </div>

          <Button
            type="button"
            className="link show-more"
            onClick={() =>
              setShowSecondaryCurrencies(!showSecondaryCurrencies)
            }>
            {showSecondaryCurrencies ? (
              <Translate id="action.show-less" defaultMessage="Show less" />
            ) : (
              <Translate id="action.show-more" defaultMessage="Show more" />
            )}
          </Button>

          {showSecondaryCurrencies && (
            <div className="CurrencySelectorList__expandable__group">
              {secondary.map(c => (
                <CurrencySelectorItem
                  key={c.code}
                  icon={null}
                  name={c.name}
                  code={c.code}
                  onChange={onChange}
                  isActive={activeCurrency === c.code}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const CurrencySelector = ({
  onSelect,
  currency,
  currencies,
  expanded = false,
  expandable = true,
  mainCurrencies = false,
  mainCurrenciesCount,
}) => {
  const [open, setOpen] = useState(expanded);

  const handleChange = c => {
    onSelect?.(c);
    setOpen(false);
  };

  return (
    <div
      className={classNames('CurrencySelector', {
        'CurrencySelector--open': open,
      })}>
      <div
        className="CurrencySelector__header"
        onClick={() => expandable && setOpen(!open)}>
        <div className="CurrencySelector__header-label">
          <Translate
            id="label.currency-selector"
            defaultMessage="Display currency in:"
          />
        </div>
        <div className="CurrencySelector__header-currency">
          <span>{currency}</span>
          {expandable && (
            <div className="expand-icon">
              <Icon icon="angle-down" />
            </div>
          )}
        </div>
      </div>
      {open && (
        <CurrencySelectorList
          onChange={c => (c === currency ? setOpen(false) : handleChange(c))}
          activeCurrency={currency}
          currencies={currencies}
          mainCurrencies={mainCurrencies}
          mainCurrenciesCount={mainCurrenciesCount}
        />
      )}
    </div>
  );
};

export default CurrencySelector;

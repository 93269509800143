import { makeApiCallAction } from 'tg-core-redux/lib/lib/action';
import http from '@utils/http';
import queryString from 'query-string';
import { segmentation } from '@utils/segmentation';
import * as contentUtils from '@utils/content';
import deviceDetect from '@utils/deviceDetect';
import { getConfig } from '@config';
import Cookies from 'universal-cookie';
import isFunction from 'lodash/isFunction';
import { getContentPreviewSettings } from '@utils/contentPreview';

global.__LOAD_STATE__ = global.__LOAD_STATE__ || {};

export const loadContents = contentOptions => (match, location, hashRoute) => (
  store,
  options
) => {
  const cookies = new Cookies();
  const { locale } = store.getState().app;
  const search = __CLIENT__
    ? window.location.search
    : options.originalUrl.split('?')[1];

  if (queryString.parse(search).content_preview === 'true') {
    __CLIENT__
      ? cookies.set('content_preview', 'activated', {
          expires: new Date(Date.now() + 31556952000 * 2),
          secure: getConfig().CURRENT_ENV === 'prod',
          path: '/',
        })
      : options.res.cookie('content_preview', 'activated', {
          expires: new Date(Date.now() + 31556952000 * 2),
          secure: getConfig().CURRENT_ENV === 'prod',
          path: '/',
        });
  }

  const loader = contentOptions.map(contentOption => {
    let query = {
      identifiers: contentOption.identifiers,
      fields: contentOption.fields,
      segment: contentOption.segment,
      urls: contentOption.urls,
      skip: contentOption.skip,
      include: contentOption.include_depth,
      order: contentOption.order,
    };

    // filter by ids
    if (isFunction(query.identifiers)) {
      query.identifiers = query.identifiers(match, store, options);
    }

    // filter by segment
    if (isFunction(query.segment)) {
      query.segment = contentOption.segment(match, store, options);
    }

    // filter by url
    if (isFunction(query.urls)) {
      query['urls'] = contentOption.urls(match, store, options);
    }

    if (typeof contentOption.limit !== 'undefined') {
      query['limit'] = contentOption.limit;
    }

    if (typeof contentOption.query !== 'undefined') {
      query = { ...query, ...contentOption.query(match) };
    }

    return store.dispatch(
      fetchContent(contentOption.name, locale, query, options)
    );
  });

  return Promise.all(loader)
    .then(() =>
      store.dispatch(filterOutContentBySegmentation(undefined, options))
    )
    .then(() => {
      const url = hashRoute ? location.hash : match.url;
      global.__LOAD_STATE__[url] = true;
    });
};

export const fetchGames = () => (dispatch, getState) => {
  const device = deviceDetect(window.navigator.userAgent);
  const { locale } = getState().app;
  const gameSegments = contentUtils.getGameSegment(device);

  let options = {
    limit: 1000,
    skip: 0,
    order: '-fields.sortOrder',
    'fields.segment.sys.id[in]': gameSegments.join(','),
  };

  return Promise.all([
    dispatch(fetchContent('game', locale, options)),
    dispatch(fetchContent('game', locale, { ...options, skip: 1000 })),
  ]).then(() => dispatch(filterOutContentBySegmentation('game')));
};

export const fetchContent = (content_type, locale, query, options) => (
  dispatch,
  getState
) => {
  const { content: config } = getConfig(getState().app.jurisdiction);

  return dispatch(
    makeApiCallAction(
      'CONTENT_FETCH_' + content_type.toUpperCase(),
      contentUtils.fetchContent(
        content_type,
        locale,
        query,
        config,
        options && options.cookies
      )
    )
  );
};

export const fetchTranslation = (locale = 'en-US', options, q = {}) => (
  dispatch,
  getState
) => {
  const previewContentSettings = getContentPreviewSettings(
    null,
    options && options.cookies
  );
  const isPreviewMode =
    previewContentSettings && previewContentSettings.previewMode;

  const { content: config } = getConfig(getState().app.jurisdiction);

  const environment = config.environment;

  const query = queryString.stringify({
    access_token: isPreviewMode
      ? config.preview_access_token
      : config.access_token,
    content_type: 'translation',
    include: 2,
    locale: locale,
    limit: 1000,
    ...q,
  });

  const url = `${isPreviewMode ? config.preview_host : config.host}/spaces/${
    config.space
  }/environments/${environment}/entries?${query}`;
  const setupTranslation = http.get(url).then(translationData => {
    const localeCode = locale.split('-')[0].toLowerCase();

    import(`@formatjs/intl-pluralrules/locale-data/${localeCode}`);
    import(`@formatjs/intl-relativetimeformat/locale-data/${localeCode}`);

    return translationData;
  });

  return dispatch(
    makeApiCallAction('CONTENT_FETCH_TRANSLATION', setupTranslation)
  );
};

const shouldDoSegmentation = (contentTypes, contentKey) => {
  const ignoredContentKeys = ['config', 'bonuses'];
  if (ignoredContentKeys.includes(contentKey)) return false;

  if (typeof contentTypes === 'undefined') return true;
  if (typeof contentTypes === 'string') {
    return contentKey === getPrivateContentType(contentTypes);
  }

  return contentTypes.map(getPrivateContentType).includes(contentKey);
};

// TODO: should find a better way to map contentType to acttual contentkey in redux
const getPrivateContentType = contentType => {
  if (contentType === 'config') return contentType;

  return `${contentType}s`;
};

export const filterOutContentBySegmentation = (contentTypes, options) => (
  dispatch,
  getState
) => {
  const state = getState();

  const segmentedContent = Object.keys(state.content.originalContent).reduce(
    (result, contentKey) => {
      result[contentKey] = shouldDoSegmentation(contentTypes, contentKey)
        ? {
            data: segmentation(
              state.content.originalContent[contentKey].data,
              state,
              options
            ),
            updated: state.content.originalContent[contentKey].updated,
            isLoading: state.content.originalContent[contentKey].isLoading,
          }
        : state.content[contentKey];

      return result;
    },
    {}
  );

  return dispatch({
    type: 'TRIGGER_SEGMENTATION',
    payload: segmentedContent,
  });
};

export const fetchLocales = locale =>
  fetchContent('language', locale, { order: '-fields.order' });

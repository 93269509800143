import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { Translate } from 'tg-core-components';
import logotype from '@images/logo.png';
import LanguageSelector from '@pages/LanguageSelector';
import { Icon, Skeleton } from 'tg-core-components';
import { withConfig } from '@config';
import withScroll from '@utils/withScroll';
import marked from 'marked';
import hamburgerIcon from '@images/hamburger-icon.svg';
import userIcon from '@images/user.svg';
import MembershipActions, {
  selectMembershipActionsProps,
} from './MembershipActions';
import cn from 'classnames';
import { startChat } from '@utils/chat';
import compose from 'recompose/compose';
import selectUnreadNotifications from '@selectors/selectUnreadNotifications';
import TopHeader from '@components/TopHeader';
import { useWindowWidth } from '@utils/hooks';
import { getFirstPathnameInUrl } from '@utils/getFirstPathnameInUrl';
import setLastVisitedCookie from '@utils/setLastVisitedCookie';

const renderer = new marked.Renderer();
renderer.paragraph = e => e;

import './style.css';

export const selectHeaderProps = state => ({
  device: state.app.device,
  jurisdiction: state.app.jurisdiction,
  topNav: state.content.menus.data.find(m => m.identifier === 'header-top-nav'),
  topHeaderNav: state.content.menus.data.find(
    m => m.identifier === 'top-header-nav'
  ),
  topHeaderSecondNav: state.content.menus.data.find(
    m => m.identifier === 'top-header-second-nav'
  ),
  isAuthenticated: state.player.isAuthenticated,
  user: state.player.user,
  unreadNotifications: selectUnreadNotifications(state),
  membershipActionsProps: selectMembershipActionsProps(state),
});

const HeaderProfileSkeleton = () => (
  <div className="HeaderProfileSkeleton">
    <Skeleton.SkeletonCircle />
    <div className="HeaderProfileSkeleton__info">
      <Skeleton.SkeletonLine bold />
      <Skeleton.SkeletonLine />
    </div>
  </div>
);

const Header = ({
  /* props from selector */
  device,
  jurisdiction,
  topNav,
  topHeaderNav,
  topHeaderSecondNav,
  isAuthenticated,
  user,
  unreadNotifications,
  cookies,

  /* withConfig */
  config,

  /* withLocation */
  location,

  /* withScroll */
  hasReachedScrollDistance,

  /* props from parent */
  transparent,

  membershipActionsProps,
}) => {
  const testIsActive = (match, location) => {
    if (match?.url == '/sports' && location.pathname == '/sports/live-betting')
      return false;
    return match;
  };

  const depositRefUrl = __CLIENT__
    ? encodeURI(location.pathname + location.search + location.hash)
    : null;

  const windowWidth = useWindowWidth();
  const deviceIsDesktopOrTablet =
    device === 'Desktop' || (device === 'Tablet' && windowWidth > 992);

  return (
    <div
      className={cn('header-wrapper', {
        'Header--transparent': transparent,
        'Header--has-reached-scroll-distance': hasReachedScrollDistance,
      })}>
      <Background
        hasReachedScrollDistance={hasReachedScrollDistance}
        transparent={transparent}
      />

      {config.showTopHeader && (
        <TopHeader
          topHeaderNav={topHeaderNav}
          topHeaderSecondNav={topHeaderSecondNav}
        />
      )}

      <header className="Header container">
        {((config.globalNavContainer && device === 'Desktop') ||
          (config.globalNavContainer &&
            device === 'Tablet' &&
            windowWidth > 992) ||
          config.alwaysShowTopMenu) && (
          <div className="Header__hamburger global-nav">
            <Link to={{ hash: 'global-nav' }} className="hamburger-icon">
              <img src={hamburgerIcon} alt="menu" />
            </Link>
          </div>
        )}

        <Link
          className="Header__logo"
          onClick={e => {
            // Scroll to the top if you're on the designated index page
            const indexPath = new RegExp(
              `^${(isAuthenticated && config.signInRedirect) ||
                (config.disableIndexPage && '/casino') ||
                '/'}$`
            );

            if (
              indexPath.test(location?.pathname) ||
              config.alwaysScrollToTopOnLogoClick
            ) {
              e.preventDefault();
              window.location.href = '#';
            }
          }}
          to={(isAuthenticated && config.signInRedirect) || '/'}>
          <img src={logotype} alt="header-logo" />
        </Link>

        <div className="Header__topnav">
          {topNav?.items?.map(item => {
            const activePath = getFirstPathnameInUrl(location);
            const isActive = activePath === item.url;
            return (
              item &&
              // Checking if it's an external link
              (/(http(s?)):\/\//gi.test(item.url) ? (
                <a
                  key={item.identifier}
                  rel="noopener noreferrer"
                  href={item.url}
                  className="top-nav-menu-item"
                  target="_blank"
                  dangerouslySetInnerHTML={{
                    __html: marked(item.menu || item.name || '', { renderer }),
                  }}
                />
              ) : (
                <NavLink
                  key={item.identifier}
                  to={item.url ? `/${item.url}` : ''}
                  activeClassName="active"
                  isActive={(match, location) => testIsActive(match, location)}
                  className="top-nav-menu-item"
                  onClick={() => {
                    if (item.url.startsWith('#')) {
                      window.location.hash = item.url;
                    }

                    config.useVisitedCookie &&
                      setLastVisitedCookie(item, cookies);
                  }}>
                  {item.menuIcon && (
                    <span className="icon">
                      <img
                        src={
                          isActive && item.selectedIcon
                            ? item.selectedIcon?.file?.url
                            : item.menuIcon?.file?.url
                        }
                        alt="menu"
                        loading="lazy"
                      />
                    </span>
                  )}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: marked(item.menu || item.name || '', {
                        renderer,
                      }),
                    }}
                  />
                </NavLink>
              ))
            );
          })}
        </div>

        {(config.languageSelector?.position === 'header' ||
          config.languageSelector?.position === 'both') && (
          <div className="Header__language">
            <LanguageSelector />
          </div>
        )}

        <div className="Header__support">
          <Link
            to="/support"
            onClick={e => {
              if (config.openSupportWidget.header) {
                e.preventDefault();
                startChat(jurisdiction);
              }
            }}>
            <Icon icon="headphones" />{' '}
            <Translate
              id="action.contactsupport"
              defaultMessage="Contact support"
            />
          </Link>
        </div>

        {config.isAccount && deviceIsDesktopOrTablet && isAuthenticated && (
          <>
            {!user && <HeaderProfileSkeleton />}

            {user && (
              <div className="Header__profile">
                <Link to={{ hash: 'dashboard' }} className="profile-icon">
                  <img src={userIcon} alt="menu" />
                  <span
                    className="notifications-badge notifications-badge--right"
                    data-notifications={unreadNotifications.internalMessages}
                  />
                  <div className="name-and-settings">
                    <span>{`${user.FirstName} ${user.LastName}`}</span>
                    <Translate
                      id="label.profile-settings"
                      defaultMessage="Profile settings"
                    />
                  </div>
                </Link>
              </div>
            )}
          </>
        )}

        <div className="Header__actions">
          <MembershipActions
            {...membershipActionsProps}
            ref_url={depositRefUrl}
          />
        </div>

        {((config.isPnp && device === 'Desktop') ||
          config.alwaysShowHamburgerMenu) && (
          <div className="Header__hamburger">
            <Link to={{ hash: 'dashboard' }} className="hamburger-icon">
              <img src={hamburgerIcon} alt="menu" />
              <span
                className="notifications-badge notifications-badge--right"
                data-notifications={unreadNotifications.internalMessages}
              />
            </Link>
          </div>
        )}
      </header>
    </div>
  );
};

export default compose(
  withCookies,
  withConfig,
  withRouter,
  withScroll(25)
)(Header);

const Background = ({ hasReachedScrollDistance, transparent }) => {
  return (
    <div
      className={cn('Header__background', {
        'Header__background--visible': hasReachedScrollDistance || !transparent,
      })}
    />
  );
};

import React from 'react';
import cn from 'classnames';
import { Input, Button } from 'tg-core-components';
import {
  validateRules,
  required,
  doesNotIncludeStrings,
  hasCapitalLetter,
  hasLowercaseLetter,
  hasDigit,
  hasSpecialCharacter,
  minLength,
} from '../../validation';
import PasswordFeedback from '../../passwordFeedback';
import { getContinueLabel } from '../../helpers';

import './style.css';

import translate from 'tg-core-components/lib/lib/utils/translate';

const Password = ({
  /* Props from parent*/
  onChange,
  intl,
  fields,
  nextStep,
  statuses,

  /* Props from selector */
  device,
  jurisdiction,
}) => {
  const isValid = statuses.Password === 'success';

  return (
    <form
      className="Password"
      onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <Input
        autoFocus
        type="password"
        label={translate(
          { id: 'label.choose_password', defaultMessage: 'Password' },
          intl
        )}
        value={fields.Password}
        name="Password"
        onChange={(_, Password) => onChange({ Password })}
        status={statuses.Password === 'success' ? statuses.Password : 'idle'}
      />

      <PasswordFeedback {...fields} jurisdiction={jurisdiction} />

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ Password, Email }) => {
  const rules = {
    Password: [
      [required, 'error.empty.password'],
      [hasCapitalLetter, 'error.condition.password'],
      [hasLowercaseLetter, 'error.condition.password'],
      [hasDigit, 'error.condition.password'],
      [hasSpecialCharacter, 'error.condition.password'],
      [minLength(8), 'error.condition.password'],
      [doesNotIncludeStrings([Email]), 'errors.password-equal-email'],
    ],
  };
  const errors = await validateRules({ Password }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  device: state.app.device,
  jurisdiction: state.app.jurisdiction,
});

export default {
  name: 'password',
  icon: 'lock',
  Component: Password,
  validate,
  selector,
  fields: ['Password'],
};

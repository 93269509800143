import React, { useEffect, useState } from 'react';
import { Input, Button } from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { validatePersonIdv2 } from 'tg-core-api/lib/player';
import cn from 'classnames';
import {
  validateRules,
  required,
  externalSupportNumberValidation,
  supportNumber,
} from '../../validation';
import { getContinueLabel, apiCache } from '../../helpers';

const SupportNumber = ({
  /* Props from parent */
  fields,
  onChange,
  isLoading,
  errors,
  intl,
  nextStep,
  statuses,

  /* Props from selector */
  jurisdiction,
  device,
}) => {
  const [supportNumberRequired, setSupportNumberRequired] = useState(false);

  useEffect(() => {
    apiCache(validatePersonIdv2)(fields.PersonId, jurisdiction).then(res => {
      setSupportNumberRequired(res.data?.RequiresSupportNumber);
    });
  }, []); // eslint-disable-line

  const isValid = supportNumberRequired
    ? statuses.SupportNumber === 'success'
    : true;

  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <Input
        disabled={!supportNumberRequired}
        autoFocus
        name="SupportNumber"
        label={translate(
          {
            id: 'label.support_number',
            defaultMessage: 'Support number',
          },
          intl
        )}
        value={fields.SupportNumber}
        onChange={(_, SupportNumber) => onChange({ SupportNumber })}
        status={(isLoading && 'loading') || statuses.SupportNumber}
        statusText={
          errors.SupportNumber && translate({ id: errors.SupportNumber }, intl)
        }
      />

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ SupportNumber }, jurisdiction) => {
  const rules = {
    SupportNumber: [
      [required, 'error.empty.supportNumber'],
      [supportNumber, 'error.format.supportNumber'],
      [
        externalSupportNumberValidation(jurisdiction),
        'error.invalid.supportNumber',
      ],
    ],
  };

  const errors = await validateRules({ SupportNumber }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  device: state.app.device,
  jurisdiction: state.app.jurisdiction,
});

export default {
  name: 'support-number',
  icon: 'user',
  Component: SupportNumber,
  validate,
  selector,
  fields: ['SupportNumber'],
};

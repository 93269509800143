import React from 'react';
import { Translate } from 'tg-core-components';
import cn from 'classnames';

import { hiddenString } from '@utils/string';

import './tournament.css';

const LeaderboardItem = ({ p, playerId, firstName, lastName }) => (
  <tr
    className={cn('leaderboard-item', {
      'leaderboard-item-active': p.Id === playerId,
    })}>
    <td>{p.Position}</td>
    <td>
      {p.Id === playerId
        ? `${firstName} ${lastName}`
        : `${hiddenString(p.FirstName)} ${hiddenString(p.LastName)}`}
    </td>
    <td>{p.Points.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</td>
  </tr>
);

const Leaderboard = ({
  player,
  playerList,
  isLoading = false,
  relativePlayers,
}) => {
  const playerId = player.user && player.user.Id;
  const firstName = player.user && player.user.FirstName;
  const lastName = player.user && player.user.LastName;
  const noPlayers = playerList && playerList.length === 0;

  const showRelativePlayers = !playerList.map(p => p.Id).includes(playerId);

  return (
    <div className="leaderboard">
      {isLoading && (
        <Translate
          id="message.loading.leaderboard"
          defaultMessage="Loading..."
        />
      )}

      {!isLoading && noPlayers && (
        <Translate
          id="message.empty.leaderboard"
          defaultMessage="No players has entered..."
        />
      )}

      {!isLoading && !noPlayers && (
        <table>
          <thead className="leaderboard-header-item">
            <tr>
              <th>
                <Translate id="label.position" defaultMessage="Position" />
              </th>
              <th>
                <Translate id="label.name" defaultMessage="Name" />
              </th>
              <th>
                <Translate id="label.points" defaultMessage="Points" />
              </th>
            </tr>
          </thead>
          <tbody>
            {playerList.map((p, i) => (
              <LeaderboardItem
                key={i}
                p={p}
                playerId={playerId}
                firstName={firstName}
                lastName={lastName}
              />
            ))}
          </tbody>

          {showRelativePlayers && relativePlayers.length > 0 && (
            <tbody className="relative">
              {relativePlayers.map((p, i) => (
                <LeaderboardItem
                  key={i}
                  p={p}
                  playerId={playerId}
                  firstName={firstName}
                  lastName={lastName}
                />
              ))}
            </tbody>
          )}
        </table>
      )}
    </div>
  );
};

export default Leaderboard;

import React, { Component } from 'react';
import Popup from '@components/Popup';
import { connect } from 'react-redux';
import marked from 'marked';
import { Button, Translate } from 'tg-core-components';
import { loadContents } from '@actions/content';
import withRouteData from '@utils/withRouteData';
import { withRouter } from 'react-router';
import { Money } from 'tg-core-components';
import { FormattedMessage } from 'react-intl';

import './style.css';

@withRouter
@withRouteData
@connect(state => ({
  sections: state.content.sections.data,
  user: state.player,
  bonus: state.bonus,
  wallet: state.wallet.data,
  currency: state.player.user?.Currency,
}))
class SuccessfulTransactionContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'section',
      identifiers: ['success-deposit-overlay', 'success-withdraw-overlay'],
    },
  ]);

  render() {
    const transactionType = this.props.overlayLocation.hash
      .slice(1)
      .split(',')
      .includes('popup/successful-deposit')
      ? 'success-deposit-overlay'
      : 'success-withdraw-overlay';

    const sucessfulDepositContent = this.props.sections?.find(
      s => s.identifier === transactionType
    );

    const currency = this.props.currency;

    const claimedBonus = Boolean(this.props.location.state?.didClaimBonus);
    const totalBalance = (
      <Money value={this.props.wallet?.Total.Balance} currency={currency} />
    );

    const bonusAmount = (
      <Money
        value={this.props.location.state?.claimedBonus?.Amount}
        currency={currency}
      />
    );
    const depositedMoney = (
      <Money
        value={this.props.user.paymentStats?.LastDepositAmount}
        currency={currency}
      />
    );

    return (
      <Popup className="SuccessfulDepositContainer">
        {sucessfulDepositContent && (
          <div
            dangerouslySetInnerHTML={{
              __html: marked(sucessfulDepositContent.content || ''),
            }}
          />
        )}

        {this.props.location.state?.didClaimBonus && (
          <div className="SuccessfulDepositContainer__bonus-activated">
            <Translate
              id="label.bonus-selector.bonus-activated"
              defaultMessage="Your bonus is active"
            />
          </div>
        )}

        <div className="TransactionInfo">
          {transactionType === 'success-deposit-overlay' ? (
            <FormattedMessage
              id="message.successful-deposit"
              defaultMessage={`Deposited {depositedMoney}`}
              values={{
                bonusAmount: bonusAmount,
                claimedBonus: claimedBonus,
                depositedMoney: depositedMoney,
                totalBalance: totalBalance,
              }}
            />
          ) : (
            <FormattedMessage
              id="message.successful-withdraw"
              defaultMessage="Current Balance {totalBalance}"
              values={{
                totalBalance: totalBalance,
              }}
            />
          )}
        </div>

        <div className="actions">
          <Button
            className="button primary"
            onClick={() => (window.location.hash = '')}>
            <Translate id="action.continue" defaultMessage="Continue" />
          </Button>
        </div>
      </Popup>
    );
  }
}

export default SuccessfulTransactionContainer;

import React, { Component } from 'react';
import { throttle } from 'lodash';

const withHideOnScroll = (delta = 5, interval = 100) => WrappedComponent =>
  class withHideOnScroll extends Component {
    state = {
      previousScrollPosition: __CLIENT__ && window.pageYOffset,
      hideElement: false,
      delta: delta,
    };

    componentDidMount() {
      __CLIENT__ &&
        window.addEventListener(
          'scroll',
          throttle(this._handleScroll, interval)
        );
    }

    componentWillUnmount() {
      __CLIENT__ && window.removeEventListener('scroll', this._handleScroll);
    }

    _handleScroll = () => {
      const { previousScrollPosition } = this.state;

      const currentScrollPosition = window.pageYOffset;
      const scrolledPastDelta =
        Math.abs(currentScrollPosition - previousScrollPosition) > delta;

      if (window.scrollY <= 0) {
        this.setState({
          hideElement: false,
        });
      } else if (scrolledPastDelta) {
        this.setState({
          hideElement: !(previousScrollPosition > currentScrollPosition),
        });
      }

      this.setState({ previousScrollPosition: currentScrollPosition });
    };

    render() {
      return (
        <WrappedComponent
          hideElement={this.state.hideElement}
          {...this.props}
        />
      );
    }
  };

export default withHideOnScroll;

const initialState = {
  messages: {},
};
const translationReducer = (state = initialState, action) => {
  if (action.type === 'CONTENT_FETCH_TRANSLATION_COMPLETE') {
    const messages = action.payload.items.reduce((translationMap, item) => {
      translationMap[item.fields.identifier] = item.fields.translation;

      return translationMap;
    }, {});

    return {
      messages: {
        ...state.messages,
        ...messages,
      },
    };
  }

  return state;
};

export default translationReducer;

import React from 'react';
import { Link } from 'react-router-dom';
import marked from 'marked';
import { Translate } from 'tg-core-components';

const PromotionLink = ({ children, url = '' }) => {
  const isExternal = url.match(/^https?:\/\//);

  if (isExternal)
    return (
      <a href={url} className="button primary">
        {children}
      </a>
    );

  return (
    <Link to={`/promotion/${url}`} className="button primary">
      {children}
    </Link>
  );
};

const Promotion = ({
  promotion: { image, summary, disclaimer, url, title, secondaryCta } = {},
}) => {
  return (
    <div className="Promotion">
      {image && (
        <img src={image.file.url} alt={image.description || image.title} />
      )}
      <div className="promotion-content-wrapper">
        {title && <div className="title">{title}</div>}

        {summary && (
          <div
            className="summary"
            dangerouslySetInnerHTML={{ __html: marked(summary) }}
          />
        )}

        <PromotionLink url={url}>
          {secondaryCta || (
            <Translate id="action.read_more" defaultMessage="Read more" />
          )}
        </PromotionLink>

        {disclaimer && (
          <div
            className="disclaimer"
            dangerouslySetInnerHTML={{
              __html: marked(disclaimer),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Promotion;

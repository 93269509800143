export const loadScript = (src, id) => {
  return new Promise((resolve, reject) => {
    const hasScript = document.querySelector(`#${id}`);

    if (!hasScript) {
      const script = document.createElement('script');

      script.src = src;
      script.id = id;

      script.addEventListener('load', () => {
        resolve();
      });

      script.addEventListener('error', e => {
        reject(e);
      });

      document.body.appendChild(script);
    } else {
      resolve();
    }
  });
};

export const removeScript = id => {
  const script = document.querySelector(`#${id}`);

  if (script) {
    script.remove();
  }
};

const initialState = {
  lastPlayedGames: [],
  error: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LAST_PLAYED_GAMES_START':
      return {
        ...state,
        isLoading: true,
      };

    case 'GET_LAST_PLAYED_GAMES_COMPLETE':
      return {
        ...state,
        isLoading: false,
        lastPlayedGames: action.payload,
      };

    case 'GET_LAST_PLAYED_GAMES_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default: {
      return state;
    }
  }
};

import quickDepositUtils from '@utils/quickDeposit';

export const onSuccess = payload => ({
  type: 'QUICK_DEPOSIT_SUCCESS',
  payload,
});

export const onFailure = payload => ({
  type: 'QUICK_DEPOSIT_FAILURE',
  payload,
});

export const onCancel = payload => ({ type: 'QUICK_DEPOSIT_CANCEL', payload });

export const init = payload => dispatch => {
  dispatch({ type: 'QUICK_DEPOSIT_INIT_START', payload });
  quickDepositUtils
    .init(payload)
    .then(() => dispatch({ type: 'QUICK_DEPOSIT_INIT_COMPLETE', payload }))
    .catch(() => dispatch({ type: 'QUICK_DEPOSIT_INIT_FAILURE', payload }));
};

export const open = payload => ({ type: 'QUICK_DEPOSIT_OPEN', payload });

export default {
  onSuccess,
  onFailure,
  onCancel,
  init,
  open,
};

import { combineEpics, ofType } from 'redux-observable';
import { GTM_EVENTS, gtmDefaultData } from '@utils/google-tag-manager';
import selectRequiredActions from '@selectors/selectRequiredActions';
import { pushGtmEvent } from '@actions/app';
import {
  map,
  switchMap,
  tap,
  ignoreElements,
  filter,
  take,
  delay,
} from 'rxjs/operators';
import { zip } from 'rxjs';

/**
 * Listens for GTM push events
 * Add default data and push event to data layer
 */
const pushGtmEventToDataLayer = (action$, state$) =>
  action$.pipe(
    ofType('PUSH_GTM_EVENT'),
    delay(1000),
    tap(action => {
      try {
        const { event, data = {} } = action.payload;
        window.dataLayer.push({
          event,
          ...gtmDefaultData(state$.value),
          ...data,
        });
      } catch (err) {
        console.error('Error pushing GTM event to data layer', err.message);
      }
    }),
    ignoreElements()
  );

const signUpFailure = action$ =>
  action$.pipe(
    ofType(
      'SIGNUPV2_FAILURE',
      'SIGNUPWITHCODE_FAILURE',
      'SIGNUPWITHTOKEN_FAILURE'
    ),
    map(action =>
      pushGtmEvent({
        event: GTM_EVENTS.SIGNUP_FAILURE,
        data: { 'Sign up errors': action.payload.value.response.data },
      })
    )
  );

const signUpSuccess = (action$, state$) =>
  action$.pipe(
    ofType(
      'SIGNUPV2_COMPLETE',
      'SIGNUPWITHCODE_COMPLETE',
      'SIGNUPWITHTOKEN_COMPLETE',
      'SIGNIN_PNP_COMPLETE'
    ),
    filter(a =>
      a.type === 'SIGNIN_PNP_COMPLETE' ? a.payload.IsSignup === 'true' : true
    ),
    switchMap(action =>
      zip(
        action$.ofType('USER_INFO_COMPLETE'),
        action$.ofType('PAYMENT_STATS_COMPLETE'),
        action$.ofType('GET_WALLET_COMPLETE')
      ).pipe(
        take(1),
        map(() => {
          const { Issues, amount } = action.payload;
          const {
            player: { user },
            exchangeRates: { data: exchangeRates },
          } = state$.value;
          return [
            pushGtmEvent({
              event: GTM_EVENTS.SIGNUP_SUCCESS,
              data: { Issues, amount: Number(amount) },
            }),
            action.payload.IsSignup &&
              pushGtmEvent({
                event: GTM_EVENTS.DEPOSIT_SUCCESS,
                data: {
                  Issues,
                  'Transaction Amount': amount,
                  'Transaction Amount EUR':
                    amount / exchangeRates[user.Currency],
                  'Transaction Currency': user.Currency,
                },
              }),
          ].filter(Boolean);
        })
      )
    )
  );

const signInFailure = action$ =>
  action$.pipe(
    ofType('SIGNINV2_FAILURE', 'SIGNINWITHCODE_FAILURE'),
    map(action =>
      pushGtmEvent({
        event: GTM_EVENTS.SIGNIN_FAILURE,
        data: { 'Sign in errors': action.payload.value.response.data },
      })
    )
  );

const signInSuccess = (action$, state$) =>
  action$
    .ofType(
      'SIGNINV2_COMPLETE',
      'SIGNINWITHCODE_COMPLETE',
      'SIGNIN_PNP_COMPLETE'
    )
    .pipe(
      filter(a =>
        a.type === 'SIGNIN_PNP_COMPLETE' ? a.payload.IsSignup !== 'true' : true
      ),
      switchMap(a =>
        zip(
          action$.ofType('GET_DISPLAY_PAGES_COMPLETE'),
          action$.ofType('USER_INFO_COMPLETE'),
          action$.ofType('PAYMENT_STATS_COMPLETE'),
          action$.ofType('GET_WALLET_COMPLETE')
        ).pipe(
          take(1),
          filter(() => selectRequiredActions(state$.value).length === 0),
          map(() => {
            const {
              player: { user },
              exchangeRates: { data: exchangeRates },
            } = state$.value;
            const { Issues, amount } = a.payload;
            return [
              pushGtmEvent({
                event: GTM_EVENTS.SIGNIN_SUCCESS,
              }),
              a.type === 'SIGNIN_PNP_COMPLETE' &&
                pushGtmEvent({
                  event: GTM_EVENTS.DEPOSIT_SUCCESS,
                  data: {
                    Issues,
                    'Transaction Amount': amount,
                    'Transaction Amount EUR':
                      amount / exchangeRates[user.Currency],
                    'Transaction Currency': user.Currency,
                  },
                }),
            ].filter(Boolean);
          })
        )
      )
    );

/**
 * There are two different cases where a payment will fail.
 * PAYMENT_FAILURE - dispatched when PIQ calls payment-failure.html. Example when a user cancels a payment from within an iframe.
 * PAYMENT_REQUEST_FAILURE - dispatched when the PIQ request fails. Example when a user enters an invalid amount or when a user has gaming limits.
 */
const paymentFailure = (action$, state$) =>
  action$.pipe(
    ofType(
      'PAYMENT_FAILURE',
      'PAYMENT_REQUEST_FAILURE',
      'QUICK_DEPOSIT_FAILURE'
    ),
    map(action => {
      const {
        player: { user },
        exchangeRates: { data: exchangeRates },
      } = state$.value;
      let data;
      if (['PAYMENT_FAILURE', 'QUICK_DEPOSIT_FAILURE'].includes(action.type)) {
        const amount = Number(action.payload?.amount);
        const currency = action.payload?.currency;
        data = {
          'Transaction Id': action.payload?.tx,
          'Transaction Amount': amount,
          'Transaction Amount EUR': amount / exchangeRates[currency],
          'Transaction Currency': currency,
          'Transaction Type': null,
          'Transaction Errors': [],
        };
      }
      if (action.type === 'PAYMENT_REQUEST_FAILURE') {
        const { txRefId, txType, amount, errors } = action.payload;
        data = {
          'Transaction Id': txRefId,
          'Transaction Amount': amount,
          'Transaction Amount EUR': amount / exchangeRates[user.Currency],
          'Transaction Currency': user.Currency,
          'Transaction Type': txType,
          'Transaction Errors': errors,
        };
      }
      return pushGtmEvent({
        event:
          action.type === 'QUICK_DEPOSIT_FAILURE'
            ? GTM_EVENTS.QUICK_DEPOSIT_FAILURE
            : GTM_EVENTS.DEPOSIT_FAILURE,
        data,
      });
    })
  );

/**
 * After PAYMENT_SUCCESS, wait until GET_WALLET_COMPLETE and PAYMENT_STATS_COMPLETE before trigger GTM event
 */
const paymentSuccess = (action$, state$) =>
  action$.pipe(
    ofType(
      'PAYMENT_SUCCESS',
      'PAYMENT_PENDING',
      'PAYMENT_REQUEST_COMPLETE',
      'QUICK_DEPOSIT_SUCCESS'
    ),
    filter(a => a.payload?.method !== 'withdraw'),
    switchMap(action =>
      zip(
        action$.ofType('GET_WALLET_COMPLETE'),
        action$.ofType('PAYMENT_STATS_COMPLETE')
      ).pipe(
        take(1),
        map(() => {
          const {
            player: { user },
            exchangeRates: { data: exchangeRates },
          } = state$.value;
          let data;
          if (
            ['PAYMENT_SUCCESS', 'QUICK_DEPOSIT_SUCCESS'].includes(action.type)
          ) {
            const { amount, currency, tx } = action.payload;
            data = {
              'Transaction Id': tx,
              'Transaction Amount': Number(amount),
              'Transaction Amount EUR':
                Number(amount) / exchangeRates[currency],
              'Transaction Currency': currency,
              'Transaction Type': null,
            };
          }
          if (action.type === 'PAYMENT_REQUEST_COMPLETE') {
            const { txRefId, txType, amount } = action.payload;
            data = {
              'Transaction Id': txRefId,
              'Transaction Amount': amount,
              'Transaction Amount EUR': amount / exchangeRates[user.Currency],
              'Transaction Currency': user.Currency,
              'Transaction Type': txType,
            };
          }
          return [
            pushGtmEvent({
              event:
                action.type === 'QUICK_DEPOSIT_SUCCESS'
                  ? GTM_EVENTS.QUICK_DEPOSIT_SUCCESS
                  : GTM_EVENTS.DEPOSIT_SUCCESS,
              data,
            }),
          ];
        })
      )
    )
  );

const sendQuickDepositInitEvent = action$ =>
  action$.pipe(
    ofType('QUICK_DEPOSIT_OPEN'),
    map(() => pushGtmEvent({ event: GTM_EVENTS.QUICK_DEPOSIT_INIT }))
  );

export default combineEpics(
  pushGtmEventToDataLayer,
  signUpFailure,
  signUpSuccess,
  signInFailure,
  signInSuccess,
  paymentFailure,
  paymentSuccess,
  sendQuickDepositInitEvent
);

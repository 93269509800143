import React from 'react';
import Alert, { createAlertId, createAlertValues } from '../Alert';

const Alerts = ({ alerts, removeAlerts, scrollIntoView = true }) => {
  return (
    alerts &&
    alerts.map((alert, index) => (
      <Alert
        key={index}
        scrollIntoView={scrollIntoView}
        id={createAlertId(alert, alert.level)}
        values={createAlertValues(alert)}
        level={alert.level}
        onDiscard={removeAlerts ? () => removeAlerts(alert.type) : null}
      />
    ))
  );
};

export default Alerts;

import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Form, Field } from 'react-final-form';
import { withConfig } from '@config';
import { Button, Icon } from 'tg-core-components';
import { getContentPreviewSettings } from '@utils/contentPreview';

import './style.css';

@withCookies
@withConfig
class ContentPreviewContainer extends Component {
  state = { formWasChanged: false, showSettings: false };

  onClickApply = settings => {
    const { cookies, config } = this.props;

    Object.entries(settings).forEach(setting => {
      if (setting[1] === 'Not set') delete settings[setting[0]];
    });

    settings.country = settings.country?.toUpperCase();

    cookies.set('content_preview_settings', JSON.stringify(settings), {
      path: '/',
      domain: config.cookieDomain(),
      secure: config.CURRENT_ENV === 'prod',
    });

    window.location.reload();
  };

  toggleSettings = () => {
    this.setState({ showSettings: !this.state.showSettings });
  };

  render() {
    const contentPreviewSettings = getContentPreviewSettings();

    return this.state.showSettings ? (
      <div className="ContentPreviewContainer">
        <Form
          onSubmit={this.onClickApply}
          initialValues={{
            previewMode: contentPreviewSettings.previewMode,
            country: contentPreviewSettings.country,
            jurisdiction: contentPreviewSettings.jurisdiction,
            showTranslations: contentPreviewSettings.showTranslations,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <label className="ToggleButton">Preview Mode</label>
              <Field name="previewMode" component="input" type="checkbox" />

              <label className="ToggleButton">Show Translations</label>
              <Field
                name="showTranslations"
                component="input"
                type="checkbox"
              />

              <label>Jurisdiction</label>
              <Field className="select" name="jurisdiction" component="select">
                <option value="Not set">Not set</option>
                <option value="uk">UK</option>
                <option value="mga">MGA</option>
                <option value="dga">DGA</option>
                <option value="sga">SGA</option>
                <option value="curacao">CURACAO</option>
              </Field>

              <label>Country</label>
              <Field
                className="country-input"
                name="country"
                maxLength="2"
                size="2"
                component="input"></Field>

              <Button type="submit" className="button secondary small">
                APPLY
              </Button>

              <Button
                className="button tertiary small"
                onClick={this.toggleSettings}>
                CLOSE
              </Button>
            </form>
          )}
        />
      </div>
    ) : (
      <div className="SettingsTab" onClick={this.toggleSettings}>
        <Icon icon={`cogs`} />
      </div>
    );
  }
}

export default ContentPreviewContainer;

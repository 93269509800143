import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getKyc, upload } from 'tg-core-redux/lib/modules/kyc/action';
import marked from 'marked';
import { VerifyAccordionWidget, Translate } from 'tg-core-components';
import OffCanvasLayout from '@components/OffCanvasLayout';
import withRouteData, { Loader } from '@utils/withRouteData';
import { loadContents } from '@actions/content';

@withRouteData
@connect(
  state => ({
    sessionId: state.player.sessionId,
    pages: state.content.pages.data,
    sets: state.content.sets.data,
    kyc: state.kyc.data,
  }),
  {
    getKyc,
    upload,
  }
)
class VerifyContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard/verify'],
    },
    {
      name: 'set',
      identifiers: ['kyc-types', 'verify-profile-instruction'],
    },
  ]);

  componentDidMount() {
    const { getKyc, sessionId } = this.props;

    getKyc(sessionId);
  }

  onSubmit = data => {
    const { sessionId } = this.props;
    const reader = new FileReader();

    reader.onload = e => {
      this.props
        .upload(sessionId, {
          Type: data.Type,
          Image: e.target.result.split(',')[1],
        })
        .then(() => data.doneCallback && data.doneCallback());
    };

    reader.readAsDataURL(data.Image);
  };

  render() {
    const { pages, sets, kyc, match } = this.props;
    const page = pages?.find(p => p.url === '#dashboard/verify');
    const types = sets?.find(s => s.identifier === 'kyc-types');
    // In my opinion we should either send the header like this, or fix it in tg-core to get the same structure as the other accordions.
    const items = types?.items.map(type => ({
      ...type,
      expanded:
        match?.params.type === (type.config && type.config.Type.toLowerCase()),
      header: (
        <div className="title-text">
          <Translate id={`label.${type.identifier}`} />
        </div>
      ),
      content: marked(type.content),
    }));

    return (
      <OffCanvasLayout className="VerifyContainer" backUrl="#dashboard">
        <Loader isLoading={!page}>
          {page?.content && (
            <header
              dangerouslySetInnerHTML={{ __html: marked(page.content) }}
            />
          )}

          <VerifyAccordionWidget
            onSubmit={this.onSubmit}
            items={items}
            data={kyc}
          />
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default VerifyContainer;

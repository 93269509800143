import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import bingoActions from 'tg-core-redux/lib/modules/bingo/action';
import walletActions from 'tg-core-redux/lib/modules/wallet/action';
import Loader from '@components/Loader';
import { Helmet } from 'react-helmet';
import { useUrls } from '@utils/urls';

const End2End = ({ sessionId, history, roomId, config }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [src, setSrc] = useState(null);
  const [height, setHeight] = useState(null);
  const { depositString } = useUrls();
  const dispatch = useDispatch();

  const getLaunchUrl = useCallback(() => {
    if (!sessionId) return;

    setIsLoading(true);

    dispatch(
      bingoActions.getEnd2EndLaunchUrl(sessionId, {
        EnableChat: true,
        BingoRoomId: roomId,
      })
    )
      .then(res => {
        setSrc(res.data?.Url);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [dispatch, roomId, sessionId]);

  const receiveMessage = event => {
    if (!event.origin.includes('end2end')) return;

    switch (event.data.event) {
      // Invalid Token, refresh token.
      case 5:
        return getLaunchUrl();

      // The user is inside the lobby and has closed the game.
      // User is in the lobby and has clicked in the logo to go the home page.
      case 3:
      case 6:
        return history.push('/casino');

      // User has opened the cashier or the deposit.
      case 4:
        return history.push(depositString());

      // User balance has been updated.
      case 8:
        return Promise.all([
          dispatch(walletActions.getWallet(sessionId)),
          dispatch(walletActions.getWallets(sessionId)),
        ]);
    }
  };

  const resize = () => {
    // How can we do this better?
    const offsetTop = window?.getComputedStyle?.(
      document.querySelector?.('.main-content-container')
    )?.marginTop;
    setHeight(window.innerHeight - offsetTop);
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    window.addEventListener('resize', resize, false);
    resize();

    return () => {
      window.removeEventListener('message', receiveMessage, false);
      window.removeEventListener('resize', resize, false);
    };
  });

  useEffect(() => {
    getLaunchUrl();
  }, [getLaunchUrl]);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover, maximum-scale=1.0, user-scalable=0"
        />
      </Helmet>
      {isLoading && <Loader />}
      <iframe src={src} style={{ height }} />
    </>
  );
};

export default End2End;

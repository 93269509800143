import React, { Component } from 'react';
import { loadContents } from '@actions/content';
import withRouteData, { Loader } from '@utils/withRouteData';
import { connect } from 'react-redux';
import OffCanvasLayout from '@components/OffCanvasLayout';
import { Select, Button, Translate } from 'tg-core-components';
import marked from 'marked';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { injectIntl } from 'react-intl';
import {
  getProperties,
  setProperties,
} from 'tg-core-redux/lib/modules/player_properties/action';
import { getTranslation } from './helpers';

@injectIntl
@withRouteData
@connect(
  state => ({
    sportsbookPage: state.content.pages.data?.find(
      p => p.url === '#dashboard/sportsbook'
    ),
    oddsTypeProperty: state.playerProperties.data?.find(
      pp => pp.Name === 'oddsFormat'
    ),
    layoutProperty: state.playerProperties.data?.find(
      pp => pp.Name === 'sportsbook-layout'
    ),
    sessionId: state.player.sessionId,
  }),
  {
    getProperties,
    setProperties,
  }
)
class SportsbookSettings extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard/sportsbook'],
    },
  ]);

  state = {
    oddsType: 'decimal',
    layout: 'classic',
    isLoading: false,
  };

  componentDidMount() {
    const {
      getProperties,
      sessionId,
      oddsTypeProperty,
      layoutProperty,
    } = this.props;
    const oddsTypePropertyValue = oddsTypeProperty?.Value;
    const layoutPropertyValue = layoutProperty?.Value;

    getProperties(sessionId);
    if (
      typeof oddsTypePropertyValue !== 'string' &&
      typeof layoutPropertyValue !== 'string'
    )
      return;

    this.setState({
      layout: layoutPropertyValue,
      oddsType: oddsTypePropertyValue,
    });
  }

  componentDidUpdate(prevProps) {
    const { oddsTypeProperty, layoutProperty } = this.props;

    const oddsTypePropertyValue = oddsTypeProperty?.Value;
    const layoutPropertyValue = layoutProperty?.Value;

    if (
      (prevProps.oddsTypeProperty?.Value !== oddsTypePropertyValue &&
        oddsTypePropertyValue !== this.state.oddsType) ||
      (prevProps.layoutProperty?.Value !== layoutPropertyValue &&
        layoutPropertyValue !== this.state.layout)
    ) {
      this.setState({
        layout: layoutPropertyValue,
        oddsType: oddsTypePropertyValue,
      });
    }
  }

  handleSave = async () => {
    const { sportsbookPage, setProperties, sessionId } = this.props;
    const { oddsType, layout } = this.state;

    const layoutContent = sportsbookPage.sections.find(
      s => s.identifier === 'sportsbook-layout'
    );

    this.setState({ isLoading: true });

    const data = {
      Properties: [
        {
          Name: 'oddsFormat',
          Type: 'Text',
          Value: oddsType,
        },
      ],
    };

    if (layoutContent) {
      data.Properties.push({
        Name: 'sportsbook-layout',
        Type: 'Text',
        Value: layout,
      });
    }

    await setProperties(sessionId, data);
    this.setState({ isLoading: false });
  };

  handleChange = (e, v) => {
    this.setState({ [e.target.name]: v });
  };

  isSameValues = (state, odds, layout) => {
    if (odds && layout) {
      return state.oddsType === odds && state.layout === layout;
    }

    if (odds) {
      return state.oddsType === odds;
    }

    return false;
  };

  render() {
    const {
      sportsbookPage,
      intl,
      oddsTypeProperty,
      layoutProperty,
    } = this.props;

    const isSameValue = this.isSameValues(
      this.state,
      oddsTypeProperty?.Value,
      layoutProperty?.Value
    );

    const oddsTypeContent = sportsbookPage?.sections?.find(
      s => s.identifier === 'sportsbook-odds-type'
    );
    const layoutContent = sportsbookPage?.sections?.find(
      s => s.identifier === 'sportsbook-layout'
    );

    return (
      <OffCanvasLayout
        className="SportsbookSettingsContainer"
        backUrl="#dashboard">
        <Loader isLoading={!sportsbookPage}>
          <header
            dangerouslySetInnerHTML={{
              __html: marked(sportsbookPage?.content || ''),
            }}
          />
          <div className="SportsbookSettingsContainer__content">
            <div className="SportsbookSettingsContainer__content__oddstype">
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(oddsTypeContent?.content || ''),
                }}
              />
              <Select
                className="layout-item-12"
                name="oddsType"
                onChange={this.handleChange}
                title={translate(
                  {
                    id: 'title.oddType',
                    defaultMessage: 'Odds Type',
                  },
                  intl
                )}
                value={this.state.oddsType}>
                {oddsTypeContent?.config?.map(v => (
                  <Select.Option key={v} value={v.toLowerCase()}>
                    {getTranslation(intl, v.toLowerCase())}
                  </Select.Option>
                ))}
              </Select>
            </div>

            {layoutContent && (
              <div className="SportsbookSettingsContainer__content__layout">
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(layoutContent?.content || ''),
                  }}
                />
                <Select
                  className="layout-item-12"
                  name="layout"
                  onChange={this.handleChange}
                  title={translate(
                    {
                      id: 'title.layout',
                      defaultMessage: 'layout',
                    },
                    intl
                  )}
                  value={this.state.layout}>
                  {layoutContent?.config?.map(l => (
                    <Select.Option key={l} value={l.toLowerCase()}>
                      {getTranslation(intl, l.toLowerCase())}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            <Button
              className="button primary wide"
              onClick={this.handleSave}
              disabled={isSameValue}
              isLoading={this.state.isLoading}>
              <Translate id="label.save" defaultMessage="Save" />
            </Button>
          </div>
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default SportsbookSettings;

import { tournamentOptIn } from 'tg-core-redux/lib/modules/player/action';
import { triggerBonusByPromoCode } from 'tg-core-redux/lib/modules/bonus/action';
import { signOut } from 'tg-core-redux/lib/modules/player/action';
import { getTableGamesSummary } from 'tg-core-redux/lib/modules/responsible_gaming/action';
import getByPath from 'lodash/get';
import identity from 'lodash/identity';
import { getConfig } from '@config';
import { addPendingAction } from '@actions/player';
import { getUrls } from './urls';
import { startChat } from './chat';

const init = () => {
  if (!__CLIENT__) return;
  hashChangeFn();
  window.addEventListener('hashchange', hashChangeFn);
};

const terminate = () => {
  if (!__CLIENT__) return;
  window.removeEventListener('hashchange', hashChangeFn);
};

const hashChangeFn = () => {
  const state = window.reduxStore.getState();
  const dispatch = window.reduxStore.dispatch;

  const currency = getByPath(state, 'player.user.Currency', null);
  const sessionId = getByPath(state, 'player.sessionId', null);
  const userId = getByPath(state, 'player.user.Id', null);
  const jurisdiction = getByPath(state, 'app.jurisdiction', null);
  const ipCountry = getByPath(state, 'app.ipCountry', null);
  const isAuthenticated = getByPath(state, 'player.isAuthenticated', false);
  const { signInString } = getUrls(jurisdiction, ipCountry);

  const hashFragments = window.location.hash.slice(1).split(',');
  const nextHash = hashFragments
    .filter(hash => !hash.includes('action/'))
    .join(',');

  window.routerHistory.push({
    search: window.location.search,
    hash: `#${nextHash}`,
  });

  const redirectToAuth = () => {
    if (isAuthenticated) return false;

    window.routerHistory.push(signInString());

    return true;
  };

  const tasks = hashFragments.map(hashAction => {
    const [isAction, action, ...params] = hashAction.split('/');
    if (isAction !== 'action') return identity;

    switch (action) {
      case 'leaderboard-opt-in': {
        if (redirectToAuth())
          return () =>
            dispatch(addPendingAction(`leaderboard-opt-in/${params[0]}`));

        return () => {
          dispatch(tournamentOptIn(userId, params[0]));
        };
      }
      case 'bonus-trigger': {
        if (redirectToAuth())
          return () => dispatch(addPendingAction(`bonus-trigger/${params[0]}`));

        return () => {
          dispatch(triggerBonusByPromoCode(sessionId, params[0]));
        };
      }
      case 'sign-out': {
        return async () => {
          if (jurisdiction?.toLowerCase() === 'es')
            await dispatch(getTableGamesSummary(sessionId));
          window.routerHistory.location.state.currency = currency;
          await dispatch(signOut(sessionId));
        };
      }
      case 'redirect': {
        return () => {
          window.routerHistory.push(
            `/${decodeURIComponent(params[0])}#${nextHash}`
          );
        };
      }
      case 'open-chat': {
        return () => startChat();
      }
      default:
        return identity;
    }
  });

  tasks.reduce(async (previousTask, currTask) => {
    await previousTask;
    return currTask();
  }, Promise.resolve());
};

export default { init, terminate };

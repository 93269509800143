import React, { useEffect, useState } from 'react';
import { getConfig } from '@utils/config';
import { useDispatch, useSelector } from 'react-redux';
import InstantCashier, { STEPS } from '@components/InstantCashier';
import {
  getUserAvailablePaymentMethods,
  getUserOnGoingTransactions,
} from 'tg-core-redux/lib/modules/devcode/action';
import paymentProviders from 'tg-core-devcode/lib/deposit';
import { setWallet } from 'tg-core-redux/lib/modules/wallet/action';
import withRouteData from '@utils/withRouteData';
import queryString from 'query-string';
import BasePage from '@pages/BasePage';
import bonusActions from 'tg-core-redux/lib/modules/bonus/action';

const InstantDepositContainer = ({ location }) => {
  const {
    userId,
    sessionId,
    jurisdiction,
    activeCurrency,
    currency,
    wallets,
    locale,
    exchangeRates,
    transactions,
    promotions,
    bonusOffer,
    currencies,
    cmsCurrencies,
    quickAmounts,
    availableDepositMethod,
  } = useSelector(state => ({
    userId: state.player.user?.Id,
    sessionId: state.player.sessionId,
    jurisdiction: state.app.jurisdiction,
    activeCurrency: state.player.activeCurrency,
    currency: state.player.user?.Currency,
    wallets: state.wallets.data,
    locale: state.app.locale,
    exchangeRates: state.exchangeRates.data,
    transactions: state.devcode?.pendingDepositTransaction?.data?.transactions,
    promotions: state.content.promotions.data,
    bonusOffer: state.bonusOffer,
    currencies: state.currencies.currencies,
    cmsCurrencies: state.content.config.data?.find(
      c => c.key === 'instant-deposit-currencies'
    )?.value,
    quickAmounts: state.content.config.data?.find(
      c => c.key === 'quick-amounts'
    ),
    availableDepositMethod: state.devcode.availableDepositMethod,
  }));

  const content = useSelector(state => ({
    [STEPS.AMOUNT]: state.content.sections?.data?.find(
      s => s.identifier === 'instant-deposit-step-amount'
    ),
    [STEPS.PAYMENT]: state.content.sections?.data?.find(
      s => s.identifier === 'instant-deposit-step-payment'
    ),
    [STEPS.RECEIPT + 'pending']: state.content.sections?.data?.find(
      s => s.identifier === 'instant-deposit-step-receipt-pending'
    ),
    [STEPS.RECEIPT + 'success']: state.content.sections?.data?.find(
      s => s.identifier === 'instant-deposit-step-receipt-success'
    ),
    [STEPS.RECEIPT + 'failure']: state.content.sections?.data?.find(
      s => s.identifier === 'instant-deposit-step-receipt-failure'
    ),
    [STEPS.RECEIPT]: state.content.sections?.data?.find(
      s => s.identifier === 'instant-deposit-step-receipt'
    ),
    [STEPS.BONUS]: state.content.sections?.data?.find(
      s => s.identifier === 'instant-deposit-step-bonus'
    ),
    [STEPS.ERROR]: state.content.sections?.data?.find(
      s => s.identifier === 'instant-deposit-step-error'
    ),
  }));

  const merchantId = getConfig(jurisdiction).MERCHANT_ID;
  const bonusProvider = getConfig(jurisdiction).bonusProvider;
  const dispatch = useDispatch();

  const [lastCurrency, setLastCurrency] = useState(null);
  const [paymentProviderDetails, setPaymentProviderDetails] = useState();
  const [isFetching, setIsFetching] = useState();
  const [bonuses, setBonuses] = useState([]);
  const [txId, setTxId] = useState(null);

  const psp = 'cryptocurrency';
  const paymentProvider = paymentProviders[psp];

  const qs = queryString.parse(location.search);

  const onMessage = e => {
    if (e?.data?.event === 'instantCashierCallback') setTxId(e?.data?.txId);
  };

  /**
   * Listen for messages from iframe or if in an iframe, post to parent
   */
  useEffect(() => {
    if (window.top !== window) {
      window.top.postMessage(
        {
          event: 'instantCashierCallback',
          txId: txId,
        },
        '*'
      );
      return;
    }

    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [txId]);

  /**
   * Fetch getUserAvailablePaymentMethods if missing
   */
  useEffect(() => {
    if (
      userId &&
      sessionId &&
      (currency != lastCurrency ||
        (!availableDepositMethod.data && !isFetching))
    ) {
      setIsFetching(true);
      setLastCurrency(currency);
      dispatch(
        getUserAvailablePaymentMethods({
          merchantId,
          userId,
          sessionId,
          method: 'Deposit',
          channelId: 'instant-deposit',
        })
      );
    } else if (availableDepositMethod.data) {
      setPaymentProviderDetails(
        availableDepositMethod.data.methods.find(
          i => i.providerType.toLowerCase() === psp
        )
      );
    }
  }, [currency, availableDepositMethod, psp, setPaymentProviderDetails]);

  useEffect(() => {
    if (qs.txId && qs.txId != txId) setTxId(qs.txId);
  }, [qs.txId]);

  useEffect(() => {
    if (userId && sessionId && txId) {
      dispatch(
        getUserOnGoingTransactions({
          merchantId,
          userId,
          sessionId,
          transactionId: txId,
        })
      );
    }
  }, [txId]);

  useEffect(() => {
    dispatch(bonusActions.getOffers(sessionId, bonusProvider));
  }, [sessionId, bonusProvider]);

  useEffect(() => {
    const _bonuses =
      bonusOffer &&
      bonusOffer.data
        .reduce((acc, b) => {
          if (b.Provider === 'fasttrack') {
            return b.promotion ? [...acc, b] : acc;
          }

          if (!b.RewardGroups) return;

          const items = b.RewardGroups.map(r => ({
            ...r,
            promotion:
              promotions && promotions.find(c => c.bonusCode === r.PromoCode),
          }));

          const result = items.filter(i => i.promotion);

          return [...acc, ...result];
        }, [])
        .filter(bonus => bonus.promotion)
        .map(bonus => bonus.promotion);

    setBonuses(_bonuses || []);
  }, [bonusOffer, promotions]);

  return (
    <BasePage
      hideHeader={true}
      hideStickyFooter={true}
      className="InstantCashierContainer">
      <InstantCashier
        backUrl={qs.ref_url || '/casino'}
        onChangeCurrency={c => dispatch(setWallet(sessionId, c))}
        locale={locale}
        bonuses={bonuses}
        currencies={cmsCurrencies || currencies}
        paymentProvider={paymentProvider}
        paymentProviderDetails={paymentProviderDetails}
        userId={userId}
        sessionId={sessionId}
        jurisdiction={jurisdiction}
        displayCurrency={activeCurrency}
        currency={currency}
        wallets={wallets}
        merchantId={merchantId}
        exchangeRates={exchangeRates}
        content={content}
        txId={txId}
        transactions={transactions || []}
        quickAmounts={quickAmounts}
      />
    </BasePage>
  );
};

export default withRouteData(InstantDepositContainer);

// eslint-disable-next-line
export default (CURRENT_ENV, country) => {
  const isPnp = true;
  return {
    config: 'common',
    defaultJurisdiction: 'mga',
    CURRENT_ENV,
    type: isPnp ? 'pnp' : 'account',
    indexContainer: 'IndexContainerPnp',
    skinId: CURRENT_ENV === 'prod' ? 50 : 30,
    defaultLocale: 'en-US',
    defaultTitle:
      'Sportsbook, Casino, Live Casino and Virtual Sports at Betive',
    MERCHANT_ID: CURRENT_ENV === 'prod' ? 100014042 : 100014983,
    content: {
      space: 'bVkxs6EsB9vT',
      host: 'https://delivery2.objectic.io',
      access_token:
        CURRENT_ENV === 'dev'
          ? '83|wepgagddzARJY5LuGAXh7BwCOD1s3lhRIegqse40'
          : '122|IqaDrpIkAYajTfJH0Hy60wdqLAHXk4frh3VSkk8O',
      preview_host: 'https://preview.objectic.io',
      preview_access_token:
        CURRENT_ENV === 'dev'
          ? '83|wepgagddzARJY5LuGAXh7BwCOD1s3lhRIegqse40'
          : '122|IqaDrpIkAYajTfJH0Hy60wdqLAHXk4frh3VSkk8O',
      environment: CURRENT_ENV === 'dev' ? 'stage' : 'master',
    },
    axiosCache: {
      duration: 600, // 10 minutes
      whitelist: [
        'cdn.contentful.com',
        'delivery2.objectic.io',
        '/event-feed/jackpots',
      ],
    },
    jurisdictions: [
      {
        key: 'mga',
        name: 'betive.com',
        domain: CURRENT_ENV === 'prod' ? 'www.betive.com' : null,
      },
      {
        key: 'sga',
        name: 'betive.com (se)',
        domain: CURRENT_ENV === 'prod' ? 'www.betive.com' : null,
      },
    ],
    tgplay: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://play.betive.com'
          : 'https://play-togethergaming-new-config-stage.azurewebsites.net',
      skin: 'betive_pz',
      standalone: true,
    },
    aleacc: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://api.betive.com'
          : 'https://api-stage-betive.codebet.dev',
    },
    devcode: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://api.paymentiq.io/paymentiq/api'
          : 'https://test-bo.paymentiq.io/paymentiq/api',
      oauth_host:
        CURRENT_ENV === 'prod'
          ? 'https://backoffice.paymentiq.io/paymentiq/oauth'
          : 'https://test-api.paymentiq.io/paymentiq/oauth',
      oauth_provider: 'trustly',
      oauth_clientid:
        CURRENT_ENV === 'prod'
          ? '0c855a349352449b86cbaf0c35f4dc87'
          : 'b41ad0b313d0425ea13c354fb656a93d',
      oauth_redirecturl:
        CURRENT_ENV === 'prod'
          ? 'https://www.betive.com/nemid.html'
          : 'https://stage-betive.codebet.dev/nemid.html',
    },
    springBuilder: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://sports.betive.com'
          : 'https://stage-sports.betive.com',
    },
    authenticgaming: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://game.itsreal.live//game/loader.aspx?'
          : 'http://game.authenticstage.live/game/loader.aspx?',
      operatorId: 38,
    },
    freshChat: {
      token: "c44b51b7-1e7b-4df7-8009-72478339d0df",
      host: "https://bethard.freshchat.com",
      widgetUuid: "6c412baf-7f02-4f60-8d62-fa5d6314107d",
      brand: 'betive',
      appName: 'Betive',
      appImage:
        CURRENT_ENV === 'prod'
          ? 'https://www.betive.com/skin/images/chatavatar.png'
          : 'https://www-betive-feature.azurewebsites.net/skin/images/chatavatar.png',
      siteId: 'betive',
      tags: ['welcomebetive', 'issuebetive'],
      config: {
        headerProperty: {
        },
        cssNames: {
          widget: 'fc_frame',
          open: 'fc_open',
        },
      },
    },
    quickDeposit: {
      enabled: false,
      host: 'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.9/tg-quickdeposit.bundle.js',
      successUrl:
        'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.9/success.html',
      failureUrl:
        'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.9/failure.html',
      cancelUrl:
        'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.9/cancel.html',
    },
    /**
     * Configure which chat to use.
     * @description
     * Current available chat options are FreshChat (freshchat), LiveAgent (ladesk), LiveChat (livechat).
     */
    chat: {
      provider: 'freshchat',
    },
    /**
     * Visibility configuration for user registration.
     * @description
     * Current available elements for the array: ['email', 'phone']
     */
    userFields: ['email'],
    /**
     * Validation rules used for the email field in the details step.
     * @description
     * Current available rules: "validationPattern", "transformationPattern", "required"
     */
    emailValidationRules: {
      validationPattern:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      transformationPattern: null,
      required: true,
    },
    /**
     * Validation rules used for the phone field in the details step.
     * @description
     * Current available rules: "validationPattern", "transformationPattern", "required"
     */
    phoneValidationRules: {
      validationPattern: /^[0-9]{9,}$/,
      transformationPattern: /^0+/,
      required: true,
    },
    /**
     * How the index page features should be displayed
     * @description
     * Current available options are 'row', 'slider', 'textSlider' and 'iconSlider'
     * Default :
     * desktop: 'row',
     * mobile: 'slider'
     */
    indexFeatures: {
      desktop: 'row',
      mobile: 'slider',
    },

    /**
     * If the sign in/fetch balance button should be displayed in header or base page.
     * @description
     * Default is true which means it should be in header.
     * showFetchBalanceIndexHeader: true
     */
    showFetchBalanceIndexHeader: isPnp ? true : false,

    stickyFooterOnIndex: false,

    globalNavContainer: false,

    products: ['casino', 'sportsbook', 'payments'],

    /**
     * If pre-game view should be activated on signed in and/or signed out.
     * @description
     * Available options are true and false for both.
     */
    preGameView: {
      signedIn: false,
      signedOut: false,
    },

    casino: {
      layout: 'slider',
      casinoCategoryMenuStyle: 'categories',
      sliderSettings: {
        isCategory: {
          1200: 8,
          1400: 10,
          2400: 12,
          default: 16,
        },
      },
    },

    /**
     * Options for communication consent checkbox on details step.
     */
    communicationConsent: {
      enabled: true,
      values: [
        'BonusEmails',
        'BonusSMSs',
        'NewsEmails',
        'NewsSMSs',
        'Phone',
        'Casino',
        'Sportsbook',
      ],
    },

    installApp: true,

    hideOnScroll: {
      footer: true,
    },

    disablePreGameView: true,

    showStickyFooter: false,
    openSupportWidget: {
      header: false,
      sidebar: true,
    },

    useVisitedCookie: true,
    visitedCookieName: 'tg-page-visited',
    visitedCookieMaxAge: 365 * 24 * 60 * 60 * 1000,
    gameHistoryName: 'tg-game-history',
    btagCookie: 'tg-btag',
    defaultCurrency: 'EUR',

    GTM_ID: 'GTM-53TK73T',

    redirects: [
      {
        source: /^\/unsub/,
        target: () =>
          isPnp
            ? '/fetch-balance?ref_url=dashboard/subscriptions'
            : '/?ref_url=dashboard/subscriptions#sign-in',
      },
      {
        source: '/deposit',
        target: () => (isPnp ? '/start-playing' : '/#deposit'),
      },
      {
        source: '/withdraw',
        target: () => '/#withdraw',
      },
      {
        source: /^\/(sign-in|login)$/,
        target: () => (isPnp ? '/fetch-balance' : '/#sign-in'),
      },
      {
        source: /^\/(sign-up|start-playing)$/,
        target: () => (isPnp ? '/start-playing' : '/#sign-up'),
      },
      {
        source: '/dashboard',
        target: () => '/#dashboard',
      },
      {
        source: /^\/(dashboard\/activate|activate)$/,
        target: () => '/#dashboard/activate',
      },
      {
        source: '/dashboard/responsible-gaming',
        target: () => '/#dashboard/responsible-gaming',
      },
      {
        source: '/dashboard/subscriptions',
        target: () => '/#dashboard/subscriptions',
      },
      {
        source: '/dashboard/verify',
        target: () => '/#dashboard/verify',
      },
      {
        source: '/dashboard/bonus',
        target: () => '/#dashboard/bonus',
      },
      {
        source: '/dashboard/history',
        target: () => '/#dashboard/history',
      },
      {
        source: '/dashboard/messages',
        target: () => '/#dashboard/messages',
      },
      {
        source: '/dashboard/edit-profile',
        target: () => '/#dashboard/edit-profile',
      },
      {
        source: '/dashboard/change-password',
        target: () => '/#dashboard/change-password',
      },
      {
        source: '/sbtech/sbtech.js',
        target: () => '/sbtech.js',
      },
    ],
    /**
     * @param {Request} [req] Provide the request object if this function is being used on the server
     * @returns {(string|boolean)}
     */
    cookieDomain: (req) => {
      if (!__CLIENT__ && !req) return false;
      return (__CLIENT__ ? window.location.href : req.get('host')).includes(
        'betive.com'
      )
        ? '.betive.com'
        : false;
    },
  };
};

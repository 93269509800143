import React from 'react';
import cn from 'classnames';
import { Translate } from 'tg-core-components';
import { Icon } from 'tg-core-components';
import {
  hasCapitalLetter,
  hasLowercaseLetter,
  hasDigit,
  hasSpecialCharacter,
  minLength,
  doesNotIncludeStrings,
  getPasswordTestStrings,
} from './validation';

// import './style.css';

const PasswordFeedback = ({
  Email = '',
  Password = '',
  FirstName = '',
  LastName = '',
  LastName2 = '',
  BirthDate = '',
  jurisdiction = '',
}) => {
  const testStrings = getPasswordTestStrings({
    FirstName,
    LastName,
    LastName2,
    BirthDate,
  });

  return (
    <div className="PasswordFeedback">
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': hasCapitalLetter(Password),
        })}>
        <Icon icon="check" />{' '}
        <Translate
          id="label.password-feedback.capital"
          defaultMessage="Capital letter"
        />
      </div>
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': hasLowercaseLetter(Password),
        })}>
        <Icon icon="check" />{' '}
        <Translate
          id="label.password-feedback.lowercase"
          defaultMessage="Lowercase letter"
        />
      </div>
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': hasDigit(Password),
        })}>
        <Icon icon="check" />{' '}
        <Translate id="label.password-feedback.digit" defaultMessage="Digit" />
      </div>
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': hasSpecialCharacter(Password),
        })}>
        <Icon icon="check" />{' '}
        <Translate
          id="label.password-feedback.special"
          defaultMessage="Special character"
        />
      </div>
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': minLength(8)(Password),
        })}>
        <Icon icon="check" />{' '}
        <Translate
          id="label.password-feedback.minimum-length"
          defaultMessage="Minimum length"
        />
      </div>
      <div
        className={cn('PasswordFeedback__rule', {
          'PasswordFeedback__rule--valid': doesNotIncludeStrings([Email])(
            Password
          ),
        })}>
        <Icon icon="check" />{' '}
        <Translate
          id="label.password-feedback.does-not-include-email"
          defaultMessage="Does not include email"
        />
      </div>
      {jurisdiction === 'es' && (
        <div
          className={cn('PasswordFeedback__rule', {
            'PasswordFeedback__rule--valid': doesNotIncludeStrings(testStrings)(
              Password
            ),
          })}>
          <Icon icon="check" />{' '}
          <Translate
            id="label.password-feedback.does-not-include-personal-information"
            defaultMessage="Does not include personal information"
          />
        </div>
      )}
    </div>
  );
};

export default PasswordFeedback;

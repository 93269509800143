import React, { useRef, useEffect } from 'react';
import marked from 'marked';
import { FormattedRelativeTime } from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';
import { Translate } from 'tg-core-components';

import './style.css';

const Calendar = ({ page, onClickDay, calendarEventDays }) => {
  const todayElement = useRef(null);

  useEffect(() => {
    todayElement?.current?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'center',
    });
  }, [todayElement]);

  const today = new Date(new Date().toISOString().split('T')[0]);

  const isToday = day => {
    const dayDate = new Date(day.date);
    return (
      dayDate.getDate() === today.getDate() &&
      dayDate.getMonth() === today.getMonth() &&
      dayDate.getFullYear() === today.getFullYear()
    );
  };
  const isBeforeToday = day => new Date(day.date) < today;
  const isAfterToday = day => new Date(day.date) > today;
  const getDayState = day => {
    if (isToday(day)) {
      return 'isToday';
    } else if (isBeforeToday(day)) {
      return 'isBeforeToday';
    } else if (isAfterToday(day)) {
      return 'isAfterToday';
    }
  };

  const getImage = day => {
    let bg = day.calendarImageActive?.file.url;

    if (isAfterToday(day) && day.calendarImageInactive?.file.url)
      bg = day.calendarImageInactive?.file.url;

    if (isBeforeToday(day) && day.calendarImageOpened?.file.url)
      bg = day.calendarImageOpened?.file.url;

    return bg;
  };

  const getBackgroundImage = day => {
    let bg = day.calendarBackgroundImageActive?.file.url;

    if (isAfterToday(day) && day.calendarBackgroundImageInactive?.file.url)
      bg = day.calendarBackgroundImageInactive?.file.url;

    if (isBeforeToday(day) && day.calendarBackgroundImageOpened?.file.url)
      bg = day.calendarBackgroundImageOpened?.file.url;

    return bg;
  };

  return (
    <div className="Calendar">
      {page && page.content && (
        <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
      )}

      {calendarEventDays?.items && (
        <div className="calendar-days">
          {calendarEventDays.items.map((day, i) => (
            <div
              ref={isToday(day) ? todayElement : null}
              key={day?.identifier}
              className={`calendar-day ${getDayState(day)}`}
              style={{ backgroundImage: `url(${getBackgroundImage(day)})` }}
              onClick={() =>
                onClickDay({
                  a: console.log(day),
                  day,
                  isBeforeToday: isBeforeToday(day),
                  isToday: isToday(day),
                  isAfterToday: isAfterToday(day),
                })
              }>
              <div className="index">{i + 1}</div>
              <header className="header">
                {isToday(day) ? (
                  <Translate id="label.today" defaultMessage="Today" />
                ) : (
                  <FormattedRelativeTime {...selectUnit(new Date(day.date))} />
                )}
              </header>

              {getImage(day) && <img src={getImage(day)} alt="day" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Calendar;

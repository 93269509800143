import { GTM_EVENTS } from './google-tag-manager';
import { pushGtmEvent } from '@actions/app';
import { experimentWin } from '@actions/experiment';
import { getConfig } from '@config';
import intersection from 'lodash/intersection';

export const experimentOptions = (config, isBootstrapping) => {
  if (isBootstrapping) return {};
  return {
    onFetch: e => {
      return Promise.resolve(
        config.experiments.find(x => x.name === e).variations
      );
    },
    onPlay: (e, v, d) =>
      window.reduxStore.dispatch(
        pushGtmEvent({
          event: GTM_EVENTS.EXPERIMENT_PLAY,
          data: { Experiment: e, Variant: v, Data: d },
        })
      ),
    onWin: (e, v, d) => {
      window.reduxStore.dispatch(
        experimentWin({
          experiment: e,
          variant: v,
        })
      );

      window.reduxStore.dispatch(
        pushGtmEvent({
          event: GTM_EVENTS.EXPERIMENT_WIN,
          data: { Experiment: e, Variant: v, Data: d },
        })
      );
    },
  };
};

export const formatExperimentTag = (experiment, variant, status) => {
  if (!experiment || !variant || !status) return '';

  return `${experiment}-${variant}-${status}`;
};

export const getCurrentExperimentTag = (
  tags,
  experiment,
  status,
  jurisdiction,
  ipCountry
) => {
  const experimentVariants = getConfig(jurisdiction, ipCountry)
    .experiments.find(e => e.name === experiment)
    .variations.map(v => v.name);

  return intersection(
    tags,
    experimentVariants.map(v => formatExperimentTag(experiment, v, status))
  )[0];
};

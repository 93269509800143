import React from 'react';
import { Translate } from 'tg-core-components';
import { startChat } from '@utils/chat';
import Button from 'tg-core-components/lib/components/Button';

import './style.css';

const Issues = ({
  /* Props from parent */
  errors,

  /* Props from selector */
  jurisdiction,
}) => {
  return (
    <div>
      {errors.Issues && (
        <ul>
          {errors.Issues.map(i => (
            <Translate key={i} tagName="li" id={`label.sign_up_issue.${i}`} />
          ))}
        </ul>
      )}
      <Button
        className="button primary"
        onClick={e => {
          e.preventDefault();
          startChat(jurisdiction);
        }}>
        <Translate id="start.live-chat" defaultMessage="Start chat" />
      </Button>
    </div>
  );
};

const selector = state => ({
  jurisdiction: state.app.jurisdiction,
});

export default {
  name: 'issues',
  selector,
  Component: Issues,
};

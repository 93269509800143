import React from 'react';
import { Translate, Button, Input, Icon } from 'tg-core-components';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import { required, validateRules } from '../../validation';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { useDispatch } from 'react-redux';
import Alerts from '@components/Alerts';
import { removeAlerts } from '@actions/alert';

import './style.css';

const Exists = ({
  intl,
  onChange,
  errors,
  fields,
  isLoading,
  setIsLoading,
  statuses,
  onClose,
  alerts,
}) => {
  const dispatch = useDispatch();

  const signIn = () => {
    setIsLoading(true);

    dispatch(
      playerActions.signInV2({
        Username: fields.Email,
        Password: fields.Password,
      })
    )
      .then(res => {
        if (!res?.type?.includes('_FAILURE')) {
          onClose();
        }
      })
      .finally(() => setIsLoading(false));
  };

  const isValid =
    statuses.Email === 'success' && statuses.Password === 'success';

  return (
    <div className="Exists">
      <Alerts
        scrollIntoView={false}
        alerts={alerts}
        removeAlerts={type => dispatch(removeAlerts(type))}
      />

      <form
        onSubmit={e => {
          e.preventDefault();
          signIn();
        }}>
        <Input
          autoFocus
          type="email"
          label={translate(
            {
              id: 'label.username_or_email',
              defaultMessage: 'Username or email address',
            },
            intl
          )}
          value={fields.Email}
          name="Email"
          onChange={(_, Email) => onChange({ Email })}
          status={errors.Email && 'failure'}
          icon={<Icon icon="envelope" />}
          statusText={errors.Email && translate({ id: errors.Email }, intl)}
        />

        <Input
          type="password"
          label={translate({ id: 'label.password' }, intl)}
          onChange={(_, Password) => onChange({ Password })}
          icon={<Icon icon="lock" />}
          status={errors.Password && 'failure'}
          statusText={
            errors.Password && translate({ id: errors.Password }, intl)
          }
        />

        <div className="actions">
          <Button
            className="primary wide"
            isLoading={isLoading}
            disabled={!isValid}>
            <Translate id="action.button.login" defaultMessage="Login" />
          </Button>

          <Button to={`#reset-password/${fields.Email}`}>
            <Translate
              id="label.forgot_password"
              defaultMessage="Forgot password?"
            />
          </Button>
        </div>
      </form>
    </div>
  );
};

const validate = async ({ Email, Password }) => {
  const rules = {
    Email: [[required, 'error.empty.username_or_email']],
    Password: [[required, 'error.empty.password']],
  };
  const errors = await validateRules({ Email, Password }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  alerts: state.alerts.data.filter(a => ['SIGNINV2'].includes(a.type)),
});

export default {
  name: 'exists',
  validate,
  selector,
  Component: Exists,
  fields: ['Email', 'Password'],
};

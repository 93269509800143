import React from 'react';
import Alert from '@components/Alert';

const getActiveBonusWithdrawPolicies = bonuses => {
  return {
    denyWithdraw: bonuses?.some(
      bonus =>
        bonus.State === 'Active' && bonus.WithdrawPolicy === 'DenyWithdraw'
    ),
    cancelMonetary: bonuses?.some(
      bonus =>
        bonus.State === 'Active' && bonus.WithdrawPolicy === 'CancelMonetary'
    ),
  };
};

const WithdrawBonusPolicies = ({ bonuses }) => {
  const activeBonusWithdrawPolicies = getActiveBonusWithdrawPolicies(bonuses);
  const hasActiveBonusWithoutPolicy = bonuses?.some(
    bonus => bonus.State === 'Active' && bonus.WithdrawPolicy === 'None'
  );

  const showDefaultNotice =
    !activeBonusWithdrawPolicies.denyWithdraw &&
    !activeBonusWithdrawPolicies.cancelMonetary &&
    hasActiveBonusWithoutPolicy;

  return (
    <>
      {activeBonusWithdrawPolicies.denyWithdraw && (
        <Alert
          icon="exclamation-triangle"
          id="alert_message.warning.withdraw-bonus-deny-withdraw"
          defaultMessage="Please note that withdrawals are not processed if you have an active bonus."
          level="warning"
        />
      )}

      {activeBonusWithdrawPolicies.cancelMonetary && (
        <Alert
          icon="exclamation-triangle"
          id="alert_message.warning.withdraw-bonus-cancel-monetary"
          defaultMessage="Please note that your bonus balance will be cancelled if you proceed with the withdrawal."
          level="warning"
        />
      )}

      {showDefaultNotice && (
        <Alert
          icon="exclamation-triangle"
          id="alert_message.warning.withdraw-bonus"
          defaultMessage="You have an active bonus. If you withdraw, your remaining bonus balance will be confiscated."
          level="warning"
        />
      )}
    </>
  );
};

export default WithdrawBonusPolicies;

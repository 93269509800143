import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SignIn, Translate } from 'tg-core-components';
import { STEPS } from './index';

const EmailSignInStep = ({ setStep, onSubmitSignIn, isLoading }) => {
  const [email, setEmail] = useState('');
  return (
    <div className="EmailSignInStep">
      <SignIn
        email={email}
        handleChangeEmail={(e, email) => setEmail(email)}
        isLoading={isLoading}
        onSubmitForm={data => {
          onSubmitSignIn(data).then(response => {
            if (response.status === 200 && response.statusText === 'OK')
              setStep(STEPS.SIGNUP);
          });
        }}
        handleToggleRememberEmail={() => {}}
      />

      <div className="links">
        <a
          onClick={() => setStep(STEPS.SET_METHOD_STEP)}
          className="bankid-signin">
          <Translate
            id="action.sign-in.bankid"
            defaultMessage="Sign in with BankID"
          />
        </a>

        <div className="forgot-password">
          <Link className="forgot-password-link" to="/reset-password">
            <Translate id="label.forgot_password" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmailSignInStep;

import LimitsStep from '@components/Account/SignUp/SetLimitsStep';
import { hasPlaceholderLimits } from '@components/PayNPlay/helpers';
import selectCurrency from '@selectors/selectCurrency';
import marked from 'marked';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Translate } from 'tg-core-components';
import responsibleGamingActions from 'tg-core-redux/lib/modules/responsible_gaming/action';
import LowLimits from '../LowLimits';

import './style.css';

const SkipLimits = ({ onSkip, onContinue, content }) => (
  <div className="Limits__skip-prompt">
    {content && <div dangerouslySetInnerHTML={{ __html: marked(content) }} />}

    <Button onClick={onSkip} className="button primary">
      <Translate id="action.pnp-set-limits-no" defaultMessage="No, continue" />
    </Button>
    <Button onClick={onContinue} className="button tertiary">
      <Translate
        id="action.pnp-set-limits-yes"
        defaultMessage="Yes, set limits"
      />
    </Button>
  </div>
);

const Limits = ({
  /* Props from parent */
  nextStep,
  fields,
  onChange,
  sessionId,
  onError,

  /* Props from selector */
  sets,
  sections,
  jurisdiction,
  currency,
}) => {
  const dispatch = useDispatch();
  const skipLimits = jurisdiction === 'mga';
  const [showSkipScreen, setShowSkipScreen] = useState(
    skipLimits && fields.Limits?.length === 0
  );

  const [isLoading, setIsLoading] = useState(false);

  const responsibleGamingContent = sets?.find(
    s => s.identifier === 'responsible-gaming-signup-new'
  );

  const limitsHeader = sections?.find(
    s => s.identifier === 'header.paynplay.limits'
  )?.content;

  const limitsPromptContent = sections?.find(
    s => s.identifier === 'header.paynplay.limits-prompt'
  )?.content;

  const onSubmit = async ({ limits }) => {
    const limitsGroupedByType = limits.reduce((acc, l) => {
      const prevAmount = acc?.[l.Type]?.[l.Timespan]?.Amount;
      return {
        ...acc,
        [l.Type]: {
          ...acc[l.Type],
          [l.Timespan]:
            !prevAmount || prevAmount > l.Amount ? l.Amount : prevAmount,
        },
      };
    }, {});

    const promises = Object.keys(limitsGroupedByType).map(type =>
      dispatch(
        responsibleGamingActions.setMultipleLimits(sessionId, {
          Type: type,
          DoRefund: false,
          IsSignupLimit: fields.Amount > 0,
          Limits: Object.keys(limitsGroupedByType[type]).map(key => ({
            Timespan: key,
            Amount: limitsGroupedByType[type][key],
          })),
        })
      )
    );

    setIsLoading(true);

    const res = await Promise.all(promises);

    setIsLoading(false);

    // Find limits lower than deposited amount from given backend response
    const hasLowLimits =
      res?.[0]?.payload?.value?.response?.data?.Code ===
      'LimitLowerThanSignupDeposit';

    const hasOtherErrors =
      res?.[0]?.type === 'SET_LIMIT_FAILURE' &&
      res?.[0]?.payload?.value?.response?.data?.Code !==
        'LimitLowerThanSignupDeposit';

    // Show generic error screen for other limit errors
    if (hasOtherErrors) {
      return onError();
    }

    if (hasLowLimits) {
      nextStep(LowLimits);
    } else {
      nextStep();
    }
  };

  const onUpdate = ({ limits }) => {
    onChange({
      Limits: limits,
    });
  };

  if (showSkipScreen) {
    return (
      <div className="Limits">
        <SkipLimits
          content={limitsPromptContent}
          onSkip={nextStep}
          onContinue={() => setShowSkipScreen(false)}
        />
      </div>
    );
  }

  return (
    <div className="Limits">
      <LimitsStep
        limits={hasPlaceholderLimits(fields.Limits) ? [] : fields.Limits}
        content={responsibleGamingContent}
        header={limitsHeader}
        onSubmit={onSubmit}
        onUpdate={onUpdate}
        currency={currency}
        isLoading={isLoading}
        jurisdiction={jurisdiction}
      />
    </div>
  );
};

const selector = state => ({
  sets: state.content.sets.data,
  sections: state.content.sections.data,
  jurisdiction: state.app.jurisdiction,
  currency: selectCurrency(state),
});

export default {
  name: 'limits',
  Component: Limits,
  selector,
};

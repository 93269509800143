import selectCurrency from '@selectors/selectCurrency';
import { useConfig } from '@utils/config';
import IntlMessageFormat from 'intl-messageformat';
import marked from 'marked';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Translate } from 'tg-core-components';
import devcodeActions from 'tg-core-redux/lib/modules/devcode/action';
import { withRouter } from 'react-router';

import './style.css';

const PendingWithdrawal = ({
  /* Props from parent */
  pendingWithdrawal,
  intl,
  sessionId,
  onError,
  nextStep,

  /* Props from selector */
  currency,
  sections,
  locale,
  user,

  /* Props from withRouter */
  history,
}) => {
  const config = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const formattedAmount = intl.formatNumber(pendingWithdrawal?.Amount, {
    style: 'currency',
    curreny: currency,
  });

  const content = sections?.find(
    s => s.identifier === 'pending-withdrawal-info'
  )?.content;

  const formattedContent = new IntlMessageFormat(content || '', locale).format({
    amount: formattedAmount,
  });

  const onSubmit = e => {
    e.preventDefault();

    setIsLoading(true);

    return dispatch(
      devcodeActions.cancelUserWithdrawal({
        transactionId: pendingWithdrawal.RemoteTransactionId,
        userId: user?.Id,
        merchantId: config.MERCHANT_ID,
        sessionId,
      })
    )
      .then(res => {
        if (res?.data?.success) {
          return history.push({
            pathname: '/casino',
            hash: 'popup/successful-deposit',
          });
        }

        onError('cancel-withdrawal');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="PendingWithdrawal">
      {formattedContent && (
        <div dangerouslySetInnerHTML={{ __html: marked(formattedContent) }} />
      )}

      <form onSubmit={onSubmit}>
        <Button
          className="button secondary"
          type="submit"
          disabled={isLoading}
          isLoading={isLoading}>
          <Translate
            id="action.cancel-withdrawal"
            defaultMessage="Cancel and deposit {amount}"
            values={{
              amount: formattedAmount,
            }}
          />
        </Button>

        <Button className="button link" onClick={nextStep}>
          <Translate
            id="label.skip-cacel-withdrawal"
            defaultMessage="Skip to deposit"
          />
        </Button>
      </form>
    </div>
  );
};

const selector = state => ({
  sections: state.content.sections.data,
  locale: state.app.locale,
  user: state.player.user,
  currency: selectCurrency(state),
});

export default {
  name: 'pending-withdrawal',
  Component: withRouter(PendingWithdrawal),
  selector,
};

import { getConfig } from '@utils/config';

const selectCountries = state => {
  const { type } = getConfig(state.app.jurisdiction, state.app.ipCountry);

  const countries = state.content.config.data.find(c => c.key === 'countries')
    ?.value;

  const typeSpecificCountries = countries?.filter(c => c.type === type);

  return typeSpecificCountries?.length > 0 ? typeSpecificCountries : countries;
};

export default selectCountries;

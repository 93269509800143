const selectAvailableBonuses = state => {
  const promotions = state.content.promotions.data;
  const bonusOffers = state.bonusOffer;

  return bonusOffers?.data
    .reduce((acc, b) => {
      if (b.Provider === 'fasttrack') {
        return b.promotion ? [...acc, b] : acc;
      }

      if (!b.RewardGroups) return;

      const items = b.RewardGroups.map(r => ({
        ...r,
        promotion: promotions?.find(c => c.bonusCode === r.PromoCode),
      }));

      const result = items.filter(i => i.promotion);

      return [...acc, ...result];
    }, [])
    .filter(bonus => bonus.promotion)
    .map(bonus => bonus.promotion);
};

export default selectAvailableBonuses;

export const reset = () => ({
  type: 'RESET_PAYMENT',
});

export const onSuccess = payload => ({
  type: 'PAYMENT_SUCCESS',
  payload,
});

export const onFailure = payload => ({
  type: 'PAYMENT_FAILURE',
  payload,
});

export const onRequestComplete = payload => ({
  type: 'PAYMENT_REQUEST_COMPLETE',
  payload,
});

export const onRequestFailure = payload => ({
  type: 'PAYMENT_REQUEST_FAILURE',
  payload,
});

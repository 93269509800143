import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'tg-core-components';
import { matchProviderType } from 'tg-core-components/lib/widgets/Cashier/Payment';
import quickDepositUtils from '@utils/quickDeposit';

import './style.css';

export const selectQuickDepositMobileProps = state => ({
  quickDeposit: state.quickDeposit,
  locale: state.app.locale,
  currency: state.player?.user?.Currency,
  methods: state.content.payments.data,
  paymentStats: state.player.paymentStats,
});

const QuickDepositMobile = ({
  /* props from selector */
  quickDeposit,
  locale,
  currency,
  methods,
  paymentStats,

  /* Props from parent */
  to,
  quickDepositInit,
  quickDepositOpen,
}) => {
  const lastDepositAmount = paymentStats?.LastDepositAmount;

  useEffect(() => {
    const createCustomQuickAmounts = selectedMethod => {
      const paymentDetails = methods.find(m =>
        matchProviderType(m, selectedMethod)
      );

      const quickAmounts =
        paymentDetails?.customAmounts?.value?.[currency] ||
        quickDepositUtils.generateQuickAmounts(
          currency,
          selectedMethod.limit.min,
          lastDepositAmount,
          null,
          selectedMethod.limit
        );

      const newAmounts = quickAmounts.map(amount => ({
        value: amount,
        text: new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: currency,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }).format(amount),
      }));

      return newAmounts;
    };

    quickDepositInit({
      root: '.QuickDepositMobile__root',
      createCustomQuickAmounts,
    });
  }, [quickDepositInit, currency, locale, methods, lastDepositAmount]);

  return (
    <div className="QuickDepositMobile">
      {quickDepositOpen && quickDeposit.isInitialized && (
        <Link
          className="QuickDepositMobile__icon button icon"
          onClick={quickDepositOpen}
          to={to || {}}>
          <Icon icon="coins" />
        </Link>
      )}
      <div className="QuickDepositMobile__root"></div>
    </div>
  );
};

export default QuickDepositMobile;

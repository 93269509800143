import {
  email,
  phone,
  required,
  validateRules,
} from '@components/PayNPlay/validation';
import { useConfig } from '@utils/config';
import marked from 'marked';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  countryEmojiFlags,
  Input,
  Select,
  Translate,
} from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import selectCountries from '@selectors/selectCountries';
import { format as moneyFormat } from 'tg-core-components/lib/components/Money';

const Contact = ({
  /* Props from parent */
  fields,
  onChange,
  nextStep,
  FirstNameRequired,
  sessionId,
  errors,
  intl,
  statuses,

  /* Props from selector */
  sections,
  countries,
  ipCountry,
}) => {
  const jurisdiction = useSelector(state => state.app.jurisdiction);
  const cmsConfig = useSelector(state => state.content.config.data);
  const currency = fields.Currency || 'EUR';
  const config = useConfig();
  const [isLoading, setIsLoading] = useState(false);

  const { userFields } = config;

  const dispatch = useDispatch();

  const stepContent = sections?.find(
    s => s.identifier === 'header.paynplay.details'
  )?.content;

  const activityOptions = cmsConfig?.find(c => c.key === 'annual-activity')
    ?.value;

  const formattedValues = activityOptions?.[currency].map(v => ({
    max: v.max ? moneyFormat(currency).format(v.max) : '',
    min: moneyFormat(currency).format(v.min),
  }));

  const value = fields.ActivityOptions?.[currency].find(
    a => a.min == fields.ExpectedAnnualLevelOfActivity
  );

  const createValueString = value => {
    return `${value.min}${value.max ? ' - ' : ''}${
      value.max ? value.max : '+'
    }`;
  };

  const onSubmit = async e => {
    e.preventDefault();

    setIsLoading(true);

    const setDetailsResponsePromise = dispatch(
      playerActions.setDetailsWithoutPassword(sessionId, {
        Email: fields.Email,
        Mobile: userFields.includes('phone') ? fields.MobilePhoneNumber : null,
        FirstName: fields.FirstName,
      })
    );

    const setExpectedAnnualActivityPromise = dispatch(
      playerActions.setExpectedAnnualActivity(
        sessionId,
        fields.ExpectedAnnualLevelOfActivity
      )
    );

    const [setDetailsResponse, setExpectedAnnualActivity] = await Promise.all([
      setDetailsResponsePromise,
      setExpectedAnnualActivityPromise,
    ]);

    setIsLoading(false);

    if (setDetailsResponse.status && setExpectedAnnualActivity.status) {
      nextStep();
    }
  };

  const isValid =
    !isLoading &&
    (userFields.includes('email') ? statuses.Email === 'success' : true) &&
    (userFields.includes('phone')
      ? statuses.MobilePhoneNumber === 'success'
      : true) &&
    jurisdiction === 'mga'
      ? statuses.ExpectedAnnualLevelOfActivity === 'success'
      : true &&
        (FirstNameRequired === 'true'
          ? statuses.FirstName === 'success'
          : true);

  return (
    <div className="Contact">
      {stepContent && (
        <div
          dangerouslySetInnerHTML={{ __html: marked(stepContent) }}
          className="Contact__content"
        />
      )}

      <form onSubmit={onSubmit}>
        {userFields.includes('email') && (
          <Input
            autoFocus
            type="email"
            name="Email"
            value={fields.Email}
            label={translate(
              {
                id: 'label.paynplay.email_placeholder',
                defaultMessage: 'E-mail',
              },
              intl
            )}
            onChange={(_, Email) => onChange({ Email })}
            status={statuses.Email}
            statusText={
              errors.Email &&
              translate(
                {
                  id: errors.Email,
                },
                intl
              )
            }
          />
        )}

        {userFields.includes('phone') && (
          <Input
            autoFocus={fields.Email}
            name="MobilePhoneNumber"
            type="tel"
            value={fields.MobilePhoneNumber || ''}
            onChange={(_, MobilePhoneNumber) => {
              onChange({ MobilePhoneNumber });
            }}
            callingCodes={countries
              ?.sort((a, b) => Number(a.callingCode) - Number(b.callingCode))
              ?.sort((a, b) =>
                a.value === ipCountry ? -1 : b.value === ipCountry ? 1 : 0
              )
              ?.map(i => ({
                value: '00' + i.callingCode,
                label: `+${i.callingCode} (${i.value}) ${countryEmojiFlags[
                  i.value
                ] || ''}`,
              }))}
            maxLength="17"
            status={
              fields.MobilePhoneNumber?.length > 5 && statuses.MobilePhoneNumber
            }
            statusText={
              fields.MobilePhoneNumber?.length > 5 &&
              errors.MobilePhoneNumber &&
              translate({ id: errors.MobilePhoneNumber }, intl)
            }
          />
        )}

        {FirstNameRequired === 'true' && (
          <Input
            autoFocus={fields.Email && fields.MobilePhoneNumber}
            status={statuses.FirstName}
            statusText={
              errors.FirstName && translate({ id: errors.FirstName }, intl)
            }
            label={translate(
              {
                id: 'label.paynplay.first_name_placeholder',
                defaultMessage: 'First name',
              },
              intl
            )}
            value={fields.FirstName}
            onChange={e => onChange({ FirstName: e.target.value })}
          />
        )}

        {jurisdiction === 'mga' && (
          <Select
            name="Activity"
            value={value}
            onChange={(_, value) =>
              onChange({
                ExpectedAnnualLevelOfActivity: value,
              })
            }
            status={statuses.ExpectedAnnualLevelOfActivity}
            statusText={
              errors.ExpectedAnnualLevelOfActivity &&
              translate({ id: errors.ExpectedAnnualLevelOfActivity }, intl)
            }>
            <Select.Option value="" selected disabled>
              {translate(
                {
                  id: 'label.expected-annual-activity',
                  defaultMessage: 'Expected Annual Activity',
                },
                intl
              )}
            </Select.Option>
            {formattedValues.map((c, i) => (
              <Select.Option
                key={i}
                value={createValueString(c)}
                hasFocus={formattedValues[1] === c}>
                {c.min}
                {i === formattedValues.length - 1 ? ' + ' : ' - '}
                {c.max ? c.max : ''}
              </Select.Option>
            ))}
          </Select>
        )}

        <Button
          className="button primary"
          type="submit"
          disabled={!isValid}
          isLoading={isLoading}>
          <Translate id="action.save" defaultMessage="Save" />
        </Button>
      </form>
    </div>
  );
};

const validate = async ({
  Email,
  MobilePhoneNumber,
  FirstName,
  ExpectedAnnualLevelOfActivity,
}) => {
  const rules = {
    Email: [
      [required, 'error.paynplay.details_email'],
      [email, 'error.paynplay.details_email'],
    ],
    MobilePhoneNumber: [
      [required, 'error.paynplay.details_phone'],
      [phone, 'error.paynplay.details_phone'],
    ],
    FirstName: [[required, 'error.paynplay.details_first_name']],
    ExpectedAnnualLevelOfActivity: [
      [required, 'error.empty.expected-annual-activity'],
    ],
  };
  const errors = await validateRules(
    { Email, MobilePhoneNumber, FirstName, ExpectedAnnualLevelOfActivity },
    rules
  );

  return Promise.resolve({ errors });
};

const selector = state => ({
  sections: state.content.sections.data,
  countries: selectCountries(state),
  ipCountry: state.app.ipCountry,
});

export default {
  name: 'contact',
  Component: Contact,
  validate,
  selector,
  fields: [
    'Email',
    'MobilePhoneNumber',
    'FirstName',
    'ExpectedAnnualLevelOfActivity',
  ],
};

import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { ResponsibleGamingWidget, Button, Translate } from 'tg-core-components';
import {
  normalize,
  validate,
  messages,
} from 'tg-core-components/lib/widgets/ResponsibleGamingWidget';
import marked from 'marked';

import './SetLimitsStep.css';

/**
 * This component is a wrapper to the ResponsibleGaming widget.
 * Adding a header (optional), back button (optional), cross-brand notice, submit button, validation.
 * Used in sign-in and sign-up.
 */
@injectIntl
class SetLimitsStep extends Component {
  onSubmit = e => {
    e.preventDefault();
    const { onSubmit, limits } = this.props;
    typeof onSubmit === 'function' && onSubmit({ limits });
  };

  onUpdate = ({ type, limits }) => {
    const { onUpdate } = this.props;
    typeof onUpdate === 'function' && onUpdate({ type, limits });
  };

  onChangeLimits = limits => {
    this.props.onUpdate({ limits });
  };

  render() {
    const {
      limits,
      content,
      header,
      onSubmit,
      onGoBack,
      isLoading,
      currency,
      jurisdiction,
      intl,
    } = this.props;

    const normalizedLimits = normalize(limits, content);

    const formIsValid = Object.values(normalizedLimits)
      .map(l => l.type)
      .every(type =>
        validate({ normalizedLimits, jurisdiction, type }).isValid()
      );

    const summaryLimits = Object.values(normalizedLimits).reduce(
      (limits, l) => [
        ...limits,
        ...Object.values(l.timespans)
          .filter(l => l.currentLimit && l.currentLimit.Amount > 0)
          .map(l => l.currentLimit),
      ],
      []
    );

    return (
      <div className="SetLimitsStep">
        {header && header.content && (
          <header
            className="SetLimitsStep__header"
            dangerouslySetInnerHTML={{
              __html: marked(header.content),
            }}
          />
        )}
        {limits && content && (
          <ResponsibleGamingWidget
            limits={limits}
            content={content}
            jurisdiction={jurisdiction}
            currency={currency}
            onChangeLimits={this.onChangeLimits}
            hideSubmit
            onSubmit={this.onUpdate}
            isLoading={isLoading}
          />
        )}
        {Array.isArray(summaryLimits) && summaryLimits.length > 0 && (
          <div className="SetLimitsStep__summary">
            {summaryLimits.map((l, i) => (
              <div className="SetLimitsStep__summary-item" key={i}>
                {messages.get(`header.responsible-gaming.${l.Type}`)}
                {messages.get(
                  `header.responsible-gaming.timespan.${l.Timespan}`
                )}
                {messages.getValue(
                  l.Amount,
                  normalizedLimits[l.Type].valueType,
                  currency,
                  intl
                )}
              </div>
            ))}
          </div>
        )}
        <form
          className="SetLimitsStep__actions layout-item"
          onSubmit={this.onSubmit}>
          {onGoBack && (
            <Button
              type="button"
              className="button tertiary wide"
              onClick={onGoBack}>
              <Translate
                id="action.button.previous"
                defaultMessage="Previous step"
              />
            </Button>
          )}
          {onSubmit && (
            <Button className="button primary wide" disabled={!formIsValid}>
              <Translate
                id="action.button.continue"
                defaultMessage="Continue"
              />
            </Button>
          )}
        </form>

        {jurisdiction === 'sga' && (
          <div className="SetLimitsStep__notice">
            <Translate
              id="setlimits.crossbrand-notice"
              tagName="small"
              defaultMessage="Please note that these limits will apply on all websites related to the licence XX000000"
            />
          </div>
        )}
      </div>
    );
  }
}

export default SetLimitsStep;

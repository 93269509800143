import React, { Component } from 'react';
import cn from 'classnames';
import { Icon } from 'tg-core-components';
import { Button } from 'tg-core-components';
import { withConfig } from '@config';

import './style.css';

@withConfig
class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevScrollPosition: 0,
      lastSlide: false,
      buttonDisabled: false,
      isDragging: false,
      mouseDownPosition: 0,
      mouseDownScrollLeft: 0,
      hoverEnabled: true,
      overflow: 0,
    };
    this.trackRef = React.createRef();
    this.listRef = React.createRef();
    this.slidesRef = React.createRef();
  }

  componentDidMount() {
    this.isActiveSlide();
  }

  componentDidUpdate(prevProps) {
    const { children } = this.props;
    if (prevProps.children.length === 0) return;
    if (prevProps.children[0].length !== children[0].length) {
      this.isActiveSlide();
    }
  }

  isActiveSlide = () => {
    const track = this.trackRef.current;
    this.slidesRef.forEach(i => {
      if (
        Math.round(i?.getBoundingClientRect().left) >= 0 &&
        Math.round(i?.getBoundingClientRect().right) <= track?.clientWidth
      ) {
        i?.classList.add('slide-item--active-slide');
      } else {
        i?.classList.remove('slide-item--active-slide');
      }
    });
  };

  getDragPositions = e => {
    const { mouseDownPosition } = this.state;
    const track = this.trackRef.current;

    this.slidesRef.forEach(i => {
      const itemRectLeft = Math.round(i?.getBoundingClientRect().left);
      const itemRectRight = Math.round(i?.getBoundingClientRect().right);
      const itemRectWidth = Math.round(i?.getBoundingClientRect().width);

      // Drag forwards
      if (mouseDownPosition > e.pageX + track.offsetLeft) {
        /**
         * If more than half of an item is inside the slider, snap forward
         * If less than half an item is inside, snap back to previous position
         */
        if (
          itemRectLeft <= track?.clientWidth - itemRectWidth / 2 &&
          itemRectRight > track?.clientWidth
        ) {
          track.scrollTo({
            left: track.scrollLeft + (itemRectRight - track?.clientWidth + 50),
            behavior: 'smooth',
          });
        } else if (
          itemRectLeft > track?.clientWidth - itemRectWidth / 2 &&
          itemRectRight > track?.clientWidth &&
          itemRectRight < track?.clientWidth + itemRectWidth
        ) {
          track.scrollTo({
            left: track.scrollLeft - (track?.clientWidth - 50 - itemRectLeft),
            behavior: 'smooth',
          });
        }
      }
      // Drag backwards
      else if (mouseDownPosition < e.pageX + track.offsetLeft) {
        /**
         * If more than half of an item is inside the slider, snap backwards
         * If less than half an item is inside, snap forward to previous position
         */
        if (
          itemRectLeft > 0 - itemRectWidth / 2 &&
          itemRectRight < itemRectWidth
        ) {
          track.scrollTo({
            left: track.scrollLeft - 50 + itemRectLeft,
            behavior: 'smooth',
          });
        } else if (
          itemRectLeft < 0 - itemRectWidth / 2 &&
          itemRectRight < itemRectWidth &&
          itemRectLeft > 0 - itemRectWidth
        ) {
          track.scrollTo({
            left: track.scrollLeft + itemRectRight - 50,
            behavior: 'smooth',
          });
        }
      }
    });
  };

  onMouseDown = e => {
    const track = this.trackRef.current;
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    this.setState({
      isDragging: true,
      mouseDownPosition: e.pageX + track.offsetLeft,
      mouseDownScrollLeft: track.scrollLeft,
    });
  };

  onMouseUp = e => {
    const track = this.trackRef.current;

    this.getDragPositions(e);

    this.setState({
      isDragging: false,
      overflow: 0,
    });

    setTimeout(() => {
      this.setState({
        hoverEnabled: true,
      });

      if (track.scrollWidth - track.clientWidth === track.scrollLeft) {
        this.setState({
          lastSlide: true,
        });
      } else {
        this.setState({
          lastSlide: false,
          prevScrollPosition: track?.scrollLeft,
        });
      }
      this.state.mouseDownPosition > e.pageX + track.offsetLeft &&
        this.isActiveSlide();
      this.state.mouseDownPosition < e.pageX + track.offsetLeft &&
        this.isActiveSlide();
    }, 500);

    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  };

  onMouseMove = e => {
    const { isDragging, mouseDownPosition, mouseDownScrollLeft } = this.state;
    const track = this.trackRef.current;
    if (!isDragging) return;

    const startPosition = e.pageX - track.offsetLeft;
    const movePosition =
      mouseDownScrollLeft - (startPosition - mouseDownPosition);

    const moveDifference = startPosition - mouseDownPosition;

    // Make the user drag more than 20 pixels before we unset pointer-events
    if (moveDifference > 20 || moveDifference < -20) {
      this.setState({
        hoverEnabled: false,
      });
    }

    track.scrollLeft = movePosition;
    // If dragging past beginning of slider
    if (movePosition < 0) {
      this.setState({
        overflow: Math.abs(movePosition),
      });
    }

    // If dragging past end of slider
    if (movePosition > track.scrollLeft) {
      this.setState({
        overflow: -Math.abs(movePosition - track.scrollLeft),
      });
    }
  };

  scroll = direction => {
    const track = this.trackRef.current;
    const list = this.listRef.current;

    const { lastSlide, prevScrollPosition } = this.state;
    const listWidth =
      direction === 'right' ? list.clientWidth : -list.clientWidth;

    /**
     * We need to know if it is the last slide and the previous scroll position
     * to determine how far to scroll back otherwise we would scroll the whole
     * list width instead of only the last slide/slides width.
     */

    if (lastSlide) {
      this.safariWorkAroundBehaviourSmooth(track, prevScrollPosition);
      this.setState({
        lastSlide: false,
        buttonDisabled: true,
      });
    } else {
      this.safariWorkAroundBehaviourSmooth(track, track.scrollLeft + listWidth);
      this.setState({
        buttonDisabled: true,
        prevScrollPosition: track?.scrollLeft,
        hoverEnabled: false,
      });
    }

    setTimeout(() => {
      this.isActiveSlide();
      this.setState({
        buttonDisabled: false,
        hoverEnabled: true,
      });
      if (track?.scrollWidth - track?.clientWidth === track?.scrollLeft) {
        this.setState({
          lastSlide: true,
        });
      }
    }, 1000);
  };

  safariWorkAroundBehaviourSmooth = (track, position) => {
    track.style.overflow = 'auto';
    window.requestAnimationFrame(() =>
      track.scrollTo({ left: position, behavior: 'smooth' })
    );
    setTimeout(() => (track.style.overflow = 'hidden'), 1000);
  };

  render() {
    const {
      children,
      showScrollNav,
      classNames,
      config: { casino },
    } = this.props;

    const { lastSlide, buttonDisabled, hoverEnabled, isDragging } = this.state;

    const slideItems = [];

    const dragEnabled = casino.sliderType === 'draggable' && showScrollNav;

    this.slidesRef = slideItems;

    return (
      <div className={cn('slider', classNames)}>
        <div className="list" ref={this.listRef}>
          {showScrollNav && (
            <Button
              className={cn('arrow arrow--prev', {
                'arrow--show': this.trackRef.current?.scrollLeft > 0,
                'arrow--last-slide': lastSlide,
              })}
              disabled={
                buttonDisabled || this.trackRef.current?.scrollLeft === 0
              }
              onClick={e => {
                e.preventDefault;
                this.scroll('left');
              }}>
              <Icon icon="chevron-circle-left" />
            </Button>
          )}
          <div
            className={cn('track container', {
              'hover-enabled': hoverEnabled,
              'is-dragging': isDragging,
            })}
            ref={this.trackRef}
            onMouseDown={dragEnabled ? this.onMouseDown : undefined}
            style={{ transform: `translateX(${this.state.overflow}px)` }}>
            <div className="items">
              {React.Children.map(children, child => {
                if (child !== null) {
                  return (
                    <div
                      ref={ref => slideItems.push(ref)}
                      className="slide-item">
                      {child}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          {showScrollNav && (
            <Button
              className={cn('arrow arrow--next', {
                'arrow--show': !lastSlide,
              })}
              disabled={buttonDisabled || lastSlide}
              onClick={e => {
                e.preventDefault();
                this.scroll('right');
              }}>
              <Icon icon="chevron-circle-right" />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default Slider;

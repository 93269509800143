export default (state, action) => {
  switch (action.type) {
    case `UPDATE_INTERNAL_MESSAGE`: {
      return {
        ...state,
        data: state.data.map(message =>
          action.payload.Id === message.Id ? action.payload : message
        ),
      };
    }
    default:
      return state;
  }
};

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Money, Translate, Skeleton } from 'tg-core-components';
import { withConfig } from '@config';
import { useUrls } from '@utils/urls';
import { useConfig } from '@utils/config';

import './style.css';

export const selectMembershipActionsProps = state => ({
  isAuthenticated: state.player.isAuthenticated,
  wallet: state.wallet,
  currency: state.player.user?.Currency,
  activeCurrency: state.player.activeCurrency,
  user: state.player.user,
});

const WalletButtonSkeleton = () => (
  <div className="WalletButtonSkeleton">
    <Skeleton.SkeletonLine />
  </div>
);

const MembershipActions = ({
  /* props from selector */
  isAuthenticated,
  wallet,
  currency,
  activeCurrency,
  user,

  /* withConfig */
  config,

  /* props from parent */
  ref_url: refUrl,
}) => {
  const { deposit, signIn, signUp } = useUrls();

  return (
    <div className="MembershipActions">
      {isAuthenticated ? (
        <WalletButton
          user={user}
          wallet={wallet}
          currency={activeCurrency || currency}
          isAuthenticated={isAuthenticated}
          refUrl={refUrl}
        />
      ) : config.isPnp ? (
        <Fragment>
          <Link
            className="button primary"
            to={deposit({ search: `?ref_url=${encodeURIComponent(refUrl)}` })}>
            <Translate id="action.pay_and_play" defaultMessage="Play now" />
          </Link>
          <Link
            to={signIn({ search: `?ref_url=${encodeURIComponent(refUrl)}` })}
            className="button secondary continue">
            <Translate
              id="action.fetch_balance"
              defaultMessage="Continue playing"
            />
          </Link>
        </Fragment>
      ) : (
        <Fragment>
          <Link className="button primary" to={signUp()}>
            <Translate id="action.sign-up" defaultMessage="Create account" />
          </Link>
          <Link className="button secondary" to={signIn()}>
            <Translate id="action.sign-in" defaultMessage="Sign in" />
          </Link>
        </Fragment>
      )}
    </div>
  );
};

export default withConfig(MembershipActions);

const WalletButton = ({ wallet, currency, isAuthenticated, refUrl, user }) => {
  const { deposit } = useUrls();

  const config = useConfig();

  if (!user || !wallet) {
    return <WalletButtonSkeleton />;
  }

  return (
    <Link
      className="button icon primary user-balance"
      to={deposit({ search: `?ref_url=${encodeURIComponent(refUrl)}` })}>
      {wallet.isLoading && <span>...</span>}
      {!wallet.isLoading && wallet.data && isAuthenticated && (
        <Fragment>
          <div className="balance">
            {config.showMembershipBonusBalance ? (
              <>
                <div className="money-balance">
                  <div className="balance-label">
                    <Translate
                      id="label.header-balance"
                      defaultMessage="Cash:"
                    />
                  </div>
                  <Money
                    value={wallet.data.Total.Balance}
                    currency={currency}
                  />
                </div>
                <div className="bonus-balance">
                  <div className="balance-label">
                    <Translate
                      id="label.header-bonus-balance"
                      defaultMessage="Bonus:"
                    />
                  </div>
                  <Money
                    value={wallet.data.Bonus.Balance}
                    currency={currency}
                  />
                </div>
              </>
            ) : (
              <Money value={wallet.data.Total.Balance} currency={currency} />
            )}
          </div>
        </Fragment>
      )}
      <div className="line" />
      <Icon icon="plus" />
    </Link>
  );
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  activate,
  requestActivationCode,
} from 'tg-core-redux/lib/modules/player/action';
import marked from 'marked';
import { injectIntl } from 'react-intl';
import OffCanvasLayout from '@components/OffCanvasLayout';
import queryString from 'query-string';
import { Button, Translate } from 'tg-core-components';
import { loadContents } from '@actions/content';
import withRouteData, { Loader } from '@utils/withRouteData';
import { Redirect } from 'react-router';
import { withConfig } from '@config';

import ActivateForm from './ActivateForm';
import ActivateSuccess from './ActivateSuccess';
import ActivateVerified from './ActivateVerified';

import './style.css';

const ACTIVATION = {
  SUCCESS: 'SUCCESS',
  VERIFIED: 'VERIFIED',
  ERROR: 'ERROR',
};

const RESEND = {
  SEND: 'SEND',
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
};

@withConfig
@injectIntl
@withRouteData
@withRouter
@connect(
  state => ({
    isAuthenticated: state.player.isAuthenticated,
    user: state.player.user,
    pages: state.content.pages.data,
    sections: state.content.sections.data,
    platform: state.app.device,
    operatingSystem: state.app.operatingSystem,
    browser: state.app.browser,
  }),
  { activate, requestActivationCode }
)
class Activate extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard/activate'],
    },
    {
      name: 'section',
      identifiers: ['activation-success', 'activation-verified'],
    },
  ]);

  state = {
    email: '',
    code: '',
    activationStatus: null,
    resendStatus: RESEND.SEND,
    emailInputType: 'text',
    codeInputType: 'text',
  };

  handleSubmit = () => {
    const { email, code } = this.state;

    this.activateUser(email, code);
  };

  activateUser = (email, code) => {
    const {
      activate,
      isAuthenticated,
      user,
      platform,
      operatingSystem,
      browser,
    } = this.props;

    const deviceDetails = {
      Platform: platform,
      OperatingSystem: operatingSystem,
      Browser: browser,
    };

    activate(isAuthenticated ? user.Email : email, code, deviceDetails).then(
      res => {
        if (res.type === 'ACTIVATE_FAILURE') {
          this.setState({
            activationStatus: ACTIVATION.ERROR,
            emailInputType: isAuthenticated ? 'hidden' : 'text',
            codeInputType: 'text',
          });
        } else {
          this.setState({ activationStatus: ACTIVATION.SUCCESS });
        }
      }
    );
  };

  resendActivationCode = () => {
    const { user, requestActivationCode, isAuthenticated } = this.props;
    const { email } = this.state;

    this.setState({
      resendStatus: RESEND.SENDING,
    });

    requestActivationCode(isAuthenticated ? user.Email : email)
      .then(() => {
        this.setState({
          resendStatus: RESEND.SENT,
        });
      })
      .catch(() => {
        this.setState({
          resendStatus: RESEND.ERROR,
        });
      });
  };

  componentDidMount() {
    const { user, isAuthenticated, location, config } = this.props;

    if (config?.disableActivation) return null;

    // Check if URL contains email or code.
    const query = queryString.parse(location.search);

    const email = isAuthenticated ? user?.Email : query.email;
    const code = query.code;

    this.setState({ email, code });

    if (user && user.IsVerified) {
      this.setState({ activationStatus: ACTIVATION.VERIFIED });
    } else if (email && code) {
      this.activateUser(email, code);
    }

    this.setState({
      emailInputType: email ? 'hidden' : 'text',
      codeInputType: code ? 'hidden' : 'text',
    });
  }

  componentWillUnmount() {
    const { location } = this.props;

    location.search = '';
  }

  render() {
    const { pages, sections, intl, history, config } = this.props;
    const {
      activationStatus,
      resendStatus,
      email,
      code,
      emailInputType,
      codeInputType,
    } = this.state;

    const page = pages?.find(p => p.url === '#dashboard/activate');
    const activationSuccess =
      sections && sections.find(s => s.identifier === 'activation-success');
    const activationVerified =
      sections && sections.find(s => s.identifier === 'activation-verified');

    let message = {};

    if (resendStatus === RESEND.SEND) {
      message.id = 'action.resend_activation_code';
      message.defaultMessage = 'Resend activation code';
    } else if (resendStatus === RESEND.SENDING) {
      message.id = 'message.sending';
      message.defaultMessage = 'Sending...';
    } else if (resendStatus === RESEND.SENT) {
      message.id = 'message.sent';
      message.defaultMessage = 'Activation code has been sent';
    } else if (resendStatus === RESEND.ERROR) {
      message.id = 'message.activation_error';
      message.defaultMessage = 'An error occured';
    }

    if (config?.disableActivation) return <Redirect to="/casino" />;

    return (
      <OffCanvasLayout className="ActivationContainer" backUrl="#dashboard">
        <Loader isLoading={!page}>
          <div className="Activate">
            {page && page.content && (
              <header>
                <div
                  dangerouslySetInnerHTML={{ __html: marked(page.content) }}
                />
              </header>
            )}

            {activationStatus === ACTIVATION.ERROR && (
              <div className="activation-error">
                <Translate
                  id="activation.error"
                  defaultMessage="An error occured, please try again..."
                />
              </div>
            )}

            {activationStatus === ACTIVATION.VERIFIED && (
              <ActivateVerified content={activationVerified} />
            )}

            {activationStatus === ACTIVATION.SUCCESS && (
              <ActivateSuccess
                content={activationSuccess}
                history={history}
                intl={intl}
              />
            )}

            {![ACTIVATION.VERIFIED, ACTIVATION.SUCCESS].includes(
              activationStatus
            ) && (
              <Fragment>
                <ActivateForm
                  emailInputType={emailInputType}
                  codeInputType={codeInputType}
                  onSubmitForm={this.handleSubmit}
                  email={email}
                  code={code}
                  changeEmail={({ target }) =>
                    this.setState({ email: target.value })
                  }
                  changeCode={({ target }) =>
                    this.setState({ code: target.value })
                  }
                />

                <Button
                  disabled={!email}
                  className="button secondary wide"
                  onClick={this.resendActivationCode}>
                  <Translate {...message} />
                </Button>
              </Fragment>
            )}
          </div>
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default Activate;

import casinoActions from 'tg-core-redux/lib/modules/casino/action';
import { ofType } from 'redux-observable';
import { map, delay } from 'rxjs/operators';

const getLastPlayedGamesWhenUpdated = (action$, state$) => {
  return action$.pipe(
    ofType('SET_LAST_PLAYED_GAME_COMPLETE'),
    delay(3000), // give backend time to update
    map(() =>
      casinoActions.getLastPlayedGames(
        state$.value.player.sessionId,
        state$.value.app.device
      )
    )
  );
};

export default getLastPlayedGamesWhenUpdated;

import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import PlaceholderThumbnail from '@images/symbol.png';
import cn from 'classnames';

import './style.css';
import marked from 'marked';

const CasinoCategoryRow = ({
  thumbnail,
  icon,
  name,
  tags = [],
  identifier,
  quality,
  location,
}) => {
  const thumbnailUrl = thumbnail?.file?.url;
  const thumbnailQuality = quality || (tags.includes('big') ? 100 : 75);

  return (
    <div className="CasinoCategoryRow">
      <Link
        to={{
          pathname: `/casino/${identifier}`,
          state: {
            back_url: location.pathname || '/casino',
          },
        }}>
        <div className={'thumbnail'}>
          <img
            className={cn('category-thumbnail', {
              'placeholder-thumbnail': !thumbnailUrl,
            })}
            src={
              thumbnailUrl
                ? `${thumbnailUrl}?fm=jpg&q=${thumbnailQuality}&fl=progressive`
                : PlaceholderThumbnail
            }
            loading="lazy"
          />
          {icon && (
            <div className="category-icon">
              <img src={`${icon.file.url}`} />
            </div>
          )}
        </div>
        <div className="category-name">
          {name && <div className="title">{name}</div>}
        </div>
        <div className="overlay"></div>
      </Link>
    </div>
  );
};

const CasinoSectionRow = ({
  backgroundImage,
  content,
  action,
  location,
  cta,
  config,
}) => {
  const backgroundUrl = backgroundImage?.file?.url;

  const style = {
    backgroundImage: backgroundUrl
      ? `url('${backgroundUrl}?fm=jpg&q=75&fl=progressive')`
      : null,
  };

  return (
    <div className="CasinoSectionRow" style={style}>
      <Link
        to={{
          ...(action?.startsWith('#')
            ? { hash: action?.slice(1) }
            : { pathname: action?.split('?')[0] }),
          state: {
            back_url: location.pathname || '/casino',
          },
        }}>
        {content && (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: marked(content) }}
          />
        )}
        {cta && action && (
          <div className="CasinoSectionRow__cta">
            <Link to={action} className={cn('button', config?.button?.primary)}>
              {cta}
            </Link>
          </div>
        )}
      </Link>
    </div>
  );
};

export const CategoryRow = withRouter(CasinoCategoryRow);
export const SectionRow = withRouter(CasinoSectionRow);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import OffCanvasLayout from '@components/OffCanvasLayout';
import BonusItem from '@components/BonusItem';
import { withCookies } from 'react-cookie';
import { Button, Translate } from 'tg-core-components';
import bonusActions from 'tg-core-redux/lib/modules/bonus/action';
import withRouteData from '@utils/withRouteData';
import { loadContents } from '@actions/content';
import { withConfig } from '@utils/config';

import './style.css';

@withConfig
@withRouter
@withCookies
@withRouteData
@connect(
  state => ({
    promotions: state.content.promotions.data,
    bonusOffer: state.bonusOffer,
    sessionId: state.player.sessionId,
  }),
  { getOffers: bonusActions.getOffers }
)
class BonusContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'promotion',
    },
  ]);

  componentDidMount() {
    const { getOffers, sessionId, config } = this.props;

    getOffers(sessionId, config?.bonusProvider);
  }

  render() {
    const { promotions, bonusOffer, cookies, history } = this.props;

    const preDepositBonusCodes = bonusOffer?.data.reduce((acc, b) => {
      const codes = b.RewardGroups.map(rg => rg.PromoCode);

      return [...acc, ...codes];
    }, []);

    const bonuses = promotions?.filter(
      i =>
        i.bonusCode && preDepositBonusCodes?.find(code => code === i.bonusCode)
    );

    const bonusCode = cookies.get('bonusCodeDeposit');

    const selectedBonus = bonuses?.find(b => b.bonusCode === bonusCode);

    return (
      <OffCanvasLayout className="BonusContainer">
        <main className="container">
          <Translate
            tagName="h2"
            id="title.bonus"
            defaultMessage="Pick a bonus"
          />
          <div className="bonus-instruction">
            <Translate
              id="text.bonus"
              defaultMessage="Here you can pick a nice bonus to boost with."
            />
          </div>
          {bonuses &&
            bonuses
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((b, k) => (
                <BonusItem
                  {...b}
                  key={k}
                  selected={
                    selectedBonus && selectedBonus.bonusCode === b.bonusCode
                  }
                  onClick={() => {
                    cookies.set('bonusCodeDeposit', b.bonusCode, {
                      expires: new Date(Date.now() + 31556952000),
                    });

                    if (
                      selectedBonus &&
                      selectedBonus.bonusCode === b.bonusCode
                    ) {
                      cookies.remove('bonusCodeDeposit');
                    }

                    history.push({
                      hash: 'deposit',
                      state: {
                        preventTransition: true,
                        removedBonus: selectedBonus?.bonusCode === b.bonusCode,
                      },
                    });
                  }}
                />
              ))}
          <Button
            className="button secondary"
            to={{ hash: 'deposit', state: { preventTransition: true } }}>
            <Translate
              id="action.back_to_deposit"
              defaultMessage="Back to deposit"
            />
          </Button>
        </main>
      </OffCanvasLayout>
    );
  }
}

export default BonusContainer;

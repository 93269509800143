import classNames from 'classnames';
import React, { useState } from 'react';
import { Money, Translate, Icon, ButtonLoader } from 'tg-core-components';
import CurrencySelector from '@components/CurrencySelector';

import './style.css';

export const selectWalletSelectorProps = state => ({
  wallet: state.wallet,
  wallets: state.wallets,
  activeWalletCurrency: state.player.user?.Currency,
  activeDisplayCurrency:
    state.player.activeCurrency || state.player.user?.Currency,
  currencies: state.currencies.currencies,
  displayCurrencies: state.currencies.displayCurrencies,
  sessionId: state.player.sessionId,
});

const WalletSelectorItem = ({ onChange, isActive, name, balance, code }) => {
  return (
    <div
      className={classNames('WalletSelectorItem', {
        'WalletSelectorItem--active': isActive,
      })}
      onClick={() => onChange?.(code)}>
      <span className="WalletSelectorItem__name"> {name} </span>
      <Money currency={code} value={balance} />
    </div>
  );
};

const WalletSelectorList = ({
  onChange,
  wallet,
  wallets,
  currencies,
  displayCurrencies,
  activeDisplayCurrency,
  onSelectDisplayCurrency,
}) => {
  const filteredDispayCurrencies = displayCurrencies.filter(
    c => !currencies.includes(c.name)
  );
  return (
    <div className="WalletSelectorList">
      {displayCurrencies.length > 0 && (
        <CurrencySelector
          currency={activeDisplayCurrency}
          currencies={filteredDispayCurrencies}
          onSelect={onSelectDisplayCurrency}
        />
      )}
      {currencies.map(c => {
        const balances = wallets?.data?.Balances?.[c];

        return (
          <WalletSelectorItem
            key={c}
            icon={null}
            name={c}
            code={c}
            balance={balances?.Total?.Balance}
            onChange={onChange}
            isActive={c === wallet}
          />
        );
      })}
    </div>
  );
};

const WalletSelector = ({
  /* props from parent */
  onSelect,
  onSelectDisplayCurrency,

  /* props from selector */
  activeWalletCurrency,
  wallets,
  wallet,
  currencies,
  displayCurrencies,
  activeDisplayCurrency,
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = c => {
    onSelect?.(c);
    setOpen(false);
  };

  return (
    <div
      className={classNames('WalletSelector', {
        'WalletSelector--open': open,
      })}>
      <div className="WalletSelector__header" onClick={() => setOpen(!open)}>
        <div className="WalletSelector__header-label">
          <Translate id="label.wallet-selector" defaultMessage="Wallet" />
        </div>
        <div className="WalletSelector__header-wallet">
          {wallet.isLoading ? (
            <ButtonLoader />
          ) : (
            <span>{activeWalletCurrency}</span>
          )}
          <div className="expand-icon">
            <Icon icon="angle-down" />
          </div>
        </div>
      </div>
      {open && (
        <WalletSelectorList
          onChange={w =>
            w === activeWalletCurrency ? setOpen(false) : handleChange(w)
          }
          wallet={activeWalletCurrency}
          wallets={wallets}
          currencies={currencies}
          displayCurrencies={displayCurrencies}
          activeDisplayCurrency={activeDisplayCurrency}
          onSelectDisplayCurrency={onSelectDisplayCurrency}
        />
      )}
    </div>
  );
};

export default WalletSelector;

import BonusSelector from '@components/BonusSelector';
import { useConfig } from '@utils/config';
import marked from 'marked';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Translate, Button } from 'tg-core-components';
import bonusActions from 'tg-core-redux/lib/modules/bonus/action';
import { useEffect } from 'react';

const Bonus = ({
  /* Props from parent */
  bonuses,
  onChange,
  fields,
  sessionId,
  nextStep,

  /* Props from selector */
  sections,
}) => {
  const dispatch = useDispatch();
  const config = useConfig();

  const showBonusSelector =
    (config.showBonusSelector && bonuses.length) || null;

  const [isLoading, setIsLoading] = useState(false);

  const stepContent = sections?.find(
    s => s.identifier === 'header.paynplay.bonus'
  )?.content;

  useEffect(() => {
    if (!showBonusSelector) nextStep();
  }, []);

  const onSubmit = async bonusCode => {
    if (!bonusCode) {
      return nextStep();
    }

    setIsLoading(true);

    const response = await dispatch(
      bonusActions.claimBonus(sessionId, bonusCode, config.bonusProvider)
    );

    setIsLoading(false);

    if (response.status) {
      nextStep({ BonusCode: bonusCode });
    }
  };

  if (!showBonusSelector) return null;

  return (
    <div className="Bonus">
      {stepContent && (
        <div
          dangerouslySetInnerHTML={{ __html: marked(stepContent) }}
          className="Bonus__content"
        />
      )}

      <BonusSelector
        bonuses={bonuses}
        isLoading={isLoading}
        onBonusSelect={bonusCode => {
          onChange({ BonusCode: bonusCode });
          onSubmit(bonusCode);
        }}
        selectedBonus={fields.BonusCode}
      />

      <Button className="button link" onClick={nextStep}>
        <Translate
          id="action.skip-bonus"
          defaultMessage="Continue without a bonus"
        />
      </Button>
    </div>
  );
};

const selector = state => ({
  sections: state.content.sections.data,
});

export default {
  name: 'bonus',
  Component: Bonus,
  selector,
  fields: ['BonusCode'],
};

import React from 'react';
import { AutocompleteInput, Input, Icon, Button } from 'tg-core-components';
import cn from 'classnames';
import {
  validateRules,
  required,
  blacklistedCharacters,
} from '../../validation';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { useAddressSuggestions } from '../../helpers';
import { getConfig } from '@config';
import { getContinueLabel } from '../../helpers';

const Location = ({
  /* Props from parent */
  errors,
  onChange,
  intl,
  fields,
  nextStep,
  statuses,

  /* Props from selector */
  config,
  device,
}) => {
  const { suggestions, isLoading } = useAddressSuggestions(
    fields.Address,
    fields.City,
    config?.aleacc?.host
  );

  const isValid =
    statuses.Address === 'success' && statuses.HouseNumber === 'success';

  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <AutocompleteInput
        autoFocus={!fields.Address}
        label={translate(
          { id: 'label.address', defaultMessage: 'Address' },
          intl
        )}
        value={fields.Address}
        name="Address"
        onChange={(_, Address) => onChange({ Address })}
        icon={<Icon icon="map-marker" />}
        status={(isLoading && 'loading') || statuses.Address}
        statusText={
          errors.Address &&
          translate(
            {
              id: errors.Address,
            },
            intl
          )
        }>
        {suggestions?.map((suggestion, i) => (
          <AutocompleteInput.Suggestion
            selected={suggestion?.address === fields.Address}
            key={i}
            value={suggestion?.address}
            onClick={Address => onChange({ Address })}>
            <span>
              <b>{suggestion.address}</b> {suggestion.city},{' '}
              {suggestion.country}
            </span>
          </AutocompleteInput.Suggestion>
        ))}
      </AutocompleteInput>

      <Input
        autoFocus={fields.Address}
        type="text"
        label={translate(
          { id: 'label.house-number', defaultMessage: 'House number' },
          intl
        )}
        value={fields.HouseNumber}
        name="HouseNumber"
        onChange={(_, HouseNumber) => onChange({ HouseNumber })}
        status={statuses.HouseNumber}
        statusText={
          errors.HouseNumber && translate({ id: errors.HouseNumber }, intl)
        }
      />

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ Address, HouseNumber }) => {
  const rules = {
    Address: [
      [required, 'error.empty.address'],
      [blacklistedCharacters, 'error.format.address'],
    ],
    HouseNumber: [
      [required, 'error.empty.house-number'],
      [blacklistedCharacters, 'error.format.house-number'],
    ],
  };
  const errors = await validateRules({ Address, HouseNumber }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  config: getConfig(state.app.jurisdiction),
  device: state.app.device,
});

export default {
  name: 'location',
  icon: 'user',
  Component: Location,
  validate,
  selector,
  fields: ['Address', 'HouseNumber'],
};

import React from 'react';
import { Button, Translate } from 'tg-core-components';
import cn from 'classnames';
import './style.css';

const Gender = ({ onChange, fields, nextStep }) => {
  const genders = [
    {
      label: <Translate id="noun.male" defaultMessage="Male" />,
      value: 'Male',
    },
    {
      label: <Translate id="noun.female" defaultMessage="Female" />,
      value: 'Female',
    },
    {
      label: <Translate id="noun.other" defaultMessage="Other" />,
      value: 'Other',
    },
  ];

  return (
    <form className="Gender" onSubmit={nextStep}>
      <div className="genders">
        {genders.map((gender, i) => {
          const isSelected = fields.Gender === gender.value;

          return (
            <Button
              autoFocus={isSelected}
              key={i}
              className={cn('tertiary', {
                selected: isSelected,
              })}
              onClick={() => {
                onChange({ Gender: gender.value });
              }}>
              {gender.label}
            </Button>
          );
        })}
      </div>
    </form>
  );
};

export default {
  name: 'gender',
  icon: 'user',
  Component: Gender,
  fields: ['Gender'],
};

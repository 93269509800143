import React from 'react';
import cn from 'classnames';
import { Icon } from 'tg-core-components';

import './style.css';

const Icons = ({ onBack, showBack, icons, nextStepToFill, onIconClick }) => {
  return (
    <nav className="Icons">
      {showBack && (
        <Icon
          onClick={onBack}
          icon="chevron-left"
          className={cn('Icons__icon', 'Icons__icon--back')}
        />
      )}

      <ul>
        {icons.map((icon, i) => {
          const nextStepToFillIndex = icons.indexOf(nextStepToFill.icon);
          const isActive = i <= nextStepToFillIndex;

          return (
            <li key={i}>
              <Icon
                onClick={() => isActive && onIconClick(icon)}
                icon={icon}
                className={cn('Icons__icon', {
                  'Icons__icon--active': isActive,
                })}
              />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Icons;

import React from 'react';
import { NavLink } from 'react-router-dom';

const NavSection = ({ item, key }) => {
  return /(http(s?)):\/\//gi.test(item?.url) ? (
    <a
      key={key}
      rel="noopener noreferrer"
      href={item.url}
      className="navigation"
      target="_blank">
      {item.menu}
    </a>
  ) : item.url ? (
    <NavLink
      className="navigation"
      key={key}
      exact={item.url === 'index'}
      activeClassName="active"
      to={
        item.url === 'index'
          ? '/'
          : `${item.url?.match(/^\#/) ? '' : '/'}${item.url}`
      }>
      <span>{item.menu}</span>
    </NavLink>
  ) : null;
};

export default NavSection;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  resetPassword,
  requestPasswordReset,
} from 'tg-core-redux/lib/modules/player/action';
import queryString from 'query-string';
import WithLoader from '@components/hoc/WithLoader';
import withRouteData from '@utils/withRouteData';
import { withRouter } from 'react-router';
import { loadContents } from '@actions/content';
import OffCanvasLayout from '@components/OffCanvasLayout';
import marked from 'marked';

import { ResetPassword, RequestResetPassword } from 'tg-core-components';

@connect(
  state => ({
    pages: state.content.pages.data,
    sections: state.content.sections.data,
    status: state.player.requestResetPasswordStatus,
    alerts: state.alerts.data.filter(a =>
      ['REQUEST_PASSWORD_RESET', 'RESET_PASSWORD'].includes(a.type)
    ),
  }),
  { resetPassword, requestPasswordReset }
)
@WithLoader
@withRouteData
@withRouter
class ResetPasswordContainer extends Component {
  state = {};

  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#reset-password'],
    },
    {
      name: 'section',
      identifiers: ['request-reset-success', 'request-reset-failure'],
    },
  ]);

  constructor(props) {
    super(props);
  }

  onSubmitReset = async data => {
    const { overlayLocation } = this.props;
    const token = queryString.parse(overlayLocation.search).token;
    const username = queryString.parse(overlayLocation.search).username;

    this.setState({ isLoading: true });
    await this.props.resetPassword({
      UsernameOrEmail: username,
      Token: token,
      NewPassword: data.NewPassword,
    });
    this.setState({ isLoading: false });
  };

  onSubmitRequestReset = async data => {
    this.setState({ isLoading: true });
    await this.props.requestPasswordReset(data.UsernameOrEmail);
    this.setState({ isLoading: false });
  };

  render() {
    const { location, status, pages, sections } = this.props;
    const page = pages?.find(p => p.url === '#reset-password');

    const successSection = sections?.find(
      p => p.identifier === 'request-reset-success'
    );

    const token = queryString.parse(location?.search).token;
    const username = queryString.parse(location?.search).username;

    return (
      <OffCanvasLayout>
        {page?.content && (
          <header dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
        )}

        {token && username && (
          <ResetPassword
            onSubmitForm={this.onSubmitReset}
            isLoading={this.state.isLoading}
          />
        )}

        {(!token || !username) && (
          <RequestResetPassword
            status={status}
            successMsg={successSection}
            onSubmitForm={this.onSubmitRequestReset}
            isLoading={this.state.isLoading}
          />
        )}
      </OffCanvasLayout>
    );
  }
}

export default ResetPasswordContainer;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import userActions from 'tg-core-redux/lib/modules/devcode/action';
import withdrawApis from 'tg-core-devcode/lib/withdraw';
import PaymentForm from 'tg-core-components/lib/widgets/Cashier/Deposit/PaymentForm';
import { withConfig } from '@config';
import * as paymentActions from '@actions/payment';
import CashierResult from 'tg-core-components/lib/widgets/CashierAccordion/Payment/CashierResult';
import { injectIntl } from 'react-intl';
import {
  Translate,
  Icon,
  Loader,
  WithdrawWidgetAccordion,
} from 'tg-core-components';
import { matchProviderType } from 'tg-core-components/lib/widgets/Cashier/Payment';
import SelectedPaymentMethod from 'tg-core-components/lib/widgets/CashierAccordion/Payment/SelectedPaymentMethod';
import Alert, { createAlertId } from '@components/Alert';
import { trimObjectStrings } from '@utils/trimObjectStrings';
import Notice from 'tg-core-components/lib/widgets/CashierAccordion/Payment/Notice';

@withConfig
class Withdraw extends Component {
  // declare depositAmount to store the amount of submitting deposit
  // this variable won't affect to UI render, don't need to declare inside of state
  depositAmount = 0;

  constructor() {
    super();

    this.state = {
      selectedOption: null,
      paymentData: null,
    };
  }

  componentWillUnmount() {
    this.props.dispatch(paymentActions.reset());
  }

  componentDidMount() {
    const { user, device, sessionId } = this.props;
    this.props.dispatch(
      userActions.getUserAvailablePaymentMethods({
        merchantId: this.props.config.MERCHANT_ID,
        userId: user?.Id,
        sessionId,
        method: 'Withdrawal',
        channelId: device ? device.substr(0, 1) : null,
      })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedOption?.selectedMethod?.txType !==
      this.state.selectedOption?.selectedMethod?.txType
    ) {
      this.props.dispatch(paymentActions.reset());
    }
  }

  handleSubmit = ({
    selectedMethod,
    selectedAccount,
    currentAmount,
    currentStep,
    ...others
  }) => {
    const { sessionId, user, locale, device, config, payments } = this.props;

    const paymentDetails = payments.find(p =>
      matchProviderType(p, selectedMethod)
    );

    this.props.dispatch(paymentActions.reset());
    this.reset();

    // store the amount of deposit to current context
    this.depositAmount = Number(currentAmount);

    if (!selectedMethod) return;

    if (
      typeof withdrawApis[selectedMethod.providerType.toLowerCase()] !==
      'function'
    ) {
      return alert(
        `Provider not implemented. Implemented: '${Object.keys(
          withdrawApis
        ).join(',')}'`
      );
    }

    this.setState({
      selectedOption: {
        selectedMethod,
        selectedAccount,
        currentAmount,
        currentStep,
        accountData: others,
      },
    });

    const paymentRequestData = {
      amount: Number(currentAmount),
      sessionId,
      locale,
      userId: user.Id,
      merchantId: this.props.config.MERCHANT_ID,
      attributes: {
        successUrl:
          window.location.origin +
          '/payment-success.html?method=${ptx.method}&tx=${ptx.id}&amount=${ptx.amount.amount}&currency=${ptx.amount.currencyCode}' +
          `&isAccount=${config.isAccount}`,
        pendingUrl:
          window.location.origin +
          '/payment-pending.html?method=${ptx.method}&tx=${ptx.id}&amount=${ptx.amount.amount}&currency=${ptx.amount.currencyCode}' +
          `&isAccount=${config.isAccount}`,
        failureUrl:
          window.location.origin +
          '/payment-failure.html?method=${ptx.method}&tx=${ptx.id}&amount=${ptx.amount.amount}&currency=${ptx.amount.currencyCode}&state=${ptx.state}&status=${ptx.statusCode}' +
          `&isAccount=${config.isAccount}`,
        channelId: device ? device.substr(0, 1) : null,
        psp: selectedMethod.service,
      },
      ...others,
    };

    if (
      selectedAccount.accountId &&
      !(
        paymentDetails.accountSelection ||
        paymentDetails.accountSelectionWithdrawal
      )
    ) {
      paymentRequestData.accountId = selectedAccount.accountId;
    }

    return withdrawApis[selectedMethod.providerType.toLowerCase()](
      trimObjectStrings(paymentRequestData),
      user
    )
      .then(response => {
        this.setState({
          paymentData: response.data,
          error: null,
        });

        if (response.data.txState === 'WAITING_APPROVAL') {
          this.props.dispatch({
            type: 'PAYMENT_PENDING',
            payload: { method: 'withdraw' },
          });
        }

        return response;
      })
      .catch(error => {
        this.setState({
          paymentData: null,
          error,
        });
      });
  };

  /**
   * @param isGoingToHomepage
   */
  handleClose = (isGoingToHomepage = false) => {
    // reset the state of paymentActions, i.e., success/failed --> Null
    this.props.dispatch(paymentActions.reset());

    this.setState({
      paymentData: null,
      error: null,
    });

    if (isGoingToHomepage) {
      this.props.onClose?.();
    }
  };

  renderError = () => {
    // Translations from PaymentIQ comes in a prio order.
    // First match == most specific translation. Default
    // message in case all others are missing comes from
    // PaymentIQs `error.msg`.

    const { paymentData } = this.state;

    if (paymentData) {
      const { errors } = paymentData;

      const alertIds = errors && errors.map(e => createAlertId(e, 'danger'));

      return (
        <Fragment>
          {alertIds && (
            <Alert icon="exclamation-triangle" id={alertIds} level="danger" />
          )}
        </Fragment>
      );
    }

    return null;
  };

  /**
   * Notice rendered when payment interrupted by user.
   * Usually when user cancels a payment from inside an iframe.
   */
  renderCanceledNotice = () => (
    <Notice
      icon={<Icon icon="stop-circle" />}
      header={<Translate id="message.cashier.error" defaultMessage="Error" />}
      content={
        <Translate
          id="message.withdraw.canceled"
          defaultMessage="Withdraw canceled."
        />
      }
    />
  );

  reset = () => {
    this.setState({
      paymentData: null,
      selectedOption: null,
    });
  };

  handleDeleteAccount = account => {
    this.props.dispatch(
      userActions.deleteUserPaymentAccount({
        merchantId: this.props.config.MERCHANT_ID,
        userId: this.props.user.Id,
        accountId: account.accountId,
        sessionId: this.props.sessionId,
      })
    );
  };

  handleTryAgain = () => {
    this.props.dispatch(paymentActions.reset());

    this.setState({
      paymentData: null,
      selectedOption: {
        ...this.state.selectedOption,
        currentStep: 'SELECT_PAYMENT_METHOD',
      },
    });
  };

  handleClearError = () =>
    this.setState({ paymentData: { ...this.state.paymentData, errors: null } });

  render() {
    const {
      user,
      userAvailablePaymentMethods,
      sections,
      amounts,
      payments,
      wallet,
      paymentStatus,
      config,
    } = this.props;

    const { selectedOption } = this.state;

    const methods = userAvailablePaymentMethods?.data?.methods?.filter(
      method =>
        payments &&
        payments.filter(content => matchProviderType(method, content)).length >
          0
    );

    if (
      !userAvailablePaymentMethods ||
      userAvailablePaymentMethods.status !== 'success' ||
      !payments
    ) {
      return <Loader inline />;
    }

    if (this.state.error) {
      return (
        <CashierResult
          status="error"
          providerType={selectedOption?.selectedMethod?.providerType}
          generalError
          handleTryAgain={this.handleTryAgain}
        />
      );
    }

    if (['success', 'pending'].includes(this.props.paymentStatus.status)) {
      const payment = payments.find(
        p =>
          selectedOption &&
          p.providerType.toLowerCase() ===
            selectedOption.selectedMethod.providerType.toLowerCase()
      );

      return (
        <CashierResult
          status={this.props.paymentStatus.status}
          providerType={selectedOption?.selectedMethod?.providerType}
          currency={this.props.user.Currency}
          wallet={this.props.wallet}
          handleClose={this.handleClose}
          successMessage={payment && payment.withdrawSuccessMessage}
        />
      );
    }

    if (this.props.paymentStatus.status === 'failed') {
      return (
        <CashierResult status="error" handleTryAgain={this.handleTryAgain} />
      );
    }

    const { paymentData } = this.state;
    if (
      paymentData &&
      paymentData.success &&
      paymentStatus.status !== 'failed'
    ) {
      const paymentDetails = payments.find(p =>
        matchProviderType(p, this.state.selectedOption.selectedMethod)
      );

      return (
        <div className="cashier-accordion">
          <div className="fill-in-steps">
            <SelectedPaymentMethod
              detail={paymentDetails}
              handleBack={() =>
                this.setState({
                  paymentData: null,
                  selectedOption: {
                    ...this.state.selectedOption,
                    currentStep: 'SELECT_PAYMENT_METHOD',
                  },
                })
              }
            />

            <PaymentForm
              data={paymentData}
              onClose={() => this.handleClose(paymentData.success)}
              onSuccess={() =>
                this.props.dispatch({
                  type: 'PAYMENT_SUCCESS',
                  payload: { method: 'withdraw' },
                })
              }
              details={paymentDetails}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="payment-popup-container form-wrapper">
        {methods?.length > 0 && user && (
          <div className="cashier-accordion withdraw">
            <WithdrawWidgetAccordion
              config={config}
              headers={sections}
              amounts={amounts}
              payments={payments}
              currency={user.Currency}
              country={user.Country}
              methods={methods}
              initialState={selectedOption}
              onSubmit={this.handleSubmit}
              reset={this.reset}
              onDeleteAccount={this.handleDeleteAccount}
              renderError={this.renderError}
              clearError={this.handleClearError}
              wallet={wallet}
              renderCanceledNotice={
                paymentStatus.status === 'failed' && this.renderCanceledNotice
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(state => ({
  userAvailablePaymentMethods: state.devcode.availableWithdrawMethod,
  paymentStatus: state.paymentStatus,
  device: state.app.device,
  user: state.player.user,
  sessionId: state.player.sessionId,
  sections: state.content.sections.data,
  locale: state.app.locale,
  payments: state.content.payments.data,
  wallet: state.wallet,
}))(injectIntl(Withdraw));

import { getConfig } from '@config';
import queryString from 'query-string';

export const getLobbyUrl = (game, location) =>
  location?.state?.backUrl ||
  (game?.tags?.includes('Live') ? '/live-casino' : '/casino');

export const isFavorite = (games, gameId, tableId) => {
  if (!games || !games.length) return false;

  // Check for live casino games first since they can have the same ID.
  if (tableId)
    return games.some(g => g.Tables.find(t => t.TableId === tableId));

  return games.some(g => g.Id === gameId);
};

export const isTopGame = (games, gameId) => {
  if (!games || !games.length) return false;
  return games.some(g => g.GameId === gameId);
};

/**
 * Create launch url for tg-play
 *
 * @param {Game} game
 * @param {String} locale
 * @param {String} homeurl
 * @param {String} session
 * @param {String} jurisdiction
 */
export const createLaunchUrl = ({
  config,
  game,
  jurisdiction,
  locale,
  sessionId,
  activeDisplayCurrency,
}) => {
  if (!game) return null;

  const homeurl = __CLIENT__
    ? `${config.tgplay.host}/exit-game.html?fallback=${encodeURIComponent(
        window.location.origin + getLobbyUrl(game)
      )}`
    : '';

  let query = {
    homeurl,
    session: sessionId,
    language: locale ? locale.split('-')[0] : 'en',
    tableId: game.tableId,
    channel: game.platform === 'Mobile' ? 'mobg' : undefined,
    disableRealityCheck: getConfig(jurisdiction).tgplay.disableRealityCheck,
    standalone: getConfig(jurisdiction).tgplay.standalone,
    activeDisplayCurrency,
  };

  query = queryString.stringify(query);

  const { host, skin } = getConfig(jurisdiction).tgplay;
  return `${host}/${skin}/${game.backendId}?${query}`;
};

export const getTopGamesPromises = props => [
  props.getTopGames(),
  props.getTopGamesWager(),
  props.getTopGamesUWC(),
];

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import responsibleGamingActions from 'tg-core-redux/lib/modules/responsible_gaming/action';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import { loadContents } from '@actions/content';
import marked from 'marked';
import Popup from '@components/Popup';
import withRouteData from '@utils/withRouteData';
import { Translate } from 'tg-core-components';

import './style.css';

@withRouteData
@injectIntl
@connect(
  state => ({
    sets: state.content.sets.data,
    sections: state.content.sections.data,
    sessionId: state.player.sessionId,
  }),
  {
    pgsiScore: responsibleGamingActions.pgsiScore,
  }
)
class ResponsibleGamingQuestionsContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'section',
      identifiers: [
        'responsiblegaming-questions-intro',
        'responsiblegaming-questions-result-1',
        'responsiblegaming-questions-result-2',
        'responsiblegaming-questions-fullresult-1',
        'responsiblegaming-questions-fullresult-2',
        'responsiblegaming-questions-fullresult-3',
        'responsiblegaming-questions-fullresult-4',
      ],
    },
    {
      name: 'set',
      identifiers: ['responsiblegaming-questions'],
    },
  ]);

  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      points: 0,
      totalPoints: 0,
      checked: false,
      showFullResult: false,
      isLoading: false,
      isChecked: false,
      resultPage: false,
      enablePopupClose: false,
    };
  }

  nextStep = e => {
    this.setState({
      step: this.state.step + 1,
      points: this.state.points,
      checked: false,
      totalPoints: Number(this.state.totalPoints) + Number(this.state.points),
    });

    e.preventDefault();
  };

  sendScore = () => {
    const { sessionId, pgsiScore } = this.props;
    const { step, points, totalPoints, showFullResult } = this.state;

    this.setState({ isLoading: true });

    const score =
      step === 9 ? Number(totalPoints) + Number(points) : Number(totalPoints);
    const version = step === 9 ? 'Full' : 'Short';

    pgsiScore(sessionId, {
      Version: version,
      Score: score,
    }).then(() => {
      if (step !== 5) {
        this.setState({
          showFullResult: !showFullResult,
          isLoading: false,
          enablePopupClose: true,
        });
      }
    });
  };

  handleChange = e => {
    this.setState({
      points: e.target.value,
      checked: true,
    });
  };

  completeTest = () => {
    this.setState({
      resultPage: !this.state.resultPage,
    });
  };

  render() {
    const { sets, sections, isRouteDataLoading, match } = this.props;
    const {
      step,
      points,
      totalPoints,
      resultPage,
      isLoading,
      enablePopupClose,
    } = this.state;

    const responsibleGamingQuestions =
      sets && sets.find(s => s.identifier === 'responsiblegaming-questions');

    const responsibleGamingQuestionsIntro =
      sections &&
      sections.find(i => i.identifier === 'responsiblegaming-questions-intro');

    const question =
      responsibleGamingQuestions &&
      responsibleGamingQuestions.items.find(
        q => q.identifier === 'responsible-gaming-questions.question-' + step
      );

    const getSummary = totalPoints => {
      if (totalPoints < 1) return 'responsiblegaming-questions-result-1';
      if (totalPoints > 0 && totalPoints < 3)
        return 'responsiblegaming-questions-result-2';
    };

    const getFullSummary = totalPoints => {
      if (totalPoints < 1) return 'responsiblegaming-questions-fullresult-1';
      if (totalPoints > 0 && totalPoints < 3)
        return 'responsiblegaming-questions-fullresult-2';
      if (totalPoints > 2 && totalPoints < 8)
        return 'responsiblegaming-questions-fullresult-3';
      if (totalPoints >= 8) return 'responsiblegaming-questions-fullresult-4';
    };

    const summary =
      sections && sections.find(i => i.identifier === getSummary(totalPoints));

    const fullSummary =
      sections &&
      sections.find(i => i.identifier === getFullSummary(totalPoints));

    const Result = () => {
      return (
        <Fragment>
          {summary && summary.content && (
            <div
              dangerouslySetInnerHTML={{
                __html: marked(summary.content),
              }}
            />
          )}
          <div className="button-wrapper">
            <button
              onClick={this.completeTest}
              className="button small tertiary">
              <Translate
                id="responsible_gaming.questions.complete"
                defaultMessage="Complete test"
              />
            </button>
            <button onClick={this.sendScore} className="button primary small">
              <Translate
                id="responsible_gaming.questions.back"
                defaultMessage="Back to lobby"
              />
            </button>
          </div>
        </Fragment>
      );
    };

    return (
      <Popup
        title={
          <Translate
            id="responsible_gaming.questions.title"
            defaultMessage="Responsible gaming"
          />
        }
        showLoading={isLoading || isRouteDataLoading}
        preventClose={!enablePopupClose}
        match={match}>
        <div className="ResponsibleGamingQuestionsContainer">
          {step < 1 &&
            responsibleGamingQuestionsIntro &&
            responsibleGamingQuestionsIntro.content && (
              <Fragment>
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: marked(responsibleGamingQuestionsIntro.content),
                  }}
                />
                {responsibleGamingQuestionsIntro.cta && (
                  <button
                    onClick={this.nextStep}
                    className="button primary wide">
                    {responsibleGamingQuestionsIntro.cta}
                  </button>
                )}
              </Fragment>
            )}
          {step > 0 &&
            question &&
            question.content &&
            !resultPage &&
            !this.state.showFullResult && (
              <Fragment>
                <Translate
                  id="responsible_gaming.question"
                  defaultMessage="Question:"
                />{' '}
                {step}/{responsibleGamingQuestions.items.length}
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(question.content),
                  }}
                />
                <form>
                  {get(question, 'config.points', []).map(a => (
                    <label key={a.identifier}>
                      <input
                        type="radio"
                        onChange={this.handleChange}
                        checked={this.state.checked && points === a.point}
                        name={a.answer}
                        value={a.point}
                      />
                      <Translate
                        tagName="span"
                        id={`responsible_gaming.questions.${a.answer}`}
                      />
                    </label>
                  ))}
                </form>
                {step < responsibleGamingQuestions.items.length && (
                  <button
                    onClick={this.nextStep}
                    disabled={!this.state.checked}
                    className="button primary small wide next-button">
                    <Translate
                      id="responsible_gaming.questions.next"
                      defaultMessage="Next question"
                    />
                  </button>
                )}
                {step === responsibleGamingQuestions.items.length && (
                  <button
                    onClick={this.sendScore}
                    disabled={!this.state.checked}
                    className="button primary send">
                    <Translate
                      id="responsible_gaming.questions.submit"
                      defaultMessage="Submit answers"
                    />
                  </button>
                )}
              </Fragment>
            )}

          {resultPage && <Result />}

          {this.state.showFullResult && (
            <div className="result">
              {fullSummary && fullSummary.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(fullSummary.content),
                  }}
                />
              )}
              <button
                onClick={() => (window.location.hash = '')}
                className="button primary">
                <Translate
                  id="responsible_gaming.questions.return"
                  defaultMessage="Return to site"
                />
              </button>
            </div>
          )}
        </div>
      </Popup>
    );
  }
}

export default ResponsibleGamingQuestionsContainer;

import React, { useState, useEffect } from 'react';
import { Button, Translate } from 'tg-core-components';
import marked from 'marked';
import responsibleGamingActions from 'tg-core-redux/lib/modules/responsible_gaming/action';
import { useDispatch } from 'react-redux';
import Loader from '@components/Loader';

import './style.css';

const LowLimits = ({
  /* Props from parent */
  fields,
  sessionId,
  nextStep,
  previousStep,

  /* Props from selector */
  sets,
  sections,
}) => {
  const [popupStep, setPopupStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    const limitsGroupedByType = fields.Limits.reduce(
      (limits, l) => ({
        ...limits,
        [l.Type]: [...(limits[l.Type] || []), l],
      }),
      {}
    );

    const promises = Object.keys(limitsGroupedByType).map(type =>
      dispatch(
        responsibleGamingActions.setMultipleLimits(sessionId, {
          Type: type,
          IsSignupLimit: false,
          Limits: limitsGroupedByType[type].map(l => ({
            Timespan: l.Timespan,
            Amount: l.Amount,
          })),
        })
      )
    );

    setIsLoading(true);

    Promise.all(promises)
      .then(() => nextStep())
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (popupStep > 1) {
      onSubmit();
    }
  }, [popupStep]); // eslint-disable-line

  const headerContent = sections?.find(
    s => s.identifier === 'header.paynplay.low-limit'
  )?.content;
  const popupStepContent = sets?.find(s => s.identifier === 'low-limits-popup');

  if (isLoading) return <Loader inline />;

  return (
    <div className="LowLimits">
      {headerContent && (
        <div
          dangerouslySetInnerHTML={{
            __html: marked(headerContent),
          }}
        />
      )}

      {popupStepContent && (
        <div
          dangerouslySetInnerHTML={{
            __html: marked(popupStepContent?.items?.[popupStep]?.content || ''),
          }}
        />
      )}

      <Button className="button primary" onClick={previousStep}>
        <Translate
          id="label.low-limit.primary-action"
          defaultMessage="Change limit"
        />
      </Button>

      <Button
        className="button link"
        onClick={() => setPopupStep(popupStep + 1)}>
        {popupStep === 0 ? (
          <Translate
            id="label.low-limit.action-1"
            defaultMessage="Continue without action"
          />
        ) : (
          <Translate id="label.low-limit.action-2" defaultMessage="Accept" />
        )}
      </Button>
    </div>
  );
};

const selector = state => ({
  sections: state.content.sections.data,
  sets: state.content.sets.data,
});

export default {
  name: 'low-limits',
  Component: LowLimits,
  selector,
};

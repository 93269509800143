import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { BehaviorSubject } from 'rxjs';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from '../epics';
import array from './middlewares/array';
import promise from './middlewares/promise';
import rootReducer from './reducers';
import { switchMap } from 'rxjs/operators';

const epicMiddleware = createEpicMiddleware();
const epic$ = new BehaviorSubject(rootEpic);

export const configureStore = (initialState, reducer = rootReducer) => {
  const middlewares = [array, thunk, promise];
  if (__CLIENT__) {
    middlewares.push(epicMiddleware);
  }

  if (process.env.NODE_ENV === 'development') {
    const reduxLogger = require('redux-logger');

    const logger = reduxLogger.createLogger({
      collapsed: true,
      predicate: (_, action) => !action.type.includes('PUSH_GTM_EVENT'),
    });

    middlewares.push(logger);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(reducer, initialState, compose(...enhancers));
  if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
      // hot reload redux
      module.hot.accept('./reducers', () =>
        store.replaceReducer(require('./reducers').default)
      );

      // hot reload redux-observable
      module.hot.accept('../epics', () => {
        const rootEpic = require('../epics').rootEpic;
        epic$.next(rootEpic);
      });
    }
  }

  const hotReloadingEpic = (...args) =>
    epic$.pipe(switchMap(epic => epic(...args)));

  epicMiddleware.run(hotReloadingEpic);

  return store;
};

import React from 'react';
import marked from 'marked';

import './style.css';

const Terms = ({ page }) => {
  return (
    <div className="Terms">
      {page?.content && (
        <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
      )}
    </div>
  );
};

export default Terms;

import React, { useState } from 'react';
import { Button, Translate } from 'tg-core-components';
import cn from 'classnames';

import './style.css';

const Currency = ({
  /* Props from parent */
  onChange,
  nextStep,
  fields,

  /* Props from selector */
  currencies,
}) => {
  const [showMore, setShowMore] = useState(false);

  const selectedCurrencyIndex = currencies.value
    .map(c => c.value)
    .indexOf(fields.Currency);

  return (
    <form className="Currency" onSubmit={nextStep}>
      <div className="currencies">
        {[
          currencies.value[selectedCurrencyIndex],
          ...currencies.value.filter(c => c.value !== fields.Currency),
        ]
          .filter(Boolean)
          .slice(0, showMore ? currencies.length : 3)

          .map((c, i) => {
            const isSelected = fields.Currency === c.value;
            return (
              <Button
                autoFocus={isSelected}
                key={i}
                className={cn('tertiary', {
                  selected: isSelected,
                })}
                onClick={() => {
                  onChange({ Currency: c.value });
                }}>
                {c.label}
              </Button>
            );
          })}
      </div>

      {currencies.value.length > 3 && (
        <Button
          type="button"
          className="link show-more"
          onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <Translate id="action.show-less" defaultMessage="Show less" />
          ) : (
            <Translate id="action.show-more" defaultMessage="Show more" />
          )}
        </Button>
      )}
    </form>
  );
};

const selector = state => ({
  currencies: state.content.config.data?.find(c => c.key === 'currency'),
});

export default {
  name: 'currency',
  icon: 'money-bill',
  Component: Currency,
  selector,
  fields: ['Currency'],
};

const initialState = {
  games: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FAVORITES_COMPLETE':
      return { ...state, games: action.payload };

    case 'SIGN_OUT_COMPLETE':
      return { ...state, games: [] };

    default:
      return state;
  }
};

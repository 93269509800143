import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import JackpotWidget from '@components/JackpotWidget';
import http from '@utils/http';
import selectCurrency from '@selectors/selectCurrency';

import './style.css';

export const useJackpotDrop = currency => {
  const timerRef = useRef(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = currency =>
      http
        .get(`https://feed-connect.redtiger.cash/jackpots?currency=${currency}`)
        .then(res => setData(res.data))
        .catch(console.err);

    if (!data) fetchData(currency);

    timerRef.current = setTimeout(() => fetchData(currency), 15000);

    return clearTimeout(timerRef.current);
  }, []);

  return data;
};

const JackpotDrop = () => {
  const sets = useSelector(state => state.content.sets.data);
  const currency = useSelector(selectCurrency);

  const data = useJackpotDrop(currency);

  const pots = data?.result?.jackpots?.[0].pots;

  if (!data || !pots) return null;

  const jackpotSections =
    sets && sets.find(s => s.identifier === 'jackpot-drop-sections')?.items;

  const hasWon = (wonTime, type) => {
    if (type === 'Hourly') return wonTime.getHours() === new Date().getHours();
    if (type === 'Daily') return wonTime.getDate() === new Date().getDate();
  };

  return (
    <div className="JackpotDrop">
      {jackpotSections?.map((s, i) => {
        const pot = pots.find(p => p.name === s.config.type);

        if (!pot) return null;

        const nextJackpotValue = Number(pot.seedNextAmount);
        const previousWinAmount = Number(pot.prevWinAmount);
        const wonTime = new Date(`${pot.time.won}+00:00`);
        const nextDropTime =
          s.config.type !== 'Progressive' &&
          new Date(`${pot.time.target.replace(/ /, 'T')}+00:00`);

        return (
          <JackpotWidget
            value={Number(pot.amount)}
            currency={currency}
            section={s}
            key={i}
            nextDropTime={nextDropTime}
            nextJackpotValue={nextJackpotValue}
            previousWinAmount={previousWinAmount}
            hasBeenWon={hasWon(wonTime, s.config.type)}
          />
        );
      })}
    </div>
  );
};

export default JackpotDrop;

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withUrls } from '@utils/urls';
import compose from 'recompose/compose';
import hoistNonReactStatic from 'hoist-non-react-statics';

export default function(ComposedComponent, state) {
  class Authenticate extends Component {
    render() {
      const {
        isAuthenticated,
        urls: { signIn },
      } = this.props;

      if (!isAuthenticated)
        return (
          <Redirect
            to={signIn({
              pathname: state?.redirectUrl,
              state: {
                referrer: __CLIENT__
                  ? window.routerHistory.location.pathname +
                    window.routerHistory.location.hash
                  : this.props.location.pathname,
              },
            })}
          />
        );

      return <ComposedComponent {...this.props} />;
    }
  }

  return compose(
    connect(state => ({
      isAuthenticated: state.player.isAuthenticated,
    })),
    withUrls
  )(hoistNonReactStatic(Authenticate, ComposedComponent));
}

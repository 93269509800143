import { getConfig } from '@utils/config';

const selectUnreadNotifications = state => {
  const unreadInternalMessages = selectUnreadInternalMessages(state);

  return {
    internalMessages: unreadInternalMessages,
  };
};

export default selectUnreadNotifications;

const selectUnreadInternalMessages = state => {
  return getConfig(state.app.jurisidction, state.app.ipCountry).imProvider ===
    'fasttrack'
    ? 0
    : state.internalMessages.data.filter(m => m.Status !== 'Read').length;
};

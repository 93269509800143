import React, { useState, useContext, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { Button, Translate, Icon } from 'tg-core-components';
import {
  getTopGames,
  getTopGamesWager,
  getTopGamesUWC,
} from 'tg-core-redux/lib/modules/event/action';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import CasinoCategoryMenu from '@components/CasinoCategoryMenu';
import MultipleVideos from '@components/MultipleVideos';
import {
  getNavbarItems,
  getGamesByCategory,
  getFuseSearch,
} from '@utils/casino';
import marked from 'marked';
import GameGrid, { selectGameGridProps } from '@components/GameGrid';
import NoGamesMessage from '@components/NoGamesMessage';
import { useLowBalancePopup, useFullscreen } from '@utils/hooks';
import FavoriteHeart from '@components/FavoriteHeart';
import selectGames from '@selectors/selectGames';
import selectLastPlayedGames from '@selectors/selectLastPlayedGames';
import selectFavoriteGames from '@selectors/selectFavoriteGames';
import selectTopGames from '@selectors/selectTopGames';
import selectFeatureDetect from '@selectors/selectFeatureDetect';
import {
  GamePlayContext,
  setIsBottomBarOpen,
  toggleDarkMode,
  setSearchTerm,
  setIsSearchOpen,
  setBottomBarCategory,
} from '../context';
import Footer, { selectFooterProps } from '@components/Footer';
import { withConfig } from '@utils/config';
import { withUrls } from '@utils/urls';
import { getLobbyUrl, isFavorite } from '@utils/game';

import './style.css';

export const selectGameDetailsProps = state => ({
  games: selectGames(state),
  sets: state.content.sets.data,
  isAuthenticated: state.player.isAuthenticated,
  jurisdiction: state.app.jurisdiction,
  featureDetect: selectFeatureDetect(state),
  gameGridProps: selectGameGridProps(state),
  footerProps: selectFooterProps(state),
  lastPlayedGames: selectLastPlayedGames(state),
  favoriteGames: selectFavoriteGames(state),
  topGames: selectTopGames(state),
});

const GameDetails = ({
  /* Props from parent */
  game,
  page,
  toggleFavoriteGameById,

  /* Props from selector */
  games,
  sets,
  isAuthenticated,
  featureDetect,
  lastPlayedGames,
  favoriteGames,
  topGames,

  /* withRouter */
  location,
  history,

  /* withConfig */
  config,

  /* withUrls */
  urls,

  gameGridProps,
  footerProps,
}) => {
  const { state, dispatch } = useContext(GamePlayContext);
  const fuseRef = useRef(null);
  const globalDispatch = useDispatch();

  const lowBalancePopup = useLowBalancePopup({
    goToGame: () => {
      dispatch(setIsBottomBarOpen(false));
      lowBalancePopup.close();
    },
    location,
    history,
  });

  useEffect(() => {
    const hasTopGamesCategory = sets
      .find(s => s.identifier === 'casino-navbar-gameplay')
      ?.items.find(i => i.identifier === 'top-games');

    if (hasTopGamesCategory) {
      Promise.all([
        globalDispatch(getTopGames()),
        globalDispatch(getTopGamesWager()),
        globalDispatch(getTopGamesUWC()),
      ]);
    }
  }, []);

  useEffect(() => {
    fuseRef.current = getFuseSearch(games);
  }, [games]);

  const { toggleFullscreen, isFullscreen } = useFullscreen();

  const allVideos = [game.preview, game.video, game.videos || []]
    .map(video => video?.file)
    .filter(v => v);

  const isFavoriteGame = isFavorite(
    favoriteGames,
    game.backendId,
    game.backendTableId
  );

  const navbarItems = getNavbarItems({
    navbar:
      (game.isLive &&
        sets?.find(s => s.identifier === 'livecasino-navbar-gameplay')) ||
      sets?.find(s => s.identifier === 'casino-navbar-gameplay'),
    games,
    hasLastPlayedGames: lastPlayedGames.length > 0,
    favoriteGames,
  });

  const activeNavbarItem =
    navbarItems.find(c => c.identifier === state.bottomBarCategory) || {};

  const categoryGames = getGamesByCategory(
    games,
    state.bottomBarCategory,
    lastPlayedGames,
    favoriteGames,
    activeNavbarItem?.config,
    topGames
  );
  const searchGames = fuseRef.current
    ?.search(state.searchTerm)
    .map(game => game.item);
  const allGames = state.searchTerm.length > 0 ? searchGames : categoryGames;

  const addOnClick = item => ({
    ...item,
    to: '',
    onClick: e => {
      e.preventDefault();
      dispatch(setBottomBarCategory(item.identifier));
      dispatch(
        setIsBottomBarOpen(
          // Close bottom bar when click active category item
          state.isGameLaunched &&
            state.isBottomBarOpen &&
            state.bottomBarCategory === item.identifier
            ? false
            : true
        )
      );
    },
    items: item.items ? item.items.map(addOnClick) : null,
  });
  const navbarWithOnClick = navbarItems.map(addOnClick);

  return (
    <div className="GameDetails">
      {!state.isGameLaunched && (allVideos.length > 0 || game.thumbnail) && (
        <div className="GameDetails__promo">
          {featureDetect.renderVideos && allVideos.length > 0 ? (
            <div className="GameDetails__promo-video">
              <MultipleVideos
                videos={allVideos}
                muted
                loop
                autoPlay
                playsInline
              />
            </div>
          ) : (
            <div
              className="GameDetails__promo-img"
              style={{
                backgroundImage: `url(${game.thumbnail?.file.url ||
                  '/images/default_game_bg.jpg'}`,
              }}
            />
          )}
        </div>
      )}

      {!state.isGameLaunched && (
        <div className="GameDetails__actions">
          {isAuthenticated && (
            <Button
              className="button primary wide play"
              onClick={() =>
                lowBalancePopup.status === 'NOT_CONFIRMED'
                  ? lowBalancePopup.open()
                  : dispatch(setIsBottomBarOpen(false))
              }>
              <Translate id="action.play-game" defaultMessage="Play game" />
            </Button>
          )}
          {!isAuthenticated && !game.isLive && (
            <Button
              className="button tertiary wide play"
              onClick={() => dispatch(setIsBottomBarOpen(false))}>
              <Translate id="action.play-demo" defaultMessage="Play demo" />
            </Button>
          )}
          {!isAuthenticated && (
            <Link
              to={urls.signUp({
                search: config.isPnp ? `ref_url=/play/${game.url}` : null,
              })}
              className="button primary wide">
              <Translate id="action.play-real" defaultMessage="Play for real" />
            </Link>
          )}
        </div>
      )}

      {!state.isGameLaunched &&
        (game.subprovider || game.provider || game.name || game.rtp) && (
          <div className="GameDetails__info">
            {(game.subprovider || game.provider) && (
              <div className="GameDetails__provider">
                {game.subprovider || game.provider}
              </div>
            )}
            {game.name && (
              <div className="GameDetails__name-and-fav">
                <h1 className="GameDetails__name">{game.name}</h1>
                {isAuthenticated && (
                  <Button className="GameDetails__fav button icon">
                    <FavoriteHeart
                      isFavorite={isFavoriteGame}
                      onClick={() =>
                        toggleFavoriteGameById(
                          game.backendId,
                          game.backendTableId
                        )
                      }
                    />
                  </Button>
                )}
              </div>
            )}
            {game.rtp && (
              <div className="GameDetails__rtp">
                <Translate
                  id="label.rtp"
                  defaultMessage="{value}% RTP"
                  values={{ value: game.rtp }}
                />
              </div>
            )}
          </div>
        )}

      {!state.isGameLaunched && page.content && (
        <ExpandableSection className="GameDetails__page-content">
          <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
        </ExpandableSection>
      )}

      <div className="GameDetails__categories-nav">
        <CasinoCategoryMenu
          items={navbarWithOnClick}
          activeCategory={state.isBottomBarOpen && state.bottomBarCategory}
          searchTerm={state.searchTerm}
          onSearchChange={searchTerm => dispatch(setSearchTerm(searchTerm))}
          isSearchOpen={state.isSearchOpen}
          onToggleSearch={() => {
            dispatch(setIsBottomBarOpen(true));
            dispatch(setIsSearchOpen(!state.isSearchOpen));
            dispatch(setSearchTerm(''));
          }}
          showSearch
        />

        <div className="GameDetails__quick-actions">
          {isAuthenticated && (
            <Button className="GameDetails__fav button icon">
              <FavoriteHeart
                isFavorite={isFavoriteGame}
                onClick={() =>
                  toggleFavoriteGameById(game.backendId, game.backendTableId)
                }
              />
            </Button>
          )}
          <Button
            className="GameDetails__lightbulb button icon"
            onClick={() => dispatch(toggleDarkMode())}>
            <Icon icon={state.isDarkMode ? 'lightbulb-off' : 'lightbulb-on'} />
          </Button>
          <Button
            className="GameDetails__fullscreen button icon"
            onClick={toggleFullscreen}>
            <Icon icon={isFullscreen ? 'compress' : 'expand'} />
          </Button>
        </div>
      </div>

      <div className="GameDetails__category">
        {allGames.length === 0 && (
          <NoGamesMessage
            linkLocation={{
              state: { bottomBarCategory: navbarItems[0]?.identifier },
            }}
            onClickLink={() => {
              dispatch(setSearchTerm(''));
              dispatch(setIsSearchOpen(false));
              dispatch(setBottomBarCategory(navbarItems[0]?.identifier));
            }}
          />
        )}
        <GameGrid
          key={`${activeNavbarItem.identifier}_${state.searchTerm}`}
          overview
          items={allGames.length > 0 ? allGames : games}
          categoryId={activeNavbarItem.identifier}
          type={activeNavbarItem?.config?.type}
          toggleFavoriteGameById={toggleFavoriteGameById}
          baseUrl={getLobbyUrl(game, location)}
          isSearchOpen={state.isSearchOpen}
          {...gameGridProps}
        />
      </div>

      {!state.isGameLaunched && <Footer {...footerProps} />}
    </div>
  );
};

const ExpandableSection = ({ children, className }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div
      className={cn(className, 'ExpandableSection', {
        'ExpandableSection--is-expanded': isExpanded,
      })}
      onClick={() => setIsExpanded(true)}>
      {children}
    </div>
  );
};

export default withUrls(withConfig(withRouter(GameDetails)));

import React from 'react';
import marked from 'marked';
import OffCanvasLayout from '@components/OffCanvasLayout';

import './style.css';

const BlockComponent = ({ className, content }) => (
  <OffCanvasLayout className={`${className ? className : ''}`}>
    <div className="BlockComponent__blocked">
      <div
        className="BlockComponent__content"
        dangerouslySetInnerHTML={{
          __html: marked(content || ''),
        }}
      />
    </div>
  </OffCanvasLayout>
);

export default BlockComponent;

import React, { Component } from 'react';
import { signOut } from 'tg-core-redux/lib/modules/player/action';
import { getTableGamesSummary } from 'tg-core-redux/lib/modules/responsible_gaming/action';
import { connect } from 'react-redux';
import { Translate } from 'tg-core-components';
import bg from '@images/error-bg.jpg';
import Loader from '@components/Loader';
import withRouteData from '@utils/withRouteData';
import marked from 'marked';
import './style.css';

const PostSessionSummary = ({ data }) => (
  <div className="PostSessionSummary">
    <div className="table-wrapper">
      <table className="table">
        <thead>
          <tr>
            <th>
              <Translate id="label.game" defaultMessage="Game" />
            </th>
            <th>
              <Translate id="label.wins" defaultMessage="Win" />
            </th>
            <th>
              <Translate id="label.wager" defaultMessage="Wager" />
            </th>
          </tr>
        </thead>

        <tbody>
          {data.map(row => (
            <tr key={row.GameName}>
              <td>{row.GameName}</td>
              <td>{row.TotalWin}</td>
              <td>{row.TotalWager}</td>
            </tr>
          ))}

          {data.length === 0 && (
            <tr>
              <td colSpan="4">
                <p>
                  <Translate id="list.no_data" defaultMessage="No data" />
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
);

@withRouteData
@connect(
  state => ({
    sessionId: state.player.sessionId,
    jurisdiction: state.app.jurisdiction,
    pages: state.content.pages.data,
    isLoading: state.responsible_gaming.isLoading,
    tableGamesSummary: state.responsible_gaming.tableGamesSummary,
  }),
  {
    signOut,
    getTableGamesSummary,
  }
)
class SignOutContainer extends Component {
  componentDidMount() {
    const {
      getTableGamesSummary,
      signOut,
      jurisdiction,
      sessionId,
      history,
    } = this.props;

    if (!sessionId) window.location.href = '/';

    if (jurisdiction !== 'es')
      return signOut(sessionId).then(() => history.push('/'));

    // Get data for table.
    getTableGamesSummary(sessionId).then(() => {
      signOut(sessionId);
    });
  }

  render() {
    const { pages, jurisdiction, isLoading, tableGamesSummary } = this.props;
    const page =
      pages && pages.find(p => p.identifier === 'dashboard-sign-out');
    if (jurisdiction === 'es') {
      <div
        className="SignOutContainer"
        style={{ backgroundImage: `url(${bg})` }}>
        <div className="section">
          {isLoading && tableGamesSummary.length === 0 && <Loader inline />}

          {!isLoading && page && (
            <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
          )}
          {!isLoading && <PostSessionSummary data={tableGamesSummary || []} />}

          <a className="button primary small" href="/sign-in">
            <Translate id="action.signin" defaultMessage="Log in" />
          </a>

          <a className="button tertiary small" href="/">
            <Translate id="action.gotohome" defaultMessage="Return to home" />
          </a>
        </div>
      </div>;
    }

    return <Loader />;
  }
}

export default SignOutContainer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withCookies } from 'react-cookie';
import { Loader } from 'tg-core-components';
import { getConfig } from '@config';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import bonusActions from 'tg-core-redux/lib/modules/bonus/action';
import queryString from 'query-string';
import BasePage from '@pages/BasePage';
import withRouteData from '@utils/withRouteData';

import Correct from './Correct';
import Wrong from './Wrong';
import Question from './Question';
import About from './About';
import Calendar from './Calendar';
import Terms from './Terms';
import Navigation from './Navigation';
import Header from './Header';

import './style.css';

export const PAGES = {
  ABOUT: 'event-calendar-about',
  CALENDAR: 'event-calendar-calendar',
  TERMS: 'event-calendar-terms',
  QUESTION: 'event-calendar-question',
  WRONG: 'event-calendar-wrong',
  CORRECT: 'event-calendar-correct',
};

export const identifierToPage = identifier =>
  Object.keys(PAGES).find(k => PAGES[k] === identifier);

@withRouteData
@withCookies
@connect(
  state => ({
    menus: state.content.menus,
    pages: state.content.pages,
    sets: state.content.sets,
    sections: state.content.sections,
    player: state.player,
    jurisdiction: state.app.jurisdiction,
  }),
  dispatch => ({
    tournamentOptIn: bindActionCreators(
      playerActions.tournamentOptIn,
      dispatch
    ),
    bonusOptIn: bindActionCreators(playerActions.bonusOptIn, dispatch),
    triggerBonusByPromoCode: bindActionCreators(
      bonusActions.triggerBonusByPromoCode,
      dispatch
    ),
    getTags: bindActionCreators(playerActions.getTags, dispatch),
    addTag: bindActionCreators(playerActions.addTag, dispatch),
    removeTag: bindActionCreators(playerActions.removeTag, dispatch),
  })
)
class EventCalendarContainer extends Component {
  state = {
    currentPage: PAGES.CALENDAR,
    day: null,
    isLoading: true,
    playerTags: null,
  };

  componentDidMount = async () => {
    const { cookies, location } = this.props;

    if (cookies.get('visited_event_calendar')) {
      this.setState({ currentPage: PAGES.CALENDAR });
    }

    this.getPlayerTags();

    // Routing with query url (quick fix)
    const query = queryString.parse(location?.search);
    const queryKeys = Object.keys(query);
    if (queryKeys.includes('toc') || queryKeys.includes('terms')) {
      this.setPage(PAGES.TERMS);
    }

    this.setState({ isLoading: false });
  };

  componentDidUpdate = prevProps => {
    const { player } = this.props;

    if (player.sessionId !== prevProps.player.sessionId) {
      this.getPlayerTags();
    }
  };

  getPlayerTags = () => {
    const { player, getTags } = this.props;

    if (player?.isAuthenticated && player?.sessionId) {
      return getTags(player.sessionId).then(response => {
        const playerTags = response?.data?.map(tag => tag.Identifier);
        this.setState({ playerTags });
      });
    }
  };

  toggleSubscription = async tag => {
    const { removeTag, addTag, player } = this.props;
    const { playerTags } = this.state;

    const promises = [];

    if (playerTags?.includes(tag)) {
      // Unsubscribe
      promises.push(removeTag(tag, player.sessionId));
      promises.push(addTag(`${tag}-out`, player.sessionId));
    } else {
      // Subscribe
      promises.push(addTag(tag, player.sessionId));
      promises.push(removeTag(`${tag}-out`, player.sessionId));
    }

    return Promise.all(promises).then(this.getPlayerTags);
  };

  onClickAboutCta = e => {
    const { cookies } = this.props;

    e.preventDefault();
    this.setPage(PAGES.CALENDAR);
    cookies.set('visited_event_calendar', true, {
      secure: getConfig().CURRENT_ENV === 'prod',
      path: '/',
    });
  };

  onClickWrongAnswer = e => {
    e.preventDefault();
    this.props.cookies.set(
      'event_calendar_question_state_' + new Date().getDate(),
      'wrong',
      {
        expires: new Date(new Date().getTime() + 2 * 86400000), // expires in 48 hours.
        secure: getConfig().CURRENT_ENV === 'prod',
        path: '/',
      }
    );
    this.setPage(PAGES.WRONG);
  };

  onClickCorrectAnswer = e => {
    const { day } = this.state;
    const {
      player,
      tournamentOptIn,
      bonusOptIn,
      triggerBonusByPromoCode,
      cookies,
    } = this.props;

    e?.preventDefault();
    cookies.set(
      'event_calendar_question_state_' + new Date().getDate(),
      'correct',
      {
        expires: new Date(new Date().getTime() + 2 * 86400000), // expires in 48 hours.
        secure: getConfig().CURRENT_ENV === 'prod',
        path: '/',
      }
    );

    if (day) {
      day.tournamentId && tournamentOptIn(player.user.Id, day.tournamentId);
      day.bonusId &&
        bonusOptIn(player.user.Id, Number(day.bonusId), player.sessionId);
      day.promoCode && triggerBonusByPromoCode(player.sessionId, day.promoCode);
    }

    this.setPage(PAGES.CORRECT, day);
  };

  onClickDay = ({ day, isBeforeToday, isToday }) => {
    const { player } = this.props;

    if (isBeforeToday) {
      return;
    }

    if (player && !player.isAuthenticated && day?.config?.requireSignIn) {
      return this.redirectToSignin();
    }

    if (isToday) {
      __CLIENT__ && window.scrollTo(0, 0);
      if (day.question) this.setPage(PAGES.QUESTION, day);
      else this.setState({ day }, () => this.onClickCorrectAnswer());
    }
  };

  setPage = (page, day = null) => {
    const { cookies } = this.props;

    if (page === PAGES.QUESTION) {
      const questionState = cookies.get(
        'event_calendar_question_state_' + new Date().getDate()
      );
      if (questionState === 'wrong') {
        page = PAGES.WRONG;
      } else if (questionState === 'correct') {
        page = PAGES.CORRECT;
      }
    }

    this.setState({
      currentPage: page,
      day,
    });
  };

  redirectToSignin = () => {
    window.routerHistory.push({
      hash: 'sign-in',
      state: { referrer: '/calendar' },
    });
  };

  getActiveComponent = () => {
    const { currentPage } = this.state;

    switch (currentPage) {
      case PAGES.ABOUT:
        return About;
      case PAGES.CALENDAR:
        return Calendar;
      case PAGES.TERMS:
        return Terms;
      case PAGES.QUESTION:
        return Question;
      case PAGES.WRONG:
        return Wrong;
      case PAGES.CORRECT:
        return Correct;
    }
  };

  render() {
    const { menus, pages, sets, sections, isRouteDataLoading } = this.props;
    const {
      currentPage,
      day,
      isLoading,
      playerTags,
      playerIsSneaky,
    } = this.state;

    const navigationItems = menus?.data?.find(
      m => m.identifier === 'event-calendar-navigation'
    );
    const page = pages?.data?.find(p => p.identifier === currentPage);
    const contentPage = pages?.data?.find(
      p => p.identifier === 'event-calendar-calendar'
    );
    const calendarHeader = sections?.data?.find(
      s => s.identifier === 'event-calendar-header'
    );
    const calendarEventDays = sets?.data?.find(
      s => s.identifier === 'event-calendar-days'
    );

    const ActiveComponent = this.getActiveComponent();

    if (isLoading) {
      return (
        <div className="EventCalendarContainer">
          <div className="event-calendar-content">
            <Loader inline />
          </div>
        </div>
      );
    }

    return (
      <BasePage
        page={contentPage}
        className="EventCalendarContainer"
        showLoading={isRouteDataLoading}>
        <div className="container">
          <Navigation
            items={navigationItems?.items}
            setPage={this.setPage}
            currentPage={currentPage}
          />

          {calendarHeader && <Header {...calendarHeader} />}

          <div className="event-calendar-content">
            <ActiveComponent
              page={page}
              setPage={this.setPage}
              calendarEventDays={calendarEventDays}
              day={day}
              onClickAboutCta={this.onClickAboutCta}
              onClickDay={this.onClickDay}
              onClickWrongAnswer={this.onClickWrongAnswer}
              onClickCorrectAnswer={this.onClickCorrectAnswer}
              toggleSubscription={this.toggleSubscription}
              playerTags={playerTags}
              playerIsSneaky={playerIsSneaky}
              {...this.props}
            />
          </div>
        </div>
      </BasePage>
    );
  }
}

export default EventCalendarContainer;

import _PaymentIQCashier from 'paymentiq-cashier-bootstrapper';

export const initCashier = options => {
  const {
    cashierRef,
    method,
    config,
    user,
    sessionId,
    locale,
    onReady,
    onSuccess,
    onFailure,
  } = options;

  const { css, predefinedAmounts = {}, ...cashierConfig } = config.piqCashier;

  const _predefinedAmounts =
    predefinedAmounts[
      Object.keys(predefinedAmounts).find(
        key => key.toLocaleLowerCase() === user.Currency.toLocaleLowerCase()
      )
    ];

  const cashierApi = new _PaymentIQCashier(
    '#cashier',
    {
      merchantId: config.MERCHANT_ID,
      userId: user.Id,
      sessionId,
      environment: config.CURRENT_ENV === 'dev' ? 'test' : 'production',
      method,
      country: user.Country,
      locale,
      newPaymentBtn: true,
      showBonusCode: false,
      ...(Array.isArray(_predefinedAmounts) && {
        predefinedAmounts: _predefinedAmounts,
      }),
      checkWithdrawalBalance: true,
      ...cashierConfig,
      predefinedValues: method !== 'withdrawal',
      blockBrowserNavigation: false,
    },
    api => {
      api.on({
        cashierInitLoad: () => {
          onReady?.();
          cashierRef.current.style.visibility = 'visible';
        },
        success: transaction => onSuccess?.(transaction),
        failure: transaction => onFailure?.(transaction),
      });

      if (css) {
        api.css(css);
      }
    }
  );

  return cashierApi;
};

import React from 'react';
import { Translate } from 'tg-core-components';

export const timeToPeriod = ({ time, type }) => {
  const periods = {
    0: { id: 'label.each_transaction', defaultMessage: 'Each transation' },
    1: { id: 'label.each_day', defaultMessage: 'Each day' },
    7: { id: 'label.each_week', defaultMessage: 'Each week' },
    30: { id: 'label.each_month', defaultMessage: 'Each month' },
  };

  if (type === 'Session')
    return (
      <Translate id="label.each_session" defaultMessage="Each session">
        {msg => msg}
      </Translate>
    );

  return (
    periods[time] && <Translate {...periods[time]}>{msg => msg}</Translate>
  );
};

import { configs, getConfig } from '@config';

export const jurisdictions = Object.keys(configs);

export const getJurisdiction = ({
  contentPreviewJurisdiction,
  userJurisdiction,
  ipCountry,
}) => {
  contentPreviewJurisdiction = (contentPreviewJurisdiction || '').toLowerCase();
  userJurisdiction = (userJurisdiction || '').toLowerCase();
  ipCountry = (ipCountry || '').toLowerCase();

  if (jurisdictions.includes(contentPreviewJurisdiction)) {
    return contentPreviewJurisdiction;
  }

  if (userJurisdiction) {
    return userJurisdiction;
  }

  const ipCountryJurisdiction = jurisdictions.find(jurisdiction =>
    getConfig(jurisdiction, ipCountry).countries?.includes(ipCountry)
  );
  if (ipCountryJurisdiction) {
    return ipCountryJurisdiction;
  }

  return getConfig(null, ipCountry).defaultJurisdiction;
};

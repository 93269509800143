import { getConfig } from '@config';

const selectCurrency = state => {
  const countries = state.content.config.data.find(c => c.key === 'countries');
  const countryCurrency = countries?.value.find(
    c => c.value === state.app.ipCountry
  )?.currency;
  return (
    state.player.user?.Currency ||
    countryCurrency ||
    getConfig(state.app.jurisdiction, state.app.ipCountry).defaultCurrency ||
    'EUR'
  );
};

export default selectCurrency;

import React from 'react';
import { getConfig } from '@config';
import IndexContainerPnp from '@pages/IndexContainerPnp';
import IndexContainerAccount from '@pages/IndexContainerAccount';
import CasinoContainer from '@pages/CasinoContainer';
import PromotionContainer from '@pages/PromotionsContainer';
import PromotionDetailContainer from '@pages/PromotionDetailContainer';
import TournamentContainer from '@pages/TournamentsContainer';
import TournamentDetailContainer from '@pages/TournamentDetailContainer';
import TermsContainer from '@pages/TermsContainer';
import ContentContainer from '@pages/ContentContainer';
import SignOutContainer from '@pages/SignOutContainer';
import PlayContainer from '@pages/PlayContainer';
import SportsContainer from '@pages/SportsContainer';
import SubscriptionContainer from '@pages/SubscriptionContainer';
import { loadContents } from '@actions/content';
import deviceDetect from '@utils/deviceDetect';
import * as contentUtils from '@utils/content';
import { STATE as IndexState } from '@pages/IndexContainerPnp';
import JackpotDropContainer from '@pages/JackpotDropContainer';
import MigrationContainer from '@pages/MigrationContainer';
import EventCalendarContainer from '@pages/EventCalendarContainer';
import BingoContainer from '@pages/BingoContainer';
import InstantDepositContainer from '@pages/InstantDepositContainer';
import AppDashboard from './pages/AppDashboard';

const commonPages = ['tournaments', 'sports', 'error-page'];

const commonSets = [
  'footer-social-icons',
  'footer-licence-icons',
  'footer-game-logos',
  'footer-nav',
  'footer-payment-logos',
  'responsible-gaming-signup-new',
  'game-details-categories',
  'agreements',
  'terms-conditions',
];

const commonSections = [
  'footer-promotion',
  'footer-licence-content',
  'footer-rights-content',
  'session-summary-info',
  'session-summary-header',
  'cookie-information',
  'popup-restricted-access',
  'popup-multiple-locales',
  'canvas-header',
  'wheel-notice',
  'install-app-prompt',

  'header.paynplay.amount',
  'header.paynplay.auth',
  'header.paynplay.limits',
  'header.paynplay.details',
  'header.paynplay.error',
  'header.paynplay.consent',
  'header.paynplay.limits-prompt',
  'header.paynplay.low-limit',
  'header.paynplay.deposit',
  'pending-withdrawal-info',
  'description.paynplay.communication-consent',

  'index-brand-description',
  'custom-footer-section',
  'global-warning',

  'deposit-step-SELECT_PAYMENT_METHOD-footer',
  'withdraw-step-SELECT_PAYMENT_METHOD-footer',

  'block-feature-sign-in',
  'block-feature-sign-up',
  'block-feature-casino',
  'block-feature-sportsbook',
  'block-feature-deposit',

  'low-balance-popup',
];

const indexSets = [
  'casino-navbar',
  'index-features',
  'index-banners',
  'index-background-banners',
  'index-banners-first-visit',
  'payment-provider-logos',
  'casino-provider-logos',
  'low-limits-popup',
  ...commonSets,
];

export const getGameSegment = (match, store, options) => {
  const device = __CLIENT__
    ? deviceDetect(window.navigator.userAgent)
    : options.device;

  return contentUtils.getGameSegment(device);
};

const withStateProp = (state, BaseComponent) => {
  return class Enhancer extends React.Component {
    render() {
      return <BaseComponent {...this.props} state={state} />;
    }
  };
};

const indexContainers = {
  IndexContainerAccount,
  IndexContainerPnp,
  SportsContainer,
};

const FetchBalanceContainer = withStateProp(
  IndexState.SIGNIN,
  IndexContainerPnp
);

const StartPlayingContainer = withStateProp(
  IndexState.DEPOSIT,
  IndexContainerPnp
);

export default (jurisdiction, ipCountry) => {
  const isPnp = getConfig(jurisdiction, ipCountry).isPnp;

  const IndexContainer =
    indexContainers[getConfig(jurisdiction, ipCountry).indexContainer];

  const depositType = getConfig(jurisdiction, ipCountry).depositType;

  const pnpRoutes = [
    {
      path: '/',
      exact: true,
      component: IndexContainer,
      loadData: loadContents([
        {
          name: 'page',
          identifiers: ['start-page', ...commonPages],
        },
        {
          name: 'set',
          identifiers: [...indexSets, 'index-page-sections'],
          include_depth: 5,
        },
        {
          name: 'section',
          identifiers: ['index-extra-info', ...commonSections],
        },
        {
          name: 'game',
          limit: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'game',
          limit: 1000,
          skip: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'config',
        },
        {
          name: 'tournament',
        },
        {
          name: 'bonus',
        },
      ]),
    },
    {
      path: '/fetch-balance',
      exact: true,
      component: FetchBalanceContainer,
      loadData: loadContents([
        {
          name: 'page',
          identifiers: ['start-page', ...commonPages],
        },
        {
          name: 'set',
          identifiers: [...indexSets, 'index-page-sections'],
          include_depth: 5,
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
        {
          name: 'game',
          limit: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'game',
          limit: 1000,
          skip: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'bonus',
        },
      ]),
    },
    {
      path: '/start-playing',
      exact: true,
      component: StartPlayingContainer,
      loadData: loadContents([
        {
          name: 'page',
          identifiers: ['start-page', ...commonPages],
        },
        {
          name: 'set',
          identifiers: [...indexSets, 'index-page-sections'],
          include_depth: 5,
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
        {
          name: 'bonus',
        },
      ]),
    },
  ];

  const accountRoutes = [
    {
      path: '/',
      exact: true,
      component: IndexContainer,
      data: { reload: true },
      loadData: loadContents([
        {
          name: 'page',
          identifiers: ['index', 'start-page', ...commonPages],
        },
        {
          name: 'game',
          limit: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'game',
          limit: 1000,
          skip: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'set',
          identifiers: ['index-page-sections', ...indexSets],
          include_depth: 7,
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
      ]),
    },
  ];

  const commonRoutes = [
    {
      path: '/_app',
      component: AppDashboard,
    },
    {
      path: '/sports/:sport?/:league?/:date?/:event?',
      exact: true,
      component: SportsContainer,
      loadData: loadContents([
        {
          name: 'page',
          urls: ['sports', 'sports/live-betting', ...commonPages],
        },
        {
          name: 'set',
          identifiers: commonSets,
        },
        {
          name: 'section',
          identifiers: [...commonSections, 'product-block-section'],
        },
      ]),
    },
    {
      path: '/live-betting/:sport?/:league?/:date?/:event?',
      exact: true,
      component: SportsContainer,
      loadData: loadContents([
        {
          name: 'page',
          urls: ['sports', 'sports/live-betting', ...commonPages],
        },
        {
          name: 'set',
          identifiers: commonSets,
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
      ]),
    },
    {
      path: '/terms-and-conditions',
      exact: true,
      component: TermsContainer,
      loadData: loadContents([
        {
          name: 'page',
          identifiers: ['terms-and-conditions', ...commonPages],
        },
        {
          name: 'set',
          identifiers: ['terms-conditions', ...commonSets],
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
      ]),
    },
    {
      path: '/jackpot-drop',
      exact: true,
      component: JackpotDropContainer,
      loadData: loadContents([
        {
          name: 'page',
          identifiers: ['jackpot-drop', ...commonPages],
        },
        {
          name: 'set',
          identifiers: [
            ...commonSets,
            'jackpot-drop-sections',
            'jackpot-drop-games',
          ],
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
        {
          name: 'config',
        },
      ]),
    },
    {
      path: '/casino/:category?',
      exact: true,
      component: CasinoContainer,
      scrollToTop: false,
      loadData: loadContents([
        {
          name: 'game',
          limit: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'game',
          limit: 1000,
          skip: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'page',
          urls: () => {
            return 'casino';
          },
        },
        {
          name: 'set',
          identifiers: [
            'casino-navbar',
            'casino-banners',
            'casino-index-categories',
            'casino-pre-index-categories',
            'jackpot-drop-sections',
            ...indexSets,
          ],
          include_depth: 5,
        },
        {
          name: 'section',
          identifiers: [
            ...commonSections,
            'inline-pnp',
            'product-block-section',
          ],
        },
        {
          name: 'page',
          identifiers: commonPages,
        },
        {
          name: 'config',
        },
      ]),
    },
    {
      path: '/live-casino/:category?',
      exact: true,
      component: CasinoContainer,
      scrollToTop: false,
      loadData: loadContents([
        {
          name: 'game',
          limit: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'game',
          limit: 1000,
          skip: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'page',
          urls: () => {
            return ['live-casino'];
          },
        },
        {
          name: 'set',
          identifiers: [
            'live-casino-navbar',
            'live-casino-banners',
            'live-casino-index-categories',
            'live-casino-pre-index-categories',
            ...indexSets,
          ],
          include_depth: 5,
        },
        {
          name: 'section',
          identifiers: [
            ...commonSections,
            'inline-pnp',
            'product-block-section',
          ],
        },
        {
          name: 'page',
          identifiers: commonPages,
        },
        {
          name: 'config',
        },
      ]),
    },
    {
      path: '/promotions/:category?',
      exact: true,
      component: PromotionContainer,
      loadData: loadContents([
        {
          name: 'promotion',
          order: '-fields.sortOrder',
        },
        {
          name: 'promotionCategory',
        },
        {
          name: 'page',
          urls: ['promotions'],
          include_depth: 5,
        },
        {
          name: 'set',
          identifiers: ['promotions-navbar', ...commonSets],
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
      ]),
    },
    {
      path: '/promotion/:promotion',
      exact: true,
      component: PromotionDetailContainer,
      loadData: loadContents([
        {
          name: 'promotion',
          urls: match => {
            return [match.params.promotion];
          },
          include_depth: 5,
        },
        {
          name: 'page',
          identifiers: commonPages,
        },
        {
          name: 'set',
          identifiers: commonSets,
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
      ]),
    },
    {
      path: '/tournaments',
      exact: true,
      component: TournamentContainer,
      loadData: loadContents([
        {
          name: 'page',
          urls: match => {
            return [match.url.slice(1)];
          },
          include_depth: 5,
        },
        {
          name: 'set',
          identifiers: commonSets,
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
      ]),
    },
    {
      path: '/tournaments/:tournament',
      exact: true,
      component: TournamentDetailContainer,
      loadData: loadContents([
        {
          name: 'set',
          identifiers: commonSets,
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
      ]),
    },
    {
      path: '/sign-out',
      exact: true,
      component: SignOutContainer,
      loadData: loadContents([
        {
          name: 'page',
          identifiers: ['dashboard-sign-out', ...commonPages],
        },
        {
          name: 'set',
          identifiers: commonSets,
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
      ]),
    },
    {
      path: '/play/:slug',
      exact: true,
      component: PlayContainer,
      loadData: loadContents([
        {
          name: 'game',
          limit: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'game',
          limit: 1000,
          skip: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'page',
          urls: match => {
            return [match.url.slice(1)];
          },
        },
        {
          name: 'set',
          identifiers: [
            ...commonSets,
            'casino-navbar-gameplay',
            'livecasino-navbar-gameplay',
          ],
        },
        {
          name: 'section',
          identifiers: [
            ...commonSections,
            'product-block-section',
            'deposit-warning-popup',
          ],
        },
        {
          name: 'config',
        },
      ]),
    },
    {
      path: '/subscriptions',
      component: SubscriptionContainer,
      loadData: loadContents([
        {
          name: 'set',
          identifiers: ['subscriptions', ...commonSets],
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
      ]),
    },
    {
      path: '/event-calendar',
      component: EventCalendarContainer,
      scrollToTop: false,
      loadData: loadContents([
        {
          name: 'menu',
          identifiers: ['event-calendar-navigation'],
        },
        {
          name: 'section',
          identifiers: ['event-calendar-header', ...commonSections],
        },
        {
          name: 'page',
          identifiers: [
            'event-calendar-about',
            'event-calendar-calendar',
            'event-calendar-reminder',
            'event-calendar-terms',
            'event-calendar-wrong',
          ],
        },
        {
          name: 'set',
          identifiers: [...commonSets, 'event-calendar-days'],
        },
      ]),
    },
    {
      path: '/migration',
      component: MigrationContainer,
      loadData: loadContents([
        {
          name: 'page',
          urls: match => {
            return [match.url.slice(1)];
          },
        },
        {
          name: 'set',
          identifiers: indexSets,
          include_depth: 5,
        },
        {
          name: 'section',
          identifiers: [
            ...commonSections,
            'migration-reset-section',
            'migration-requested-section',
            'migration-request-section',
            'migration-promo-section',
          ],
        },
      ]),
    },
    {
      path: '/bingo/play/:room?',
      exact: true,
      component: BingoContainer,
      loadData: loadContents([
        {
          name: 'page',
          urls: ['bingo', 'bingo/play', ...commonPages],
        },
        {
          name: 'set',
          identifiers: commonSets,
        },
        {
          name: 'section',
          identifiers: commonSections,
        },
      ]),
    },
    ...(depositType === 'InstantDeposit'
      ? [
          {
            path: '/instant-deposit',
            exact: true,
            component: InstantDepositContainer,
            loadData: loadContents([
              {
                name: 'page',
                identifiers: ['instant-deposit', ...commonPages],
              },
              {
                name: 'set',
                identifiers: commonSets,
              },
              {
                name: 'section',
                identifiers: [
                  ...commonSections,
                  'instant-deposit-step-amount',
                  'instant-deposit-step-payment',
                  'instant-deposit-step-receipt-pending',
                  'instant-deposit-step-receipt-success',
                  'instant-deposit-step-receipt-failure',
                  'instant-deposit-step-receipt',
                  'instant-deposit-step-bonus',
                  'instant-deposit-step-error',
                ],
              },
              {
                name: 'bonus',
              },
              {
                name: 'promotion',
              },
            ]),
          },
        ]
      : []),
    {
      path: '*',
      component: ContentContainer,
      loadData: loadContents([
        {
          name: 'page',
          urls: match => {
            return [match.url.slice(1), ...commonPages];
          },
          include_depth: 5,
        },
        {
          name: 'set',
          identifiers: commonSets,
        },
        {
          name: 'section',
          identifiers: [...commonSections],
        },
        {
          name: 'game',
          limit: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
        {
          name: 'game',
          limit: 1000,
          skip: 1000,
          segment: getGameSegment,
          order: '-fields.sortOrder',
        },
      ]),
    },
  ];

  return [...(isPnp ? pnpRoutes : accountRoutes), ...commonRoutes];
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import BasePage from '@pages/BasePage';
import withRouteData from '@utils/withRouteData';
import PageBuilder from '@components/PageBuilder';
import IndexHeader from '@components/IndexHeader';
import { withConfig } from '@config';
import getByPath from 'lodash/get';
import { withOverlayRouter } from '@components/OverlayRouter';
import selectGames from '@selectors/selectGames';
import { getTopGamesPromises } from '@utils/game';
import {
  getTopGames,
  getTopGamesWager,
  getTopGamesUWC,
} from 'tg-core-redux/lib/modules/event/action';
import { withExperiment } from '@components/hoc/withExperiment';

import './style.css';

@withExperiment('sign-up-no-index')
@withConfig
@withOverlayRouter
@withRouteData
@connect(
  state => ({
    pages: state.content.pages.data,
    menus: state.content.menus.data,
    jurisdiction: state.app.jurisdiction,
    isAutoLoggedIn: state.player.isAutoLoggedIn,
    sets: state.content.sets.data,
    games: selectGames(state),
    isAuthenticated: state.player.isAuthenticated,
    jackpots: state.event.jackpots,
    device: state.app.device,
    requestApproveTac: state.player.requestApproveTac,
  }),
  { getTopGames, getTopGamesWager, getTopGamesUWC }
)
class IndexContainerAccount extends Component {
  componentDidMount() {
    const hasTopGamesCategory = this.props?.sets
      .find(s => s.identifier === 'index-page-sections')
      ?.items.find(c => c.identifier === 'index-game-category')
      ?.items.find(i => i.identifier === 'top-games');

    if (hasTopGamesCategory) {
      Promise.all(getTopGamesPromises(this.props));
    }

    const shouldRunExperiment = this.props.config.experiments?.find(
      e => e.name === 'sign-up-no-index'
    );

    if (shouldRunExperiment) {
      this.props.experimentPlay();
    }
  }

  render() {
    const {
      pages,
      sets,
      games,
      jackpots,
      isAuthenticated,
      config,
      isAutoLoggedIn,
      device,
      overlayMatches,
      requestApproveTac,
      location,
      experimentVariant,
    } = this.props;

    if (experimentVariant === 'hide-index') {
      return <Redirect to={{ ...location, pathname: '/casino' }} />;
    }

    if (!pages || !sets) return null;

    // new config to add: simpleHeader type Boolean
    const { simpleHeader, hideStickyFooter } = config;

    // If signed in from the sign up - stay until activated (epic) or sidebar is closed
    if (
      getByPath(config, 'redirectCasino') &&
      isAuthenticated &&
      !requestApproveTac &&
      (!isAutoLoggedIn || overlayMatches.length === 0)
    )
      return <Redirect to={{ ...location, pathname: '/casino' }} />;

    const page = pages.find(p => p.url === 'start-page');

    const indexPageSections = sets.find(
      s => s.identifier === 'index-page-sections'
    );

    const totalJackpot =
      jackpots && jackpots.reduce((prev, curr) => prev + curr.Amount, 0);
    const currency = (jackpots && jackpots[0]?.Currency) || 'EUR';

    return (
      <BasePage
        header={{ transparent: true }}
        page={page}
        hideHeader={simpleHeader}
        className="IndexContainerAccount">
        <main>
          {simpleHeader && (
            <IndexHeader showDashboardButton={!hideStickyFooter} />
          )}

          <PageBuilder
            items={indexPageSections ? indexPageSections.items : []}
            games={games}
            totalJackpot={totalJackpot}
            currency={currency}
            device={device}
          />
        </main>
      </BasePage>
    );
  }
}

export default IndexContainerAccount;

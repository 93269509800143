import React, { Component, Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import OffCanvasLayout from '@components/OffCanvasLayout';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import cn from 'classnames';
import LanguageSelector from '@pages/LanguageSelector';
import { loadContents } from '@actions/content';
import { startChat } from '@utils/chat';
import { Money, Button, Icon, Translate, Skeleton } from 'tg-core-components';
import WalletSelector, {
  selectWalletSelectorProps,
} from '@components/WalletSelector';
import {
  getWallet,
  setWallet,
  getWallets,
} from 'tg-core-redux/lib/modules/wallet/action';
import {
  getActiveCurrency,
  setActiveCurrency,
} from 'tg-core-redux/lib/modules/player/action';
import { withUrls } from '@utils/urls';
import { withConfig } from '@config';
import withRouteData, { Loader } from '@utils/withRouteData';
import setLastVisitedCookie from '@utils/setLastVisitedCookie';
import selectUnreadNotifications from '@selectors/selectUnreadNotifications';
import translate from 'tg-core-components/lib/lib/utils/translate';
import delay from 'lodash/delay';

import './style.css';

const BalanceItem = ({ balanceValue, currency }) => {
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    setBlink(true);
    const id = delay(() => setBlink(false), 200);
    return () => clearTimeout(id);
  }, [balanceValue]);

  return (
    <div className={cn({ 'blink-money': blink })}>
      <Money value={balanceValue} currency={currency} />
    </div>
  );
};

const RefreshButton = ({ updateWalletFunction }) => {
  const [displayLoading, setDisplayLoading] = useState(false);
  const timerRef = useRef(null);

  return (
    <Button
      className="refresh-button button icon"
      isLoading={displayLoading}
      onClick={async () => {
        clearTimeout(timerRef.current);
        setDisplayLoading(true);
        await updateWalletFunction();

        timerRef.current = delay(() => setDisplayLoading(false), 1000);
      }}>
      {!displayLoading && <Icon icon="refresh" />}
    </Button>
  );
};

const BalanceSkeleton = () => (
  <div className="BalanceSkeleton">
    <div className="BalanceSkeleton__balance">
      <Skeleton.SkeletonLine />
      <Skeleton.SkeletonLine bold />
    </div>

    <div className="BalanceSkeleton__balance">
      <Skeleton.SkeletonLine />
      <Skeleton.SkeletonLine bold />
    </div>
  </div>
);

const InitialsSkeleton = () => (
  <div className="InitialsSkeleton">
    <Skeleton.SkeletonCircle />
    <Skeleton.SkeletonLine bold />
  </div>
);

@withConfig
@withCookies
@withUrls
@withRouteData
@injectIntl
@connect(
  state => ({
    jurisdiction: state.app.jurisdiction,
    menus: state.content.menus.data,
    wallet: state.wallet,
    user: state.player.user,
    isAuthenticated: state.player.isAuthenticated,
    sessionId: state.player.sessionId,
    displayCurrencies: state.currencies.displayCurrencies,
    activeDisplayCurrency: state.player.activeCurrency,
    unreadNotifications: selectUnreadNotifications(state),
    walletSelectorProps: selectWalletSelectorProps(state),
  }),
  { getWallet, getWallets, setWallet, getActiveCurrency, setActiveCurrency }
)
class Dashboard extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard'],
    },
    {
      name: 'menu',
      identifiers: ['dashboard'],
    },
  ]);

  testIfExternal = url => {
    return /^((http|https):\/\/)/.test(url);
  };

  render() {
    const {
      isRouteDataLoading,
      menus,
      wallet,
      getWallet,
      getWallets,
      sessionId,
      user,
      isAuthenticated,
      jurisdiction,
      unreadNotifications,
      activeDisplayCurrency,
      setActiveCurrency,
      setWallet,
      config: {
        isPnp,
        isAccount,
        openSupportWidget,
        useVisitedCookie,
        multiCurrency,
      },
      cookies,
      displayCurrencies,
      walletSelectorProps,
      urls: { deposit, withdraw, signIn, signUp },
      intl,
    } = this.props;

    const profileMenu = menus?.find(m => m.identifier === 'dashboard');
    const productsMenu = menus?.find(m => m.identifier === 'header-top-nav');
    const navigationMenu = menus?.find(m => m.identifier === 'dashboard-links');

    const totalBalance = wallet.data?.Total.Balance;
    const bonusBalance = wallet.data?.Bonus.Balance;
    const currency = user?.Currency;
    const nameInitials = user && user.FirstName[0] + user.LastName[0];
    const fullName = user && `${user.FirstName} ${user.LastName}`;

    return (
      <OffCanvasLayout className="DashboardContainer">
        <Loader isLoading={isRouteDataLoading}>
          <div className="Dashboard">
            {isAuthenticated && (
              <Fragment>
                {isAccount && (
                  <Fragment>
                    {!user && <InitialsSkeleton />}

                    {user && (
                      <Fragment>
                        <div className="section initials">
                          <div className="ellipsis">{nameInitials}</div>
                        </div>
                        <div className="section name">{fullName}</div>
                      </Fragment>
                    )}
                  </Fragment>
                )}

                {!wallet.data && <BalanceSkeleton />}

                {wallet.data && (
                  <div className="section balance">
                    <div>
                      <Translate
                        id="label.current-balance"
                        defaultMessage="Current balance"
                        tagName="div"
                      />
                      <BalanceItem
                        balanceValue={totalBalance}
                        currency={activeDisplayCurrency || currency}
                      />
                    </div>
                    <div>
                      <Translate
                        id="label.bonus-balance"
                        defaultMessage="Bonus balance"
                        tagName="div"
                      />
                      <BalanceItem
                        balanceValue={bonusBalance}
                        currency={activeDisplayCurrency || currency}
                      />
                    </div>
                    {multiCurrency && (
                      <div className="selectors">
                        <div className="selectors__wallet">
                          <WalletSelector
                            {...walletSelectorProps}
                            displayCurrencies={[
                              {
                                code: '',
                                name: translate(
                                  {
                                    id: 'label.currency-selector.native',
                                    defaultMessage: 'Native ({symbol})',
                                    values: {
                                      symbol: currency,
                                    },
                                  },
                                  intl
                                ),
                              },
                              ...displayCurrencies.map(code => ({
                                name: code,
                                code,
                              })),
                            ]}
                            onSelectDisplayCurrency={c => {
                              setActiveCurrency(c, sessionId);
                            }}
                            onSelect={c => {
                              setWallet(sessionId, c);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <RefreshButton
                      updateWalletFunction={() =>
                        Promise.all([
                          getWallet(sessionId),
                          getWallets(sessionId),
                        ])
                      }
                    />
                  </div>
                )}
              </Fragment>
            )}

            {isAccount && !isAuthenticated && (
              <div className="section membership-buttons">
                <Link
                  to={signUp({
                    state: { backUrl: '#dashboard', preventTransition: true },
                  })}
                  className="button primary">
                  <Translate id="action.sign-up" defaultMessage="Sign up" />
                </Link>
                <Link
                  to={signIn({
                    state: { backUrl: '#dashboard', preventTransition: true },
                  })}
                  className="button secondary">
                  <Translate id="action.sign-in" defaultMessage="Sign in" />
                </Link>
              </div>
            )}

            <div className="section payment-buttons">
              {!isAuthenticated && isPnp && (
                <Link to={signIn()} className="button secondary wide">
                  <Translate
                    id="action.fetch_balance"
                    defaultMessage="continue playing"
                  />
                </Link>
              )}
              {((isAccount && isAuthenticated) || isPnp) && (
                <Link
                  to={deposit({
                    state: {
                      backUrl: '#dashboard',
                      preventTransition: true,
                    },
                  })}
                  className="button primary wide">
                  <Translate id="action.deposit" defaultMessage="deposit" />
                </Link>
              )}
              {isAuthenticated && (
                <Link
                  to={withdraw({
                    state: { preventTransition: true, backUrl: '#dashboard' },
                  })}
                  className="button tertiary wide">
                  <Translate id="action.withdraw" defaultMessage="withdraw" />
                </Link>
              )}
            </div>

            {isAuthenticated && (
              <div className="section profile-links">
                {profileMenu?.items.map(i =>
                  this.testIfExternal(i.url) ? (
                    <a
                      key={i.identifier}
                      href={i.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>{i.menu}</span>
                    </a>
                  ) : (
                    <Link
                      className="ItemWithIconAndText"
                      key={i.identifier}
                      to={{
                        ...(i.url.startsWith('#')
                          ? { hash: i.url }
                          : { pathname: i.url }),
                        state: {
                          backUrl: '#dashboard',
                          preventTransition: true,
                        },
                      }}>
                      {i.menuIcon?.file.url && (
                        <div className="menu-icon">
                          <img
                            src={i.menuIcon.file.url}
                            alt="menu"
                            className="icon"
                          />
                        </div>
                      )}
                      <div className="text">{i.menu}</div>
                      {i.url === '#dashboard/messages' && (
                        <span
                          className="notifications-badge notifications-badge--right"
                          data-notifications={
                            unreadNotifications.internalMessages
                          }
                        />
                      )}
                    </Link>
                  )
                )}
              </div>
            )}

            <div className="section products">
              {productsMenu?.items?.map(i =>
                this.testIfExternal(i.url) ? (
                  <a
                    key={i.identifier}
                    href={i.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <span>{i.menu}</span>
                  </a>
                ) : (
                  <Link
                    className="ItemWithIconAndText"
                    key={i.identifier}
                    to={'/' + i.url}
                    onClick={() => {
                      if (i.url.startsWith('#')) {
                        window.location.hash = i.url;
                      }
                      useVisitedCookie && setLastVisitedCookie(i, cookies);
                    }}>
                    {i.menuIcon?.file.url && (
                      <img
                        src={i.menuIcon.file.url}
                        alt="menu"
                        className="icon"
                      />
                    )}
                    <div className="text">{i.menu}</div>
                  </Link>
                )
              )}
            </div>

            {navigationMenu && (
              <div className="section navigation">
                {navigationMenu.items.map(i =>
                  this.testIfExternal(i.url) ? (
                    <a
                      key={i.identifier}
                      href={i.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>{i.menu}</span>
                    </a>
                  ) : (
                    <Link key={i.identifier} to={'/' + i.url}>
                      {i.menu}
                    </Link>
                  )
                )}
              </div>
            )}

            <div className="section support">
              <Link
                to="/support"
                className="button primary wide"
                onClick={e => {
                  if (openSupportWidget.sidebar) {
                    e.preventDefault();
                    startChat(jurisdiction);
                  }
                }}>
                <Translate
                  id="action.contactsupport"
                  defaultMessage="Contact support"
                />
              </Link>
            </div>

            <div className="section footer">
              <LanguageSelector />

              {isAuthenticated && (
                <Link to="/sign-out" className="button tertiary">
                  <Translate id="action.signout" defaultMessage="Sign out" />
                </Link>
              )}
            </div>
          </div>
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default Dashboard;

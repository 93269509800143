import React, { useEffect } from 'react';
import { Input, Button } from 'tg-core-components';
import {
  validateRules,
  required,
  stringWithoutNumbers,
  blacklistedCharacters,
} from '../../validation';
import cn from 'classnames';
import translate from 'tg-core-components/lib/lib/utils/translate';
import axios from 'axios';
import { getConfig } from '@config';
import { getContinueLabel } from '../../helpers';

const City = ({
  /* Props from parent */
  errors,
  onChange,
  intl,
  fields,
  nextStep,
  isLoading,
  setIsLoading,
  statuses,

  /* Props from selector */
  jurisdiction,
  countries,
  device,
}) => {
  useEffect(() => {
    const getCity = async () => {
      const { aleacc } = getConfig(jurisdiction);
      const country = countries?.value?.find(c => c.value === fields.Country)
        ?.label;

      setIsLoading(true);

      try {
        const res = await axios.get(aleacc.host + '/geocode', {
          params: {
            zip: fields.Zip,
            country,
          },
        });

        if (res.data !== '') {
          onChange({ City: res.data });
        }
      } finally {
        setIsLoading(false);
      }
    };

    getCity();
  }, []); // eslint-disable-line

  const isValid = statuses.City === 'success';

  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <Input
        autoFocus
        type="text"
        label={translate({ id: 'label.city', defaultMessage: 'City' }, intl)}
        value={fields.City}
        name="City"
        onChange={(_, City) => {
          onChange({ City });
        }}
        status={(isLoading && 'loading') || statuses.City}
        statusText={errors.City && translate({ id: errors.City }, intl)}
      />

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ City }) => {
  const rules = {
    City: [
      [required, 'error.empty.city'],
      [stringWithoutNumbers, 'error.invalid.city'],
      [blacklistedCharacters, 'error.invalid.city'],
    ],
  };

  const errors = await validateRules({ City }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  jurisdiction: state.app.jurisdiction,
  countries: state.content.config.data?.find(c => c.key === 'countries'),
  device: state.app.device,
});

export default {
  name: 'city',
  icon: 'user',
  Component: City,
  validate,
  selector,
  fields: ['City'],
};

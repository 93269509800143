import selectRequiredActions from '@selectors/selectRequiredActions';

export const REQUIRED_ACTIONS = {
  SOURCE_OF_WEALTH: {
    name: 'SourceOfWealthFormRequired',
    location: '#popup/source-of-wealth',
  },
  RESPONSIBLE_GAMING_TEST: {
    name: 'ResponsibleGamingTest',
    location: '#popup/responsible-gaming-questions',
  },
  RESPONSIBLE_GAMING_INFORMATION: {
    name: 'ResponsibleGamingInformation',
    location: '',
  },
  DEPOSIT_LIMITS: {
    name: 'DepositLimitsRequired',
    location: '#set-limits',
  },
  KYC_PROVIDE_DOCUMENTS: {
    name: 'KycProvideDocuments',
    location: '',
  },
  KYC_REMIND_ABOUT_DOCUMENTS: {
    name: 'KycRemindAboutDocuments',
    location: '',
  },
  UPDATE_PLAYER_INFO: {
    name: 'UpdatePlayerInfo',
    location: '#popup/request-edit-profile',
  },

  // Not from Aleacc
  ACCEPT_TAC: {
    name: 'AcceptTac',
    location: '#popup/accept-tac',
  },
  LOGIN_BLOCK: {
    name: 'LoginBlock',
    location: '#login-block',
  },
};

export const handleRequiredActions = state => {
  const requiredActions = selectRequiredActions(state);

  if (!requiredActions.length) return;

  window.location.hash = '';

  const matchingAction = Object.values(REQUIRED_ACTIONS).find(action =>
    requiredActions.includes(action.name)
  );

  if (matchingAction) {
    window.location.hash = matchingAction.location;
  }
};

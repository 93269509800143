import React, { Component } from 'react';
import compose from 'recompose/compose';
import { Input, Button } from 'tg-core-components';
import { injectIntl } from 'react-intl';
import WithValidation from 'tg-core-components/lib/lib/WithValidation';
import require from 'tg-core-components/lib/lib/WithValidation/rules/required';
import { startChat } from '@utils/chat';
import { Translate } from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';
import './ActivationStyle.css';

const SignUpIssues = ({ issues, jurisdiction }) => (
  <div>
    <Translate tagName="p" id={`label.sign_up_issues`} />
    <ul>
      {issues.map(i => (
        <li key={i}>
          <Translate id={`label.sign_up_issue.${i}`} />
        </li>
      ))}
    </ul>

    <a
      onClick={e => {
        e.preventDefault();
        startChat(jurisdiction);
      }}
      className="button primary">
      <Translate id="start.live-chat" defaultMessage="Start chat" />
    </a>
  </div>
);

class ActivationStep extends Component {
  render() {
    const {
      phone,
      onBlur,
      errors,
      onSubmit,
      resendActivateCode,
      intl,
      signUpIssues,
      isLoading,
      currentStatus,
      jurisdiction,
    } = this.props;

    if (signUpIssues && signUpIssues.hasIssues)
      return (
        <SignUpIssues
          issues={signUpIssues.issues}
          jurisdiction={jurisdiction}
        />
      );

    return (
      <form className="Step4 form activate-form" onSubmit={onSubmit}>
        <div>
          <p>
            {translate({ id: 'label.activation_code_received' }, intl)} {phone}
          </p>
        </div>
        <div>
          <Input
            label={translate(
              {
                id: 'title.activation_code',
                defaultMessage: 'Activation code',
              },
              intl
            )}
            name="activation_code"
            onBlur={e => onBlur('activation_code', e.target.value)}
            status={(errors.activation_code && 'failure') || 'idle'}
            statusText={
              errors.activation_code &&
              translate({ id: errors.activation_code }, intl)
            }
            autoComplete="one-time-code"
          />
        </div>
        <Button
          className="button primary wide"
          type="submit"
          isLoading={isLoading}
          disabled={
            isLoading || (errors.activation_code && errors.activation_code)
          }>
          {translate({ id: 'action.button.activate_account' }, intl)}
        </Button>
        <a
          className={`resend ${currentStatus === 'resend' ? 'active' : ''}`}
          onClick={currentStatus === 'resend' && resendActivateCode}>
          {currentStatus === 'resend' && (
            <Translate
              id="action.resend_activation_code"
              defaultMessage="Resend activation code"
            />
          )}
          {currentStatus === 'sending' && (
            <Translate id="message.sending" defaultMessage="Sending" />
          )}
          {currentStatus === 'sent' && (
            <Translate id="message.sent" defaultMessage="Sent" />
          )}
        </a>
      </form>
    );
  }
}

const rules = {
  activation_code: [[require, 'error.empty.activation_code']],
};

export default compose(WithValidation(rules), injectIntl)(ActivationStep);

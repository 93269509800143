import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadContents } from '@actions/content';
import withRouteData from '@utils/withRouteData';
import Popup from '@components/Popup';
import marked from 'marked';
import { Translate, Button } from 'tg-core-components';
import { closeHash } from '@utils/hash';
import { withRouter } from 'react-router';
import Loader from '@components/Loader';
import { useAddToHomescreenPrompt } from '@utils/hooks';
import { isPWA } from '@components/InstallApp';
import { useCookies } from 'react-cookie';
import { useConfig } from '@utils/config';
import { hideInstallAppBanner } from '@actions/app';
import { useDispatch } from 'react-redux';

import './style.css';

const Desktop = ({ sections }) => {
  const content = sections?.find(s => s.identifier === 'install-app-desktop')
    ?.content;

  return (
    <div className="Desktop">
      {content && <div dangerouslySetInnerHTML={{ __html: marked(content) }} />}
    </div>
  );
};

const IOS = ({ sections }) => {
  const content = sections?.find(s => s.identifier === 'install-app-ios')
    ?.content;

  return (
    <div className="IOS">
      {content && <div dangerouslySetInnerHTML={{ __html: marked(content) }} />}
    </div>
  );
};

const Android = ({
  sections,
  overlayLocation,
  overlayMatch,
  history,
  prompt,
  promptInstall,
}) => {
  const content = sections?.find(s => s.identifier === 'install-app-android')
    ?.content;

  if (!prompt) return <Loader inline />;

  return (
    <div className="Desktop">
      {content && <div dangerouslySetInnerHTML={{ __html: marked(content) }} />}
      <Button
        onClick={() => {
          promptInstall();
          history.push({
            hash: closeHash(overlayLocation.hash, overlayMatch.url),
          });
        }}
        className="button primary">
        <Translate id="action.install" defaultMessage="Install" />
      </Button>
    </div>
  );
};

const getPopupComponent = (device, operatingSystem) => {
  if (device === 'Desktop') {
    return Desktop;
  }

  if (operatingSystem === 'iOS') {
    return IOS;
  }

  if (operatingSystem === 'Android') {
    return Android;
  }
};

const InstallAppContainer = ({ history, overlayLocation, overlayMatch }) => {
  const sections = useSelector(state => state.content.sections.data);
  const operatingSystem = useSelector(state => state.app.operatingSystem);
  const device = useSelector(state => state.app.device);

  const config = useConfig();
  const dispatch = useDispatch();
  const [_, setCookie] = useCookies();

  useEffect(() => {
    if (isPWA()) {
      history.push({
        hash: closeHash(overlayLocation.hash, overlayMatch.url),
      });
    }

    return () => {
      setCookie('install-app', false, {
        path: '/',
        expires: new Date(Date.now() + 31556952000 * 2),
        secure: config.CURRENT_ENV === 'prod',
      });
      dispatch(hideInstallAppBanner(true));
    };
  }, [history, overlayLocation.hash, overlayMatch.url]);

  const [prompt, promptInstall] = useAddToHomescreenPrompt();

  const Component = getPopupComponent(device, operatingSystem);

  return (
    <Popup
      className="InstallAppContainer"
      title={
        <Translate
          id="install-app.header"
          defaultMessage="Install app"
          tagName="h1"
        />
      }>
      {Component && (
        <Component
          sections={sections}
          overlayLocation={overlayLocation}
          overlayMatch={overlayMatch}
          history={history}
          prompt={prompt}
          promptInstall={promptInstall}
        />
      )}
    </Popup>
  );
};

InstallAppContainer.hashRoute = true;
InstallAppContainer.globalLoader = false;
InstallAppContainer.dataLoader = loadContents([
  {
    name: 'section',
    identifiers: [
      'install-app-desktop',
      'install-app-ios',
      'install-app-android',
    ],
  },
]);

export default withRouteData(withRouter(InstallAppContainer));

import React, { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import withRouteData from '@utils/withRouteData';
import { getLobbyUrl } from '@utils/game';
import { getNavbarItems } from '@utils/casino';
import { toggleFavoriteGameById } from '@actions/favorite';
import { Redirect, withRouter } from 'react-router-dom';
import {
  init as quickDepositInit,
  open as quickDepositOpen,
} from '@actions/quickDeposit';
import { pushGtmEvent } from '@actions/app';
import { addAlert } from '@actions/alert';
import GamePlay, { selectGamePlayProps } from './GamePlay';
import BasePage from '@pages/BasePage';
import compose from 'recompose/compose';
import { fetchContent, filterOutContentBySegmentation } from '@actions/content';
import { injectIntl } from 'react-intl';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { setLastPlayedGame } from 'tg-core-redux/lib/modules/casino/action';
import cn from 'classnames';
import selectGames from '@selectors/selectGames';
import selectLastPlayedGames from '@selectors/selectLastPlayedGames';
import selectFavoriteGames from '@selectors/selectFavoriteGames';
import { GamePlayContext, reducer, initialState } from './context';
import withNoIndex from '@components/hoc/withNoIndex';
import { useConfig } from '@utils/config';

const PlayContainer = ({
  /* connect */
  pages,
  sets,
  games,
  jurisdiction,
  isAuthenticated,
  user,
  device,
  locale,
  productBlocks,
  quickDepositInit,
  quickDepositOpen,
  pushGtmEvent,
  addAlert,
  toggleFavoriteGameById,
  fetchContent,
  filterOutContentBySegmentation,
  setLastPlayedGame,
  lastPlayedGames,

  /* withRouter */
  history,
  match,
  location,

  /* injectIntl */
  intl,

  gamePlayProps,
  favoriteGames,
}) => {
  const config = useConfig();
  const game = games?.find(g => g.url === match.params.slug);

  const navbarItems = getNavbarItems({
    navbar:
      (game?.isLive &&
        sets?.find(s => s.identifier === 'livecasino-navbar-gameplay')) ||
      sets?.find(s => s.identifier === 'casino-navbar-gameplay'),
    games,
    hasLastPlayedGames: lastPlayedGames.length > 0,
    favoriteGames,
  });

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    bottomBarCategory: device === 'Desktop' ? null : navbarItems[0]?.identifier,
    isBottomBarOpen:
      device === 'Desktop' || config.disablePreGameView ? false : true,
    isGameLaunched: device === 'Desktop' ? true : false,
  });

  useEffect(() => {
    window.addEventListener('message', receiveMessage);
    return () => window.removeEventListener('message', receiveMessage);
  });

  const receiveMessage = e => {
    if (!(e && e.data)) return;
    const { action } = e.data;
    switch (action) {
      case 'tgPlayStopGame':
      case 'tgplay_exitGame': {
        onExitGame();
        break;
      }

      case 'tgplay_exitGame_deposit': {
        history.push({
          pathname: getLobbyUrl(game, location),
          hash: '#deposit',
        });
        break;
      }
    }
  };

  const onExitGame = () => history.push(getLobbyUrl(game, location));

  useEffect(() => {
    fetchContent('page', locale, {
      urls: [match.url.slice(1)],
    }).then(() => filterOutContentBySegmentation());
  }, [fetchContent, filterOutContentBySegmentation, locale, match.url]);

  if (match.params.slug === 'random') {
    const rng = Math.round(games.length * Math.random());

    history.replace(`/play/${games[rng].url}`);

    return null;
  }

  const page = {
    ...{
      title: translate(
        {
          id: 'label.default-play-title',
          defaultMessage: '{game}',
          values: {
            game: game?.name || game?.title,
          },
        },
        intl
      ),
    },
    ...(pages?.find(p => p.url === match.url.slice(1)) || {}),
  };

  if (
    productBlocks?.find(block => block.Product === 'Casino') ||
    config?.block?.casino
  ) {
    return <Redirect to="/casino" />;
  }

  if (!game) {
    onExitGame();
    return null;
  }

  if (
    !config?.disableActivation &&
    isAuthenticated &&
    user &&
    !user.IsVerified &&
    !(config?.isPnp && jurisdiction.toLowerCase() === 'mga') &&
    !['sga', 'dga', 'curacao'].includes(jurisdiction.toLowerCase())
  ) {
    addAlert({
      type: 'game.user-not-activated',
      level: 'warning',
    });
    return (
      <Redirect
        to={{
          pathname: getLobbyUrl(game, location),
          hash: 'dashboard/activate',
          state: { referrer: location.pathname },
        }}
      />
    );
  }

  return (
    <BasePage
      className={cn('PlayContainer', {
        'PlayContainer--is-game-launched': state.isGameLaunched,
        'PlayContainer--is-dark-mode': state.isDarkMode,
      })}
      page={page}
      hideHeader={true}
      hideStickyFooter={true}
      hideFooter={device?.toLowerCase() !== 'desktop'}>
      <GamePlayContext.Provider value={{ state, dispatch }}>
        <GamePlay
          {...gamePlayProps}
          game={game}
          page={page}
          onExitGame={onExitGame}
          toggleFavoriteGameById={toggleFavoriteGameById}
          setLastPlayedGame={setLastPlayedGame}
          quickDepositInit={quickDepositInit}
          quickDepositOpen={quickDepositOpen}
          pushGtmEvent={pushGtmEvent}
        />
      </GamePlayContext.Provider>
    </BasePage>
  );
};

const enhance = compose(
  injectIntl,
  withRouter,
  withRouteData,
  connect(
    state => ({
      pages: state.content.pages.data,
      sets: state.content.sets.data,
      games: selectGames(state),
      favoriteGames: selectFavoriteGames(state),
      jurisdiction: state.app.jurisdiction,
      isAuthenticated: state.player.isAuthenticated,
      user: state.player.user,
      device: state.app.device,
      locale: state.app.locale,
      productBlocks: state.responsible_gaming.productBlocks,
      gamePlayProps: selectGamePlayProps(state),
      lastPlayedGames: selectLastPlayedGames(state),
    }),
    {
      toggleFavoriteGameById,
      quickDepositInit,
      quickDepositOpen,
      pushGtmEvent,
      addAlert,
      fetchContent,
      filterOutContentBySegmentation,
      setLastPlayedGame,
    }
  ),
  withNoIndex(({ games, match }) => {
    const game = games?.find(g => g.url === match.params.slug);
    if (!game || !game.contentPage) return true;
  })
);

export default enhance(PlayContainer);

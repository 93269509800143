import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withConfig } from '@config';
import { Select, countryEmojiFlags, Icon } from 'tg-core-components';
import './style.css';

@withRouter
@withConfig
@connect(state => ({
  languages: state.content.languages.data,
  locale: state.app.uiLocale,
  operatingSystem: state.app.operatingSystem,
}))
class LanguageSelector extends Component {
  renderFlagAndCountryName = lang => {
    if (lang.emoji) {
      return `${lang.emoji} ${lang.name}`;
    }

    if (lang.code.includes('-')) {
      return `${countryEmojiFlags[lang.code.split('-')[1]]} ${lang.name}`;
    }

    return lang.name;
  };

  render() {
    const { config, languages, locale, location, operatingSystem } = this.props;

    if (languages && languages.length <= 1) return null;

    const currentLocale = languages?.find(l => l.code === locale);
    const hideFlagEmojis =
      operatingSystem === 'Windows' || config?.languageSelector?.hideFlagEmojis;

    const sortedActiveLangFirst = [...languages].sort(lang =>
      lang.name === currentLocale?.name ? -1 : 1
    );

    return (
      <Fragment>
        <ul className="LanguageSelectorList">
          {languages?.map(language => {
            const localeUrl = language?.alias?.[0] || language.code;

            return (
              language.name && (
                <li
                  className={
                    'language-item' +
                    (language.name === currentLocale?.name ? ' active' : ' ')
                  }
                  key={language.code}>
                  <a href={`/${localeUrl}${location.pathname}${location.hash}`}>
                    <img src={language.icon.file.url} alt="Country" />
                    <span>{language.name}</span>
                  </a>
                </li>
              )
            );
          })}
        </ul>

        <div className="LanguageSelector">
          <div className="LanguageSelector__label">
            <img src={currentLocale?.icon.file.url} alt="Country" />
            <span>{currentLocale?.name}</span>
            <div className="select-icon">
              <Icon icon="chevron-down" />
            </div>
          </div>
          <Select
            className="LanguageSelector__select"
            value={currentLocale?.alias?.[0] || locale}
            onChange={e =>
              (window.location.href = `/${e.target.value}${location.pathname}`)
            }>
            {sortedActiveLangFirst?.map((lang, i) => (
              <Select.Option key={i} value={lang?.alias?.[0] || lang.code}>
                {hideFlagEmojis
                  ? lang.name
                  : this.renderFlagAndCountryName(lang)}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Fragment>
    );
  }
}
export default LanguageSelector;

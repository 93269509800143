import React, { useEffect } from 'react';
import { ActivateWidget } from 'tg-core-components';
import { useDispatch } from 'react-redux';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import { removeAlerts } from '@actions/alert';

import './style.css';

const Activation = ({ fields, nextStep }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(removeAlerts('ACTIVATE'));
      dispatch(removeAlerts('REQUEST_ACTIVATION_CODE'));
    };
  }, [dispatch]);

  const onSubmit = (...args) => {
    dispatch(playerActions.activate(...args)).then(res => {
      if (!res?.type?.includes('_FAILURE')) {
        nextStep();
      }
    });
  };

  return (
    <div className="Activation">
      <ActivateWidget
        email={fields.Email}
        onSubmit={onSubmit}
        onResend={(...args) =>
          dispatch(playerActions.requestActivationCode(...args))
        }
      />
    </div>
  );
};

export default {
  name: 'activation',
  Component: Activation,
};

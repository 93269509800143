import { combineEpics } from 'redux-observable';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import { map, filter } from 'rxjs/operators';

const reloadOptInOuts = (action$, state$) =>
  action$.pipe(
    filter(
      action =>
        ['OPT_IN_COMPLETE', 'OPT_OUT_COMPLETE'].indexOf(action.type) > -1 &&
        state$.value.player.sessionId
    ),
    map(() => playerActions.getOptInOuts(state$.value.player.sessionId))
  );

export default combineEpics(reloadOptInOuts);

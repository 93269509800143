const initialState = {
  isShowingToast: false,
  toastType: null,
  text: null,
  duration: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_TOAST':
      return {
        isShowingToast: true,
        text: action.text,
        toastType: action.toastType,
        duration: action.duration,
      };

    case 'HIDE_TOAST':
      return {
        isShowingToast: false,
        text: null,
        toastType: null,
        duration: null,
      };

    default:
      return state;
  }
};

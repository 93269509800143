import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Input, Button } from 'tg-core-components';
import {
  validateRules,
  required,
  noSpecialCharacters,
  stringWithoutNumbers,
} from '../../validation';
import { getContinueLabel, apiCache } from '../../helpers';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { validatePersonIdv2 } from 'tg-core-api/lib/player';

const Name = ({
  /* Props from parent*/
  errors,
  onChange,
  intl,
  fields,
  nextStep,
  statuses,

  /* Props from selector */
  device,
  jurisdiction,
}) => {
  const [supportNumberRequired, setSupportNumberRequired] = useState(false);

  const isValid =
    statuses.FirstName === 'success' &&
    statuses.LastName === 'success' &&
    (!supportNumberRequired ? statuses.LastName2 === 'success' : true);

  useEffect(() => {
    apiCache(validatePersonIdv2)(fields.PersonId, jurisdiction).then(res => {
      setSupportNumberRequired(res.data?.RequiresSupportNumber);
    });
  }, []); // eslint-disable-line

  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <Input
        autoFocus={!fields.FirstName}
        type="text"
        label={translate(
          { id: 'label.firstName', defaultMessage: 'First name' },
          intl
        )}
        value={fields.FirstName}
        name="FirstName"
        onChange={(_, FirstName) => onChange({ FirstName })}
        status={statuses.FirstName}
        statusText={
          errors.FirstName && translate({ id: errors.FirstName }, intl)
        }
      />

      <Input
        autoFocus={fields.FirstName && !fields.LastName}
        type="text"
        label={translate(
          { id: 'label.lastName', defaultMessage: 'Last name' },
          intl
        )}
        value={fields.LastName}
        name="LastName"
        onChange={(_, LastName) => onChange({ LastName })}
        status={statuses.LastName}
        statusText={errors.LastName && translate({ id: errors.LastName }, intl)}
      />

      {!supportNumberRequired && (
        <Input
          autoFocus={fields.FirstName && fields.LastName}
          type="text"
          label={translate(
            { id: 'label.lastName2', defaultMessage: 'Last name' },
            intl
          )}
          value={fields.LastName2}
          name="LastName2"
          onChange={(_, LastName2) => onChange({ LastName2 })}
          status={statuses.LastName2}
          statusText={
            errors.LastName2 && translate({ id: errors.LastName2 }, intl)
          }
        />
      )}

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ FirstName, LastName, LastName2 }) => {
  const rules = {
    FirstName: [
      [required, 'error.empty.first-name'],
      [noSpecialCharacters, 'error.invalid.first-name'],
      [stringWithoutNumbers, 'error.invalid.first-name'],
    ],
    LastName: [
      [required, 'error.empty.last-name'],
      [noSpecialCharacters, 'error.invalid.last-name'],
      [stringWithoutNumbers, 'error.invalid.last-name'],
    ],
    LastName2: [
      [required, 'error.empty.last-name'],
      [noSpecialCharacters, 'error.invalid.last-name'],
      [stringWithoutNumbers, 'error.invalid.last-name'],
    ],
  };
  const errors = await validateRules({ FirstName, LastName, LastName2 }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  device: state.app.device,
  jurisdiction: state.app.jurisdiction,
});

export default {
  name: 'name',
  icon: 'user',
  Component: Name,
  validate,
  selector,
  fields: ['FirstName', 'LastName', 'LastName2'],
};

import hash from 'object-hash';
import Cookies from 'universal-cookie';
import { getConfig } from './config';

export const GTM_EVENTS = {
  SIGNUP_INIT: 'init sign up', // sign-up form opened, NO ADDITIONAL DATA
  SIGNUP_SUCCESS: 'sign up', // sign-up completed successfully, NO ADDITIONAL DATA
  SIGNUP_FAILURE: 'sign up failure', // DATA: "Sign up errors"
  SIGNUP_STEP_BONUS: 'bonus',
  SIGNUP_STEP_USER_INFORMATION: 'user information',
  SIGNUP_STEP_CONTACT_INFORMATION: 'contact information',
  SIGNUP_STEP_LIMITS: 'set limits',
  SIGNUP_STEP_CONFIRM_INFORMATION: 'confirm information',
  SIGNUP_STEP_ACTIVATION: 'activation',
  SIGNUP_STEP_EXTERNAL_AUTH: 'external auth',
  SIGNUP_STEP_WELCOME: 'welcome',

  SIGNIN_INIT: 'init sign in', // Sign-in form opened. NO ADDITIONAL DATA.
  SIGNIN_SUCCESS: 'sign in', // Sign-in completed successfully. NO ADDITIONAL DATA.
  SIGNIN_FAILURE: 'sign in failure', // DATA: "Sign in errors"
  SIGNIN_STEP_SET_METHOD: 'set method step',
  SIGNIN_STEP_EMAIL: 'email signin',
  SIGNIN_STEP_BANKID: 'bankid signin',

  DEPOSIT_INIT: 'init deposit', // deposit form opened, NO ADDITIONAL DATA
  DEPOSIT_SUCCESS: 'deposit', // deposit completed successfully, DATA state.player.paymentStats, transactionId, amount
  DEPOSIT_FAILURE: 'deposit failure', // deposit failed, DATA transaction id, amount, currency

  PAYNPLAY_INIT: 'paynplay init',
  PAYNPLAY_CLOSE: 'paynplay close',
  PAYNPLAY_SUCCESS: 'paynplay success',
  PAYNPLAY_FAILURE: 'paynplay failure',

  QUICK_DEPOSIT_INIT: 'init quick deposit',
  QUICK_DEPOSIT_SUCCESS: 'quick deposit success',
  QUICK_DEPOSIT_FAILURE: 'quick deposit failure',

  INTERNAL_MESSAGE_OPEN: 'internal message open',
  INTERNAL_MESSAGE_CTA: 'internal message cta',

  MISSING_TRANSLATION: 'missing translation',

  EXPERIMENT_PLAY: 'experiment play',
  EXPERIMENT_WIN: 'experiment win',

  TOURNAMENT_JOIN_SUCCESS: 'tournament join',
  TOURNAMENT_JOIN_FAILURE: 'tournament failure',
};

export function gtmInit(GTM_ID) {
  const gtmScript = document.createElement('script');
  gtmScript.src = (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', GTM_ID);
  // adding no-script Google Tag Manager
  const noscriptElems = document.getElementsByTagName('noscript');
  if (noscriptElems && noscriptElems.length > 0) {
    const gtmIframe = document.createElement('iframe');
    gtmIframe.setAttribute(
      'src',
      `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`
    );
    gtmIframe.setAttribute('height', '0');
    gtmIframe.setAttribute('width', '0');
    gtmIframe.setAttribute('style', 'display:none;visibility:hidden');
    noscriptElems[0].appendChild(gtmIframe);
  }
}

const getClickId = affiliateTag => {
  const regex = /a_([0-9]+)b_([0-9]+)c_(.*)$/;
  if (!regex.test(affiliateTag)) return '';
  return affiliateTag.substring(affiliateTag.lastIndexOf('_') + 1);
};

export const gtmDefaultData = ({
  app: { ipCountry, jurisdiction, device },
  player: { user, paymentStats },
  wallet,
  content: { games },
}) => {
  const cookies = new Cookies();
  const config = getConfig(jurisdiction);
  const game =
    games.data.find(g => g.url === window.location.pathname.split('/')[3]) ||
    {};
  let defaultData = {
    Country: ipCountry,
    Jurisdiction: jurisdiction,
    Device: device,
    AffiliateTag: cookies.get(config.btagCookie) || null,
    Product: cookies.get(config.visitedCookieName),
    GameIdentifier: game.identifier,
    GameBackendId: game.backendId,
    GameName: game.name,
  };
  if (user) {
    const userInfo = {
      PlayerId: user.Id,
      EmailHash: hash.sha1(user.Email),
      Country: user.Country,
      Gender: user.Gender,
      Currency: user.Currency,
      IsComplete: user.IsComplete,
      AffiliateTag: user.AffiliateTag,
    };
    defaultData = { ...defaultData, ...userInfo };
  }
  if (paymentStats) {
    defaultData = { ...defaultData, ...paymentStats };
  }
  if (wallet && wallet.data) {
    const walletInfo = { Balance: wallet.data.Total.Balance };
    defaultData = { ...defaultData, ...walletInfo };
  }

  // Add click id
  if (defaultData.AffiliateTag) {
    defaultData.ClickId = getClickId(defaultData.AffiliateTag);
    if (defaultData.ClickId) {
      defaultData.ClickId.split('_').forEach(
        (id, i) => (defaultData[`ClickId${i + 1}`] = id)
      );
    }
  }

  return defaultData;
};

let interval = null;

export const init = (
  { TimeUntilRealityCheck = 60 },
  isAuthenticated,
  jurisdiction
) => {
  if (
    __CLIENT__ &&
    isAuthenticated &&
    jurisdiction &&
    ['sga', 'mga'].includes(jurisdiction.toLowerCase())
  ) {
    const realityCheckTimestamp =
      Date.now() + TimeUntilRealityCheck * 60 * 1000;

    clearInterval(interval);

    interval = setInterval(() => {
      if (Date.now() > realityCheckTimestamp) {
        window.location.hash = '#popup/realitycheck';
      }
    }, 15 * 1000);
  }
};

export default {
  init,
};

import React, { Fragment } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import marked from 'marked';
import { Translate } from 'tg-core-components';
import hamburgerIcon from '@images/hamburger-icon-white.svg';
import exitIcon from '@images/cancel-white.svg';
import userIcon from '@images/user.svg';
import { getFirstPathnameInUrl } from '@utils/getFirstPathnameInUrl';
import cn from 'classnames';
import { withOverlayRouter } from '@components/OverlayRouter';
import withHideOnScroll from '@components/hoc/withHideOnScroll';
import { withConfig } from '@config';
import compose from 'recompose/compose';
import selectUnreadNotifications from '@selectors/selectUnreadNotifications';
import withScroll from '@utils/withScroll';
import setLastVisitedCookie from '../../utils/setLastVisitedCookie';
import { withCookies } from 'react-cookie';
import Helmet from 'react-helmet';

const renderer = new marked.Renderer();
renderer.paragraph = e => e;

import './style.css';

export const selectStickyFooterProps = state => ({
  nav:
    state.content.menus.data.find(m => m.identifier === 'sticky-footer-nav') ||
    state.content.menus.data.find(m => m.identifier === 'header-products-nav'),
  isAuthenticated: state.player.isAuthenticated,
  unreadNotifications: selectUnreadNotifications(state),
});

const StickyFooter = ({
  /* props from selector */
  nav,
  isAuthenticated,
  unreadNotifications,
  cookies,

  /* withConfig */
  config,

  /* withOverlayRouter */
  overlayMatches,

  /* withHideOnScroll */
  hideElement,

  /* withScroll */
  hasReachedScrollDistance,

  /* withRouter */
  location,
}) => {
  const isGlobalNavOpen = overlayMatches.some(o => o.path === 'global-nav');
  const sidebarIsOpen = overlayMatches.length && !isGlobalNavOpen;

  return (
    <nav
      className={cn('StickyFooter-mobile', {
        hide: config.hideOnScroll.footer && hideElement,
        'StickyFooter-mobile--has-reached-scroll-distance': hasReachedScrollDistance,
      })}>
      <Helmet
        bodyAttributes={{
          'StickyFooter-hide': config.hideOnScroll.footer && hideElement,
        }}
      />
      {!isAuthenticated && config.stickyFooterButton && (
        <Link className="button primary" to="/sign-up">
          {config.isAccount ? (
            <Translate id="action.signup" defaultMessage="Create account" />
          ) : (
            <Translate id="action.play-now" defaultMessage="Play now" />
          )}
        </Link>
      )}

      <div className="stickyfooter-nav-items">
        {config.globalNavContainer && (
          <Fragment>
            {isGlobalNavOpen ? (
              <Link to={{ hash: '' }} className="exit-sidebar-icon">
                <img src={exitIcon} alt="exit" />
                <Translate
                  id="label.close"
                  defaultMessage="Close"
                  tagName="div"
                />
              </Link>
            ) : (
              <Link to={{ hash: 'global-nav' }} className="hamburger-icon">
                <img src={hamburgerIcon} alt="menu" />
                <Translate
                  id="label.nav-menu"
                  defaultMessage="Menu"
                  tagName="div"
                />
                <span
                  className="notifications-badge notifications-badge--right"
                  data-notifications={unreadNotifications.internalMessages}
                />
              </Link>
            )}
          </Fragment>
        )}

        {nav.items?.map((i, k) => {
          const activePath = getFirstPathnameInUrl(location);
          const isActive = activePath === i.url;
          return (
            <NavLink
              key={k}
              to={i.url?.startsWith('/') ? i.url : `/${i.url}`}
              onClick={() => {
                if (i.url.startsWith('#')) {
                  window.location.hash = i.url;
                }
                config.useVisitedCookie && setLastVisitedCookie(i, cookies);
              }}>
              {
                <img
                  src={
                    isActive && i.selectedIcon
                      ? i.selectedIcon?.file?.url
                      : i.menuIcon?.file?.url
                  }
                  alt="icons"
                  className="icon"
                />
              }
              <div className="text">{i.menu || i.name}</div>
            </NavLink>
          );
        })}

        {sidebarIsOpen ? (
          <Link to={{ hash: '' }} className="exit-sidebar-icon">
            <img src={exitIcon} alt="exit" />
            <Translate id="label.close" defaultMessage="Close" tagName="div" />
          </Link>
        ) : (
          <Link
            to={{
              hash:
                config.isPnp || isAuthenticated || config.forceDashboard
                  ? 'dashboard'
                  : 'sign-in',
            }}
            className={
              config.isPnp || config.forceDashboard
                ? 'hamburger-icon'
                : 'profile-icon'
            }>
            <img
              src={
                config.isPnp || config.forceDashboard ? hamburgerIcon : userIcon
              }
              alt="menu"
            />
            {config.isAccount && !config.forceDashboard ? (
              <Fragment>
                {isAuthenticated ? (
                  <Translate
                    id="label.profile"
                    defaultMessage="Profile"
                    tagName="div"
                  />
                ) : (
                  <Translate
                    id="action.sign-in"
                    defaultMessage="Sign in"
                    tagName="div"
                  />
                )}
              </Fragment>
            ) : (
              <Fragment>
                {isAuthenticated ? (
                  <Translate
                    id="label.menu"
                    defaultMessage="Menu"
                    tagName="div"
                  />
                ) : (
                  <Translate
                    id="label.menu-unauthenticated"
                    defaultMessage="Menu sign in"
                    tagName="div"
                  />
                )}
              </Fragment>
            )}
            <span
              className={cn('notifications-badge notifications-badge--right', {
                'internal-message': unreadNotifications.internalMessages,
              })}
              data-notifications={unreadNotifications.internalMessages}
            />
          </Link>
        )}
      </div>
    </nav>
  );
};

export default compose(
  withConfig,
  withOverlayRouter,
  withHideOnScroll(),
  withScroll(25),
  withCookies,
  withRouter
)(StickyFooter);

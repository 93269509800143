/**
 * 1. account/messages
 * has no user actions
 *
 * 2. account/history
 * has no user actions
 *
 * 3. account/bonus
 * preclaim bonus
 *
 * 4. account/settings/personal-data
 * player saves new data
 *
 * 5. account/settings/subscriptions
 * opt in
 * opt out
 *
 * 6. account/settings/responsible-gaming
 * self block
 * self exclude
 * set multiple limits
 * delete limit
 * confirm
 * decline
 * get limit
 *
 * 7. account/settings/change-password
 * change password
 *
 * 8. account/settings/sportsbook
 * set properties
 *
 * 9. popup/source-of-wealth
 * submit
 *
 * 10. popup/responsible-gaming-questions
 * submit
 *
 * 11. deposit
 * success
 * failure
 */

export const APP_DASHBOARD_ACTIONS = [
  // 3
  'TRIGGER_BONUS_BY_PROMO_CODE_COMPLETE',
  // 4
  'PLAYER_UPDATE_COMPLETE',
  // 5
  'OPT_IN_COMPLETE',
  'OPT_OUT_COMPLETE',
  // 6
  'SELF_BLOCK_COMPLETE',
  'SELF_EXCLUDE_COMPLETE',
  'SET_MULTIPLE_LIMITS_COMPLETE',
  'DELETE_LIMIT_COMPLETE',
  'RG_CONFIRM_COMPLETE',
  'RG_DECLINE_COMPLETE',
  // 7
  'CHANGE_PASSWORD_COMPLETE',
  // 8
  'SET_PLAYER_PROPERTIES_COMPLETE',
  // 9
  'SOURCE_OF_WEALTH_COMPLETE',
  // 10
  'RG_PGSI_COMPLETE',
  // 11
  'PAYMENT_SUCCESS',
  'PAYMENT_FAILURE',
  // extra
  'SIGN_OUT_COMPLETE',
];

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SignIn, Translate, Input, Button } from 'tg-core-components';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import OffCanvasLayout from '@components/OffCanvasLayout';
import withRouteData from '@utils/withRouteData';
import Alert, { createAlertId, createAlertValues } from '@components/Alert';

import './style.css';

@withRouteData
@connect(
  state => ({
    isLoading: state.player.isLoading,
    operatingSystem: state.app.operatingSystem,
    device: state.app.device,
    browser: state.app.browser,
  }),
  dispatch => ({
    signIn: bindActionCreators(playerActions.signInV2, dispatch),
  })
)
class SignInForTestContainer extends Component {
  static hashRoute = true;
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      isLoading: false,
      email: __CLIENT__ && localStorage.getItem('email'),
      shouldRememberEmail: __CLIENT__ && localStorage.getItem('remember-email'),
      isProtected: true,
      password: '',
      passwordError: false,
    };
  }

  onProtectedSubmit = () => {
    if (this.state.password === 'LoginForTest123') {
      this.setState({
        isProtected: false,
      });
    } else {
      this.setState({
        passwordError: true,
      });
    }
  };

  onSubmit = data => {
    const {
      signIn,
      operatingSystem: OperatingSystem,
      browser: Browser,
      device: Platform,
    } = this.props;

    let request = {
      ...data,
      Platform,
      OperatingSystem,
      Browser,
      SignInForTest: true,
    };

    this.setState({ isLoading: true });

    return signIn(request).then(action => {
      if (action.type && action.type.includes('_FAILURE')) {
        this.setState({
          error: action,
          isLoading: false,
        });
      } else {
        this.setState({
          error: false,
          isLoading: false,
        });

        if (this.state.shouldRememberEmail) {
          __CLIENT__ && localStorage.setItem('email', this.state.email);
          __CLIENT__ && localStorage.setItem('remember-email', true);
        } else {
          __CLIENT__ && localStorage.removeItem('email');
          __CLIENT__ && localStorage.removeItem('remember-email');
        }
        window.routerHistory.replace('/casino');
      }

      return action;
    });
  };

  handleChangePassword = e => this.setState({ password: e.target.value });

  handleChangeEmail = e => this.setState({ email: e.target.value });

  handleToggleRememberEmail = e => {
    this.setState({ shouldRememberEmail: e.target.checked });
  };

  render() {
    const {
      email,
      shouldRememberEmail,
      isLoading,
      isProtected,
      passwordError,
    } = this.state;

    return (
      <OffCanvasLayout className={`SignInForTestContainer`}>
        {isProtected ? (
          <form onSubmit={e => e.preventDefault()}>
            <Translate
              tagName="h3"
              id="header.test-login-password"
              defaultMessage="Enter password"
            />
            <Input onChange={this.handleChangePassword} />
            <Button
              className="button primary wide"
              onClick={this.onProtectedSubmit}>
              <Translate defaultMessage="Submit" id="action.submit" />
            </Button>
            {passwordError && (
              <Translate
                id="error.test-login-wrong-password"
                defaultMessage="Wrong password"
              />
            )}
          </form>
        ) : (
          <div>
            <Translate
              tagName="h3"
              id="header.test-login"
              defaultMessage="Login form for test accounts"
            />
            {this.state.error && (
              <Alert
                icon="exclamation-triangle"
                id={createAlertId(this.state.error, 'danger')}
                values={createAlertValues(this.state.error)}
                level="danger"
              />
            )}
            <div className="sign-in-wrapper">
              <SignIn
                onSubmitForm={this.onSubmit}
                email={email}
                isLoading={isLoading}
                handleChangeEmail={this.handleChangeEmail}
                shouldRememberEmail={shouldRememberEmail}
                handleToggleRememberEmail={this.handleToggleRememberEmail}
              />
            </div>
          </div>
        )}
      </OffCanvasLayout>
    );
  }
}
export default SignInForTestContainer;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './ResponsibleGamingLogos.css';

export const selectresponsibleGamingLogosProps = state => ({
  jurisdiction: state.app.jurisdiction,
  sessionId: state.player.sessionId,
});

class ResponsibleGamingLogos extends Component {
  render() {
    const { jurisdiction } = this.props;
    if (jurisdiction.toLowerCase() !== 'sga') {
      return null;
    }

    return (
      <div className="responsible-gaming-logos">
        <Link
          className="responsible-gaming-logo"
          to="#dashboard/responsible-gaming">
          <img
            src="/images/spelgranser.svg"
            alt="spelgranser"
            className="spelgranser"
          />
        </Link>

        <a
          href="https://www.stodlinjen.se/#!/spelberoende-test-pgsi"
          target="_blank"
          rel="noopener noreferrer"
          className="responsible-gaming-logo">
          <img
            src="/images/sjalvtest.svg"
            alt="sjalvtest"
            className="sjalvtest"
          />
        </a>

        <Link className="responsible-gaming-logo" to="#popup/spelpaus">
          <img src="/images/spelpaus.svg" alt="spelpaus" className="spelpaus" />
        </Link>
      </div>
    );
  }
}

export default ResponsibleGamingLogos;

import { validateEmail, validatePhone } from 'tg-core-api/lib/player';

import { apiCache } from './helpers';

const validateRules = async (fields, rules) => {
  const fieldKeys = Object.keys(fields);
  let errors = {};

  for (let i = 0; i < fieldKeys.length; i++) {
    const field = fieldKeys[i];

    const fieldRules = rules[field];

    for (let j = 0; j < fieldRules.length; j++) {
      const rule = fieldRules[j];
      const [fn, msg] = rule;

      // Only validate one rule for a field at a time
      // since we want to check the format before sending
      // a network validation request.
      if (errors[field]) continue;

      const value = fields[field];
      const isValid = value === undefined ? true : await fn(value);

      if (!isValid) {
        errors = {
          ...errors,
          [field]: msg,
        };
      }
    }
  }

  return errors;
};

const min = min => value => Number(value) >= min;
const max = max => value => Number(value) <= max;
const stringWithoutNumbers = value => value.match(/^[\D]+$/);
const noSpecialCharacters = value =>
  !value.match(/[\s!"#\$%&'\(\)\*\+,\-\.\/:;<>\?@\[\\\]\^_`{|}~]/);
const email = value =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );

const phone = value => value.match(/^\+?\d{9,17}$/);
const required = value =>
  typeof value === 'string' ? Boolean(value?.trim()) : Boolean(value);

const externalEmailValidation = jurisdiction => email => {
  return apiCache(validateEmail)(encodeURIComponent(email), jurisdiction).then(
    res => {
      if (!res?.isNetworkException) {
        return true;
      }

      const { Code } = res.value.response.data;

      if (Code === 'RateLimited') {
        return true;
      }

      return false;
    }
  );
};

const externalPhoneValidation = jurisdiction => phone => {
  return apiCache(validatePhone)(phone, jurisdiction).then(res => {
    if (!res?.isNetworkException) {
      return true;
    }

    const { Code } = res.value.response.data;

    if (Code === 'RateLimited') {
      return true;
    }

    return false;
  });
};

export {
  min,
  max,
  email,
  phone,
  validateRules,
  externalEmailValidation,
  externalPhoneValidation,
  required,
  stringWithoutNumbers,
  noSpecialCharacters,
};

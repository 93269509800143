import translate from 'tg-core-components/lib/lib/utils/translate';

export const getTranslation = (intl, type) => {
  const oddsType = {
    decimal: () =>
      translate(
        { id: 'label.odds_type.decimal', defaultMessage: 'Decimal' },
        intl
      ),
    fractional: () =>
      translate(
        { id: 'label.odds_type.fractional', defaultMessage: 'Fractional' },
        intl
      ),
    american: () =>
      translate(
        { id: 'label.odds_type.american', defaultMessage: 'American' },
        intl
      ),
    hongkong: () =>
      translate(
        { id: 'label.odds_type.hongKong', defaultMessage: 'HongKong' },
        intl
      ),
    malay: () =>
      translate({ id: 'label.odds_type.malay', defaultMessage: 'Malay' }, intl),
    indo: () =>
      translate({ id: 'label.odds_type.indo', defaultMessage: 'Indo' }, intl),
    asian: () =>
      translate({ id: 'label.layout.asian', defaultMessage: 'Asian' }, intl),
    classic: () =>
      translate(
        { id: 'label.layout.classic', defaultMessage: 'Classic' },
        intl
      ),
  };

  return oddsType[type]();
};

import React from 'react';

import './style.css';

const ProgressBar = ({ completed }) => {
  return (
    <div className="ProgressBar">
      <div className="ProgressBar__label">{completed.toFixed(0)}%</div>
      <div className="ProgressBar__bar">
        <div
          className="ProgressBar__bar__progress"
          style={{ backgroundSize: `${completed}% 100%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import marked from 'marked';
import OffCanvasLayout from '@components/OffCanvasLayout';
import withRouteData, { Loader } from '@utils/withRouteData';
import { loadContents } from '@actions/content';
import { onSuccess } from '@actions/payment';
import { withConfig } from '@config';
import { initCashier } from '@utils/cashier';
import Wallets from '@components/Wallets';
import WithdrawBonusPolicies from '@components/WithdrawBonusPolicies';

import Withdraw from '@pages/Withdraw';

@withConfig
@withRouteData
@connect(
  state => ({
    user: state.player.user,
    jurisdiction: state.app.jurisdiction,
    isAuthenticated: state.player.isAuthenticated,
    sessionId: state.player.sessionId,
    pages: state.content.pages.data,
    locale: state.app.locale,
    wallet: state.wallet,
    sections: state.content.sections.data,
    bonuses: state.bonus.data,
    activeCurrency: state.player.activeCurrency,
  }),
  { onSuccess }
)
class WithdrawContainer extends Component {
  constructor(props) {
    super(props);
    this.cashierRef = React.createRef();
  }
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#withdraw'],
    },
    {
      name: 'payment',
    },
    {
      name: 'section',
      identifiers: ['withdraw-balance-info'],
    },
  ]);

  state = {
    loading: true,
  };

  componentDidMount = () => {
    const { user, config, sessionId, locale, onSuccess } = this.props;

    if (
      config.piqCashier &&
      user &&
      document.getElementById('cashier')?.childNodes?.length === 0
    ) {
      const { cashierRef } = this;
      initCashier({
        cashierRef,
        method: 'withdrawal',
        config,
        user,
        sessionId,
        locale,
        onSuccess: () => onSuccess({ method: 'withdraw' }),
        onReady: () => this.setState({ loading: false }),
      });
    }
  };

  onSelectMethodorAccount = () => {
    const offCanvasContent = this.offCanvasLayout?.querySelector(
      '.canvas-content'
    );
    if (offCanvasContent) {
      offCanvasContent.scrollTop = offCanvasContent.scrollHeight;
    }
  };

  render() {
    const {
      location,
      user,
      jurisdiction,
      pages,
      config,
      sections,
      wallet,
      bonuses,
      activeCurrency,
    } = this.props;

    const page = pages?.find(p => p.url === '#withdraw');

    const balanceInfo = sections?.find(
      s => s.identifier === 'withdraw-balance-info'
    );

    if (!user) return null;

    if (
      !config?.disableActivation &&
      user &&
      !user.IsVerified &&
      !['sga', 'dga', 'curacao'].includes((jurisdiction || '').toLowerCase())
    ) {
      return (
        <Redirect
          to={{ hash: 'dashboard/activate' }}
          state={{
            referrer: location ? location.pathname : '/',
          }}
        />
      );
    }

    return (
      <OffCanvasLayout
        className="AccountWithdrawContainer WithdrawContainer"
        ref={ref => (this.offCanvasLayout = ref)}>
        <div className="payment-wrapper">
          <Loader isLoading={!page}>
            {page?.content && (
              <header>
                <div
                  dangerouslySetInnerHTML={{ __html: marked(page.content) }}
                />
              </header>
            )}

            {wallet && user && (
              <Wallets
                wallets={[
                  {
                    label: (
                      <div
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: marked(balanceInfo?.content || ''),
                        }}
                      />
                    ),
                    currency: activeCurrency || user.Currency,
                    balance: wallet.data?.Real?.Balance,
                  },
                ]}
              />
            )}

            <WithdrawBonusPolicies bonuses={bonuses} />
          </Loader>

          {config.piqCashier ? (
            <div ref={this.cashierRef} id="cashier" />
          ) : (
            <Withdraw onSelectMethodOrAccount={this.onSelectMethodorAccount} />
          )}
        </div>
      </OffCanvasLayout>
    );
  }
}

export default WithdrawContainer;

import React from 'react';
import { useInnerDimensions } from '@utils/hooks';
import { useUrls } from '@utils/urls';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'tg-core-components';
import { getBetssonContext } from 'tg-core-redux/lib/modules/sportsbook/action';
import selectCurrency from '../../selectors/selectCurrency';
import { getConfig } from '@utils/config';

const Betsson = ({
  locale,
  jurisdiction,
  device,
  sessionId,
  getWallet,
  getWallets,
  location,
  history,
  wallet,
  ipCountryCode,
  isAuthenticated,
}) => {
  const [platform, setPlatform] = useState(device);
  const [isLoading, setIsLoading] = useState(false);
  const [src, setSrc] = useState(null);
  const [responseNotes, setResponseNotes] = useState('');
  const [routeData, setRouteData] = useState();
  const iframeRef = useRef(null);
  const { deposit, signIn, signUp } = useUrls();
  const refUrl = routeData?.deeplinkMeta
    ? `?ref_url=${encodeURIComponent(`?${routeData?.deeplinkMeta}`)}`
    : '';

  const [innerWidth, innerHeight, updateInnerHeight] = useInnerDimensions(1000);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const updateBalance = () => {
    getWallet(sessionId);
    getWallets(sessionId);
  };

  const postMessage = data =>
    iframeRef.current.contentWindow.postMessage(data, '*');

  const receiveMessage = event => {
    if (!event) return;

    switch (event.type) {
      case 'ready':
        scrollToTop();
        setIsLoading(false);
        break;
      case 'requestActionOut':
        handleAction(event.payload.action);
        break;
      case 'interaction':
        scrollToTop();
        break;
      case 'betPlacement':
        if (event.version === 'v3' && event.payload.status === 'success') {
          updateBalance();
        }
        break;
      case 'cashout':
        if (event.version === 'v2' && event.payload.status === 'success') {
          updateBalance();
        }
        break;
      case 'routeChangeOut':
        if (event.payload) {
          setRouteData(event.payload);
        }
        if (
          event.payload.externalUrl === '/' &&
          !location.pathname.startsWith('/sports')
        ) {
          history.replace('/sports');
        }

        if (
          event.payload.externalUrl === '/live' &&
          !location.pathname.startsWith('/live-betting')
        ) {
          history.replace('/live-betting');
        }
        break;
      case 'routeData':
        if (event.payload) {
          setRouteData(event.payload);
        }
        break;
    }
  };

  const handleAction = action => {
    switch (action) {
      case 'login':
        history.push({ hash: signIn().hash, pathname: `sports${refUrl}` });
        break;
      case 'deposit':
        history.push({ hash: deposit().hash, pathname: `sports${refUrl}` });
        break;
      case 'register':
        history.push({ hash: signUp().hash, pathname: `sports${refUrl}` });
        break;
    }
  };

  useEffect(() => {
    setPlatform(innerWidth < 1024 ? 'Mobile' : 'Desktop');
  }, [innerWidth]);

  useEffect(() => {
    if (!isLoading && wallet) {
      postMessage({
        type: 'balanceUpdate',
        payload: {
          value: wallet.Total.Balance,
        },
      });
    }
  }, [isLoading, wallet]);

  useEffect(() => {
    const message = e => receiveMessage(e.data);
    window.addEventListener('message', message);

    return () => window.removeEventListener('message', message);
  });

  const mapLocale = locale => {
    const supportedLocales = getConfig(jurisdiction).betsson?.locales;
    locale = locale === 'nb-NO' ? 'no' : locale.split('-')[0];

    if (!supportedLocales?.includes(locale))
      return supportedLocales?.[0] || 'en';

    return locale;
  };

  const dispatch = useDispatch();
  const currency = useSelector(selectCurrency) || 'EUR';
  const query =
    location.pathname === '/live-betting'
      ? '?version=1&page=liveLobby'
      : location.search;

  const deepLinkString = query.replace('?', '').replaceAll('&', ';');

  useEffect(() => {
    setIsLoading(true);
    setSrc(null);

    dispatch(
      getBetssonContext({
        playerSessionID: isAuthenticated && sessionId ? sessionId : null,
        Language: mapLocale(locale),
        Currency: currency,
        Jurisdiction: jurisdiction,
        UserAgent: window.navigator.userAgent,
        CountryCode: ipCountryCode || 'MT',
        Platform: platform,
        IframeSetup: {
          AllowedParentIFrameBaseUrl: window.location.origin,
        },
        DeepLinking: deepLinkString,
      })
    )
      .then(res => {
        setSrc(res?.data?.IFrameHelper?.AbsoluteUri),
          setResponseNotes(
            res?.data?.ResponseNotes
              ? `${res?.data?.ResponseCode}: ${res?.data?.ResponseNotes}`
              : 'Unknown error'
          );
      })
      .catch(error => console.error(error));
  }, [
    locale,
    dispatch,
    deepLinkString,
    ipCountryCode,
    jurisdiction,
    currency,
    sessionId,
    isAuthenticated,
    platform,
  ]);

  if (!src && responseNotes && !responseNotes.includes('Success')) {
    console.error(responseNotes);
    return <div>Unable to load sportsbook...</div>;
  }

  return (
    <div
      className="Betsson"
      style={{
        '--inner-width': `${innerWidth}px`,
        '--inner-height': `${innerHeight}px`,
      }}>
      {isLoading && <Loader inline />}

      <iframe
        src={src}
        ref={iframeRef}
        onLoad={() => updateInnerHeight()}></iframe>

      <Helmet>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,minimal-ui"
        />
      </Helmet>
    </div>
  );
};

export default Betsson;

import { getConfig } from '@config';
import translate from 'tg-core-components/lib/lib/utils/translate';

export const initChat = async ({ jurisdiction, ipCountry, ...rest }) => {
  const config = getConfig(jurisdiction, ipCountry);

  switch (config.chat.provider) {
    case 'ladesk':
      return initLADesk(rest);
    case 'livechat':
      return initLiveChat({ ...rest, config });
    case 'zendesk':
      return initZendesk({ ...rest, config });
    default:
      return initFreshChat(rest);
  }
};

export const initLADesk = () => {
  /**
   * Append CSS class to the widget's container.
   */
  __CLIENT__ &&
    setTimeout(() => {
      const containerElement = document.querySelector(
        `#${window?.ladeskButton?.elementId} > div`
      );
      containerElement?.classList.add('ladesk');
    }, 3000);

  return null;
};

export const initLiveChat = ({ locale, config }) => {
  const liveChat = config.liveChat;

  if (liveChat) {
    window.__lc.group = liveChat[locale] || liveChat.default;
  }
  return null;
};

export const initZendesk = ({ config, locale, user }) => {
  window.zESettings = {
    webWidget: {
      chat: {
        departments: {
          enabled: config.chat.departments,
          select: config.chat.departments[0],
        },
        title: config.chat.title,
        prechatForm: {
          departmentLabel: {
            '*': 'Choose a language',
            ja: '言語を選択',
            no: 'Velg språket',
            fi: 'Valitse kieli',
            de: 'Sprache auswählen',
          },
        },
        tags: [...(config.chat?.tags || []), ...(user?.Tags || [])],
      },
      color: {
        // Text color inside the widget is set through admin panel.
        theme: config.chat?.colors?.theme,
        launcher: config.chat?.colors?.launcher,
        launcherText: config.chat?.colors?.launcherText,
        button: config.chat?.colors?.button,
      },
    },
  };

  if (window.zE) {
    window.zE('webWidget:on', 'open', () =>
      document.getElementById('launcher').classList.add('open')
    );

    window.zE('webWidget:on', 'close', () =>
      document.getElementById('launcher').classList.remove('open')
    );

    if (locale) {
      let formattedLocale = locale.slice(0, 2);

      if (locale === 'nb-NO') {
        formattedLocale = 'no';
      }

      window.zE('webWidget', 'setLocale', formattedLocale);
    }
  }
};

export const authenticateZendesk = () => {
  if (window.zE) {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: function(callback) {
              fetch('/verify-user').then(res => {
                res.text().then(jwt => {
                  callback(jwt?.replace(/\"/g, ''));
                });
              });
            },
          },
        },
      },
    });

    window.zE('webWidget', 'chat:reauthenticate');
  }
};

export const initFreshChat = async ({
  intl,
  config,
  locale,
  user,
  playerProperties,
  setPlayerProperties,
  sessionId,
}) => {
  if (!__CLIENT__ || !window.fcWidget || window.fcWidget.isInitialized())
    return false;

  let restoreId;

  if (user) {
    const property =
      playerProperties &&
      playerProperties.find(property => property.Name === 'fcRestoreId');
    restoreId = property && property.Value;
  }

  const chatConfig = {
    host: config.host,
    token: config.token,
    siteId: config.brand,
    widgetUuid: config.widgetUuid,
    locale: locale.split('-')[0],
    restoreId,
    tags: config.tags,
    config: {
      agent: {
        hidePic: true,
      },
      headerProperty: {
        appName: config.appName,
        appLogo: config.appImage,
        backgroundColor: config.config.headerProperty.backgroundColor,
        foregroundColor: config.config.headerProperty.foregroundColor,
      },

      content: {
        placeholders: {
          search_field: translate(
            {
              id: 'freshchat.placeholders.search_field',
              defaultMessage: 'Search',
            },
            intl
          ),
          reply_field: translate(
            {
              id: 'freshchat.placeholders.reply_field',
              defaultMessage: 'Reply',
            },
            intl
          ),
          csat_reply: translate(
            {
              id: 'freshchat.placeholders.csat_reply',
              defaultMessage: 'Add your comments here',
            },
            intl
          ),
        },
        actions: {
          csat_yes: translate(
            {
              id: 'freshchat.actions.csat_yes',
              defaultMessage: 'Yes',
            },
            intl
          ),
          csat_no: translate(
            {
              id: 'freshchat.actions.csat_no',
              defaultMessage: 'No',
            },
            intl
          ),
          push_notify_yes: translate(
            {
              id: 'freshchat.actions.push_notify_yes',
              defaultMessage: 'Yes',
            },
            intl
          ),
          push_notify_no: translate(
            {
              id: 'freshchat.actions.push_notify_no',
              defaultMessage: 'No',
            },
            intl
          ),
          tab_faq: translate(
            {
              id: 'freshchat.actions.tab_faq',
              defaultMessage: 'Solutions',
            },
            intl
          ),
          tab_chat: translate(
            {
              id: 'freshchat.actions.tab_chat',
              defaultMessage: 'Chat',
            },
            intl
          ),
          csat_submit: translate(
            {
              id: 'freshchat.actions.csat_submit',
              defaultMessage: 'Submit',
            },
            intl
          ),
        },
        headers: {
          chat: translate(
            {
              id: 'freshchat.headers.chat',
              defaultMessage: 'Chat with Us',
            },
            intl
          ),
          chat_help: translate(
            {
              id: 'freshchat.headers.chat_help',
              defaultMessage: 'Reach out to us if you have any questions',
            },
            intl
          ),
          faq: translate(
            {
              id: 'freshchat.headers.faq',
              defaultMessage: 'Solution Articles',
            },
            intl
          ),
          faq_help: translate(
            {
              id: 'freshchat.headers.faq_help',
              defaultMessage: 'Browse our articles',
            },
            intl
          ),
          faq_not_available: translate(
            {
              id: 'freshchat.headers.faq_not_available',
              defaultMessage: 'No Articles Found',
            },
            intl
          ),
          faq_search_not_available: translate(
            {
              id: 'freshchat.headers.faq_search_not_available',
              defaultMessage: 'No articles were found for {{query}}',
            },
            intl
          ),
          faq_useful: translate(
            {
              id: 'freshchat.headers.faq_useful',
              defaultMessage: 'Was this article helpful?',
            },
            intl
          ),
          faq_thankyou: translate(
            {
              id: 'freshchat.headers.faq_thankyou',
              defaultMessage: 'Thank you for your feedback',
            },
            intl
          ),
          faq_message_us: translate(
            {
              id: 'freshchat.headers.faq_message_us',
              defaultMessage: 'Message Us',
            },
            intl
          ),
          push_notification: translate(
            {
              id: 'freshchat.headers.push_notification',
              defaultMessage:
                "Don't miss out on any replies! Allow push notifications?",
            },
            intl
          ),
          csat_question: translate(
            {
              id: 'freshchat.headers.csat_question',
              defaultMessage: 'Did we address your concerns??',
            },
            intl
          ),
          csat_yes_question: translate(
            {
              id: 'freshchat.headers.csat_yes_question',
              defaultMessage: 'How would you rate this interaction?',
            },
            intl
          ),
          csat_no_question: translate(
            {
              id: 'freshchat.headers.csat_no_question',
              defaultMessage: 'How could we have helped better?',
            },
            intl
          ),
          csat_thankyou: translate(
            {
              id: 'freshchat.headers.csat_thankyou',
              defaultMessage: 'Thanks for the response',
            },
            intl
          ),
          csat_rate_here: translate(
            {
              id: 'freshchat.headers.csat_rate_here',
              defaultMessage: 'Submit your rating here',
            },
            intl
          ),
          channel_response: {
            offline: translate(
              {
                id: 'freshchat.headers.channel_response.offline',
                defaultMessage:
                  'We are currently away. Please leave us a message',
              },
              intl
            ),
            online: {
              minutes: {
                one: translate(
                  {
                    id: 'freshchat.headers.channel_response.online.minutes.one',
                    defaultMessage: 'Currently replying in {!time!} minutes',
                  },
                  intl
                ),
                more: translate(
                  {
                    id:
                      'freshchat.headers.channel_response.online.minutes.more',
                    defaultMessage: 'Typically replies in {!time!} minutes',
                  },
                  intl
                ),
              },
              hours: {
                one: translate(
                  {
                    id: 'freshchat.headers.channel_response.online.hours.one',
                    defaultMessage: 'Currently replying in under an hour',
                  },
                  intl
                ),
                more: translate(
                  {
                    id: 'freshchat.headers.channel_response.online.hours.more',
                    defaultMessage: 'Typically replies in {!time!} hours',
                  },
                  intl
                ),
              },
            },
          },
        },
      },
    },
  };

  if (user) {
    chatConfig.externalId = `${user.Username}/${user.Id}`;
  }

  window.fcWidget.init(chatConfig);

  window.fcWidget.user.get(res => {
    if (res.status === 200) return;

    window.fcWidget.on('user:created', res => {
      if (res && res.status === 200 && res.data.restoreId && sessionId) {
        const playerProperty = {
          Properties: [
            {
              Name: 'fcRestoreId',
              Type: 'Text',
              Value: res.data.restoreId,
            },
          ],
        };

        setPlayerProperties(sessionId, playerProperty);
      }
    });
  });

  if (user) {
    window.fcWidget.user.setFirstName(user.FirstName);
    window.fcWidget.user.setLastName(user.LastName);
    window.fcWidget.user.setEmail(user.Email);
    window.fcWidget.user.setPhone(user.MobilePhoneNumber);

    window.fcWidget.user.setProperties({
      Authenticated: `yes/${user.Username}`,
      Language: locale,
      Site: config.brand,
      Id: user.Id,
      IsVerified: `${user.IsVerified} (${user.Jurisdiction})`,
      UserCountry: user.Country,
      Jurisdiction: user.Jurisdiction,
      BirthDate: user.BirthDate,
      Currency: user.Currency,
      IsComplete: user.IsComplete,
      LastDeposit: user.LastDeposit,
      DepositCount: user.DepositCount,
      WithdrawCount: user.WithdrawCount,
    });
  } else {
    window.fcWidget.user.setProperties({
      Authenticated: 'no/Anonymous',
      Language: locale,
      Site: config.brand,
    });
  }
};

export const clearChatHistory = jurisdiction => {
  const chat = getConfig(jurisdiction).chat;

  chat.provider === 'freshchat' &&
    window.fcWidget &&
    window.fcWidget.user.clear();
};

export const startChat = jurisdiction => {
  const chat = getConfig(jurisdiction).chat;

  if (__CLIENT__ && chat.provider === 'ladesk' && window.ladeskButton) {
    window.ladeskButton.onClick();
  } else if (__CLIENT__ && chat.provider === 'freshchat' && window.fcWidget) {
    window.fcWidget.open();
  } else if (__CLIENT__ && chat.provider === 'livechat' && window.LC_API) {
    window.LC_API.open_chat_window();
  } else if (__CLIENT__ && chat.provider === 'zendesk' && window.zE) {
    window.zE('messenger', 'open');
  }
};

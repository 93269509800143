import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import withRouteData from '@utils/withRouteData';
import BasePage from '@pages/BasePage';
import { withCookies } from 'react-cookie';
import PayNPlay from '@components/PayNPlay';
import queryString from 'query-string';
import Features from '@components/Features';
import Carousel from '@components/Carousel';
import TextSlider from '@components/TextSlider';
import IconSlider from '@components/IconSlider';
import cn from 'classnames';
import marked from 'marked';
import IndexHeader, { SignInButton } from '@components/IndexHeader';
import arrow from '@images/seo-arrow.png';
import { withConfig } from '@config';
import { Translate } from 'tg-core-components';
import { withOverlayRouter } from '@components/OverlayRouter';
import Banners, { selectBannersProps } from '@components/Banners';

import './style.css';

export const STATE = {
  INDEX: 'index',
  DEPOSIT: 'signup',
  SIGNIN: 'signin',
};

@withCookies
@withConfig
@withOverlayRouter
@withRouteData
@connect(state => ({
  sets: state.content.sets.data,
  sections: state.content.sections.data,
  pages: state.content.pages.data,
  isAutoLoggedIn: state.player.isAutoLoggedIn,
  isAuthenticated: state.player.isAuthenticated,
  requestApproveTac: state.player.requestApproveTac,
  browser: state.app.browser,
  contentConfig: state.content.config.data,
  device: state.app.device,
  bannersProps: selectBannersProps(state),
}))
class IndexContainerPnp extends Component {
  state = {
    state: this.props.state || STATE.INDEX,
  };
  seoBodyRef = createRef();

  onChangeState = (event, state, history) => {
    event && event.preventDefault();
    const { in_banner, state: currentState } = this.state;

    if (in_banner && state === STATE.INDEX)
      state = currentState === STATE.SIGNIN ? STATE.DEPOSIT : STATE.SIGNIN;

    this.setState({
      state,
    });

    if (state === STATE.INDEX) history.push('/');
  };

  handleScroll = () => {
    if (this.seoBodyRef && this.seoBodyRef.current) {
      this.seoBodyRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  componentDidUpdate() {
    if (
      this.props.history.action === 'POP' &&
      this.state.state === STATE.DEPOSIT
    )
      this.setState({
        state: STATE.INDEX,
      });
  }

  render() {
    const {
      config,
      pages,
      sets,
      sections,
      isAuthenticated,
      device,
      contentConfig,
      isAutoLoggedIn,
      location,
      history,
      overlayMatches,
      requestApproveTac,
      bannersProps,
    } = this.props;

    const { state } = this.state;

    const query = queryString.parse(location.search);

    const {
      indexFeatures,
      showFetchBalanceIndexHeader,
      indexContainer,
      stickyFooterOnIndex,
      redirectCasino = true,
      disableIndexPage,
      isPnp,
      signInRedirect,
    } = config;

    const isOnStartPlaying = location.pathname.match(
      /\/(start-playing|fetch-balance|instant-deposit)/
    );

    // Redirect to casino if index is disabled or if signed in from the sign up - stay until activated (epic) or sidebar is closed
    if (
      (disableIndexPage && !isOnStartPlaying) ||
      (redirectCasino &&
        !isOnStartPlaying &&
        isAuthenticated &&
        !requestApproveTac &&
        (!isAutoLoggedIn || overlayMatches.length === 0))
    )
      return <Redirect to={{ ...location, pathname: '/casino' }} />;

    const page = pages?.find(p => p.url === 'start-page');
    const features = sets?.find(s => s.identifier === 'index-features');

    const banners = sets.find(s => s.identifier === 'index-background-banners');

    const brandDescription = sections?.find(
      s => s.identifier === 'index-brand-description'
    );
    const extraInfo = sections?.find(i => i.identifier === 'index-extra-info');
    const stepsIntervalTime = contentConfig?.find(
      i => i.key === 'IndexStepsIntervalTime'
    );

    const accountIndexContainer = indexContainer === 'IndexContainerAccount';
    const logoLinkUrl = accountIndexContainer ? '/' : '/casino';

    const currentIndexFeatureConfig =
      device?.toLowerCase() === 'mobile'
        ? indexFeatures.mobile
        : indexFeatures.desktop;

    const indexFeaturesToRender =
      currentIndexFeatureConfig === 'row' ? (
        <Features features={features} />
      ) : currentIndexFeatureConfig === 'slider' ? (
        <Carousel interval={stepsIntervalTime} features={features} />
      ) : currentIndexFeatureConfig === 'iconSlider' ? (
        <IconSlider features={features} interval={stepsIntervalTime} />
      ) : (
        <TextSlider features={features} interval={stepsIntervalTime} />
      );
    const [seoHeader, ...seoContent] = page?.content?.split('\n\n') || [];

    return (
      <BasePage
        className="IndexContainerPnp"
        page={page}
        hideHeader
        hideStickyFooter={!stickyFooterOnIndex}
        hideBackground={banners}>
        <Helmet>
          {page && <title>{page.title}</title>}
          {page && <meta name="description" content={page.description} />}
        </Helmet>

        {banners && <Banners banners={banners} {...bannersProps} />}

        <IndexHeader
          logoLinkUrl={logoLinkUrl}
          onLogoClick={e => {
            if (accountIndexContainer)
              return history.push(disableIndexPage ? '/casino' : '/');

            !accountIndexContainer &&
            !isAuthenticated &&
            state != STATE.INDEX &&
            disableIndexPage
              ? history.push(signInRedirect || '/casino')
              : this.onChangeState(e, STATE.INDEX, history);
          }}
          showSignInButton={
            state === STATE.INDEX && showFetchBalanceIndexHeader
          }
          signInButtonClick={e => {
            if (isPnp) {
              e.preventDefault();
              this.setState({ state: STATE.SIGNIN });
            }
          }}
          isPnp={isPnp}
          showDashboardButton={!stickyFooterOnIndex || device === 'Desktop'}
          homeButtonLink={accountIndexContainer ? '/' : '/casino'}
        />

        <div
          className={cn('index-container-full', {
            'seo-header': seoHeader && state == STATE.INDEX,
          })}>
          <div
            className={cn('index-background', {
              'index-background-active': STATE.INDEX !== state,
            })}
          />
          <div className="fixed-position">
            {[STATE.INDEX, STATE.DEPOSIT].includes(state) &&
              brandDescription &&
              brandDescription.content && (
                <div
                  className="brand-description"
                  dangerouslySetInnerHTML={{
                    __html: marked(brandDescription.content),
                  }}
                />
              )}
            {state == STATE.INDEX && (
              <div className="actions">
                <Link
                  className="button primary"
                  to="#sign-up"
                  onClick={e => {
                    if (isPnp) {
                      e.preventDefault();

                      this.onChangeState(e, STATE.DEPOSIT);
                    }
                  }}>
                  {isPnp ? (
                    <Translate
                      id="action.pay_and_play"
                      defaultMessage="Play now"
                    />
                  ) : (
                    <Translate id="action.sign-up" defaultMessage="Sign up" />
                  )}
                </Link>
                {!showFetchBalanceIndexHeader && (
                  <SignInButton config={config} />
                )}
              </div>
            )}

            {state === STATE.INDEX && extraInfo && extraInfo.content && (
              <div
                className="extra-info"
                dangerouslySetInnerHTML={{
                  __html: marked(extraInfo.content),
                }}
              />
            )}
            {state == STATE.DEPOSIT && (
              <PayNPlay
                onClose={() => this.onChangeState(null, STATE.INDEX, history)}
              />
            )}
            {[STATE.INDEX, STATE.DEPOSIT].includes(state) &&
              features &&
              indexFeaturesToRender}
            {state == STATE.SIGNIN && (
              <PayNPlay
                fetchBalance={true}
                onClose={() => this.onChangeState(null, STATE.INDEX, history)}
              />
            )}
          </div>

          {state === STATE.INDEX && seoHeader && (
            <div className="seo-header">
              <div
                className="seo-header-content"
                dangerouslySetInnerHTML={{
                  __html: marked(seoHeader),
                }}
              />

              <div className="seo-arrow" onClick={this.handleScroll}>
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          )}
        </div>

        {state === STATE.INDEX && seoContent.length > 0 && (
          <div className="seo-body" ref={this.seoBodyRef}>
            <div
              className="seo-body-content"
              dangerouslySetInnerHTML={{
                __html: marked(seoContent.join('\n\n')),
              }}
            />
          </div>
        )}
      </BasePage>
    );
  }
}

export default IndexContainerPnp;

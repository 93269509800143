import { combineReducers } from 'redux';
import { createResource } from '@utils/reducer';
import identity from 'lodash/identity';
import player from './player';
import playerProperties from './playerProperties';
import app from './app';
import content from './content';
import toast from './toast';
import notification from './notification';
import devcode from './devcode';
import paymentStatus from './paymentStatus';
import kyc from './kyc';
import translation from './translation';
import favorite from './favorite';
import event from './event';
import responsible_gaming from './responsible_gaming';
import leaderboards from './leaderboards';
import internalMessage from './internalMessage';
import quickDeposit from './quickDeposit';
import alerts from './alerts';
import currencies from './currencies';
import casino from './casino';

export default combineReducers({
  player,
  playerProperties,
  content,
  alerts,
  app,
  toast,
  notification,
  translation,
  paymentTransactions: createResource('GET_PAYMENT_TRANSACTIONS', identity, []),
  gameTransactions: createResource('GET_GAMEPLAY_TRANSACTIONS', identity, []),
  bonus: createResource('GET_BONUSES', identity, []),
  sportsbookTransactions: createResource(
    'GET_SPORTSBOOK_TRANSACTIONS',
    identity,
    []
  ),
  bingoTransactions: createResource('GET_BINGO_TRANSACTIONS', identity, []),
  preClaimBonus: createResource('PRECLAIM_BONUS', identity, []),
  bonusOffer: createResource('GET_OFFERS', identity, []),
  wallet: createResource('GET_WALLET'),
  wallets: createResource('GET_WALLETS'),
  optInOut: createResource('GET_OPT_IN_OUTS'),
  exchangeRates: createResource('GET_EXCHANGE_RATES'),
  internalMessages: createResource(
    'GET_MESSAGES',
    identity,
    [],
    internalMessage
  ),
  kyc,
  devcode,
  paymentStatus,
  favorite,
  event,
  responsible_gaming,
  leaderboards,
  quickDeposit,
  currencies,
  casino,
});

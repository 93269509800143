import React, { Component } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import BetslipTab from '@components/BetslipTab/index';
import { connect } from 'react-redux';
import { open as quickDepositOpen } from '@actions/quickDeposit';

import './style.css';

@connect(
  state => ({
    device: state.app.device,
    wallet: state.wallet,
  }),
  {
    quickDepositOpen,
  }
)
@withRouter
class Sbtech extends Component {
  langIds = [
    { id: 'sv', code: '722' },
    { id: 'en', code: this.props.jurisdiction === 'sga' ? '1632' : '721' },
    { id: 'fi', code: '739' },
    { id: 'nb', code: '740' },
    { id: 'de', code: '817' },
    { id: 'da', code: '1487' },
    { id: 'es', code: '2100' }, // LATAM
    { id: 'es-ES', code: '1736' }, // Spain
    { id: 'en-US', code: '1735' }, // Spain
  ];
  iframe = null;

  _receiveMessage = e => {
    if (!this.iframe || !e.data) return;

    const {
      wallet,
      user,
      token,
      isAuthenticated,
      sessionId,
      device,
      quickDepositOpen,
      config: {
        tgplay: { host, skin },
      },
      getWallet,
      getWallets,
    } = this.props;

    switch (e.data.name) {
      case 'sbtech_status':
        if (!this.iframe) return;

        this.iframe.contentWindow.postMessage(
          {
            name: e.data.name,
            data: {
              uid: user ? user.Id + '' : '',
              token: token ? token : '',
              status: token ? 'real' : 'anon',
              message: '',
              balance: wallet ? wallet.Total.Balance + '' : '',
            },
          },
          '*'
        );
        break;

      case 'sbtech_refreshSession':
        if (!this.iframe) return;

        Promise.all([getWallet(sessionId), getWallets(sessionId)]).then(() => {
          this.iframe.contentWindow.postMessage(
            {
              name: e.data.name,
              data: {
                status: token ? 'success' : 'failure',
                message: '',
                balance: wallet ? wallet.Total.Balance + '' : '',
              },
            },
            '*'
          );
        });
        break;

      case 'sbtech_setDeviceTypeAndOrientation':
        if (e.data.value.deviceType === 'IsTablet') {
          this.sbtechOrientationChange();
          window.addEventListener('resize', self.sbtechOrientationChange);
        } else {
          this.iframe.style.height = e.data.value.height + 'px';
        }
        break;

      case 'sbtech_openQuickDeposit': {
        const betAmount = e.data.value.amount;
        const balance = wallet && wallet?.data?.Total?.Balance;
        if (
          device === 'Mobile' &&
          wallet &&
          wallet?.data?.Total?.Balance < betAmount
        ) {
          const depositAmount = Number(betAmount) - balance;
          typeof quickDepositOpen === 'function' &&
            quickDepositOpen({ amount: Math.ceil(depositAmount) });
        }
        break;
      }

      case 'sbtech_liveGamePromoReady': {
        if (isAuthenticated && sessionId) {
          this._sendMessage('sbtech_gamePromoIframeSessionId', {
            session: sessionId,
            baseUrl: `${host}/${skin}`,
          });
        }
        break;
      }

      case 'sbtech_setCurrentLocation':
      case 'sbtech_setCurrentLocationMobile':
        break;

      case 'sbtech_placeBets':
        window.scrollTo(0, 0);
        break;

      case 'sbtech_scrollToTop':
        window.scrollTo(0, 0);
        break;
    }
  };

  _sendMessage = (name, value) => {
    if (!this.iframe) return;

    this.iframe.contentWindow.postMessage(
      {
        name,
        value,
      },
      '*'
    );
  };

  componentDidMount() {
    window.addEventListener('message', this._receiveMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this._receiveMessage);
  }

  sbtechOrientationChange() {
    const space = 100 + 50; // sticky footer + sticky header

    if (this.iframe)
      this.iframe.style.height = window.innerHeight - space + 'px';
  }

  render() {
    const { match, location, token, locale, config } = this.props;
    const langId = this.langIds.find(l => l.id === locale.split('-')[0]);

    let url = config.sbtech.host;
    let query = queryString.parse(location.search);

    // If query is present, use mobilegoto page.
    // If not, use the one provided in the request.
    if (Object.keys(query).length > 0) url += '/mobilegoto.aspx';
    else url += match.url;

    query.stoken = token ? token : 'logout';
    if (langId) query.langid = langId.code;

    // Rename from old system sbtech_LineID to LineID etc.
    Object.keys(query).forEach(i => {
      if (i.match(/^sbtech_/)) {
        query[i.replace(/^sbtech_/, '')] = query[i];
        delete query[i];
      }
    });

    url += '?' + queryString.stringify(query);

    return (
      <div>
        <BetslipTab provider="sbtech" />
        <iframe
          id="sbtech"
          className="container"
          ref={r => (this.iframe = r)}
          src={url}
          data-hj-allow-iframe
        />
      </div>
    );
  }
}

export default Sbtech;

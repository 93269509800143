export default (state = [], action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return [
        ...state,
        {
          show: true,
          ...action.payload,
        },
      ];

    case 'HIDE_NOTIFICATION':
      return [
        { ...state[0], show: false, direction: action.payload },
        ...state.slice(1),
      ];

    case 'REMOVE_NOTIFICATION':
      return [...state.slice(1)];

    default:
      return state;
  }
};

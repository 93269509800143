import React from 'react';
import SwishLoader from '@components/Loader/SwishLoader';
import { injectIntl } from 'react-intl';

import './swish.css';

const Swish = ({ intl: { formatMessage }, url }) => {
  window.location = `swish://`;

  return (
    <div className="swish-wrapper">
      <iframe
        src={url}
        ref={ref => (this.swishIframe = ref)}
        id="swishIframe"
      />

      <img src="/images/swish_logo.svg" alt="swish" className="logo" />

      <SwishLoader />

      <p className="info">
        {formatMessage({
          id: 'label.swish-info',
          defaultMessage:
            'Starta Swish-appen för att slutföra din transaktion.',
        })}
      </p>
    </div>
  );
};

export default injectIntl(Swish);

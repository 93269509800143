import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'tg-core-components';
import marked from 'marked';

import Countdown from '@components/Countdown';
import Icon from 'tg-core-components/lib/components/Icon';

const Tournament = ({
  tournament: { image, summary, disclaimer, url, title },
  leaderboard: { PlayerCount = 0, StartDate, EndDate },
  playerInfo: { Position },
}) => {
  return (
    <div className="Tournament">
      {image && (
        <img
          src={`${image.file.url}?w=765&h=390&fit=fill`}
          alt={image.description || image.title}
        />
      )}
      <div className="tournament-content-wrapper">
        <div className="additional-information">
          {StartDate && EndDate && (
            <div className="info time">
              <Icon icon="clock" />
              <Countdown startDate={StartDate} endDate={EndDate} />
            </div>
          )}

          <div className="info player-count">
            <Icon icon="users" />
            {PlayerCount}
          </div>

          {Position && (
            <div className="info position">
              <Icon icon="hashtag" />
              {Position}
            </div>
          )}
        </div>

        {title && <div className="title">{title}</div>}

        {summary && (
          <div
            className="summary"
            dangerouslySetInnerHTML={{ __html: marked(summary) }}
          />
        )}

        <Link to={`/tournaments/${url}`} className="button primary">
          <Translate id="action.read_more" defaultMessage="Read more" />
        </Link>

        {disclaimer && (
          <div
            className="disclaimer"
            dangerouslySetInnerHTML={{
              __html: marked(disclaimer),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Tournament;

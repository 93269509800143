import React from 'react';
import marked from 'marked';
import { Button, Icon } from 'tg-core-components';
import { PAGES } from '..';

import './style.css';

const Question = ({
  day,
  onClickWrongAnswer,
  onClickCorrectAnswer,
  setPage,
}) => {
  return (
    <div className="Question">
      <div className="question-close" onClick={() => setPage(PAGES.CALENDAR)}>
        <Icon icon="close" />
      </div>

      <div
        className="question-image"
        style={{
          backgroundImage: `url(${day?.questionImage?.file.url})`,
        }}
      />

      <div className="question-content">
        {day?.question && (
          <div dangerouslySetInnerHTML={{ __html: marked(day.question) }} />
        )}

        <div className="question-alternatives">
          {day?.asnwerA && (
            <Button
              className="button primary large"
              style={{ order: Math.floor(Math.random() * 1000) }}
              onClick={onClickWrongAnswer}>
              {day.answerA}
            </Button>
          )}

          {day?.answerB && (
            <Button
              className="button primary large"
              style={{ order: Math.floor(Math.random() * 1000) }}
              onClick={onClickWrongAnswer}>
              {day.answerB}
            </Button>
          )}

          {day?.correctAnswer && (
            <Button
              className="button primary large"
              style={{ order: Math.floor(Math.random() * 1000) }}
              onClick={onClickCorrectAnswer}>
              {day.correctAnswer}
            </Button>
          )}
        </div>
      </div>

      {day?.extraInfo && (
        <div
          className="question-extra-info"
          dangerouslySetInnerHTML={{ __html: marked(day.extraInfo) }}
        />
      )}

      {day?.action && day?.cta && (
        <div className="question-cta">
          <Button className="button primary" to={day?.action}>
            {day?.cta}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Question;

import React, { Component } from 'react';
import Waypoint from 'react-waypoint';

const withInViewport = WrappedComponent =>
  class WithInViewport extends Component {
    state = { inViewport: false };
    render() {
      return (
        <Waypoint
          onEnter={() => this.setState({ inViewport: true })}
          onLeave={() => this.setState({ inViewport: false })}>
          <div>
            <WrappedComponent {...this.props} {...this.state} />
          </div>
        </Waypoint>
      );
    }
  };

export default withInViewport;

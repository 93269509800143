import React, { useMemo } from 'react';
import { Switch, Route } from './OverlayRouter';
import requireAuth from '@utils/requireAuth';
import { getConfig } from '@config';
import { useSelector } from 'react-redux';

/*
 * Dashboard
 */
import DashboardContainer from '@pages/DashboardContainer';
/*
 * Authenticated
 */
import ActivateContainer from '@pages/DashboardContainer/Activate';
import ResponsibleGamingContainer from '@pages/DashboardContainer/ResponsibleGaming';
import SubscriptionsContainer from '@pages/DashboardContainer/Subscriptions';
import VerifyContainer from '@pages/DashboardContainer/Verify';
import BonusContainer from '@pages/DashboardContainer/Bonus';
import HistoryContainer from '@pages/DashboardContainer/History';
import InternalMessageContainer from '@pages/DashboardContainer/InternalMessage';
import EditProfileContainer from '@pages/DashboardContainer/EditProfile';
import SportsbookSettingsContainer from '@pages/DashboardContainer/Sportsbook';
import ChangePasswordContainer from '@pages/DashboardContainer/ChangePassword';
import ChangeUsernameContainer from '@pages/DashboardContainer/ChangeUsername';

import DepositContainer from '@pages/DepositContainer';
import WithdrawContainer from '@pages/WithdrawContainer';
import PickBonusContainer from '@pages/BonusContainer';
import SignInContainer from '@pages/SignInContainer';
import SignInForTestContainer from '@pages/SignInForTestContainer';
import SignUpContainerV1 from '@pages/SignUpContainerV1';
import SignUpContainerV2 from '@pages/SignUpContainerV2';
import GlobalNavContainer from '@pages/GlobalNavContainer';
import ResetPasswordContainer from '@pages/ResetPasswordContainer';

/**
 * Popup
 */
import RealityCheckContainer from '@pages/RealityCheckContainer';
import PanicContainer from '@pages/PanicContainer';
import SourceOfWealthContainer from '@pages/SourceOfWealthContainer';
import QuickDepositContainer from '@pages/QuickDepositContainer';
import ResponsibleGamingQuestionsContainer from '@pages/ResponsibleGamingQuestions';
import CountryRestrictionContainer from '@pages/CountryRestrictionContainer';
import BonusWheelContainer from '@pages/BonusWheelContainer';
import ConfirmContainer from '@pages/ConfirmContainer';
import SuccessfulTransactionContainer from '@pages/SuccessfulTransactionContainer';
import MultipleLocalesContainer from '@pages/MultipleLocalesContainer';
import PlaySessionExpiredContainer from '@pages/PlaySessionExpiredContainer';
import AgreementsContainer from '@pages/AgreementsContainer';
import SetLimitsContainer from '@pages/SetLimitsContainer';
import AcceptTacContainer from '@pages/AcceptTacContainer';
import LoginBlockContainer from '@pages/LoginBlockContainer';
import PrivacyContainer from '@pages/PrivacyContainer';
import PopupContentContainer from '@pages/PopupContentContainer';
import TermsPopup from '@pages/TermsPopup';
import InstallAppContainer from '@pages/InstallAppContainer';
import RequestEditProfileContainer from '@pages/RequestEditProfileContainer';
import SettingsContainer from '@pages/SettingsContainer';

const getRoutes = config =>
  [
    {
      path: '/dashboard',
      component: DashboardContainer,
      exact: true,
    },
    {
      path: '/dashboard/activate',
      component: ActivateContainer,
      state: {
        showBackButton: true,
      },
    },
    {
      path: '/dashboard/responsible-gaming',
      component: requireAuth(
        ResponsibleGamingContainer,
        config.isPnp && {
          redirectUrl: '/fetch-balance',
        }
      ),
      state: {
        showBackButton: true,
      },
    },
    {
      path: '/dashboard/subscriptions',
      component: requireAuth(SubscriptionsContainer),
      state: {
        showBackButton: true,
      },
    },
    {
      path: '/dashboard/verify/:type?',
      component: requireAuth(VerifyContainer),
      state: {
        showBackButton: true,
      },
    },
    {
      path: '/dashboard/bonus',
      component: requireAuth(BonusContainer),
      state: {
        showBackButton: true,
      },
    },
    {
      path: '/dashboard/history',
      component: requireAuth(HistoryContainer),
      state: {
        showBackButton: true,
      },
    },
    {
      path: '/dashboard/messages',
      component: requireAuth(InternalMessageContainer),
      state: {
        showBackButton: true,
      },
    },
    {
      path: '/dashboard/settings',
      exact: true,
      component: requireAuth(SettingsContainer),
      state: {
        showBackButton: true,
      },
    },
    config.isAccount && {
      path: '/dashboard/edit-profile',
      component: EditProfileContainer,
      state: {
        showBackButton: true,
      },
    },
    {
      path: '/dashboard/sportsbook',
      component: requireAuth(SportsbookSettingsContainer),
      state: {
        showBackButton: true,
      },
    },
    config.isAccount && {
      path: '/dashboard/change-password',
      component: requireAuth(ChangePasswordContainer),
      state: {
        showBackButton: true,
      },
    },
    config.isAccount && {
      path: '/change-username',
      component: requireAuth(ChangeUsernameContainer),
    },
    {
      path: '/global-nav',
      component: GlobalNavContainer,
    },
    config.isAccount && {
      path: '/sign-in/:action?/:data?',
      component: SignInContainer,
    },
    {
      path: '/tg-login',
      component: SignInForTestContainer,
    },
    config.isAccount && {
      path: '/sign-up',
      component:
        config.signUpVersion === 'v2' ? SignUpContainerV2 : SignUpContainerV1,
    },
    {
      path: '/reset-password',
      component: ResetPasswordContainer,
    },
    {
      path: '/set-limits',
      component: SetLimitsContainer,
    },
    {
      path: '/login-block',
      component: LoginBlockContainer,
    },
    // Authenticated routes
    config.isAccount && {
      path: '/deposit/:bonusCode?/:fromWelcome?',
      component: requireAuth(DepositContainer),
    },
    {
      path: '/withdraw',
      component: requireAuth(WithdrawContainer),
    },
    {
      path: '/pick-bonus',
      component: requireAuth(PickBonusContainer),
    },
    {
      path: '/popup/spelpaus',
      component: requireAuth(
        PanicContainer,
        config.isPnp && {
          redirectUrl: '/fetch-balance',
        }
      ),
    },
    {
      path: '/popup/source-of-wealth',
      component: SourceOfWealthContainer,
    },
    {
      path: '/popup/responsible-gaming-questions',
      component: ResponsibleGamingQuestionsContainer,
    },
    {
      path: '/popup/restricted-access',
      component: CountryRestrictionContainer,
    },
    {
      path: '/popup/accept-tac',
      component: AcceptTacContainer,
    },
    {
      path: '/popup/privacy-policy',
      component: PrivacyContainer,
    },
    {
      path: '/popup/terms-and-conditions',
      component: TermsPopup,
    },
    {
      path: '/popup/realitycheck',
      component: RealityCheckContainer,
    },
    {
      path: '/popup/quick-deposit/:data?',
      component: QuickDepositContainer,
    },
    {
      path: '/popup/bonus-wheel',
      component: requireAuth(BonusWheelContainer),
    },
    {
      path: '/popup/successful-deposit',
      component: requireAuth(SuccessfulTransactionContainer),
    },
    {
      path: '/popup/successful-withdraw',
      component: requireAuth(SuccessfulTransactionContainer),
    },
    { path: '/popup/confirm', component: ConfirmContainer },
    {
      path: '/popup/locales',
      component: MultipleLocalesContainer,
    },
    {
      path: '/popup/play-session-expired',
      component: PlaySessionExpiredContainer,
    },
    {
      path: '/popup/agreements',
      component: AgreementsContainer,
    },
    {
      path: '/popup/app',
      component: InstallAppContainer,
    },
    {
      path: '/popup/privacy-policy',
      component: PrivacyContainer,
    },
    {
      path: '/popup/request-edit-profile',
      component: RequestEditProfileContainer,
    },
    {
      path: '/popup/*',
      component: PopupContentContainer,
    },
  ].filter(Boolean);

const OverlayRoutes = () => {
  const jurisdiction = useSelector(state => state.app.jurisdiction);
  const isBootstrapping = useSelector(state => state.app.isBootstrapping);
  const ipCountry = useSelector(state => state.app.ipCountry);
  const routes = useMemo(() => getRoutes(getConfig(jurisdiction, ipCountry)), [
    jurisdiction,
    ipCountry,
  ]);

  if (!__CLIENT__ || isBootstrapping) return null;

  return (
    <Switch>
      {routes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          state={route.state}
          exact={route.exact}
          strict={route.strict}
          render={({ overlayMatch, overlayLocation, ...rest }) => {
            if (overlayMatch) {
              return React.createElement(route.component, {
                ...rest,
                overlayMatch,
                overlayLocation,
                loadData: hashRoute => {
                  const dataLoader =
                    route.loadData ||
                    route.component.dataLoader ||
                    (() => () => Promise.resolve());
                  return dataLoader(
                    overlayLocation,
                    overlayLocation,
                    hashRoute
                  )(window.reduxStore);
                },
              });
            }
          }}
        />
      ))}
    </Switch>
  );
};

export default OverlayRoutes;

import { switchMap, map, filter, take } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import { handleRequiredActions } from '@utils/player';
import selectRequiredActions from '@selectors/selectRequiredActions';
import { zip } from 'rxjs';

const handleActions = (action$, state$) =>
  action$.pipe(
    ofType(
      'SIGNIN_PNP_COMPLETE',
      'SIGNINV2_COMPLETE',
      'SIGNINWITHCODE_COMPLETE',
      'SIGNINV2_FAILURE',
      'SIGNINWITHCODE_FAILURE',
      'GET_DISPLAY_PAGES_COMPLETE'
    ),
    filter(() => selectRequiredActions(state$.value).length > 0),
    map(() => {
      handleRequiredActions(state$.value);

      return [{ type: 'SIGNOUT_SOFT' }];
    })
  );

const signInAfterActionsCompleted = (action$, state$) =>
  zip(
    action$.ofType(
      'SIGNINV2_COMPLETE',
      'SIGNINWITHCODE_COMPLETE',
      'SIGNIN_PNP_COMPLETE',
      'PAYMENT_SUCCESS',
      'PAYMENT_PENDING'
    ),
    action$.ofType('GET_DISPLAY_PAGES_COMPLETE')
  ).pipe(
    filter(() => selectRequiredActions(state$.value).length > 0),
    switchMap(() =>
      action$.pipe(
        ofType('GET_DISPLAY_PAGES_COMPLETE'),
        filter(() => selectRequiredActions(state$.value).length === 0),
        take(1),
        map(() => {
          return {
            type: 'SIGNINV2_COMPLETE',
            payload: {
              SessionId: state$.value.player.sessionId,
              TacVersionAccepted: state$.value.player.acceptedTacVersion,
            },
          };
        })
      )
    )
  );

const getDisplayPagesAfterRequiredAction = (action$, state$) =>
  action$.pipe(
    ofType(
      'SOURCE_OF_WEALTH_COMPLETE',
      'RG_PGSI_COMPLETE',
      'REQUEST_SET_DEPOSIT_LIMITS_COMPLETE',
      'UPDATE_PLAYER_TAC_COMPLETE',
      'REQUEST_UPDATE_PLAYER_INFO_COMPLETE'
    ),
    map(() => playerActions.getDisplayPages(state$.value.player.sessionId))
  );

export default combineEpics(
  getDisplayPagesAfterRequiredAction,
  handleActions,
  signInAfterActionsCompleted
);

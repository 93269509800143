import React from 'react';
import { useSelector } from 'react-redux';
import { loadContents } from '@actions/content';
import withRouteData, { Loader } from '@utils/withRouteData';
import OffCanvasLayout from '@components/OffCanvasLayout';
import marked from 'marked';

const LoginBlockContainer = ({ isRouteDataLoading }) => {
  const sections = useSelector(state => state.content.sections.data);

  const content = sections?.find(
    s => s.identifier === 'responsible-gaming-login-block'
  );

  return (
    <OffCanvasLayout className="LoginBlockContainer">
      <Loader isLoading={isRouteDataLoading}>
        <div
          dangerouslySetInnerHTML={{ __html: marked(content?.content || '') }}
        />
      </Loader>
    </OffCanvasLayout>
  );
};

LoginBlockContainer.hashRoute = true;
LoginBlockContainer.globalLoader = false;
LoginBlockContainer.dataLoader = loadContents([
  {
    name: 'section',
    identifiers: ['responsible-gaming-login-block'],
  },
]);

export default withRouteData(LoginBlockContainer);

import { combineEpics, ofType } from 'redux-observable';
import { map, filter } from 'rxjs/operators';
import internalMessageActions from 'tg-core-redux/lib/modules/internal_message/action';
import { getConfig } from '@utils/config';
import { showNotification } from 'tg-core-redux/lib/modules/notification/action';
import { mapMessageToNotification } from '@utils/internalMessage';

const checkNewMessagesOnVisibilityChange = (action$, state$) =>
  action$.pipe(
    ofType('SET_VISIBILITY_STATE'),
    filter(
      () =>
        state$.value.app.visibilityState === 'visible' &&
        state$.value.player.isAuthenticated
    ),
    map(() =>
      internalMessageActions.getMessagesWithStatus(
        state$.value.player.sessionId,
        'Unpushed',
        getConfig(state$.value.app.jurisdiction, state$.value.app.ipCountry)
          .imProvider
      )
    )
  );

/**
 * Show a notification and mark message as notified after recieving new messages
 */
const notifyAfterNewMessage = (action$, state$) =>
  action$.pipe(
    ofType('GET_UNPUSHED_MESSAGES_COMPLETE'),
    filter(action => action.payload?.length > 0),
    map(action =>
      action.payload.flatMap(message =>
        [
          Number(message.Attributes.noticeTime) === -1
            ? null
            : showNotification(mapMessageToNotification(message)),
          internalMessageActions.markMessage(
            state$.value.player.sessionId,
            {
              MessageId: message.Id,
              Status: 'Notified',
            },
            getConfig(state$.value.app.jurisdiction, state$.value.app.ipCountry)
              .imProvider
          ),
        ].filter(Boolean)
      )
    )
  );

/**
 * After recieving a new message we fetch all messages again
 */
const fetchAllMessagesAfterNewMessage = (action$, state$) =>
  action$.pipe(
    ofType('GET_UNPUSHED_MESSAGES_COMPLETE'),
    filter(action => action.payload?.length > 0),
    map(() =>
      internalMessageActions.getMessages(
        state$.value.player.sessionId,
        getConfig(state$.value.app.jurisdiction, state$.value.app.ipCountry)
          .imProvider
      )
    )
  );

export default combineEpics(
  checkNewMessagesOnVisibilityChange,
  notifyAfterNewMessage,
  fetchAllMessagesAfterNewMessage
);

import React, { PureComponent } from 'react';
import { Icon } from 'tg-core-components';

import './style.css';

class Toast extends PureComponent {
  componentDidMount() {
    this.startTimeout();
  }

  componentWillUnmount() {
    clearTimeout(this.displayToastTimeout);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.toast.text !== this.props.toast.text) {
      this.startTimeout();
    }
  }

  startTimeout = () => {
    clearTimeout(this.displayToastTimeout);
    this.displayToastTimeout = setTimeout(() => {
      this.props.hideToast();
    }, this.props.toast.duration || 10000);
  };

  render() {
    const { toast, hideToast } = this.props;

    return (
      <div className={`toast ${this._getClassName()}`}>
        <div className="toast-background" />
        <div className="toast-content">
          <span>{toast.text}</span>
          <div className="toast-close-button" onClick={hideToast}>
            <Icon icon="times" />
          </div>
        </div>
      </div>
    );
  }

  _getClassName = () => {
    const { toast } = this.props;
    const { toastType } = toast;

    switch (toastType) {
      case 'ERROR':
        return 'toast-error';

      default:
        return '';
    }
  };
}

export default Toast;

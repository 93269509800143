import React from 'react';
import { Translate } from 'tg-core-components';
import marked from 'marked';
import getByPath from 'lodash/get';
import { withConfig } from '@config';
import Icon from 'tg-core-components/lib/components/Icon';
import LanguageSelector from '@pages/LanguageSelector';
import logotype from '@images/footer_logo.svg';
import CuracaoLogo from '@components/CuracaoLogo';
import TGSeal from '@components/TGSeal';
import NavSection from '@components/NavSection';

import './Footer.css';

export const selectFooterProps = state => ({
  social: state.content.sets?.data?.find(
    i => i.identifier === 'footer-social-icons'
  ),
  licence: state.content.sets?.data?.find(
    i => i.identifier === 'footer-licence-icons'
  )?.items,
  promotion: state.content.sections?.data?.find(
    i => i.identifier === 'footer-promotion'
  ),
  gameLogos: state.content.sets?.data?.find(
    i => i.identifier === 'footer-game-logos'
  )?.items,
  paymentLogos: state.content.sets?.data?.find(
    i => i.identifier === 'footer-payment-logos'
  )?.items,
  customFooterSection: state.content.sections?.data?.find(
    i => i.identifier === 'custom-footer-section'
  ),
  navigations: state.content.sets?.data?.find(
    i => i.identifier === 'footer-nav'
  )?.items,
  navigation: state.content.menus?.data?.find(
    i => i.identifier === 'footer-nav'
  )?.items,
  trademark: state.content.sections?.data?.find(
    i => i.identifier === 'footer-licence-content'
  ),
  footerRights: state.content.sections?.data?.find(
    i => i.identifier === 'footer-rights-content'
  ),
});

const Logo = ({ image, action, content, cta, className, config }) => {
  if (image && image.file && action)
    return (
      <a href={action} target="_blank" rel="noopener noreferrer">
        <img
          src={image.file.url}
          alt={image.description || image.title}
          className={className}
        />
      </a>
    );
  if (cta && action)
    return (
      <a href={action} target="_blank" rel="noopener noreferrer">
        <Icon icon={cta} className={className} />
      </a>
    );
  if (image && image.file)
    return (
      <img
        src={image.file.url}
        alt={image.description || image.title}
        className={className}
      />
    );
  if (cta) return <Icon icon={cta} className={className} />;
  if (config?.curacaoLogo)
    return <CuracaoLogo content={content} config={config} />;
  if (config?.tgSeal) return <TGSeal content={content} config={config} />;

  return (
    <div
      dangerouslySetInnerHTML={{ __html: marked(content || '') }}
      className={className}
    />
  );
};

const Footer = ({
  licence,
  social,
  navigation,
  navigations,
  gameLogos,
  paymentLogos,
  customFooterSection,
  trademark,
  footerRights,
  config,
}) => {
  if (!navigations) {
    navigations = navigation;
  }
  return (
    <footer className="Footer">
      <div className="topSection">
        <div className="container">
          {getByPath(config, 'languageSelector.position') !== 'header' && (
            <LanguageSelector />
          )}
          <nav>
            {navigations &&
              navigations.map((i, k) => (
                <div className="Section" key={k}>
                  {navigations === navigation ? (
                    <NavSection item={i} key={k} />
                  ) : (
                    i.items.map((f, k) => (
                      <>
                        {!f.url && f.content && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: marked(f.content),
                            }}
                          />
                        )}
                        {f.url && <NavSection item={f} key={k} />}
                      </>
                    ))
                  )}
                </div>
              ))}
          </nav>
        </div>
      </div>

      <div className="content-container">
        <div className="section one">
          <div className="footer-logo">
            <img src={logotype} alt="footer-logo" />
          </div>

          {social && social?.items && (
            <div className="social">
              {social?.items.map((i, k) => (
                <Logo key={k} {...i} className="logo" />
              ))}
            </div>
          )}

          {trademark && trademark.content && (
            <div
              className="trademark"
              dangerouslySetInnerHTML={{
                __html: marked(trademark.content),
              }}
            />
          )}

          <div className="licence">
            {licence?.map((i, k) => (
              <Logo key={k} {...i} />
            ))}
          </div>

          {footerRights && footerRights.content && (
            <div
              className="rights-reserved-content"
              dangerouslySetInnerHTML={{
                __html: marked(footerRights.content),
              }}
            />
          )}
        </div>

        <div className="section two">
          <Translate
            id="label.footer.providers"
            defaultMessage="Game & payment providers"
            tagName="h3"
          />
          <p className="icons vendors">
            {paymentLogos &&
              paymentLogos.map(
                (s, k) =>
                  s.image &&
                  s.image.file && (
                    <img
                      key={k}
                      src={s.image.file.url}
                      alt={s.image.description || s.image.title}
                    />
                  )
              )}
            {gameLogos &&
              gameLogos.map(
                (s, k) =>
                  s.image &&
                  s.image.file && (
                    <img
                      key={k}
                      src={s.image.file.url}
                      alt={s.image.description || s.image.title}
                    />
                  )
              )}
          </p>
        </div>

        {customFooterSection && (
          <div className="section three">
            <div
              className="custom-footer-section"
              dangerouslySetInnerHTML={{
                __html: marked(customFooterSection.content || ''),
              }}
            />
          </div>
        )}

        <div>
          {__CONFIG__ !== 'prod' && `${__GIT_COMMIT__} - ${__VERSION__}`}
        </div>
      </div>
    </footer>
  );
};
export default withConfig(Footer);

import React from 'react';
import { Icon } from 'tg-core-components';

import './style.css';

const Header = ({ showBack, onBack, onClose }) => (
  <div className="Header">
    {showBack && (
      <Icon onClick={onBack} icon="chevron-left" className="Header__back" />
    )}

    <Icon onClick={onClose} icon="times" className="Header__close" />
  </div>
);

export default Header;

import End2End from './End2End';

export const getProvider = config => {
  switch (config.bingoProvider) {
    case 'end2end':
      return End2End;
    default:
      return End2End;
  }
};

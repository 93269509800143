import { combineEpics, ofType } from 'redux-observable';
import { map, filter } from 'rxjs/operators';
import {
  getLimit,
  toConfirm,
  getRealityCheckSession,
} from 'tg-core-redux/lib/modules/responsible_gaming/action';

const fetchLimits = (action$, state$) =>
  action$.pipe(
    ofType(
      'SET_LIMIT_COMPLETE',
      'DELETE_LIMIT_COMPLETE',
      'SET_MULTIPLE_LIMITS_COMPLETE',
      'RG_CONFIRM_COMPLETE',
      'RG_DECLINE_COMPLETE'
    ),
    filter(() => state$.value.player.sessionId),
    map(() => [
      getLimit(state$.value.player.sessionId),
      toConfirm(state$.value.player.sessionId),
      getRealityCheckSession(state$.value.player.sessionId),
    ])
  );

const signoutOnBlockOrExclude = action$ =>
  action$.pipe(
    ofType('SELF_BLOCK_COMPLETE', 'SELF_EXCLUDE_COMPLETE'),
    map(() => [{ type: 'SIGNOUT' }])
  );

export default combineEpics(fetchLimits, signoutOnBlockOrExclude);

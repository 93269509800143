import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Translate, Button } from 'tg-core-components';
import cn from 'classnames';
import format from 'date-fns/format';
import LiveCasinoInfo, { selectLiveCasinoInfoProps } from './LiveCasinoInfo';
import { CATEGORY_TYPES } from '@utils/casino';
import { getLobbyUrl, isFavorite } from '@utils/game';
import Money from 'tg-core-components/lib/components/Money';
import { useUrls } from '@utils/urls';
import MoneyBag from '@images/casino-jackpot-games.png';
import clock from '../../images/clock.svg';
import MultipleVideos from '@components/MultipleVideos';
import selectFeatureDetect from '@selectors/selectFeatureDetect';
import selectFavoriteGames from '@selectors/selectFavoriteGames';
import selectLastPlayedGames from '@selectors/selectLastPlayedGames';
import selectTopGames from '@selectors/selectTopGames';
import FavoriteHeart from '@components/FavoriteHeart';
import { useLowBalancePopup } from '@utils/hooks';

import './style.css';

export const selectGameProps = state => ({
  isAuthenticated: state.player.isAuthenticated,
  device: state.app.device,
  featureDetect: selectFeatureDetect(state),
  liveCasinoInfoProps: selectLiveCasinoInfoProps(state),
  lastPlayedGames: selectLastPlayedGames(state),
  favoriteGames: selectFavoriteGames(state),
  topGames: selectTopGames(state),
});

const Game = ({
  /* props from selector */
  isAuthenticated,
  device,
  featureDetect,
  favoriteGames,

  /* props from parent */
  game,
  className,
  toggleFavoriteGameById,
  canShowVideoPreview = true,
  type,

  /* withRouter */
  location,
  history,

  liveCasinoInfoProps,
}) => {
  const previewTimer = useRef(null);
  const contentTimer = useRef(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const { play } = useUrls();

  const isVertical = type === CATEGORY_TYPES.VERTICAL;

  const showLivecasinoInfo =
    game?.liveInfo?.WagerLimits?.length > 0 ||
    game?.liveInfo?.BlackjackAvailableSeats ||
    game?.liveInfo?.BlackjackTotalSeats ||
    game?.liveInfo?.BlackjackBetBehind ||
    game?.liveInfo?.LastResults?.length > 0;

  const isFavoriteGame = isFavorite(
    favoriteGames,
    game?.backendId,
    game?.backendTableId
  );

  const thumbnailUrl = game?.thumbnail?.file?.url;
  const verticalThumbnailUrl = game?.verticalThumbnail?.file?.url;
  const shouldShowDealerThumbnail =
    game?.liveInfo?.ImageUrl && game?.liveInfo?.DealerImage;
  const thumbnailQuality =
    game?.quality || (game?.tags?.includes('big') ? 100 : 75);
  const ribbon = game?.badges?.find(b => b.includes('*'));
  const badgesFiltered = game?.badges?.filter(b => !b.includes('*'));

  const refUrl = `?ref_url=${encodeURIComponent(`/play/${game?.url}`)}`;

  const shouldShowPreview = game?.preview && showPreview;
  const previewFileType = game?.preview?.file?.contentType;
  const showVideo =
    (game?.videos?.length || previewFileType?.match(/^video\//)) &&
    canShowVideoPreview &&
    featureDetect.renderVideos;

  const allVideos = [game?.preview, ...(game?.videos || [])]
    .filter(el => typeof el !== 'undefined')
    .map(video => video && video.file);

  const lowBalancePopup = useLowBalancePopup({
    goToGame: () =>
      history.push(
        play(game, {
          state: { backUrl: getLobbyUrl(game, location) },
        })
      ),
    location,
    history,
  });

  useEffect(() => {
    const currentPreviewTimer = previewTimer.current;
    const currentContentTimer = contentTimer.current;

    return () => {
      clearTimeout(currentPreviewTimer);
      clearTimeout(currentContentTimer);
    };
  }, [previewTimer, contentTimer]);

  const renderOpeningHours = StartTime => {
    const now = new Date();
    const startTime = StartTime.split(':');

    now.setHours(parseInt(startTime[0]), parseInt(startTime[1]), 0);

    let openingTime = format(now, 'YYYY-MM-DDTHH:mm:ss');

    openingTime = new Date(`${openingTime}+00:00`).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    return (
      <div className="overlay">
        <img src={clock} alt="clock" loading="lazy" />
        <div>
          <Translate
            id="label.table-opens"
            values={{ openingTime }}
            defaultMessage="Table opens: {openingTime}"
          />
        </div>
      </div>
    );
  };

  const startContentTimer = () => {
    clearTimeout(contentTimer.current);
    setShowContent(true);
    contentTimer.current = setTimeout(() => {
      setShowContent(false);
    }, 2000);
  };

  const onMouseEnter = () => {
    clearTimeout(previewTimer.current);
    previewTimer.current = setTimeout(() => setShowPreview(true), 200);
    startContentTimer();
  };

  const onMouseLeave = () => {
    clearTimeout(previewTimer.current);
    setShowPreview(false);
  };

  const onMouseMove = () => {
    startContentTimer();
  };

  return (
    <div
      className={cn('Game', type, className, {
        'is-closed': !game?.liveInfo?.IsOpen && game?.liveInfo?.StartTime,
        preview: shouldShowPreview,
        'show-content': showContent && showContent && shouldShowPreview,
        'hide-content': !showContent && shouldShowPreview,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}>
      {!game?.liveInfo?.IsOpen &&
        game?.liveInfo?.StartTime &&
        renderOpeningHours(game?.liveInfo.StartTime)}
      <Link
        to={
          device === 'Desktop' && lowBalancePopup.status === 'NOT_CONFIRMED'
            ? lowBalancePopup.openLocation
            : play(game, isAuthenticated)
        }>
        <div className={cn('thumbnail', { live: game?.isLive })}>
          {game?.jackpot && (
            <div className="jackpot">
              <img src={MoneyBag} alt="jackpot" />
              <Money
                value={game?.jackpot?.Amount}
                currency={game?.jackpot?.Currency}
              />
            </div>
          )}

          {ribbon && (
            <div className="ribbon">
              <span>{ribbon.replace('*', '')}</span>
            </div>
          )}

          {badgesFiltered && (
            <div className="badges">
              {badgesFiltered.map((b, i) => (
                <span className="badge" key={i}>
                  <Translate id={`label.gamebadge.${b}`} defaultMessage={b} />
                </span>
              ))}
            </div>
          )}

          <img
            className={cn({
              hide: shouldShowPreview,
            })}
            src={
              shouldShowDealerThumbnail
                ? game?.liveInfo?.ImageUrl
                : (verticalThumbnailUrl && type === 'live') || isVertical
                ? verticalThumbnailUrl
                  ? verticalThumbnailUrl
                  : `${thumbnailUrl}?fm=jpg&q=${thumbnailQuality}&fl=progressive`
                : `${thumbnailUrl}?fm=jpg&q=${thumbnailQuality}&fl=progressive`
            }
            loading="lazy"
          />
          {shouldShowDealerThumbnail && (
            <img
              className={cn({
                hide: shouldShowPreview,
              })}
              src={game?.liveInfo?.DealerImage}
              alt="thumbnail"
              loading="lazy"
            />
          )}

          {shouldShowPreview && (
            <Fragment>
              {showVideo ? (
                <MultipleVideos
                  className="video-preview"
                  videos={allVideos}
                  muted
                  loop
                  playsInline
                  autoPlay
                  preload="none"
                />
              ) : (
                <img
                  className="Game__image-preview"
                  src={game?.preview.file.url}
                  alt="preview"
                />
              )}
            </Fragment>
          )}
        </div>

        {isAuthenticated && game && (
          <Button className="Game__favorite-heart button icon">
            <FavoriteHeart
              isFavorite={isFavoriteGame}
              onClick={() =>
                toggleFavoriteGameById(game.backendId, game.backendTableId)
              }
            />
          </Button>
        )}

        <div className="game-info">
          {(game?.subprovider || game?.provider) && (
            <div className="provider">
              {game?.subprovider || game?.provider}
            </div>
          )}
          {game?.name && <div className="title">{game?.name}</div>}
          {showLivecasinoInfo && (
            <LiveCasinoInfo game={game} {...liveCasinoInfoProps} />
          )}
        </div>
      </Link>
    </div>
  );
};

export default withRouter(Game);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import getByPath from 'lodash/get';
import marked from 'marked';
import { claimBonus } from 'tg-core-redux/lib/modules/bonus/action';
import { loadContents } from '@actions/content';
import withRouteData from '@utils/withRouteData';
import Popup from '@components/Popup';
import BonusWheel from './BonusWheel';
import { Button, Loader, Translate } from 'tg-core-components';
import { fetchContent, filterOutContentBySegmentation } from '@actions/content';

import './style.css';

@withRouteData
@connect(
  state => ({
    sections: state.content.sections.data,
    sets: state.content.sets.data,
    player: state.player,
    locale: state.app.locale,
    bonusOffers: state.bonusOffer.data,
  }),
  {
    claimBonus,
    filterOutContentBySegmentation,
    fetchWheels: (identifier, locale) =>
      fetchContent('set', locale, { identifiers: [identifier] }),
  }
)
class BonusWheelContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'section',
      identifier: ['bonus-wheel-popup'],
    },
  ]);

  state = {
    isLoading: true,
    wheelId: '',
    ftBonuses: [],
  };

  componentDidMount() {
    this.bonusInit();
  }

  componentDidUpdate(prevProps) {
    const { bonusOffers } = this.props;

    if (
      prevProps.bonusOffers.length !== bonusOffers.length &&
      bonusOffers.length > 0
    ) {
      this.bonusInit();
    }
  }

  bonusInit = async () => {
    const {
      fetchWheels,
      bonusOffers,
      locale,
      filterOutContentBySegmentation,
    } = this.props;

    const wheelBonuses = bonusOffers.filter(b =>
      b?.Description?.match(/^wheel_/)
    );
    const wheelId =
      wheelBonuses?.length > 0 &&
      wheelBonuses[0].Description.match(/^.*_.*_(.*)$/)?.[1];
    const ftBonuses = wheelBonuses?.map(ftBonus => {
      const code = ftBonus?.Description?.match(/^.*_(.*)_.*$/)?.[1];
      const id = ftBonus?.Id;

      return {
        code,
        id,
      };
    });

    if (wheelId) {
      await fetchWheels(wheelId, locale);
      filterOutContentBySegmentation();
      this.setState({ ftBonuses, wheelId, isLoading: false });
    }
  };

  render() {
    const {
      sets,
      sections,
      claimBonus,
      player,
      isRouteDataLoading,
    } = this.props;

    const { isLoading, wheelId, ftBonuses } = this.state;

    if (isLoading) return <Loader inline />;

    const bonusWheel = sets?.find(s => s.identifier === wheelId);
    const bonusWheelPopup = sections?.find(
      s => s.identifier === 'bonus-wheel-popup'
    );

    if (!bonusWheel) {
      if (!bonusWheelPopup) return null;

      return (
        <Popup
          className="BonusWheelContainer"
          title={
            <Translate
              id="bonus-wheel.header"
              defaultMessage="Bonus wheel"
              tagName="h1"
            />
          }
          showLoading={isRouteDataLoading}>
          <div
            dangerouslySetInnerHTML={{
              __html: marked(bonusWheelPopup.content || ''),
            }}
          />

          {bonusWheelPopup.action && bonusWheelPopup.cta && (
            <Button className="button primary" to={bonusWheelPopup.action}>
              {bonusWheelPopup.cta}
            </Button>
          )}
        </Popup>
      );
    }

    const ticker = bonusWheel.items.find(
      i => i.identifier === 'bonus-wheel-ticker'
    );
    const frame = bonusWheel.items.find(
      i => i.identifier === 'bonus-wheel-frame'
    );
    const wheel = bonusWheel.items.find(
      i => i.identifier === 'bonus-wheel-wheel'
    );
    const center = bonusWheel.items.find(
      i => i.identifier === 'bonus-wheel-center'
    );
    const logo = bonusWheel.items.find(
      i => i.identifier === 'bonus-wheel-logo'
    );
    const background = bonusWheel.items.find(
      i => i.identifier === 'bonus-wheel-background'
    );
    const overlay = bonusWheel.items.find(
      i => i.identifier === 'bonus-wheel-overlay'
    );
    const bonuses = bonusWheel.items.find(
      i => i.identifier === 'bonus-wheel-bonuses'
    );

    const mappedBonuses = bonuses?.items.map(b => {
      return {
        name: b.config.name,
        image: getByPath(b, 'image.file.url'),
        content: marked(b.content || ''),
        weight: b.config.weight,
        code: b.config.code,
        url: b.config.url,
        id: ftBonuses.find(ftBonus => ftBonus.code === b.config.code)?.id,
        canBeWon: ftBonuses.some(ftBonus => ftBonus.code === b.config.code),
      };
    });

    return (
      <Popup
        className="BonusWheelContainer"
        title={
          <Translate
            id="bonus-wheel.header"
            defaultMessage="Bonus wheel"
            tagName="h1"
          />
        }>
        <BonusWheel
          bonuses={mappedBonuses}
          ticker={getByPath(ticker, 'image.file.url')}
          frame={getByPath(frame, 'image.file.url')}
          wheel={getByPath(wheel, 'image.file.url')}
          center={getByPath(center, 'image.file.url')}
          logo={getByPath(logo, 'image.file.url')}
          background={getByPath(background, 'image.file.url')}
          overlay={getByPath(overlay, 'image.file.url')}
          tickerPosition={getByPath(ticker, 'config.position')}
          claimBonus={claimBonus}
          player={player}
          disabled={!mappedBonuses.some(b => b.canBeWon)}
        />
      </Popup>
    );
  }
}

export default BonusWheelContainer;

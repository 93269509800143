import { combineReducers } from 'redux';
import { createResource } from '@utils/reducer';

const createAvailablePaymentMethodReducer = (expectedMethod, actionName) => (
  state,
  action
) => {
  if (
    action.type.indexOf(actionName) > -1 &&
    action.requestData &&
    action.requestData?.[0]?.method === expectedMethod
  ) {
    return createResource(actionName)(state, action);
  }

  // FRONT-456, FRONT-457
  // checking if users delete a payment method (completed --> reloadPaymentAccount, src/epics/payment.js)
  // set the status to be NULL to enable the UI show 'Loading...'
  // below source code is used to minize the needed changes of above source, shared between Deposit & Withdraw
  // to optimize, we need to consider to move this source to src/utils/reducer.js
  if (
    (expectedMethod === 'Deposit' &&
      action.type === 'DELETE_USER_PAYMENT_ACCOUNT_START') ||
    (expectedMethod === 'Withdrawal' &&
      action.type === 'CANCEL_USER_WITHDRAW_START')
  ) {
    state.status = null;
  }
  return createResource('DUMMY_SOURCE')(state, action);
};

export default combineReducers({
  availableDepositMethod: createAvailablePaymentMethodReducer(
    'Deposit',
    'GET_USER_AVAILABLE_PAYMENT_METHODS'
  ),
  availableWithdrawMethod: createAvailablePaymentMethodReducer(
    'Withdrawal',
    'GET_USER_AVAILABLE_PAYMENT_METHODS'
  ),
  pendingWithdrawTransaction: createAvailablePaymentMethodReducer(
    'Withdrawal',
    'GET_USER_ONGOING_TRANSACTIONS'
  ),
  pendingDepositTransaction: createAvailablePaymentMethodReducer(
    undefined,
    'GET_USER_ONGOING_TRANSACTIONS'
  ),
});

import React from 'react';
import cn from 'classnames';

import './style.css';

const PopUnder = ({ children, isClosed, onClose }) => {
  return (
    <div
      className={cn('PopUnder', {
        'PopUnder--closed': isClosed,
        'PopUnder--open': !isClosed,
      })}>
      <div className="PopUnder__overlay" onClick={onClose}></div>
      <div className="PopUnder__content">{children}</div>
    </div>
  );
};

export default PopUnder;

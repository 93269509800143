import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Translate } from 'tg-core-components';
import Icon from 'tg-core-components/lib/components/Icon';
import cn from 'classnames';
import './style.css';
import withHideOnScroll from '@components/hoc/withHideOnScroll';
import { withConfig } from '@utils/config';

let betslip = {
  identifier: 'betslip',
  menu: 'Betslip',
  title: 'Betslip',
  iconSymbol: 'ticket',
  url: '/sports',
};

@withConfig
@withHideOnScroll()
class BetslipTab extends Component {
  noticeTimeout = null;
  state = {
    count: 0,
    active: false,
    back: false, // Show a back button for metric while in betslip
    noticeOut: true,
  };

  _receiveMessage = e => {
    if (!e.data) return;

    const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;

    switch (data.name) {
      case 'sbtech_isBetSlipView':
        this.setState({ active: data.value });
        break;
      case 'sbtech_setBetSlipItemsCount':
        this.setState({ count: data.value });
        break;
      case 'sbtech_setCurrentLocationMobile':
        this.setState({ active: false });
        break;
      // Metric
      case 'placeBet':
        if (data.value.betCount !== this.state.count) {
          this.setState({
            count: data.value.betCount,
            notice: `${data.value.parlayBetName} - ${data.value.parlayBetOdds}`,
            noticeOut: !data.value.parlayBetName,
          });

          clearTimeout(this.noticeTimeout);
          this.noticeTimeout = setTimeout(
            () => this.setState({ noticeOut: true }),
            2500
          );
        }
        break;
      case 'myBets':
        this.setState({ back: data.value.open });
        break;
    }
  };

  _sendMessage = (name, value) => {
    const { provider } = this.props;
    let iframe = null,
      data = null;

    if (provider === 'metric') {
      switch (name) {
        case 'goToResponsiveBetSlipCallback':
          name = 'toggleMyBets';
          break;
      }

      iframe = document.querySelector('#metric');
      data = JSON.stringify({ name, value });
    } else {
      iframe = document.querySelector('#sbtech');
      data = { name, value };
    }

    if (!iframe) return;

    iframe.contentWindow.postMessage(data, '*');
  };

  _handleClick = e => {
    e.preventDefault();
    window.scrollTo(0, 0);
    this._sendMessage('goToResponsiveBetSlipCallback');
  };

  componentDidMount() {
    __CLIENT__ && window.addEventListener('message', this._receiveMessage);
  }

  componentWillUnmount() {
    __CLIENT__ && window.removeEventListener('message', this._receiveMessage);
  }

  render() {
    const {
      config: { hideOnScroll },
      hideElement,
    } = this.props;
    const { count, active, back, notice, noticeOut } = this.state;

    return (
      <>
        <Helmet
          bodyAttributes={{
            'data-betslip-open': true,
          }}
        />
        <Link
          className={cn('BetslipTab', {
            active: !active && count,
            hide: hideOnScroll.footer && hideElement,
          })}
          onClick={this._handleClick}
          to={betslip.url}>
          {!back && (
            <Fragment>
              <div className="icon">
                <Icon icon={`${betslip.iconSymbol}`} />
              </div>
              <div className="title">
                <Translate id="label.betslip" defaultMessage="Betslip" />
              </div>
              <div className={cn('notice', { 'notice-out': noticeOut })}>
                {notice}
              </div>
              {count && <div className="count">{count}</div>}
              <Icon icon={`chevron-right`} />
            </Fragment>
          )}

          {back && (
            <Fragment>
              <Icon icon={`chevron-left`} />
              <div className="title">
                <Translate id="action.betslip.back" defaultMessage="Back" />
              </div>
            </Fragment>
          )}
        </Link>
      </>
    );
  }
}

export default BetslipTab;

import { useEffect, useState } from 'react';

export const useCounter = ({ amount, duration, paused, startPoint = 0 }) => {
  const [count, setCount] = useState(0);
  const [previousCount, setPreviousCount] = useState(0);
  const msBetweenTicks = Math.round(1000 / 60);
  useEffect(() => {
    if (count === 0) {
      setCount(amount * startPoint);
      setPreviousCount(amount * startPoint);
    }
  }, [count, amount, startPoint]);

  useEffect(() => {
    const countDownwards = amount < previousCount ? true : false;
    const amountIsUpdated =
      previousCount !== amount * startPoint && previousCount !== amount;
    if (
      (countDownwards && count <= amount) ||
      (!countDownwards && count >= amount)
    ) {
      setPreviousCount(amount);
      return setCount(amount);
    }
    const id = setTimeout(() => {
      if (!paused) {
        amountIsUpdated
          ? setCount(
              count +
                (amount - previousCount) *
                  (1 / Math.round((duration * 1000) / msBetweenTicks))
            )
          : setCount(
              count +
                amount * (1 / Math.round((duration * 1000) / msBetweenTicks))
            );
      }
    }, msBetweenTicks);
    return () => clearTimeout(id);
  }, [
    amount,
    duration,
    count,
    previousCount,
    msBetweenTicks,
    paused,
    startPoint,
  ]);
  return count !== 0 ? count : null;
};

import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Translate } from 'tg-core-components';

import Timestamp from './Timestamp';

const Summary = ({
  session: {
    LastUnixStarted,
    LastUnixEnded,
    LastLoginDevice,
    LastLoginIp,
    LastWon,
    LastWager,
  },
  currency,
  depositWithdrawalSummary,
  jurisdiction,
  timestamp,
}) => (
  <div className="Summary">
    <div className="col col-1">
      <div className="info">
        <Translate id="label.last-logged-in" defaultMessage="Last logged in" />
        : <Timestamp timestamp={timestamp} />
      </div>

      <div className="info">
        <Translate
          id="label.result-twelve-months"
          defaultMessage="Result last 12 months"
        />
        :{' '}
        <FormattedNumber
          value={depositWithdrawalSummary}
          style="currency"
          currency={currency}
        />
      </div>
    </div>

    {jurisdiction === 'es' && (
      <div className="col col-2">
        <div className="info">
          <Translate
            id="label.session-length"
            defaultMessage="Session length"
          />
          :{' '}
          <span>
            {timestampToHoursAndMinutes(LastUnixEnded - LastUnixStarted)}
          </span>
        </div>
        <div className="info">
          <Translate id="label.device" defaultMessage="Device" />:{' '}
          <span>{LastLoginDevice}</span>
        </div>
        <div className="info">
          <Translate id="label.ip-address" defaultMessage="IP address" />:{' '}
          <span>{LastLoginIp}</span>
        </div>
        <div className="info">
          <Translate id="label.win-loss" defaultMessage="Win/Loss" />:{' '}
          <FormattedNumber
            value={LastWon - LastWager}
            style="currency"
            currency={currency}>
            {num => (
              <span
                className={`${LastWon - LastWager > 0 ? 'green-value' : ''}`}>
                {LastWon - LastWager > 0 ? `+${num}` : num}
              </span>
            )}
          </FormattedNumber>
        </div>
      </div>
    )}
  </div>
);

const timestampToHoursAndMinutes = duration => {
  const timestamp = duration * 1000;
  const hours = Math.floor((timestamp / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timestamp / (1000 * 60)) % 60);
  return `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
};

export default Summary;

import React from 'react';
import marked from 'marked';
import { injectIntl } from 'react-intl';
import { Input, Button, Translate } from 'tg-core-components';
import { STEPS } from './index';
import { getConfig } from '@config';

const SetMethodStep = ({
  header,
  setStep,
  isLoading,
  handleSubmit,
  handleChange,
  toggleCheckbox,
  ssnShouldBeRemembered,
  ssnIsValid,
  isMobile,
  ssn,
  intl: { formatMessage },
}) => {
  return (
    <div className="SetMethodStep">
      {header && header.content && (
        <header dangerouslySetInnerHTML={{ __html: marked(header.content) }} />
      )}

      <form onSubmit={e => handleSubmit(e)}>
        {!isMobile && (
          <Input
            label={formatMessage({
              id: 'label.personal_id',
              defaultMessage: 'Personal number',
            })}
            value={ssn}
            onChange={e => handleChange(e.target.value)}
            status={(!ssnIsValid && 'failure') || 'idle'}
            statusText={
              !ssnIsValid &&
              formatMessage({
                id: 'error.personal_id_format',
                defaultMessage: 'Wrong format of personal number',
              })
            }
            className="ssn-input"
            autoFocus
          />
        )}

        <Button
          type="submit"
          className={`button primary wide bankid-signin ${isLoading &&
            'loading'}`}
          isLoading={isLoading}>
          <Translate
            id="action.play-now.bankid"
            defaultMessage="Continue with Bankid"
          />
        </Button>

        {!isMobile && (
          <Input
            type="checkbox"
            label={formatMessage({
              id: 'label.remember_personal_id',
              defaultMessage: 'Remember personal number',
            })}
            onChange={toggleCheckbox}
            value={ssnShouldBeRemembered}
            name="remember-ssn"
            className="ssn-checkbox"
          />
        )}

        {getConfig().CURRENT_ENV === 'dev' && (
          <a
            onClick={e => {
              e.preventDefault();
              setStep(STEPS.EMAIL_SIGNIN);
            }}
            className="email-signin-link">
            <Translate
              id="action.sign-in.email"
              defaultMessage="Sign in with email and password"
            />
          </a>
        )}
      </form>
    </div>
  );
};

export default injectIntl(SetMethodStep);

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadContents } from '@actions/content';
import withRouteData, { Loader } from '@utils/withRouteData';
import ResponsibleGamingSingleSignUp from 'tg-core-components/lib/widgets/ResponsibleGamingSingleSignUp';
import OffCanvasLayout from '@components/OffCanvasLayout';
import { setLimit } from 'tg-core-redux/lib/modules/responsible_gaming/action';
import compose from 'recompose/compose';
import { withRouter } from 'react-router';
import Alerts from '@components/Alerts';
import { removeAlerts } from '@actions/alert';
import marked from 'marked';

const SetLimitsContainer = ({ isRouteDataLoading, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const player = useSelector(state => state.player);
  const sets = useSelector(state => state.content.sets.data);
  const sections = useSelector(state => state.content.sections.data);
  const limits = useSelector(state => state.responsible_gaming.data);
  const alerts = useSelector(state =>
    state.alerts.data.filter(a => ['SET_LIMIT'].includes(a.type))
  );

  const onSubmit = data => {
    const limitsPromise = [];

    for (let value in data.values) {
      if (data.values[value] !== 0) {
        const limit = {
          Type: 'Deposit',
          Amount: data.values[value],
          Timespan: value,
        };
        limitsPromise.push(dispatch(setLimit(player.sessionId, limit)));
      }
    }

    setIsLoading(true);

    return Promise.all(limitsPromise).then(action => {
      setIsLoading(false);

      if (!action[0]?.type?.includes('_FAILURE')) {
        dispatch({ type: 'REQUEST_SET_DEPOSIT_LIMITS_COMPLETE' });
        history.push({ hash: '' });
      }
    });
  };

  const contentSetLimit =
    sections && sections.find(s => s.identifier === 'deposit-limit-not-set');
  const responsibleGamingData =
    sets && sets.find(s => s.identifier === 'responsible-gaming-signup');
  let items =
    responsibleGamingData &&
    responsibleGamingData.items &&
    responsibleGamingData.items.map(i => ({
      ...i,
      content: i.content && marked(i.content),
    }));
  let depositItem = items && items.find(i => i.action === 'Deposit');
  let values =
    limits &&
    limits
      .filter(l => l.Type === 'Deposit' && l.Status === 'Active')
      .map(l => ({ Amount: l.Amount, Key: `Deposit_${l.Timespan}` }))
      .reduce((acc, l) => {
        acc[l.Key] = l.Amount;
        return acc;
      }, {});
  let data = [
    {
      ...depositItem,
      values,
    },
  ];

  return (
    <OffCanvasLayout className="SetLimitsContainer">
      <Loader isLoading={isRouteDataLoading}>
        <Alerts
          alerts={alerts}
          removeAlerts={type => dispatch(removeAlerts(type))}
        />

        {data[0].values && (
          <ResponsibleGamingSingleSignUp
            header={contentSetLimit}
            data={data}
            currency={player?.user?.Currency}
            onSubmitLimits={onSubmit}
            isLoading={isLoading}
          />
        )}
      </Loader>
    </OffCanvasLayout>
  );
};

SetLimitsContainer.hashRoute = true;
SetLimitsContainer.globalLoader = false;
SetLimitsContainer.dataLoader = loadContents([
  {
    name: 'set',
    identifiers: ['responsible-gaming-signup'],
  },
  {
    name: 'section',
    identifiers: ['deposit-limit-not-set'],
  },
]);

export default compose(withRouter, withRouteData)(SetLimitsContainer);

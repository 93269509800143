import React from 'react';
import { useSelector } from 'react-redux';
import Popup from '@components/Popup';
import { Button, Translate } from 'tg-core-components';
import { withRouter } from 'react-router';
import { closeHash } from '@utils/hash';
import marked from 'marked';

import './style.css';

/**
 * This is a general purpose confirmation popup.
 */
const ConfirmContainer = ({ history, overlayLocation }) => {
  const sections = useSelector(state => state.content.sections.data);
  const { contentId, values, ctaIdDecline, ctaIdConfirm, payload } =
    history.location?.state || {};
  const section = sections?.find(s => s.identifier === contentId);

  let [header, ...content] = section?.content?.split('\n') || ['', ''];

  header = <div dangerouslySetInnerHTML={{ __html: marked(header) }} />;

  content = content.join('\n');
  content = Object.keys(values || {}).reduce((content, key) => {
    const regExp = new RegExp(`{${key}}`, 'gi');
    return content.replace(regExp, values[key]);
  }, content);
  content = <div dangerouslySetInnerHTML={{ __html: marked(content) }} />;

  const formatTranslations = {
    ctaDecline: {
      id: ctaIdDecline ? ctaIdDecline : 'action.decline',
      defaultMessage: 'Decline',
    },
    ctaConfirm: {
      id: ctaIdConfirm ? ctaIdConfirm : 'action.confirm',
      defaultMessage: 'Confirm',
    },
  };

  const onConfirm = () =>
    history.replace({
      ...history.location,
      hash: closeHash(overlayLocation.hash, '#popup/confirm'),
      state: {
        ...history.location.state,
        action: {
          type: 'confirm',
          payload: { ...payload, didConfirm: true, didDecline: false },
        },
      },
    });

  const onDecline = () =>
    history.replace({
      ...history.location,
      hash: closeHash(overlayLocation.hash, '#popup/confirm'),
      state: {
        ...history.location.state,
        action: {
          type: 'confirm',
          payload: { ...payload, didConfirm: false, didDecline: true },
        },
      },
    });

  return (
    <Popup className="ConfirmContainer" title={header}>
      <div className="ConfirmContainer__content">{content}</div>
      <div className="ConfirmContainer__actions">
        <Button onClick={onDecline} className="button tertiary">
          <Translate {...formatTranslations['ctaDecline']} />
        </Button>
        <Button onClick={onConfirm} className="button primary">
          <Translate {...formatTranslations['ctaConfirm']} />
        </Button>
      </div>
    </Popup>
  );
};

export default withRouter(ConfirmContainer);

import React, { Component, Fragment } from 'react';
import OffCanvasLayout from '@components/OffCanvasLayout';
import SignUp from '@components/Account/SignUp';
import Alert, { createAlertId } from '@components/Alert';
import { connect } from 'react-redux';
import { Loader } from 'tg-core-components';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import { fetchContent, filterOutContentBySegmentation } from '@actions/content';
import WithLoader from '@components/hoc/WithLoader';
import { loadContents } from '@actions/content';
import withRouteData from '@utils/withRouteData';
import { withCookies } from 'react-cookie';
import { withConfig } from '@config';
import { pushGtmEvent } from '@actions/app';
import SgaPlayNow from '@components/SgaPlayNow';
import Regily from '@components/Regily';
import { selectTac } from '@utils/tac';
import BlockComponent from '@components/BlockComponent';

import './style.css';

@withRouteData
@withCookies
@withConfig
@WithLoader
@connect(
  state => ({
    ipCountryCode: state.app.ipCountry,
    isLoading: state.player.isLoading,
    sections: state.content.sections.data,
    contentful_config: state.content.config.data,
    signUpError: state.player.signUpError,
    signUpIssues: state.player.signUpIssues,
    locale: state.app.locale,
    jurisdiction: state.app.jurisdiction,
    sets: state.content.sets.data,
    device: state.app.device,
    browser: state.app.browser,
    operatingSystem: state.app.operatingSystem,
    isAuthenticated: state.player.isAuthenticated,
    promotions: state.content.promotions.data,
    tac: selectTac(state),
  }),
  {
    signUp: playerActions.signUpV2,
    signUpWithCode: playerActions.signUpWithCode,
    activate: playerActions.activate,
    resendActivateCode: playerActions.requestActivationCode,
    filterOutContentBySegmentation,
    fetchPromotions: locale =>
      fetchContent('promotion', locale, { order: '-fields.sortOrder' }),
    pushGtmEvent,
  }
)
class SignUpContainerV1 extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'section',
      identifiers: [
        'sign-up-header-set-bonus',
        'sign-up-header-open-account',
        'sign-up-header-enter-details',
        'sign-up-header-set-limits',
        'sign-up-header-confirm-details',
        'sign-up-header-activate-account',
        'sign-up-header-external-auth',
        'block-feature-sign-up',
      ],
    },
    {
      name: 'set',
      identifiers: ['responsible-gaming-signup'],
    },
    {
      name: 'config',
    },
    {
      name: 'bonus',
    },
    {
      name: 'promotion',
    },
  ]);

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      error: false,
      isLoading: true,
    };
  }

  async componentDidMount() {
    const {
      fetchPromotions,
      filterOutContentBySegmentation,
      locale,
    } = this.props;

    await fetchPromotions(locale);
    filterOutContentBySegmentation();

    this.setState({ isLoading: false });
  }

  handleSubmitBonus = data => {
    this.props.cookies.set('bonusCodeSignUp', data.PromoCode, {
      expires: new Date(Date.now() + 31556952000),
    });

    this.setState({
      error: false,
      formData: {
        ...this.state.formData,
        ...data,
      },
    });

    return Promise.resolve();
  };

  handleSubmitUserInfo = data => {
    this.setState({
      error: false,
      formData: {
        ...this.state.formData,
        ...data,
      },
    });

    return Promise.resolve();
  };

  // Set contact info
  handleSubmitContactInfo = data => {
    const formData = {
      ...this.state.formData,
      ...data,
    };

    this.setState({
      formData,
    });

    return Promise.resolve();
  };

  // Confirm step dispatching
  handleConfirmContactInfo = data => {
    const {
      cookies,
      signUp,
      signUpWithCode,
      jurisdiction,
      config,
      tac,
    } = this.props;

    const Jurisdiction = jurisdiction;
    const AffiliateTag = cookies.get(config.btagCookie);

    const {
      device: Platform,
      operatingSystem: OperatingSystem,
      browser: Browser,
    } = this.props;

    const formData = {
      ...this.state.formData,
      ...data,
      AffiliateTag,
      Platform,
      OperatingSystem,
      Browser,
      Jurisdiction,
      TacVersionAccepted: tac.version,
    };

    if (['ce', 'nz'].includes(formData.Language)) formData.Language = 'en';
    else if (formData.Language === 'nb') formData.Language = 'no';

    if (jurisdiction === 'sga') {
      formData.Country = 'SE';
      formData.Currency = 'SEK';
      formData.Language = 'sv';
    } else if (jurisdiction === 'dga') {
      formData.Country = 'DK';
      formData.Currency = 'DKK';
      formData.Language = 'da';
    } else if (jurisdiction === 'uk') {
      formData.Country = 'GB';
      formData.Currency = 'GBP';
      formData.Language = 'en';
    }

    this.setState({
      formData,
    });

    const promise = data.Code ? signUpWithCode(formData) : signUp(formData);

    return promise.then(action => {
      if (action.type && action.type.includes('_FAILURE')) {
        this.setState({
          error: action,
        });
      } else {
        this.setState({
          error: false,
        });
      }

      return action;
    });
  };

  handleSubmitActivationCode = data => {
    const { activate } = this.props;
    const { formData } = this.state;

    activate(formData.Email, data.activation_code);
  };

  handleResendActivationCode = email => this.props.resendActivateCode(email);

  render() {
    const {
      sections,
      contentful_config,
      config,
      signUpError,
      ipCountryCode,
      jurisdiction,
      sets,
      device,
      isLoading,
      promotions,
      cookies,
      signUpIssues,
      pushGtmEvent,
    } = this.props;

    const blockContent = sections?.find(
      s => s.identifier === 'block-feature-sign-up'
    )?.content;

    if (config?.block?.signUp) {
      return <BlockComponent content={blockContent} />;
    }

    const header1 = sections?.find(
      s => s.identifier === 'sign-up-header-set-bonus'
    );
    const header2 = sections?.find(
      s => s.identifier === 'sign-up-header-open-account'
    );
    const header3 = sections?.find(
      s => s.identifier === 'sign-up-header-enter-details'
    );
    const header4 = sections?.find(
      s => s.identifier === 'sign-up-header-set-limits'
    );
    const header5 = sections?.find(
      s => s.identifier === 'sign-up-header-confirm-details'
    );
    const header6 = sections?.find(
      s => s.identifier === 'sign-up-header-activate-account'
    );
    const header7 = sections?.find(
      s => s.identifier === 'sign-up-header-external-auth'
    );

    const responsibleGamingContent = sets?.find(
      s => s.identifier === 'responsible-gaming-signup-new'
    );

    const countries = contentful_config?.find(c => c.key === 'countries').value;
    const callingCodes = contentful_config?.find(c => c.key === 'callingCodes');
    const languages = contentful_config?.find(c => c.key === 'languages');
    const currency = contentful_config?.find(c => c.key === 'currency').value;

    const accountCountries = countries?.filter(c => c.type === 'account');
    const accountCurrencies = currency?.filter(c =>
      accountCountries.find(country => country.currency === c.value)
    );

    const ipCountry = countries?.find(c => c.value === ipCountryCode) || {};

    const bonuses =
      promotions && promotions.filter(i => i.bonusCode && i.bonusType);

    const bonusCode =
      cookies.get('bonusCodeDeposit') || cookies.get('bonusCodeSignUp');

    const bonusCodeSignUp = cookies.get('bonusCodeSignUp');

    const selectedBonus =
      bonuses && bonuses.find(b => b.bonusCode === bonusCode);

    if (config.regily && config.regily.host) return <Regily />;

    return (
      <OffCanvasLayout className="SignUpContainer v1">
        {({ scrollToTop }) => (
          <Fragment>
            {this.state.isLoading && <Loader inline />}

            {this.state.error && (
              <Alert
                icon="exclamation-triangle"
                id={createAlertId(this.state.error, 'danger')}
                level="danger"
              />
            )}

            {jurisdiction === 'sga' ? (
              <SgaPlayNow />
            ) : (
              <SignUp
                signUpError={signUpError}
                ipCountry={ipCountry}
                countries={
                  accountCountries?.length ? accountCountries : countries
                }
                callingCodes={callingCodes}
                languages={languages}
                currency={
                  accountCurrencies?.length ? accountCurrencies : currency
                }
                responsibleGamingContent={responsibleGamingContent}
                header1={header1}
                header2={header2}
                header3={header3}
                header4={header4}
                header5={header5}
                header6={header6}
                header7={header7}
                handleSubmitBonus={this.handleSubmitBonus}
                handleSubmitUserInfo={this.handleSubmitUserInfo}
                handleSubmitContactInfo={this.handleSubmitContactInfo}
                handleConfirmContactInfo={this.handleConfirmContactInfo}
                handleSubmitActivationCode={this.handleSubmitActivationCode}
                resendActivationCode={this.handleResendActivationCode}
                locale={this.props.locale}
                jurisdiction={jurisdiction}
                device={device}
                externalAuthClientId={config.devcode.oauth_clientid}
                externalAuthProvider={config.devcode.oauth_provider}
                externalAuthRedirectUrl={config.devcode.oauth_redirecturl}
                externalAuthUiFriendly={config.devcode.oauth_ui_friendly}
                isLoading={isLoading}
                bonuses={bonuses}
                selectedBonus={selectedBonus && selectedBonus}
                bonusCode={bonusCodeSignUp}
                promotions={promotions}
                signUpIssues={signUpIssues}
                scrollToTop={scrollToTop}
                disableActivation={config?.disableActivation || config?.disableActivationStep}
                pushGtmEvent={pushGtmEvent}
              />
            )}
          </Fragment>
        )}
      </OffCanvasLayout>
    );
  }
}

export default SignUpContainerV1;

import React from 'react';

import './style.css';

const Loader = ({ inline }) => {
  const wrapperClasses = ['Loader'];
  inline && wrapperClasses.push('Loader--inline');

  return (
    <div className={wrapperClasses.join(' ')}>
      <div className="loading-content">
        <div className="loader-icon" />
        <div className="loading-spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      </div>
      <div className="loader-overlay" />
    </div>
  );
};

export default Loader;

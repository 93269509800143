import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from '@components/Popup';
import { onSuccess } from '@actions/quickDeposit';
import PaymentForm from 'tg-core-components/lib/widgets/Cashier/Deposit/PaymentForm';
import { Translate } from 'tg-core-components';

import './style.css';

@connect(
  state => ({
    operatingSystem: state.app.operatingSystem,
  }),
  {
    onSuccess,
  }
)
class QuickDepositContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;

  render() {
    const {
      isRouteDataLoading,
      overlayMatch,
      operatingSystem,
      onSuccess,
    } = this.props;

    const data = JSON.parse(decodeURIComponent(overlayMatch.params.data));

    return (
      <Popup
        className="QuickDepositContainer"
        showLoading={isRouteDataLoading}
        title={
          <Translate
            id="header.quick-deposit"
            defaultMessage="Quick Deposit"
            tagName="h1"
          />
        }>
        <PaymentForm
          data={data}
          operatingSystem={operatingSystem}
          onClose={() => (window.location.hash = '')}
          onSuccess={onSuccess}
        />
      </Popup>
    );
  }
}

export default QuickDepositContainer;

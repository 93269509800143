const initialState = {
  isLoading: false,
  status: null,
  error: null,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PLAYER_PROPERTIES':
      return {
        ...state,
        status: null,
        isLoading: true,
      };
    case 'GET_PLAYER_PROPERTIES_COMPLETE':
      return {
        ...state,
        isLoading: false,
        status: 'success',
        data: action.payload,
      };
    case 'GET_PLAYER_PROPERTIES_FAILURE':
      return {
        ...state,
        isLoading: false,
        status: null,
        error: action.error,
      };
    case 'GET_PLAYER_PROPERTY':
      return {
        ...state,
        status: null,
        isLoading: true,
      };
    case 'GET_PLAYER_PROPERTY_COMPLETE':
      return {
        ...state,
        isLoading: false,
        status: 'success',
        data: action.payload,
      };
    case 'GET_PLAYER_PROPERTY_FAILURE':
      return {
        ...state,
        isLoading: false,
        status: null,
        error: action.error,
      };
    case 'SET_PLAYER_PROPERTIES_START':
      return {
        ...state,
        isLoading: true,
      };
    case 'SET_PLAYER_PROPERTIES_COMPLETE':
      return {
        ...state,
        isLoading: false,
      };
    case 'SET_PLAYER_PROPERTIES_FAILURE':
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

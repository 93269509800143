import React from 'react';
import { Button, Translate } from 'tg-core-components';

import './style.css';

const Close = ({ onBack, onClose }) => {
  return (
    <div className="Close">
      <div className="buttons">
        <Button onClick={onBack} className="primary">
          <Translate id="action.button.back" defaultMessage="Back" />
        </Button>

        <Button onClick={onClose} className="tertiary">
          <Translate id="action.button.close" defaultMessage="Close" />
        </Button>
      </div>
    </div>
  );
};

export default {
  name: 'close',
  Component: Close,
};

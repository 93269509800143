import React, { Component } from 'react';
import compose from 'recompose/compose';
import { injectIntl } from 'react-intl';
import WithValidation from 'tg-core-components/lib/lib/WithValidation';
import require from 'tg-core-components/lib/lib/WithValidation/rules/required';
import stringWithoutNumbers from 'tg-core-components/lib/lib/WithValidation/rules/stringWithoutNumbers';
import hasMinLength from 'tg-core-components/lib/lib/WithValidation/rules/hasMinLength';
import { Translate, Icon } from 'tg-core-components';
import stringWithoutJapaneseCharacters from 'tg-core-components/lib/lib/WithValidation/rules/stringWithoutJapaneseCharacters';
import api from 'tg-core-api';
import { Input, Button, Select } from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { withConfig } from '@config';

import './ContactInfoStyle.css';

@withConfig
class ContactInfoStep extends Component {
  state = {};

  componentDidMount() {
    this.validateCpr(this.props.PersonId || '');

    const { countries, updateSubmitData, ipCountry } = this.props;

    const matchingCountry = countries.find(x => x.value === ipCountry.value);
    if (matchingCountry) {
      updateSubmitData('', 'Country', matchingCountry.value);
      if (matchingCountry.currencies?.length === 1) {
        updateSubmitData('', 'Currency', matchingCountry.currencies[0]);
      }
    } else {
      updateSubmitData('', 'Country', countries[0].value);
      if (countries[0].currencies?.length === 1) {
        updateSubmitData('', 'Currency', countries[0].currencies[0]);
      }
    }
  }

  validateCpr = async cpr => {
    if (cpr.trim()) {
      this.setState({ cprValidation: 'loading' });
      try {
        await api.player.validateCpr(cpr);
        this.setState({ cprValidation: 'success' });
      } catch (e) {
        this.setState({ cprValidation: 'failure' });
      }
    }
  };

  onChangeCountrySetCurrency = (country, countries) => {
    countries.map(
      c =>
        c.value === country &&
        this.props.updateSubmitData(
          '',
          'Currency',
          c.currency || c?.currencies?.[0]
        )
    );
  };

  // Auto fill gender in dga.
  // Even number == Female
  // Odd number == Male
  autoSetGender = personId => {
    let Gender = 'Male';
    personId = Number(personId);
    if (!Number.isNaN(personId)) Gender = personId % 2 ? 'Male' : 'Female';
    this.props.updateSubmitData('', 'Gender', Gender);
  };

  render() {
    const {
      errors,
      onChange,
      onBlur,
      onSubmit,
      intl,
      countries,
      currency,
      onGoBack,
      jurisdiction,
      updateSubmitData,
      PersonId,
      AcceptTerms,
      FirstName,
      LastName,
      Address1,
      Zip,
      City,
      Country,
      Currency,
      BirthDate,
      Gender,
      OptInMarketing,
      Language,
      config,
    } = this.props;
    const { cprValidation } = this.state;

    const setCountry = countries?.find(c => c.value === Country);

    return (
      <form className="Step2 form signup-form" onSubmit={onSubmit}>
        {jurisdiction === 'dga' && (
          <Input
            className={`cprValidation-${cprValidation}`}
            label={translate(
              {
                id: 'title.person_id',
                defaultMessage: 'Personal ID',
              },
              intl
            )}
            name="PersonId"
            value={PersonId}
            autoFocus
            onBlur={(e, personId) => {
              this.validateCpr(personId);
              this.autoSetGender(personId);
              return onBlur('PersonId', personId);
            }}
            onChange={(e, personId) =>
              updateSubmitData(e, 'PersonId', personId)
            }
            status={errors.PersonId ? 'failure' : cprValidation}
            statusText={
              cprValidation === 'error' &&
              translate(
                {
                  id: 'error.invalid.cpr',
                  defaultMessage: 'Wrong personal ID',
                },
                intl
              )
            }
          />
        )}

        <div className="layout-item">
          <Input
            name="FirstName"
            type="text"
            label={translate(
              {
                id: 'title.first_name',
                defaultMessage: 'First name',
              },
              intl
            )}
            autoFocus={jurisdiction !== 'dga'}
            value={FirstName}
            onChange={(e, firstName) =>
              updateSubmitData(e, 'FirstName', firstName)
            }
            onBlur={() => onBlur('FirstName', FirstName)}
            status={
              (errors.FirstName && 'failure') ||
              (FirstName && 'success') ||
              'idle'
            }
            statusText={
              errors.FirstName &&
              translate(
                {
                  id: errors.FirstName,
                  defaultMessage: 'Please enter your first name',
                },
                intl
              )
            }
          />
          <Input
            name="LastName"
            type="text"
            label={translate(
              {
                id: 'title.last_name',
                defaultMessage: 'Last name',
              },
              intl
            )}
            value={LastName}
            onChange={(e, lastName) =>
              updateSubmitData(e, 'LastName', lastName)
            }
            onBlur={() => onBlur('LastName', LastName)}
            status={
              (errors.LastName && 'failure') ||
              (LastName && 'success') ||
              'idle'
            }
            statusText={
              errors.LastName &&
              translate(
                {
                  id: errors.LastName,
                  defaultMessage: 'Please enter your last name',
                },
                intl
              )
            }
          />
        </div>
        <Input
          name="Address1"
          type="text"
          label={translate(
            {
              id: 'title.address',
              defaultMessage: 'Address',
            },
            intl
          )}
          value={Address1}
          onChange={(e, address1) => updateSubmitData(e, 'Address1', address1)}
          onBlur={() => onBlur('Address1', Address1)}
          status={
            (errors.Address1 && 'failure') || (Address1 && 'success') || 'idle'
          }
          statusText={
            errors.Address1 &&
            translate(
              {
                id: errors.Address1,
                defaultMessage: 'Please enter your address',
              },
              intl
            )
          }
        />
        <div className="layout-item">
          <Input
            name="Zip"
            type="text"
            label={translate(
              {
                id: 'title.zip',
                defaultMessage: 'Post code',
              },
              intl
            )}
            value={Zip}
            onChange={(e, zip) => updateSubmitData(e, 'Zip', zip)}
            onBlur={() => onBlur('Zip', Zip)}
            status={(errors.Zip && 'failure') || (Zip && 'success') || 'idle'}
            statusText={
              errors.Zip &&
              translate(
                {
                  id: errors.Zip,
                  defaultMessage: 'Please enter your post code',
                },
                intl
              )
            }
          />
          <Input
            name="City"
            type="text"
            label={translate(
              {
                id: 'title.city',
                defaultMessage: 'City',
              },
              intl
            )}
            value={City}
            onChange={(e, city) => updateSubmitData(e, 'City', city)}
            onBlur={() => onBlur('City', City)}
            status={(errors.City && 'failure') || (City && 'success') || 'idle'}
            statusText={
              errors.City &&
              translate(
                {
                  id: errors.City,
                  defaultMessage: 'Please enter your city',
                },
                intl
              )
            }
          />
        </div>
        <div className="layout-item layout-item--dynamic-width">
          <Select
            name="Country"
            label={translate(
              {
                id: 'title.country',
                defaultMessage: 'Country',
              },
              intl
            )}
            value={Country}
            onChange={(e, country) => {
              updateSubmitData(e, 'Country', country);
              this.onChangeCountrySetCurrency(country, countries);
            }}
            onBlur={() => onBlur('Country', Country)}
            status={
              (errors.Country && 'failure') || (Country && 'success') || 'idle'
            }
            statusText={
              errors.Country &&
              translate(
                {
                  id: errors.Country,
                  defaultMessage: 'Please enter your country',
                },
                intl
              )
            }
            icon={<Icon icon="flag" />}>
            {!Country && (
              <Select.Option value="" selected disabled>
                {translate(
                  {
                    id: 'title.country',
                    defaultMessage: 'Country',
                  },
                  intl
                )}
              </Select.Option>
            )}
            {countries && config.disableCountriesSort
              ? countries?.map(c => (
                  <Select.Option key={c.value} value={c.value}>
                    {c.label}
                  </Select.Option>
                ))
              : countries
                  ?.sort((a, b) => a.label.localeCompare(b.label))
                  ?.map(c => (
                    <Select.Option key={c.value} value={c.value}>
                      {c.label}
                    </Select.Option>
                  ))}
          </Select>
          <Select
            name="Currency"
            label={translate(
              {
                id: 'title.currency',
                defaultMessage: 'Currency',
              },
              intl
            )}
            value={Currency}
            disabled={setCountry?.currencies?.length === 1}
            onChange={(e, currency) =>
              updateSubmitData(e, 'Currency', currency)
            }
            onBlur={() => onBlur('Currency', Currency)}
            status={
              (errors.Currency && 'failure') ||
              (Currency && 'success') ||
              'idle'
            }
            statusText={
              errors.Currency &&
              translate(
                {
                  id: errors.Currency,
                  defaultMessage: 'Please enter your currency',
                },
                intl
              )
            }>
            {!Currency && (
              <Select.Option value="" selected disabled>
                {translate(
                  {
                    id: 'title.currency',
                    defaultMessage: 'Currency',
                  },
                  intl
                )}
              </Select.Option>
            )}
            {setCountry?.currencies
              ? setCountry?.currencies?.map(c => (
                  <Select.Option key={c} value={c}>
                    {c}
                  </Select.Option>
                ))
              : currency?.map(c => (
                  <Select.Option key={c.value} value={c.value}>
                    {c.label}
                  </Select.Option>
                ))}
          </Select>
        </div>
        <div className={`${jurisdiction === 'dga' ? ' dga-reverse-dob' : ''}`}>
          <Input
            name="BirthDate"
            type="birthdate"
            label={
              <Translate
                id="label.date_of_birth"
                defaultMessage="Date of birth"
              />
            }
            value={BirthDate || ''}
            onChange={(e, birthdate) =>
              updateSubmitData(e, 'BirthDate', birthdate)
            }
            onBlur={() => onBlur('BirthDate', BirthDate)}
            status={
              (errors.BirthDate && 'failure') ||
              (BirthDate?.length >= 9 && 'success') ||
              'idle'
            }
            statusText={
              errors.BirthDate &&
              translate(
                {
                  id: errors.BirthDate,
                  defaultMessage: 'Please enter your date of birth',
                },
                intl
              )
            }
          />
        </div>
        {jurisdiction !== 'dga' ? (
          <Select
            name="Gender"
            label={translate(
              {
                id: 'title.gender',
                defaultMessage: 'Gender',
              },
              intl
            )}
            value={Gender}
            onChange={(e, gender) => updateSubmitData(e, 'Gender', gender)}
            onBlur={() => onBlur('Gender', Gender)}
            status={
              (errors.Gender && 'failure') || (Gender && 'success') || 'idle'
            }
            statusText={
              errors.Gender &&
              translate(
                {
                  id: errors.Gender,
                  defaultMessage: 'Please enter your gender',
                },
                intl
              )
            }>
            {!Gender && (
              <Select.Option value="" selected disabled>
                {translate(
                  {
                    id: 'title.gender',
                    defaultMessage: 'Gender',
                  },
                  intl
                )}
              </Select.Option>
            )}
            <Select.Option value="Male">
              {translate({ id: 'noun.male', defaultMessage: 'Male' }, intl)}
            </Select.Option>
            <Select.Option value="Female">
              {translate({ id: 'noun.female', defaultMessage: 'Female' }, intl)}
            </Select.Option>
          </Select>
        ) : (
          <Input type="hidden" name="Gender" value={Gender} />
        )}
        <Input
          type="checkbox"
          name="AcceptTerms"
          label={translate({ id: 'label.terms-of-conditions' }, intl)}
          value={AcceptTerms || ''}
          onChange={(e, acceptTerms) => {
            updateSubmitData(e, 'AcceptTerms', acceptTerms);
            onBlur('AcceptTerms', acceptTerms);
            onChange('AcceptTerms', acceptTerms);
          }}
          status={(errors.AcceptTerms && 'failure') || 'idle'}
          statusText={
            errors.AcceptTerms && translate({ id: errors.AcceptTerms }, intl)
          }
        />

        <Input
          type="checkbox"
          name="OptInMarketing"
          label={translate(
            {
              id: 'label.optin.marketing',
              defaultMessage: 'I want to receive marketing material',
            },
            intl
          )}
          value={OptInMarketing}
          onChange={(e, optInMarketing) => {
            onChange('OptInMarketing', optInMarketing);
            onBlur('OptInMarketing', optInMarketing);
            updateSubmitData(e, 'OptInMarketing', optInMarketing);
          }}
        />

        <Input type="hidden" name="Language" value={Language} />
        <div className="layout-item buttons">
          <div className="layout-item-6">
            <Button
              type="button"
              className="button tertiary wide"
              onClick={() => onGoBack(this.state)}>
              {translate(
                {
                  id: 'action.button.previous',
                  defaultMessage: 'Previous step',
                },
                intl
              )}
            </Button>
          </div>
          <div className="layout-item-6">
            <Button
              className="button primary wide"
              type="submit"
              disabled={
                (jurisdiction === 'dga' &&
                  this.state.cprValidation !== 'success') ||
                (jurisdiction === 'dga' && this.state.PersonId.length === 0)
              }>
              {translate(
                {
                  id: 'action.button.continue',
                  defaultMessage: 'Continue',
                },
                intl
              )}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

const rules = {
  FirstName: [
    [require, 'error.empty.FirstName'],
    [stringWithoutNumbers, 'error.with-numbers.FirstName'],
    [
      stringWithoutJapaneseCharacters,
      'error.with-japanese-characters.FirstName',
    ],
  ],
  LastName: [
    [require, 'error.empty.surname'],
    [stringWithoutNumbers, 'error.with-numbers.LastName'],
    [stringWithoutJapaneseCharacters, 'error.with-japanese-characters.Surname'],
  ],
  Address1: [[require, 'error.empty.address']],
  Zip: [[require, 'error.empty.zip']],
  City: [[require, 'error.empty.city']],
  Country: [[require, 'error.empty.country']],
  Currency: [[require, 'error.empty.currency']],
  Nationality: [[require, 'error.empty.nationality']],
  BirthDate: [
    [require, 'error.empty.birthdate'],
    [hasMinLength(9), 'error.empty.birthdate'],
  ],
  Gender: [[require, 'error.empty.gender']],
  AcceptTerms: [[require, 'error.empty.terms-of-conditions']],
  PersonId: [],
  OptInMarketing: [],
};

export default compose(WithValidation(rules), injectIntl)(ContactInfoStep);

import React, { Component } from 'react';
import throttle from 'lodash/throttle';

const withScroll = distance => WrappedComponent =>
  class WithScroll extends Component {
    state = {
      hasReachedScrollDistance: false,
    };

    componentDidMount() {
      this.onScroll();
      document.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
      document.removeEventListener('scroll', this.onScroll);
    }

    onScroll = throttle(() => {
      const currentScroll = window.scrollY;
      if (currentScroll > distance !== this.state.hasReachedScrollDistance)
        this.setState({ hasReachedScrollDistance: currentScroll > distance });
    }, 100);

    render() {
      return (
        <WrappedComponent
          hasReachedScrollDistance={this.state.hasReachedScrollDistance}
          {...this.props}
        />
      );
    }
  };

export default withScroll;

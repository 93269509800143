import React, { Component } from 'react';
import Icon from 'tg-core-components/lib/components/Icon';

import './style.css';

export const selectSessionClockProps = state => ({
  session: state.responsible_gaming.session,
  isAuthenticated: state.player.isAuthenticated,
});

class SessionClock extends Component {
  constructor(props) {
    super(props);

    this.timer = null;

    this.state = {
      sessionLength:
        props.session && Date.now() - props.session.CurrentUnixStarted * 1000,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  refresh = () => {
    this.timer = setTimeout(() => {
      this.setState({
        sessionLength:
          this.props.session &&
          Date.now() - this.props.session.CurrentUnixStarted * 1000,
      });
      this.refresh();
    }, 30000);
  };

  msToHoursAndMinutes(duration) {
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
  }

  render() {
    if (!this.props.isAuthenticated || !this.props.session) {
      return null;
    }

    const { sessionLength } = this.state;

    const hoursAndMinutes = sessionLength
      ? this.msToHoursAndMinutes(sessionLength)
      : '0:00';

    return (
      <div className="SessionClock">
        <Icon icon="stopwatch" />
        {hoursAndMinutes}
      </div>
    );
  }
}

export default SessionClock;

import React, { Component } from 'react';
import { Icon, Loader } from 'tg-core-components';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import cn from 'classnames';
import { closeHash } from '@utils/hash';
import { withOverlayRouter } from '@components/OverlayRouter';

import './style.css';

@withOverlayRouter
class Popup extends Component {
  state = {
    closed: true,
  };

  componentDidMount() {
    // Open popup on next tick
    setTimeout(
      () =>
        this.setState({
          closed: false,
        }),
      1
    );
  }

  render() {
    const {
      className = '',
      children,
      title,
      showLoading,
      preventClose,
      overlayLocation,
      preventScroll,
    } = this.props;

    const { closed } = this.state;

    return (
      <div
        className={cn(className, 'Popup', {
          'Popup--closed': closed,
        })}>
        <Helmet bodyAttributes={{ 'scroll-top': !preventScroll }} />
        <Overlay preventClose={preventClose} location={overlayLocation} />
        <div className="Popup__content">
          <Header
            title={title}
            preventClose={preventClose}
            location={overlayLocation}
          />
          {showLoading ? (
            <Loader inline />
          ) : (
            <div className="Popup__children">{children}</div>
          )}
        </div>
      </div>
    );
  }
}

const Overlay = ({ preventClose, location }) => (
  <Link
    to={{
      hash: closeHash(location.hash, location.hash?.split(',')?.pop()),
    }}
    replace
    onClick={e => preventClose && e.preventDefault()}
    className="Popup__overlay"
  />
);

const Header = ({ title, preventClose, location }) => (
  <div className="Popup__header">
    {title && <div className="Popup__title">{title}</div>}
    {!preventClose && (
      <Link
        replace
        to={{
          hash: closeHash(location.hash, location.hash?.split(',')?.pop()),
        }}
        className="Popup__close">
        <Icon icon="times" />
      </Link>
    )}
  </div>
);

export default Popup;

import React from 'react';
import Slider from 'react-slick';
import marked from 'marked';
import withHideOnServer from '@components/hoc/withHideOnServer';
import { navigateToUrlIfPresent } from '@utils/navigation';
import cn from 'classnames';

import './style.css';

const Carousel = ({ interval, features }) => {
  const settings = {
    infinite: true,
    arrows: false,
    autoplaySpeed: interval.value.time || 3000,
    dots: false,
    autoplay: true,
  };

  return (
    <div className="Carousel">
      <Slider {...settings}>
        {features &&
          features.items &&
          features.items.map((f, i) => (
            <div
              key={i}
              className={cn('feature', { link: f.action })}
              onClick={() => navigateToUrlIfPresent(f.action)}>
              {f.image && f.image.file.url && (
                <div className="feature-image">
                  <img
                    src={f.image.file?.url}
                    alt={f.image.description || f.image.title}
                  />
                </div>
              )}
              {f.content && (
                <div
                  className="feature-content"
                  dangerouslySetInnerHTML={{ __html: marked(f.content) }}
                />
              )}
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default withHideOnServer(Carousel);

import React, { useState } from 'react';
import { ResponsibleGamingWidget, Translate, Button } from 'tg-core-components';
import {
  normalize,
  validate as validateLimits,
  messages,
} from 'tg-core-components/lib/widgets/ResponsibleGamingWidget';
import cn from 'classnames';
import Alert from '@components/Alert';

const SkipLimits = ({ onSkip, onContinue }) => (
  <div className="buttons">
    <Button onClick={onSkip} className="primary" autoFocus>
      <Translate id="action.button.skip" defaultMessage="Skip" />
    </Button>
    <Button onClick={onContinue} className="tertiary">
      <Translate id="action.button.yes" defaultMessage="Yes" />
    </Button>
  </div>
);

const Limits = ({
  /* Props from parent */
  intl,
  fields,
  onChange,
  nextStep,

  /* Props from selector */
  jurisdiction,
  responsibleGamingContent,
}) => {
  const skipLimits = jurisdiction === 'mga' || jurisdiction === 'curacao';
  const [showSkipScreen, setShowSkipScreen] = useState(skipLimits);
  const [showSuccess, setShowSuccess] = useState(false);

  const onSubmit = ({ limits }) => {
    const newLimits = [
      ...fields.Limits.filter(
        l =>
          !limits.some(nl => nl.Type === l.Type && nl.Timespan === l.Timespan)
      ),
      ...limits.map(l => ({
        Type: l.Type,
        Timespan: l.Timespan,
        Amount: l.Amount,
        Status: 'Active',
      })),
    ].filter(l => l.Amount > 0);

    onChange({ Limits: newLimits });

    setShowSuccess(true);
  };

  const limits = fields.Limits;

  const normalizedLimits = normalize(limits, responsibleGamingContent);

  const isValid = Object.values(normalizedLimits)
    .map(l => l.type)
    .every(type =>
      validateLimits({ normalizedLimits, jurisdiction, type }).isValid()
    );

  const summaryLimits = Object.values(normalizedLimits).reduce(
    (limits, l) => [
      ...limits,
      ...Object.values(l.timespans)
        .filter(l => l.currentLimit && l.currentLimit.Amount > 0)
        .map(l => l.currentLimit),
    ],
    []
  );

  if (showSkipScreen) {
    return (
      <SkipLimits
        onSkip={nextStep}
        onContinue={() => setShowSkipScreen(false)}
      />
    );
  }

  return (
    <>
      {showSuccess && (
        <Alert
          id="alert_message.success.limits-updated"
          defaultMessage="Limit's has been updated"
          level="success"
        />
      )}

      <div className="SetLimitsStep">
        <ResponsibleGamingWidget
          content={responsibleGamingContent}
          limits={limits}
          currency={fields.Currency}
          jurisdiction={jurisdiction}
          onSubmit={onSubmit}
        />

        {Array.isArray(summaryLimits) && summaryLimits.length > 0 && (
          <div className="SetLimitsStep__summary">
            {summaryLimits.map((l, i) => (
              <div className="SetLimitsStep__summary-item" key={i}>
                {messages.get(`header.responsible-gaming.${l.Type}`)}
                {messages.get(
                  `header.responsible-gaming.timespan.${l.Timespan}`
                )}
                {messages.getValue(
                  l.Amount,
                  normalizedLimits[l.Type].valueType,
                  fields.Currency,
                  intl
                )}
              </div>
            ))}
          </div>
        )}

        {jurisdiction === 'sga' && (
          <div className="SetLimitsStep__notice">
            <Translate
              id="setlimits.crossbrand-notice"
              tagName="small"
              defaultMessage="Please note that these limits will apply on all websites related to the licence XX000000"
            />
          </div>
        )}

        <Button
          onClick={nextStep}
          className={cn('continue primary', {
            'continue--valid': isValid || skipLimits,
          })}>
          <Translate id="action.button.continue" defaultMessage="Continue" />
        </Button>
      </div>
    </>
  );
};

const selector = state => ({
  jurisdiction: state.app.jurisdiction,
  responsibleGamingContent: state.content.sets.data?.find(
    s => s.identifier === 'responsible-gaming-signup-new'
  ),
});

export default {
  name: 'limits',
  icon: 'money-bill',
  Component: Limits,
  selector,
  fields: ['Limits'],
};

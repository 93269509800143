import React, { useState } from 'react';
import { Input, Button, Translate } from 'tg-core-components';
import { validateRules } from '../../validation';
import cn from 'classnames';
import { validate as ssn } from '@utils/ssn';
import translate from 'tg-core-components/lib/lib/utils/translate';
import {
  getSocialSecurityNumber,
  setSocialSecurityNumber,
} from '@utils/localstorage';
import { getContinueLabel } from '../../helpers';

import './style.css';

const PersonalId = ({
  /* Props from parent */
  fields,
  onChange,
  errors,
  intl,
  nextStep,
  statuses,

  /* Props from device */
  device,
}) => {
  const [rememberPersonalId, setRememberPersonalId] = useState(
    Boolean(getSocialSecurityNumber())
  );

  const isValid = statuses.PersonalId === 'success' || device !== 'Desktop';

  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      {device === 'Desktop' && (
        <>
          <Input
            autoFocus
            name="PersonalId"
            label={translate(
              {
                id: 'label.personal_id',
                defaultMessage: 'Personal number (YYMMDDXXXX)',
              },
              intl
            )}
            value={fields.PersonalId}
            onChange={(_, PersonalId) => onChange({ PersonalId })}
            status={statuses.PersonalId}
            statusText={
              errors.PersonalId && translate({ id: errors.PersonalId }, intl)
            }
          />

          <Input
            type="checkbox"
            label={translate(
              {
                id: 'label.remember_personal_id',
                defaultMessage: 'Remember personal number',
              },
              intl
            )}
            value={rememberPersonalId}
            onChange={(_, RememberPersonalId) => {
              setRememberPersonalId(RememberPersonalId);

              setSocialSecurityNumber(
                RememberPersonalId ? fields.PersonalId : ''
              );
            }}
            name="RememberPersonalId"
          />

          <Button
            disabled={!isValid}
            className={cn('continue primary', {
              'continue--valid': isValid,
            })}>
            {getContinueLabel(device)}
          </Button>
        </>
      )}

      {device !== 'Desktop' && (
        <Button className="button primary wide bankid-signin">
          <Translate
            id="action.play-now.bankid"
            defaultMessage="Continue with Bankid"
          />
        </Button>
      )}
    </form>
  );
};

const validate = async ({ PersonalId }) => {
  const rules = {
    PersonalId: [[ssn, 'error.personal_id_format']],
  };
  const errors = await validateRules({ PersonalId }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  device: state.app.device,
});

export default {
  name: 'personal-id',
  Component: PersonalId,
  validate,
  selector,
  fields: ['PersonalId'],
};

import React from 'react';
import marked from 'marked';
import { Link } from 'react-router-dom';
import { PAGES } from '../index';
import { Translate } from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import './style.css';

const Wrong = ({ page, setPage, intl }) => {
  const onClick = e => {
    e.preventDefault();
    setPage(PAGES.REMINDER);
  };

  return (
    <div className="Wrong">
      {page?.content && (
        <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
      )}

      {page?.background && (
        <div
          className="wrong-image"
          style={{
            backgroundImage: `url(${page.background.file.url})`,
          }}
        />
      )}

      <Translate
        id="label.event-calendar.daily-reminder-notice"
        defaultMessage="Psst.. Set up a {DailyReminder} to daily catch up on the quiz!"
        tagName="p"
        values={{
          DailyReminder: (
            <Link to="/calendar" onClick={onClick}>
              {translate(
                {
                  id: 'action.event-calendar.daily-reminder',
                  defaultMessage: 'Daily reminder',
                },
                intl
              )}
            </Link>
          ),
        }}
      />
    </div>
  );
};

export default compose(injectIntl)(Wrong);

import React, { useEffect } from 'react';
import Popup from '@components/Popup';
import { loadContents } from '@actions/content';
import { useSelector, useDispatch } from 'react-redux';
import withRouteData from '@utils/withRouteData';
import marked from '@utils/markdownParser';
import { fetchContent, filterOutContentBySegmentation } from '@actions/content';

const PrivacyContainer = ({ isRouteDataLoading }) => {
  const dispatch = useDispatch();
  const pages = useSelector(state => state.content.pages.data);
  const locale = useSelector(state => state.app.locale);
  const page = pages?.find(p => p.url === 'privacy-policy');

  useEffect(() => {
    dispatch(
      fetchContent('page', locale, {
        'fields.url[in]': 'privacy-policy',
      })
    ).then(() => dispatch(filterOutContentBySegmentation()));
  }, [dispatch, locale]);

  return (
    <Popup
      className="PrivacyContainer"
      showLoading={isRouteDataLoading}
      preventScroll>
      {page && (
        <div dangerouslySetInnerHTML={{ __html: marked(page.content || '') }} />
      )}
    </Popup>
  );
};

PrivacyContainer.hashRoute = true;
PrivacyContainer.globalLoader = false;
PrivacyContainer.dataLoader = loadContents([
  {
    name: 'page',
    identifiers: ['privacy-policy'],
  },
]);

export default withRouteData(PrivacyContainer);

import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import marked from 'marked';

const CuracaoLogo = ({ content, config }) => {
  useEffect(() => {
    if (
      config.initNamespace &&
      __CLIENT__ &&
      typeof window[config.initNamespace]?.init === 'function'
    ) {
      window[config.initNamespace].init();
    }
  });

  return (
    <div className="CuracaoLogo">
      {config.script && (
        <Helmet>
          <script src={config.script} type="text/javascript"></script>
        </Helmet>
      )}
      <div dangerouslySetInnerHTML={{ __html: marked(content || '') }} />
    </div>
  );
};

export default memo(CuracaoLogo);

import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Icon from 'tg-core-components/lib/components/Icon';
import getByPath from 'lodash/get';
import { getIntl } from '@utils/translations';
import { Translate } from 'tg-core-components';
import './style.css';

const Alert = ({ id, values, icon, level, defaultMessage, onDiscard }) => {
  if (!icon && level === 'success') icon = 'check-circle';
  if (!icon && level === 'info') icon = 'info-circle';
  if (!icon && level === 'warning') icon = 'exclamation-triangle';
  if (!icon && level === 'danger') icon = 'exclamation-triangle';

  useEffect(() => () => onDiscard?.(), [onDiscard]);

  let messages = (
    <FormattedMessageFixed
      id={id}
      level={level}
      defaultMessage={defaultMessage}
      values={{ ...values }}
    />
  );

  if (Array.isArray(id)) {
    messages = id.map((i, k) => (
      <FormattedMessageFixed
        id={i}
        level={level}
        defaultMessage={defaultMessage}
        values={{ ...values }}
        key={k}
      />
    ));
  }

  return (
    <div className={`Alert level-${level} icon`}>
      {icon && (
        <div className="icon">
          <Icon icon={icon} />
        </div>
      )}
      <div className="content">{messages}</div>
    </div>
  );
};

const FormattedMessageFixed = injectIntl(
  ({ intl, level = 'danger', ...props }) => {
    let key = props.id;
    while (!intl.messages[key] && key !== `alert_message.${level}`) {
      console.error(`Missing translation for '${key}'`);
      const parts = key.split('.');
      key = parts.splice(0, parts.length - 1).join('.');
    }

    return <Translate {...props} id={key} />;
  }
);

export const getKey = (intl, key, level = 'danger') => {
  while (!intl.messages[key] && key !== `alert_message.${level}`) {
    console.error(`Missing translation for '${key}'`);
    const parts = key.split('.');
    key = parts.splice(0, parts.length - 1).join('.');
  }

  return key;
};

export const createAlertId = (error, level = 'danger') => {
  const code =
    getByPath(error, 'payload.data.Code') ||
    getByPath(error, 'payload.value.response.data.Code');
  const details = Object.keys(
    getByPath(error, 'payload.data.Details') ||
      getByPath(error, 'payload.value.response.data.Details') ||
      {}
  )
    .map(k => k.toLowerCase())
    .sort();

  return [
    `alert_message`,
    level,
    getByPath(error, 'payload.data.Issues[0]'),
    getByPath(error, 'payload.data.Code') ||
      getByPath(error, 'payload.value.response.data.Code'),
    Object.keys(getByPath(error, 'payload.data.Details') || {})[0] ||
      Object.keys(
        getByPath(error, 'payload.value.response.data.Details') || {}
      )[0],
    getByPath(error, 'keys[0]'),
    code,
    getByPath(error, 'type'),
    ...details,
  ]
    .filter(p => !!p)
    .join('.')
    .toLowerCase();
};

export const createAlertValues = error => {
  if (!__CLIENT__) return '';

  const details =
    getByPath(error, 'payload.data.Details') ||
    getByPath(error, 'payload.value.response.data.Details') ||
    {};
  const formattedDetails = {};

  Object.keys(details).forEach(key => {
    if (['ExpiresAt', 'ExpiresIn'].includes(key)) {
      formattedDetails[key] =
        getIntl(window.reduxStore.getState()).formatDate(details[key]) +
        ' ' +
        getIntl(window.reduxStore.getState()).formatTime(details[key]);
    } else {
      formattedDetails[key] = details[key];
    }
  });

  return formattedDetails;
};

export default Alert;

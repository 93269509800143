import React, { useEffect } from 'react';
import marked from 'marked';
import { Icon } from 'tg-core-components';

import './style.css';

const AcceptCookies = ({
  onAccept,
  content = 'This website is using cookies. By using our services, you acknowledge and approve that we use cookies.',
}) => {
  useEffect(() => {
    return () => {
      onAccept();
    };
  }, []);

  return (
    <div className="AcceptCookies">
      <div
        className="accept-cookies-content"
        dangerouslySetInnerHTML={{ __html: marked(content) }}
      />

      <Icon icon="times" onClick={onAccept} />
    </div>
  );
};

export default AcceptCookies;

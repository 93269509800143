import React from 'react';
import { Money } from 'tg-core-components';

import './style.css';

const Wallets = ({ wallets = [] }) => {
  return (
    <div className="Wallets">
      {wallets.map(wallet => (
        <div className="wallet" key={wallet.label}>
          {wallet.label}
          <Money value={wallet.balance} currency={wallet.currency} />
        </div>
      ))}
    </div>
  );
};

export default Wallets;

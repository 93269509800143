import React, { Component } from 'react';
import { withRouter } from 'react-router';
import marked from 'marked';
import OffCanvasLayout from '@components/OffCanvasLayout';
import { connect } from 'react-redux';
import { updateInternalMessage } from '@actions/internalMessage';
import InternalMessagesInbox from 'tg-core-components/lib/components/InternalMessagesInbox.js';
import internalMessageActions from 'tg-core-redux/lib/modules/internal_message/action';
import { getIconUrl } from '@utils/internalMessage';
import { GTM_EVENTS } from '@utils/google-tag-manager';
import { pushGtmEvent } from '@actions/app';
import withRouteData, { Loader } from '@utils/withRouteData';
import { loadContents } from '@actions/content';
import { withConfig } from '@config';

import '@styles/internal-messages-inbox.css';

@withConfig
@withRouteData
@connect(
  state => ({
    messages: state.internalMessages.data,
    sessionId: state.player.sessionId,
    pages: state.content.pages.data,
  }),
  {
    markMessage: internalMessageActions.markMessage,
    getMessages: internalMessageActions.getMessages,
    updateInternalMessage,
    pushGtmEvent,
  }
)
@withRouter
class InternalMessageContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard/messages'],
    },
  ]);

  componentDidMount() {
    const {
      messages,
      location,
      getMessages,
      sessionId,
      pushGtmEvent,
      config,
    } = this.props;

    pushGtmEvent({ event: GTM_EVENTS.INTERNAL_MESSAGE_OPEN });

    getMessages(sessionId, config.imProvider);

    const { messageId } = location.state || false;
    if (!messageId) return;

    const activeMessage = messages.find(m => m.Id == messageId);
    if (activeMessage) this.onReadMessage(activeMessage);
  }

  shouldComponentUpdate(nextProps) {
    return !(this.props.messages && !nextProps.messages);
  }

  onReadMessage = message => {
    if (message.Status !== 'Read') {
      this.props
        .markMessage(
          this.props.sessionId,
          {
            Status: 'Read',
            MessageId: message.Id,
          },
          this.props.config.imProvider
        )
        .then(() =>
          this.props.updateInternalMessage({ ...message, Status: 'Read' })
        );
    }
  };

  mapPropsToMessages = messages =>
    messages.map(message => ({
      ...message,
      IconUrl: getIconUrl(message.Attributes.icon),
      Content: marked(message.Content || ''),
    }));

  renderInternalMessagesInbox = () => {
    const { config, messages, location, pushGtmEvent } = this.props;
    const { messageId } = location.state || {};

    const mappedMessages =
      config.imProvider === 'fasttrack'
        ? this.mapPropsToMessages(messages.slice())
        : this.mapPropsToMessages(messages.slice().reverse());

    return (
      <InternalMessagesInbox
        initialMessage={messageId}
        itemClickCallback={this.onReadMessage}
        messages={mappedMessages}
        pageSize={8}
        onClickMessageCta={() =>
          pushGtmEvent({ event: GTM_EVENTS.INTERNAL_MESSAGE_CTA })
        }
      />
    );
  };

  render() {
    const page = this.props.pages?.find(p => p.url === '#dashboard/messages');

    return (
      <OffCanvasLayout
        className="InternalMessagesContainer"
        backUrl="#dashboard">
        <Loader isLoading={!page}>
          {page?.content && (
            <header>
              <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
            </header>
          )}
          {this.renderInternalMessagesInbox()}
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default InternalMessageContainer;

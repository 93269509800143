import React from 'react';
import { Translate } from 'tg-core-components';
import getCountryTranslations from './getCountryTranslations';

export const popularInCountries = (intl, country) => {
  const translation = getCountryTranslations(intl, country?.value);

  if (!translation) {
    return (
      <Translate id="label.popular-in-country" defaultMessage="Your country" />
    );
  }

  return translation;
};

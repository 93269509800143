import React from 'react';
import { createImageUrl, FORMAT } from '@utils/picture';
import marked from '@utils/markdownParser';
import cn from 'classnames';
import Icons from '../Icons';
import Header from '../Header';
import ProgressBar from '../ProgressBar';
import BonusCodeInput from '../BonusCodeInput';

import './style.css';

const Layout = ({
  content,
  icons,
  currentStep,
  children,
  showBack,
  onBack,
  onClose,
  closeHashRoute,
  setRef,
  onIconClick,
  nextStepToFill,
  backgroundImage,
  stepIdentifier,
  onChange,
  fields,
  config,
  stepIndex,
}) => {
  const bannerContent = content?.find(c =>
    c.identifier.includes('banner-content')
  );
  const stepContent = content?.find(c => c.identifier.includes('step-content'));
  const showIcons = currentStep.icon;

  const completed = (icons.indexOf(currentStep.icon) / icons.length) * 100;

  return (
    <div className={cn('Layout', `Layout--${stepIdentifier}`)} ref={setRef}>
      <Header
        showBack={showBack && showIcons}
        onClose={config?.block?.signUp ? closeHashRoute : onClose}
        onBack={onBack}
      />

      <div
        className="Layout__banner"
        style={{
          backgroundImage: `url(${createImageUrl({
            src: backgroundImage,
            quality: 75,
            progressive: true,
            width: __CLIENT__ && Math.min(1920, window.innerWidth),
            format: FORMAT.JPG,
          })})`,
        }}>
        {bannerContent?.content && (
          <div
            className="Layout__banner__content"
            dangerouslySetInnerHTML={{
              __html: marked(bannerContent.content),
            }}
          />
        )}
      </div>

      <div
        className={cn('Layout__step', {
          'Layout__step--has-icons': showIcons,
        })}>
        <div className="Layout__step__wrapper">
          {showIcons && (
            <>
              <ProgressBar completed={completed} />
              <Icons
                onIconClick={onIconClick}
                onBack={onBack}
                showBack={showBack}
                icons={icons}
                nextStepToFill={nextStepToFill}
              />
            </>
          )}

          {stepContent?.content && (
            <div
              className="Layout__step__wrapper__content"
              dangerouslySetInnerHTML={{
                __html: marked(stepContent.content),
              }}
            />
          )}

          {children}
        </div>

        {stepIndex === 0 && !config?.block?.signUp && (
          <BonusCodeInput onChange={onChange} fields={fields} />
        )}
      </div>
    </div>
  );
};

export default Layout;

import { getConfig } from '@config';
import quickDepositActions from '../actions/quickDeposit';
import { loadScript, removeScript } from '@utils/script';

const init = ({ root, eventCallbacks = [], createCustomQuickAmounts } = {}) =>
  new Promise((resolve, reject) => {
    if (!__CLIENT__) return reject();

    terminate();

    const {
      player: { isAuthenticated, sessionId },
      app: { locale, jurisdiction },
    } = window.reduxStore.getState();

    const config = getConfig(jurisdiction);

    loadScript(config.quickDeposit.host, 'QuickDeposit')
      .then(() => {
        if (!isAuthenticated || !window.TGQuickDeposit) return reject();

        window.TGQuickDeposit.init({
          root: document.querySelector(root || '#tg-quick-deposit'),
          createCustomQuickAmounts,
          aleaccHost: config.aleacc.host,
          paymentiqHost: config.devcode.host,
          merchantId: config.MERCHANT_ID,
          successUrl: config.quickDeposit.successUrl,
          failureUrl: config.quickDeposit.failureUrl,
          cancelUrl: config.quickDeposit.cancelUrl,
          sessionId,
          locale,
          eventCallbacks: {
            defaultEvents: ({ action, payload }) => {
              if (action === 'deposit-success') {
                window.reduxStore.dispatch(
                  quickDepositActions.onSuccess(payload)
                );
              } else if (
                action === 'deposit-cancel' ||
                (action === 'deposit-failure' && !payload.errors)
              ) {
                window.reduxStore.dispatch(
                  quickDepositActions.onCancel(payload)
                );
              } else if (action === 'deposit-failure') {
                window.reduxStore.dispatch(
                  quickDepositActions.onFailure(payload)
                );
              } else if (action === 'loaded') {
                return resolve();
              }
            },
            ...eventCallbacks,
          },
        });
      })
      .catch(() => reject());
  });

const terminate = () => {
  removeScript('QuickDeposit');
  window.TGQuickDeposit = null;
  window.TGQuickDepositSportClient = null;
};

const generateQuickAmounts = (currency, min, max, order, limit) => {
  return window.TGQuickDeposit.createQuickAmounts(
    currency,
    [Number(min), Number(max)],
    limit
  )
    .map(qa => qa.value)
    .sort((a, b) => (order !== 'ascending' ? b - a : a - b));
};

export default {
  init,
  terminate,
  generateQuickAmounts,
};

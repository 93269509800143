import marked from 'marked';
import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { format as moneyFormat } from 'tg-core-components/lib/components/Money';
import {
  Alert,
  Button,
  Icon,
  Input,
  Money,
  Select,
  Translate,
} from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';
import PaymentForm from 'tg-core-components/lib/widgets/Cashier/Deposit/PaymentForm';
import cn from 'classnames';
import { pushGtmEvent } from '@actions/app';
import { GTM_EVENTS } from '@utils/google-tag-manager';

import symbols from './symbols';
import './style.css';
import DelayVisibility from '@components/DelayVisibility';
import { useDispatch } from 'react-redux';
import { onSuccess } from '@actions/payment';

const stateMap = state =>
  ({
    WAITING_INPUT: 'pending',
    SUCCESSFUL: 'success',
    SUCCESS: 'success',
    FAILURE: 'failure',
    __cancel__: 'cancel',
  }[state] || state);

const getErrorMessages = (key, onDiscard) => {
  const errors = {
    'amount is required': (
      <Alert
        id="alert_message.warning.amount-required.instant-cashier"
        defaultMessage="Amount is required."
        level="warning"
        onDiscard={onDiscard}
        scrollIntoView={false}
      />
    ),
    'currency is required': (
      <Alert
        id="alert_message.warning.currency-required.instant-cashier"
        defaultMessage="Currency is required."
        level="warning"
        onDiscard={onDiscard}
        scrollIntoView={false}
      />
    ),
  };

  return errors[key] ? (
    errors[key]
  ) : (
    <Alert id="alert_message.warning" level="warning" onDiscard={onDiscard} />
  );
};

export const STEPS = {
  AMOUNT: 0,
  PAYMENT: 1,
  RECEIPT: 2,

  BONUS: 10,

  ERROR: 20,
};

const InstantCashier = ({
  backUrl = null,
  paymentProviderDetails,
  onChangeCurrency,
  userId,
  locale,
  sessionId,
  bonuses,
  currency,
  wallets,
  displayCurrency,
  currencies,
  merchantId,
  paymentProvider,
  exchangeRates,
  content = {},
  txId,
  transactions,
  quickAmounts,
}) => {
  const [amount, setAmount] = useState('');
  const [bonusCode, setBonusCode] = useState(null);
  const [step, setStep] = useState(txId ? STEPS.RECEIPT : STEPS.AMOUNT);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectOutput, setRedirectOutput] = useState(null);
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [tx, setTx] = useState(null);
  const dispatch = useDispatch();

  const limitMin = parseFloat(paymentProviderDetails?.limit?.min);
  const limitMax = parseFloat(paymentProviderDetails?.limit?.max);

  useEffect(() => {
    const _tx = transactions.find(t => t.transactionId === txId);
    setTx(_tx);

    switch (stateMap(_tx?.state)) {
      case 'success':
        setStep(STEPS.RECEIPT);
        break;
      case 'failure':
        setStep(STEPS.ERROR);
        break;
      case 'pending':
        setStep(STEPS.RECEIPT);
        break;
      case 'cancel':
        setStep(STEPS.RECEIPT);
        break;
    }
  }, [txId, transactions]);

  const handleOnSubmitAmountStep = async data => {
    if (error) {
      setFormError();
    }

    dispatch(pushGtmEvent({ event: GTM_EVENTS.DEPOSIT_INIT }));

    window.scrollTo(0, 0);

    setIsLoading(true);
    try {
      const res = await paymentProvider({
        amount: data.amount,
        merchantId,
        sessionId,
        userId,
        locale,
        cryptoCurrency: currency,
        attributes: {
          aleaccBonusCode: bonusCode,
          successUrl:
            window.location.origin +
            '/instant-deposit?state=success&txId=${ptx.id}',
          failureUrl:
            window.location.origin +
            '/instant-deposit?state=failure&txId=${ptx.id}',
          pendingUrl:
            window.location.origin +
            '/instant-deposit?state=pending&txId=${ptx.id}',
          cancelUrl:
            window.location.origin +
            '/instant-deposit?state=cancel&txId=${ptx.id}',
        },
      });
      setRedirectOutput(res.data);
      setStep(STEPS.PAYMENT);
    } catch (e) {
      const key = Object.keys(e?.errors)[0];
      setFormError(e?.errors?.[key] ? e?.errors?.[key] : 'unknown');
    }
    setIsLoading(false);
  };

  const renderStep = step => {
    const section = content[step + stateMap(tx?.state)] || content[step];
    switch (step) {
      case STEPS.AMOUNT:
        return (
          <>
            <AmountStep
              onChangeCurrency={e => onChangeCurrency(e.target.value)}
              onChangeAmount={e => setAmount(e?.target?.value)}
              amount={amount}
              bonuses={bonuses}
              bonusCode={bonusCode}
              onOpenBonus={() => setStep(STEPS.BONUS)}
              formError={formError}
              isLoading={isLoading}
              onSubmit={handleOnSubmitAmountStep}
              currency={currency}
              wallets={wallets}
              currencies={currencies}
              displayCurrency={displayCurrency}
              exchangeRates={exchangeRates}
              content={section?.content}
              limitMin={limitMin}
              limitMax={limitMax}
              amountButtons={quickAmounts?.value?.[currency]}
            />
          </>
        );
      case STEPS.PAYMENT:
        return (
          <PaymentStep
            isLoading={isLoading}
            redirectOutput={redirectOutput}
            onClose={() => setStep(STEPS.AMOUNT)}
            onSuccess={() => setStep(STEPS.RECEIPT)}
            content={section?.content}
          />
        );
      case STEPS.RECEIPT:
        return (
          <ReceiptStep
            tx={tx}
            cta={section?.cta}
            backUrl={backUrl}
            content={section?.content}
          />
        );
      case STEPS.BONUS:
        return (
          <BonusStep
            bonuses={bonuses}
            bonusCode={bonusCode}
            onClose={() => setStep(STEPS.AMOUNT)}
            onSelect={b => {
              setStep(STEPS.AMOUNT);
              setBonusCode(b?.bonusCode);
            }}
            content={section?.content}
          />
        );
      case STEPS.ERROR:
        return (
          <ErrorStep error={error} content={content[STEPS.ERROR]?.content} />
        );
    }
  };

  return (
    <div className="InstantCashier">
      {backUrl && step !== STEPS.BONUS && (
        <Link className="InstantCashier__close" to={backUrl}>
          <Icon icon="close" />
        </Link>
      )}

      {renderStep(step)}
    </div>
  );
};

const AmountStep = injectIntl(
  ({
    onSubmit,
    isLoading,
    onChangeAmount,
    amount,
    onChangeCurrency,
    currency,
    currencies,
    displayCurrency,
    formError,
    amountButtons = [0.001, 0.0025, 0.005],
    exchangeRates,
    content = '',
    onOpenBonus,
    bonuses,
    bonusCode,
    limitMin,
    limitMax,
    intl,
  }) => {
    const [displayAmount, setDisplayAmount] = useState('');
    const onBtnClick = (e, a) => {
      e.preventDefault();
      onChangeAmount({
        target: {
          value: a,
        },
      });
    };

    useEffect(() => {
      if (
        amount &&
        exchangeRates?.[currency] &&
        exchangeRates?.[displayCurrency]
      ) {
        setDisplayAmount(
          (amount * exchangeRates?.[displayCurrency]) /
            exchangeRates?.[currency]
        );
      }
    }, [exchangeRates, amount, currency, displayCurrency]);

    const Symbol = symbols[currency];

    const parsedAmount = parseFloat(amount);
    const showError =
      limitMin && limitMax
        ? parsedAmount < limitMin || parsedAmount > limitMax
        : false;

    // Error messages
    const messageId =
      showError && (parsedAmount < limitMin || !parsedAmount)
        ? 'label.paynplay.below_minimum'
        : 'label.paynplay.above_maximum';

    const invalidAmount =
      limitMin && limitMax
        ? !parsedAmount ||
          (parsedAmount && parsedAmount < limitMin) ||
          parsedAmount > limitMax
        : false;

    return (
      <div
        className={cn('InstantCashier__Step InstantCashier__AmountStep', {
          'InstantCashier__AmountStep--isLoading': isLoading,
        })}>
        <div
          className="InstantCashier__Step__content"
          dangerouslySetInnerHTML={{ __html: content && marked(content) }}
        />

        <BonusButton
          bonusCode={bonusCode}
          numBonuses={bonuses.length}
          onClick={onOpenBonus}
        />

        <div className="InstantCashier__Step__main">
          <form
            onSubmit={e => {
              e.preventDefault();
              onSubmit({ amount });
            }}>
            {formError && getErrorMessages(formError)}

            <fieldset className="InstantCashier__AmountStep__amount">
              <Input
                step="any"
                type="number"
                name="amount"
                inputMode="decimal"
                autofocus
                onChange={onChangeAmount}
                value={amount}
                placeholder={translate(
                  {
                    id: 'label.instant-deposit.placeholder-amount',
                    defaultMessage: '{placeholderAmount}',
                    values: {
                      placeholderAmount: currency
                        ? moneyFormat(currency).format(0)
                        : null,
                    },
                  },
                  intl
                )}
              />
              {currencies && Symbol && <Symbol />}
              {currencies && (
                <Select value={currency} onChange={onChangeCurrency}>
                  {currencies.map(c => (
                    <Select.Option key={c}>{c}</Select.Option>
                  ))}
                </Select>
              )}

              <input
                type="submit"
                hidden
                disabled={isLoading || invalidAmount}
              />
            </fieldset>

            {displayCurrency != currency && (
              <div className="InstantCashier__AmountStep__fiat_amount">
                <Translate
                  id="label.instant-deposit.display-amount"
                  defaultMessage="Amount in display currency ({currency}): {amount}"
                  values={{
                    amount: (
                      <Money value={displayAmount} currency={displayCurrency} />
                    ),
                    currency: displayCurrency,
                  }}
                />
              </div>
            )}

            {limitMin && limitMax && (
              <div className="InstantCashier__AmountStep__limits">
                <Translate
                  id="label.instant-deposit.limits"
                  defaultMessage="Min: {min} Max: {max}"
                  values={{
                    min: <Money value={limitMin} currency={currency} />,
                    max: <Money value={limitMax} currency={currency} />,
                  }}
                />
              </div>
            )}

            <div className="InstantCashier__AmountStep__amounts">
              {amountButtons?.map(a => (
                <Button
                  key={a}
                  className="button tertiary wide"
                  onClick={e => onBtnClick(e, a)}>
                  {Symbol ? <Symbol /> : null}{' '}
                  {currency ? moneyFormat(currency).format(a) : null}
                </Button>
              ))}
            </div>
            <Button
              className="button primary wide large"
              type="submit"
              disabled={isLoading || invalidAmount}
              isLoading={isLoading}>
              <Translate id="action.deposit" defaultMessage="Deposit" />
            </Button>
          </form>

          {Boolean(showError) && (
            <DelayVisibility>
              <div className="InstantCashier__AmountStep__error">
                <Translate
                  id={messageId}
                  values={{
                    minAmount: limitMin,
                    maxAmount: limitMax,
                    currency: currency,
                  }}
                />
              </div>
            </DelayVisibility>
          )}
        </div>
      </div>
    );
  }
);

const PaymentStep = ({
  redirectOutput,
  isLoading,
  onClose,
  onSuccess,
  content,
}) => {
  return (
    <div
      className={cn('InstantCashier__Step InstantCashier__PaymentStep', {
        'InstantCashier__PaymentStep--isLoading': isLoading,
      })}>
      <div
        className="InstantCashier__Step__content"
        dangerouslySetInnerHTML={{ __html: content && marked(content) }}
      />
      <div className="InstantCashier__Step__main">
        <PaymentForm
          data={redirectOutput}
          onClose={onClose}
          onSuccess={onSuccess}
          iframeProps={{
            scrolling: 'no',
          }}
        />
      </div>
    </div>
  );
};

const ReceiptStep = ({
  tx,
  isLoading,
  success,
  amount,
  content,
  cta,
  backUrl,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const state = stateMap(tx?.state);

    if (['success', 'pending'].includes(state)) {
      dispatch(onSuccess({ amount: tx?.txAmount }));
    }
  }, [dispatch, tx?.state, tx?.txAmount]);

  return (
    <div
      className={cn('InstantCashier__Step InstantCashier__ReceiptStep', {
        'InstantCashier__ReceiptStep--isLoading': isLoading,
      })}>
      <div
        className="InstantCashier__Step__content"
        dangerouslySetInnerHTML={{ __html: content && marked(content) }}
      />
      <div className="InstantCashier__Step__main">
        <hr />
        <table>
          <tr>
            <td>
              <Translate
                id="label.instant-deposit.amount"
                defaultMessage="Amount"
              />
            </td>
            <td>{tx?.amount}</td>
          </tr>
          <tr>
            <td>
              <Translate id="label.instant-deposit.fee" defaultMessage="Fee" />
            </td>
            <td>{tx?.fee}</td>
          </tr>
        </table>

        {cta && (
          <Link to={backUrl} className="button primary large wide">
            {cta}
          </Link>
        )}
      </div>
    </div>
  );
};

const ErrorStep = ({ error, isLoading, content }) => {
  return (
    <div
      className={cn('InstantCashier__Step InstantCashier__ErrorStep', {
        'InstantCashier__ErrorStep--isLoading': isLoading,
      })}>
      <div
        className="InstantCashier__Step__content"
        dangerouslySetInnerHTML={{ __html: content && marked(content) }}
      />
      <div className="InstantCashier__Step__main">{error}</div>
    </div>
  );
};

const BonusStep = ({
  bonuses,
  onClose,
  content,
  onSelect,
  bonusCode,
  isLoading = false,
}) => {
  return (
    <div
      className={cn('InstantCashier__Step InstantCashier__BonusStep', {
        'InstantCashier__BonusStep--isLoading': isLoading,
      })}>
      <div
        className="InstantCashier__Step__content"
        dangerouslySetInnerHTML={{ __html: content && marked(content) }}
      />
      <div className="InstantCashier__Step__main">
        <div className="Bonuses">
          {bonuses.map(b => (
            <div
              className={cn('Bonus', {
                'Bonus--selected': b.bonusCode === bonusCode,
              })}
              key={b.identifier}>
              <img src={b.image.file.url} />
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(b.summary || ''),
                }}
              />

              <button className="button primary" onClick={() => onSelect?.(b)}>
                <Translate
                  id="action.instant-deposit.select-bonus"
                  defaultMessage="Select"
                />
              </button>
            </div>
          ))}
          <button className="button tertiary" onClick={() => onSelect?.(null)}>
            <Translate
              id="action.instant-deposit.select-no-bonus"
              defaultMessage="No bonus"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const BonusButton = ({ numBonuses, bonusCode, onClick }) => {
  return (
    <div
      className={cn('InstantCashier__BonusButton', {
        'InstantCashier__BonusButton--NoBonus': numBonuses === 0,
      })}
      onClick={numBonuses > 0 ? onClick : null}>
      <Icon icon="promotions" />
      {!bonusCode && (
        <Translate
          id="action.instant-deposit.open-bonuses"
          defaultMessage={`You have {numBonuses, plural,
          =0 {no bonuses available.}
          =1 {one bonus available.}
          other {# bonuses available.}
        }`}
          values={{ numBonuses }}
        />
      )}
      {bonusCode && (
        <Translate
          id="action.instant-deposit.open-bonuses-selected"
          defaultMessage={`You have selected {bonusCode}.`}
          values={{ bonusCode }}
        />
      )}
    </div>
  );
};

export default withRouter(InstantCashier);

import React, { Component } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import RouterContext from './RouterContext';

const withOverlayRouter = BaseComponent => {
  class WithOverlayRouter extends Component {
    render() {
      return (
        <RouterContext.Consumer>
          {context => {
            if (!context.location) {
              console.error('Missing locaion in context');
              return null;
            }

            return (
              <BaseComponent
                {...this.props}
                overlayLocation={context.location}
                overlayMatches={context.matches}
              />
            );
          }}
        </RouterContext.Consumer>
      );
    }
  }

  return hoistNonReactStatic(WithOverlayRouter, BaseComponent);
};

export default withOverlayRouter;

import { useState, useEffect } from 'react';

const DelayVisibility = ({ delay = 1000, children }) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const id = setTimeout(() => setVisible(true), delay);
    return () => clearTimeout(id);
  });
  return !visible ? null : children;
};

export default DelayVisibility;

import { createIntl, createIntlCache } from 'react-intl';
import { GTM_EVENTS } from '@utils/google-tag-manager';
import { pushGtmEvent } from '@actions/app';

export const translationError = error => {
  const errors = error.message
    .replace(/\n/g, ' ')
    .match(/\] ([^:]+):."([^"]+)"(.*)/m);

  if (!errors || !__CLIENT__) return;

  window.reduxStore.dispatch(
    pushGtmEvent({
      event: GTM_EVENTS.MISSING_TRANSLATION,
      data: {
        translationError: errors[1],
        translationKey: errors[2],
        translationLanguage: errors[3]
          .replace(', using default message as fallback.', '')
          .trim(),
      },
    })
  );
};

export const getIntl = state => {
  const {
    translation: { messages },
    app: { locale },
  } = state;

  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale,
      messages,
      onError: error => translationError(error),
    },
    cache
  );

  return intl;
};

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { Loader } from 'tg-core-components';
import { setProperties } from 'tg-core-redux/lib/modules/player_properties/action';
import { useInnerDimensions } from '@utils/hooks';
import { loadScript, removeScript } from '@utils/script';
import { useUrls } from '@utils/urls';

const Betconstruct = ({
  user,
  device,
  locale,
  token,
  config,
  match,
  sessionId,
  getWallet,
  getWallets,
  history,
  location,
  addAlert,
  isAuthenticated,
  oddsFormat: oddsType,
  layout,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const dispatch = useDispatch();

  const { signInString, signUpString, depositString } = useUrls();
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  const [innerWidth, innerHeight] = useInnerDimensions();

  const refUrl = __CLIENT__
    ? encodeURI(location.pathname + location.search + location.hash)
    : null;

  const host =
    config.betconstruct.host[
      device?.toLowerCase() === 'desktop' ? 'desktop' : 'mobile'
    ];

  const container = useRef(null);

  /**
   * Add script
   */
  const userId = user?.Id;
  useEffect(() => {
    const options = {
      containerID: 'bcsportsbookcontainer',
      UserId: userId,
      AuthToken: token || 'anonymous',
      lang: langMap[locale.split('-')[0]] || 'eng',
      sportsBookLayout: layout || 'classic',
      oddsType: oddsType || 'decimal',
      dynamicheight: 'true',
      callbackName: 'bettingCB',
      type: 'prematch',
      page: device?.toLowerCase() === 'mobile' ? 'prematch' : '',
      sport: match.params.sport,
      region: match.params.league,
      competition: '',
      game: match.params.event,
    };
    // If signed in -> wait for token
    if (!scriptLoaded && (isAuthenticated ? token && userId : true)) {
      setScriptLoaded(true);
      removeScript('bcsportsbook');
      loadScript(`${host}?${qs.stringify(options)}`, 'bcsportsbook');
    }
  }, [
    host,
    device,
    locale,
    match.params.event,
    match.params.league,
    match.params.sport,
    scriptLoaded,
    token,
    userId,
    oddsType,
    isAuthenticated,
  ]);

  if (container?.current?.children?.length > 1) {
    container?.current.removeChild(container?.current?.children[1]);
  }

  const setBetconstructLayout = layout => {
    dispatch(
      setProperties(sessionId, {
        Properties: [
          {
            Name: 'sportsbook-layout',
            Type: 'Text',
            Value: layout,
          },
        ],
      })
    );
  };

  /**
   * Setup listeners for postMessages and "bettingCB"
   */
  useEffect(() => {
    const actionHandler = (type, data) => {
      switch (type) {
        case 'applicationReady':
          setIsLoading(false);
          break;
        case 'balanceUpdate':
        case 'balance':
          sessionId && getWallet(sessionId);
          sessionId && getWallets(sessionId);

          // Fetching balance again to give backend time to update
          // Remove this when we have balance push functionality
          sessionId &&
            setTimeout(() => {
              getWallet(sessionId);
              getWallets(sessionId);
            }, 5000);
          break;
        case 'openParentLoginPopup':
          addAlert({
            type: 'label.sign-in-to-place-bet',
            level: 'warning',
          });
          history.push(signInString());
          break;
        case 'login':
          history.push(signInString());
          break;
        case 'register':
          history.push(signUpString());
          break;
        case 'deposit':
          history.push(
            depositString({ search: `?ref_url=${encodeURIComponent(refUrl)}` })
          );
          break;
        case 'switchSportsbookLayout':
          setBetconstructLayout(data);
          break;
      }
    };

    const message = e =>
      actionHandler(e.data.type || e.data.data?.type, e.data.value);
    window.addEventListener('message', message);
    window.bettingCB = (name, data) => actionHandler(name, data);
    return () => {
      window.removeEventListener('message', message);
      window.bettingCB = null;
    };
  });

  /**
   * Post message to the iframe
   */
  const postMessage = data =>
    document
      .querySelector('#bcsportsbookcontainer iframe')
      ?.contentWindow.postMessage(data, '*');

  const signOut = useCallback(
    () =>
      device?.toLowerCase() === 'desktop'
        ? postMessage({ action: 'logout' })
        : postMessage({ type: 'logout' }),
    [device]
  );

  const signIn = useCallback(
    () =>
      device?.toLowerCase() === 'desktop'
        ? postMessage({ action: 'login', user_id: userId, auth_token: token })
        : postMessage({
            type: 'login',
            data: { userId: userId, authToken: token },
          }),
    [device, token, userId]
  );

  /**
   * Sign out when userId is missing
   */
  useEffect(() => {
    if (!isLoading && !userId) signOut();
  }, [isLoading, signOut, userId]);

  /**
   * Sign out on unmount
   */
  useEffect(() => {
    return () => signOut();
  }, [signOut]);

  /**
   * Sign in
   */
  useEffect(() => {
    if (!isLoading && token && userId) signIn();
  }, [isLoading, signIn, token, userId]);

  return (
    <div
      className="Betconstruct"
      style={{
        '--inner-width': hasMounted ? `${innerWidth}px` : '100vw',
        '--inner-height': hasMounted ? `${innerHeight}px` : '100vh',
      }}>
      {isLoading && <Loader inline />}
      <div id="bcsportsbookcontainer" ref={container}></div>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,minimal-ui"
        />
      </Helmet>
    </div>
  );
};

export default withRouter(Betconstruct);

const langMap = {
  hy: 'arm', // Armenian
  bg: 'bgr', // Bulgarian
  bs: 'bos', // Bosnian
  zh: 'chi', // Chinese
  cs: 'cze', // Czech
  nl: 'dut', // Dutch
  en: 'eng', // English
  fa: 'fas', // Farsi
  fr: 'fre', // French
  ka: 'geo', // Georgian
  de: 'ger', // German
  el: 'gre', // Greek
  hr: 'hrv', // Croatian
  it: 'ita', // Italian
  ja: 'jpn', // Japanese
  ko: 'kor', // Korean
  lt: 'lit', // Lithuanian
  no: 'nor', // Norwegian
  nb: 'nor', // Norwegian
  mk: 'mac', // Macedonian
  pl: 'pol', // Polish
  pt: 'por', // Portuguese
  // pt­br, // Brazilian Portuguese rus Russian
  sl: 'slv', // Slovenian
  es: 'spa', // Spanish
  sr: 'srp', // Serbian
  sv: 'swe', // Swedish
  tr: 'tur', // Turkish
  uk: 'ukr', // Ukrainian
  fi: 'fin', // Finnish
};

import queryString from 'query-string';

/**
 * @name getQueryParams
 * @description Easily get a query param.
 * @param location React router location object
 * @param queryName The name of the param you're getting
 */
export const getQueryParams = ({ location, queryName }) =>
  location &&
  location.search &&
  queryString.parse(location.search) &&
  queryString.parse(location.search)[queryName];

/**
 * @name redirectToRefUrlIfPresent
 * @description Redirects to the ref_url if it exists.
 * @param routerHistory The react router history object
 * @param redirectUrlIfRefUrlIsEmpty Url to redirect to if the ref_url is empty
 */
export const redirectToRefUrlIfPresent = (
  routerHistory,
  redirectUrlIfRefUrlIsEmpty,
  hardReload
) => {
  // Let's refactor this...
  const hashUrl = routerHistory.location.hash.split('/');
  const index = hashUrl.indexOf('redirect');
  if (index >= 0 && hashUrl[index + 1]) {
    hardReload
      ? (window.location.href = hashUrl[index + 1])
      : routerHistory.push(hashUrl[index + 1]);

    return;
  }

  const refUrl = getQueryParams({
    location: routerHistory.location,
    queryName: 'ref_url',
  });
  if (refUrl) {
    hardReload ? (window.location.href = refUrl) : routerHistory.push(refUrl);
  } else if (redirectUrlIfRefUrlIsEmpty) {
    hardReload
      ? (window.location.href = redirectUrlIfRefUrlIsEmpty)
      : routerHistory.push(redirectUrlIfRefUrlIsEmpty);
  }
};

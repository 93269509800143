import translate from 'tg-core-components/lib/lib/utils/translate';

const getCountryTranslations = (intl, code) => {
  const countries = {
    AF: () =>
      translate({ id: 'country.afghanistan', defaultMessage: 'Afghanistan' }),
    AX: () =>
      translate({
        id: 'country.åland-islands',
        defaultMessage: 'Åland Islands',
      }),
    AL: () => translate({ id: 'country.albania', defaultMessage: 'Albania' }),
    DZ: () => translate({ id: 'country.algeria', defaultMessage: 'Algeria' }),
    AS: () =>
      translate(
        {
          id: 'country.american-samoa',
          defaultMessage: 'American Samoa',
        },
        intl
      ),
    AD: () =>
      translate(
        {
          id: 'country.andorra',
          defaultMessage: 'AndorrA',
        },
        intl
      ),
    AO: () =>
      translate(
        {
          id: 'country.angola',
          defaultMessage: 'Angola',
        },
        intl
      ),
    AI: () =>
      translate(
        {
          id: 'country.anguilla',
          defaultMessage: 'Anguilla',
        },
        intl
      ),
    AQ: () =>
      translate(
        {
          id: 'country.antarctica',
          defaultMessage: 'Antarctica',
        },
        intl
      ),
    AG: () =>
      translate(
        {
          id: 'country.antigua-and-barbuda',
          defaultMessage: 'Antigua and Barbuda',
        },
        intl
      ),
    AR: () =>
      translate(
        {
          id: 'country.argentina',
          defaultMessage: 'Argentina',
        },
        intl
      ),
    AM: () =>
      translate(
        {
          id: 'country.armenia',
          defaultMessage: 'Armenia',
        },
        intl
      ),
    AW: () =>
      translate(
        {
          id: 'country.aruba',
          defaultMessage: 'Aruba',
        },
        intl
      ),
    AU: () =>
      translate(
        {
          id: 'country.australia',
          defaultMessage: 'Australia',
        },
        intl
      ),
    AT: () =>
      translate(
        {
          id: 'country.austria',
          defaultMessage: 'Austria',
        },
        intl
      ),
    AZ: () =>
      translate(
        {
          id: 'country.azerbaijan',
          defaultMessage: 'Azerbaijan',
        },
        intl
      ),

    BS: () =>
      translate(
        {
          id: 'country.bahamas',
          defaultMessage: 'Bahamas',
        },
        intl
      ),
    BH: () =>
      translate(
        {
          id: 'country.bahrain',
          defaultMessage: 'Bahrain',
        },
        intl
      ),
    BD: () =>
      translate(
        {
          id: 'country.bangladesh',
          defaultMessage: 'Bangladesh',
        },
        intl
      ),
    BB: () =>
      translate(
        {
          id: 'country.barbados',
          defaultMessage: 'Barbados',
        },
        intl
      ),
    BY: () =>
      translate(
        {
          id: 'country.belarus',
          defaultMessage: 'Belarus',
        },
        intl
      ),
    BE: () =>
      translate(
        {
          id: 'country.belgium',
          defaultMessage: 'Belgium',
        },
        intl
      ),
    BZ: () =>
      translate(
        {
          id: 'country.belize',
          defaultMessage: 'Belize',
        },
        intl
      ),
    BJ: () =>
      translate(
        {
          id: 'country.benin',
          defaultMessage: 'Benin',
        },
        intl
      ),
    BM: () =>
      translate(
        {
          id: 'country.bermuda',
          defaultMessage: 'Bermuda',
        },
        intl
      ),
    BT: () =>
      translate(
        {
          id: 'country.bhutan',
          defaultMessage: 'Bhutan',
        },
        intl
      ),
    BO: () =>
      translate(
        {
          id: 'country.bolivia',
          defaultMessage: 'Bolivia',
        },
        intl
      ),
    BA: () =>
      translate(
        {
          id: 'country.bosnia-and-herzegovina',
          defaultMessage: 'Bosnia and Herzegovina',
        },
        intl
      ),
    BW: () =>
      translate(
        {
          id: 'country.botswana',
          defaultMessage: 'Botswana',
        },
        intl
      ),
    BV: () =>
      translate(
        {
          id: 'country.bouvet-island',
          defaultMessage: 'Bouvet Island',
        },
        intl
      ),
    BR: () =>
      translate(
        {
          id: 'country.brazil',
          defaultMessage: 'Brazil',
        },
        intl
      ),
    IO: () =>
      translate(
        {
          id: 'country.british-indian-ocean-territory',
          defaultMessage: 'British Indian Ocean Territory',
        },
        intl
      ),
    BN: () =>
      translate(
        {
          id: 'country.brunei-darussalam',
          defaultMessage: 'Brunei Darussalam',
        },
        intl
      ),
    BG: () =>
      translate(
        {
          id: 'country.bulgaria',
          defaultMessage: 'Bulgaria',
        },
        intl
      ),
    BF: () =>
      translate(
        {
          id: 'country.burkina-faso',
          defaultMessage: 'Burkina Faso',
        },
        intl
      ),
    BI: () =>
      translate(
        {
          id: 'country.burundi',
          defaultMessage: 'Burundi',
        },
        intl
      ),
    KH: () =>
      translate(
        {
          id: 'country.cambodia',
          defaultMessage: 'Cambodia',
        },
        intl
      ),
    CM: () =>
      translate(
        {
          id: 'country.cameroon',
          defaultMessage: 'Cameroon',
        },
        intl
      ),
    CA: () =>
      translate(
        {
          id: 'country.canada',
          defaultMessage: 'Canada',
        },
        intl
      ),
    CV: () =>
      translate(
        {
          id: 'country.cape-verde',
          defaultMessage: 'Cape Verde',
        },
        intl
      ),
    KY: () =>
      translate(
        {
          id: 'country.cayman-islands',
          defaultMessage: 'Cayman Islands',
        },
        intl
      ),
    CF: () =>
      translate(
        {
          id: 'country.central-african-republic',
          defaultMessage: 'Central African Republic',
        },
        intl
      ),
    TD: () =>
      translate(
        {
          id: 'country.chad',
          defaultMessage: 'Chad',
        },
        intl
      ),
    CL: () =>
      translate(
        {
          id: 'country.chile',
          defaultMessage: 'Chile',
        },
        intl
      ),
    CN: () =>
      translate(
        {
          id: 'country.china',
          defaultMessage: 'China',
        },
        intl
      ),
    CX: () =>
      translate(
        {
          id: 'country.christmas-island',
          defaultMessage: 'Christmas Island',
        },
        intl
      ),
    CC: () =>
      translate(
        {
          id: 'country.cocos-islands',
          defaultMessage: 'Cocos (Keeling) Islands',
        },
        intl
      ),
    CO: () =>
      translate(
        {
          id: 'country.colombia',
          defaultMessage: 'Colombia',
        },
        intl
      ),
    KM: () =>
      translate(
        {
          id: 'country.comoros',
          defaultMessage: 'Comoros',
        },
        intl
      ),
    CG: () =>
      translate(
        {
          id: 'country.congo',
          defaultMessage: 'Congo',
        },
        intl
      ),
    CD: () =>
      translate(
        {
          id: 'country.congo-democratic-republic',
          defaultMessage: 'Congo, The Democratic Republic of the',
        },
        intl
      ),
    CK: () =>
      translate(
        {
          id: 'country.cook-islands',
          defaultMessage: 'Cook Islands',
        },
        intl
      ),
    CR: () =>
      translate(
        {
          id: 'country.costa-rica',
          defaultMessage: 'Costa Rica',
        },
        intl
      ),
    CI: () =>
      translate(
        {
          id: 'country.cote-d-ivoire',
          defaultMessage: 'Cote D Ivoire',
        },
        intl
      ),
    HR: () =>
      translate(
        {
          id: 'country.croatia',
          defaultMessage: 'Croatia',
        },
        intl
      ),
    CU: () =>
      translate(
        {
          id: 'country.cuba',
          defaultMessage: 'Cuba',
        },
        intl
      ),
    CY: () =>
      translate({ id: 'country.cyprus', defaultMessage: 'Cyprus' }, intl),
    CZ: () =>
      translate(
        {
          id: 'country.czech-republic',
          defaultMessage: 'Czech Republic',
        },
        intl
      ),
    DK: () =>
      translate({ id: 'country.denmark', defaultMessage: 'Denmark' }, intl),
    DJ: () =>
      translate({ id: 'country.djibouti', defaultMessage: 'Djibouti' }, intl),
    DM: () =>
      translate({ id: 'country.dominica', defaultMessage: 'Dominica' }, intl),
    DO: () =>
      translate(
        {
          id: 'country.dominican-republic',
          defaultMessage: 'Dominican Republic',
        },
        intl
      ),
    EC: () =>
      translate({ id: 'country.ecuador', defaultMessage: 'Ecuador' }, intl),
    EG: () => translate({ id: 'country.egypt', defaultMessage: 'Egypt' }, intl),
    SV: () =>
      translate(
        { id: 'country.el-salvador', defaultMessage: 'El Salvador' },
        intl
      ),
    GQ: () =>
      translate(
        {
          id: 'country.equatorial-guinea',
          defaultMessage: 'Equatorial Guinea',
        },
        intl
      ),
    ER: () =>
      translate({ id: 'country.eritrea', defaultMessage: 'Eritrea' }, intl),
    EE: () =>
      translate({ id: 'country.estonia', defaultMessage: 'Estonia' }, intl),
    ET: () =>
      translate({ id: 'country.ethiopia', defaultMessage: 'Ethiopia' }, intl),
    FK: () =>
      translate(
        {
          id: 'country.falkland-islands',
          defaultMessage: 'Falkland Islands (Malvinas)',
        },
        intl
      ),
    FO: () =>
      translate(
        {
          id: 'country.faroe-islands',
          defaultMessage: 'Faroe Islands',
        },
        intl
      ),
    FJ: () => translate({ id: 'country.fiji', defaultMessage: 'Fiji' }, intl),
    FI: () =>
      translate({ id: 'country.finland', defaultMessage: 'Finland' }, intl),
    FR: () =>
      translate({ id: 'country.france', defaultMessage: 'France' }, intl),
    GF: () =>
      translate(
        {
          id: 'country.french-guiana',
          defaultMessage: 'French Guiana',
        },
        intl
      ),
    PF: () =>
      translate(
        {
          id: 'country.french-polynesia',
          defaultMessage: 'French Polynesia',
        },
        intl
      ),
    TF: () =>
      translate(
        {
          id: 'country.french-southern-territories',
          defaultMessage: 'French Southern Territories',
        },
        intl
      ),
    GA: () => translate({ id: 'country.gabon', defaultMessage: 'Gabon' }, intl),
    GM: () =>
      translate({ id: 'country.gambia', defaultMessage: 'Gambia' }, intl),
    GE: () =>
      translate({ id: 'country.georgia', defaultMessage: 'Georgia' }, intl),
    DE: () =>
      translate({ id: 'country.germany', defaultMessage: 'Germany' }, intl),
    GH: () => translate({ id: 'country.ghana', defaultMessage: 'Ghana' }, intl),
    GI: () =>
      translate({ id: 'country.gibraltar', defaultMessage: 'Gibraltar' }, intl),
    GR: () =>
      translate({ id: 'country.greece', defaultMessage: 'Greece' }, intl),
    GL: () =>
      translate({ id: 'country.greenland', defaultMessage: 'Greenland' }, intl),
    GD: () =>
      translate({ id: 'country.grenada', defaultMessage: 'Grenada' }, intl),
    GP: () =>
      translate(
        { id: 'country.guadeloupe', defaultMessage: 'Guadeloupe' },
        intl
      ),
    GU: () => translate({ id: 'country.guam', defaultMessage: 'Guam' }, intl),
    GT: () =>
      translate({ id: 'country.guatemala', defaultMessage: 'Guatemala' }, intl),
    GG: () =>
      translate({ id: 'country.guernsey', defaultMessage: 'Guernsey' }, intl),
    GN: () =>
      translate({ id: 'country.guinea', defaultMessage: 'Guinea' }, intl),
    GW: () =>
      translate(
        {
          id: 'country.guinea-Bissau',
          defaultMessage: 'Guinea-Bissau',
        },
        intl
      ),
    GY: () =>
      translate({ id: 'country.guyana', defaultMessage: 'Guyana' }, intl),
    HT: () => translate({ id: 'country.haiti', defaultMessage: 'Haiti' }, intl),
    HM: () =>
      translate(
        {
          id: 'country.heard-island',
          defaultMessage: 'Heard Island and Mcdonald Islands',
        },
        intl
      ),
    VA: () =>
      translate(
        {
          id: 'country.holy-See',
          defaultMessage: 'Holy See (Vatican City State)',
        },
        intl
      ),
    HN: () =>
      translate({ id: 'country.honduras', defaultMessage: 'Honduras' }, intl),
    HK: () =>
      translate({ id: 'country.hong-kong', defaultMessage: 'Hong Kong' }, intl),
    HU: () =>
      translate({ id: 'country.hungary', defaultMessage: 'Hungary' }, intl),
    IS: () =>
      translate({ id: 'country.iceland', defaultMessage: 'Iceland' }, intl),
    IN: () => translate({ id: 'country.india', defaultMessage: 'India' }, intl),
    ID: () =>
      translate({ id: 'country.indonesia', defaultMessage: 'Indonesia' }, intl),
    IR: () =>
      translate(
        {
          id: 'country.iran',
          defaultMessage: 'Iran, Islamic Republic Of',
        },
        intl
      ),
    IQ: () => translate({ id: 'country.iraq', defaultMessage: 'Iraq' }, intl),
    IE: () =>
      translate({ id: 'country.ireland', defaultMessage: 'Ireland' }, intl),
    IM: () =>
      translate(
        { id: 'country.isle-of-man', defaultMessage: 'Isle of Man' },
        intl
      ),
    IL: () =>
      translate({ id: 'country.israel', defaultMessage: 'Israel' }, intl),
    IT: () => translate({ id: 'country.italy', defaultMessage: 'Italy' }, intl),
    JM: () =>
      translate({ id: 'country.jamaica', defaultMessage: 'Jamaica' }, intl),
    JP: () => translate({ id: 'country.japan', defaultMessage: 'Japan' }, intl),
    JE: () =>
      translate({ id: 'country.jersey', defaultMessage: 'Jersey' }, intl),
    JO: () =>
      translate({ id: 'country.jordan', defaultMessage: 'Jordan' }, intl),
    KZ: () =>
      translate(
        { id: 'country.kazakhstan', defaultMessage: 'Kazakhstan' },
        intl
      ),
    KE: () => translate({ id: 'country.kenya', defaultMessage: 'Kenya' }, intl),
    KI: () =>
      translate({ id: 'country.kiribati', defaultMessage: 'Kiribati' }, intl),
    KP: () =>
      translate(
        {
          id: 'country.korea-democratic-republic',
          defaultMessage: "Korea, Democratic People'S Republic of",
        },
        intl
      ),
    KR: () =>
      translate(
        { id: 'country.korea', defaultMessage: 'Korea, Republic of' },
        intl
      ),
    KW: () =>
      translate({ id: 'country.kuwait', defaultMessage: 'Kuwait' }, intl),
    KG: () =>
      translate(
        { id: 'country.kyrgyzstan', defaultMessage: 'Kyrgyzstan' },
        intl
      ),
    LA: () =>
      translate(
        {
          id: 'country.lao',
          defaultMessage: "Lao People'S Democratic Republic",
        },
        intl
      ),
    LV: () =>
      translate({ id: 'country.latvia', defaultMessage: 'Latvia' }, intl),
    LB: () =>
      translate({ id: 'country.lebanon', defaultMessage: 'Lebanon' }, intl),
    LS: () =>
      translate({ id: 'country.lesotho', defaultMessage: 'Lesotho' }, intl),
    LR: () =>
      translate({ id: 'country.liberia', defaultMessage: 'Liberia' }, intl),
    LY: () =>
      translate(
        {
          id: 'country.libyan-arab-jamahiriya',
          defaultMessage: 'Libyan Arab Jamahiriya',
        },
        intl
      ),
    LI: () =>
      translate(
        {
          id: 'country.liechtenstein',
          defaultMessage: 'Liechtenstein',
        },
        intl
      ),
    LT: () =>
      translate({ id: 'country.lithuania', defaultMessage: 'Lithuania' }, intl),
    LU: () =>
      translate(
        { id: 'country.luxembourg', defaultMessage: 'Luxembourg' },
        intl
      ),
    MO: () => translate({ id: 'country.macao', defaultMessage: 'Macao' }, intl),
    MK: () =>
      translate(
        {
          id: 'country.macedonia',
          defaultMessage: 'Macedonia, The Former Yugoslav Republic of',
        },
        intl
      ),
    MG: () =>
      translate(
        { id: 'country.madagascar', defaultMessage: 'Madagascar' },
        intl
      ),
    MW: () =>
      translate({ id: 'country.malawi', defaultMessage: 'Malawi' }, intl),
    MY: () =>
      translate({ id: 'country.malaysia', defaultMessage: 'Malaysia' }, intl),
    MV: () =>
      translate({ id: 'country.maldives', defaultMessage: 'Maldives' }, intl),
    ML: () => translate({ id: 'country.mali', defaultMessage: 'Mali' }, intl),
    MT: () => translate({ id: 'country.malta', defaultMessage: 'Malta' }, intl),
    MH: () =>
      translate(
        {
          id: 'country.marshall-islands',
          defaultMessage: 'Marshall Islands',
        },
        intl
      ),
    MQ: () =>
      translate(
        { id: 'country.martinique', defaultMessage: 'Martinique' },
        intl
      ),
    MR: () =>
      translate(
        { id: 'country.mauritania', defaultMessage: 'Mauritania' },
        intl
      ),
    MU: () =>
      translate({ id: 'country.mauritius', defaultMessage: 'Mauritius' }, intl),
    YT: () =>
      translate({ id: 'country.mayotte', defaultMessage: 'Mayotte' }, intl),
    MX: () =>
      translate({ id: 'country.mexico', defaultMessage: 'Mexico' }, intl),
    FM: () =>
      translate(
        {
          id: 'country.micronesia',
          defaultMessage: 'Micronesia, Federated States of',
        },
        intl
      ),
    MD: () =>
      translate(
        {
          id: 'country.moldova',
          defaultMessage: 'Moldova, Republic of',
        },
        intl
      ),
    MC: () =>
      translate({ id: 'country.monaco', defaultMessage: 'Monaco' }, intl),
    MN: () =>
      translate({ id: 'country.mongolia', defaultMessage: 'Mongolia' }, intl),
    MS: () =>
      translate(
        { id: 'country.montserrat', defaultMessage: 'Montserrat' },
        intl
      ),
    MA: () =>
      translate({ id: 'country.morocco', defaultMessage: 'Morocco' }, intl),
    MZ: () =>
      translate(
        { id: 'country.mozambique', defaultMessage: 'Mozambique' },
        intl
      ),
    MM: () =>
      translate({ id: 'country.myanmar', defaultMessage: 'Myanmar' }, intl),
    NA: () =>
      translate({ id: 'country.namibia', defaultMessage: 'Namibia' }, intl),
    NR: () => translate({ id: 'country.nauru', defaultMessage: 'Nauru' }, intl),
    NP: () => translate({ id: 'country.nepal', defaultMessage: 'Nepal' }, intl),
    NL: () =>
      translate(
        { id: 'country.netherlands', defaultMessage: 'Netherlands' },
        intl
      ),
    AN: () =>
      translate(
        {
          id: 'country.netherlands-antilles',
          defaultMessage: 'Netherlands Antilles',
        },
        intl
      ),
    NC: () =>
      translate(
        {
          id: 'country.new-caledonia',
          defaultMessage: 'New Caledonia',
        },
        intl
      ),
    NZ: () =>
      translate(
        { id: 'country.new-zealand', defaultMessage: 'New Zealand' },
        intl
      ),
    NI: () =>
      translate({ id: 'country.nicaragua', defaultMessage: 'Nicaragua' }, intl),
    NE: () => translate({ id: 'country.niger', defaultMessage: 'Niger' }, intl),
    NG: () =>
      translate({ id: 'country.nigeria', defaultMessage: 'Nigeria' }, intl),
    NU: () => translate({ id: 'country.niue', defaultMessage: 'Niue' }, intl),
    NF: () =>
      translate(
        {
          id: 'country.norfolk-island',
          defaultMessage: 'Norfolk Island',
        },
        intl
      ),
    MP: () =>
      translate(
        {
          id: 'country.northern-mariana-islands',
          defaultMessage: 'Northern Mariana Islands',
        },
        intl
      ),
    NO: () =>
      translate({ id: 'country.norway', defaultMessage: 'Norway' }, intl),
    OM: () => translate({ id: 'country.oman', defaultMessage: 'Oman' }, intl),
    PK: () =>
      translate({ id: 'country.pakistan', defaultMessage: 'Pakistan' }, intl),
    PW: () => translate({ id: 'country.palau', defaultMessage: 'Palau' }, intl),
    PS: () =>
      translate(
        {
          id: 'country.palestinian-territory',
          defaultMessage: 'Palestinian Territory, Occupied',
        },
        intl
      ),
    PA: () =>
      translate({ id: 'country.panama', defaultMessage: 'Panama' }, intl),
    PG: () =>
      translate(
        {
          id: 'country.papua-new-guinea',
          defaultMessage: 'Papua New Guinea',
        },
        intl
      ),
    PY: () =>
      translate({ id: 'country.paraguay', defaultMessage: 'Paraguay' }, intl),
    PE: () => translate({ id: 'country.peru', defaultMessage: 'Peru' }, intl),
    PH: () =>
      translate(
        { id: 'country.philippines', defaultMessage: 'Philippines' },
        intl
      ),
    PN: () =>
      translate({ id: 'country.pitcairn', defaultMessage: 'Pitcairn' }, intl),
    PL: () =>
      translate({ id: 'country.poland', defaultMessage: 'Poland' }, intl),
    PT: () =>
      translate({ id: 'country.portugal', defaultMessage: 'Portugal' }, intl),
    PR: () =>
      translate(
        { id: 'country.puerto-rico', defaultMessage: 'Puerto Rico' },
        intl
      ),
    QA: () => translate({ id: 'country.qatar', defaultMessage: 'Qatar' }, intl),
    RE: () =>
      translate({ id: 'country.reunion', defaultMessage: 'Reunion' }, intl),
    RO: () =>
      translate({ id: 'country.romania', defaultMessage: 'Romania' }, intl),
    RU: () =>
      translate(
        {
          id: 'country.russian-federation',
          defaultMessage: 'Russian Federation',
        },
        intl
      ),
    RW: () =>
      translate({ id: 'country.rWANDA', defaultMessage: 'RWANDA' }, intl),
    SH: () =>
      translate(
        { id: 'country.saint-helena', defaultMessage: 'Saint Helena' },
        intl
      ),
    KN: () =>
      translate(
        {
          id: 'country.saint-kitts-and-nevis',
          defaultMessage: 'Saint Kitts and Nevis',
        },
        intl
      ),
    LC: () =>
      translate(
        { id: 'country.saint-lucia', defaultMessage: 'Saint Lucia' },
        intl
      ),
    PM: () =>
      translate(
        {
          id: 'country.saint-pierre-and-miquelon',
          defaultMessage: 'Saint Pierre and Miquelon',
        },
        intl
      ),
    VC: () =>
      translate(
        {
          id: 'country.saint-vincent-and-the-grenadines',
          defaultMessage: 'Saint Vincent and the Grenadines',
        },
        intl
      ),
    WS: () => translate({ id: 'country.samoa', defaultMessage: 'Samoa' }, intl),
    SM: () =>
      translate(
        { id: 'country.san-marino', defaultMessage: 'San Marino' },
        intl
      ),
    ST: () =>
      translate(
        {
          id: 'country.sao-tome-and-principe',
          defaultMessage: 'Sao Tome and Principe',
        },
        intl
      ),
    SA: () =>
      translate(
        { id: 'country.saudi-arabia', defaultMessage: 'Saudi Arabia' },
        intl
      ),
    SN: () =>
      translate({ id: 'country.senegal', defaultMessage: 'Senegal' }, intl),
    CS: () =>
      translate(
        {
          id: 'country.serbia-and-montenegro',
          defaultMessage: 'Serbia and Montenegro',
        },
        intl
      ),
    SC: () =>
      translate(
        { id: 'country.seychelles', defaultMessage: 'Seychelles' },
        intl
      ),
    SL: () =>
      translate(
        { id: 'country.sierra-Leone', defaultMessage: 'Sierra Leone' },
        intl
      ),
    SG: () =>
      translate({ id: 'country.singapore', defaultMessage: 'Singapore' }, intl),
    SK: () =>
      translate({ id: 'country.slovakia', defaultMessage: 'Slovakia' }, intl),
    SI: () =>
      translate({ id: 'country.slovenia', defaultMessage: 'Slovenia' }, intl),
    SB: () =>
      translate(
        {
          id: 'country.solomon-islands',
          defaultMessage: 'Solomon Islands',
        },
        intl
      ),
    SO: () =>
      translate({ id: 'country.somalia', defaultMessage: 'Somalia' }, intl),
    ZA: () =>
      translate(
        { id: 'country.south-africa', defaultMessage: 'South Africa' },
        intl
      ),
    GS: () =>
      translate(
        {
          id: 'country.south-georgia',
          defaultMessage: 'South Georgia and the South Sandwich Islands',
        },
        intl
      ),
    ES: () => translate({ id: 'country.spain', defaultMessage: 'Spain' }, intl),
    LK: () =>
      translate({ id: 'country.sri-lanka', defaultMessage: 'Sri Lanka' }, intl),
    SD: () => translate({ id: 'country.sudan', defaultMessage: 'Sudan' }, intl),
    SR: () =>
      translate({ id: 'country.suriname', defaultMessage: 'Suriname' }, intl),
    SJ: () =>
      translate(
        {
          id: 'country.svalbard',
          defaultMessage: 'Svalbard and Jan Mayen',
        },
        intl
      ),
    SZ: () =>
      translate({ id: 'country.swaziland', defaultMessage: 'Swaziland' }, intl),
    SE: () =>
      translate({ id: 'country.sweden', defaultMessage: 'Sweden' }, intl),
    CH: () =>
      translate(
        { id: 'country.switzerland', defaultMessage: 'Switzerland' },
        intl
      ),
    SY: () =>
      translate(
        {
          id: 'country.syrian-arab-republic',
          defaultMessage: 'Syrian Arab Republic',
        },
        intl
      ),
    TW: () =>
      translate(
        {
          id: 'country.taiwan',
          defaultMessage: 'Taiwan, Province of China',
        },
        intl
      ),
    TJ: () =>
      translate(
        { id: 'country.tajikistan', defaultMessage: 'Tajikistan' },
        intl
      ),
    TZ: () =>
      translate(
        {
          id: 'country.tanzania',
          defaultMessage: 'Tanzania, United Republic of',
        },
        intl
      ),
    TH: () =>
      translate({ id: 'country.thailand', defaultMessage: 'Thailand' }, intl),
    TL: () =>
      translate(
        { id: 'country.timor-Leste', defaultMessage: 'Timor-Leste' },
        intl
      ),
    TG: () => translate({ id: 'country.togo', defaultMessage: 'Togo' }, intl),
    TK: () =>
      translate({ id: 'country.tokelau', defaultMessage: 'Tokelau' }, intl),
    TO: () => translate({ id: 'country.tonga', defaultMessage: 'Tonga' }, intl),
    TT: () =>
      translate(
        {
          id: 'country.trinidad',
          defaultMessage: 'Trinidad and Tobago',
        },
        intl
      ),
    TN: () =>
      translate({ id: 'country.tunisia', defaultMessage: 'Tunisia' }, intl),
    TR: () =>
      translate({ id: 'country.turkey', defaultMessage: 'Turkey' }, intl),
    TM: () =>
      translate(
        { id: 'country.turkmenistan', defaultMessage: 'Turkmenistan' },
        intl
      ),
    TC: () =>
      translate(
        {
          id: 'country.caicos-islands',
          defaultMessage: 'Turks and Caicos Islands',
        },
        intl
      ),
    TV: () =>
      translate({ id: 'country.tuvalu', defaultMessage: 'Tuvalu' }, intl),
    UG: () =>
      translate({ id: 'country.uganda', defaultMessage: 'Uganda' }, intl),
    UA: () =>
      translate({ id: 'country.ukraine', defaultMessage: 'Ukraine' }, intl),
    AE: () =>
      translate(
        {
          id: 'country.united-arab-emirates',
          defaultMessage: 'United Arab Emirates',
        },
        intl
      ),
    GB: () =>
      translate(
        {
          id: 'country.united-kingdom',
          defaultMessage: 'United Kingdom',
        },
        intl
      ),
    US: () =>
      translate(
        {
          id: 'country.united-states',
          defaultMessage: 'United States',
        },
        intl
      ),
    UM: () =>
      translate(
        {
          id: 'country.united-states-islands',
          defaultMessage: 'United States Minor Outlying Islands',
        },
        intl
      ),
    UY: () =>
      translate({ id: 'country.uruguay', defaultMessage: 'Uruguay' }, intl),
    UZ: () =>
      translate(
        { id: 'country.uzbekistan', defaultMessage: 'Uzbekistan' },
        intl
      ),
    VU: () =>
      translate({ id: 'country.vanuatu', defaultMessage: 'Vanuatu' }, intl),
    VE: () =>
      translate({ id: 'country.venezuela', defaultMessage: 'Venezuela' }, intl),
    VN: () =>
      translate({ id: 'country.viet Nam', defaultMessage: 'Viet Nam' }, intl),
    VG: () =>
      translate(
        {
          id: 'country.virgin-islands-british',
          defaultMessage: 'Virgin Islands, British',
        },
        intl
      ),
    VI: () =>
      translate(
        {
          id: 'country.virgin-islands-us',
          defaultMessage: 'Virgin Islands, U.S.',
        },
        intl
      ),
    WF: () =>
      translate(
        {
          id: 'country.wallis-and-futuna',
          defaultMessage: 'Wallis and Futuna',
        },
        intl
      ),
    EH: () =>
      translate(
        {
          id: 'country.western-sahara',
          defaultMessage: 'Western Sahara',
        },
        intl
      ),
    YE: () => translate({ id: 'country.yemen', defaultMessage: 'Yemen' }, intl),
    ZM: () =>
      translate({ id: 'country.zambia', defaultMessage: 'Zambia' }, intl),
    ZW: () =>
      translate({ id: 'country.zimbabwe', defaultMessage: 'Zimbabwe' }, intl),
  };

  return countries[code]();
};

export default getCountryTranslations;

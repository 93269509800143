export const signOut = (payload = null) => {
  window.routerHistory.replace('/');

  return {
    type: 'SIGNOUT',
    payload,
  };
};

export const addPendingAction = payload => ({
  type: 'ADD_PENDING_ACTION',
  payload,
});

export const removePendingAction = () => ({
  type: 'REMOVE_PENDING_ACTION',
});

import React from 'react';
import withRouteData, { Loader } from '@utils/withRouteData';
import compose from 'recompose/compose';
import { loadContents } from '@actions/content';
import OffCanvasLayout from '@components/OffCanvasLayout';
import Popup from '@components/Popup';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import marked from 'marked';

const OffCanvasContentContainer = ({ isRouteDataLoading, overlayMatch }) => {
  const page = useSelector(state =>
    state.content.pages?.data?.find(p => p.url.slice(1) === overlayMatch.url)
  );

  return (
    <OffCanvasLayout className="OverlayContentContainer">
      <Loader isLoading={isRouteDataLoading}>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: marked(page?.content || '') }}
        />
      </Loader>
    </OffCanvasLayout>
  );
};

const PopupContentContainer = ({ isRouteDataLoading, overlayMatch }) => {
  const page = useSelector(state =>
    state.content.pages?.data?.find(p => p.url.slice(1) === overlayMatch.url)
  );

  return (
    <Popup className="OverlayContentContainer" title={page?.title}>
      <Loader isLoading={isRouteDataLoading}>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: marked(page?.content || '') }}
        />
      </Loader>
    </Popup>
  );
};

const OverlayContentContainer = props => {
  const page = useSelector(state =>
    state.content.pages?.data?.find(
      p => p.url.slice(1) === props.overlayMatch.url
    )
  );

  if (!page) return null;

  if (page.config?.type === 'sidebar')
    return <OffCanvasContentContainer {...props} />;

  return <PopupContentContainer {...props} />;
};

OverlayContentContainer.hashRoute = true;
OverlayContentContainer.globalLoader = false;
OverlayContentContainer.dataLoader = loadContents([
  {
    name: 'page',
    urls: match => {
      return [...match?.hash.split(',')];
    },
  },
]);

export default compose(withRouteData, withRouter)(OverlayContentContainer);

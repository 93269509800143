import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import OffCanvasLayout from '@components/OffCanvasLayout';
import { connect } from 'react-redux';
import marked from 'marked';
import HistoryWidget from 'tg-core-components/lib/widgets/HistoryWidget';
import {
  paymentStats,
  lifetimeStats,
} from 'tg-core-redux/lib/modules/player/action';
import {
  getPayments,
  getGamePlayTransactions,
  getSportsbookTransactions,
  getBingoTransactions,
} from 'tg-core-redux/lib/modules/transaction/action';
import { cancelUserWithdrawal } from 'tg-core-redux/lib/modules/devcode/action';
import { withConfig } from '@config';
import WinLoss from './WinLoss';
import withRouteData, { Loader } from '@utils/withRouteData';
import { loadContents } from '@actions/content';

import './style.css';

@withConfig
@withRouteData
@connect(
  state => ({
    pages: state.content.pages.data,
    player: state.player,
    sessionId: state.player.sessionId,
    jurisdiction: state.app.jurisdiction,
    paymentTransactions: state.paymentTransactions,
    gameTransactions: state.gameTransactions,
    sportsbookTransactions: state.sportsbookTransactions,
    bingoTransactions: state.bingoTransactions,
  }),
  {
    paymentStats,
    getPayments,
    getGamePlayTransactions,
    getSportsbookTransactions,
    getBingoTransactions,
    cancelUserWithdrawal,
    lifetimeStats,
  }
)
@injectIntl
@withRouter
class HistoryContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard/history'],
    },
  ]);

  componentDidMount = () => {
    const { player, paymentStats, lifetimeStats } = this.props;
    paymentStats(player.sessionId);
    lifetimeStats(player.sessionId);
  };

  render() {
    const {
      player: { user },
      player,
      player: { lifetimeStats },
      sessionId,
      pages,
      paymentTransactions,
      gameTransactions,
      sportsbookTransactions,
      bingoTransactions,
      getPayments,
      getGamePlayTransactions,
      getSportsbookTransactions,
      getBingoTransactions,
      config,
      cancelUserWithdrawal,
      jurisdiction,
    } = this.props;

    const TotalDeposit = player.paymentStats?.TotalDeposit;
    const TotalWithdraw = player.paymentStats?.TotalWithdraw;

    const page = pages?.find(p => p.url === '#dashboard/history');
    const { NGR } = lifetimeStats || {};

    return (
      <OffCanvasLayout className="HistoryContainer" backUrl="#dashboard">
        <Loader isLoading={!page}>
          {page?.content && (
            <header>
              <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
            </header>
          )}
          {jurisdiction !== 'curacao' && (
            <WinLoss
              NetBalance={NGR}
              TotalDeposit={TotalDeposit}
              TotalWithdraw={TotalWithdraw}
              Currency={user?.Currency}
            />
          )}
          <HistoryWidget
            products={config.products}
            items={{
              casino: gameTransactions || [],
              sportsbook: sportsbookTransactions || [],
              payments: paymentTransactions || [],
              bingo: bingoTransactions || [],
            }}
            getTransactions={{
              casino: (max, offset) =>
                getGamePlayTransactions(sessionId, max, offset),
              sportsbook: (max, offset) =>
                getSportsbookTransactions(sessionId, max, offset),
              payments: (max, offset) => getPayments(sessionId, max, offset),
              bingo: (max, offset) =>
                getBingoTransactions(sessionId, max, offset),
            }}
            limit={10}
            currency={user?.Currency}
            cancelUserWithdrawal={cancelUserWithdrawal}
          />
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default HistoryContainer;

import identity from 'lodash/identity';

export const createResource = (
  ACTION_NAME,
  selector = identity,
  initialData,
  subReducer
) => {
  const initState = {
    isLoading: false,
    status: null,
    error: null,
    data: initialData,
  };

  return (state = initState, action) => {
    const { type, payload, error } = action;

    switch (type) {
      case `${ACTION_NAME}_START`:
        return Object.assign({}, state, {
          status: null,
          isLoading: true,
        });

      case `${ACTION_NAME}_COMPLETE`:
        return Object.assign({}, state, {
          isLoading: false,
          status: 'success',
          data: selector(payload),
        });

      case `${ACTION_NAME}_FAILURE`:
        return Object.assign({}, state, {
          status: null,
          isLoading: false,
          error: error,
          data: initialData,
        });
      default:
        return subReducer ? subReducer(state, action) : state;
    }
  };
};

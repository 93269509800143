export const addAlert = payload => {
  return {
    type: 'ADD_ALERT',
    payload,
  };
};

export const removeAlerts = payload => {
  return {
    type: 'REMOVE_ALERTS',
    payload,
  };
};

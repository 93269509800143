import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import OffCanvasLayout from '@components/OffCanvasLayout';
import marked from 'marked';
import inlineRenderer from '@utils/inlineRenderer';
import { Icon, Translate } from 'tg-core-components';
import { startChat } from '@utils/chat';
import { getFirstPathnameInUrl } from '@utils/getFirstPathnameInUrl';
import { withConfig } from '@config';
import { loadContents } from '@actions/content';
import withRouteData from '@utils/withRouteData';
import setLastVisitedCookie from '@utils/setLastVisitedCookie';
import getByPath from 'lodash/get';
import Footer, { selectFooterProps } from '@components/Footer';

import './style.css';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { injectIntl } from 'react-intl';

const NavItem = ({ item, isActive, className, activeClassName, onClick }) =>
  /(http(s?)):\/\//gi.test(item.url) ? (
    <a
      rel="noopener noreferrer"
      href={item.url}
      className={className}
      target="_blank">
      {item.menuIcon && item.menuIcon.file.url && (
        <span className="icon">
          <img src={item.menuIcon.file.url} alt="menu" loading="lazy" />
        </span>
      )}

      <span
        className="text"
        dangerouslySetInnerHTML={{
          __html: marked(item.menu || item.name || '', {
            renderer: inlineRenderer,
          }),
        }}
      />
      <Icon
        icon="external-link"
        className="GlobalNavContainer__external-icon"
      />
    </a>
  ) : (
    <NavLink
      to={`${item.url?.match(/^\#/) ? '' : '/'}${item.url}`}
      exact={true}
      onClick={onClick}
      className={className}
      activeClassName={activeClassName}>
      {item.menuIcon && (
        <span className="icon">
          <img
            src={
              isActive && item.selectedIcon
                ? item.selectedIcon?.file?.url
                : item.menuIcon?.file?.url
            }
            alt="menu"
            loading="lazy"
          />
        </span>
      )}

      <span
        className="text"
        dangerouslySetInnerHTML={{
          __html: marked(item.menu || item.name || '', {
            renderer: inlineRenderer,
          }),
        }}
      />
    </NavLink>
  );

const NavSection = ({ location, items, type, onClickItem }) => (
  <section
    className={`GlobalNavContainer__section GlobalNavContainer__section--${type}`}>
    {items &&
      items.map(i => (
        <NavItem
          key={i.identifier}
          item={i}
          isActive={i.url === getFirstPathnameInUrl(location)}
          className={`GlobalNavContainer__link GlobalNavContainer__link--${type}`}
          activeClassName="GlobalNavContainer__link--active"
          onClick={e => onClickItem?.(i, e)}>
          {i.menu}
        </NavItem>
      ))}
  </section>
);

const AccountSection = ({ intl }) => (
  <section className="GlobalNavContainer__account">
    <NavLink
      className="GlobalNavContainer__button button primary"
      to={{ hash: 'sign-up' }}>
      <Translate id="action.signup" defaultMessage="Create account" />
    </NavLink>
    <Translate
      id="message.sticky-footer.already-a-user"
      defaultMessage="Already a user? {Link}"
      values={{
        Link: (
          <NavLink to={{ hash: 'sign-in' }}>
            {translate(
              { id: 'action.signin', defaultMessage: 'Sign in' },
              intl
            )}
          </NavLink>
        ),
      }}
    />
  </section>
);

@withConfig
@withCookies
@withRouteData
@withRouter
@injectIntl
@connect(state => ({
  menus: state.content.menus.data,
  isAuthenticated: state.player.isAuthenticated,
  sections: state.content.sections.data,
  jurisdiction: state.app.jurisdiction,
  footerProps: selectFooterProps(state),
}))
class GlobalNavContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'menu',
      identifiers: ['header-products-nav', 'account', 'footer-nav'],
    },
    {
      name: 'section',
      identifiers: ['global-nav-banner'],
    },
  ]);

  onClickSupport = e => {
    e.preventDefault();
    startChat(this.props.jurisdiction);
  };

  onClickItem = item => {
    const { cookies, config } = this.props;

    if (config.useVisitedCookie) {
      setLastVisitedCookie(item, cookies);
    }
  };

  render() {
    const {
      menus,
      sections,
      isAuthenticated,
      config,
      footerProps,
      intl,
      location,
    } = this.props;

    const productsNav = menus?.find(
      m => m.identifier === 'header-products-nav'
    );
    const accountNav = menus?.find(m => m.identifier === 'account');
    const footerNav = menus?.find(i => i.identifier === 'footer-nav');
    const globalNavBanner = sections?.find(
      s => s.identifier === 'global-nav-banner'
    );

    const globalNavBannerBackground = getByPath(globalNavBanner, [
      'backgroundImage',
      'file',
      'url',
    ]);

    const globalNavBannerImage = getByPath(globalNavBanner, [
      'image',
      'file',
      'url',
    ]);

    const globalNavBannerAction = globalNavBanner?.action;

    return (
      <OffCanvasLayout direction="left" className="GlobalNavContainer">
        <div className="global-nav-banner-wrapper">
          {(globalNavBannerImage || globalNavBannerBackground) && (
            <div
              className="global-nav-banner"
              style={{
                backgroundImage: `url(${globalNavBannerBackground})`,
              }}>
              {globalNavBannerAction ? (
                <Link to={globalNavBannerAction}>
                  <img src={globalNavBannerImage} alt="banner" />
                </Link>
              ) : (
                <img src={globalNavBannerImage} alt="banner" />
              )}
            </div>
          )}
        </div>

        <div className="section-wrapper">
          {!isAuthenticated && config.isAccount && (
            <AccountSection intl={intl} />
          )}
          {!isAuthenticated && !config.isAccount && (
            <Link to="/start-playing" className="button primary">
              <Translate id="action.play-now" defaultMessage="Play now" />
            </Link>
          )}

          <NavSection
            items={productsNav && productsNav.items}
            type="products"
            location={location}
            onClickItem={this.onClickItem}
          />
          {isAuthenticated && (
            <NavSection
              location={location}
              items={accountNav && accountNav.items}
              type="account"
            />
          )}
          <NavSection
            location={location}
            items={footerNav && footerNav.items}
            type="footer-nav"
          />
          <div className="support-button-wrapper">
            <NavLink
              to="/support"
              onClick={this.onClickSupport}
              className="GlobalNavContainer__button GlobalNavContainer__button--support button tertiary">
              <Icon icon="headphones" />{' '}
              <Translate id="action.contactsupport" defaultMessage="Support" />
            </NavLink>
          </div>
        </div>

        {config.showFooterInGlobalNav && <Footer {...footerProps} />}
      </OffCanvasLayout>
    );
  }
}

export default GlobalNavContainer;

import Loader from '@components/Loader';
import { useConfig } from '@utils/config';
import { getDepositMethod, getService } from '@utils/payment';
import marked from 'marked';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import PaymentForm from 'tg-core-components/lib/widgets/Cashier/Deposit/PaymentForm';

const Deposit = ({
  /* Props from parent */
  fields,
  onError,
  sessionId,
  refUrl,
  pendingOptin,

  /* Props from selector */
  sections,
  locale,
  user,
  device,
  operatingSystem,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const config = useConfig();

  useEffect(() => {
    initDeposit();
  }, []); // eslint-disable-line

  const initDeposit = () => {
    const params = queryString.stringify({ refUrl, pendingOptin });

    const paymentRequestData = {
      amount: fields.Amount,
      sessionId: sessionId,
      locale,
      userId: user.Id,
      merchantId: config.MERCHANT_ID,
      attributes: {
        successUrl:
          window.location.origin +
          '/payment-success.html?tx=${ptx.id}&amount=${ptx.amount.amount}&currency=${ptx.amount.currencyCode}&method=${ptx.method}',
        failureUrl:
          window.location.origin +
          '/payment-failure.html?tx=${ptx.id}&amount=${ptx.amount.amount}&currency=${ptx.amount.currencyCode}&state=${ptx.state}&status=${ptx.statusCode}&method=${ptx.method}',
        pendingUrl:
          window.location.origin +
          '/payment-pending.html?method=${ptx.method}&tx=${ptx.id}&amount=${ptx.amount.amount}&currency=${ptx.amount.currencyCode}',
        channelId: device ? device.substr(0, 1) : null,
        aleaccBonusCode: fields.BonusCode,
        psp: getService(config.devcode.oauth_provider),
      },
    };

    if (params) {
      paymentRequestData.attributes.successUrl += `&${params}`;
    }

    const deposit = getDepositMethod(config.devcode.oauth_provider);

    setIsLoading(true);

    deposit(paymentRequestData)
      .then(res => {
        if (!res.data.success) throw res;

        setData(res.data);
      })
      .catch(error => {
        console.log(error);
        onError(error?.data?.errors?.[0]);
      })
      .finally(() => setIsLoading(false));
  };

  const stepContent = sections?.find(
    s => s.identifier === 'header.paynplay.deposit'
  )?.content;

  return (
    <div className="Deposit">
      {stepContent && (
        <div
          dangerouslySetInnerHTML={{ __html: marked(stepContent) }}
          className="Deposit__content"
        />
      )}

      {isLoading && <Loader inline />}

      {data && (
        <PaymentForm
          data={data}
          operatingSystem={operatingSystem}
          redirect={device === 'Mobile'}
        />
      )}
    </div>
  );
};

const selector = state => ({
  sections: state.content.sections.data,
  locale: state.app.locale,
  user: state.player.user,
  device: state.app.device,
  operatingSystem: state.app.operatingSystem,
});

export default {
  name: 'deposit',
  Component: Deposit,
  selector,
};

import React, { Component } from 'react';
import OffCanvasLayout from '@components/OffCanvasLayout';
import withRouteData, { Loader } from '@utils/withRouteData';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { loadContents } from '@actions/content';
import selectUnreadNotifications from '@selectors/selectUnreadNotifications';
import { Link } from 'react-router-dom';
import marked from 'marked';
import translate from 'tg-core-components/lib/lib/utils/translate';

@injectIntl
@withRouteData
@connect(state => ({
  menus: state.content.menus.data,
  pages: state.content.pages.data,
  jurisdiction: state.app.jurisdiction,
  unreadNotifications: selectUnreadNotifications(state),
  isAuthenticated: state.player.isAuthenticated,
}))
class SettingsContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'menu',
      identifiers: ['settings-navigation'],
    },
    {
      name: 'page',
      urls: ['#dashboard/settings'],
    },
  ]);

  render() {
    const {
      jurisdiction,
      isAuthenticated,
      menus,
      intl,
      unreadNotifications,
      isRouteDataLoading,
      pages,
    } = this.props;
    const page = pages?.find(p => p.url === '#dashboard/settings');
    const navigation = menus?.find(m => m.identifier === 'settings-navigation');

    return (
      <OffCanvasLayout
        className="SettingsContainer"
        jurisdiction={jurisdiction}
        isAuthenticated={isAuthenticated}
        backUrl="#dashboard"
        title={translate(
          {
            id: 'label.settings',
            defaultMessage: 'Settings',
          },
          intl
        )}>
        <Loader isLoading={isRouteDataLoading}>
          {page?.content && (
            <header>
              <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
            </header>
          )}
          <div className="Settings">
            <div className="section profile-links">
              {navigation?.items?.map(i => (
                <Link
                  className="ItemWithIconAndText"
                  key={i.identifier}
                  to={{
                    ...(i.url.startsWith('#')
                      ? { hash: i.url }
                      : { pathname: i.url }),
                    state: {
                      backUrl: '#dashboard/settings',
                      preventTransition: true,
                    },
                  }}>
                  {i.menuIcon?.file.url && (
                    <div className="menu-icon">
                      <img
                        src={i.menuIcon.file.url}
                        alt="menu"
                        className="icon"
                      />
                    </div>
                  )}
                  <div className="text">{i.menu}</div>
                  {i.url === '#dashboard/messages' && (
                    <span
                      className="notifications-badge notifications-badge--right"
                      data-notifications={unreadNotifications.internalMessages}
                    />
                  )}
                </Link>
              ))}
            </div>
          </div>
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default SettingsContainer;

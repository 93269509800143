import React, { Component } from 'react';
import { connect } from 'react-redux';
import OffCanvasLayout from '@components/OffCanvasLayout';
import marked from 'marked';
import bonusActions from 'tg-core-redux/lib/modules/bonus/action';
import BonusWidget from 'tg-core-components/lib/widgets/BonusWidget';
import withRouteData, { Loader } from '@utils/withRouteData';
import { loadContents } from '@actions/content';
import { withConfig } from '@config';
import { withUrls } from '@utils/urls';
import { withRouter } from 'react-router';
import Alerts from '@components/Alerts';
import { removeAlerts } from '@actions/alert';

import './style.css';

@withUrls
@withConfig
@withRouteData
@withRouter
@connect(
  state => ({
    device: state.app.device,
    pages: state.content.pages.data,
    sessionId: state.player.sessionId,
    bonuses: state.bonus.data,
    bonusOffers: state.bonusOffer.data,
    promotions: state.content.promotions.data,
    alerts: state.alerts.data.filter(a => ['PRECLAIM_BONUS'].includes(a.type)),
  }),
  {
    getBonuses: bonusActions.getBonuses,
    getBonusOffers: bonusActions.getOffers,
    preclaimBonus: bonusActions.preclaimBonus,
    claimBonus: bonusActions.claimBonus,
    rejectBonus: bonusActions.rejectBonus,
  }
)
class BonusContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard/bonus'],
    },
    {
      name: 'promotion',
    },
  ]);

  componentDidMount() {
    const { getBonuses, getBonusOffers, sessionId, config } = this.props;

    Promise.all([
      getBonuses(sessionId, config.bonusProvider),
      getBonusOffers(sessionId, config.bonusProvider),
    ]);
  }

  componentWillUnmount() {
    this.props.dispatch(removeAlerts('PRECLAIM_BONUS'));
  }

  render() {
    const {
      pages,
      bonuses,
      bonusOffers,
      sessionId,
      preclaimBonus,
      claimBonus,
      rejectBonus,
      getBonuses,
      device,
      history,
      config,
      urls,
      promotions,
      alerts,
    } = this.props;

    const page = pages?.find(p => p.url === '#dashboard/bonus');

    const onRejectBonus = (bonus, isLoading, event) => {
      event.preventDefault();
      event.stopPropagation();

      if (bonus.Provider === 'fasttrack') {
        // Not supported.
      } else {
        // Reject aleacc bonus and fetch new once.
        isLoading?.(true);
        rejectBonus(sessionId, bonus.Id, bonus.Provider)
          .then(() => getBonuses(sessionId, config.bonusProvider))
          .finally(() => isLoading?.(false));
      }
    };

    const onClaimBonus = (bonus, isLoading, event) => {
      event.preventDefault();
      event.stopPropagation();

      if (bonus.Provider === 'fasttrack') {
        isLoading?.(true);
        let gameUrl = '/casino';
        if (
          device === 'Desktop' &&
          bonus.DesktopGameUrl &&
          bonus.DesktopGameUrl.trim()
        )
          gameUrl = bonus.DesktopGameUrl.trim();
        else if (
          device !== 'Desktop' &&
          bonus.MobileGameUrl &&
          bonus.MobileGameUrl.trim()
        )
          gameUrl = bonus.MobileGameUrl.trim();

        // FTType 1 - Pending Bonus
        if (bonus.FTType === 1) {
          return claimBonus(sessionId, bonus.Id, bonus.Provider)
            .then(() => history.push(gameUrl))
            .finally(() => isLoading?.(false));
        }

        // FTType 2 - Pending Deposit Bonus
        if (bonus.FTType === 2) {
          return urls.deposit({
            search: config.isPnp
              ? `?bonusCode=${bonus.PromoCode}`
              : `?ref_url=${encodeURIComponent(gameUrl)}`,
            state: config.isPnp ? null : { bonusCode: bonus.PromoCode },
          });
        }

        // FTType 3 - Locked Bonus
        if (bonus.FTType === 3) {
          if (!bonus.IsLocked) {
            claimBonus(sessionId, bonus.Id, bonus.Provider)
              .then(() => history.push(gameUrl))
              .finally(() => isLoading?.(false));
          } else {
            return urls.deposit({
              search: config.isPnp
                ? `?bonusCode=${bonus.PromoCode}`
                : `?ref_url=${encodeURIComponent(gameUrl)}`,
              state: config.isPnp ? null : { bonusCode: bonus.PromoCode },
            });
          }
        }
      } else if (bonus.Type === 'Deposit') {
        const promoCode = bonus?.RewardGroups?.find(reward => reward?.PromoCode)
          ?.PromoCode;

        return history.push(
          urls.deposit({
            search: config.isPnp ? `?bonusCode=${promoCode}` : null,
            state: config.isPnp ? null : { bonusCode: promoCode },
          })
        );
      } else {
        // Claim aleacc bonus and fetch new once.
        isLoading?.(true);
        claimBonus(sessionId, bonus.Id, bonus.Provider)
          .then(() => getBonuses(sessionId, config.bonusProvider))
          .finally(() => isLoading?.(false));
      }
    };

    const bonusesWithContent = [
      ...bonuses,
      ...bonusOffers?.map(b => ({
        ...b,
        Type: 'Deposit',
        Initiated: b?.ValidDateStart,
        Expires: b?.ValidDateEnd,
      })),
    ]
      ?.map(bonus => ({
        ...bonus,
        content: promotions?.find(
          p =>
            p.identifier ===
            (config.bonusProvider === 'fasttrack'
              ? bonus.Name
              : bonus.Identifier)
        ),
      }))
      ?.filter(bonus => {
        // Filter out post claim bonuses that are in "claim mode" and contains "wheel_". These should only be visible in pnp sign up bonuse selector and bonus wheel.
        return !(
          ['Initiated', 'PreClaimed'].includes(bonus.State) &&
          bonus.Name?.match(/^wheel_/)
        );
      });

    return (
      <OffCanvasLayout className="BonusContainer" backUrl="#dashboard">
        <Loader isLoading={!page}>
          <div className="bonuses-item-selector">
            {page?.content && (
              <header>
                <div
                  dangerouslySetInnerHTML={{ __html: marked(page.content) }}
                />
              </header>
            )}

            <Alerts
              alerts={alerts}
              removeAlerts={type => this.props.dispatch(removeAlerts(type))}
            />

            <BonusWidget
              device={device}
              bonuses={bonusesWithContent}
              handleBonusCodeSubmit={bonusCode =>
                preclaimBonus(sessionId, bonusCode, config.bonusProvider)
              }
              onClaimBonus={onClaimBonus}
              onRejectBonus={onRejectBonus}
            />
          </div>
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default BonusContainer;

export const hiddenString = string => {
  const firstLetters = string ? string.substring(0, 2) : '';
  const lastLetters = string
    ? string.substring(string.length - 1, string.length)
    : '';

  return firstLetters + '****' + lastLetters;
};

export const stringifyArrayAndRestrictLength = (limit, data) => {
  let dataToMutate = [...data];
  let stringified = JSON.stringify(dataToMutate);
  while (stringified.length >= limit) {
    dataToMutate.pop();
    stringified = JSON.stringify(dataToMutate);
  }
  return stringified;
};

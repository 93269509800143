import React from 'react';
import cn from 'classnames';

import './style.css';

const GamePlayBackground = ({ game }) => (
  <div
    className={cn('GamePlayBackground', {
      'GamePlayBackground--has-thumbnail': game.thumbnail,
      'GamePlayBackground--has-background': game.background,
    })}>
    {game.thumbnail && (
      <div
        className="GamePlayBackground__thumbnail"
        style={{ backgroundImage: `url(${game.thumbnail?.file.url})` }}
      />
    )}
    {game.background && (
      <div
        className="GamePlayBackground__background"
        style={{ backgroundImage: `url(${game.background?.file.url})` }}
      />
    )}
    <div className="GamePlayBackground__dark-mode-overlay" />
  </div>
);

export default GamePlayBackground;

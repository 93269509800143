import React from 'react';
import ExternalAuthAccount from '@components/ExternalAuthAccount';
import { transform } from '@utils/ssn';
import { getConfig } from '@config';

const ExternalAuthentication = ({
  /* Props from parent */
  fields,
  signIn,

  /* Props from selector */
  device,
  locale,
  browser,
  config,
}) => {
  const {
    oauth_clientid,
    oauth_ui_friendly,
    oauth_redirecturl,
    oauth_provider,
  } = config?.devcode;

  return (
    <div>
      <ExternalAuthAccount
        onSubmit={signIn}
        clientId={oauth_clientid}
        uiFriendly={oauth_ui_friendly}
        redirectUrl={oauth_redirecturl}
        provider={oauth_provider}
        locale={locale}
        browser={browser}
        bankIdQR="false"
        bankIdDevice={device === 'Desktop' ? 'other' : 'same'}
        ssn={fields.PersonalId ? transform(fields.PersonalId) : null}
      />
    </div>
  );
};

const selector = state => ({
  locale: state.app.locale,
  device: state.app.device,
  browser: state.app.browser,
  config: getConfig(state.app.jurisdiction),
});

export default {
  name: 'external-authentication',
  Component: ExternalAuthentication,
  selector,
};

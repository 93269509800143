import {
  Auth,
  Limits,
  Contact,
  Consent,
  Amount,
  PendingWithdrawal,
  Deposit,
  BlockFeature,
  Bonus,
} from './steps';

export const getSteps = (jurisdiction, isAuthenticated, config) => {
  if (isAuthenticated)
    return [Amount, Deposit, ...(config?.onlyShowBonusOnSignUp ? [] : [Bonus])];

  switch (jurisdiction?.toLowerCase()) {
    case 'curacao':
      return [Amount, Auth, Contact, Bonus];

    default:
      return [Amount, Auth, Limits, Contact, Bonus];
  }
};

export const getStepStatuses = (steps, fields, errors) =>
  steps
    .map(step => ({
      [step]:
        fields[step] === undefined
          ? 'idle'
          : errors[step]
          ? 'failure'
          : 'success',
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

let cache = {};

export const apiCache = endpoint => (...args) => {
  // Extract the function name from the provided endpoint.
  // Ex. "function validateEmail() {...}" -> "validateEmail".
  const endpointName = endpoint
    .toString()
    .match(/function(.*?)\(/)[1]
    .trim();

  const cacheKey = `${endpointName}_${args.join('_')}`;
  const cachedResponse = cache[cacheKey];

  if (cachedResponse) return Promise.resolve(cachedResponse);

  return endpoint(...args)
    .then(res => {
      cache = {
        ...cache,
        [cacheKey]: res,
      };

      return res;
    })
    .catch(err => {
      // Don't cache rate limited requests or network errors.
      if (
        err?.value?.response?.data?.Code !== 'RateLimited' &&
        err?.value?.response
      ) {
        cache = {
          ...cache,
          [cacheKey]: err,
        };
      }

      return err;
    });
};

// Used for SGA players since we send in placeholder limits during sign up.
export const hasPlaceholderLimits = limits =>
  limits?.some(limit => limit.Amount === 99999999 && limit.Type === 'Deposit');

export const getRequiredSteps = (
  steps,
  fields,
  data,
  communicationConsent,
  onlyShowBonusOnSignUp
) => {
  const emailUpdateRequired = data?.EmailUpdateRequired;
  const firstNameRequired = data?.FirstNameRequired;
  const depositLimitRequired = data?.DepositLimitRequired;
  const placeholderLimits = hasPlaceholderLimits(fields.Limits);
  const isSignUp = data?.IsSignup;

  steps = steps.filter(step => !['amount', 'auth'].includes(step.name));

  if (emailUpdateRequired === 'false' && firstNameRequired === 'false') {
    steps = steps.filter(step => step.name !== 'contact');
  }

  if (
    depositLimitRequired === 'false' &&
    !placeholderLimits &&
    isSignUp === 'false'
  ) {
    steps = steps.filter(step => step.name !== 'limits');
  }

  if (onlyShowBonusOnSignUp && isSignUp === 'false') {
    steps = steps.filter(step => step.name !== 'bonus');
  }

  if (communicationConsent && isSignUp === 'true') {
    steps.push(Consent);
  }

  return steps;
};

export const getNextStep = (steps, currentStep, previousStep) => {
  const currentStepIndex = steps.indexOf(currentStep);
  const previousStepIndex = steps.indexOf(previousStep);

  if (currentStepIndex === -1 && previousStepIndex > -1) {
    return steps[previousStepIndex + 1];
  }

  return steps[currentStepIndex + 1];
};

export const getInitialStep = (
  config,
  steps,
  depositAmount,
  sessionId,
  fetchBalance,
  pendingWithdrawal,
  isInline
) => {
  if (config?.block?.signIn) {
    return BlockFeature;
  }

  if (fetchBalance) {
    return Auth;
  }

  if (config?.block?.deposit) {
    return BlockFeature;
  }

  if (isInline) {
    return Amount;
  }

  if (depositAmount) {
    return sessionId ? Deposit : Auth;
  }

  if (pendingWithdrawal) {
    return PendingWithdrawal;
  }

  return steps[0];
};

export const transformAmount = amount => {
  const parsedAmount = parseInt(amount, 10);

  if (parsedAmount === 0 || isNaN(parsedAmount)) {
    return '';
  }

  return parsedAmount.toString();
};

export function showToast({ text, toastType, duration }) {
  return dispatch => {
    dispatch({ type: 'SHOW_TOAST', text, toastType, duration });
  };
}

export function hideToast() {
  return dispatch => {
    dispatch({ type: 'HIDE_TOAST' });
  };
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.css';

// test if current path match page or subpage.
const testIsActive = (match, location, itemUrl) => {
  if (match) return true;

  const parts = itemUrl.split('/');
  if (parts.length < 3) return false;

  delete parts[parts.length - 1];
  match = location.pathname.indexOf(parts.join('/'));
  return match === 0 || match === 1;
};

const testIfExternal = url => {
  return /^((http|https):\/\/)/.test(url);
};

const NavList = (
  { menu, customClickHandlers } = { customClickHandlers: {} }
) => {
  const checkForCustomClickHandler = i => e => {
    if (!customClickHandlers[i.identifier]) return;
    e.preventDefault();
    customClickHandlers[i.identifier](i, e);
  };

  return (
    <nav className="NavList">
      <ul>
        {menu &&
          menu.items.map((i, k) => (
            <li key={k}>
              {testIfExternal(i.url) ? (
                <a href={i.url} target="_blank" rel="noopener noreferrer">
                  <span>{i.menu}</span>
                </a>
              ) : (
                <NavLink
                  onClick={() => checkForCustomClickHandler(i)}
                  activeClassName="active"
                  isActive={(match, location) =>
                    testIsActive(match, location, i.url)
                  }
                  to={`/${i.url}`}>
                  <span>{i.menu}</span>
                </NavLink>
              )}
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default NavList;

import React from 'react';
import { Button, Translate } from 'tg-core-components';
import { startChat } from '@utils/chat';
import marked from 'marked';

import './style.css';

const Error = ({
  /* Props from parent */
  errors,
  onClose,

  /* Props from selector */
  sections,
  jurisdiction,
}) => {
  const stepContent = sections?.find(
    s => s.identifier === 'header.paynplay.error'
  )?.content;

  return (
    <div className="Error">
      {stepContent && (
        <div
          dangerouslySetInnerHTML={{ __html: marked(stepContent) }}
          className="Error__content"
        />
      )}

      {errors.Error && (
        <Translate tagName="p" id={`label.paynplay.error.${errors.Error}`} />
      )}

      <Button
        className="button primary"
        onClick={e => {
          e.preventDefault();
          startChat(jurisdiction);
        }}>
        <Translate
          id="action.contactsupport"
          defaultMessage="Contact support"
        />
      </Button>

      <a href="#" onClick={onClose} className="close-button">
        <Translate id="action.close" defaultMessage="Close" />
      </a>
    </div>
  );
};

const selector = state => ({
  sections: state.content.sections.data,
  jurisdiction: state.app.jurisdiction,
});

export default {
  name: 'error',
  Component: Error,
  selector,
};

import { getConfig } from '@config';

export default (jurisdiction, ipCountry) => {
  const chat = getConfig(jurisdiction, ipCountry).chat;

  switch (chat.provider) {
    case 'ladesk':
      return { src: '/skin/ladesk.js' };
    case 'livechat':
      return { src: '/skin/livechat.js' };
    case 'zendesk':
      return {
        src: chat.host,
        id: 'ze-snippet',
      };
    default:
      return { src: 'https://wchat.eu.freshchat.com/js/widget.js' };
  }
};

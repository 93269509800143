import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import { useInnerDimensions } from '@utils/hooks';
import { useUrls } from '@utils/urls';

const SpringBuilder = ({
  user,
  locale,
  token,
  config,
  match,
  isAuthenticated,
  sessionId,
  oddsFormat: oddsType,
  history,
  getWallet,
  getWallets,
  location,
}) => {
  const iframeRef = useRef(null);
  const { depositString, signInString } = useUrls();
  const [innerWidth, innerHeight] = useInnerDimensions();
  const userId = user?.Id;

  const refUrl = __CLIENT__
    ? encodeURI(location.pathname + location.search + location.hash)
    : null;

  const signOut = () => postMessage({ action: 'logout' });
  const signIn = () =>
    postMessage({
      action: 'login',
      credentials: { playerId: userId, auth_token: token },
    });

  useEffect(() => {
    if (!userId) signOut();
  }, [userId]);

  const postMessage = data =>
    iframeRef.current.contentWindow.postMessage(data, '*');

  const receiveMessage = (event, data) => {
    if (!event) return;

    switch (event) {
      case 'loginRestored':
        if (!isAuthenticated && !sessionId) {
          signOut();
        }
        break;
      case 'loggedOut':
        if (isAuthenticated) {
          signIn();
        }
        break;
      case 'openSlider':
        if (data?.tab === 'login') {
          history.push(
            signInString({ search: `?ref_url=${encodeURIComponent(refUrl)}` })
          );
        }
        break;
      case 'openPage':
        if (data.page === 'deposit' && isAuthenticated) {
          history.push(
            depositString({ search: `?ref_url=${encodeURIComponent(refUrl)}` })
          );
        }
        break;
      case 'userBalanceUpdated':
        if (sessionId) {
          debouncedWallets();
        }
    }
  };

  // Debouncing wallets since springbuilder sends multiple 'userBalanceUpdated' on init.
  const debouncedWallets = debounce(() => {
    getWallet(sessionId);
    getWallets(sessionId);
  }, 2000);

  useEffect(() => {
    const message = e => receiveMessage(e.data.info || e.data.action, e.data);

    window.addEventListener('message', message);
    return () => {
      signOut();
      window.removeEventListener('message', message);
    };
  }, [isAuthenticated, sessionId, token]);

  const getLang = () => {
    const lang = locale.split('-')[0];
    switch (lang) {
      case 'nb':
        return '/no';
      case 'en':
        return '';
      default:
        return `/${lang}`;
    }
  };

  const getPath = () => (match.url.includes('live-betting') ? '' : '/prematch');

  let url = `${config.springBuilder.host}${getLang()}${getPath()}`;

  if (match.params.sport) {
    url +=
      '/match/' +
      match.url
        .split('/')
        .slice(2)
        .join('/');
  }

  const query = {
    odds_type: oddsType.toLowerCase(),
  };

  if (userId && token && isAuthenticated) {
    query.user_id = userId;
    query.auth_token = token;
  }

  url += '?' + queryString.stringify(query);

  return (
    <div
      className="SpringBuilder"
      style={{
        '--inner-width': `${innerWidth}px`,
        '--inner-height': `${innerHeight}px`,
      }}>
      <iframe src={url} ref={iframeRef}></iframe>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,minimal-ui"
        />
      </Helmet>
    </div>
  );
};

export default SpringBuilder;

import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import marked from 'marked';
import { Button, Translate } from 'tg-core-components';

import './style.css';

const FAQWidget = ({ identifier, config, items }) => {
  const expandedByDefault = config?.expanded;
  const disableScroll = config?.disableScroll;

  const [header, ...faqItems] = items;
  const [expandedKey, setExpandedKey] = useState(expandedByDefault ? 0 : null);

  useEffect(() => setExpandedKey(null), [items]);

  const clickHandler = (k, e) => {
    const href = `${identifier}-question-${k}`;
    const target = document.getElementById(href);
    const prevHref = `${identifier}-question-${expandedKey}`;
    const prevtarget = document.getElementById(prevHref);

    scrollToTarget(target, prevtarget, k);
  };

  const duration = 300;

  const scrollToTarget = (target, prevtarget, k) => {
    const curTarget = target.getBoundingClientRect();
    const prevTarget = prevtarget?.getBoundingClientRect();
    const startPos = window.pageYOffset;

    let diff;
    let startTime = null;
    let requestId;

    expandedKey != null &&
    k !== 0 &&
    expandedKey < k &&
    curTarget.top != prevTarget.top
      ? (diff = curTarget.top - prevTarget.height - 30)
      : (diff = curTarget.top - curTarget.height - 30);

    const loop = currentTime => {
      if (!startTime) {
        startTime = currentTime;
      }

      const time = currentTime - startTime;
      const percent = Math.min(time / duration, 1);
      window.scrollTo(0, startPos + diff * percent);

      if (time < duration) {
        requestId = window.requestAnimationFrame(loop);
      } else {
        window.cancelAnimationFrame(requestId);
      }
    };
    requestId = window.requestAnimationFrame(loop);
  };

  return (
    <div className="FAQWidget" itemScope itemType="https://schema.org/FAQPage">
      <div
        className="FAQWidget__header"
        dangerouslySetInnerHTML={{ __html: marked(header.content || '') }}
      />
      {faqItems.length &&
        faqItems.map((i, k) => {
          const [question, ...answer] = i.content.trim().split('\n');

          if (!question || !answer) return null;

          return (
            <div
              id={`${identifier}-question-${k}`}
              className={cn('FAQWidget__item', {
                'FAQWidget__item--expanded': k === expandedKey,
              })}
              key={k}
              itemScope
              itemProp="mainEntity"
              itemType="https://schema.org/Question">
              {disableScroll ? (
                <div
                  className="FAQWidget__item__question"
                  onClick={() => {
                    setExpandedKey(k === expandedKey ? null : k);
                  }}
                  dangerouslySetInnerHTML={{ __html: marked(question || '') }}
                  itemProp="name"
                />
              ) : (
                <div
                  className="FAQWidget__item__question"
                  onClick={() => {
                    setExpandedKey(k === expandedKey ? null : k),
                      clickHandler(k);
                  }}
                  dangerouslySetInnerHTML={{ __html: marked(question || '') }}
                  itemProp="name"
                />
              )}

              <div
                className="FAQWidget__item__answer"
                itemScope
                itemProp="acceptedAnswer"
                itemType="https://schema.org/Answer">
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(answer.join('\n').trim()),
                  }}
                  itemProp="text"
                />
                <div className="FAQWidget__item__scroll-button">
                  <Button
                    className="button primary"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: 'smooth' })
                    }>
                    <Translate
                      id="action.button.scroll-top"
                      defaultMessage="Scroll to top"
                    />
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default FAQWidget;

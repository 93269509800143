import React from 'react';
import { Helmet } from 'react-helmet';

import './styles.css';

const AppDashboard = () => {
  return (
    <Helmet>
      <body className="from-app" />
    </Helmet>
  );
};

export default AppDashboard;

import React, { Fragment } from 'react';
import marked from 'marked';
import getByPath from 'lodash/get';
import { Link, NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import Game, { selectGameProps } from '@components/Game';
import Banners, { selectBannersProps } from '@components/Banners';
import FAQWidget from '@components/FAQWidget';
import Features from '@components/Features';
import Carousel from '@components/Carousel';
import TextSlider from '@components/TextSlider';
import IconSlider from '@components/IconSlider';
import Slider from '@components/Slider';
import GameGroupTitle from '@components/GameGroupTitle';
import RecentWins, { selectRecentWinProps } from '@components/RecentWins';
import PayNPlay from '@components/PayNPlay';
import CasinoPromotion from '@components/CasinoPromotion';
import GameGrid, { selectGameGridProps } from '@components/GameGrid';
import CasinoGrid, { selectCasinoGridProps } from '@components/CasinoGrid';
import Accordion from '@components/Accordion';
import TopListNumber from '@components/TopListNumber';
import selectFavoriteGames from '@selectors/selectFavoriteGames';
import selectTopGames from '@selectors/selectTopGames';
import replaceToken from '@utils/replaceToken';
import Translate from 'tg-core-components/lib/components/Translate';
import {
  getGamesByCategory,
  getCategoryType,
  CATEGORY_TYPES,
} from '@utils/casino';
import selectCountry from '@selectors/selectCountry';

import './style.css';

const recursiveRender = (item, props) => {
  const Component = components(getByPath(item, 'config.type'), item);
  if (!Component) return null;

  return <Component {...props} {...item} key={item.identifier} />;
};

/**
 * Get's the classes that should be added to the specific page builder component
 *
 * @param {string} baseClass Component base class ("Set, Section, GameGrid")
 * @param {object} config Config object from CMS
 * @param {string} identifier Identifier field from CMS
 * @param {string} className Additional classes (legacy)
 * @returns {string} String of classes
 */
const getClasses = (baseClass, config, identifier, className) => {
  return cn(
    baseClass,
    config?.identifier || identifier,
    config?.className,
    className
  );
};

const components = (type = '', item) => {
  if (item.backendId) return GameAdapter;
  switch (type.toLowerCase()) {
    case 'banners':
      return BannersAdapter;
    case 'game':
      return GameAdapter;
    case 'faq':
      return FAQWidget;
    case 'accordion':
      return AccordionAdapter;
    case 'game-count':
      return GameCount;
    case 'jackpot':
      return Jackpot;
    case 'jackpot-game':
      return JackpotGame;
    case 'grid':
      return GridAdapter;
    case 'features':
      return FeaturesAdapter;
    case 'game-row':
      return GameRowAdapter;
    case 'game-grid':
      return GameGridAdapter;
    case 'promotion':
      return PromotionAdapter;
    case 'set':
      return SetComponent;
    case 'menu':
      return MenuComponent;
    case 'image':
      return Image;
    case 'recent-wins':
      return RecentWinsAdapter;
    case 'inline-pnp':
      return PayNPlayAdapter;
    case 'casino-slider':
      return CasinoSliderAdapter;
    case 'casino-category-slider':
      return CategorySliderAdapter;
    case 'casino-grid':
      return CasinoGridAdapter;
    case 'casino-category-grid':
      return CategoryGridAdapter;
    default:
      return Section;
  }
};

const GameAdapter = ({ games = [], ...rest }) => {
  const gameProps = useSelector(selectGameProps);
  return (
    <Game
      {...rest}
      game={games.find(g => g.identifier === rest.identifier)}
      {...gameProps}
    />
  );
};

const BannersAdapter = set => {
  const bannerProps = useSelector(selectBannersProps);
  return <Banners banners={set} {...bannerProps} />;
};

// eslint-disable-next-line
const GridAdapter = ({ items, identifier, config, _sys, ...rest }) => (
  <Grid identifier={identifier} config={config} {...rest}>
    {items.map(item => recursiveRender(item, rest))}
  </Grid>
);

const Grid = ({ identifier = '', children, config, className = '' }) => (
  <div className={getClasses('Grid', config, identifier, className)}>
    <div className="container">{children}</div>
  </div>
);

const AccordionAdapter = ({ items, ...rest }) => {
  const data = items.map(item => {
    return {
      header: (
        <Translate id={`accordion.title.${item.identifier}`} tagName="h3" />
      ),
      content: recursiveRender(item, rest),
    };
  });

  return <Accordion {...rest} items={data} />;
};

const Image = ({ identifier = '', config, image, action }) => {
  if (!image) return null;

  return action ? (
    <Link className={getClasses('Image', config, identifier)} to={`/${action}`}>
      <img src={image.file?.url} alt={image.description || image.title} />
    </Link>
  ) : (
    <img
      className={getClasses('Image', config, identifier)}
      src={image.file?.url}
      alt={image.description || image.title}
    />
  );
};

const FormattedSection = ({ content, values, ...rest }) => {
  content = replaceToken(content, values);

  return <Section content={content} {...rest} />;
};

const Section = ({
  identifier = '',
  content,
  image,
  cta,
  action,
  children,
  config,
  video,
}) => (
  <div
    className={getClasses('Section', config, identifier)}
    style={{
      backgroundImage: image && `url(${getByPath(image, 'file.url')})`,
    }}>
    {video?.file && (
      <video
        className="Section__video"
        muted
        loop
        autoPlay
        playsInline
        poster={image?.file?.url}>
        <source src={video.file.url} type={video.file.contentType} />
      </video>
    )}
    {content && (
      <div
        className="container"
        dangerouslySetInnerHTML={{ __html: marked(content) }}
      />
    )}
    {cta && action && (
      <div className="Section__cta">
        {action.startsWith('mailto:') ? (
          <a
            href={action}
            className={cn('button', getByPath(config, 'button', 'primary'))}>
            {cta}
          </a>
        ) : (
          <Link
            to={action}
            className={cn('button', getByPath(config, 'button', 'primary'))}>
            {cta}
          </Link>
        )}
      </div>
    )}
    {children}
  </div>
);

const SetComponent = ({ identifier = '', items, config, ...rest }) => (
  <div className={getClasses('Set', config, identifier)}>
    {items.map(item => recursiveRender(item, rest))}
  </div>
);

const MenuComponent = ({ identifier = '', config, items, ...rest }) => (
  <div className={getClasses('Menu', config, identifier)}>
    {items.map(
      item => (
        <NavLink
          className="navigation"
          key={item.identifier}
          exact={item.url === 'index'}
          activeClassName="active"
          to={item.url === 'index' ? '/' : `/${item.url}`}>
          <span>{item.menu}</span>
        </NavLink>
      ),
      rest
    )}
  </div>
);

const GameCount = ({ games, ...rest }) => {
  const values = {
    count: games?.length,
  };

  return <FormattedSection values={values} {...rest} />;
};

const Jackpot = ({ totalJackpot, currency, intl, ...rest }) => {
  const jackpotValue = intl.formatNumber(totalJackpot, {
    style: 'currency',
    currency,
  });

  const values = {
    jackpot: jackpotValue !== 'null' ? jackpotValue : '...',
  };

  return <FormattedSection values={values} {...rest} />;
};

const JackpotGame = ({ games = [], intl, ...rest }) => {
  const { backendId = [] } = rest.config;

  const game = games.find(g =>
    backendId.length
      ? backendId.includes(g.backendId)
      : backendId === g.backendId
  );
  if (!game) return null;

  const jackpotValue = intl.formatNumber(game.jackpot?.Amount, {
    style: 'currency',
    currency: game.jackpot?.Currency,
  });

  const values = {
    jackpot: jackpotValue !== 'undefined' ? jackpotValue : '...',
    name: game.name ? game.name : '',
  };

  return <FormattedSection values={values} {...rest} />;
};

const FeaturesAdapter = ({ device, config, items }) => {
  const { mobile, desktop, interval } = config?.settings;

  const featuresType = device.toLowerCase() === 'mobile' ? mobile : desktop;

  switch (featuresType) {
    case 'row':
      return <Features features={{ items }} />;
    case 'slider':
      return <Carousel interval={interval} features={{ items }} />;
    case 'iconSlider':
      return <IconSlider interval={interval} features={{ items }} />;
    default:
      return <TextSlider interval={interval} features={{ items }} />;
  }
};

const GameRowAdapter = ({ items, games, ...rest }) => {
  if (!items) return null;

  return (
    <div className="GameRow">
      {items.map((i, k) => {
        if (i.action) return <Section {...rest} {...i} />;
        return <GameRow key={k} {...rest} {...i} games={games} />;
      })}
    </div>
  );
};

const GameRow = ({ identifier, name, icon, games, config }) => {
  const gameProps = useSelector(selectGameProps);
  const items = getGamesByCategory(
    games,
    identifier,
    null,
    null,
    config,
    gameProps.topGames
  ).slice(0, config?.gamesDisplayed || 6);

  return (
    items?.length > 0 && (
      <Fragment>
        <div className="GameRow__title">
          <div className="header-and-icon">
            {icon && <img src={icon.file.url} alt="icon" loading="lazy" />}
          </div>
          {name}
        </div>
        <Slider classNames="GameRow__items" showScrollNav={false}>
          {items.map((g, i) => (
            <div className="game-wrapper" key={i}>
              <Game game={g} {...gameProps} />
              {config.type === CATEGORY_TYPES.LIST && (
                <TopListNumber number={++i} />
              )}
            </div>
          ))}
        </Slider>
      </Fragment>
    )
  );
};

const CasinoSliderAdapter = ({ ...rest }) => {
  return <CasinoGamesSlider {...rest} />;
};

const CategorySliderAdapter = ({ ...rest }) => {
  return <CasinoGamesSlider {...rest} isCategory />;
};

const CasinoGamesSlider = ({
  games,
  lastPlayedGames,
  items,
  identifier,
  icon,
  type,
  isCategory,
  toggleFavoriteGameById,
}) => {
  const country = useSelector(selectCountry);
  const casinoSliderProps = useSelector(selectGameGridProps);
  const favoriteGames = useSelector(selectFavoriteGames);
  const topGames = useSelector(selectTopGames);
  return (
    <Fragment>
      <GameGrid
        key={identifier}
        items={
          items ||
          getGamesByCategory(
            games,
            identifier,
            lastPlayedGames,
            favoriteGames,
            null,
            topGames
          )
        }
        isCategory={isCategory}
        overview
        baseUrl={'/casino'}
        categoryId={identifier}
        type={getCategoryType(type)}
        toggleFavoriteGameById={toggleFavoriteGameById}
        title={
          <GameGroupTitle
            country={country}
            identifier={identifier}
            action={!isCategory && `/casino/${identifier}`}
            icon={icon}
          />
        }
        {...casinoSliderProps}
      />
    </Fragment>
  );
};

const CasinoGridAdapter = ({ ...rest }) => {
  return <CasinoGamesGrid {...rest} />;
};

const CategoryGridAdapter = ({ ...rest }) => {
  return <CasinoGamesGrid {...rest} isCategory />;
};

const CasinoGamesGrid = ({
  games,
  lastPlayedGames,
  items,
  identifier,
  icon,
  type,
  isCategory,
  toggleFavoriteGameById,
}) => {
  const country = useSelector(selectCountry);
  const casinoGridProps = useSelector(selectCasinoGridProps);
  const favoriteGames = useSelector(selectFavoriteGames);
  const topGames = useSelector(selectTopGames);
  return (
    <Fragment>
      <CasinoGrid
        key={identifier}
        items={
          items ||
          getGamesByCategory(
            games,
            identifier,
            lastPlayedGames,
            favoriteGames,
            null,
            topGames
          )
        }
        isCategory={isCategory}
        type={getCategoryType(type)}
        toggleFavoriteGameById={toggleFavoriteGameById}
        title={
          <GameGroupTitle
            country={country}
            identifier={identifier}
            action={!isCategory && `/casino/${identifier}`}
            icon={icon}
          />
        }
        {...casinoGridProps}
      />
    </Fragment>
  );
};

const GameGridAdapter = ({ identifier, items, config, games, ...rest }) => {
  if (!items) return null;

  return (
    <div className={getClasses('GameGrid', config, identifier)}>
      {items.map((i, k) => {
        if (i.action)
          return (
            <Section config={config} identifier={identifier} {...rest} {...i} />
          );
        return (
          <SimpleGameGrid
            key={k}
            config={config}
            {...rest}
            {...i}
            games={games}
          />
        );
      })}
    </div>
  );
};

const SimpleGameGrid = ({ identifier, icon, games, type, config }) => {
  const country = useSelector(selectCountry);
  const gameProps = useSelector(selectGameProps);
  const items = getGamesByCategory(
    games,
    identifier,
    gameProps.lastPlayedGames,
    gameProps.favoriteGames,
    config,
    gameProps.topGames
  ).slice(0, config?.gamesDisplayed || 6);

  return (
    <Fragment>
      <div className="GameGrid__title">
        <GameGroupTitle
          country={country}
          identifier={identifier}
          action={`/casino/${identifier}`}
          icon={icon}
        />
      </div>
      <div className="GameGrid__items" showScrollNav={false}>
        {items.map((g, i) => (
          <Game
            className={cn({
              'Game--large': !config?.disableLargeThumbnail && i === 0,
            })}
            key={i}
            game={g}
            type={getCategoryType(type)}
            {...gameProps}
          />
        ))}
      </div>
    </Fragment>
  );
};

const RecentWinsAdapter = ({ config, ...rest }) => {
  const { limit = 4, threshold, gameId } = config;

  const recentWinProps = useSelector(selectRecentWinProps);

  return (
    <RecentWins
      limit={limit}
      threshold={threshold}
      gameId={gameId}
      {...recentWinProps}
      {...rest}
    />
  );
};

const PromotionAdapter = ({
  identifier,
  config,
  content,
  action,
  image,
  video,
}) => {
  const promotion = { config, content, action, image, video };

  return (
    <div className={getClasses('Promotion', config, identifier)}>
      <CasinoPromotion promotion={promotion} />
    </div>
  );
};

const PayNPlayAdapter = () => <PayNPlay isInline={true} />;

const PageBuilder = injectIntl(({ items, ...rest }) => (
  <div className="PageBuilder">
    {items.map(item => recursiveRender(item, rest))}
  </div>
));

export default PageBuilder;

import React, { Component } from 'react';
import AccountWidget from 'tg-core-components/lib/widgets/AccountDetail';
import { connect } from 'react-redux';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import marked from 'marked';
import OffCanvasLayout from '@components/OffCanvasLayout';
import withRouteData, { Loader } from '@utils/withRouteData';
import { loadContents } from '@actions/content';
import { withRouter, Redirect } from 'react-router';

import './style.css';

@withRouter
@withRouteData
@connect(state => ({
  user: state.player.user,
  sessionId: state.player.sessionId,
  jurisdiction: state.app.jurisdiction,
  contentful_config: state.content.config.data,
  pages: state.content.pages.data,
  player: state.player,
}))
class EditProfile extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      urls: ['#dashboard/edit-profile'],
    },
  ]);

  state = {
    isLoading: false,
    formData: null,
  };

  replaceUnkownGender = data => {
    if (data?.Gender === 'Unknown') {
      return {
        ...data,
        Gender: '',
      };
    }

    return data;
  };

  handleUpdateProfile = updatedInfo => {
    const { sessionId, dispatch, player, history } = this.props;

    const formData = this.mapFormData(updatedInfo);

    this.setState({ isLoading: true, formData });
    dispatch(playerActions.update(formData, sessionId)).then(res => {
      if (!player.isAuthenticated && res?.type !== 'PLAYER_UPDATE_FAILURE') {
        history.push('/casino');
        dispatch({ type: 'REQUEST_UPDATE_PLAYER_INFO_COMPLETE' });
      }

      this.setState({ isLoading: false });
    });
  };

  mapFormData = data => {
    const { user } = this.props;

    const formData = {
      BirthDate: user.BirthDate,
      Email: user.Email,
      NickName: user.NickName,
      FirstName: user.FirstName,
      LastName: user.LastName,
      Region: user.Region,
      Country: typeof data.Country === 'string' ? data.Country : user.Country,
      PhoneNumber: user.PhoneNumber,
      Language: user.Language,
      Gender: typeof data.Gender === 'string' ? data.Gender : user.Gender,
      Address2:
        typeof data.Address2 === 'string' ? data.Address2 : user.Address2,
      Address1:
        typeof data.Address1 === 'string' ? data.Address1 : user.Address1,
      City: typeof data.City === 'string' ? data.City : user.City,
      Zip: typeof data.Zip === 'string' ? data.Zip : user.Zip,
      MobilePhoneNumber:
        typeof data.MobilePhoneNumber === 'string'
          ? data.MobilePhoneNumber
          : user.MobilePhoneNumber,
      Password: data.Password,
    };

    return this.replaceUnkownGender(formData);
  };

  render() {
    const { pages, user, jurisdiction, contentful_config, player } = this.props;

    const page = pages?.find(p => p.url === '#dashboard/edit-profile');
    const countries = contentful_config?.find(c => c.key === 'countries');

    const data = this.state.formData || user;

    if (!user) {
      return <Redirect to="/casino#sign-in" />;
    }

    return (
      <OffCanvasLayout
        className="EditProfile"
        backUrl={player.isAuthenticated ? '#dashboard' : null}
        preventClose={!player.isAuthenticated}>
        <Loader isLoading={!page}>
          {page?.content && (
            <header>
              <div dangerouslySetInnerHTML={{ __html: marked(page.content) }} />
            </header>
          )}
          {user && (
            <AccountWidget
              data={this.replaceUnkownGender(data)}
              jurisdiction={jurisdiction}
              showCallingCode={!user.MobilePhoneNumber}
              countries={countries?.value || []}
              onSubmitForm={this.handleUpdateProfile}
              onFormError={data => {
                const formData = this.mapFormData(data);
                this.setState({ formData });
              }}
              isLoading={this.state.isLoading}
              isComplete={user?.IsComplete}
            />
          )}
        </Loader>
      </OffCanvasLayout>
    );
  }
}

export default EditProfile;

import React, { Fragment } from 'react';
import Icon from 'tg-core-components/lib/components/Icon';
import { withCookies } from 'react-cookie';
import getByPath from 'lodash/get';
import { Translate } from 'tg-core-components';

import './style.css';

const BonusBar = ({ promoCode, promotions, cookies, onClick }) => {
  const bonuses = promotions && promotions.filter(i => i.bonusCode);

  if (!(bonuses && bonuses.length > 0)) return null;

  const bonusCode =
    promoCode ||
    cookies.get('bonusCodeDeposit') ||
    cookies.get('bonusCodeSignUp');

  const selectedBonus = bonuses && bonuses.find(b => b.bonusCode === bonusCode);
  const bonusImage = getByPath(selectedBonus, 'image.file.url');

  return (
    <div onClick={onClick} className="bonus-footer">
      {bonusImage && (
        <div
          className="bonus-background"
          style={{ backgroundImage: `url('${bonusImage}')` }}
        />
      )}
      <div className="content-wrapper">
        <div className="content">
          <Icon icon="promotions" />
          {!bonusCode ? (
            <Fragment>
              <div className="title no-bonus">
                <Translate
                  id="label.choose-bonus"
                  defaultMessage="Choose your bonus"
                />
              </div>
              <Translate
                id="label.available-bonuses"
                defaultMessage="({count} available)"
                values={{
                  count: bonuses && bonuses.length,
                }}
              />
            </Fragment>
          ) : (
            <div className="selected-bonus">
              <Translate
                id="label.your-bonus"
                defaultMessage="Your selected bonus:"
              />
              <div className="title">
                {selectedBonus ? selectedBonus.title : bonusCode}
              </div>
            </div>
          )}
        </div>
        <div className="right">
          {bonusCode && (
            <Translate id="label.change-bonus" defaultMessage="Change" />
          )}
          <Icon icon="chevron-right" />
        </div>
      </div>
    </div>
  );
};

export default withCookies(BonusBar);

import React, { useState } from 'react';
import Attach from '@components/Attach';
import { Translate, Button } from 'tg-core-components';
import Alert from '@components/Alert';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import kycActions from 'tg-core-redux/lib/modules/kyc/action';

const Verify = ({
  /* Props from parent */
  nextStep,

  /* Props from selector*/
  sessionId,
}) => {
  const [errorCode, setErrorCode] = useState(0);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const dispatch = useDispatch();

  // Move this to the parent container, like how we handle ativation issues,
  // failure action is not dispatched... why?!
  const onSubmit = ({ file, setLoading }) => {
    const reader = new FileReader();
    setErrorCode(0);

    try {
      reader.onload = e => {
        const { result } = e.target;
        const tag = ';base64,';

        dispatch(
          kycActions.upload(sessionId, {
            Type: 'ID',
            Image: result.substring(result.indexOf(tag) + tag.length),
          })
        )
          .then(() => {
            setUploadSuccessful(true);
            setLoading(false);
          })
          .catch(e => {
            setErrorCode(e.response.status);
            setLoading(false);
          });
      };

      reader.readAsDataURL(file);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      {errorCode >= 400 && (
        <Alert
          id="alert_message.danger.upload-file-type"
          defaultMessage="Unsupported file type"
          level="danger"
          scrollIntoView={false}
        />
      )}

      {errorCode >= 500 && (
        <Alert
          id="alert_message.danger.upload-file-size"
          defaultMessage="File size exceeded"
          level="danger"
          scrollIntoView={false}
        />
      )}

      <Attach
        uniqueIDPrefix="Verify_ID"
        onClick={onSubmit}
        extraInfo={
          <Translate
            id="message.validate-id-info"
            defaultMessage="Valid ID: ID card, Passport, Driving license"
          />
        }
      />

      <Button
        onClick={nextStep}
        className={cn('continue primary', {
          'continue--valid': uploadSuccessful,
        })}>
        <Translate id="action.button.continue" defaultMessage="Continue" />
      </Button>
    </div>
  );
};

const selector = state => ({
  sessionId: state.player.sessionId,
});

export default {
  name: 'verify',
  Component: Verify,
  selector,
};

import React from 'react';
import queryString from 'query-string';

import './style.css';

class ExternalAuth extends React.PureComponent {
  constructor(props) {
    super(props);
    window.addEventListener('message', this.onMessage);
  }

  getHost() {
    if (typeof global !== 'undefined' && global.paymentIQOAuthHost)
      return global.paymentIQOAuthHost;
    if (typeof window !== 'undefined' && window.paymentIQOAuthHost)
      return window.paymentIQOAuthHost;
    return 'https://test-api.paymentiq.io/paymentiq/oauth';
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
  }

  onMessage = message => {
    if (message.origin === 'https://td.yieldify.com') return;

    const { onSubmit, redirectUrl: RedirectUrl, browser } = this.props;
    let data = {};

    try {
      data =
        typeof message.data === 'string'
          ? JSON.parse(message.data)
          : message.data;
    } catch (e) {
      // Ignore
    }

    switch (data.name || data.action) {
      case 'gii::location_href': {
        if (data.href.match(/^bankid:/)) {
          let href = data.href;
          if (browser === 'Mobile Safari')
            href = href.replace(
              /redirect=null/,
              'redirect=' + encodeURIComponent(window.location.href + '#')
            );

          window.open(href, '_self');
        }
        break;
      }
      case 'externalauth':
        onSubmit({
          ...data,
          RedirectUrl,
        });
        break;
    }
  };

  render() {
    const {
      provider,
      clientId,
      redirectUrl,
      ssn,
      uiFriendly,
      locale,
      bankIdDevice,
      bankIdQR,
      ...rest
    } = this.props;

    let query = {
      'nemid-start': 'card',
      display: 'popup',
      identity_provider: provider,
      client_id: clientId,
      redirect_uri: redirectUrl,
      ssn: ssn,
    };

    if (uiFriendly) query.ui_friendly = uiFriendly;

    if (bankIdDevice)
      query['provider_attributes[bankid-se-device]'] = bankIdDevice;

    if (bankIdQR) query['provider_attributes[bankid-se-qr]'] = bankIdQR;
    if (locale) query['provider_attributes[ui_locales]'] = locale.split('-')[0];

    return (
      <div className="ExternalAuth">
        <iframe
          {...rest}
          src={`${this.getHost()}/authorize?${queryString.stringify(query)}`}
          data-hj-allow-iframe
        />
      </div>
    );
  }
}

export default ExternalAuth;

import React from 'react';
import { Link } from 'react-router-dom';
import { PAGES, identifierToPage } from '../index';

import './style.css';

const Navigation = ({ items, setPage, currentPage }) => {
  const onClick = (e, page) => {
    e.preventDefault();
    setPage(page);
  };

  const isActive = item => {
    if (
      currentPage === PAGES.QUESTION ||
      currentPage === PAGES.WRONG ||
      currentPage === PAGES.CORRECT
    ) {
      currentPage = PAGES.CALENDAR;
    }

    return item && item.identifier === PAGES[identifierToPage(currentPage)];
  };

  return (
    <nav className="Navigation">
      {items &&
        items.map(i => (
          <Link
            to={i.url}
            onClick={e => onClick(e, PAGES[identifierToPage(i.identifier)])}
            className={isActive(i) ? 'active' : undefined}
            key={i.identifier}>
            {i.menu}
          </Link>
        ))}
    </nav>
  );
};

export default Navigation;

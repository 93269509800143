import React from 'react';
import { Translate, Button } from 'tg-core-components';

import './style.css';

const Welcome = ({ onClose }) => {
  return (
    <div className="Welcome">
      <Button onClick={onClose} className="primary">
        <Translate id="action.button.lets-go" defaultMessage="Let's go!" />
      </Button>
    </div>
  );
};

export default {
  name: 'welcome',
  Component: Welcome,
};

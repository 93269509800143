import React from 'react';
import './loading.css';

const SwishLoader = () => {
  return (
    <div className="swish-loader">
      <div className="loader-dot" />
      <div className="dot-1" />
      <div className="dot-2" />
      <div className="dot-3" />
    </div>
  );
};

export default SwishLoader;

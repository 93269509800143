import React from 'react';
import marked from 'marked';
import './style.css';
import { Button, Translate } from 'tg-core-components';

const Panic = ({ topContent, bottomContent, onPanic, onContinue, disable }) => {
  return (
    <div className="Panic">
      {topContent?.content && (
        <div
          dangerouslySetInnerHTML={{
            __html: marked(topContent.content),
          }}
        />
      )}

      <div className="panic-actions">
        <Button
          disabled={disable}
          className="button primary"
          onClick={e => {
            e.preventDefault();
            onPanic();
          }}>
          <Translate id="action.panic.break" defaultMessage="Take a break" />
        </Button>

        <Button
          className="button secondary"
          onClick={e => {
            e.preventDefault();
            onContinue();
          }}>
          <Translate
            id="action.panic.continue"
            defaultMessage="Continue play"
          />
        </Button>
      </div>

      {bottomContent?.content && (
        <div
          dangerouslySetInnerHTML={{
            __html: marked(bottomContent.content),
          }}
        />
      )}
    </div>
  );
};

export default Panic;

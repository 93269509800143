import React from 'react';
import RouterContext from './RouterContext';

const Router = ({ children, location }) => {
  const [matches, setMatches] = React.useState([]);

  return (
    <RouterContext.Provider value={{ location, matches, setMatches }}>
      {children}
    </RouterContext.Provider>
  );
};

export default Router;

import React from 'react';
import marked from 'marked';
import Popup from '@components/Popup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import './style.css';

const MultipleLocalesContainer = ({
  countries = [],
  languages,
  ipCountry,
  location,
  sections,
}) => {
  const country = countries.find(c => c.value == ipCountry);

  if (!country?.locales) return null;

  const items = languages?.filter(l => country?.locales.includes(l.code)) || [];
  if (items.length == 0) return null;

  const section = sections?.find(
    i => i.identifier === 'popup-multiple-locales'
  );
  const [title, ...content] = section?.content?.split('\n') || [];

  return (
    <Popup
      title={
        title && <div dangerouslySetInnerHTML={{ __html: marked(title) }} />
      }>
      <div className="MultipleLocalesContainer">
        {content && (
          <div
            dangerouslySetInnerHTML={{ __html: marked(content.join('\n')) }}
          />
        )}

        <div className="flags">
          {items.map(language => {
            const localeUrl = language?.alias?.[0] || language.code;

            return (
              <a href={`/${localeUrl}${location.pathname}`} key={language.name}>
                <img src={language.icon.file.url} alt="Country flag" />
                <span>{language.name}</span>
              </a>
            );
          })}
        </div>
      </div>
    </Popup>
  );
};

export default connect(state => ({
  ipCountry: state.app.ipCountry,
  languages: state.content.languages.data,
  sections: state.content.sections.data,
  countries:
    state.content.config.data?.find(c => c.key == 'countries')?.value || [],
}))(withRouter(MultipleLocalesContainer));

import React from 'react';

export const BTC = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <path
      fill="#f7931a"
      d="M63.04 39.741c-4.275 17.143-21.638 27.576-38.783 23.301C7.12 58.768-3.313 41.404.962 24.262 5.234 7.117 22.597-3.317 39.737.957c17.144 4.274 27.576 21.64 23.302 38.784z"
    />
    <path
      fill="#FFF"
      d="M46.11 27.441c.636-4.258-2.606-6.547-7.039-8.074l1.438-5.768-3.51-.875-1.4 5.616c-.924-.23-1.872-.447-2.814-.662l1.41-5.653-3.509-.875-1.439 5.766c-.764-.174-1.514-.346-2.242-.527l.004-.018-4.842-1.209-.934 3.75s2.605.597 2.55.634c1.422.355 1.68 1.296 1.636 2.042l-1.638 6.571c.098.025.225.061.365.117l-.37-.092-2.297 9.205c-.174.432-.615 1.08-1.609.834.035.051-2.552-.637-2.552-.637l-1.743 4.019 4.57 1.139c.85.213 1.682.436 2.502.646l-1.453 5.834 3.507.875 1.44-5.772c.957.26 1.887.5 2.797.726l-1.434 5.745 3.511.875 1.453-5.823c5.987 1.133 10.49.676 12.384-4.739 1.527-4.36-.076-6.875-3.226-8.515 2.294-.529 4.022-2.038 4.483-5.155zM38.086 38.69c-1.085 4.36-8.426 2.003-10.806 1.412l1.928-7.729c2.38.594 10.012 1.77 8.878 6.317zm1.086-11.312c-.99 3.966-7.1 1.951-9.082 1.457l1.748-7.01c1.982.494 8.365 1.416 7.334 5.553z"
    />
  </svg>
);
export const XRP = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 424"
    xmlSpace="preserve"
    {...props}>
    <path
      d="M437 0h74L357 152.5c-55.8 55.2-146.2 55.2-202 0L.9 0H75l117 115.8c35.5 35 92.4 35 127.9 0L437 0zM74.1 424H0l155-153.4c55.8-55.2 146.2-55.2 202 0L512 424h-74L320 307.2c-35.5-35-92.4-35-127.9 0L74.1 424z"
      style={{
        fill: '#fff',
      }}
    />
  </svg>
);
export const LTC = props => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 82.6 82.6"
    {...props}>
    <circle cx={41.3} cy={41.3} r={36.83} fill="#fff" />
    <path
      d="M41.3 0a41.3 41.3 0 1 0 41.3 41.3A41.18 41.18 0 0 0 41.54 0Zm.7 42.7-4.3 14.5h23a1.16 1.16 0 0 1 1.2 1.12v.38l-2 6.9a1.49 1.49 0 0 1-1.5 1.1H23.2l5.9-20.1-6.6 2L24 44l6.6-2 8.3-28.2a1.51 1.51 0 0 1 1.5-1.1h8.9a1.16 1.16 0 0 1 1.2 1.12v.38l-7 23.8 6.6-2-1.4 4.8Z"
      fill="#345d9d"
    />
  </svg>
);
export const ETH = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 784.37 1277.39"
    {...props}>
    <g fillRule="nonzero">
      <path
        fill="#343434"
        d="m392.07 0-8.57 29.11v844.63l8.57 8.55 392.06-231.75z"
      />
      <path fill="#8C8C8C" d="M392.07 0 0 650.54l392.07 231.75V472.33z" />
      <path
        fill="#3C3C3B"
        d="m392.07 956.52-4.83 5.89v300.87l4.83 14.1 392.3-552.49z"
      />
      <path fill="#8C8C8C" d="M392.07 1277.38V956.52L0 724.89z" />
      <path fill="#141414" d="m392.07 882.29 392.06-231.75-392.06-178.21z" />
      <path fill="#393939" d="m0 650.54 392.07 231.75V472.33z" />
    </g>
  </svg>
);

export const BCH = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 788 788"
    xmlSpace="preserve"
    {...props}>
    <circle cx={394} cy={394} r={394} fill="#0ac18e" />
    <path
      d="M516.9 261.7c-19.8-44.9-65.3-54.5-121-45.2L378 147.1 335.8 158l17.6 69.2c-11.1 2.8-22.5 5.2-33.8 8.4L302 166.8l-42.2 10.9 17.9 69.4c-9.1 2.6-85.2 22.1-85.2 22.1l11.6 45.2s31-8.7 30.7-8c17.2-4.5 25.3 4.1 29.1 12.2l49.2 190.2c.6 5.5-.4 14.9-12.2 18.1.7.4-30.7 7.9-30.7 7.9l4.6 52.7s75.4-19.3 85.3-21.8l18.1 70.2 42.2-10.9-18.1-70.7c11.6-2.7 22.9-5.5 33.9-8.4l18 70.3 42.2-10.9-18.1-70.1c65-15.8 110.9-56.8 101.5-119.5-6-37.8-47.3-68.8-81.6-72.3 21.1-18.7 31.8-46 18.7-81.7zm-20.3 165.5c8.4 62.1-77.9 69.7-106.4 77.2l-24.8-92.9c28.6-7.5 117-39 131.2 15.7zm-52-126.5c8.9 55.2-64.9 61.6-88.7 67.7l-22.6-84.3c23.9-5.9 93.2-34.5 111.3 16.6z"
      fill="#fff"
    />
  </svg>
);

export default {
  BTC,
  XRP,
  LTC,
  ETH,
  BCH,
};

const initialState = {
  isLoading: false,
  status: null,
  error: null,
  data: null,
  info: {},
  confirms: [],
  session: null,
  realityCheckData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LIMIT_INCREASE_INFO_START':
      return {
        ...state,
        status: null,
        isLoading: true,
      };
    case 'LIMIT_INCREASE_INFO_COMPLETE':
      return {
        ...state,
        isLoading: false,
        status: 'success',
        info: action.payload,
      };
    case 'LIMIT_INCREASE_INFO_FAILURE':
      return {
        ...state,
        isLoading: false,
        status: null,
        error: action.error,
      };
    case 'GET_LIMIT_START':
      return {
        ...state,
        status: null,
        isLoading: true,
      };
    case 'GET_LIMIT_COMPLETE':
      return {
        ...state,
        isLoading: false,
        status: 'success',
        data: action.payload,
      };
    case 'GET_LIMIT_FAILURE':
      return {
        ...state,
        isLoading: false,
        status: null,
        error: action.error,
      };
    case 'GET_REALITYCHECK_START':
    case 'GET_REALITYCHECK_SESSION_START':
      return {
        ...state,
        status: null,
        isLoading: true,
      };
    case 'GET_REALITYCHECK_COMPLETE':
      return {
        ...state,
        isLoading: false,
        status: 'success',
        realityCheckData: action.payload,
      };
    case 'GET_REALITYCHECK_SESSION_COMPLETE':
      return {
        ...state,
        isLoading: false,
        status: 'success',
        session: action.payload,
      };
    case 'GET_REALITYCHECK_FAILURE':
    case 'GET_REALITYCHECK_SESSION_FAILURE':
      return {
        ...state,
        isLoading: false,
        status: null,
        error: action.error,
      };
    case 'SET_PGSI_START':
    case 'SET_LIMIT_START':
    case 'SET_MULTIPLE_LIMITS_START':
      return {
        ...state,
        isLoading: true,
      };

    case 'SET_PGSI_COMPLETE':
    case 'SET_LIMIT_COMPLETE':
    case 'SET_MULTIPLE_LIMITS_COMPLETE':
      return {
        ...state,
        isLoading: false,
      };
    case 'SET_PGSI_FAILURE':
    case 'SET_LIMIT_FAILURE':
    case 'SET_MULTIPLE_LIMITS_FAILURE':
      return {
        ...state,
        isLoading: false,
      };
    case 'RG_TO_CONFIRM_START':
      return {
        ...state,
        isLoading: true,
      };
    case 'RG_TO_CONFIRM_COMPLETE':
      return {
        ...state,
        confirms: action.payload,
        isLoading: false,
      };
    case 'RG_TO_CONFIRM_FAILURE':
      return {
        ...state,
        isLoading: false,
      };
    case 'GET_LIMIT_WITH_TOKEN_COMPLETE':
      return {
        ...state,
        data: action.payload,
      };
    case 'GET_PRODUCT_BLOCKS_START':
      return {
        ...state,
        status: null,
        isLoading: true,
      };
    case 'GET_PRODUCT_BLOCKS_COMPLETE':
      return {
        ...state,
        isLoading: false,
        status: 'success',
        productBlocks: action.payload,
      };
    case 'GET_PRODUCT_BLOCKS_FAILURE':
      return {
        ...state,
        isLoading: false,
        status: null,
        error: action.error,
      };
    default:
      return state;
  }
};

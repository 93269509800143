import React, { useState } from 'react';
import { Button, Icon, Input, Translate } from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { useIntl } from 'react-intl';

import './style.css';

const BonusCodeInput = ({ onChange, fields }) => {
  const intl = useIntl();

  const [showBonusCodeInput, setShowBonusCodeInput] = useState(
    Boolean(fields.PromoCode)
  );

  const isValid = fields.PromoCode?.length > 0;

  return (
    <div className="BonusCodeInput">
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          setShowBonusCodeInput(true);
        }}>
        <Translate
          id="action.button.promo_code"
          defaultMessage="Do you have a bonus code?"
        />
      </a>

      {showBonusCodeInput && (
        <div className="BonusCodeInput__group">
          <Input
            label={translate({ id: 'label.bonus_code' }, intl)}
            value={fields.PromoCode}
            onChange={e => onChange({ PromoCode: e.target.value })}
            status={isValid ? 'success' : 'idle'}
          />

          <Button
            className="button icon primary"
            onClick={() => {
              onChange({ PromoCode: null });
              setShowBonusCodeInput(false);
            }}>
            <Icon icon="close" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default BonusCodeInput;

import React, { Component } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

const withLoader = BaseComponent => {
  class WithLoader extends Component {
    componentDidMount() {
      if (this.props.isLoading) {
        this._handleShowLoading();
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (!this.props.isLoading && nextProps.isLoading) {
        this._handleShowLoading();
      } else if (this.props.isLoading && !nextProps.isLoading) {
        this._handleHideLoading();
      }
    }

    render() {
      return <BaseComponent {...this.props} />;
    }

    _handleShowLoading = () => {
      const event = new Event('showLoading');
      document.dispatchEvent(event);
    };

    _handleHideLoading = () => {
      const event = new Event('hideLoading');
      document.dispatchEvent(event);
    };
  }

  return hoistNonReactStatic(WithLoader, BaseComponent);
};
export default withLoader;

import React, { useState } from 'react';
import { Translate } from 'tg-core-components';
import Paperclip from '../../images/paperclip.svg';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Button, Picture } from 'tg-core-components';

import './style.css';

const Attach = ({ onClick, uniqueIDPrefix = 'default', extraInfo }) => {
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);

  // Makes sure we cant trigger click event multiple times before ajax have completed. See onClick logic. We shouldnt rely on just props state because of setState async nature.
  let _loading = loading;

  return (
    <div className="Attach">
      <input
        className="attach-input"
        id={`${uniqueIDPrefix}_attach`}
        type="file"
        onChange={({ currentTarget: e }) => {
          if (e.files[0]) setFile(e.files[0]);
        }}
      />
      {file && file.name && (
        <div className="Attach__file-name">
          <Translate
            tagName="b"
            id="message.attach.file-name"
            defaultMessage="file name: "
          />
          {file.name}
        </div>
      )}
      <div className="buttons">
        <div className="segment">
          <Button
            className="button tertiary wide"
            for={true}
            htmlFor={`${uniqueIDPrefix}_attach`}>
            <Translate id="action.attach.file-browse" defaultMessage="browse" />
          </Button>
        </div>
        <div className="segment">
          <Button
            type="button"
            className={cn('button primary wide', {
              hidden: !file,
            })}
            onClick={() => {
              if (_loading) return;
              onClick({ file, setLoading });
              setLoading(true);
              _loading = true;
            }}
            isLoading={loading}>
            <Translate
              id="action.attach.file-attach"
              defaultMessage="Attach to account"
            />
          </Button>
        </div>
      </div>

      {
        <div className="Attach__meta-info">
          <div>
            <Translate id="label.upload_document_description" />
          </div>
          <Picture
            src={Paperclip}
            className="paperclip"
            width={14}
            height={27}
          />
          {extraInfo}
        </div>
      }
    </div>
  );
};

Attach.propTypes = {
  onClick: PropTypes.func.isRequired,
  uniqueIDPrefix: PropTypes.string,
  loading: PropTypes.bool,
};

export default Attach;

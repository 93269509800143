import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouteData from '@utils/withRouteData';
import marked from 'marked';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import BasePage from '@pages/BasePage';
import { withConfig } from '@config';
import { Translate } from 'tg-core-components';
import translate from 'tg-core-components/lib/lib/utils/translate';
import PageBuilder from '@components/PageBuilder';

import './style.css';

@withRouteData
@withConfig
@injectIntl
@connect(state => ({
  promotions: state.content.promotions.data,
  isAuthenticated: state.player.isAuthenticated,
}))
class PromotionDetailContainer extends Component {
  render() {
    const {
      promotions,
      match,
      config: { isAccount },
      isAuthenticated,
      intl,
    } = this.props;

    const promotion =
      promotions && promotions.find(p => p.url === match.params.promotion);

    if (!promotion) return <BasePage page={null} />;

    const {
      banner,
      content,
      action,
      cta,
      title,
      termsAndConditions,
      accountAction,
      pnpAction,
      bannerContent,
    } = promotion;

    const promoSections = promotion?.sections || null;

    return (
      <BasePage page={promotion} className="PromotionDetailContainer">
        <div className="container">
          <div className="promotion-banner">
            {bannerContent && (
              <div
                className="banner-content"
                dangerouslySetInnerHTML={{ __html: marked(bannerContent) }}
              />
            )}

            {!bannerContent && (
              <div className="banner-content">
                {title && <div className="title">{title}</div>}

                {((cta && action) || (pnpAction && accountAction)) && (
                  <Link
                    to={action ? action : isAccount ? accountAction : pnpAction}
                    className="button primary">
                    {cta
                      ? cta
                      : translate(
                          {
                            id: `cta.${isAccount ? 'account' : 'pnp'}.${
                              isAuthenticated ? 'logged-in' : 'logged-out'
                            }`,
                            defaultMessage: 'Start playing',
                          },
                          intl
                        )}
                  </Link>
                )}
              </div>
            )}

            {banner && banner.file.url && (
              <div
                style={{
                  backgroundImage: `url("${banner.file.url}")`,
                }}
                className="banner-image"
              />
            )}
          </div>

          <div className="promotion-content">
            {content && (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: marked(content) }}
              />
            )}

            {promoSections && <PageBuilder items={promoSections} />}

            {termsAndConditions && (
              <div className="terms-and-conditions">
                <div className="terms-title">
                  <Translate
                    id="label.terms_and_conditions"
                    defaultMessage="Terms and conditions"
                  />
                </div>

                <div
                  className="terms-content"
                  dangerouslySetInnerHTML={{
                    __html: marked(termsAndConditions),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </BasePage>
    );
  }
}

export default PromotionDetailContainer;

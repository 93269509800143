import React from 'react';
import { Translate } from 'tg-core-components';
import marked from 'marked';

import './style.css';

const Error = ({ error }) => {
  return (
    <main>
      <div className="error-background">
        {error ? (
          <div
            className="error-content"
            dangerouslySetInnerHTML={{ __html: marked(error.content || '') }}
          />
        ) : (
          <>
            <Translate
              tagName="h1"
              id="label.notfound"
              defaultMessage="404 - Page not found"
            />
            <div className="error-content">
              <Translate
                id="message.notfound"
                defaultMessage="This page could not be found."
              />
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default Error;

import React from 'react';
import { AcceptUpdatedTaC } from 'tg-core-components';
import withRouteData, { Loader } from '@utils/withRouteData';
import { updatePlayerTac } from 'tg-core-redux/lib/modules/player/action';
import compose from 'recompose/compose';
import { loadContents } from '@actions/content';
import Popup from '@components/Popup';
import { selectTac } from '@utils/tac';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Alerts from '@components/Alerts';
import { removeAlerts } from '@actions/alert';

const AcceptTacContainer = ({ isRouteDataLoading, history }) => {
  const dispatch = useDispatch();

  const currentTac = useSelector(selectTac);
  const sessionId = useSelector(state => state.player.sessionId);
  const alerts = useSelector(state =>
    state.alerts.data.filter(a => ['UPDATE_PLAYER_TAC'].includes(a.type))
  );

  const termsContent = currentTac?.content?.items?.reduce(
    (acc, item) => acc + (item.content + '\n' || ''),
    ''
  );

  const onAcceptUpdatedTac = async () => {
    return dispatch(updatePlayerTac(currentTac.version, sessionId)).then(
      action => {
        if (!action?.type?.includes('_FAILURE')) {
          history.push({ hash: '', search: history.location.search });
        }
      }
    );
  };

  return (
    <Popup className="AcceptTacContainer" preventClose>
      <Loader isLoading={isRouteDataLoading}>
        <Alerts
          alerts={alerts}
          removeAlerts={type => dispatch(removeAlerts(type))}
        />

        <AcceptUpdatedTaC
          current={currentTac.version}
          termsContent={termsContent}
          onAccept={onAcceptUpdatedTac}
        />
      </Loader>
    </Popup>
  );
};

AcceptTacContainer.hashRoute = true;
AcceptTacContainer.globalLoader = false;
AcceptTacContainer.dataLoader = loadContents([
  {
    name: 'set',
    identifiers: ['terms-conditions'],
  },
]);

export default compose(withRouteData, withRouter)(AcceptTacContainer);

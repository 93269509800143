import { combineEpics, ofType } from 'redux-observable';
import { showToast } from '@actions/toast';
import { getIntl } from '@utils/translations';
import { createAlertId } from '@components/Alert';
import { map, tap, ignoreElements, filter, delay } from 'rxjs/operators';

const open = (action$, state$) =>
  action$.pipe(
    ofType('QUICK_DEPOSIT_OPEN'),
    filter(
      () =>
        state$.value.quickDeposit.isInitialized &&
        window.TGQuickDepositSportClient &&
        !window.TGQuickDepositSportClient.isOpen
    ),
    tap(action => window.TGQuickDepositSportClient.open(action.payload)),
    ignoreElements()
  );

const reloadDataAfterDeposit = action$ =>
  action$.pipe(
    ofType('PAYMENT_SUCCESS', 'QUICK_DEPOSIT_SUCCESS'),
    delay(5000),
    tap(() => window.TGQuickDeposit && window.TGQuickDeposit.loadData()),
    ignoreElements()
  );

const showToastOnSuccess = (action$, state$) =>
  action$.pipe(
    ofType('QUICK_DEPOSIT_SUCCESS'),
    filter(() => state$.value.app.device !== 'Mobile'),
    tap(() => (window.location.hash = '')),
    map(() =>
      showToast({
        text: getIntl(state$.value).formatMessage({
          id: 'quick-deposit.success',
          defaultMessage: 'Your deposit was successful!',
        }),
        toastType: 'SUCCESS',
      })
    )
  );

const showToastOnFailure = (action$, state$) =>
  action$.pipe(
    ofType('QUICK_DEPOSIT_FAILURE'),
    filter(() => state$.value.app.device !== 'Mobile'),
    tap(() => (window.location.hash = '')),
    map(action => {
      const { errors } = action.payload;

      const alertIds = errors && errors.map(e => createAlertId(e, 'danger'));

      return showToast({
        text: getIntl(state$.value).formatMessage({
          id:
            action.payload.statusCode === 'ERR_RESPONSIBLE_GAMING_LIMIT'
              ? 'quick-deposit.error_responsible_gaming_limit'
              : (alertIds && alertIds[0]) || 'quick-deposit.failure',
          defaultMessage: 'An error occured, try again or contact support.',
        }),
        toastType: 'ERROR',
      });
    })
  );

const showToastOnCancel = (action$, state$) =>
  action$.pipe(
    ofType('QUICK_DEPOSIT_CANCEL'),
    filter(() => state$.value.app.device !== 'Mobile'),
    tap(() => (window.location.hash = '')),
    map(() =>
      showToast({
        text: getIntl(state$.value).formatMessage({
          id: 'quick-deposit.cancel',
          defaultMessage: 'Your payment has been canceled',
        }),
        toastType: 'ERROR',
      })
    )
  );

export default combineEpics(
  open,
  reloadDataAfterDeposit,
  showToastOnSuccess,
  showToastOnFailure,
  showToastOnCancel
);

import Cookies from 'universal-cookie';

/**
 * @param {String} prop
 * @param {Request} req Request object. Required if you use it on the server.
 * @returns The whole settings object if no prop is passed. If prop is passed it returns only the value tied to that prop.
 */
export const getContentPreviewSettings = (prop, externalCookies) => {
  const cookies = new Cookies();

  const contentPreviewCookie =
    externalCookies && externalCookies.content_preview_settings
      ? JSON.parse(externalCookies.content_preview_settings)
      : cookies.get('content_preview_settings');

  if (!contentPreviewCookie) return false;

  return prop ? contentPreviewCookie[prop] : contentPreviewCookie;
};

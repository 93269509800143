import React from 'react';
import marked from 'marked';
import { navigateToUrlIfPresent } from '@utils/navigation';
import cn from 'classnames';

import './style.css';

const Features = ({ features }) => {
  return (
    <div className="Features">
      {features &&
        features.items &&
        features.items.map((f, i) => (
          <div
            key={i}
            className={cn('feature', { link: f.action })}
            onClick={() => navigateToUrlIfPresent(f.action)}>
            {f.image?.file?.url && (
              <div className="feature-image">
                <img
                  src={f.image.file?.url}
                  alt={f.image.description || f.image.title}
                />
              </div>
            )}

            {f.content && (
              <div
                className="feature-content"
                dangerouslySetInnerHTML={{ __html: marked(f.content) }}
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default Features;

import React from 'react';
import marked from 'marked';

// import './style.css';

const CalendarHeader = ({ content, backgroundImage }) => {
  const style = {};

  if (backgroundImage?.file?.url)
    style.backgroundImage = `url('${backgroundImage.file.url}')`;

  return (
    <div className="CalendarHeader" style={style}>
      <div dangerouslySetInnerHTML={{ __html: marked(content || '') }} />
    </div>
  );
};

export default CalendarHeader;

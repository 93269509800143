import React from 'react';
import { Money, Translate } from 'tg-core-components';

import './WinLoss.css';

const WinLoss = ({ NetBalance, TotalDeposit, TotalWithdraw, Currency }) => {
  return (
    <div className="WinLossContainer">
      <div className="win-loss-section net-balance">
        <div className="win-loss-header">
          <Translate id="label.net-balance" defaultMessage="Net balance" />
        </div>
        <div
          className={`win-loss-amount${NetBalance >= 0 ? ' is-positive' : ''}`}>
          {NetBalance && <Money value={NetBalance} currency={Currency} />}
        </div>
      </div>
      <div className="win-loss-section total-deposit">
        <div className="win-loss-header">
          <Translate id="label.total-deposit" defaultMessage="Total deposit" />
        </div>
        <div className="win-loss-amount">
          {TotalDeposit && <Money value={TotalDeposit} currency={Currency} />}
        </div>
      </div>
      <div className="win-loss-section total-withdraw">
        <div className="win-loss-header">
          <Translate
            id="label.total-withdraw"
            defaultMessage="Total withdraw"
          />
        </div>
        <div className="win-loss-amount">
          {TotalWithdraw && <Money value={TotalWithdraw} currency={Currency} />}
        </div>
      </div>
    </div>
  );
};

export default WinLoss;

import { getConfig } from '@config';

const setLastVisitedCookie = (item, cookies) => {
  cookies.set(getConfig().visitedCookieName, item.url, {
    domain: getConfig().cookieDomain(),
    secure: getConfig().CURRENT_ENV === 'prod',
    path: '/',
    expires: new Date(Date.now() + getConfig().visitedCookieMaxAge),
  });
};

export default setLastVisitedCookie;

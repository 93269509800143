import React from 'react';
import marked from 'marked';

import './style.css';

const SignUpBlocked = ({
  /* Props from selector */
  sets,
}) => {
  const content = sets?.data
    ?.find(set => set.identifier === 'sign-up-items')
    ?.items?.find(s => s.identifier === 'block-feature-sign-up')?.content;

  return (
    <div className="SignUpBlocked">
      <div
        className="SignUpBlocked__content"
        dangerouslySetInnerHTML={{
          __html: marked(content || ''),
        }}
      />
    </div>
  );
};

const selector = state => ({
  sets: state.content.sets,
});

export default {
  name: 'SignUpBlocked',
  selector,
  Component: SignUpBlocked,
};

export const shouldClientRedirect = (route, location) => {
  const req = {
    path: location.pathname,
    query: location.search,
  };

  return (
    (new RegExp(route.source).test(location.pathname) ||
      new RegExp(route.source).test(location.hash)) &&
    location.pathname !== route.target(req) &&
    location.hash !== route.target(req)
  );
};

export const shouldServerRedirect = (route, req) =>
  new RegExp(route.source).test(req.originalUrl) &&
  req.originalUrl !== route.target(req);

import React from 'react';

import ResponsibleGamingLogos, {
  selectresponsibleGamingLogosProps,
} from '@components/ResponsibleGamingLogos';
import SessionClock, {
  selectSessionClockProps,
} from '@components/SessionClock';

import './style.css';

export const selectResponsibleGamingWrapperProps = state => ({
  responsibleGamingLogosProps: selectresponsibleGamingLogosProps(state),
  sessionClockProps: selectSessionClockProps(state),
});

const ResponsibleGamingWrapper = ({
  responsibleGamingLogosProps,
  sessionClockProps,
}) => {
  return (
    <div className="rg-wrapper">
      <ResponsibleGamingLogos {...responsibleGamingLogosProps} />
      <SessionClock {...sessionClockProps} />
    </div>
  );
};

export default ResponsibleGamingWrapper;

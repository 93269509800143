import React, { Fragment, useState, useEffect } from 'react';
import marked from 'marked';
import cn from 'classnames';

import './style.css';

const IconSlider = ({ features, interval }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    const delay = setInterval(
      updateActiveItemIndex,
      interval.value.time || 3000
    );

    return () => clearInterval(delay);
  });

  const updateActiveItemIndex = () => {
    const numberOfItems =
      features && features.items ? features.items.length : 0;

    setActiveItemIndex(
      activeItemIndex < numberOfItems - 1 ? activeItemIndex + 1 : 0
    );
  };

  return (
    <div className="IconSlider">
      <div className="feature-icons">
        {features &&
          features.items &&
          features.items.map((f, i) => (
            <Fragment key={i}>
              {f.image && f.image.file.url && (
                <img
                  className={cn('feature-image', {
                    active: i === activeItemIndex,
                  })}
                  src={f.image.file.url}
                  alt={f.image.description || f.image.title}
                />
              )}
            </Fragment>
          ))}
      </div>

      {features &&
        features.items &&
        features.items[activeItemIndex] &&
        features.items[activeItemIndex].content && (
          <div
            className="feature-content"
            dangerouslySetInnerHTML={{
              __html: marked(features.items[activeItemIndex].content),
            }}
          />
        )}
    </div>
  );
};

export default IconSlider;

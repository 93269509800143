import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SignIn, Translate } from 'tg-core-components';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import OffCanvasLayout from '@components/OffCanvasLayout';
import Alert, { createAlertId, createAlertValues } from '@components/Alert';
import SgaPlayNow from '@components/SgaPlayNow';
import { injectIntl } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';
import marked from 'marked';
import { Link } from 'react-router-dom';
import { pushGtmEvent } from '@actions/app';
import { withConfig } from '@config';
import { loadContents } from '@actions/content';
import withRouteData, { Loader } from '@utils/withRouteData';
import getByPath from 'lodash/get';
import { GTM_EVENTS } from '@utils/google-tag-manager';
import { getGameSegment } from '../../routes';

import '@styles/AcceptUpdatedTaC.css';
import './style.css';
import Alerts from '@components/Alerts';
import withAlertHandling from '@components/hoc/withAlertHandling';
import BlockComponent from '@components/BlockComponent';

@withConfig
@withRouteData
@connect(
  state => ({
    isLoading: state.player.isLoading,
    pages: state.content.pages.data,
    sections: state.content.sections.data,
    sessionId: state.player.sessionId,
    jurisdiction: state.app.jurisdiction,
    operatingSystem: state.app.operatingSystem,
    device: state.app.device,
    browser: state.app.browser,
    isAuthenticated: state.player.isAuthenticated,
    sidebar: state.sidebar,
  }),

  dispatch => ({
    signIn: bindActionCreators(playerActions.signInV2, dispatch),
    signInWithCode: bindActionCreators(playerActions.signInWithCode, dispatch),
    pushGtmEvent: bindActionCreators(pushGtmEvent, dispatch),
  })
)
@withAlertHandling([
  'RESET_PASSWORD',
  'label.sign-in-to-place-bet',
  'session_expired',
])
class SignInContainer extends Component {
  static hashRoute = true;
  static globalLoader = false;
  static dataLoader = loadContents([
    {
      name: 'page',
      identifiers: ['sign-in'],
    },
    {
      name: 'game',
      limit: 1000,
      segment: getGameSegment,
      order: '-fields.sortOrder',
    },
    {
      name: 'game',
      limit: 1000,
      skip: 1000,
      segment: getGameSegment,
      order: '-fields.sortOrder',
    },
  ]);

  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();

    this.state = {
      error: false,
      isLoading: false,
      offCanvasTitle: 'offcanvas.title.play-now.1',
      email: __CLIENT__ && localStorage.getItem('email'),
      shouldRememberEmail: __CLIENT__ && localStorage.getItem('remember-email'),
    };
  }

  onSubmit = async data => {
    const {
      signIn,
      signInWithCode,
      operatingSystem: OperatingSystem,
      browser: Browser,
      device: Platform,
      config: { recaptcha },
    } = this.props;

    const refUrl = getByPath(window, 'routerHistory.location.pathname');

    const CaptchaToken = recaptcha?.sitekey
      ? await this?.recaptchaRef?.current?.executeAsync()
      : null;

    let request = {
      ...data,
      Platform,
      OperatingSystem,
      Browser,
      CaptchaToken,
    };

    if (refUrl.includes('play')) {
      request = { ...request, refUrl };
    }

    this.setState({ isLoading: true });

    const promise = data.Code ? signInWithCode(request) : signIn(request);

    return promise.then(action => {
      if (action.type && action.type.includes('_FAILURE')) {
        this?.recaptchaRef?.current?.reset();

        this.setState({
          error: action,
          isLoading: false,
        });
      } else {
        this.setState({
          error: false,
          isLoading: false,
        });

        if (this.state.shouldRememberEmail) {
          __CLIENT__ && localStorage.setItem('email', this.state.email);
          __CLIENT__ && localStorage.setItem('remember-email', true);
        } else {
          __CLIENT__ && localStorage.removeItem('email');
          __CLIENT__ && localStorage.removeItem('remember-email');
        }
      }

      return action;
    });
  };

  handleChangeEmail = e => this.setState({ email: e.target.value });

  handleToggleRememberEmail = e => {
    this.setState({ shouldRememberEmail: e.target.checked });
  };

  render() {
    const {
      pages,
      intl,
      className,
      jurisdiction,
      alerts,
      pushGtmEvent,
      config,
      sections,
    } = this.props;

    const { email, shouldRememberEmail, isLoading } = this.state;
    const { recaptcha } = config;

    const page = pages && pages.find(p => p.url === 'sign-in');

    if (config?.block?.signIn) {
      const signInBlockedContent = sections?.find(
        s => s.identifier === 'block-feature-sign-in'
      )?.content;
      return <BlockComponent content={signInBlockedContent} />;
    }

    return (
      <OffCanvasLayout
        className={`SignInContainer ${className ? className : ''}`}>
        <Loader isLoading={!page?.content}>
          <div className="sign-in-wrapper">
            {page?.content && (
              <header
                dangerouslySetInnerHTML={{ __html: marked(page.content) }}
              />
            )}

            <Alerts alerts={alerts} />

            {this.state.error && (
              <Alert
                icon="exclamation-triangle"
                id={createAlertId(this.state.error, 'danger')}
                values={createAlertValues(this.state.error)}
                level="danger"
              />
            )}

            {jurisdiction === 'sga' ? (
              <SgaPlayNow
                intl={intl}
                onChangeStep={step =>
                  this.setState({
                    offCanvasTitle: `offcanvas.title.play-now.${step}`,
                  })
                }
              />
            ) : (
              <>
                {recaptcha?.sitekey && (
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    size="invisible"
                    sitekey={recaptcha.sitekey}
                  />
                )}
                <SignInWidget
                  onSubmit={this.onSubmit}
                  isLoading={isLoading}
                  sections={sections}
                  email={email}
                  handleChangeEmail={this.handleChangeEmail}
                  shouldRememberEmail={shouldRememberEmail}
                  handleToggleRememberEmail={this.handleToggleRememberEmail}
                  pushGtmEvent={pushGtmEvent}
                  showSignUpNow={config.showSignUpNow}
                />
              </>
            )}
          </div>
        </Loader>
      </OffCanvasLayout>
    );
  }
}

class SignInWidget extends Component {
  componentDidMount = () => {
    const { pushGtmEvent } = this.props;

    pushGtmEvent({ event: GTM_EVENTS.SIGNIN_INIT });
    pushGtmEvent({ event: GTM_EVENTS.SIGNIN_STEP_EMAIL });
  };

  render() {
    const {
      onSubmit,
      email,
      isLoading,
      handleChangeEmail,
      shouldRememberEmail,
      handleToggleRememberEmail,
      showSignUpNow,
    } = this.props;

    return (
      <>
        <SignIn
          onSubmitForm={onSubmit}
          email={email}
          isLoading={isLoading}
          handleChangeEmail={handleChangeEmail}
          shouldRememberEmail={shouldRememberEmail}
          handleToggleRememberEmail={handleToggleRememberEmail}
        />

        {showSignUpNow ? (
          <div className="sign-up-now">
            <Translate
              id="message.no-account"
              defaultMessage="Don't have any account, "
            />
            <Link to={{ hash: 'sign-up', state: { preventTransition: true } }}>
              <Translate
                id="action.signup_here"
                defaultMessage="sign up here"
              />
            </Link>
          </div>
        ) : (
          <div className="button-wrapper">
            <Link
              to={{ hash: 'sign-up', state: { preventTransition: true } }}
              className="button tertiary wide">
              <Translate id="action.signup" />
            </Link>
          </div>
        )}

        <div className="forgot-password">
          <Link
            className="forgot-password-link"
            to={{
              hash: 'reset-password',
              state: {
                preventTransition: true,
                email,
              },
            }}>
            <Translate id="label.forgot_password" />
          </Link>
        </div>
      </>
    );
  }
}
export default injectIntl(SignInContainer);

import React from 'react';
import RouterContext from './RouterContext';

const Route = props => (
  <RouterContext.Consumer>
    {context => {
      if (!context.location) {
        console.error('Missing locaion in context');
        return null;
      }

      return (
        props.children ||
        React.createElement(props.component, {
          overlayLocation: context.location,
        })
      );
    }}
  </RouterContext.Consumer>
);

export default Route;

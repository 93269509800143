import { useEffect, useState, useMemo } from 'react';
import { openHash, closeHash } from '@utils/hash';

export const CONFIRM_STATUS = {
  NOT_CONFIRMED: 'NOT_CONFIRMED',
  CONFIRMED: 'CONFIRMED',
};

/**
 * Wrapper hook for confirm popup
 */
const useConfirmPopup = ({ content, confirm, decline, location, history }) => {
  const [status, setStatus] = useState(CONFIRM_STATUS.NOT_CONFIRMED);
  const key = useMemo(() => Math.random(), []);

  /**
   * Listen for actions performed in the confirm popup
   */
  const payload = location.state?.action?.payload;
  useEffect(() => {
    if (payload?.key !== key) return;
    if (payload?.didConfirm) {
      confirm?.action?.(payload);
      setStatus(CONFIRM_STATUS.CONFIRMED);
    }
    if (payload?.didDecline) {
      decline?.action?.(payload);
    }
  }, [confirm, decline, key, payload]);

  const openLocation = {
    ...history.location,
    hash: openHash(history.location.hash, '#popup/confirm'),
    state: {
      ...history.location.state,
      contentId: content?.id,
      values: content?.values,
      ctaIdConfirm: confirm?.cta,
      ctaIdDecline: decline?.cta,
      action: null,
      payload: { key },
    },
  };

  const open = () => history.replace(openLocation);

  const closeLocation = {
    ...history.location,
    hash: closeHash(history.location.hash, '#popup/confirm'),
    state: {
      ...history.location.state,
      contentId: null,
      values: null,
      ctaIdConfirm: null,
      ctaIdDecline: null,
      action: null,
      payload: null,
    },
  };

  const close = () => history.replace(closeLocation);

  return { status, setStatus, open, openLocation, close, closeLocation };
};

export default useConfirmPopup;

import React from 'react';
import BasePage from '@pages/BasePage';
import marked from 'marked';

import './style.css';

const ProductBlock = ({ productBlockSection }) => (
  <BasePage>
    <main className="container">
      <div
        className="product-block"
        dangerouslySetInnerHTML={{
          __html: marked(productBlockSection?.content || ''),
        }}
      />
    </main>
  </BasePage>
);

export default ProductBlock;

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';

"use strict";

/**
 * 
 * If you want to add a polyfill, check if it is available on https://polyfill.io first
 * Add it to dev.html, index.html and default.ejs in the script-tag from polyfill.io
 * 
 **/

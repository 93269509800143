import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeAlerts } from '@actions/alert';
import hoistNonReactStatic from 'hoist-non-react-statics';

const withAlertHandling = (alerts, config) => BaseComponent => {
  @connect(
    state => ({
      alerts: state.alerts.data.filter(a => alerts.includes(a.type)),
    }),
    {
      removeAlerts,
    }
  )
  class withAlertHandling extends Component {
    componentWillUnmount() {
      let _alerts = alerts;
      if (config?.excludeOnUnmount) {
        _alerts = alerts.filter(a => !config.excludeOnUnmount.includes(a));
      }
      this.props.removeAlerts(_alerts);
    }

    render() {
      const { alerts, removeAlerts } = this.props;
      return (
        <BaseComponent
          {...this.props}
          alerts={alerts}
          removeAlerts={removeAlerts}
        />
      );
    }
  }

  return hoistNonReactStatic(withAlertHandling, BaseComponent);
};

export default withAlertHandling;

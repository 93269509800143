import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux';
import config from '../skin/config';
import merge from 'lodash/merge';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { getContentPreviewSettings } from './contentPreview';

export const env = __CONFIG__;

export const configs = config.configs;

export const common = config.common;

/**
 * Creates a config using jurisdiction and ipCountry
 */
export const getConfig = (jurisdiction, ipCountry) => {
  jurisdiction = (jurisdiction || '').toLowerCase();
  ipCountry = (ipCountry || '').toLowerCase();

  const mergedConfigs = Object.values(configs)
    .map(config => merge(common(env, ipCountry), config(env, ipCountry)))
    .map(config => ({
      ...config,
      isPnp: config.type === 'pnp',
      isAccount: config.type === 'account',
    }));

  return (
    mergedConfigs.find(c => c.config === jurisdiction) ||
    mergedConfigs.find(
      c => c.config === common(env, ipCountry).defaultJurisdiction
    )
  );
};

export const withConfig = BaseComponent => {
  @connect(state => ({
    config: getConfig(state.app.jurisdiction, state.app.ipCountry),
  }))
  class WithConfig extends Component {
    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  return hoistNonReactStatic(WithConfig, BaseComponent);
};

export const useConfig = () => {
  const jurisdiction = useSelector(state => state.app.jurisdiction);
  const ipCountry = useSelector(state => state.app.ipCountry);
  return getConfig(jurisdiction, ipCountry);
};

export const setGlobalConfig = jurisdiction => {
  if (typeof window !== 'undefined') {
    if (getConfig(jurisdiction).fastTrack) {
      window.fastTrackAPIHost = getConfig(jurisdiction).fastTrack.apiHost;
    }

    window.middlemanHost = getConfig(jurisdiction).aleacc.host;
    window.paymentIQHost = getConfig(jurisdiction).devcode.host;
    window.paymentIQOAuthHost = getConfig(jurisdiction).devcode.oauth_host;
    window.showTranslations = getContentPreviewSettings('showTranslations');
  }

  if (typeof global !== 'undefined') {
    if (getConfig(jurisdiction).fastTrack) {
      global.fastTrackAPIHost = getConfig(jurisdiction).fastTrack.apiHost;
    }

    global.middlemanHost = getConfig(jurisdiction).aleacc.host;
    global.paymentIQHost = getConfig(jurisdiction).devcode.host;
    global.paymentIQOAuthHost = getConfig(jurisdiction).devcode.oauth_host;
    global.showTranslations = getContentPreviewSettings('showTranslations');
  }
};

import { isFavorite } from '@utils/game';

const selectFavoriteGames = state => {
  const aleaccFavoriteGames = state.favorite.games;
  const cmsGames = state.content.games.data;
  const cmsFavoriteGames = cmsGames?.filter(g =>
    isFavorite(aleaccFavoriteGames, g.backendId, g.backendTableId)
  );

  return cmsFavoriteGames?.map(g => {
    // Find matching favorite game in Aleacc favorites.
    const aleaccFavoriteGame = g.backendTableId
      ? aleaccFavoriteGames.find(f =>
          f.Tables.find(t => t.TableId === g.backendTableId)
        )
      : aleaccFavoriteGames.find(f => f.Id === g.backendId);

    // Add properties "Id" and "Tables" in order to make the games
    // always work with the "isFavorite" util.
    if (aleaccFavoriteGame) {
      return {
        ...g,
        Tables: aleaccFavoriteGame.Tables,
        Id: aleaccFavoriteGame.Id,
      };
    }

    return g;
  });
};

export default selectFavoriteGames;

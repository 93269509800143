import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'tg-core-components';
import { popularInCountries } from '@utils/popularInTranslationMap';
import { useIntl } from 'react-intl';

const GameGroupTitle = ({ identifier, action, icon, country }) => {
  const showPopularInCountry = identifier === 'popular' && country?.label;

  const intl = useIntl();

  return (
    <Fragment>
      <div className="header-and-icon">
        {icon && <img src={icon.file.url} alt="header-icon" loading="lazy" />}
        {showPopularInCountry && (
          <span>
            <Translate id="label.popular-in" defaultMessage="Popular in" />{' '}
            <span className="country">{popularInCountries(intl, country)}</span>
          </span>
        )}
        {identifier && !showPopularInCountry ? (
          <Translate id={'header.' + identifier} />
        ) : (
          ''
        )}
      </div>
      {action && (
        <Link to={action}>
          <Translate id="action.show_more" defaultMessage="Show more" />
        </Link>
      )}
    </Fragment>
  );
};

export default GameGroupTitle;

import React from 'react';
import cn from 'classnames';
import { Money, Translate } from 'tg-core-components';
import infinity from '../../images/infinity.svg';
import bet from '../../images/bet.png';
import './LiveCasinoInfo.css';

export const selectLiveCasinoInfoProps = state => ({
  isAuthenticated: state.player.isAuthenticated,
  currency: state.player.user?.Currency,
});

const LiveCasinoInfo = ({
  /* props from selector */
  isAuthenticated,
  currency,

  /* props from parent */
  game,
}) => {
  const wagerLimitCurrency = game.liveInfo?.WagerLimits.find(
    wl => wl.Currency === (isAuthenticated && currency ? currency : 'EUR')
  );

  const rouletteResults = game.liveInfo?.LastResults.slice(0, 6).map(
    r => r && r.Results[0]
  );

  const blackResults = [
    '2',
    '4',
    '6',
    '8',
    '10',
    '11',
    '13',
    '15',
    '17',
    '20',
    '22',
    '24',
    '26',
    '28',
    '29',
    '31',
    '33',
    '35',
  ];

  const allSeatsAvailable =
    game.liveInfo.BlackjackAvailableSeats?.length ===
    game.liveInfo.BlackjackTotalSeats;
  const noSeatsAvailable = game.liveInfo.BlackjackAvailableSeats?.length === 0;
  const middleSeat = Math.floor((game.liveInfo?.BlackjackTotalSeats || 0) / 2);

  const showBetBehind =
    game.liveInfo.BlackjackTotalSeats &&
    game.liveInfo.BlackjackAvailableSeats.length === 0 &&
    game.liveInfo.BlackjackBetBehind === true;

  const showLimitedSeats =
    noSeatsAvailable && game.liveInfo.BlackjackBetBehind ? false : true;

  const showRouletteResults =
    rouletteResults &&
    game.categories?.find(c => c.identifier?.toLowerCase() === 'roulette');

  const showBlackjackResults = game.categories?.find(
    c => c.identifier?.toLowerCase() === 'blackjack'
  );

  return (
    <div className="live-casino-info">
      {wagerLimitCurrency && (
        <div className="wager-limits">
          <Money
            value={wagerLimitCurrency.Min}
            currency={wagerLimitCurrency.Currency}
          />
          {' - '}
          <Money
            value={wagerLimitCurrency.Max}
            currency={wagerLimitCurrency.Currency}
          />
        </div>
      )}
      {showRouletteResults && (
        <div className="roulette-numbers">
          {rouletteResults.map((r, i) => (
            <div
              key={i}
              className={cn('number', {
                black: blackResults.some(item => item === r),
                green: r === '0',
                red: !blackResults.some(item => item === r) && r !== 0,
              })}>
              <span>{r}</span>
            </div>
          ))}
        </div>
      )}
      {showBlackjackResults &&
        game.liveInfo.BlackjackAvailableSeats &&
        game.liveInfo.BlackjackTotalSeats &&
        showLimitedSeats && (
          <div className="blackjack-info">
            <Translate
              id="label.available-seats"
              defaultMessage="Seats available"
            />
            <div
              className={cn('seats', {
                green: allSeatsAvailable,
                red: noSeatsAvailable,
                orange:
                  !allSeatsAvailable &&
                  !noSeatsAvailable &&
                  game.liveInfo.BlackjackAvailableSeats.length <= middleSeat,
                yellow:
                  !allSeatsAvailable &&
                  !noSeatsAvailable &&
                  game.liveInfo.BlackjackAvailableSeats.length > middleSeat,
              })}>
              {game.liveInfo.BlackjackAvailableSeats.length}
            </div>
          </div>
        )}

      {showBlackjackResults && showBetBehind && (
        <div className="blackjack-info">
          <Translate id="label.bet-behind" defaultMessage="Bet behind" />
          <div className="bet-behind gray">
            <img src={bet} alt="bet" loading="lazy" />
          </div>
        </div>
      )}

      {showBlackjackResults && game.liveInfo.BlackjackTotalSeats === null && (
        <div className="blackjack-info">
          <Translate
            id="label.unlimited-seats"
            defaultMessage="Unlimited seats"
          />
          <div className="unlimited-seats green">
            <img src={infinity} alt="info" loading="lazy" />
          </div>
        </div>
      )}
    </div>
  );
};
export default LiveCasinoInfo;

import React from 'react';

const MultipleVideos = ({
  videos,
  className,
  videoRef,
  posterUrl,
  ...rest
}) => (
  <video
    key={videos?.[0].url}
    className={className}
    ref={videoRef}
    poster={posterUrl}
    {...rest}>
    {videos?.map((video, i) => (
      <source
        src={video.url}
        type={
          video.contentType === 'video/x-matroska'
            ? 'video/webm'
            : video.contentType
        }
        key={i}
      />
    ))}
  </video>
);

export default MultipleVideos;

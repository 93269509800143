import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import { getWallet, getWallets } from 'tg-core-redux/lib/modules/wallet/action';
import { withCookies } from 'react-cookie';
import Header, { selectHeaderProps } from '@components/Header';
import Footer, { selectFooterProps } from '@components/Footer';
import AcceptCookies from '@components/AcceptCookies';
import Helmet from 'react-helmet';
import ErrorPage from '@components/Error';
import WheelNotice from '@components/WheelNotice';
import { withConfig } from '@config';
import ContentPreviewContainer from '@components/ContentPreviewContainer';
import StickyFooter, {
  selectStickyFooterProps,
} from '@components/StickyFooter';
import cn from 'classnames';
import getByPath from 'lodash/get';
import ResponsibleGamingWrapper, {
  selectResponsibleGamingWrapperProps,
} from '@components/ResponsibleGamingWrapper';
import queryString from 'query-string';
import { showToast } from '@actions/toast';
import { withOverlayRouter } from '@components/OverlayRouter';
import withWindowWidth from '@components/hoc/withWindowWidth';
import { getFastTrackSid } from 'tg-core-api/lib/utils';
import { getBonuses, getOffers } from 'tg-core-redux/lib/modules/bonus/action';
import { loadScript } from '@utils/script';
import { withRouter } from 'react-router';
import InstallApp, { selectInstallAppProps } from '@components/InstallApp';

import '@styles/accordion.css';
import '@styles/button.css';
import '@styles/checkbox.css';
import '@styles/base.css';
import '@styles/media-queries.css';
import '@styles/input.css';
import '@styles/pagination.css';
import '@styles/icomoon.css';
import '@styles/responsible-gaming-widget.css';
import '@styles/notification.css';
import '@styles/notifications-badge.css';
import '@styles/list.css';
import '@styles/skeleton.css';
import './style.css';

@withWindowWidth
@withRouter
@withOverlayRouter
@withCookies
@withConfig
@connect(
  (state, props) => ({
    device: state.app.device,
    jurisdiction: state.app.jurisdiction,
    user: state.player.user,
    player: state.player,
    isAuthenticated: state.player.isAuthenticated,
    sessionId: state.player.sessionId,
    sections: state.content.sections.data,
    browser: state.app.browser,
    ipCountry: state.app.ipCountry,
    bonusOffer: state.bonusOffer.data,
    countries:
      state.content.config.data?.find(c => c.key == 'countries')?.value || [],
    domain: props.config.jurisdictions.find(
      j => j.key === state.app.jurisdiction.toLowerCase()
    )?.domain,
    languages: state.content.languages.data,
    headerProps: selectHeaderProps(state),
    footerProps: selectFooterProps(state),
    stickyFooterProps: selectStickyFooterProps(state),
    responsibleGamingWrapperProps: selectResponsibleGamingWrapperProps(state),
    cookieConsentAleacc: state.playerProperties.data?.find(
      p => p.Name === 'accepted-cookies'
    ),
    installAppProps: selectInstallAppProps(state),
    hideInstallAppBanner: state.app.hideInstallAppBanner,
  }),
  {
    signOut: playerActions.signOut,
    showToast,
    getBonuses,
    getOffers,
    getWallet,
    getWallets,
  }
)
class BasePage extends Component {
  state = {
    acceptedCookies: this.props.cookies.get('accepted-cookies'),
  };

  componentDidMount() {
    const {
      config,
      isAuthenticated,
      sessionId,
      getWallet,
      getWallets,
      location,
      cookies,
    } = this.props;

    if (isAuthenticated) {
      getWallet(sessionId);
      getWallets(sessionId);
    }

    const params = queryString.parse(window.location.search);

    // handle payment result
    if (params.paymentSuccess === 'true') {
      window.handlePaymentSuccess(window.location.search);
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + window.location.hash
      );
    } else if (params.paymentSuccess === 'false') {
      window.handlePaymentFailure(window.location.search);
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + window.location.hash
      );
    } else if (params.paymentSuccess === 'pending') {
      window.handlePaymentPending(window.location.search);
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + window.location.hash
      );
    } else if (params.paymentSuccess === 'canceled') {
      window.handlePaymentCancellation(window.location.search);
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + window.location.hash
      );
    }

    const globalWarning = this.props.sections?.find(
      s => s.identifier === 'global-warning'
    );

    if (globalWarning) {
      const globalWarningViews = cookies.get('global-warning-views');

      const parsedGlobalWarningViews = globalWarningViews
        ? parseInt(globalWarningViews)
        : 0;

      const showTimes = getByPath(globalWarning, 'config.show_times', -1);
      const urls = getByPath(globalWarning, 'config.urls', []);
      const status = getByPath(globalWarning, 'config.status', 'ERROR');
      const hideAfter = getByPath(globalWarning, 'config.hide_after', 5000);
      const resetAfter = getByPath(
        globalWarning,
        'config.reset_after',
        86400000
      );

      if (showTimes > -1 && parsedGlobalWarningViews >= showTimes) {
        return;
      }

      if (
        urls.length > 0 &&
        !urls.some(url => location.pathname.includes(url))
      ) {
        return;
      }

      this.props.showToast({
        text: globalWarning.content,
        toastType: status,
        duration: hideAfter,
      });

      cookies.set('global-warning-views', parsedGlobalWarningViews + 1, {
        path: '/',
        expires: new Date(Date.now() + resetAfter),
        secure: config.CURRENT_ENV === 'prod',
      });
    }

    if (config.fastTrack && isAuthenticated && __CLIENT__) {
      getFastTrackSid(sessionId).then(() => {
        window.fasttrackbrand = config.fastTrack.brand;
        window.fasttrack = config.fastTrack.config;

        loadScript(config.fastTrack.script, 'ftScript').then(() => {
          new window.FastTrackLoader();
        });

        window.addEventListener(
          'fasttrack-crm-authed',
          this.getFastTrackBonuses
        );
      });
    }
  }

  getFastTrackBonuses = () => {
    const { config, sessionId, getBonuses, getOffers } = this.props;

    getBonuses(sessionId, config.bonusProvider);
    getOffers(sessionId, config.bonusProvider);
  };

  componentWillUnmount() {
    if (this.props.config.fastTrack && __CLIENT__) {
      window.removeEventListener(
        'fasttrack-crm-authed',
        this.getFastTrackBonuses
      );
    }
  }

  render() {
    const {
      cookies,
      config,
      children,
      isAuthenticated,
      user,
      className,
      sections,
      page,
      languages,
      domain,
      jurisdiction,
      hideHeader,
      header,
      hideStickyFooter,
      device,
      ipCountry,
      sessionId,
      bonusOffer,
      error,
      overlayMatches,
      location,
      countries,
      headerProps,
      stickyFooterProps,
      hideFooter,
      footerProps,
      setProperties,
      cookieConsentAleacc,
      responsibleGamingWrapperProps,
      installAppProps,
      screenWidth,
      hideBackground,
      hideInstallAppBanner,
    } = this.props;
    const { acceptedCookies } = this.state;

    const restrictedAccess = sections.find(
      i => i.identifier === 'popup-restricted-access'
    );
    const hadNoLocale = __CLIENT__ && cookies.get('hadLocale') === 'false';

    const disableRestrictedAccess =
      restrictedAccess && cookies.get('disableRestrictedAccess');
    const qs = queryString.parse(location.search);

    if (restrictedAccess && !disableRestrictedAccess && qs.dra) {
      cookies.set('disableRestrictedAccess', String(qs.dra), {
        path: '/',
      });
    }

    const preview = __CLIENT__
      ? cookies.get('content_preview') === 'activated'
      : false;

    const crawlerAgentRegex = /bot|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|yandex/i;
    if (
      __CLIENT__ &&
      (!disableRestrictedAccess || disableRestrictedAccess !== 'true') &&
      restrictedAccess &&
      ipCountry &&
      !crawlerAgentRegex.test(window.navigator.userAgent)
    ) {
      /**
       * Show a popup for restricted countries.
       * Countries that the popup should show for is
       * managed from the segment "restricted-countires"
       * in contentful.
       */
      window.location.hash = '#popup/restricted-access';
    }

    /**
     * Show a popup if the current country supports more
     * than one locale and the player has not yet selected a locale.
     */
    const country = countries.find(c => c.value == ipCountry);
    if (__CLIENT__ && hadNoLocale && country?.locales) {
      window.location.hash = '#popup/locales';
    }

    const cookieContent = sections.find(
      s => s.identifier === 'cookie-information'
    );

    const showWheelNotice =
      config.bonusWheel &&
      bonusOffer.some(b => b.Description?.match(/^wheel_/));

    const sga = jurisdiction.toLowerCase() === 'sga';
    const backgroundImage = getByPath(page, 'background.file.url');

    const isNewIpad =
      __CLIENT__ &&
      window.navigator.userAgent.match(/Mac/) &&
      window.navigator.maxTouchPoints &&
      window.navigator.maxTouchPoints > 2;

    return (
      <div className={cn('BasePage', className && `${className}`)}>
        <Helmet
          bodyAttributes={{
            class: cn(
              `page-${page?.url}`,
              {
                'is-authenticated': isAuthenticated,
                'is-offcanvas-open': overlayMatches.length > 0,
                'hide-sticky-footer': hideStickyFooter || device === 'Desktop',
                'is-pnp': config.isPnp,
                'is-account': config.isAccount,
                'is-desktop': device === 'Desktop',
                'is-tablet': device === 'Tablet' || isNewIpad,
                'is-mobile': device === 'Mobile',
              },
              user && `gender-${user.Gender.toLowerCase()}`,
              jurisdiction && `jurisdiction-${jurisdiction.toLowerCase()}`,
              this.props.browser && `${this.props.browser.toLowerCase()}`
            ),
          }}>
          {page && <title>{page.title}</title>}
          {page && <meta name="description" content={page.description} />}
          {domain && page?.canonicalUrl && (
            <link
              rel="canonical"
              href={'https://' + domain + '/' + page.canonicalUrl}
            />
          )}
          {domain &&
            languages?.map((lang, i) => {
              const localeUrl = lang?.alias?.[0] || lang.code;
              const path = location.pathname.substring(
                1,
                location.pathname.length
              );

              return (
                <link
                  key={i}
                  rel="alternate"
                  hrefLang={lang.code}
                  href={`https://${domain}/${localeUrl}/${path}`}
                />
              );
            })}
          {page && <meta property="og:title" content={page.title} />}
          {page && (
            <meta property="og:description" content={page.description} />
          )}
          {page && (
            <meta
              property="og:image"
              content={`https:${page.image?.file.url}`}
            />
          )}
          {(config.CURRENT_ENV === 'dev' || (page && page.private)) && (
            <meta name="robots" content="noindex, nofollow" />
          )}
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <script
            src={`${config.devcode.host}/viq/jscardencrypter/${config.MERCHANT_ID}`}
          />
        </Helmet>
        <Fragment>
          {sga && (
            <ResponsibleGamingWrapper {...responsibleGamingWrapperProps} />
          )}

          {!hideHeader && <Header {...headerProps} {...(header || {})} />}

          {config.installApp && !hideInstallAppBanner && (
            <InstallApp {...installAppProps} />
          )}
        </Fragment>

        {/* Show cookie notice */}
        {!cookieConsentAleacc && !acceptedCookies && (
          <AcceptCookies
            content={cookieContent && cookieContent.content}
            onAccept={() => {
              cookies.set('accepted-cookies', true, {
                path: '/',
                expires: new Date(Date.now() + 31556952000 * 2),
                secure: config.CURRENT_ENV === 'prod',
              });
              this.setState({
                acceptedCookies: true,
              });
              sessionId &&
                setProperties(sessionId, {
                  Properties: [
                    {
                      Name: 'accepted-cookies',
                      Type: 'Text',
                      Value: 'true',
                    },
                  ],
                });
            }}
          />
        )}

        {page || !Object.hasOwnProperty.call(this.props, 'page') ? (
          <div
            className={cn('main-content-container', {
              'layout-no-sidebar': page?.layout?.includes('No sidebar'),
              'layout-no-header': page?.layout?.includes('No header'),
              'layout-center-content': page?.layout?.includes('Center content'),
            })}
            style={{
              backgroundImage: backgroundImage && `url(${backgroundImage})`,
              ...(hideBackground && { backgroundImage: 'none' }),
            }}>
            {children}
          </div>
        ) : (
          <ErrorPage error={error} />
        )}

        {preview && <ContentPreviewContainer />}

        {showWheelNotice && (
          <WheelNotice
            section={sections?.find(s => s.identifier === 'wheel-notice')}
          />
        )}

        {Boolean(
          (__CLIENT__ && screenWidth < 992) ||
            (device !== 'Desktop' && screenWidth < 992) ||
            config.showStickyFooter ||
            (isNewIpad && screenWidth < 992)
        ) &&
          !hideStickyFooter && <StickyFooter {...stickyFooterProps} />}

        {!hideFooter && <Footer {...footerProps} />}
      </div>
    );
  }
}

export default BasePage;

import React from 'react';

import './style.css';

const TopListNumber = ({ number }) => (
  <div className="TopListNumber">
    <span>{number}</span>
  </div>
);

export default TopListNumber;

import { getConfig } from '@config';
import { withExperiment as _withExperiment } from 'react-experiment-hoc';

export const withExperiment = (experimentName, options) => {
  return BaseComponent => {
    const isOptedIn = (getConfig().experiments || []).find(
      e => e?.name === experimentName
    );

    if (isOptedIn)
      return _withExperiment(experimentName, options)(BaseComponent);
    else return BaseComponent;
  };
};

import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import compose from 'recompose/compose';
import { useCounter } from '@utils/counter';
import withInViewport from '@components/hoc/withInViewport';
import selectJackpots from '@selectors/selectJackpots';

export const selectJackpotCounterProps = state => ({
  jackpots: selectJackpots(state),
});

const JackpotCounter = ({
  inViewport,
  jackpots,
  intl,
  isPaused,
  initialDurationConfig,
  durationConfig,
  startPointConfig,
}) => {
  const [totalJackpot, setTotalJackpot] = useState(0);
  const [duration, setDuration] = useState(0);
  const initialDuration = initialDurationConfig
    ? initialDurationConfig
    : durationConfig && !initialDurationConfig
    ? null
    : 10;
  const remainingDuration = durationConfig || 300;

  useEffect(() => {
    if (totalJackpot === 0 && inViewport && jackpots.total && initialDuration) {
      setTotalJackpot(jackpots.total * 0.9);
      setDuration(initialDuration);
    }
  }, [
    jackpots.total,
    totalJackpot,
    setTotalJackpot,
    inViewport,
    initialDuration,
  ]);

  const jackpot = useCounter({
    amount: totalJackpot,
    duration: duration,
    paused: isPaused,
    startPoint: startPointConfig,
  });

  useEffect(() => {
    if (
      (initialDuration === null && jackpots.total) ||
      (totalJackpot !== 0 && jackpot === totalJackpot)
    ) {
      if (inViewport) {
        setTotalJackpot(jackpots.total);
        setDuration(remainingDuration);
      }
    }
  }, [
    jackpots.total,
    jackpot,
    totalJackpot,
    setTotalJackpot,
    inViewport,
    initialDuration,
    remainingDuration,
  ]);

  const formattedJackpot =
    jackpot &&
    intl.formatNumber(jackpot, {
      style: 'currency',
      currency: jackpots.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  return formattedJackpot;
};

export default compose(withInViewport, injectIntl)(JackpotCounter);

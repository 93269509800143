import Cookies from 'universal-cookie';
import { getConfig } from '@config';
import { getContentPreviewSettings } from '@utils/contentPreview';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  isAutoLoggedIn: false,
  sessionId: null,
  user: null,
  paymentStats: null,
  lifetimeStats: null,
  signUpError: {},
  signUpIssues: {},
  requestResetPasswordStatus: null,
  acceptedTacVersion: null,
  requestPersonId: false,
  jwtToken: null,
  hasLoginBlock: false,
  displayPages: [],
  pendingAction: null,
};

const cookies = new Cookies();

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SIGNINWITHCODE_START':
    case 'SIGNINV2_START':
    case 'ACTIVATE_START':
    case 'REQUEST_PASSWORD_RESET_START':
    case 'RESET_PASSWORD_START':
    case 'REQUEST_ACTIVATION_CODE_START':
      return {
        ...state,
        isLoading: true,
      };

    case 'SIGNUPWITHCODE_START':
    case 'SIGNUPV2_START':
    case 'SIGNUP_START':
      return {
        ...state,
        isLoading: true,
        signUpError: {
          hasError: false,
          currentStep: null,
        },
      };

    case 'REQUEST_ACTIVATION_CODE_RESET':
    case 'ACTIVATE_RESET':
      return {
        ...state,
        isLoading: false,
      };

    case 'SIGNINWITHCODE_COMPLETE':
    case 'SIGNINV2_COMPLETE':
    case 'SIGNIN_PNP_COMPLETE': {
      cookies.set('sessionId', action.payload.SessionId, {
        domain: getConfig().cookieDomain(),
        expires: new Date(
          Date.now() + (getConfig()?.sessionCookieMaxAge || 9000000 * 2)
        ),
        secure: getConfig().CURRENT_ENV === 'prod',
        sameSite: 'lax',
        path: '/',
      });

      cookies.set('hasLoggedIn', true, {
        expires: new Date(Date.now() + 31556952000),
        secure: getConfig().CURRENT_ENV === 'prod',
        path: '/',
      });

      const jwtToken = action.payload.JwtTokenSignup || state.jwtToken;

      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        sessionId: action.payload.SessionId,
        acceptedTacVersion: action.payload.TacVersionAccepted,
        jwtToken,
        hasPassword: action.payload.HasPassword !== false,
        displayPages: action.payload.DisplayPage,
      };
    }

    case 'SIGNUPWITHTOKEN_COMPLETE':
    case 'SIGNUPWITHCODE_COMPLETE':
    case 'SIGNUPV2_COMPLETE': {
      cookies.remove('bonusCodeSignUp');
      const { Issues } = action.payload;

      return {
        ...state,
        isLoading: false,
        isAutoLoggedIn: Issues.length === 0,
        registrationInfo: action.requestData[0],
        signUpIssues: {
          issues: Issues,
          hasIssues: Issues.length > 0,
        },
        signUpError: {
          hasError: false,
          currentStep: 'step3',
        },
      };
    }

    case 'PLAYER_GROUPS_COMPLETE': {
      return {
        ...state,
        groups: action.payload,
      };
    }

    case 'ACTIVATE_COMPLETE':
    case 'RESET_PASSWORD_COMPLETE':
    case 'REQUEST_ACTIVATION_CODE_COMPLETE':
      return {
        ...state,
        isLoading: false,
      };

    case 'PAYMENT_STATS_FAILURE':
    case 'ACTIVATE_FAILURE':
    case 'REQUEST_ACTIVATION_CODE_FAILURE':
    case 'RESET_PASSWORD_FAILURE':
      return {
        ...state,
        isLoading: false,
      };

    case 'SIGNINWITHCODE_FAILURE':
    case 'SIGNINV2_FAILURE':
      return {
        ...state,
        isLoading: false,
        hasLoginBlock:
          action.payload.value.response?.data?.Code === 'LoginTimeError',
        jwtToken: action.payload.value.response?.data?.Token,
      };

    case 'REQUEST_PASSWORD_RESET_FAILURE':
      return {
        ...state,
        requestResetPasswordStatus: 'FAILURE',
        isLoading: false,
      };

    case 'REQUEST_PASSWORD_RESET_COMPLETE':
      return {
        ...state,
        isLoading: false,
        requestResetPasswordStatus: 'COMPLETE',
      };

    case 'CHANGE_PASSWORD_FAILURE':
      return {
        ...state,
        changePasswordStatus: 'FAILURE',
        isLoading: false,
      };

    case 'CHANGE_PASSWORD_COMPLETE':
      return {
        ...state,
        isLoading: false,
        changePasswordStatus: 'COMPLETE',
      };

    case 'CHANGE_USERNAME_COMPLETE':
      return {
        ...state,
        changeUsernameState: 'COMPLETE',
        changeUsernameError: false,
        isLoading: false,
      };

    case 'CHANGE_USERNAME_FAILURE': {
      const { Code } = action.payload.value.response.data;

      return {
        ...state,
        changeUsernameState: 'FAILURE',
        changeUsernameError: Code ? `${Code}`.toLowerCase() : 'unknown_error',
        isLoading: false,
      };
    }

    case 'SIGNUPWITHCODE_FAILURE':
    case 'SIGNUPV2_FAILURE':
    case 'SIGNUP_FAILURE': {
      const error = action.payload.value.response?.data.Message.toLowerCase();
      const step2Fields = [
        'FirstName',
        'LastName',
        'Address1',
        'Zip',
        'City',
        'MobilePhoneNumber',
        'BirthDate',
        'Gender',
        'Country',
        'Currency',
        'Language',
        'PromoCode',
      ];
      const step1Fields = ['Email', 'UserName', 'Password'];
      const isSignupStep2Error =
        step2Fields.findIndex(item => error.indexOf(item.toLowerCase()) > -1) >
        -1;
      const isSignupStep1Error =
        step1Fields.findIndex(item => error.indexOf(item.toLowerCase()) > -1) >
        -1;

      return {
        ...state,
        isLoading: false,
        signUpError: {
          hasError: true,
          currentStep: isSignupStep1Error
            ? 'step1'
            : isSignupStep2Error
            ? 'step2'
            : null,
        },
      };
    }

    case 'UPDATE_AUTHENTICATE':
      return {
        ...state,
        isAuthenticated: true,
        sessionId: action.payload,
      };

    case 'PAYMENT_STATS_START':
    case 'USER_INFO_START':
      return {
        ...state,
        isLoading: true,
      };

    case 'USER_INFO_COMPLETE': {
      const payload = { ...action.payload };
      cookies.set('userJurisdiction', payload.Jurisdiction, {
        secure: getConfig().CURRENT_ENV === 'prod',
        expires: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000), // 1 day
        path: '/',
      });

      const contentPreviewSettings = getContentPreviewSettings();

      if (contentPreviewSettings.country)
        payload.Country = contentPreviewSettings.country;

      return {
        ...state,
        user: payload,
        acceptedTacVersion: payload.TacVersionAccepted,
        isLoading: false,
      };
    }

    case 'PAYMENT_STATS_COMPLETE': {
      return {
        ...state,
        paymentStats: action.payload,
        isLoading: false,
      };
    }

    case 'LIFETIME_STATS_COMPLETE': {
      return {
        ...state,
        lifetimeStats: action.payload,
        isLoading: false,
      };
    }

    case 'USER_INFO_FAILURE':
    case 'SIGNOUT':
    case 'SIGN_OUT_START': {
      cookies.remove('bonusCodeDeposit');
      cookies.remove('bonusCodeSignUp');
      cookies.remove('userJurisdiction');
      cookies.remove('sessionId', {
        domain: getConfig().cookieDomain(),
        path: '/',
      });
      return {
        isAuthenticated: false,
        sessionId: null,
        user: null,
      };
    }

    case 'SIGNOUT_SOFT': {
      cookies.remove('userJurisdiction');
      cookies.remove('sessionId', {
        domain: getConfig().cookieDomain(),
        path: '/',
      });
      return {
        ...state,
        isAuthenticated: false,
      };
    }

    case 'VERIFY_EXISTING_SE_PLAYER_COMPLETE':
    case 'VERIFY_EXISTING_SE_PLAYER_WITH_TOKEN_COMPLETE': {
      return {
        ...state,
        isAutoLoggedIn: true,
      };
    }

    case 'UPDATE_PLAYER_TAC_COMPLETE':
      return {
        ...state,
        acceptedTacVersion: action.requestData[0],
      };

    case 'GET_DISPLAY_PAGES_COMPLETE':
      return {
        ...state,
        displayPages: action.payload,
      };

    case 'ADD_PENDING_ACTION': {
      return {
        ...state,
        pendingAction: state.pendingAction
          ? `${state.pendingAction},action/${action.payload}`
          : action.payload,
      };
    }

    case 'REMOVE_PENDING_ACTION': {
      return {
        ...state,
        pendingAction: null,
      };
    }

    case 'GET_ACTIVE_CURRENCY_COMPLETE': {
      return {
        ...state,
        activeCurrency: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

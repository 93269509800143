import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouteData from '@utils/withRouteData';
import Tournament from './Tournament';
import {
  setProperties,
  getProperties,
} from 'tg-core-redux/lib/modules/player_properties/action';
import {
  getRelativeLeaderboard,
  getLeaderboard,
} from 'tg-core-redux/lib/modules/tournament/action';
import Loader from '@components/Loader';
import PageBuilder from '@components/PageBuilder';

import BasePage from '@pages/BasePage';

import './style.css';

@withRouteData
@connect(
  state => ({
    pages: state.content.pages.data,
    menus: state.content.menus.data,
    locale: state.app.locale,
    games: state.content.games.data,
    device: state.app.device,
    jackpots: state.event.jackpots,
    sets: state.content.sets.data,
    tournaments: state.content.tournaments.data,
    playerProperties: state.playerProperties.data,
    leaderboards: state.leaderboards.data,
    player: state.player,
  }),
  {
    getRelativeLeaderboard,
    getLeaderboard,
    setProperties,
    getProperties,
  }
)
class TournamentsContainer extends Component {
  state = {
    isLoading: false,
  };

  async componentDidMount() {
    const {
      setProperties,
      getProperties,
      player,
      getRelativeLeaderboard,
      getLeaderboard,
      tournaments,
    } = this.props;
    const tournamentIdentifiers =
      tournaments && tournaments.map(t => t.identifier);

    this.setState({ isLoading: true });

    if (player.isAuthenticated) {
      const playerId = player.user.Id;

      // Fetch player data
      await Promise.all(
        tournamentIdentifiers.map(id => getRelativeLeaderboard(id, playerId))
      );

      const payload = {
        Properties: [
          {
            Name: 'viewedTournamentTimestamp',
            Type: 'Number',
            Value: +new Date(),
          },
        ],
      };

      await setProperties(player.sessionId, payload);
      await getProperties(player.sessionId);
    } else {
      // Fetch general data
      await Promise.all(
        tournamentIdentifiers.map(id => getLeaderboard(id, 0, 1))
      );
    }

    this.setState({ isLoading: false });
  }

  render() {
    const {
      pages,
      tournaments,
      leaderboards,
      player,
      games,
      device,
      jackpots,
    } = this.props;

    const page = pages && pages.find(m => m.url === 'tournaments');
    const pageSections = page?.sections || [];

    const totalJackpot = jackpots?.reduce(
      (prev, curr) => prev + curr.Amount,
      0
    );

    const currency = (jackpots && jackpots[0]?.Currency) || 'EUR';

    if (this.state.isLoading) return <Loader />;

    return (
      <BasePage page={page} className="TournamentsContainer">
        <main className="container">
          <PageBuilder
            items={pageSections}
            game={games}
            totalJackpot={totalJackpot}
            currency={currency}
            device={device}
          />
          {tournaments && (
            <div className="tournaments-grid">
              {tournaments.map((t, k) => {
                const relativeLeaderboard =
                  leaderboards &&
                  leaderboards.relativeLeaderboards &&
                  leaderboards.relativeLeaderboards[t.identifier];

                const leaderboard =
                  leaderboards &&
                  leaderboards.leaderboards &&
                  leaderboards.leaderboards[t.identifier];

                const playerId = player.isAuthenticated ? player.user.Id : null;

                const playerInfo =
                  relativeLeaderboard &&
                  relativeLeaderboard.Players &&
                  relativeLeaderboard.Players.find(p => p.Id === playerId);

                return (
                  <Tournament
                    key={k}
                    tournament={t}
                    leaderboard={relativeLeaderboard || leaderboard || {}}
                    playerInfo={playerInfo ? playerInfo : {}}
                  />
                );
              })}
            </div>
          )}
        </main>
      </BasePage>
    );
  }
}

export default TournamentsContainer;

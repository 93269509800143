import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import depositApis from 'tg-core-devcode/lib/deposit';
import PaymentForm from 'tg-core-components/lib/widgets/Cashier/Deposit/PaymentForm';
import { getConfig } from '@config';
import { getPayments } from 'tg-core-redux/lib/modules/transaction/action';
import { getKyc } from 'tg-core-redux/lib/modules/kyc/action';
import {
  getUserAvailablePaymentMethods,
  deleteUserPaymentAccount,
  cancelUserWithdrawal,
} from 'tg-core-redux/lib/modules/devcode/action';
import { reset, onSuccess } from '@actions/payment';
import {
  Icon,
  Loader,
  DepositWidgetAccordion,
  Translate,
} from 'tg-core-components';
import CashierResult from 'tg-core-components/lib/widgets/CashierAccordion/Payment/CashierResult';
import { injectIntl } from 'react-intl';
import { withCookies } from 'react-cookie';
import { matchProviderType } from 'tg-core-components/lib/widgets/Cashier/Payment';
import Alert, { createAlertId } from '@components/Alert';
import Swish from './Swish';
import Notice from 'tg-core-components/lib/widgets/CashierAccordion/Payment/Notice';
import SelectedPaymentMethod from 'tg-core-components/lib/widgets/CashierAccordion/Payment/SelectedPaymentMethod';
import marked from 'marked';
import { trimObjectStrings } from '@utils/trimObjectStrings';
import { getQueryParams } from '@utils/refRedirect';
import { onRequestComplete, onRequestFailure } from '@actions/payment';

import './style.css';
import translate from 'tg-core-components/lib/lib/utils/translate';
import seletAvailableBonuses from '@selectors/selectAvailableBonuses';

@withCookies
@injectIntl
@connect(
  state => ({
    userAvailablePaymentMethods: state.devcode.availableDepositMethod,
    paymentTransactions: state.paymentTransactions,
    paymentStatus: state.paymentStatus,
    device: state.app.device,
    operatingSystem: state.app.operatingSystem,
    user: state.player.user,
    sessionId: state.player.sessionId,
    sections: state.content.sections.data,
    locale: state.app.locale,
    bonusOffer: state.bonusOffer,
    paymentStats: state.player.paymentStats,
    payments: state.content.payments.data,
    config: getConfig(state.app.jurisdiction),
    limits: state.responsible_gaming.data,
    wallet: state.wallet,
    contentful_config: state.content.config.data,
    jurisdiction: state.app.jurisdiction,
    kyc: state.kyc,
    availableBonuses: seletAvailableBonuses(state),
  }),
  {
    reset,
    onSuccess,
    getKyc,
    getPayments,
    cancelUserWithdrawal,
    deleteUserPaymentAccount,
    getUserAvailablePaymentMethods,
    onRequestComplete,
    onRequestFailure,
  }
)
class Deposit extends Component {
  // declare depositAmount to store the amount of submitting deposit
  // this variable won't affect to UI render, don't need to declare inside of state
  depositAmount = 0;

  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
      paymentData: null,
    };
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidMount() {
    const {
      user,
      device,
      sessionId,
      skipBonusStep,
      getPayments,
      getUserAvailablePaymentMethods,
      config,
    } = this.props;

    if (skipBonusStep)
      this.setState({
        selectedOption: {
          ...this.state.selectedOption,
          currentStep: 'SELECT_PAYMENT_METHOD',
        },
      });

    getUserAvailablePaymentMethods({
      merchantId: config.MERCHANT_ID,
      userId: user.Id,
      sessionId,
      method: 'Deposit',
      channelId: device ? device.substr(0, 1) : null,
    });
    getPayments(sessionId, 100, 0, 'Withdraw');
    getKyc(sessionId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { paymentData, selectedOption } = this.state;

    if (
      paymentData &&
      paymentData.success &&
      paymentData.txState === 'SUCCESSFUL' &&
      !(
        prevState.paymentData &&
        prevState.paymentData.success &&
        prevState.paymentData.txState === 'SUCCESSFUL'
      )
    )
      this.onPaymentSuccess();

    if (
      paymentData &&
      !paymentData.success &&
      paymentData.txState === 'FAILED' &&
      !(
        prevState.paymentData &&
        !paymentData.success &&
        prevState.paymentData.txState === 'FAILED'
      )
    )
      this.onPaymentFailure();

    if (
      prevState.selectedOption?.selectedMethod?.txType !==
      selectedOption?.selectedMethod?.txType
    ) {
      this.props.reset();
    }
  }

  onPaymentSuccess = async () => {
    const { onRequestComplete, cookies } = this.props;
    const { paymentData, selectedOption } = this.state;

    if (cookies.get('bonusCodeSignUp')) cookies.remove('bonusCodeSignUp');

    onRequestComplete({
      ...paymentData,
      txType: selectedOption.selectedMethod.txType,
      amount: this.depositAmount,
    });
  };

  onPaymentFailure = async () => {
    const { onRequestFailure } = this.props;
    const { paymentData, selectedOption } = this.state;
    onRequestFailure({
      ...paymentData,
      txType: selectedOption.selectedMethod.txType,
      amount: this.depositAmount,
    });
  };

  handleSubmit = ({
    selectedMethod,
    selectedAccount,
    currentAmount,
    bonusCode,
    preDepositBonus,
    currentStep,
    ...others
  }) => {
    const {
      sessionId,
      user,
      locale,
      device,
      config,
      getPayments,
      cancelUserWithdrawal,
      reset,
    } = this.props;

    reset();
    this.reset();

    // store the amount of deposit to current context
    this.depositAmount = Number(currentAmount);

    if (!selectedMethod) return;

    if (
      typeof depositApis[selectedMethod.providerType.toLowerCase()] !==
      'function'
    ) {
      return alert(
        `Provider not implemented. Implemented: '${Object.keys(
          depositApis
        ).join(',')}'`
      );
    }

    this.setState({
      selectedOption: {
        selectedMethod,
        selectedAccount,
        currentAmount,
        bonusCode,
        currentStep,
        accountData: others,
        preDepositBonus,
      },
    });

    const paymentRequestData = {
      amount: Number(currentAmount),
      sessionId,
      locale,
      userId: user.Id,
      merchantId: config.MERCHANT_ID,
      attributes: {
        successUrl:
          window.location.origin +
          '/payment-success.html?method=${ptx.method}&tx=${ptx.id}&amount=${ptx.amount.amount}&currency=${ptx.amount.currencyCode}' +
          `&isAccount=${config.isAccount}`,
        pendingUrl:
          window.location.origin +
          '/payment-pending.html?method=${ptx.method}&tx=${ptx.id}&amount=${ptx.amount.amount}&currency=${ptx.amount.currencyCode}' +
          `&isAccount=${config.isAccount}`,
        failureUrl:
          window.location.origin +
          '/payment-failure.html?method=${ptx.method}&tx=${ptx.id}&amount=${ptx.amount.amount}&currency=${ptx.amount.currencyCode}&state=${ptx.state}&status=${ptx.statusCode}' +
          `&isAccount=${config.isAccount}`,
        cancelUrl:
          window.location.origin +
          '/payment-cancellation.html?method=${ptx.method}' +
          `&isAccount=${config.isAccount}`,
        psp: selectedMethod.service,
        channelId: device ? device.substr(0, 1) : null,
      },
      ...others,
    };

    if (bonusCode) {
      paymentRequestData.attributes.aleaccBonusCode = bonusCode;
    }

    if (selectedAccount.accountId) {
      paymentRequestData.accountId = selectedAccount.accountId;
    }

    if (selectedMethod.providerType.toLowerCase() === 'reversewithdrawal') {
      return cancelUserWithdrawal({
        merchantId: config.MERCHANT_ID,
        userId: user.Id,
        transactionId: selectedMethod.id,
        sessionId,
      })
        .then(response => {
          this.setState({
            paymentData: {
              ...response.data,
              amount: Number(currentAmount),
              currency: user.Currency,
            },
            error: null,
            isLoading: false,
          });
          getPayments(sessionId, 100, 0, 'Withdraw');

          this.props.scrollTop();
        })
        .catch(error => {
          this.setState({
            paymentData: null,
            isLoading: false,
            error,
          });

          this.props.scrollTop();
        });
    } else {
      return depositApis[selectedMethod.providerType.toLowerCase()](
        trimObjectStrings(paymentRequestData),
        user
      )
        .then(response => {
          this.setState({
            paymentData: response.data,
            error: null,
            isLoading: false,
          });
        })
        .catch(error => {
          this.setState({
            paymentData: null,
            isLoading: false,
            error,
          });

          this.props.scrollTop();
        });
    }
  };

  /**
   * Since GoogleTagManager will fetching latest data before trigger event --> update props --> re-render --> infinite loop
   * We need to trigger GTM to fetch data after get out of the UI with render causing loop
   * @param isGoingToHomepage
   */
  handleClose = (isGoingToHomepage = false) => {
    // reset the state of paymentActions, i.e., success/failed --> Null
    this.props.reset();

    this.setState({
      paymentData: null,
      error: null,
    });

    const refUrl = getQueryParams({
      location: window.routerHistory.location,
      queryName: 'ref_url',
    });

    if (refUrl) {
      window.routerHistory.replace(refUrl);
    }

    if (isGoingToHomepage) {
      this.props.onClose?.();
    }
  };

  renderError = () => {
    // Translations from PaymentIQ comes in a prio order.
    // First match == most specific translation. Default
    // message in case all others are missing comes from
    // PaymentIQs `error.msg`.

    const { paymentData } = this.state;

    if (paymentData) {
      const { errors } = paymentData;

      const alertIds = errors && errors.map(e => createAlertId(e, 'danger'));

      return (
        <Fragment>
          {alertIds && (
            <Alert icon="exclamation-triangle" id={alertIds} level="danger" />
          )}
        </Fragment>
      );
    }

    return null;
  };

  renderDepositLimitNotice = remainingDepositLimit => {
    const { intl, user } = this.props;
    return (
      <Notice
        icon={<Icon icon="stop-circle" />}
        header={
          <Translate id="label.deposit_limit" defaultMessage="Deposit limit" />
        }
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: marked(
                translate(
                  {
                    id: 'cashier.remaining-deposit-limit',
                    defaultMessage:
                      'Your remaining deposit limit is {amount}. [Review your gaming limits.](#dashboard/responsible-gaming)',
                    values: {
                      amount: intl.formatNumber(
                        remainingDepositLimit < 0 ? 0 : remainingDepositLimit,
                        {
                          style: 'currency',
                          currency: user.Currency,
                          minimumFractionDigits:
                            remainingDepositLimit % 1 === 0 ? 0 : 2,
                          maximumFractionDigits:
                            remainingDepositLimit % 1 === 0 ? 0 : 2,
                        }
                      ),
                    },
                  },
                  intl
                )
              ),
            }}
          />
        }
      />
    );
  };

  renderNotVerifiedNotice = amount => {
    if (!amount) amount = 0;
    const { jurisdiction, user, paymentStats, kyc, intl } = this.props;

    const hasIdApproved =
      kyc &&
      kyc.data &&
      kyc.data.find(i => i.Type === 'ID' && i.Status === 'Approved');

    const remainingDepositAmount =
      150 - parseFloat(paymentStats.TotalDeposit) < 0
        ? 0
        : 150 - parseFloat(paymentStats.TotalDeposit);

    const shouldShowNotVerifiedNotice =
      !hasIdApproved &&
      jurisdiction.toLowerCase() === 'es' &&
      (parseFloat(paymentStats.TotalDeposit) + parseFloat(amount) > 150 ||
        paymentStats.TotalDeposit >= 150);

    if (!shouldShowNotVerifiedNotice) return null;

    return (
      <Notice
        icon={<Icon icon="stop-circle" />}
        header={
          <Translate
            id="deposit.not-verified.header"
            defaultMessage="Not verified"
          />
        }
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: marked(
                translate(
                  {
                    id: 'deposit.not-verified.content',
                    defaultMessage:
                      'To deposit more than €150 you need to [verify your account](/account/verify). Your remaining deposit amount is {remainingDepositAmount}.',
                    values: {
                      remainingDepositAmount: intl.formatNumber(
                        remainingDepositAmount,
                        {
                          style: 'currency',
                          currency: user.Currency,
                          minimumFractionDigits:
                            remainingDepositAmount % 1 === 0 ? 0 : 2,
                          maximumFractionDigits:
                            remainingDepositAmount % 1 === 0 ? 0 : 2,
                        }
                      ),
                    },
                  },
                  intl
                )
              ),
            }}
          />
        }
      />
    );
  };

  /**
   * Notice rendered when payment interrupted by user.
   * Usually when user cancels a payment from inside an iframe.
   */
  renderCanceledNotice = () => (
    <Notice
      icon={<Icon icon="stop-circle" />}
      header={<Translate id="message.cashier.error" defaultMessage="Error" />}
      content={
        <Translate
          id="message.deposit.canceled"
          defaultMessage="Deposit canceled."
        />
      }
    />
  );

  reset = () => {
    this.setState({
      paymentData: null,
      selectedOption: null,
    });
  };

  handleDeleteAccount = account => {
    this.props.deleteUserPaymentAccount({
      merchantId: this.props.config.MERCHANT_ID,
      userId: this.props.user.Id,
      accountId: account.accountId,
      sessionId: this.props.sessionId,
    });
  };

  handleTryAgain = () => {
    this.props.reset();

    this.setState({
      paymentData: null,
      selectedOption: {
        ...this.state.selectedOption,
        currentStep: 'SELECT_PAYMENT_METHOD',
      },
      error: null,
    });
  };

  handleClearError = () => {
    this.setState({ paymentData: { ...this.state.paymentData, errors: null } });
  };

  render() {
    const {
      user,
      userAvailablePaymentMethods,
      paymentTransactions,
      bonusCode,
      fromWelcome,
      amounts,
      payments,
      paymentStatus,
      paymentStats,
      sessionId,
      sections,
      limits,
      contentful_config,
      onSuccess,
      jurisdiction,
      kyc,
      operatingSystem,
      wallet,
      device,
      config,
      handlePaymentSuccess,
      availableBonuses,
    } = this.props;

    const { selectedOption, paymentData } = this.state;

    if (
      !userAvailablePaymentMethods ||
      userAvailablePaymentMethods.status !== 'success' ||
      paymentTransactions.status !== 'success' ||
      !payments
    ) {
      return <Loader inline />;
    }

    const countries = contentful_config.find(c => c.key === 'countries');

    const depositLimits =
      limits &&
      limits.length > 0 &&
      limits.filter(
        l =>
          l.Type === 'Deposit' &&
          (l.Status === 'Active' || l.Status === 'Canceled')
      );

    const remainingDepositLimit =
      depositLimits &&
      depositLimits.length > 0 &&
      depositLimits.reduce(
        (acc, l) => (l.RemainingAmount < acc ? l.RemainingAmount : acc),
        depositLimits[0].RemainingAmount
      );

    const hasIdApproved =
      kyc &&
      kyc.data &&
      kyc.data.find(i => i.Type === 'ID' && i.Status === 'Approved');

    let notVerifiedNL = !hasIdApproved && user.Country == 'NL';
    notVerifiedNL = false;
    if (
      this.state.paymentData &&
      this.state.paymentData.txState === 'WAITING_INPUT' &&
      this.state.paymentData.success &&
      this.state.selectedOption.selectedMethod.txType === 'SwishDeposit' &&
      this.props.paymentStatus.status !== 'success' &&
      this.props.paymentStatus.status !== 'failed'
    ) {
      return <Swish url={paymentData.redirectOutput.url} />;
    }

    if (this.state.error || this.props.paymentStatus.status === 'failed') {
      return (
        <CashierResult
          status="error"
          providerType={selectedOption?.selectedMethod?.providerType}
          cashierType="deposit"
          generalError
          handleTryAgain={this.handleTryAgain}
        />
      );
    }

    if (['success', 'pending'].includes(paymentStatus.status)) {
      const payment = payments.find(
        p =>
          selectedOption &&
          p.providerType.toLowerCase() ===
            selectedOption.selectedMethod.providerType.toLowerCase()
      );

      return (
        <CashierResult
          status={paymentStatus.status}
          providerType={selectedOption?.selectedMethod?.providerType}
          cashierType="deposit"
          currency={user.Currency}
          wallet={wallet}
          handleClose={this.handleClose}
          successMessage={payment && payment.depositSuccessMessage}
        />
      );
    }

    if (
      paymentData &&
      paymentData.success &&
      paymentStatus.status !== 'failed'
    ) {
      const paymentDetails = payments.find(p =>
        matchProviderType(p, selectedOption.selectedMethod)
      );

      return (
        <div className="cashier-accordion">
          <div className="fill-in-steps">
            <SelectedPaymentMethod
              detail={paymentDetails}
              handleBack={() =>
                this.setState({
                  paymentData: null,
                  selectedOption: {
                    ...selectedOption,
                    currentStep: 'SELECT_PAYMENT_METHOD',
                  },
                })
              }
            />

            <PaymentForm
              data={paymentData}
              operatingSystem={operatingSystem}
              onClose={() => this.handleClose(paymentData.success)}
              onSuccess={() => {
                onSuccess();
                handlePaymentSuccess && handlePaymentSuccess();
              }}
              details={paymentDetails}
            />
          </div>
        </div>
      );
    }

    let methods =
      userAvailablePaymentMethods?.data?.methods?.filter(
        method =>
          payments.filter(content => matchProviderType(method, content))
            .length > 0
      ) || [];
    const filteredTransactions =
      this.props.paymentTransactions.data &&
      this.props.paymentTransactions.data.filter(t => t.Status === 'initiated');

    if (filteredTransactions && filteredTransactions.length > 0) {
      const lastestWithdrawl = {
        id: filteredTransactions[0].RemoteTransactionId,
        accountSettings: null,
        accounts: [],
        canAddAccount: false,
        fees: [],
        amount: filteredTransactions[0].Amount,
        maintenanceInfo: { maintenance: false },
        providerType: 'reverseWithdrawal',
        limit: {
          min: '1',
          max: '100000',
        },
        pspCurrency: '',
        pspToUserCurrencyBuyRate: 0,
        service: null,
        toUserCurrencyBuyRate: 0,
        txType: 'reverseWithdrawal',
      };
      methods = [lastestWithdrawl, ...methods];
    }

    return (
      <div className="payment-popup-container form-wrapper">
        <div className="cashier-accordion deposit">
          <DepositWidgetAccordion
            headers={sections}
            payments={payments}
            paymentStats={paymentStats}
            bonusCode={bonusCode}
            fromWelcome={fromWelcome}
            currency={user.Currency}
            country={user.Country}
            bonuses={availableBonuses}
            methods={notVerifiedNL ? [] : methods}
            initialState={selectedOption}
            onSubmit={this.handleSubmit}
            onDeleteAccount={this.handleDeleteAccount}
            reset={this.reset}
            user={user}
            sessionId={sessionId}
            renderError={this.renderError}
            clearError={this.handleClearError}
            remainingDepositLimit={remainingDepositLimit}
            amounts={amounts}
            renderDepositLimitNotice={this.renderDepositLimitNotice}
            countries={countries && countries.value}
            renderNotVerifiedNotice={this.renderNotVerifiedNotice}
            renderCanceledNotice={
              paymentStatus.status === 'failed' && this.renderCanceledNotice
            }
            jurisdiction={jurisdiction}
            hasIdApproved={hasIdApproved}
            shouldAutoFocus={device.toLowerCase() === 'desktop'}
            config={config}
          />
        </div>
      </div>
    );
  }
}

export default Deposit;

import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useConfirmPopup, { CONFIRM_STATUS } from './useConfirmPopup';
import { useUrls } from './urls';
import debounce from 'lodash/debounce';

export const useHorizontalScrollPosition = ref => {
  const [scrollLeft, setScrollLeft] = useState(null);
  const [scrollRight, setScrollRight] = useState(null);
  const setPositions = element => {
    setScrollLeft(element.scrollLeft);
    setScrollRight(
      element.scrollWidth - (element.scrollLeft + element.clientWidth)
    );
  };
  useLayoutEffect(() => {
    const element = ref.current;
    if (element) {
      setPositions(element);
      const event = e => setPositions(e.target);
      element.addEventListener('scroll', event);
      return () => element.removeEventListener('scroll', event);
    }
  }, [ref]);
  return [scrollLeft, scrollRight];
};

export const useIsScrollable = ref => {
  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    if (ref.current) {
      const { scrollWidth, clientWidth } = ref.current;
      setIsScrollable(clientWidth < scrollWidth);
    }
  }, [ref]);
  return isScrollable;
};

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(null);

  useEffect(() => {
    if (__CLIENT__) {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      window.addEventListener('orientationchange', debounce(handleResize, 50));

      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleResize);
      };
    }
  }, []);

  return windowWidth;
};

/**
 * Show a low balance popup for user whose balance is below a threshold set in CMS
 */
export const useLowBalancePopup = ({ goToGame, location, history }) => {
  const user = useSelector(state => state.player.user);
  const wallet = useSelector(state => state.wallet.data);
  const lowBalanceConfig = useSelector(state =>
    state.content.config.data?.find(c => c.key === 'low-balance')
  );
  const { deposit } = useUrls();

  const lowBalanceConfirmPopup = useConfirmPopup({
    content: { id: 'low-balance-popup' },
    confirm: {
      cta: 'action.deposit',
      action: () => history.push(deposit()),
    },
    decline: {
      cta: 'action.low-balance.go-to-game',
      action: goToGame,
    },
    location,
    history,
  });

  /**
   * Set status to "not confirmed" if user has low balance
   */
  const hasLowBalance =
    lowBalanceConfig && user
      ? lowBalanceConfig.value[user?.Currency]?.threshold >=
        wallet?.Total.Balance
      : false;
  useEffect(() => {
    lowBalanceConfirmPopup.setStatus(
      hasLowBalance ? CONFIRM_STATUS.NOT_CONFIRMED : CONFIRM_STATUS.CONFIRMED
    );
  }, [hasLowBalance, lowBalanceConfirmPopup]);

  return lowBalanceConfirmPopup;
};

export const useFullscreen = () => {
  const checkIsFullscreen = () =>
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullScreenElement ||
    document.webkitFullscreenElement;

  const [isFullscreen, setIsFullscreen] = useState(
    __CLIENT__ && checkIsFullscreen()
  );

  useEffect(() => {
    const event = () => setIsFullscreen(checkIsFullscreen());
    document.addEventListener('fullscreenchange', event);
    document.addEventListener('webkitfullscreenchange', event);
    return () => {
      document.removeEventListener('fullscreenchange', event);
      document.removeEventListener('webkitfullscreenchange', event);
    };
  });

  useEffect(() => () => checkIsFullscreen() && closeFullscreen(), []);

  const openFullscreen = () => {
    document.documentElement.requestFullscreen?.();
    document.documentElement.mozRequestFullScreen?.();
    document.documentElement.webkitRequestFullscreen?.();
    document.documentElement.msRequestFullscreen?.();
  };

  const closeFullscreen = () => {
    document.exitFullscreen?.();
    document.mozCancelFullScreen?.();
    document.webkitExitFullscreen?.();
    document.msExitFullscreen?.();
  };

  const toggleFullscreen = () => {
    isFullscreen ? closeFullscreen() : openFullscreen();
  };

  return { toggleFullscreen, isFullscreen };
};

/**
 * Hook subscribing to window.innerWidth and window.innerHeight
 */
export const useInnerDimensions = (debounce = 50) => {
  const [innerHeight, setInnerHeight] = useState(
    __CLIENT__ ? window.innerHeight : 0
  );
  const [innerWidth, setInnerWidth] = useState(
    __CLIENT__ ? window.innerWidth : 0
  );
  const updateTimeout = useRef();
  const updateDimensions = () => {
    clearTimeout(updateTimeout.current);
    updateTimeout.current = setTimeout(() => {
      setInnerHeight(window.innerHeight);
      setInnerWidth(window.innerWidth);
    }, debounce);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('orientationchange', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('orientationchange', updateDimensions);
      clearTimeout(updateTimeout.current);
    };
  }, []);

  return [innerWidth, innerHeight, updateDimensions];
};

export const useAddToHomescreenPrompt = () => {
  const [prompt, setPrompt] = useState(null);

  const promptAppInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }

    return Promise.reject('App cannot be installed.');
  };

  useEffect(() => {
    const onBeforeInstallPrompt = e => {
      e.preventDefault();
      setPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);

    return () =>
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
  }, []);

  return [prompt, promptAppInstall];
};

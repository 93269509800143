import React, { useEffect, useState } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import Loader from '@components/Loader';
import { useConfig } from '@utils/config';

import './style.css';

import PaymentForm from 'tg-core-components/lib/widgets/Cashier/Deposit/PaymentForm';

const ExternalAuthPnp = ({
  config,
  device,
  operatingSystem,
  onError,
  onSuccess,
  params,
}) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const {
    devcode: {
      oauth_clientid: client_id,
      oauth_provider: identity_provider,
      oauth_redirecturl: redirect_uri,
      oauth_ui_friendly: uiFriendly,
    },
  } = useConfig();

  const getHost = () => {
    if (typeof global !== 'undefined' && global.paymentIQOAuthHost)
      return global.paymentIQOAuthHost;
    if (typeof window !== 'undefined' && window.paymentIQOAuthHost)
      return window.paymentIQOAuthHost;

    return 'https://test-api.paymentiq.io/paymentiq/oauth';
  };

  const onMessage = message => {
    const { data } = message;

    if (data.name !== 'externalauth') return;

    if (data.query.tx_state === 'FAILED') {
      return onError(data?.query?.errCode);
    }

    return onSuccess(data?.query);
  };

  const getCustomProvider = () => {
    setIsLoading(true);

    axios
      .get(
        `${getHost()}/authorize?${queryString.stringify({
          ...config,
          client_id,
          identity_provider,
          redirect_uri: params ? `${redirect_uri}?${params}` : redirect_uri,
          uiFriendly,
        })}`
      )
      .then(res => {
        setData(res.data);
      })
      .catch(e => {
        let data = e.request.response;
        if (typeof data === 'string') data = JSON.parse(data);

        onError(data.code);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const qs = queryString.parse(window.location.search.substring(1));

    const obj = {
      name: 'externalauth',
      query: qs,
    };

    if (qs.loginFromTrustly === 'true') {
      window.postMessage(obj, '*');
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []); // eslint-disable-line

  useEffect(() => {
    const qs = queryString.parse(window.location.search.substring(1));

    // Prevent loop of provider initializations
    if (qs.loginFromTrustly !== 'true') {
      getCustomProvider();
    }
  }, []); // eslint-disable-line

  if (isLoading) return <Loader inline />;

  if (data) {
    return (
      <div className="ExternalAuth">
        <PaymentForm
          data={data}
          operatingSystem={operatingSystem}
          redirect={device === 'Mobile'}
        />
      </div>
    );
  }

  return null;
};

export default ExternalAuthPnp;
